
import Vue from 'vue';
import UnsavedGuard from '@/mixins/unsaved-guard';
import SettingsList from '@/components/views/settings/SettingsList.vue';
import Employee from '@/model/Employee';

export default Vue.extend({
  name: 'Employees',
  components: { SettingsList },
  mixins: [UnsavedGuard],
  data() { return { changed: false }; },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    isChanged(): boolean { return this.changed; },
  },
  methods: {
    async save(employees: Employee[]) { await this.$tstore.dispatch('sendEmployees', { entities: employees }); },
    add(): Employee { return new Employee(); },
    isChangedChange(changed: boolean) { this.changed = changed; },
  },
});

