import { IDTOCampaignFilter } from '@/api/api-campaign-filter';
import { strcmp } from '@/services/common';
import { dateFromDateTimeString, dateTimeStringFromDate } from '@/services/time-utils';
import Campaign from './Campaign';

export interface CampaignFilterMapping {
  campaignsById: Map<number, Campaign>;
}

export default class CampaignFilter {
  id: number;

  campaign?: Campaign | null;

  dateFrom: Date;

  dateTo: Date;

  get order(): number { return this.dateFrom.getTime(); }

  get stringOrder(): string { return dateTimeStringFromDate(this.dateFrom)! + dateTimeStringFromDate(this.dateTo)!; }

  constructor(o?: IDTOCampaignFilter, m?: CampaignFilterMapping) {
    this.id = o?.filterId ?? 0;
    this.dateFrom = dateFromDateTimeString(o?.dtFrom) ?? new Date();
    this.dateTo = dateFromDateTimeString(o?.dtTo) ?? new Date();

    this.campaign = m?.campaignsById.get(o?.campaignId ?? NaN) ?? null;
  }

  toDto(): IDTOCampaignFilter {
    const dto: IDTOCampaignFilter = {
      filterId: this.id,
      campaignId: this.campaign?.id,
      dtFrom: Number(dateTimeStringFromDate(this.dateFrom)) ?? undefined,
      dtTo: Number(dateTimeStringFromDate(this.dateTo)) ?? undefined,
    };
    return dto;
  }
}

export function compareCampaignFilter(o1: CampaignFilter, o2:CampaignFilter): number {
  let res = o1.dateFrom.getTime() - o2.dateFrom.getTime();
  if (res !== 0) return res;
  res = o1.dateTo.getTime() - o2.dateTo.getTime();
  return res;
}
