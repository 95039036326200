/**
 * @fileoverview gRPC-Web generated client stub for grpc.api.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.1
// source: feedback_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var empty_pb = require('./empty_pb.js')

var feedback_pb = require('./feedback_pb.js')

var reservation_pb = require('./reservation_pb.js')

var feedback_settings_pb = require('./feedback_settings_pb.js')
const proto = {};
proto.grpc = {};
proto.grpc.api = {};
proto.grpc.api.v1 = require('./feedback_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.FeedbackServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.FeedbackServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.FeedbackGetFeedbackRequest,
 *   !proto.grpc.api.v1.FeedbackFeedbackResponse>}
 */
const methodDescriptor_FeedbackService_getFeedback = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.FeedbackService/getFeedback',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.FeedbackGetFeedbackRequest,
  proto.grpc.api.v1.FeedbackFeedbackResponse,
  /**
   * @param {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.FeedbackFeedbackResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.FeedbackFeedbackResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.FeedbackFeedbackResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.FeedbackServiceClient.prototype.getFeedback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/getFeedback',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_getFeedback,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.FeedbackFeedbackResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.FeedbackServicePromiseClient.prototype.getFeedback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/getFeedback',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_getFeedback);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.FeedbackListFeedbacksRequest,
 *   !proto.grpc.api.v1.FeedbackListFeedbackResponse>}
 */
const methodDescriptor_FeedbackService_listFeedbacks = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.FeedbackService/listFeedbacks',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.FeedbackListFeedbacksRequest,
  proto.grpc.api.v1.FeedbackListFeedbackResponse,
  /**
   * @param {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.FeedbackListFeedbackResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.FeedbackListFeedbackResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.FeedbackServiceClient.prototype.listFeedbacks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.FeedbackService/listFeedbacks',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_listFeedbacks);
};


/**
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.FeedbackListFeedbackResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.FeedbackServicePromiseClient.prototype.listFeedbacks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.FeedbackService/listFeedbacks',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_listFeedbacks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.FeedbackGetReservationRequest,
 *   !proto.grpc.api.v1.FeedbackReservationResponse>}
 */
const methodDescriptor_FeedbackService_getReservation = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.FeedbackService/getReservation',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.FeedbackGetReservationRequest,
  proto.grpc.api.v1.FeedbackReservationResponse,
  /**
   * @param {!proto.grpc.api.v1.FeedbackGetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.FeedbackReservationResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.FeedbackGetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.FeedbackReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.FeedbackReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.FeedbackServiceClient.prototype.getReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/getReservation',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_getReservation,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.FeedbackGetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.FeedbackReservationResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.FeedbackServicePromiseClient.prototype.getReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/getReservation',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_getReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.EmptyRequest,
 *   !proto.grpc.api.v1.FeedbackSettingsResponse>}
 */
const methodDescriptor_FeedbackService_getSettings = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.FeedbackService/getSettings',
  grpc.web.MethodType.UNARY,
  empty_pb.EmptyRequest,
  proto.grpc.api.v1.FeedbackSettingsResponse,
  /**
   * @param {!proto.grpc.api.v1.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.FeedbackSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.FeedbackSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.FeedbackSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.FeedbackServiceClient.prototype.getSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/getSettings',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_getSettings,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.FeedbackSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.FeedbackServicePromiseClient.prototype.getSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/getSettings',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_getSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.FeedbackSetSettingsRequest,
 *   !proto.grpc.api.v1.FeedbackSettingsResponse>}
 */
const methodDescriptor_FeedbackService_setSettings = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.FeedbackService/setSettings',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.FeedbackSetSettingsRequest,
  proto.grpc.api.v1.FeedbackSettingsResponse,
  /**
   * @param {!proto.grpc.api.v1.FeedbackSetSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.FeedbackSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.FeedbackSetSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.FeedbackSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.FeedbackSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.FeedbackServiceClient.prototype.setSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/setSettings',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_setSettings,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.FeedbackSetSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.FeedbackSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.FeedbackServicePromiseClient.prototype.setSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.FeedbackService/setSettings',
      request,
      metadata || {},
      methodDescriptor_FeedbackService_setSettings);
};


module.exports = proto.grpc.api.v1;

