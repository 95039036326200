
import WelcomeStepWelcome from '@/components/views/onboarding/WelcomeStepWelcome.vue';
import WelcomeStepAccount from '@/components/views/onboarding/WelcomeStepAccount.vue';
import WelcomeStepTables from '@/components/views/onboarding/WelcomeStepTables.vue';
import WelcomeStepOnlineAlt from '@/components/views/onboarding/WelcomeStepOnline.vue';
import WelcomeStepHours from '@/components/views/onboarding/WelcomeStepHours.vue';
import DialogClosable from '@/mixins/dialog-closable';

import Vue from 'vue';

export default Vue.extend({
  name: 'Welcome',
  components: {
    WelcomeStepWelcome,
    WelcomeStepAccount,
    WelcomeStepTables,
    WelcomeStepOnlineAlt,
    WelcomeStepHours,
  },
  mixins: [DialogClosable],
  props: { },
  data() {
    return {
      step: 0,
      steps: 4,
      editable: true,
      completedSteps: [false, false, false, false, false],
      skipSteps: [false, true, false, false, false],
      stepLabels: ['',
        this.$i18n.t('title.account'),
        this.$i18n.t('title.tables'),
        this.$i18n.t('title.opening_hours'),
        this.$i18n.t('title.finished')],
    };
  },
  computed: { },
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        if (value) this.$emit('open');
      },
    },
  },
  methods: {
    finishStep(s: number) {
      this.completedSteps[s] = true;
      this.nextStep();
    },
    skip() {
      this.nextStep();
    },
    submit() {
      const stepComponent = this.$refs[`step_${this.step}`] as any;
      stepComponent.submit();
    },
    nextStep() {
      if (this.step === this.steps) {
        this.$emit('close');
        return;
      }
      this.step += 1;
      this.stepChanged(this.step);
    },
    stepChanged(step: number) {
      if (step !== 4) return;
      const stepComponent = this.$refs[`step_${this.step}`] as any;
      stepComponent.reset();
    },
  },
});
