
import Vue from 'vue';

import ReportsToolbar from '@/components/views/reports/ReportsToolbar.vue';
import MiniPieChart from '@/components/views/reports/MiniPieChart.vue';
import ServicesRevenueChart from '@/components/views/reports/ServicesRevenueChart.vue';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import ReportsStore from '@/store/reports-store';
import { ChartData, PieChartData } from '@/services/reports-utils';
import { ReportsType } from '@/services/reports';

export default Vue.extend({
  name: 'ReportServices',
  components: {
    ReportsToolbar, ServicesRevenueChart, MiniPieChart,
  },
  data() {
    return {};
  },
  computed: {
    services(): ChartData { return this.$tstore.getters.reportsServices; },
    servicesRevenue(): ChartData { return this.$tstore.getters.reportsServicesRevenue; },
    reportsServicesPie(): {
      datasetByReservations: PieChartData,
      datasetByGuests: PieChartData,
      datasetByRevenue: PieChartData,
    } {
      return this.$tstore.getters.reportsServicesPie;
    },
  },
  created() {
    loadStoreModule(this, 'reports', ReportsStore, async () => {
      this.$tstore.dispatch('updateReports', { reportsType: ReportsType.Services });
    });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'reports', async () => { this.$tstore.dispatch('resetReports'); });
  },
  methods: {},
});

