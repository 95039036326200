import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import theme from '@/theme/theme';

import IconGuestplan from '@/components/icons/IconGuestplan.vue';
import IconWalkOutline from '@/components/icons/IconWalkOutline.vue';
import '@mdi/font/css/materialdesignicons.css';

import en from 'vuetify/src/locale/en';
import nl from 'vuetify/src/locale/nl';
import de from 'vuetify/src/locale/de';
import it from 'vuetify/src/locale/it';
import cs from 'vuetify/src/locale/cs';
import sk from 'vuetify/src/locale/sk';
import fr from 'vuetify/src/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      en, nl, de, it, cs, sk, fr,
    },
    current: 'en',
  },
  theme: theme[process.env.VUE_APP_BRAND!],
  icons: {
    iconfont: 'mdi',
    values: {
      guestplan: {
        component: IconGuestplan,
        props: {
          name: 'icon-guestplan',
        },
      },
      'walk-outline': {
        component: IconWalkOutline,
        props: {
          name: 'icon-walk-outline',
        },
      },
    },
  },
});
