/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/giftcard_pb';
import * as pbs from '@/grpc-pb/giftcards_service_pb';
import { dateFromDateString, dateFromDateTimeString, unixFromDate } from '@/services/time-utils';
import { ContactMapping } from '@/model/Contact';
import Currency from './currency';
import GiftcardTransaction, { GiftcardTransactionMapping } from './giftcard-transaction';
import Contact from './contact';
import GiftcardRecipient from './giftcard-recipient';

export interface GiftcardMapping extends GiftcardTransactionMapping, ContactMapping {
}

export default class Giftcard {
  id: number = 0;

  title?: string;

  description?: string;

  backgroundImageUrl?: string;

  serviceId?: number = 0;

  locale?: string;

  balance: number = 0;

  currency: string = '';

  status: string = '';

  code: string = '';

  color?: string;

  dtCreate?: Date;

  dtUpdate?: Date;

  dtExpiry?: Date;

  price?: number;

  showLogo?: boolean;

  contact?: Contact;

  recipient?: GiftcardRecipient;

  transactions: GiftcardTransaction[] = [];

  isExpired?: boolean;

  issuedBy?: string;

  issuedByAccountId?: number;

  issuedByAccountName?: string;

  static fromGrpcResponse(r: pbs.GiftcardsGiftcardResponse, m?: GiftcardMapping): Giftcard {
    const o = r.getGiftcard();
    return Giftcard.fromGrpcModel(o, m);
  }

  static fromGrpcModel(o?: pbm.Giftcard, m?: GiftcardMapping): Giftcard {
    const p = new Giftcard();

    p.id = o?.getId() ?? 0;
    p.title = o?.getTitle();
    p.description = o?.getDescription();
    p.backgroundImageUrl = o?.getBackgroundImageUrl();
    p.serviceId = o?.getServiceId();
    p.locale = o?.getLocale();
    p.balance = o?.getBalance() ?? 0;
    p.currency = o?.getCurrency() ?? '';
    p.status = o?.getStatus() ?? '';
    p.code = o?.getCode() ?? '';
    p.color = o?.getColor();
    p.dtCreate = o?.getDtCreate() ? new Date(o.getDtCreate() * 1000) : undefined;
    p.dtUpdate = o?.getDtUpdate() ? new Date(o.getDtUpdate() * 1000) : undefined;
    // p.dtExpiry = o?.getDtExpiry() ? new Date(o.getDtExpiry() * 1000) : undefined;
    p.price = o?.getPrice();
    p.showLogo = o?.getShowLogo();

    p.recipient = o?.getRecipient() ? GiftcardRecipient.fromGrpcModel(o?.getRecipient(), m) : undefined;
    p.contact = o?.getContact() ? Contact.fromGrpcModel(o?.getContact(), m) : undefined;
    p.transactions = (o?.getTransactionsList() ?? []).map((t) => GiftcardTransaction.fromGrpcModel(t, m));

    p.isExpired = o?.getIsExpired();
    p.dtExpiry = dateFromDateString(o?.getDtExpiryMil()) ?? undefined;

    p.issuedByAccountId = o?.getIssuedByAccountId();
    p.issuedByAccountName = o?.getIssuedByAccountName();

    return p;
  }
}
