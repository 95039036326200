/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import * as pbcm from '@/grpc-pb/contact_manager_service_pb';
import * as pbcms from '@/grpc-pb/contact_manager_service_grpc_web_pb';
import * as pbd from '@/grpc-pb/dashboard_service_pb';
import * as pbds from '@/grpc-pb/dashboard_service_grpc_web_pb';
import * as pbr from '@/grpc-pb/reports_service_pb';
import * as pbrs from '@/grpc-pb/reports_service_grpc_web_pb';
import * as pbs from '@/grpc-pb/settings_service_pb';
import * as pbss from '@/grpc-pb/settings_service_grpc_web_pb';
import * as pbh from '@/grpc-pb/history_service_pb';
import * as pbhs from '@/grpc-pb/history_service_grpc_web_pb';
import * as pbm from '@/grpc-pb/message_service_pb';
import * as pbms from '@/grpc-pb/message_service_grpc_web_pb';
import * as pba from '@/grpc-pb/apps_service_pb';
import * as pbas from '@/grpc-pb/apps_service_grpc_web_pb';
import * as pbsm from '@/grpc-pb/service_manager_service_pb';
import * as pbsms from '@/grpc-pb/service_manager_service_grpc_web_pb';
import * as pbgc from '@/grpc-pb/giftcards_service_pb';
import * as pbgcs from '@/grpc-pb/giftcards_service_grpc_web_pb';
import * as pbfb from '@/grpc-pb/feedback_service_pb';
import * as pbfbs from '@/grpc-pb/feedback_service_grpc_web_pb';

import { EmptyRequest } from '@/grpc-pb/empty_pb';

import {
  grpcStreamOp, grpcStreamOpAll, grpcUnaryOp, grpcUnaryOpEx, OnErrorFnc, OnMultipleDataFnc,
} from '@/services/grpc-client-utils';
import Contact from '@/grpc-api/model/contact';
import Employee from '@/grpc-api/model/employee';
import { ContactMapping } from '@/model/Contact';
import ContactFilter from '@/grpc-api/model/contact-filter';
import EditorTemplate from '@/grpc-api/model/editor-template';
import DashboardAvailability from '@/grpc-api/model/dashboard-availability';
import Campaign from '@/grpc-api/model/campaign';
import Message from '@/grpc-api/model/message';
import { ReorderEntity, ReorderRequest } from '@/grpc-pb/reorder_pb';
import { ReservationMapping } from '@/model/Reservation';
import { unixFromDate } from '@/services/time-utils';
import App from './model/app';
import ReservationService, { ReservationServiceMapping } from './model/reservation-service';
import Currency from './model/currency';
import GiftcardProduct from './model/giftcard-product';
import GiftcardTransaction, { GiftcardTransactionListParams } from './model/giftcard-transaction';
import Giftcard, { GiftcardMapping } from './model/giftcard';
import Feedback, { FeedbackMapping } from './model/feedback';
import Reservation from './model/reservation';
import FeedbackSettings, { FeedbackSettingsMapping } from './model/feedback-settings';

const baseURL = process.env.VUE_APP_ENDPOINT_GRPC!;

export interface IGrpcClientState {
  token?: string;
}

class GrpcClient {
  state: IGrpcClientState = {};

  contactService = new pbcms.ContactServiceClient(baseURL);

  contactFilterService = new pbcms.ContactFilterServiceClient(baseURL);

  editorTemplateService = new pbcms.EditorTemplateServiceClient(baseURL);

  contactManagerService = new pbcms.ContactManagerServiceClient(baseURL);

  dashboardService = new pbds.DashboardServiceClient(baseURL);

  reportsService = new pbrs.ReportsServiceClient(baseURL);

  settingsService = new pbss.SettingsServiceClient(baseURL);

  historyService = new pbhs.HistoryServiceClient(baseURL);

  messageService = new pbms.MessageServiceClient(baseURL);

  appsService = new pbas.AppsServiceClient(baseURL);

  serviceManagerService = new pbsms.ServiceManagerServiceClient(baseURL);

  giftcardsService = new pbgcs.GiftcardsServiceClient(baseURL);

  feedbackService = new pbfbs.FeedbackServiceClient(baseURL);

  get metadata() {
    return ({
      authorization: `Bearer ${this.state.token}`,
      // deadline: `${Date.now() + 10000}`,
    });
  }

  // Contact
  async listContacts(onData: OnMultipleDataFnc<Contact>, onError: OnErrorFnc, m: ContactMapping) {
    const req = new pbcm.ContactListRequest();
    return grpcStreamOp(Contact, this.contactService, 'list', this.metadata, req, m, onData, onError);
  }

  async createContact(c: Contact, e: Employee|undefined, m: ContactMapping) {
    const req = new pbcm.ContactRequest(); req.setContact(c.toGrpcModel()); if (e) req.setEmployee(e.toGrpcModel());
    return grpcUnaryOp(Contact, this.contactService, 'create', this.metadata, req, m);
  }

  async updateContact(c: Contact, e: Employee|undefined, m: ContactMapping) {
    const req = new pbcm.ContactRequest(); req.setContact(c.toGrpcModel()); if (e) req.setEmployee(e.toGrpcModel());
    return grpcUnaryOp(Contact, this.contactService, 'update', this.metadata, req, m);
  }

  // ContactFilter
  async createContactFilter(cf: ContactFilter, m = undefined) {
    const req = new pbcm.ContactFilterRequest(); req.setContactfilter(cf.toGrpcModel());
    return grpcUnaryOp(ContactFilter, this.contactFilterService, 'create', this.metadata, req, m);
  }

  async getContactFilter(cf: ContactFilter, m = undefined) {
    const req = new pbcm.ContactFilterIdRequest(); req.setId(cf.id);
    return grpcUnaryOp(ContactFilter, this.contactFilterService, 'get', this.metadata, req, m);
  }

  async updateContactFilter(cf: ContactFilter, m = undefined) {
    const req = new pbcm.ContactFilterRequest(); req.setContactfilter(cf.toGrpcModel());
    return grpcUnaryOp(ContactFilter, this.contactFilterService, 'update', this.metadata, req, m);
  }

  async deleteContactFilter(cf: ContactFilter, m = undefined) {
    const req = new pbcm.ContactFilterIdRequest(); req.setId(cf.id);
    return grpcUnaryOp(ContactFilter, this.contactFilterService, 'delete', this.metadata, req, m);
  }

  async listContactFilters(onData: OnMultipleDataFnc<ContactFilter>, onError: OnErrorFnc, m = undefined) {
    const req = new pbcm.ContactFilterListRequest();
    return grpcStreamOp(ContactFilter, this.contactFilterService, 'list', this.metadata, req, m, onData, onError);
  }

  // EditorTemplate
  async createEditorTemplate(et: EditorTemplate, m = undefined) {
    const req = new pbcm.EditorTemplateRequest(); req.setEditortemplate(et.toGrpcModel());
    return grpcUnaryOp(EditorTemplate, this.editorTemplateService, 'create', this.metadata, req, m);
  }

  async getEditorTemplate(et: EditorTemplate, m = undefined) {
    const req = new pbcm.EditorTemplateIdRequest(); req.setId(et.id);
    return grpcUnaryOp(EditorTemplate, this.editorTemplateService, 'get', this.metadata, req, m);
  }

  async updateEditorTemplate(et: EditorTemplate, m = undefined) {
    const req = new pbcm.EditorTemplateRequest(); req.setEditortemplate(et.toGrpcModel());
    return grpcUnaryOp(EditorTemplate, this.editorTemplateService, 'update', this.metadata, req, m);
  }

  async deleteEditorTemplate(et: EditorTemplate, m = undefined) {
    const req = new pbcm.EditorTemplateIdRequest(); req.setId(et.id);
    return grpcUnaryOp(EditorTemplate, this.editorTemplateService, 'delete', this.metadata, req, m);
  }

  async listEditorTemplates(onData: OnMultipleDataFnc<EditorTemplate>, onError: OnErrorFnc, m = undefined) {
    const req = new pbcm.EditorTemplateListRequest();
    return grpcStreamOp(EditorTemplate, this.editorTemplateService, 'list', this.metadata, req, m, onData, onError);
  }

  // ContactManager
  async selectedContacts(ids: number[], onData: OnMultipleDataFnc<Contact>, onError: OnErrorFnc, m?: ContactMapping) {
    const req = new pbcm.SelectedContactsRequest(); req.setIdsList(ids);
    return grpcStreamOp(Contact, this.contactManagerService, 'selectedContacts', this.metadata, req, m, onData, onError, true);
  }

  async mergeContacts(c: Contact, e: Employee|undefined, ids: number[], onData: OnMultipleDataFnc<Contact>, onError: OnErrorFnc, m?: ContactMapping) {
    const req = new pbcm.MergeContactsRequest();
    req.setContact(c.toGrpcModel());
    req.setIdsList(ids);
    if (e) req.setEmployee(e.toGrpcModel());
    return grpcStreamOp(Contact, this.contactManagerService, 'mergeContacts', this.metadata, req, m, onData, onError, true);
  }

  async deleteContacts(ids: number[], e: Employee|undefined, onData: OnMultipleDataFnc<Contact>, onError: OnErrorFnc, m?: ContactMapping) {
    const req = new pbcm.DeleteContactsRequest();
    req.setIdsList(ids);
    if (e) req.setEmployee(e.toGrpcModel());
    return grpcStreamOp(Contact, this.contactManagerService, 'deleteContacts', this.metadata, req, m, onData, onError, true);
  }

  async searchContacts(filter: string, onData: OnMultipleDataFnc<Contact>, onError: OnErrorFnc, m?: ContactMapping) {
    const req = new pbcm.SearchContactsRequest();
    req.setFilter(filter);
    return grpcStreamOp(Contact, this.contactManagerService, 'searchContacts', this.metadata, req, m, onData, onError, true);
  }

  // Dashboard
  async getAvailability(dateIndex: number, m = undefined): Promise<DashboardAvailability> {
    const req = new pbd.DashboardAvailabilityRequest(); req.setDate(dateIndex);
    return grpcUnaryOp(DashboardAvailability, this.dashboardService, 'getAvailability', this.metadata, req, m);
  }

  // Reports
  async getTabsReports(beginDateIndex: number, endDateIndex: number, binType: pbr.ReportsParams.ReportsBinType, m = undefined): Promise<pbr.TabsReportsResponse> {
    const params = new pbr.ReportsParams();
    params.setBeginDate(beginDateIndex);
    params.setEndDate(endDateIndex);
    params.setBinType(binType);
    const req = new pbr.ReportsRequest(); req.setParams(params);
    return grpcUnaryOpEx(null, this.reportsService, 'getTabsReports', this.metadata, req, m) as Promise<pbr.TabsReportsResponse>;
  }

  async getMsgsReports(beginDateIndex: number, endDateIndex: number, binType: pbr.ReportsParams.ReportsBinType, m = undefined): Promise<pbr.MsgsReportsResponse> {
    const params = new pbr.ReportsParams();
    params.setBeginDate(beginDateIndex);
    params.setEndDate(endDateIndex);
    params.setBinType(binType);
    const req = new pbr.ReportsRequest(); req.setParams(params);
    return grpcUnaryOpEx(null, this.reportsService, 'getMsgsReports', this.metadata, req, m) as Promise<pbr.MsgsReportsResponse>;
  }

  // Settings
  async getDefaultCampaign(m = undefined): Promise<Campaign> {
    const req = new EmptyRequest();
    return grpcUnaryOp(Campaign, this.settingsService, 'getDefaultCampaign', this.metadata, req, m);
  }

  async testDefaultCampaign(data: Campaign, m = undefined): Promise<void> {
    const req = new pbs.CampaignRequest(); req.setCampaign(data.toGrpcModel());
    const res = grpcUnaryOpEx(null, this.settingsService, 'testDefaultCampaign', this.metadata, req, m) as Promise<void>;
    return res;
  }

  async updateDefaultCampaign(data: Campaign, m = undefined): Promise<Campaign> {
    const req = new pbs.CampaignRequest(); req.setCampaign(data.toGrpcModel());
    return grpcUnaryOp(Campaign, this.settingsService, 'updateDefaultCampaign', this.metadata, req, m);
  }

  // History
  async getReservationHistory(reservationId: number, m = undefined): Promise<{ records: string[], messages: string[] }> {
    const req = new pbh.HistoryRequest(); req.setReservationid(reservationId);
    const response = await (grpcUnaryOpEx(null, this.historyService, 'getHistory', this.metadata, req, m) as Promise<pbh.HistoryResponse>);
    const records = response.getRecordsList().map((hr) => hr.getJson());
    const messages = response.getMessagesList().map((hr) => hr.getJson());
    return { records, messages };
  }

  // Message
  async sendMessage(message: Message): Promise<void> {
    const req = new pbm.SendMessageRequest(); req.setMessage(message.toGrpcModel());
    const res = grpcUnaryOpEx(null, this.messageService, 'sendMessage', this.metadata, req, undefined) as Promise<void>;
    return res;
  }

  // Apps
  async getApp(appId: number): Promise<App> {
    const app = new App({ appId });
    const req = new pba.AppRequest(); req.setApp(app.toGrpcModel());
    const res = grpcUnaryOp(App, this.appsService, 'getApp', this.metadata, req, undefined) as Promise<App>;
    return res;
  }

  // async installApp(appId: number, isInstalled: boolean): Promise<App> {
  //   const app = new App({ appId, isInstalled });
  //   const req = new pba.AppRequest(); req.setApp(app.toGrpcModel());
  //   const res = grpcUnaryOp(App, this.appsService, 'installApp', this.metadata, req, undefined) as Promise<App>;
  //   return res;
  // }

  async updateApp(appId: number, isInstalled: boolean, params?: string): Promise<App> {
    const app = new App({ appId, isInstalled });
    app.params = params || undefined;
    const req = new pba.AppRequest(); req.setApp(app.toGrpcModel());
    const res = grpcUnaryOp(App, this.appsService, 'updateApp', this.metadata, req, undefined) as Promise<App>;
    return res;
  }

  // Service Manager
  async listReservationServices(onData: OnMultipleDataFnc<ReservationService>, m: ReservationServiceMapping): Promise<void> {
    const req = new EmptyRequest();
    return grpcStreamOp(ReservationService, this.serviceManagerService, 'listServices', this.metadata, req, m, onData, () => {}, true);
  }

  async reorderReservationServices(services: ReservationService[], onData: OnMultipleDataFnc<ReservationService>, m: ReservationServiceMapping): Promise<void> {
    const req = new ReorderRequest();
    services.forEach((s) => {
      const rentity = new ReorderEntity(); rentity.setId(s.id); rentity.setOrder(s.order);
      req.addEntities(rentity);
    });
    return grpcStreamOp(ReservationService, this.serviceManagerService, 'reorderServices', this.metadata, req, m, onData, () => {}, true);
  }

  async updateReservationService(service: ReservationService, onData: OnMultipleDataFnc<ReservationService>, m: ReservationServiceMapping): Promise<void> {
    const req = new pbsm.ReservationServiceRequest(); req.setService(service.toGrpcModel());
    return grpcStreamOp(ReservationService, this.serviceManagerService, 'updateService', this.metadata, req, m, onData, () => {}, true);
  }

  async listCurrencies(): Promise<Currency[]> {
    const req = new EmptyRequest();
    return grpcStreamOpAll(Currency, this.settingsService, 'listCurrencies', this.metadata, req);
  }

  // giftcards
  async getGiftcardProduct(): Promise<GiftcardProduct> {
    const req = new EmptyRequest();
    return grpcUnaryOp(GiftcardProduct, this.giftcardsService, 'getProduct', this.metadata, req, {});
  }

  async updateGiftcardProduct(p: GiftcardProduct): Promise<GiftcardProduct> {
    const req = new pbgc.GiftcardsProductRequets(); req.setProduct(p.toGrpcModel());
    return grpcUnaryOp(GiftcardProduct, this.giftcardsService, 'updateProduct', this.metadata, req, {});
  }

  async listGiftcardTransactions(p: GiftcardTransactionListParams, onData: OnMultipleDataFnc<GiftcardTransaction>) {
    const params = new pbgc.GiftcardsListTransactionsParams();
    if (p.beginDateIndex) params.setBeginDate(p.beginDateIndex);
    if (p.endDateIndex) params.setEndDate(p.endDateIndex);
    if (p.types) params.setTypesList(p.types);
    if (p.codes) params.setGiftcardCodesList(p.codes);
    if (p.code) params.setGiftcardCode(p.code);

    const req = new pbgc.GiftcardsListTransactionsRequest();
    req.setParams(params);

    return grpcStreamOp(GiftcardTransaction, this.giftcardsService, 'listTransactions', this.metadata, req, {}, onData, () => {}, true);
  }

  async getGiftcard(p: { id?: number, code?: string }, m: GiftcardMapping): Promise<Giftcard> {
    const req = new pbgc.GiftcardsGetGiftcardRequest();
    if (p.id) req.setGiftcardId(p.id);
    if (p.code) req.setGiftcardCode(p.code);
    return grpcUnaryOp(Giftcard, this.giftcardsService, 'getGiftcard', this.metadata, req, m);
  }

  async redeemGiftcard(id: number, code: string, amount: number, balance: number, p: { idempotencyKey?: string}, m: GiftcardMapping): Promise<Giftcard|undefined> {
    const req = new pbgc.GiftcardsRedeemGiftcardRequest();
    req.setGiftcardId(id);
    req.setGiftcardCode(code);
    req.setAmount(amount);
    req.setBalance(balance);
    if (p.idempotencyKey) req.setIdempotencyKey(p.idempotencyKey);

    return grpcUnaryOp(Giftcard, this.giftcardsService, 'redeemGiftcard', this.metadata, req, m);
  }

  // Feedback
  async listFeedbacks(beginDateIndex: number, endDateIndex: number, since: Date|undefined, onData: OnMultipleDataFnc<Feedback>, onError: OnErrorFnc, m: FeedbackMapping) {
    const params = new pbfb.FeedbackListFeedbacksParams();
    params.setBeginDate(beginDateIndex);
    params.setEndDate(endDateIndex);
    if (since) params.setSinceDtCreate(unixFromDate(since));
    const req = new pbfb.FeedbackListFeedbacksRequest(); req.setParams(params);
    return grpcStreamOp(Feedback, this.feedbackService, 'listFeedbacks', this.metadata, req, m, onData, onError);
  }

  async getFeedback(id: number, m: FeedbackMapping) {
    const req = new pbfb.FeedbackGetFeedbackRequest();
    req.setFeedbackId(id);
    return grpcUnaryOp(Feedback, this.feedbackService, 'getFeedback', this.metadata, req, m);
  }

  async getFeedbackReservation(id: number, m: ReservationMapping) {
    const req = new pbfb.FeedbackGetReservationRequest();
    req.setReservationId(id);
    return grpcUnaryOp(Reservation, this.feedbackService, 'getReservation', this.metadata, req, m);
  }

  async getFeedbackSettings(m: FeedbackSettingsMapping) {
    const req = new EmptyRequest();
    return grpcUnaryOp(FeedbackSettings, this.feedbackService, 'getSettings', this.metadata, req, m);
  }

  async setFeedbackSettings(settings: FeedbackSettings, m: FeedbackSettingsMapping) {
    const req = new pbfb.FeedbackSetSettingsRequest();
    req.setSettings(settings.toGrpcModel());
    return grpcUnaryOp(FeedbackSettings, this.feedbackService, 'setSettings', this.metadata, req, m);
  }
}

export default new GrpcClient();
