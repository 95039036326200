
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { performAction } from '@/services/vue-utils';
import toast from '@/plugins/toast-plugin/toast';

export default Vue.extend({
  name: 'VerifyAccountStep',
  data() {
    return {
      activationCode: '',
    };
  },
  computed: {
    username(): string {
      return this.$tstore.getters.username ?? 'N/A';
    },
  },
  watch: { },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.focusOtp();
      }, 300);
    });
  },
  methods: {
    focusOtp() {
      (this.$refs.activationCode as any)?.focus();
    },
    input() {
      this.activationCode = this.activationCode?.toUpperCase();
    },
    reset() {
      this.activationCode = '';
      (this.$refs.form as HTMLFormElement).reset();
      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
      this.focusOtp();
    },
    async logout() {
      this.close();
      try {
        await this.$tstore.dispatch('logout');
      } catch (e) {
        console.log('Logout error: ', e);
      }
      this.$vuetify.theme.dark = false;
    },
    async close() {
      this.reset();
      this.$emit('close');
    },
    async submit() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        toast.error(this.$i18n.tc('error.validation_failed'));
        return;
      }

      const ok = await performAction(
        this.$i18n.tc('message.registration_successful', undefined, [this.username]),
        this.$i18n.tc('error.registration_failed'),
        async () => {
          await this.$tstore.dispatch('register', { username: this.username, code: this.activationCode.toUpperCase() });
          await this.$tstore.dispatch('update');
        },
      );

      if (!ok) {
        this.reset();
      }
    },
    async resend() {
      const ok = await performAction(
        this.$i18n.tc('message.registration_code_sent_to_x_please_enter_code', undefined, [this.username]),
        this.$i18n.tc('error.registration_failed'),
        async () => this.$tstore.dispatch('register', { username: this.username }),
      );
    },
  },
});
