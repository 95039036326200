import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    visible: { type: Boolean as PropType<boolean>, required: true },
  },
  data() {
    return {
      dialog: this.visible,
    };
  },
  watch: {
    visible() {
      this.dialog = this.visible;
    },
    dialog() {
      if (this.dialog === false) { this.$emit('close'); }
    },
  },
  mounted() {
    this.dialog = this.visible;
  },
});
