import * as pbm from '@/grpc-pb/campaign_pb';
import * as pbs from '@/grpc-pb/settings_service_pb';

export default class Campaign {
  id: number = 0;

  subdomain?: string;

  constructor(o?: { id?: number, subdomain?: string }) {
    this.id = o?.id ?? 0;
    this.subdomain = o?.subdomain;
  }

  static fromGrpcResponse(r: pbs.CampaignResponse): Campaign {
    const e = r.getCampaign();
    return Campaign.fromGrpcModel(e);
  }

  static fromGrpcModel(o?: pbm.Campaign): Campaign {
    const e = new Campaign();

    e.id = o?.getId() ?? 0;
    e.subdomain = o?.getSubdomain() ?? undefined;

    return e;
  }

  toGrpcModel(): pbm.Campaign {
    const o = new pbm.Campaign();

    o.setId(this.id);
    if (this.subdomain) o.setSubdomain(this.subdomain);

    return o;
  }
}
