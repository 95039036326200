import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { IDTOAccount } from '@/model/Account';
import { IDTOTab } from '@/model/Tab';
import { IDTOUser } from '@/model/User';
import { IDTOEmployee } from '@/model/Employee';
import { IDTOTagNote } from '@/model/TagNote';
import { IDTOLabel } from '@/model/Label';
import { IDTOService } from '@/model/Service';

import { IApi } from '@/model/model-utils';
import { IDTOOpeningHour } from '@/model/OpeningHour';
import { IDTOTabItem } from '@/model/TabItem';
import { IDTODurationRule } from '@/model/DurationRule';
import { IDTOAccountSetting } from '@/model/AccountSettings';
import { IDTODateAndTime } from '@/model/DateAndTime';
import { IDTODayNote } from '@/model/DayNote';
import { IDTOSendAllocation } from '@/model/Allocation';
import { v4 as uuidv4 } from 'uuid';
import { IDTOApp } from '@/model/AppStoreApp';
import { countries } from '@/model/Country';
import { ApiError, ApiErrorCode } from './api-error';
import IDTOReservation from './api-reservations-dto';
import { IUpdateResponse, IUpdate } from './api-update';
import {
  MessengerConvert, IDTOMessengerUpdate, IDTOMessageRule, IDTOMessageRulesUpdate,
} from './api-messenger';
import { AllocationsConvert, IDTOAllocationsUpdate } from './api-allocations';
import { Convert as DashboardConvert, GuestStats } from './api-dashboard';
import { IDTOFloorplan } from './api-floorplan';
import {
  BillingConvert, Product, Customer, Invoice, SetupIntent, PaymentMethod, Country, PaymentIntent,
  SMSProduct,
} from './api-billing';
import { CampaignFilterConvert, IDTOCampaignFilter, IDTOCampaignFilterUpdate } from './api-campaign-filter';
import { BillingConnectConvert, ConnectAccount } from './api-billing-connect';

export interface IHttpClientState {
  token?: string;
  timestamp?: string;
  unixTimestamp?: string;
  accountID?: number;
  fcmToken?: string;
}

export type OnProgressFnc = (event: { loaded: number, total: number }) => void;

const origin = `web_${process.env.VUE_APP_VERSION}`;
const baseURL = process.env.VUE_APP_ENDPOINT_APIV1;
const baseURLv2 = process.env.VUE_APP_ENDPOINT_APIV2;
// const baseURLv2 = 'http://127.0.0.1:8080/api/v2/';
const connectBaseURL = process.env.VUE_APP_ENDPOINT_ETC;

class HttpClient {
  private client = axios.create({
    baseURL,
    responseType: 'json',
    // headers: { 'Content-Type': 'text/plain' },
    headers: { 'Content-Type': 'text/plain', 'gp-origin': origin },
  });

  private clientV2 = axios.create({
    baseURL: baseURLv2,
    responseType: 'json',
    // headers: { 'Content-Type': 'text/plain' },
    headers: { 'Content-Type': 'text/plain', 'gp-origin': origin },
  });

  state: IHttpClientState = {};

  get token() {
    if (!this.state.token) throw new ApiError(ApiErrorCode.missing_token_error);
    return this.state.token;
  }

  get params() {
    if (!this.state.token) throw new ApiError(ApiErrorCode.missing_token_error);

    return {
      token: this.state.token,
      timeStamp: this.state.timestamp ?? undefined,
      unixTimeStamp: this.state.unixTimestamp ?? undefined,
      FCMToken: this.state.fcmToken ?? undefined,
    };
  }

  get clientConfigV2(): AxiosRequestConfig {
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data], headers: {} };
    if (process.env.VUE_APP_DEBUG_AUTH_HEADER === 'true' && this.state.accountID !== undefined) {
      config.headers.Authorization = `AccountID ${this.state.accountID}`;
    } else {
      config.headers.Authorization = `Bearer ${this.token}`;
    }
    return config;
  }

  // eslint-disable-next-line class-methods-use-this
  handleError(response: { warning?: string, smile?: string }): boolean {
    const errorCode = Number(response?.warning);

    if (errorCode) throw new ApiError(errorCode);

    return true;
  }

  handleToken(response: { token?: string }): boolean {
    if (!response.token) throw new ApiError(ApiErrorCode.no_token_error);
    this.state.token = response.token;
    return true;
  }

  async openAccount(
    username: string,
    password: string,
    firstname: string,
    companyName: string,
    phone: string,
    locale?: string,
    promoCode?: string,
  ): Promise<void> {
    let country: Country | undefined;
    try {
      country = await this.getCountry(5000);
    } catch (e) {
      console.log('Country code error: ', e);
    }

    const params = {
      keyToOpen: 't3Jki5hGQ49mlhyOegvWsdewBaTzcBv8F5nvCsddJhKhYuIF',
      username,
      password,
      firstname,
      companyName,
      phone,
      locale,
      countryCode: country?.countryCode || undefined,
      state: country?.region || undefined,
      postalCode: country?.postalCode || undefined,
      timeZone: country?.timeZoneID || undefined,
      promoCode,
    };
    type Response = {
      token?: string,
      warning?: string,
    }
    const response = await this.client.post<Response>('/openAccount/default.asp', params);
    this.handleError(response?.data);
    this.handleToken(response?.data);
  }

  async register(username: string, registrationCode: string): Promise<void> {
    const params = { ...this.params, username, registrationCode };
    type Response = {
      warning?: string,
    }
    const response = await this.client.post<Response>('/register/default.asp', params);
    this.handleError(response?.data);
  }

  async deleteAccount(reason?: string): Promise<void> {
    const params = { ...this.params, reason };
    type Response = {
      warning?: string,
    }
    const response = await this.client.post<Response>('/deleteAccount/default.asp', params);
    this.handleError(response?.data);
  }

  async login(username: string, password: string): Promise<void> {
    const params = { username, password };
    type Response = {
      token?: string,
      warning?: string,
    }
    const response = await this.client.post<Response>('/login/default.asp', params);
    this.handleError(response?.data);
    this.handleToken(response?.data);
  }

  async logout(): Promise<void> {
    const params = { ...this.params };
    type Response = {
      warning?: string,
    }
    const response = await this.client.post<Response>('/logout/default.asp', params);
    this.handleError(response?.data);
  }

  async processUpdateResponse(
    response: AxiosResponse<IUpdateResponse>,
    p?: { retryCount?: number, date?: string },
  ): Promise<IUpdate> {
    //
    // handle smile == no updates
    if (response?.data?.smile) return { isFullUpdate: false };

    // handle full update needed
    const retryCount = p?.retryCount ?? 1;
    if (Number(response?.data?.warning) === ApiErrorCode.full_update_needed && retryCount > 0) {
      console.log('Forced full update');
      this.state.timestamp = undefined;
      this.state.unixTimestamp = undefined;
      return this.getUpdates(p?.date, retryCount - 1);
    }

    // handle warning
    this.handleError(response?.data);

    // handle warning
    const update = response?.data?.update;
    if (!update) throw Error('No update');
    update.isFullUpdate = update.isFullUpdate === true || String(update.isFullUpdate) === 'True';
    if (update.timeStamp) this.state.timestamp = update.timeStamp;
    if (update.unixTimeStamp) this.state.unixTimestamp = update.unixTimeStamp;
    return update;
  }

  async getUpdates(date?: string, retryCount?: number): Promise<IUpdate> {
    const params = { ...this.params, singleReservationDate: date };
    const response = await this.client.post<IUpdateResponse>('/updates/default.asp', params);
    return this.processUpdateResponse(response, { retryCount, date });
  }

  async sendSingleEntity(entity: IApi, url: string): Promise<IUpdate> {
    const params = { ...this.params, ...entity };
    const response = await this.client.post<IUpdateResponse>(url, params);
    // console.log('sendSingleEntity: ', url, params, response);
    return this.processUpdateResponse(response);
  }

  async sendReservation(reservation: IDTOReservation): Promise<IUpdate> {
    return this.sendSingleEntity(reservation, '/reservations/default.asp');
  }

  async sendAccount(account: IDTOAccount): Promise<IUpdate> {
    return this.sendSingleEntity(account, '/account/default.asp');
  }

  async sendAccountSettings(accountSettings: IDTOAccountSetting): Promise<IUpdate> {
    return this.sendSingleEntity(accountSettings, '/accountSettings/default.asp');
  }

  async sendUser(user: IDTOUser): Promise<IUpdate> {
    return this.sendSingleEntity(user, '/users/default.asp');
  }

  async sendDurationRule(rule: IDTODurationRule): Promise<IUpdate> {
    return this.sendSingleEntity(rule, '/durationRules/default.asp');
  }

  async sendDateAndTime(dateAndTime: IDTODateAndTime): Promise<IUpdate> {
    return this.sendSingleEntity(dateAndTime, '/availability/default.asp');
  }

  async sendDayNote(dayNote: IDTODayNote): Promise<IUpdate> {
    return this.sendSingleEntity(dayNote, '/dayNotes/default.asp');
  }

  async sendListEntities(entities: IApi[], fname: string, url: string, extraParams?: object): Promise<IUpdate> {
    const params = { ...this.params, ...extraParams, [fname]: entities };
    const response = await this.client.post<IUpdateResponse>(url, params);
    return this.processUpdateResponse(response);
  }

  async sendTabs(tabs: IDTOTab[]): Promise<IUpdate> {
    return this.sendListEntities(tabs, 'tabs', '/tabs/default.asp');
  }

  async sendTabItems(tabId: number, tabItems: IDTOTabItem[]): Promise<IUpdate> {
    return this.sendListEntities(tabItems, 'tabItems', '/tabItems/default.asp', { tabId });
  }

  async sendEmployees(employees: IDTOEmployee[]): Promise<IUpdate> {
    return this.sendListEntities(employees, 'employees', '/employees/default.asp');
  }

  async sendTagNotes(tags: IDTOTagNote[]): Promise<IUpdate> {
    return this.sendListEntities(tags, 'tagNotes', '/tagNotes/default.asp');
  }

  async sendLabels(labels: IDTOLabel[]): Promise<IUpdate> {
    return this.sendListEntities(labels, 'labels', '/labels/default.asp');
  }

  async sendServices(services: IDTOService[]): Promise<IUpdate> {
    return this.sendListEntities(services, 'services', '/services/default.asp');
  }

  // eslint-disable-next-line class-methods-use-this
  async sendOpeningHours(openingHours: IDTOOpeningHour[]): Promise<IUpdate> {
    return this.sendListEntities(openingHours, 'openingHours', '/openingHours/default.asp');
  }

  async changePassword(username: string, password: string, newPassword: string): Promise<IUpdate> {
    const params = {
      ...this.params, username, password, newPassword,
    };
    const response = await this.client.post<IUpdateResponse>('/password/default.asp', params);
    return this.processUpdateResponse(response);
  }

  async resetPassword(username: string): Promise<IUpdate> {
    const params = {
      username, keyToOpen: 't3Jki5hGQ49mlhyOegvWsdewBaTzcBv8F5nvCsddJhKhYuIF',
    };
    const response = await this.client.post<IUpdateResponse>('/resetPassword/default.asp', params);
    return this.processUpdateResponse(response);
  }

  async search(query: string): Promise<IUpdate> {
    const params = {
      ...this.params, q: query,
    };
    const response = await this.client.post<IUpdateResponse>('/search/default.asp', params);
    return this.processUpdateResponse(response);
  }

  async suggestTables(reservation: IDTOReservation): Promise<number[]> {
    const {
      reservationId, tabId, partySize, dtBegin, dtEnd,
    } = reservation;
    const params = {
      ...this.params, reservationId, tabId, partySize, dtBegin, dtEnd,
    };
    type Response = {
      tabItems?: number[],
      warning?: string,
    }

    const response = await this.client.post<Response>('/tableSuggest/default.asp', params);
    this.handleError(response?.data);
    return response?.data.tabItems ?? [];
  }

  async sendFloorplan(params: IDTOFloorplan): Promise<IUpdate> {
    const response = await this.client.post<IUpdateResponse>('/floorplan/default.asp', params);
    return this.processUpdateResponse(response);
  }

  async loadAllocations(tabId: number): Promise<IDTOAllocationsUpdate> {
    const params = { ...this.params, tabId };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/allocations/default.asp', params, config);
    const allocationsResponse = AllocationsConvert.toAllocationsResponse(response?.data);

    // handle warning
    this.handleError(allocationsResponse);

    if (!allocationsResponse.update) return { allocations: [] };
    return allocationsResponse.update;
  }

  async sendAllocations(tabId: number, partySize: number, allocations: IDTOSendAllocation[]):
  Promise<IDTOAllocationsUpdate> {
    const params = {
      ...this.params, allocations, tabId, partySize,
    };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/allocations/default.asp', params, config);
    const allocationsResponse = AllocationsConvert.toAllocationsResponse(response?.data);

    // handle warning
    this.handleError(allocationsResponse);

    if (!allocationsResponse.update) return { allocations: [] };
    return allocationsResponse.update;
  }

  // message center
  async loadMessageCenter(): Promise<IDTOMessengerUpdate> {
    const params = { ...this.params };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/messenger/default.asp', params, config);
    const messengerResponse = MessengerConvert.toMessangerResponse(response?.data);

    // handle warning
    this.handleError(messengerResponse);

    if (!messengerResponse.update) throw new ApiError(ApiErrorCode.no_updates);
    return messengerResponse.update;
  }

  async sendMessageRule(rule: IDTOMessageRule): Promise<IDTOMessageRulesUpdate> {
    const params = { ...this.params, ...rule };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/messageRules/default.asp', params, config);
    const messageRulesResponse = MessengerConvert.toMessageRulesResponse(response?.data);

    // handle warning
    this.handleError(messageRulesResponse);

    if (!messageRulesResponse.update) throw new ApiError(ApiErrorCode.no_updates);
    return messageRulesResponse.update;
  }

  // apps
  async installApp(app: IDTOApp): Promise<IUpdate> {
    return this.sendSingleEntity(app, '/apps/default.asp');
  }

  // campaign filter
  async loadCampaignFilter(): Promise<IDTOCampaignFilterUpdate> {
    const params = { ...this.params };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/campaignFilters/default.asp', params, config);
    const capaignFilterResponse = CampaignFilterConvert.toCampaignFilterResponse(response?.data);

    // handle warning
    this.handleError(capaignFilterResponse);

    if (!capaignFilterResponse.update) throw new ApiError(ApiErrorCode.no_updates);
    return capaignFilterResponse.update;
  }

  async sendCampaignFilter(filter: IDTOCampaignFilter): Promise<IDTOCampaignFilterUpdate> {
    const params = { ...this.params, campaignFilters: { ...filter } };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/campaignFilters/default.asp', params, config);

    const capaignFilterResponse = CampaignFilterConvert.toCampaignFilterResponse(response?.data);

    // handle warning
    this.handleError(capaignFilterResponse);

    if (!capaignFilterResponse.update) throw new ApiError(ApiErrorCode.no_updates);
    return capaignFilterResponse.update;
  }

  // v2 api
  async getStats(tabId: number | undefined, dateIndexFrom: number, dateIndexTo: number): Promise<GuestStats> {
    const params = {
      ...this.params, tabId, dateIndexFrom, dateIndexTo,
    };
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data] };
    const response = await this.clientV2.post<string>('/dashboard', params, config);
    const guestsStats = DashboardConvert.toGuestStats(response?.data);

    return guestsStats;
  }

  // file upload
  async uploadFile(path: string, file: File, accessKey: string, onProgressFnc?: OnProgressFnc): Promise<string> {
    const fileName = `${uuidv4()}/${file.name.replace(/\s\s+/g, ' ')}`;
    const apiFile = new File([file], fileName, { type: file.type, lastModified: file.lastModified });

    const formData = new FormData();
    formData.append('file', apiFile);

    const config: AxiosRequestConfig = {
      responseType: 'json',
      headers: { Authorization: `Bearer ${this.token}`, 'Content-Type': 'multipart/form-data' },
      onUploadProgress: onProgressFnc,
    };

    const response = await this.clientV2.post<{ imageUrl: string }>(path, formData, config);
    return response.data.imageUrl;
  }

  // image file upload
  async uploadImageFile(file: File, accessKey: string, onProgressFnc?: OnProgressFnc): Promise<string> {
    return this.uploadFile('uploadImage', file, accessKey, onProgressFnc);
  }

  // attachment file upload
  async uploadAttachmentFile(file: File, accessKey: string, onProgressFnc?: OnProgressFnc): Promise<string> {
    return this.uploadFile('uploadAttachment', file, accessKey, onProgressFnc);
  }

  // validate email
  async validateEmailAddress(emailAddress: string): Promise<boolean> {
    const params = { emailAddress };
    const config: AxiosRequestConfig = {
      responseType: 'json',
      headers: { Authorization: `Bearer ${this.token}` },
    };
    const response = await this.clientV2.post<{ valid?: boolean }>('/validateEmailAddress', params, config);
    return response.data.valid ?? false;
  }

  // export
  async requestExport(exportType: string, exportPeriod: number, exportFormat: string): Promise<Blob> {
    const params = {
      ...this.params,
      exportType,
      exportPeriod,
      exportFormat,
    };

    const config: AxiosRequestConfig = { responseType: 'blob', transformResponse: [(data) => data] };
    const response = await this.client.post<string>('/export/default.asp', params, config);
    const type = response.headers['content-type'] ?? 'text/csv';
    const blob = new Blob([response.data], { type });
    return blob;
  }

  // billing
  async getBilling(p?: { createdAfter?: number, invoicesLimit?: number })
    : Promise<{
      products: Product[],
      customer: Customer | null,
      invoices: Invoice[],
      paymentMethods: PaymentMethod[],
      smsProduct: SMSProduct | null,
      country: Country | null,
    }> {
    const { createdAfter, invoicesLimit: limit } = { ...p };
    const responses = await axios.all([
      this.clientV2.post<string>('/billing/getProducts', {}, this.clientConfigV2),
      this.clientV2.post<string>('/billing/getAccount', {}, this.clientConfigV2),
      this.clientV2.post<string>('/billing/getInvoices', { createdAfter, limit }, this.clientConfigV2),
      this.clientV2.post<string>('/billing/getPaymentMethods', {}, this.clientConfigV2),
      this.clientV2.post<string>('/billing/getSMSProduct', {}, this.clientConfigV2),
      this.clientV2.post<string>('/billing/country', {}, this.clientConfigV2),
    ]);
    console.log('getBilling: ', responses);
    const products = BillingConvert.toProducts(responses[0].data);
    const customer = BillingConvert.toCustomer(responses[1].data);
    const invoices = BillingConvert.toInvoices(responses[2].data);
    const paymentMethods = BillingConvert.toPaymentMethods(responses[3].data);
    const smsProduct = BillingConvert.toSMSProduct(responses[4].data);
    let country: Country | null = BillingConvert.toCountry(responses[5].data);

    if (countries.find((c) => c.code === country?.countryCode) === undefined) {
      country = null;
    }

    return {
      products, customer, invoices, paymentMethods, smsProduct, country,
    };
  }

  async getProducts(): Promise<Product[]> {
    const params = {};
    const response = await this.clientV2.post<string>('/billing/getProducts', params, this.clientConfigV2);
    const products = BillingConvert.toProducts(response?.data);
    return products;
  }

  async sendCustomerRequest(url: string, params?: any): Promise<Customer | null> {
    const response = await this.clientV2.post<string>(url, params, this.clientConfigV2);
    const customer = BillingConvert.toCustomer(response?.data);
    console.log('customer: ', customer);
    return customer;
  }

  async getCustomer(): Promise<Customer | null> {
    return this.sendCustomerRequest('/billing/getAccount');
  }

  async createCustomer(customer: Customer): Promise<Customer | null> {
    return this.sendCustomerRequest('/billing/createAccount', BillingConvert.customerToDto(customer));
  }

  async updateCustomer(customer: Customer): Promise<Customer | null> {
    return this.sendCustomerRequest('/billing/updateAccount', BillingConvert.customerToDto(customer));
  }

  async createSubscription(p: {
    priceID?: string, paymentMethodTypes?: string[], idempotencyKey?: string, deactivatePaymentMethod?: boolean,
  }) : Promise<Customer | null> {
    const {
      priceID, paymentMethodTypes, idempotencyKey, deactivatePaymentMethod,
    } = { ...p };

    return this.sendCustomerRequest('/billing/createSubscription', {
      priceID, paymentMethodTypes, idempotencyKey, deactivatePaymentMethod,
    });
  }

  async updateSubscription(p: {
    priceID?: string, paymentMethodTypes?: string[], idempotencyKey?: string,
    prorationDate?: number,
  }) : Promise<Customer | null> {
    const {
      priceID, paymentMethodTypes, idempotencyKey, prorationDate,
    } = { ...p };

    return this.sendCustomerRequest('/billing/updateSubscription', {
      priceID, paymentMethodTypes, idempotencyKey, prorationDate,
    });
  }

  async cancelSubscription(): Promise<Customer | null> {
    return this.sendCustomerRequest('/billing/cancelSubscription');
  }

  async deleteSubscription(): Promise<Customer | null> {
    return this.sendCustomerRequest('/billing/deleteSubscription', {});
  }

  async getInvoices(p?: { createdAfter?: number, limit?: number }): Promise<Invoice[]> {
    const { createdAfter, limit } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/getInvoices',
      { createdAfter, limit },

      this.clientConfigV2,
    );
    const invoices = BillingConvert.toInvoices(response?.data);
    return invoices;
  }

  async previewSubscription(p: { priceID?: string, idempotencyKey?: string }): Promise<Invoice> {
    const { priceID } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/previewSubscriptionInvoice',
      { priceID },

      this.clientConfigV2,
    );
    const invoice = BillingConvert.toInvoice(response?.data);
    console.log('previewSubscription: ', invoice);
    return invoice;
  }

  async getPaymentMethod(paymentMethodID: string): Promise<PaymentMethod|null> {
    const params = { paymentMethodID };
    const response = await this.clientV2.post<string>('/billing/getPaymentMethod', params, this.clientConfigV2);
    const method = BillingConvert.toPaymentMethod(response?.data);
    return method;
  }

  async getPaymentMethods(): Promise<PaymentMethod[]> {
    const params = {};
    const response = await this.clientV2.post<string>('/billing/getPaymentMethods', params, this.clientConfigV2);
    const methods = BillingConvert.toPaymentMethods(response?.data);
    return methods;
  }

  async addPaymentMethod(): Promise<SetupIntent | null> {
    const params = {};
    const response = await this.clientV2.post<string>('/billing/addPaymentMethod', params, this.clientConfigV2);
    const setupIntent = BillingConvert.toSetupIntent(response?.data);
    return setupIntent;
  }

  async preparePayment(paymentIntentID: string): Promise<PaymentIntent | null> {
    const params = { paymentIntentID };
    const response = await this.clientV2.post<string>('/billing/preparePayment', params, this.clientConfigV2);
    const paymentIntent = BillingConvert.toPaymentIntent(response?.data);
    return paymentIntent;
  }

  async deletePaymentMethod(paymentMethodID: string): Promise<void> {
    const params = { paymentMethodID };
    const response = await this.clientV2.post<string>('/billing/deletePaymentMethod', params, this.clientConfigV2);
  }

  async activatePaymentMethod(paymentMethodID?: string): Promise<Customer | null> {
    return this.sendCustomerRequest('/billing/activatePaymentMethod', { paymentMethodID });
  }

  async finishPaymentMethod(p: { intentID: string, activate?: boolean }): Promise<SetupIntent|null> {
    const { intentID, activate } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/finishPaymentMethod',
      { intentID, activate },
      this.clientConfigV2,
    );
    const intent = BillingConvert.toSetupIntent(response?.data);
    return intent;
  }

  // country
  async getCountry(timeout?: number): Promise<Country> {
    const config: AxiosRequestConfig = { responseType: 'text', transformResponse: [(data) => data], headers: {} };
    if (timeout) config.timeout = timeout;

    const response = await this.clientV2.post<string>('/billing/country', {}, config);
    const country = BillingConvert.toCountry(response?.data);

    if (countries.find((c) => c.code === country.countryCode) === undefined) {
      throw new Error(`Unsupported country code ${country.countryCode}`);
    }

    return country;
  }

  async getCountryCode(): Promise<string | null> {
    const country = await this.getCountry();
    return country.countryCode || null;
  }

  // payments
  async createPaymentInvoice(p: { priceIDs: string[] }): Promise<Invoice> {
    const { priceIDs } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/createPaymentInvoice',
      { priceIDs },
      this.clientConfigV2,
    );
    const invoice = BillingConvert.toInvoice(response?.data);
    return invoice;
  }

  async finalizePaymentInvoice(
    p: { invoiceID: string, paymentMethodTypes?: string[], confirm?: boolean },
  ): Promise<Invoice> {
    const { invoiceID, paymentMethodTypes, confirm } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/finalizePaymentInvoice',
      { invoiceID, paymentMethodTypes, confirm },
      this.clientConfigV2,
    );
    const invoice = BillingConvert.toInvoice(response?.data);
    return invoice;
  }

  async finishPaymentInvoice(p: { invoiceID: string, activate?: boolean }): Promise<Invoice> {
    const { invoiceID, activate } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/finishPaymentInvoice',
      { invoiceID, activate },
      this.clientConfigV2,
    );
    const invoice = BillingConvert.toInvoice(response?.data);
    return invoice;
  }

  async deletePaymentInvoice(p: { invoiceID: string }): Promise<Invoice> {
    const { invoiceID } = { ...p };
    const response = await this.clientV2.post<string>(
      '/billing/deletePaymentInvoice',
      { invoiceID },
      this.clientConfigV2,
    );
    const invoice = BillingConvert.toInvoice(response?.data);
    return invoice;
  }

  async getInvoice(p: { invoiceID: string }): Promise<Invoice> {
    const { invoiceID } = { ...p };
    const response = await this.clientV2.post<string>('/billing/getInvoice', { invoiceID }, this.clientConfigV2);
    const invoice = BillingConvert.toInvoice(response?.data);
    return invoice;
  }

  // sms info
  async getSMSProduct(): Promise<SMSProduct | null> {
    const response = await this.clientV2.post<string>('/billing/getSMSProduct', {}, this.clientConfigV2);
    const smsProduct = BillingConvert.toSMSProduct(response?.data);
    console.log('smsProduct: ', smsProduct);
    return smsProduct;
  }

  async createSMSProduct(params: SMSProduct): Promise<SMSProduct | null> {
    const response = await this.clientV2.post<string>('/billing/createSMSProduct', params, this.clientConfigV2);
    const smsProduct = BillingConvert.toSMSProduct(response?.data);
    console.log('smsProduct: ', smsProduct);
    return smsProduct;
  }

  async updateSMSProduct(params: SMSProduct): Promise<SMSProduct | null> {
    const response = await this.clientV2.post<string>('/billing/updateSMSProduct', params, this.clientConfigV2);
    const smsProduct = BillingConvert.toSMSProduct(response?.data);
    console.log('smsProduct: ', smsProduct);
    return smsProduct;
  }

  // billing connect
  async sendConnectAccountRequest(url: string, params?: any): Promise<ConnectAccount | null> {
    const response = await this.clientV2.post<string>(url, params, this.clientConfigV2);
    const account = BillingConnectConvert.toAccount(response?.data);
    console.log('account: ', account);
    return account;
  }

  async getConnectAccount(): Promise<ConnectAccount | null> {
    return this.sendConnectAccountRequest('/billing-connect/getAccount');
  }

  async createConnectAccount(account: ConnectAccount): Promise<ConnectAccount | null> {
    return this.sendConnectAccountRequest(
      '/billing-connect/createAccount',
      BillingConnectConvert.accountToDto(account),
    );
  }
}

export default new HttpClient();
