

import { h } from 'vue';
import { VDivider, VCol } from 'vuetify/lib';

export default {
  setup() {
    return () => h(VCol, { attrs: { cols: '12' }, class: 'py-0' }, [
      h(VDivider),
    ]);
  },
};

