
/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import Vue, { PropType } from 'vue';
import 'echarts';
import VChart from 'vue-echarts';

import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';
import DashboardAvailability, {
  DashboardAvailabilityFlag, DashboardChartData, DashboardLimitData, DashboardLimitReason,
} from '@/grpc-api/model/dashboard-availability';
import { DAY_TIME_SLOTS_COUNT, MORNING_TIME_SLOTS_COUNT, niceTimeTextFromSlot } from '@/services/time-utils';
import Tab from '@/model/Tab';

export default Vue.extend({
  name: 'AvailablePartySizeCard',
  components: {
    VChart,
  },
  props: {
    maxPartySize: { type: Number as PropType<number>, required: false, default: 12 },
    dayAvailability: { type: Object as PropType<DashboardAvailability|null>, required: false, default: null },
    dayLimits: { type: Object as PropType<Array<DashboardLimitData>|null>, required: false, default: null },
    disabled: { type: Boolean as PropType<boolean>, required: false, default: true },
    tabs: { type: Array as PropType<Array<Tab>>, required: false, default: () => [] },
  },
  data() {
    return {
      option: (this as any).createDataset(),
      hours: [] as string[],
      partySizes: [] as string[],
      data: [] as [number, number, number, any?][],
      dataDetails: [] as {p:number, c:number, d:number, xs:number}[][],
      limitDetails: [] as { r: DashboardLimitReason, p?: number}[],
      themeDark,
      themeLight,
    };
  },
  computed: {
    darkMode(): boolean {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    dayAvailability() {
      this.updateDataset();
    },
    dayLimits() {
      this.updateDataset();
    },
    tabs() {
      this.updateDataset();
    },
    disabled() {
      this.updateDataset();
    },
    darkMode() {
      this.updateDataset();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateDataset();
    });
  },
  methods: {
    click(event: any) {
      this.$emit('grid-clicked', { timeSlot: event.data[0], partySize: event.data[1] + 1 });
    },
    formatPoint(p: any) {
      const wrapfnc = (line: string, detail?: string) => {
        let body = line;
        if (detail) body += `<hr class="my-2 v-divider theme--light" /><p class="text-body-2 my-0">${detail}</p>`;
        return `<div style='max-width: 300px; white-space: normal;'>${body}</div>`;
      };

      const states = [
        this.$i18n.t('label.available'),
        this.$i18n.t('label.mixed'),
        this.$i18n.t('label.unavailable'),
        this.$i18n.t('label.unavailable'),
        this.$i18n.t('label.closed'),
      ];
      const slot = p.value[0] + MORNING_TIME_SLOTS_COUNT;
      const isPlus = p.value[1] + 1 === this.maxPartySize;
      const partySize = isPlus ? '12+' : p.value[1] + 1;

      const line = `<span class="font-weight-bold">${p.name}</span><br/> ${p.marker} ${p.seriesName} ${partySize}: <span class="font-weight-medium">${states[p.value[2]]}</span><br/>`;
      let detail: string|undefined;

      const lr = this.limitDetails[p.dataIndex];
      if (
        (p.value[2] === DashboardAvailabilityFlag.NO_CLOSED || p.value[2] === DashboardAvailabilityFlag.NO_LIMITED)
          && lr && lr.r !== DashboardLimitReason.None) {
        detail = this.$i18n.t(`code.dashboard_limit_reason.${lr.r}`, [lr.p || '-']) as string;
      } else if (p.value[2] === DashboardAvailabilityFlag.NO_ALLOCATIONS) {
        detail = this.$i18n.t('code.dashboard_limit_reason.no_allocations') as string;
      } else if (p.value[2] === DashboardAvailabilityFlag.MIXED) {
        const prs = this.dataDetails[p.dataIndex];
        if (prs && prs.length > 0) {
          const maxc = Math.max(...prs.map((pr) => pr.c ?? 0));
          const slotc = Math.ceil(maxc / 15);
          const timeText = niceTimeTextFromSlot(slot + slotc);
          const durationText = this.$localized.durationFromSeconds(maxc * 60);
          detail = this.$i18n.t('code.dashboard_limit_reason.limited_duration', [timeText, durationText]) as string;
        }
      }

      if (process.env.VUE_APP_SHOW_DASHBOARD_DETAILS === 'true') {
        const prs = this.dataDetails[p.dataIndex];
        const extra = prs.map((pr) => {
          const tab = this.tabs.find((t) => t.id === pr.xs)?.name;
          return `${tab}: p:${pr.p} c:${pr.c ?? '-'} d:${pr.d}`;
        }).join(',<br/>');

        if (detail) detail = [detail, extra].join('<br/>');
        else detail = extra;
      }

      return wrapfnc(line, detail);
    },
    createDataset() {
      const i18n = this.$i18n;
      return {
        tooltip: {
          formatter: this.formatPoint,
        },
        grid: {
          left: '32px',
          right: '16px',
          top: '16px',
        },
        xAxis: {
          type: 'category',
          data: this.hours,
          axisLabel: {
            interval: 3,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: this.partySizes,
          splitArea: {
            show: false,
          },
        },
        visualMap: {
          type: 'piecewise',
          inRange: {
            color: [
              '#00A26F', // yes
              '#ffc24e', // mixed
              '#FF8262', // no - limited
              '#FF002B', // no - no allocations
              this.darkMode ? '#3F3F49' : '#EAEAEB', // no - closed
            ],
          },
          min: -0.5,
          max: 4.5,
          splitNumber: 5,
          showLabel: true,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '5%',
          formatter(val1: any, val2: any) {
            console.log('formatter: ', val1, val2);
            if (val1 === DashboardAvailabilityFlag.YES - 0.5) { return i18n.t('label.available'); }
            if (val1 === DashboardAvailabilityFlag.MIXED - 0.5) { return i18n.t('label.mixed'); }
            if (val1 === DashboardAvailabilityFlag.NO_ALLOCATIONS - 0.5) { return i18n.t('label.unavailable_no_allocations'); }
            if (val1 === DashboardAvailabilityFlag.NO_LIMITED - 0.5) { return i18n.t('label.unavailable_limited'); }
            if (val1 === DashboardAvailabilityFlag.NO_CLOSED - 0.5) { return i18n.t('label.closed'); }
            return 'N/A';
          },
          hoverLink: false,
        },
        series: [
          {
            itemStyle: {
              borderWidth: 2,
              borderColor: this.darkMode ? '#1E1E2B' : 'white',
            },
            name: this.$i18n.t('label.party_size'),
            type: 'heatmap',
            data: this.data,
            selectedMode: false,
            emphasis: {
              itemStyle: {
                shadowBlur: 2,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    createHours(): string[] {
      return [...new Array(DAY_TIME_SLOTS_COUNT - MORNING_TIME_SLOTS_COUNT)].map((_, i) => {
        const si = MORNING_TIME_SLOTS_COUNT + i;
        const tm = niceTimeTextFromSlot(si);
        return tm;
      });
    },
    createPartySizes(): string[] {
      return [...new Array(this.maxPartySize)].map(
        (_, i) => (i + 1 === this.maxPartySize ? `${i + 1}+` : `${i + 1}`),
      );
    },
    createData(): DashboardChartData[] {
      if (this.disabled || !this.dayAvailability || !this.dayLimits) return [];

      const tabIds = this.tabs.map((t) => t.id);
      const data = this.dayAvailability.toChartData(tabIds, this.dayLimits, this.maxPartySize);
      return data;
    },
    updateDataset() {
      this.hours = this.createHours();
      this.partySizes = this.createPartySizes();
      const data = this.createData();
      // console.log('createData: ', data);
      this.data = data.map((a) => [a.si, a.ps - 1, a.flag]);
      this.dataDetails = data.map((a) => a.prs);
      this.limitDetails = data.map((a) => ({ r: a.lr, p: a.lrp }));
      this.option = this.createDataset();
    },
  },
});

