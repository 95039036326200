/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class AnnoncerApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.annoncer', {
      categories: [GstplnAppCategory.Integrations],
      configComponentImportFnc: () => import('@/components/apps/annoncer/SettingsDialog.vue'),
    });
  }
}
