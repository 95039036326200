import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import store from '@/store/store';
import { firebaseAppCfg, fcmVapidKey } from '@/services/fcm-app-cfg';

export default function requestFcmNotificationPermission() {
  const firebase = initializeApp(firebaseAppCfg);
  const messaging = getMessaging(firebase);
  navigator.serviceWorker.getRegistration().then((r) => {
    getToken(
      messaging,
      {
        vapidKey: fcmVapidKey,
        serviceWorkerRegistration: r,
      },
    ).then((currentToken: string) => {
      if (currentToken) {
        store.commit('SET_FCM_TOKEN', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err: Error) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  });
}
