/**
 * @fileoverview gRPC-Web generated client stub for grpc.api.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.1
// source: settings_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var empty_pb = require('./empty_pb.js')

var campaign_pb = require('./campaign_pb.js')

var currency_pb = require('./currency_pb.js')
const proto = {};
proto.grpc = {};
proto.grpc.api = {};
proto.grpc.api.v1 = require('./settings_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.SettingsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.SettingsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.EmptyRequest,
 *   !proto.grpc.api.v1.CampaignResponse>}
 */
const methodDescriptor_SettingsService_getDefaultCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.SettingsService/getDefaultCampaign',
  grpc.web.MethodType.UNARY,
  empty_pb.EmptyRequest,
  proto.grpc.api.v1.CampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.CampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.CampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.CampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.SettingsServiceClient.prototype.getDefaultCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.SettingsService/getDefaultCampaign',
      request,
      metadata || {},
      methodDescriptor_SettingsService_getDefaultCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.CampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.SettingsServicePromiseClient.prototype.getDefaultCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.SettingsService/getDefaultCampaign',
      request,
      metadata || {},
      methodDescriptor_SettingsService_getDefaultCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.CampaignRequest,
 *   !proto.grpc.api.v1.EmptyResponse>}
 */
const methodDescriptor_SettingsService_testDefaultCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.SettingsService/testDefaultCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.CampaignRequest,
  empty_pb.EmptyResponse,
  /**
   * @param {!proto.grpc.api.v1.CampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  empty_pb.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.CampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.SettingsServiceClient.prototype.testDefaultCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.SettingsService/testDefaultCampaign',
      request,
      metadata || {},
      methodDescriptor_SettingsService_testDefaultCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.CampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.SettingsServicePromiseClient.prototype.testDefaultCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.SettingsService/testDefaultCampaign',
      request,
      metadata || {},
      methodDescriptor_SettingsService_testDefaultCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.CampaignRequest,
 *   !proto.grpc.api.v1.CampaignResponse>}
 */
const methodDescriptor_SettingsService_updateDefaultCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.SettingsService/updateDefaultCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.CampaignRequest,
  proto.grpc.api.v1.CampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.CampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.CampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.CampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.CampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.CampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.SettingsServiceClient.prototype.updateDefaultCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.SettingsService/updateDefaultCampaign',
      request,
      metadata || {},
      methodDescriptor_SettingsService_updateDefaultCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.CampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.CampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.SettingsServicePromiseClient.prototype.updateDefaultCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.SettingsService/updateDefaultCampaign',
      request,
      metadata || {},
      methodDescriptor_SettingsService_updateDefaultCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.EmptyRequest,
 *   !proto.grpc.api.v1.CurrencyResponse>}
 */
const methodDescriptor_SettingsService_listCurrencies = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.SettingsService/listCurrencies',
  grpc.web.MethodType.SERVER_STREAMING,
  empty_pb.EmptyRequest,
  proto.grpc.api.v1.CurrencyResponse,
  /**
   * @param {!proto.grpc.api.v1.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.CurrencyResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.CurrencyResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.SettingsServiceClient.prototype.listCurrencies =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.SettingsService/listCurrencies',
      request,
      metadata || {},
      methodDescriptor_SettingsService_listCurrencies);
};


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.CurrencyResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.SettingsServicePromiseClient.prototype.listCurrencies =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.SettingsService/listCurrencies',
      request,
      metadata || {},
      methodDescriptor_SettingsService_listCurrencies);
};


module.exports = proto.grpc.api.v1;

