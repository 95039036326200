/* eslint-disable no-param-reassign */
/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import i18n from '@/plugins/i18n';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import {
  dateByAddingDays, dateByAddingMonths, dateByAddingYears,
  dateFlooredToDate, dateFlooredToMonth, dateFlooredToWeek, dateFlooredToYear, areDatesEqual, daysInBetweenDates,
} from './time-utils';

export enum DateRangePeriod {
  Custom = 'custom',
  CustomMonth = 'custom_month',
  CustomYear = 'custom_year',

  Separator = 'separator',

  Today = 'today',
  Yesterday = 'yesterday',
  Tomorrow = 'tomorrow',
  ThisWeek = 'this_week',
  LastWeek = 'last_week',
  NextWeek = 'next_week',
  ThisMonth = 'this_month',
  LastMonth = 'last_month',
  NextMonth = 'next_month',
  Last30Days = 'last_30_days',
  Next30Days = 'next_30_days',
  Last90Days = 'last_90_days',
  Next90Days = 'next_90_days',
  ThisYear = 'this_year',
  LastYear = 'last_year',
  NextYear = 'next_year',
}

export const DateRangePeriods = [
  DateRangePeriod.Custom,
  DateRangePeriod.Separator, // separator
  // DateRangePeriod.Today,
  // DateRangePeriod.Yesterday,
  // DateRangePeriod.Tomorrow,
  // DateRangePeriod.Separator, // separator
  DateRangePeriod.ThisWeek,
  DateRangePeriod.LastWeek,
  DateRangePeriod.NextWeek,
  DateRangePeriod.Separator, // separator
  DateRangePeriod.ThisMonth,
  DateRangePeriod.LastMonth,
  DateRangePeriod.NextMonth,
  DateRangePeriod.Separator, // separator
  // DateRangePeriod.Last30Days,
  // DateRangePeriod.Next30Days,
  // DateRangePeriod.Last90Days,
  // DateRangePeriod.Next90Days,
  // DateRangePeriod.Separator, // separator
  DateRangePeriod.ThisYear,
  DateRangePeriod.LastYear,
  DateRangePeriod.NextYear,
];

export const DateRangePeriodsPast = [
  DateRangePeriod.Custom,
  DateRangePeriod.Separator, // separator
  DateRangePeriod.Last30Days,
  DateRangePeriod.Last90Days,
  DateRangePeriod.Separator, // separator
  // DateRangePeriod.Today,
  // DateRangePeriod.Yesterday,
  // DateRangePeriod.Separator, // separator
  // DateRangePeriod.ThisWeek,
  // DateRangePeriod.LastWeek,
  DateRangePeriod.Separator, // separator
  DateRangePeriod.ThisMonth,
  DateRangePeriod.LastMonth,
  DateRangePeriod.Separator, // separator
  DateRangePeriod.ThisYear,
  DateRangePeriod.LastYear,
];

const SpecialDateRangePeriods = [
  DateRangePeriod.Custom,
  DateRangePeriod.CustomMonth,
  DateRangePeriod.CustomYear,
  DateRangePeriod.Separator,
];

export interface DateRangePickerItem {
  divider?: boolean,
  value?: DateRangePeriod,
  text?: String,
  disabled?: boolean,
}

export function createPickerItems(periods: DateRangePeriod[]): DateRangePickerItem[] {
  return periods.map((p) => {
    if (p === DateRangePeriod.Separator) return { divider: true };
    return { value: p, text: i18n.t(`code.date_range.${p}`) as string, disabled: p === DateRangePeriod.Custom };
  });
}
export interface DateRange {
  period: DateRangePeriod,
  firstDayOfWeek: number;
  beginDate: Date,
  endDate: Date,
  previousBeginDate?: Date,
  previousEndDate?: Date,
}

export function dateRangeFromPeriod(period: DateRangePeriod = DateRangePeriod.ThisMonth, firstDayOfWeek: number = 0): DateRange {
  // eslint-disable-next-line no-use-before-define
  const range = dateRangeDatesFromPeriod(period, firstDayOfWeek);
  return ({
    period,
    firstDayOfWeek,
    beginDate: range.begin,
    endDate: range.end,
  });
}

export function areDateRangesEqual(dr1: DateRange, dr2: DateRange): boolean {
  return dr1.period === dr2.period
    && areDatesEqual(dr1.beginDate, dr2.beginDate)
    && areDatesEqual(dr1.endDate, dr2.endDate);
}

function formattedDateInterval(begin: Date, end: Date): string {
  const now = new Date();

  // full months
  if (areDatesEqual(begin, dateFlooredToMonth(begin)) && areDatesEqual(dateByAddingDays(end, 1), dateByAddingMonths(dateFlooredToMonth(end), 1))) {
    // same month and year -> single month and year
    if (begin.getMonth() === end.getMonth() && begin.getFullYear() === end.getFullYear()) { return localized.monthYearText(begin)!; }

    // same year -> year at end only
    if (begin.getFullYear() === end.getFullYear()) { return `${localized.monthText(begin)!} - ${localized.monthYearText(end)!}`; }

    // different year -> year at begin and end
    return `${localized.monthYearText(begin)!} - ${localized.monthYearText(end)!}`;
  }

  // single day (display weekday)
  if (areDatesEqual(begin, end)) {
    // current year -> no year
    if (begin.getFullYear() === now.getFullYear()) {
      return localized.shortDayMonthText(begin)!;
    }

    // not current year
    return localized.shortDateText(begin)!;
  }

  // different days (no weekdays)

  // current year -> no year at all
  if ((begin.getFullYear() === now.getFullYear()) && (end.getFullYear() === now.getFullYear())) {
    return `${localized.veryShortDayMonthText(begin)!} - ${localized.veryShortDayMonthText(end)!}`;
  }

  // same year -> year at end only
  if (begin.getFullYear() === end.getFullYear()) {
    return `${localized.veryShortDayMonthText(begin)!} - ${localized.veryShortDateText(end)!}`;
  }

  // different year -> year at begin and end
  return `${localized.veryShortDateText(begin)!} - ${localized.veryShortDateText(end)!}`;
}

export function formattedDateRange(dateRange: DateRange):
{ period: string, main: string, previous: string, full: string } {
  const period = i18n.t(`code.date_range.${dateRange.period}`) as string;
  const main = formattedDateInterval(dateRange.beginDate, dateRange.endDate);
  const previous = dateRange.previousBeginDate && dateRange.previousEndDate
    ? formattedDateInterval(dateRange.previousBeginDate, dateRange.previousEndDate)
    : 'N/A';
  const full = dateRange.previousBeginDate && dateRange.previousEndDate ? `${main} vs. ${previous}` : main;

  return {
    period, main, previous, full,
  };
}

// makes range (begin date, end date>
export function dateRangeDatesFromPeriod(period: DateRangePeriod, firstDayOfWeek: number) : { begin: Date, end: Date} {
  const today = dateFlooredToDate(new Date());
  let begin = today;
  let end = dateByAddingDays(begin, 1);

  switch (period) {
    case DateRangePeriod.Today:
      begin = dateFlooredToDate(today); end = dateByAddingDays(begin, 1); break;
    case DateRangePeriod.Yesterday:
      begin = dateByAddingDays(dateFlooredToDate(today), -1); end = dateByAddingDays(begin, 1); break;
    case DateRangePeriod.Tomorrow:
      begin = dateByAddingDays(dateFlooredToDate(today), 1); end = dateByAddingDays(begin, 1); break;

    case DateRangePeriod.ThisWeek:
      begin = dateFlooredToWeek(today, firstDayOfWeek);
      end = dateByAddingDays(begin, 7);
      break;
    case DateRangePeriod.LastWeek:
      begin = dateByAddingDays(dateFlooredToWeek(today, firstDayOfWeek), -7);
      end = dateByAddingDays(begin, 7);
      break;
    case DateRangePeriod.NextWeek:
      begin = dateByAddingDays(dateFlooredToWeek(today, firstDayOfWeek), 7);
      end = dateByAddingDays(begin, 7);
      break;

    case DateRangePeriod.ThisMonth:
      begin = dateFlooredToMonth(today);
      end = dateByAddingMonths(begin, 1);
      break;
    case DateRangePeriod.LastMonth:
      begin = dateByAddingMonths(dateFlooredToMonth(today), -1);
      end = dateByAddingMonths(begin, 1);
      break;
    case DateRangePeriod.NextMonth:
      begin = dateByAddingMonths(dateFlooredToMonth(today), 1);
      end = dateByAddingMonths(begin, 1);
      break;

    case DateRangePeriod.Last30Days:
      begin = dateByAddingDays(begin, -30);
      end = dateByAddingDays(begin, 31); // 31!
      break;
    case DateRangePeriod.Next30Days:
      end = dateByAddingDays(begin, 31); // 31!
      break;
    case DateRangePeriod.Last90Days:
      begin = dateByAddingDays(begin, -90);
      end = dateByAddingDays(begin, 91); // 91!
      break;
    case DateRangePeriod.Next90Days:
      end = dateByAddingDays(begin, 91); // 91!
      break;

    case DateRangePeriod.ThisYear:
      begin = dateFlooredToYear(today);
      end = dateByAddingYears(begin, 1);
      break;
    case DateRangePeriod.LastYear:
      begin = dateByAddingYears(dateFlooredToYear(today), -1);
      end = dateByAddingYears(begin, 1);
      break;
    case DateRangePeriod.NextYear:
      begin = dateByAddingYears(dateFlooredToYear(today), 1);
      end = dateByAddingYears(begin, 1);
      break;

    default:
      break;
  }

  end = dateByAddingDays(end, -1);

  return { begin, end };
}

// check full period of right open date range
function isFullMonth(begin: Date, end: Date): number | undefined {
  return areDatesEqual(begin, dateFlooredToMonth(begin)) && areDatesEqual(end, dateByAddingMonths(begin, 1)) ? begin.getMonth() : undefined;
}
function isFullYear(begin: Date, end: Date): number | undefined {
  return areDatesEqual(begin, dateFlooredToYear(begin)) && areDatesEqual(end, dateByAddingYears(begin, 1)) ? begin.getFullYear() : undefined;
}

export function periodFromDateRangeDates(periods: DateRangePeriod[], begin: Date, end: Date, firstDayOfWeek: number): DateRangePeriod {
  // selectable periods
  const period = periods.find((p) => {
    if (SpecialDateRangePeriods.includes(p)) return false;
    const range = dateRangeDatesFromPeriod(p, firstDayOfWeek);
    return areDatesEqual(range.begin, begin) && areDatesEqual(range.end, end);
  });

  if (period) return period;

  // pseudo custom periods
  const roend = dateByAddingDays(end, 1);
  if (isFullMonth(begin, roend)) return DateRangePeriod.CustomMonth;
  if (isFullYear(begin, roend)) return DateRangePeriod.CustomYear;

  return DateRangePeriod.Custom;
}

export function shiftedDateRangeFromPeriodAndRange(periods: DateRangePeriod[], period: DateRangePeriod, beginDate: Date, endDate: Date, firstDayOfWeek: number, previous = true) : { begin: Date, end: Date, period: DateRangePeriod } {
  const diff = daysInBetweenDates(endDate, beginDate) - 1; //  diff to add or subtract
  let begin = dateByAddingDays(endDate, 1); //  for the next period
  let end = dateByAddingDays(beginDate, -1); // for the previous period

  switch (period) {
    case DateRangePeriod.ThisMonth:
    case DateRangePeriod.LastMonth:
    case DateRangePeriod.NextMonth:
    case DateRangePeriod.CustomMonth:
      begin = dateFlooredToMonth(previous ? end : begin); // first day of shifted month
      end = dateByAddingDays(dateByAddingMonths(begin, 1), -1); // +1month -1day
      break;
    case DateRangePeriod.ThisYear:
    case DateRangePeriod.LastYear:
    case DateRangePeriod.NextYear:
    case DateRangePeriod.CustomYear:
      begin = dateFlooredToYear(previous ? end : begin); // first day of shifted year
      end = dateByAddingDays(dateByAddingYears(begin, 1), -1); // +1year -1day
      break;

    default:
      if (previous) {
        begin = dateByAddingDays(end, -diff);
      } else {
        end = dateByAddingDays(begin, diff); // right open
      }
      break;
  }

  // new period
  let newPeriod = periodFromDateRangeDates(periods, begin, end, firstDayOfWeek);

  switch (period) {
    case DateRangePeriod.CustomMonth: // keep month period
      newPeriod = [DateRangePeriod.ThisMonth, DateRangePeriod.LastMonth, DateRangePeriod.NextMonth].includes(newPeriod)
        ? newPeriod : DateRangePeriod.CustomMonth; break;
    case DateRangePeriod.CustomYear: // keep year period
      newPeriod = [DateRangePeriod.ThisYear, DateRangePeriod.LastYear, DateRangePeriod.NextYear].includes(newPeriod)
        ? newPeriod : DateRangePeriod.CustomYear; break;

    case DateRangePeriod.Today: newPeriod = previous ? DateRangePeriod.Yesterday : DateRangePeriod.Tomorrow; break;
    case DateRangePeriod.Yesterday: newPeriod = previous ? DateRangePeriod.Custom : DateRangePeriod.Today; break;
    case DateRangePeriod.Tomorrow: newPeriod = previous ? DateRangePeriod.Today : DateRangePeriod.Custom; break;
    case DateRangePeriod.ThisWeek: newPeriod = previous ? DateRangePeriod.LastWeek : DateRangePeriod.NextWeek; break;
    case DateRangePeriod.LastWeek: newPeriod = previous ? DateRangePeriod.Custom : DateRangePeriod.ThisWeek; break;
    case DateRangePeriod.NextWeek: newPeriod = previous ? DateRangePeriod.ThisWeek : DateRangePeriod.Custom; break;
    case DateRangePeriod.ThisMonth: newPeriod = previous ? DateRangePeriod.LastMonth : DateRangePeriod.NextMonth; break;
    case DateRangePeriod.LastMonth: newPeriod = previous ? DateRangePeriod.CustomMonth : DateRangePeriod.ThisMonth; break;
    case DateRangePeriod.NextMonth: newPeriod = previous ? DateRangePeriod.ThisMonth : DateRangePeriod.CustomMonth; break;
    case DateRangePeriod.Last30Days: newPeriod = previous ? DateRangePeriod.Custom : DateRangePeriod.Next30Days; break;
    case DateRangePeriod.Next30Days: newPeriod = previous ? DateRangePeriod.Last30Days : DateRangePeriod.Custom; break;
    case DateRangePeriod.Last90Days: newPeriod = previous ? DateRangePeriod.Custom : DateRangePeriod.Next90Days; break;
    case DateRangePeriod.Next90Days: newPeriod = previous ? DateRangePeriod.Last90Days : DateRangePeriod.Custom; break;
    case DateRangePeriod.ThisYear: newPeriod = previous ? DateRangePeriod.LastYear : DateRangePeriod.NextYear; break;
    case DateRangePeriod.LastYear: newPeriod = previous ? DateRangePeriod.CustomYear : DateRangePeriod.ThisYear; break;
    case DateRangePeriod.NextYear: newPeriod = previous ? DateRangePeriod.ThisYear : DateRangePeriod.CustomYear; break;
    default: break;
  }

  return { begin, end, period: newPeriod };
}

export function isWholeWeeks(begin: Date, end: Date, firstDayOfWeek: number): boolean {
  const firstwd = begin.getDay();
  const lastwd = end.getDay();
  return firstwd === firstDayOfWeek && lastwd === (firstDayOfWeek + 6) % 7;
}

export function makeWholeWeeks(dateRange: DateRange, firstDayOfWeek: number): DateRange {
  const diffb = ((dateRange.beginDate.getDay() + 7) - firstDayOfWeek) % 7;
  const diffe = ((firstDayOfWeek + 6) - dateRange.endDate.getDay()) % 7;

  const beginDate = dateByAddingDays(dateRange.beginDate, -diffb);
  const endDate = dateByAddingDays(dateRange.endDate, diffe);

  let previousBeginDate = undefined as Date | undefined;
  let previousEndDate = undefined as Date | undefined;

  if (dateRange.previousBeginDate && dateRange.previousEndDate) {
    ({ begin: previousBeginDate, end: previousEndDate } = shiftedDateRangeFromPeriodAndRange([], DateRangePeriod.Custom, beginDate, endDate, firstDayOfWeek));
  }

  return {
    beginDate,
    endDate,
    previousBeginDate,
    previousEndDate,
    period: DateRangePeriod.Custom,
    firstDayOfWeek,
  };
}
