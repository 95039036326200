
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import TabItemAllocationRow from '@/components/views/settings/TabItemAllocationRow.vue';
import Tab from '@/model/Tab';
import Allocation from '@/model/Allocation';
import TabItem from '@/model/TabItem';
import { ValidationObserver } from 'vee-validate';
import { cloneModel, uniqueMinId } from '@/model/model-utils';
import { performSaveAction } from '@/services/vue-utils';
import { mapActions } from 'vuex';
import { AllocationsDialogMode } from './TabAllocationsDialog.vue';

export default Vue.extend({
  name: 'TableCombinationDialog',
  components: { TabItemAllocationRow },
  mixins: [DialogClosable],
  props: {
    mode: { type: Number as PropType<AllocationsDialogMode>, required: true },
  },

  data() {
    return {
      tabItemSelection: [] as TabItem[],
      partySizeSelection: [] as number[],
      partySizeItems: [...new Array(99)].map(
        (e, i) => ({ value: i + 1, text: this.$i18n.t('label.x_guests', [i + 1]) }),
      ),
    };
  },
  computed: {
    tabItems(): Array<TabItem> {
      return this.$tstore.state.allocations.tab.tabItems;
    },
    selectedSeats(): number {
      return this.tabItemSelection.map(((o) => o.seats)).reduce((a, b) => a + b, 0);
    },
    allocationsByPartySize(): Map<number, Allocation[]> {
      switch (this.mode) {
        case AllocationsDialogMode.AllowedAllocations:
          return this.$tstore.getters.allowedAllocationsByPartySize;
        case AllocationsDialogMode.DisallowedAllocations:
          return this.$tstore.getters.disallowedAllocationsByPartySize;
        default: return new Map<number, Allocation[]>();
      }
    },
  },

  watch: {
    visible(value: boolean) {
      if (!value) { return; }
      this.reset();
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(['sendAllocations']),
    reset() {
      this.tabItemSelection = [];
      this.partySizeSelection = [];

      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver> | undefined;
      if (observer !== undefined) {
        observer.reset();
      }
    },

    async save() {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
      let isValid = await observer.validate();

      // check party size <= selected seats
      this.partySizeSelection.forEach((partySize) => { isValid = isValid && partySize <= this.selectedSeats; });

      if (!isValid) {
        observer.setErrors(
          { partySizeSelection: this.$i18n.tc('error.allocation_partysize_too_big') },
        );
        return;
      }

      const isForbidden = this.mode === AllocationsDialogMode.DisallowedAllocations;
      let allok = true;
      for (let i = 0; i < this.partySizeSelection.length; i += 1) {
        const partySize = this.partySizeSelection[i];

        const oldAllocations = this.allocationsByPartySize.get(partySize) ?? [];
        const allocations = oldAllocations.map((o) => cloneModel(o));

        const allocation = new Allocation();
        allocation.id = uniqueMinId(allocations);
        allocation.partySize = partySize;
        allocation.tabItems = this.tabItemSelection;
        allocation.isForbidden = isForbidden;

        allocations.push(allocation);

        // eslint-disable-next-line no-await-in-loop
        const ok = await performSaveAction(
          this.$refs.observer,
          async () => this.sendAllocations({ entities: allocations, partySize, isForbidden }),
        );

        allok = allok && ok;
        if (!allok) break;
      }

      if (allok) this.close();
    },
    close() {
      this.$emit('close');
    },
  },
});
