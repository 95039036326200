import { ILocale } from '@/api/api-update';
import i18n from '@/plugins/i18n';

export default class Locale {
  id: number;

  locale: string;

  language: string;

  constructor(o: ILocale) {
    this.id = new TextEncoder().encode(o.locale).reduce((sum, val, i) => sum + val * i * 256);
    this.locale = o.locale ?? '';
    this.language = i18n.t(`code.locale.${this.locale}`) as string;
  }

  get value() { return this.locale; }

  get text() { return this.language; }
}
