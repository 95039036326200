
import Vue, { PropType } from 'vue';
import 'echarts';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';
import { use } from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'SmsBalanceChart',
  components: {
    VChart,
  },
  props: {
    balance: { type: Number, required: true },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
    };
  },
  computed: {
    isDark(): boolean { return this.$vuetify.theme.dark; },
  },
  watch: {
    balance() { this.reset(); },
    isDark() { this.reset(); },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const max = Math.max(500, Math.ceil(this.balance / 500) * 500);
      const position = (this.balance / max) < 0.1 ? 'right' : 'inside';
      return {
        grid: {
          left: '0px',
          top: '0px',
          bottom: '24px',
          right: '28px',
        },
        xAxis: {
          type: 'value',
          max,
          axisLabel: {
            padding: [0, 0, 0, 8],
          },
        },
        yAxis: {
          type: 'category',
          show: false,
          data: ['credits'],
        },
        series: [{
          type: 'bar',
          showBackground: true,
          itemStyle: {
            color: '#3355ff',
          },
          cursor: 'default',
          label: {
            show: true,
            color: this.isDark || position === 'inside' ? 'white' : 'black',
            position,

          },
          labelLayout: {
            moveOverlap: 'shiftY',
          },
          data: [this.balance],
        }],
      };
    },
  },
});

