import Vue from 'vue';
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate';

import VueFileAgent from 'vue-file-agent';
import setupValidation from '@/plugins/validation';

import ReorderTable from '@/plugins/reorder-table';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/store';
import vuetify from '@/plugins/vuetify';

import MainView from '@/components/views/MainView.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import ReservationView from '@/components/views/reservation/Reservation.vue';
import CalendarView from '@/components/views/calendar/Calendar.vue';
import TimePickerField from '@/components/pickers/TimePickerField.vue';
import DatePickerField from '@/components/pickers/DatePickerField.vue';
import ColorPickerField from '@/components/pickers/ColorPickerField.vue';
import MenuItem from '@/components/util/MenuItem.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import SectionHeader from '@/components/forms/SectionHeader.vue';
import RowFormDivider from '@/components/layouts/RowFormDivider.vue';
import ValidatedTextField from '@/components/forms/ValidatedTextField.vue';
import FilledButton from '@/components/buttons/FilledButton.vue';
import ToolbarContainer from '@/components/layouts/ToolbarContainer.vue';
import TooltipIconButton from '@/components/buttons/TooltipIconButton.vue';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import SettingsGroup from '@/components/layouts/SettingsGroup.vue';

import '@/services/autodarkmode';

import 'vue-file-agent/dist/vue-file-agent.css';

import i18n from '@/plugins/i18n';
import VueLocalizedFormatterPlugin from '@/plugins/vue-localized-formatter/src/plugin';
import ActivityIndicatorPlugin from '@/plugins/activity-indicator-plugin/plugin';
import ToastPlugin from '@/plugins/toast-plugin/plugin';
import localized from '@/plugins/vue-localized-formatter/src/localized';

import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.component('MainView', MainView);
Vue.component('ReservationView', ReservationView);
Vue.component('CalendarView', CalendarView);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('TimePickerField', TimePickerField);
Vue.component('DatePickerField', DatePickerField);
Vue.component('ColorPickerField', ColorPickerField);
Vue.component('MenuItem', MenuItem);
Vue.component('ModalDialog', ModalDialog);
Vue.component('SectionHeader', SectionHeader);
Vue.component('ConfirmationDialog', ConfirmationDialog);
Vue.component('RowFormDivider', RowFormDivider);
Vue.component('ValidatedTextField', ValidatedTextField);
Vue.component('FilledButton', FilledButton);
Vue.component('ToolbarContainer', ToolbarContainer);
Vue.component('TooltipIconButton', TooltipIconButton);
Vue.component('SettingsForm', SettingsForm);
Vue.component('SettingsGroup', SettingsGroup);

Vue.use(VueFileAgent);

Vue.directive('reorder-table', ReorderTable);

Vue.use(VueLocalizedFormatterPlugin);
Vue.use(ToastPlugin);
Vue.use(ActivityIndicatorPlugin);

Object.defineProperty(Vue.prototype, '$tstore', {
  get() { return this.$store; },
});

setupValidation(i18n, localized);

new Vue({
  components: {
    ValidationProvider,
  },
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
