

import Vue, { PropType } from 'vue';
import { ReservationStatus } from '@/model/Reservation';
import { reservationStatusAllItems, reservationStatusItems } from '@/util/status';
import ClosableDialog from '@/mixins/dialog-closable';
import Tab from '@/model/Tab';
import { colorForStatus } from '@/components/helpers/colors';

export default Vue.extend({
  name: 'GuestFilter',
  components: { },
  mixins: [ClosableDialog],
  props: {
    compact: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      filterStatusItems: reservationStatusAllItems(),
      statusItems: reservationStatusItems(),
    };
  },
  computed: {
    filterTimeTo(): string { return this.$tstore.state.filter.to; },
    filterTimeFrom(): string { return this.$tstore.state.filter.from; },
    filterStatus(): ReservationStatus { return this.$tstore.state.filter.status; },
    filterSection(): Tab | null { return this.$tstore.state.filter.tab; },
    filterSectionItems() {
      return [
        { text: this.$i18n.t('label.all_sections'), value: null },
        { divider: true },
        ...this.$tstore.state.settings.tabs,
      ];
    },
    filterTimeFromDirty(): boolean { return this.$tstore.getters.isFilterFrom; },
    filterTimeToDirty(): boolean { return this.$tstore.getters.isFilterTo; },
    filterStatusDirty(): boolean { return this.$tstore.getters.isFilterStatus; },
    filterSectionDirty(): boolean { return this.$tstore.getters.isFilterTab; },
  },
  methods: {
    resetFilter() {
      this.$tstore.dispatch('resetFilter');
      this.$emit('close', false);
    },
    applyFilter(p: { from?: string | null, to?: string | null, status?: ReservationStatus | null, tab?: Tab | null }) {
      this.$tstore.dispatch('applyFilter', p);
    },
    changeFilterTimeFrom(value: string | null) { this.applyFilter({ from: value }); },
    changeFilterTimeTo(value: string | null) { this.applyFilter({ to: value }); },
    changeFilterSection(value: number | null) {
      const tab = this.$tstore.state.settings.tabs.find((t) => t.id === value) ?? null;
      this.applyFilter({ tab });
    },
    changeFilterStatus(value: ReservationStatus | null) { this.applyFilter({ status: value }); },
    close() {
      this.$emit('close', false);
    },
    colorForStatus(status: ReservationStatus) {
      if (!this.isStatus(status)) return undefined;
      return colorForStatus(status);
    },
    isStatus(status: ReservationStatus) {
      return this.statusItems.find((si) => si.value === status);
    },
  },
});

