import { daysBetweenDates, isDateToday } from '@/services/time-utils';
import { capitalize } from '@/util/common';
import moment from 'moment';
import VueI18n from 'vue-i18n';

import { VueLocalizedFormatterOptions } from './options';

export default class VueLocalizedFormatter {
  locale : string;

  private m : moment.Moment;

  private i18n : VueI18n;

  private timeFormat = '24hr'; // 'ampm'

  //
  private hourMinFormat = 'HH:mm';

  private hourMinSecFormat = 'HH:mm:ss';

  constructor(opts: VueLocalizedFormatterOptions) {
    this.locale = opts.locale;
    this.i18n = opts.i18n;

    this.m = moment();
    this.m.locale(this.locale);
  }

  public setLocale(locale: string) {
    this.locale = locale;
    this.m = moment('2022-01-30T16:00:00');
    this.m.locale(locale);

    const formatted = this.m.format('LLLL');
    this.set24HrFormat(formatted.indexOf('16') > -1);
  }

  public getLocale(): string {
    return this.m.locale();
  }

  public set24HrFormat(is24hr: boolean) {
    this.timeFormat = is24hr ? '24hr' : 'ampm';
    this.hourMinFormat = is24hr ? 'HH:mm' : 'h:mm A';
    this.hourMinSecFormat = is24hr ? 'HH:mm:ss' : 'h:mm:ss A';
  }

  public weekdays(): {value: number, text:string}[] {
    this.m.locale(this.locale);
    const firstDayWk = this.firstDayOfWeek();
    const wkDays = this.m.localeData().weekdaysShort().map((d, i) => ({ value: i, text: capitalize(d) }));
    return wkDays.slice(firstDayWk, wkDays.length).concat(wkDays.slice(0, firstDayWk));
  }

  public weekday(d: number): string {
    return capitalize(this.m.localeData().weekdaysShort()[d]);
  }

  public longWeekdays(): {value: number, text:string}[] {
    const firstDayWk = this.firstDayOfWeek();
    const wkDays = this.m.localeData().weekdays().map((d, i) => ({ value: i, text: capitalize(d) }));
    return wkDays.slice(firstDayWk, wkDays.length).concat(wkDays.slice(0, firstDayWk));
  }

  private stringFromDate(format: string, value?: Date | null): string | null {
    if (!value) return null;
    const m = moment(value);
    m.locale(this.locale);
    if (!m.isValid) return null;
    return m.format(format);
  }

  public hourMinTextFrom24hr(time: string): string | null {
    const d = new Date();
    const timeParts = time.split(':');
    d.setHours(parseInt(timeParts[0], 10));
    d.setMinutes(parseInt(timeParts[1], 10));
    return this.hourMinText(d);
  }

  // Time Formatting from time-utils.ts
  public fullDateText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'full'));
  }

  public dateText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'long'));
  }

  public shortDateText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'short'));
  }

  public veryShortDateText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'shorter'));
  }

  public dayMonthText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'long-no-year'));
  }

  public shortWeekdayYearText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'short-weekday-year'));
  }

  public shortDayMonthText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'short-no-year'));
  }

  public veryShortDayMonthText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'short-no-weekday-no-year'));
  }

  public dateTimeText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'long-datetime'));
  }

  public numericDateTimeText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'compact-datetime'));
  }

  public shortDateTimeText(date?: Date | null | undefined): string | null | undefined {
    if (!date) { return date; }
    return capitalize(this.i18n.d(date, 'short-datetime'));
  }

  public monthText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'month'));
  }

  public monthYearText(date?: Date | null): string | null {
    if (!date) { return null; }
    return capitalize(this.i18n.d(date, 'month-year'));
  }

  public hourMinSecText(date: Date | null): string | null {
    return this.stringFromDate(this.hourMinSecFormat, date);
  }

  public hourMinText(date: Date | null): string | null {
    return this.stringFromDate(this.hourMinFormat, date);
  }

  public durationFromSeconds(seconds: number): string {
    if (seconds === 0) {
      return this.i18n.t('label.x_min', [0]) as string;
    }
    const duration = moment.duration(seconds, 's');
    moment.locale(this.locale);
    const min = duration.get('m');
    const minStr = min !== 0 ? this.i18n.t('label.x_min', [duration.get('m')]) : '';

    let hourStr = '';
    const hour = duration.get('h');
    if (hour !== 0) {
      hourStr = this.i18n.tc('label.x_hours', hour);
    }

    let dayStr = '';
    const day = duration.get('d');
    if (day !== 0) {
      dayStr = this.i18n.tc('label.x_days', day);
    }

    return `${dayStr} ${hourStr} ${minStr}`.trim();
  }

  public firstDayOfWeek(): number {
    return this.m.localeData().firstDayOfWeek();
  }

  public getHourMinFormat(): string {
    return this.hourMinFormat;
  }

  public getTimeFormat(): string {
    return this.timeFormat;
  }

  public smartDateText(date?: Date | null): string | null {
    if (!date) { return null; }

    const minutes = this.hourMinText(date);
    const daysBetween = daysBetweenDates(new Date(), date);
    if (daysBetween === 0) { return `${this.i18n.t('label.today')}, ${minutes}`; }
    if (daysBetween === 1) { return `${this.i18n.t('label.yesterday')}, ${minutes}`; }
    if (daysBetween > 1 && daysBetween < 5) {
      const wkday = this.i18n.d(date, 'weekday');
      return capitalize(`${wkday}, ${minutes}`);
    }

    return capitalize(this.i18n.d(date, 'compact-datetime'));
  }

  public currencyString(amt: number, curr: string, hideDecimal: boolean = false): string {
    const currency = curr.toUpperCase();
    return new Intl.NumberFormat(this.getLocale(), {
      style: 'currency',
      currency,
      minimumFractionDigits: hideDecimal ? 0 : undefined,
      maximumFractionDigits: hideDecimal ? 0 : undefined,
    }).format(amt);
  }

  public currencySymbolInfo(curr: string): {symbol: string, prefix: boolean} {
    const currency = curr.toUpperCase();
    const parts = new Intl.NumberFormat(this.getLocale(), { style: 'currency', currency }).formatToParts(1);
    const currencyIdx = parts.findIndex((part) => part.type === 'currency');
    const integerIdx = parts.findIndex((part) => part.type === 'integer');
    const prefix = currencyIdx < integerIdx;
    const symbol = parts[currencyIdx].value;
    return { symbol, prefix };
  }
  // ??? unused
  // export function extraShortDateText(date?: Date | null): string | null {
//   return stringFromDate('DD-MMM-YYYY', date);
// }
}
