
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'StackedLineChart',
  components: {
    VChart,
  },
  props: {
    title: { type: String, required: true },
    categoryType: { type: String as PropType<string>, default: 'day' },
    category: { type: Array as PropType<Array<Date>>, default: () => [] },
    series: { type: Array as PropType<Array<string>>, default: () => [] },
    dataset: { type: Array as PropType<Array<Array<number>>>, default: () => [] },
    // totals: { type: Object as PropType<Array<number>>, default: [] },
    total: { type: Number as PropType<number>, default: 0 },
    extraOptions: { type: Object as PropType<Array<{text: string, value: string}>>, default: undefined },
    extraOption: { type: String, default: '' },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
      extraOptionSelected: this.extraOption,
    };
  },
  computed: {
    categoryData(): string[] {
      switch (this.categoryType) {
        case 'hour': return this.category.map((d) => this.$localized.hourMinText(d)!);
        case 'month': return this.category.map((d) => this.$localized.monthText(d)!);
        default: return this.category.map((d) => this.$localized.shortDayMonthText(d)!);
      }
    },
  },
  watch: {
    categoryType() { this.reset(); },
    category() { this.reset(); },
    series() { this.reset(); },
    dataset() { this.reset(); },
    extraOptionSelected() {
      if (this.extraOptionSelected === this.extraOption) { return; }
      this.$emit('update:extra-option', this.extraOptionSelected);
    },
    extraOption() {
      if (this.extraOptionSelected === this.extraOption) { return; }
      this.extraOptionSelected = this.extraOption;
    },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const series = this.series.map((name, i) => ({
        name,
        type: 'line',
        smooth: 0.15,
        areaStyle: {},
        symbol: 'roundRect',
        emphasis: {
          focus: 'series',
        },
        data: this.dataset[i],
      }));

      return {
        grid: {
          left: '0px',
          top: '16px',
          right: '2%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
          extraCssText: 'min-width: 200px; max-width: 600px',
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
        },
        xAxis: {
          // splitNumber: 16,
          type: 'category',
          data: this.categoryData,
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
          splitLine: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis: {
          type: 'value',
        },
        series,
      };
    },
  },
});

