/* eslint-disable import/prefer-default-export */
/* eslint-disable no-return-assign */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */

import { Filter } from './filter';
import {
  FilterBoolRule, FilterFieldRule, FilterRule, FilterRuleType,
} from './filter-rule';

interface IFilter {
  id?: number
  version?: string
  rule?: IRule
  [key: string]: unknown;
}

interface IRule {
  id?: number
  type?: string
  field?: string
  fieldType?: string
  operand?: any
  rules?: IRule[]
  [key: string]: unknown;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class FilterConvert {
  public static fromJson(json: string) {
    const ifl = cast(JSON.parse(json), r('IFilter')) as IFilter;
    return this.toFilter(ifl);
  }

  public static toJson(value: Filter): string {
    const ifl = this.fromFilter(value);
    return JSON.stringify(uncast(ifl, r('IFilter')), null, 2);
  }

  private static fromFilter(filter: Filter): IFilter {
    const rule = this.fromRule(filter.rule);
    const { version } = filter;
    return { rule, version };
  }

  private static toFilter(ifilter: IFilter) {
    const rule = this.toRule(ifilter.rule!);
    return Filter.newFilter(ifilter.version!, rule);
  }

  private static fromRule(rule: FilterRule): IRule {
    const { id, type } = rule;
    const { field, fieldType, operand } = (rule as FilterFieldRule);
    const rules = (rule as FilterBoolRule)?.rules?.map((r) => this.fromRule(r));

    return {
      id, type, field, fieldType, operand, rules,
    };
  }

  private static toRule(irule: IRule): FilterRule {
    const type = irule.type as FilterRuleType;
    let rule: FilterRule | null;

    if (FilterRule.isFilterBoolRuleType(type)) {
      const rules = irule.rules?.map((ir) => FilterConvert.toRule(ir)) ?? [];
      rule = FilterRule.newFilterBoolRule(type, rules);
    } else if (FilterRule.isFilterFieldRuleType(type)) {
      rule = FilterRule.newFilterFieldRule(type, irule.field ?? '', irule.fieldType ?? '', irule.operand);
    } else {
      throw Error(`Unknown rule ${irule.type}`);
    }

    if (!rule) throw Error(`Error create rule ${irule.type}`);

    return rule;
  }
}

function invalidValue(typ: any, val: any): never {
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) { }
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue('array', val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(typ: any, val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue('Date', val);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue('object', val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps);
      }
    });
    return result;
  }

  if (typ === 'any') return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === 'object' && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers') ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems') ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty('props') ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(typ, val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  IFilter: o([
    { json: 'id', js: 'id', typ: u(undefined, 0) },
    { json: 'version', js: 'version', typ: '' },
    { json: 'rule', js: 'rule', typ: r('IRule') },
  ], false),
  IRule: o([
    { json: 'id', js: 'id', typ: u(undefined, 0) },
    { json: 'type', js: 'type', typ: u(undefined, '') },
    { json: 'field', js: 'field', typ: u(undefined, '') },
    { json: 'fieldType', js: 'fieldType', typ: u(undefined, '') },
    { json: 'operand', js: 'operand', typ: u(undefined, u(null, true, 0, '')) },
    { json: 'rules', js: 'rules', typ: u(undefined, a(r('IRule'))) },
  ], false),
};

// const typeMap: any = {
//   Filter: o([
//     { json: 'id', js: 'id', typ: u(undefined, 0) },
//     { json: 'version', js: 'version', typ: u(undefined, '') },
//     { json: 'rule', js: 'rule', typ: u(undefined, r('FilterRule')) },
//   ], false),
//   FilterRule: o([
//     { json: 'id', js: 'id', typ: u(undefined, 0) },
//     { json: 'type', js: 'type', typ: u(undefined, '') },
//     { json: 'rules', js: 'rules', typ: u(undefined, a(r('PurpleRule'))) },
//   ], false),
//   PurpleRule: o([
//     { json: 'id', js: 'id', typ: u(undefined, 0) },
//     { json: 'type', js: 'type', typ: u(undefined, '') },
//     { json: 'rules', js: 'rules', typ: u(undefined, a(r('FluffyRule'))) },
//   ], false),
//   FluffyRule: o([
//     { json: 'id', js: 'id', typ: u(undefined, 0) },
//     { json: 'type', js: 'type', typ: u(undefined, '') },
//     { json: 'field', js: 'field', typ: u(undefined, '') },
//     { json: 'operand', js: 'operand', typ: u(undefined, u(true, 0, '')) },
//   ], false),
// };

// const typeMap: any = {
//   "Filter": o([
//     { json: "id", js: "id", typ: u(undefined, 0) },
//     { json: "version", js: "version", typ: u(undefined, "") },
//     { json: "rule", js: "rule", typ: u(undefined, r("FilterRule")) },
//   ], false),
//   "FilterRule": o([
//     { json: "id", js: "id", typ: u(undefined, 0) },
//     { json: "type", js: "type", typ: u(undefined, "") },
//     { json: "rules", js: "rules", typ: u(undefined, a(r("PurpleRule"))) },
//   ], false),
//   "PurpleRule": o([
//     { json: "id", js: "id", typ: u(undefined, 0) },
//     { json: "type", js: "type", typ: u(undefined, "") },
//     { json: "rules", js: "rules", typ: u(undefined, a(r("FluffyRule"))) },
//   ], false),
//   "FluffyRule": o([
//     { json: "id", js: "id", typ: u(undefined, 0) },
//     { json: "type", js: "type", typ: u(undefined, "") },
//     { json: "field", js: "field", typ: u(undefined, "") },
//     { json: "operand", js: "operand", typ: u(undefined, u(0, null, "")) },
//   ], false),
// };
