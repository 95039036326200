import { IDurationRule } from '@/api/api-update';
import { timeNumberFromText } from '@/services/time-utils';

export interface IDTODurationRule extends IDurationRule {}

export default class DurationRule {
  id: number;

  tabId: number;

  partySizeFrom: number = 0;

  partySizeTo: number = 0;

  timeFrom?: string;

  timeTo?: string;

  additionalDuration: number = 0;

  timeNumberFrom: number;

  timeNumberTo: number;

  get order(): number { return this.id; }

  constructor(o?: IDurationRule) {
    this.id = o?.durationRuleId ?? 0;

    this.tabId = o?.tabId ?? 0;

    this.partySizeFrom = o?.partySizeFrom ?? 0;

    this.partySizeTo = o?.partySizeTo ?? 0;

    this.timeFrom = o?.timeFrom;

    this.timeTo = o?.timeTo;

    this.additionalDuration = o?.additionalDuration ?? 0;

    this.timeNumberFrom = timeNumberFromText(o?.timeFrom);

    this.timeNumberTo = timeNumberFromText(o?.timeTo);
  }

  toDto(): IDTODurationRule {
    const dto: IDTODurationRule = {
      durationRuleId: this.id,
      tabId: this.tabId,
      partySizeFrom: this.partySizeFrom,
      partySizeTo: this.partySizeTo,
      timeFrom: this.timeFrom,
      timeTo: this.timeTo,
      additionalDuration: this.additionalDuration,
    };
    return dto;
  }
}
