import { PluginObject, VueConstructor } from 'vue';
import ToastPlugin from './toast';

interface IToastPluginOptions {}

declare global {
  interface Window {
    Vue: VueConstructor;
  }
}

const version = '1.0.0';

const plugin: PluginObject<IToastPluginOptions> = {
  install: (Vue): void => {
    if (plugin.installed) {
      return;
    }
    plugin.installed = true;

    let toast = ToastPlugin;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toast = toast;

    Object.defineProperties(Vue.prototype, {
      $toast: {
        get() {
          return toast;
        },
        set(t: typeof ToastPlugin) {
          toast = t;
        },
      },
    });
  },
  version,
};

export default plugin;
