/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class StripeConnectApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.stripe-connect', {
      categories: [GstplnAppCategory.Payments],
      configComponentImportFnc: () => import('@/components/apps/stripe-connect/SettingsDialog.vue'),
    });
  }
}
