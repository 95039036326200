import OpeningHour from '@/model/OpeningHour';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import {
  minutesFromTimeString, niceTimeTextFromSlot, slotFromMinutes, weekdayNumberFromDateDay,
} from './time-utils';

export class SimpleHour {
  beginSlot: number = slotFromMinutes(minutesFromTimeString('18:00'));

  endSlot: number = slotFromMinutes(minutesFromTimeString('22:00'));

  isOpen: boolean = false;
}

// in weekdays - starting from 1 (Mon === 1, Tue === 2, ....)
export function openingHoursToSimpleHoursInWeekdays(
  hourMaps: Map<number, OpeningHour>[],
  is30minInterval: boolean,
): SimpleHour[] {
  const simpleHours: SimpleHour[] = [];

  hourMaps.forEach((hourMap, di) => {
    const hours = Array.from(hourMap.values()).sort((o1, o2) => o1.timeIndex - o2.timeIndex);
    const sh = new SimpleHour();
    if (hours.length > 0) {
      sh.beginSlot = hours[0].slot;
      if (sh.beginSlot % 2 === 1 && is30minInterval) sh.beginSlot -= 1; // if 30 minutes step
      sh.endSlot = hours[hours.length - 1].slot + 1;
      if (sh.endSlot % 2 === 1 && is30minInterval) sh.endSlot += 1;
      sh.isOpen = true;
    }

    simpleHours.push(sh);
  });

  return simpleHours;
}

export function roundedSimpleHoursInWeekdays(simpleHours: SimpleHour[]) {
  return simpleHours.map((osh) => {
    const sh = new SimpleHour();
    sh.isOpen = osh.isOpen; sh.beginSlot = osh.beginSlot; sh.endSlot = osh.endSlot;
    if (sh.beginSlot % 2 === 1) sh.beginSlot -= 1; // if 30 minutes step
    if (sh.endSlot % 2 === 1) sh.endSlot += 1;
    return sh;
  });
}

// in weekdays - starting from 1 (Mon === 1, Tue === 2, ....)
export function hoursFromSimpleHoursInWeekdays(simpleHours: SimpleHour[], is30minInterval: boolean): OpeningHour[] {
  const slotStep = is30minInterval ? 2 : 1;
  const hours: OpeningHour[] = [];

  localized.weekdays().forEach((day) => {
    const weekdayNumber = weekdayNumberFromDateDay(day.value);
    if (!simpleHours[weekdayNumber].isOpen) return;

    for (let si = simpleHours[weekdayNumber].beginSlot; si < simpleHours[weekdayNumber].endSlot; si += slotStep) {
      const hour = OpeningHour.fromWeekdayAndSlot(weekdayNumber, si);
      hours.push(hour);
    }
  });

  hours.sort((o1, o2) => o1.order - o2.order);

  return hours;
}

// in weekdays - starting from 1 (Mon === 1, Tue === 2, ....)
export function guessIntervalFromHoursInWeekdays(hourMaps: Map<number, OpeningHour>[]): number {
  let interval = 30;
  if (hourMaps.some((hourMap) => Array.from(hourMap.values()).some((oh) => oh.slot % 2 === 1))) { interval = 15; }
  return interval;
}

export function isOnlySimpleHours(hourMaps: Map<number, OpeningHour>[]): boolean {
  // guess interval
  const interval = guessIntervalFromHoursInWeekdays(hourMaps);

  // for all days
  const special = hourMaps.some((hourMap, di) => {
    // sorted hours
    const hours = Array.from(hourMap.values()).sort((o1, o2) => o1.timeIndex - o2.timeIndex);
    // no hours -> nothing special
    if (hours.length === 0) return false;

    // begin and end slot
    const bs = hours[0].slot;
    const es = hours[hours.length - 1].slot;

    // test slots in continuous sequence
    for (let i = 0; i < hours.length; i += 1) {
      // test hour in continuous sequence
      if (hours[i].slot !== bs + i * (interval / 15)) return true;
      // test hours special
      if (hours[i].isCustom) return true;
    }

    // nothing special
    return false;
  });

  return !special;
}
