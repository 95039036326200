// eslint-disable-next-line import/prefer-default-export
export function isSafariOS(): boolean {
  const ua = navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
}

export function isSafari(): boolean {
  const ua = navigator.userAgent;
  return !!ua.match(/webkit\W(?!.*chrome).*safari\W/i);
}

export function capitalize(s:string | null): string {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function removeInvisibleUnicodeCharacters(input: string): string {
  const charactersToRemove: string[] = [
    '\u0000', // NULL
    '\u0001', // START OF HEADING
    '\u0002', // START OF TEXT
    '\u0003', // END OF TEXT
    '\u0004', // END OF TRANSMISSION
    '\u0005', // ENQUIRY
    '\u0006', // ACKNOWLEDGE
    '\u0007', // BELL
    '\u0008', // BACKSPACE
    '\u0009', // HORIZONTAL TAB
    '\u000A', // LINE FEED (NEW LINE)
    '\u000B', // VERTICAL TAB
    '\u000C', // FORM FEED
    '\u000D', // CARRIAGE RETURN
    '\u000E', // SHIFT OUT
    '\u000F', // SHIFT IN
    '\u0010', // DATA LINK ESCAPE
    '\u0011', // DEVICE CONTROL 1
    '\u0012', // DEVICE CONTROL 2
    '\u0013', // DEVICE CONTROL 3
    '\u0014', // DEVICE CONTROL 4
    '\u0015', // NEGATIVE ACKNOWLEDGE
    '\u0016', // SYNCHRONOUS IDLE
    '\u0017', // END OF TRANSMISSION BLOCK
    '\u0018', // CANCEL
    '\u0019', // END OF MEDIUM
    '\u001A', // SUBSTITUTE
    '\u001B', // ESCAPE
    '\u001C', // FILE SEPARATOR
    '\u001D', // GROUP SEPARATOR
    '\u001E', // RECORD SEPARATOR
    '\u001F', // UNIT SEPARATOR
    '\u0020', // SPACE
    '\u00A0', // NO-BREAK SPACE
    '\u1680', // OGHAM SPACE MARK
    '\u2000', // EN QUAD
    '\u2001', // EM QUAD
    '\u2002', // EN SPACE
    '\u2003', // EM SPACE
    '\u2004', // THREE-PER-EM SPACE
    '\u2005', // FOUR-PER-EM SPACE
    '\u2006', // SIX-PER-EM SPACE
    '\u2007', // FIGURE SPACE
    '\u2008', // PUNCTUATION SPACE
    '\u2009', // THIN SPACE
    '\u200A', // HAIR SPACE
    '\u200B', // ZERO WIDTH SPACE
    '\u200C', // ZERO WIDTH NON-JOINER
    '\u200D', // ZERO WIDTH JOINER
    '\u2028', // LINE SEPARATOR
    '\u2029', // PARAGRAPH SEPARATOR
    '\u202F', // NARROW NO-BREAK SPACE
    '\u205F', // MEDIUM MATHEMATICAL SPACE
    '\u3000', // IDEOGRAPHIC SPACE
    '\uFEFF', // ZERO WIDTH NO-BREAK SPACE (BYTE ORDER MARK)
    '\u00AD', // SOFT HYPHEN
    '\u034F', // COMBINING GRAPHEME JOINER
    '\u061C', // ARABIC LETTER MARK
    '\u115F', // HANGUL CHOSEONG FILLER
    '\u1160', // HANGUL JUNGSEONG FILLER
    '\u17B4', // KHMER VOWEL INHERENT AQ
    '\u180E', // MONGOLIAN VOWEL SEPARATOR
    '\u2800', // BRAILLE PATTERN BLANK
    '\u3164', // HANGUL FILLER
    '\uFFA0', // HALFWIDTH HANGUL FILLER
    '\u1D159', // MUSICAL SYMBOL NULL NOTEHEAD
    '\u1D173', // MUSICAL SYMBOL BEGIN BEAM
    '\u1D174', // MUSICAL SYMBOL END BEAM
    '\u1D175', // MUSICAL SYMBOL BEGIN TIE
    '\u1D176', // MUSICAL SYMBOL END TIE
    '\u1D177', // MUSICAL SYMBOL BEGIN SLUR
    '\u1D178', // MUSICAL SYMBOL END SLUR
    '\u1D179', // MUSICAL SYMBOL BEGIN PHRASE
    '\u1D17A', // MUSICAL SYMBOL END PHRASE
    '\u200E', // LEFT-TO-RIGHT MARK
    '\u200F', // RIGHT-TO-LEFT MARK
    '\u202A', // LEFT-TO-RIGHT EMBEDDING
    '\u202B', // RIGHT-TO-LEFT EMBEDDING
    '\u202C', // POP DIRECTIONAL FORMATTING
    '\u202D', // LEFT-TO-RIGHT OVERRIDE
    '\u202E', // RIGHT-TO-LEFT OVERRIDE
    '\u2060', // WORD JOINER (ZERO WIDTH NO-BREAK SPACE)
    '\u2061', // FUNCTION APPLICATION
    '\u2062', // INVISIBLE TIMES
    '\u2063', // INVISIBLE SEPARATOR
    '\u2064', // INVISIBLE PLUS
    '\u2066', // LEFT-TO-RIGHT ISOLATE
    '\u2067', // RIGHT-TO-LEFT ISOLATE
    '\u2068', // FIRST STRONG ISOLATE
    '\u2069', // POP DIRECTIONAL ISOLATE
    '\u206A', // INHIBIT SYMMETRIC SWAPPING
    '\u206B', // ACTIVATE SYMMETRIC SWAPPING
    '\u206C', // INHIBIT ARABIC FORM SHAPING
    '\u206D', // ACTIVATE ARABIC FORM SHAPING
    '\u206E', // NATIONAL DIGIT SHAPES
    '\u206F', // NOMINAL DIGIT SHAPES
  ];

  const characterSetToRemove = new Set(charactersToRemove);
  const filteredScalars = Array.from(input).filter((char) => !characterSetToRemove.has(char));
  const cleanString = filteredScalars.join('');

  return cleanString;
}
