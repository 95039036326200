import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  dateFlooredToDate, hourMinFromTimeText, isDateAfterDate,
  setDateHoursMinutes, TIME_SLOTS_COUNT, dateIndexFromDate, weekdayNumber, areDatesEqual,
} from '@/services/time-utils';
import Tab from '@/model/Tab';
import TimeSlot from '@/model/TimeSlot';
import { InvalidStatuses } from '@/util/status';
import Reservation, { ReservationSource } from '@/model/Reservation';
import Service from '@/model/Service';
import { DEFAULT_SPLIT_LUNCH_DINNER_TIME } from '@/services/configuration';
import TagNote from '@/model/TagNote';
import Label from '@/model/Label';
import { strcmp } from '@/services/common';
import i18n from '@/plugins/i18n';
import { timeSlotsFromHours } from '@/services/timestlot-utils';
import grpcClient from '@/grpc-api/grpc-client';
import DashboardAvailability, {
  DashboardLimitData,
  DashboardLimitReason,
} from '@/grpc-api/model/dashboard-availability';
import { IUpdate } from '@/api/api-update';
import storage from '@/services/local-storage';
import IRootState, { IDashboardState } from './store-state';

export const DashboardAllTabs = new Tab({ tabId: -1, tabName: i18n.tc('label.all_sections') });
export const DashboardOnlineTabs = new Tab({ tabId: -2, tabName: i18n.tc('label.online_sections') });

export class DashboardState implements IDashboardState {
  tab: Tab = DashboardOnlineTabs;

  date: Date | null = null;

  isLoaded: boolean = false;

  isDayAvailability: boolean = false;

  dayAvailability: DashboardAvailability | null = null;

  isDayAvailabilityLoaded: boolean = false;

  showDetails: boolean = storage.dashboardShowDetails();
}

const mutations = <MutationTree<IDashboardState>>{
  RESET(state: DashboardState) {
    Object.assign(state, new DashboardState());
  },
  RESET_DASHBOARD(state: DashboardState) {
    Object.assign(state, new DashboardState());
  },
  UPDATE_DASHBOARD(
    state: DashboardState,
    p: { tab?: Tab, date?: Date | null, isLoaded?: boolean},
  ) {
    state.tab = p.tab === undefined ? state.tab : p.tab;
    state.isLoaded = p.isLoaded === undefined ? state.isLoaded : p.isLoaded;
    const date = p.date ? dateFlooredToDate(p.date) : p.date;
    state.date = date === undefined ? state.date : date;
  },
  UPDATE_DASHBOARD_AVAILABILITY(
    state: DashboardState,
    p: { isDayAvailability?: boolean, dayAvailability?: DashboardAvailability, isDayAvailabilityLoaded?: boolean },
  ) {
    state.isDayAvailability = p.isDayAvailability === undefined ? state.isDayAvailability : p.isDayAvailability;
    state.dayAvailability = p.dayAvailability === undefined ? state.dayAvailability : p.dayAvailability;
    state.isDayAvailabilityLoaded = p.isDayAvailabilityLoaded === undefined
      ? state.isDayAvailabilityLoaded : p.isDayAvailabilityLoaded;

    console.log('UPDATE_DASHBOARD_AVAILABILITY: ', state.isDayAvailability, state.dayAvailability);
  },
  SET_DASHBOARD_AUX(state: DashboardState, p: { showDetails?: boolean }) {
    if (p.showDetails !== undefined) {
      state.showDetails = p.showDetails;
      storage.setDashboardShowDetails(p.showDetails);
    }
    console.log('SET_DASHBOARD_AUX: ', state.showDetails);
  },
};

const actions = <ActionTree<IDashboardState, IRootState>>{
  async resetDashboard({
    state, commit, dispatch, rootGetters,
  }) {
    commit('RESET_DASHBOARD');
  },
  async setDashboardDate({
    state, commit, dispatch, rootGetters,
  }, date: Date) {
    if (state.date && areDatesEqual(state.date, dateFlooredToDate(date))) return;

    commit('UPDATE_DASHBOARD', { date, isLoaded: false });
    commit('UPDATE_DASHBOARD_AVAILABILITY', {
      isDayAvailability: false,
      dayAvailability: null,
      isDayAvailabilityLoaded: false,
    });
    // await dispatch('loadDashboard');
    if (!rootGetters.isTodayOrFuture) return;
    await dispatch('loadDashboardAvailability');
    commit('UPDATE_DASHBOARD', { isLoaded: true });
  },
  async setDashboardTab({
    state, commit, dispatch, rootGetters,
  }, tab: Tab) {
    commit('UPDATE_DASHBOARD', { tab });
  },
  async loadDashboardAvailability({
    state, commit, dispatch, rootGetters,
  }) {
    if (!rootGetters.isLoaded || !state.date) return;

    const dateIndex = dateIndexFromDate(state.date);
    const dayAvailability = await grpcClient.getAvailability(dateIndex);
    if (dateIndexFromDate(state.date) !== dateIndex) return;

    commit('UPDATE_DASHBOARD_AVAILABILITY', {
      isDayAvailability: dayAvailability.isAvailable,
      dayAvailability,
      isDayAvailabilityLoaded: true,
    });
  },
  async setDashboardShowDetails({ commit }, showDetails: boolean) {
    commit('SET_DASHBOARD_AUX', { showDetails });
  },
  async refresh({ dispatch }, update: IUpdate) {
    console.log('dashboard store refresh');

    if (
      !update.accountSettings
      && !update.openingHours
      && !update.datesAndTimes
      && !update.reservations) return;

    // load now
    await dispatch('loadDashboardAvailability');
  },
};

const getters = <GetterTree<IDashboardState, IRootState>>{
  isDashboardDayAvailabilityLoaded(state: DashboardState, localGetters: any, rootState: any, rootGetters: any) {
    return state.isLoaded && rootGetters.isLoaded && state.isDayAvailabilityLoaded;
  },
  dashboardDayAvailability(state: DashboardState, localGetters: any, rootState: any, rootGetters: any) {
    if (!localGetters.isDashboardDayAvailabilityLoaded) return new DashboardAvailability();
    return state.dayAvailability;
  },
  isDashboardLoaded(state: DashboardState, localGetters: any, rootState: any, rootGetters: any) {
    return state.isLoaded && rootGetters.isLoaded;
  },
  dashboardTimeSlots(state, localGetters, rootState, rootGetters) {
    return timeSlotsFromHours(
      state.date ?? new Date(),
      rootGetters.openingHoursInWeekdaysByTimeIndex,
      localGetters.datesAndTimesById,
    );
  },
  dashboardIsOnlineTab(state) {
    return state.tab === DashboardOnlineTabs || state.tab.usingWeekdaysAndTimes === true;
  },
  dashboardReservations(state, localGetters, rootState, rootGetters) {
    const di = dateIndexFromDate(state.date ?? new Date());
    const dateSlot = rootState.reservations.dateSlotsByDateIndex[di];
    const reservations = dateSlot?.reservations ?? [];

    const predicate = (r: Reservation) => !InvalidStatuses.includes(r.status) && !r.isBlock;
    return reservations.filter(predicate);
  },
  dashboardFilteredReservations(state, localGetters, rootState, rootGetters) {
    const onlineTabs = rootState.settings.tabs.filter((t) => t.usingWeekdaysAndTimes === true);
    const reservations = localGetters.dashboardReservations as Reservation[];
    const predicate = (r: Reservation) => false
      || (r.tab?.id === state.tab.id) // selected specific tab
      || (state.tab === DashboardOnlineTabs && onlineTabs.some((t) => t.id === r.tab?.id)) // selected online tabs
      || (state.tab === DashboardAllTabs); // selected all tabs
    return reservations.filter(predicate);
  },
  dashboardGuestsPerTime(state, localGetters, rootState, rootGetters) {
    const { tabs } = rootState.settings;
    const onlineTabs = tabs.filter((t) => t.usingWeekdaysAndTimes === true);

    const sdata = Array.from({ length: TIME_SLOTS_COUNT }, (x, si) => ({
      s: 0, // seated guests
      i: 0, // incoming guests
      ri: 0, // incoming reservations
      sl: 0, // seated limit
      il: 0, // incoming limit
      ril: 0, // incoming reservations limit
      slr: DashboardLimitReason.None,
      ilr: DashboardLimitReason.None,
      rilr: DashboardLimitReason.None,
      imin: 0,
      imax: 0,
      iminr: DashboardLimitReason.None,
      imaxr: DashboardLimitReason.None,
    }) as DashboardLimitData);

    const smap = new Map<Number, {
      s: number, i: number, ri: number,
      ts: number, ti: number, tri: number,
      sl: number, il: number, ril: number,
      slr: DashboardLimitReason, ilr: DashboardLimitReason, rilr: DashboardLimitReason,
      imin: number, imax: number,
      iminr: DashboardLimitReason, imaxr: DashboardLimitReason,
    }>();
    sdata.forEach((sd, i) => { smap.set(i, sd); });

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      // incoming guests and reservations
      smap.get(r.slotBegin)!.i += r.partySize;
      smap.get(r.slotBegin)!.ri += 1;
      // seated guests
      for (let si = r.slotBegin; si <= r.slotEnd; si += 1) smap.get(si)!.s += r.partySize;
    });

    // max capacity for section or all sections
    let ma = 0;
    let mar = DashboardLimitReason.None;
    if (state.tab !== DashboardAllTabs && state.tab !== DashboardOnlineTabs) {
      if (state.tab.usingWeekdaysAndTimes) { ma = state.tab.capacity; mar = DashboardLimitReason.Capacity; }
    } else {
      tabs.forEach((t) => {
        if (t.usingWeekdaysAndTimes) { ma += t.capacity; mar = DashboardLimitReason.Capacity; }
      });
    }

    const slots = localGetters.dashboardTimeSlots as TimeSlot[];
    slots.forEach((s) => {
      const sd = smap.get(s.slot)!;

      // seated limit
      sd.sl = ma;
      sd.slr = mar;

      let il = ma;
      let ilr = mar;
      let ril = ma;
      let rilr = mar;
      let imin = 0;
      let imax = Infinity;
      let iminr = DashboardLimitReason.None;
      let imaxr = DashboardLimitReason.None;

      const {
        defaultMaxGuestsPerTime, defaultMaxReservationsPerTime, defaultMinPartySize, defaultMaxPartySize,
      } = rootState.settings.accountSettings;

      // incoming limit and min and max incoming only for all sections or online sections
      if (!s.isOpen) {
        il = 0;
        ril = 0;
        ilr = s.dateAndTime ? DashboardLimitReason.ClosedSpecialHour : DashboardLimitReason.ClosedOpeningHour;
        rilr = s.dateAndTime ? DashboardLimitReason.ClosedSpecialHour : DashboardLimitReason.ClosedOpeningHour;
      } else { // if (state.tab === DashboardAllTabs || state.tab === DashboardOnlineTabs) {
        // incoming guests limit
        if (s.dateAndTime?.maximumGuests) {
          il = s.dateAndTime?.maximumGuests; ilr = DashboardLimitReason.MaxGuestsSpecialHour;
        } else if (s.openingHour?.maximumGuests) {
          il = s.openingHour?.maximumGuests; ilr = DashboardLimitReason.MaxGuestsOpeningHour;
        } else if (defaultMaxGuestsPerTime) {
          il = defaultMaxGuestsPerTime; ilr = DashboardLimitReason.MaxGuestsDefault;
        } else {
          il = Infinity;
        }

        // incoming reservations limit
        if (s.dateAndTime?.maximumReservations) {
          ril = s.dateAndTime?.maximumReservations; rilr = DashboardLimitReason.MaxReservationsSpecialHour;
        } else if (s.openingHour?.maximumReservations) {
          ril = s.openingHour?.maximumReservations; rilr = DashboardLimitReason.MaxReservationsOpeningHour;
        } else if (defaultMaxReservationsPerTime) {
          ril = defaultMaxReservationsPerTime; rilr = DashboardLimitReason.MaxReservationsDefault;
        } else {
          ril = Infinity;
        }

        // min party size limit
        if (s.dateAndTime?.minimumPartySize) {
          imin = s.dateAndTime?.minimumPartySize; iminr = DashboardLimitReason.MinPartySizeSpecialHour;
        } else if (s.openingHour?.minimumPartySize) {
          imin = s.openingHour?.minimumPartySize; iminr = DashboardLimitReason.MinPartySizeOpeningHour;
        } else if (defaultMinPartySize) {
          imin = defaultMinPartySize; iminr = DashboardLimitReason.MinPartySizeDefault;
        } else {
          imin = 0;
        }

        // max party size limit
        if (s.dateAndTime?.maximumPartySize) {
          imax = s.dateAndTime?.maximumPartySize; imaxr = DashboardLimitReason.MaxPartySizeSpecialHour;
        } else if (s.openingHour?.maximumPartySize) {
          imax = s.openingHour?.maximumPartySize; imaxr = DashboardLimitReason.MaxPartySizeOpeningHour;
        } else if (defaultMaxPartySize) {
          imax = defaultMaxPartySize; imaxr = DashboardLimitReason.MaxPartySizeDefault;
        } else {
          imax = Infinity;
        }
      }

      sd.il = il;
      sd.ilr = ilr;
      sd.ril = ril;
      sd.rilr = rilr;
      sd.imin = imin;
      sd.imax = imax;
      sd.iminr = iminr;
      sd.imaxr = imaxr;
    });

    // data
    // console.log('dashboardGuestsPerTime:', sdata);
    return sdata;
  },
  dashboardGuestsForSections(state, localGetters, rootState, rootGetters): { s: Tab, g: number, r: number }[] {
    const map = new Map<number, { g: number, r: number }>();

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      if (!r.tab) return;
      let tdata = map.get(r.tab.id);
      if (!tdata) {
        tdata = { g: 0, r: 0 };
        map.set(r.tab.id, tdata);
      }
      tdata.g += r.partySize;
      tdata.r += 1;
    });

    const sdata = rootState.settings.tabs.map((t) => ({ s: t, ...(map.get(t.id) ?? { g: 0, r: 0 }) }));

    // console.log('dashboardGuestsForSections:', sdata);
    return sdata;
  },
  dashboardLunchDinnerForSections(state, localGetters, rootState, rootGetters): { s: Tab, l: number, d: number }[] {
    const splitTime = rootState.settings.accountSettings.splitLunchDinnerTime ?? DEFAULT_SPLIT_LUNCH_DINNER_TIME;
    const hm = hourMinFromTimeText(splitTime) ?? hourMinFromTimeText(DEFAULT_SPLIT_LUNCH_DINNER_TIME)!;
    const splitDate = setDateHoursMinutes(rootState.update.date, hm.h, hm.m);

    const map = new Map<number, { l: number, d: number }>();

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      if (!r.tab) return;
      let tdata = map.get(r.tab.id);
      if (!tdata) {
        tdata = { l: 0, d: 0 };
        map.set(r.tab.id, tdata);
      }

      if (!isDateAfterDate(r.dateBegin, splitDate)) { tdata.l += r.partySize; } else { tdata.d += r.partySize; }
    });

    const sdata = rootState.settings.tabs.map((t) => ({ s: t, ...(map.get(t.id) ?? { l: 0, d: 0 }) }));

    // console.log('dashboardLunchDinnerForSections:', sdata);
    return sdata;
  },
  dashboardGuestsForServices(state, localGetters, rootState, rootGetters): { s: Service, g: number, r: number }[] {
    const map = new Map<number, { g: number, r: number }>();

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      if (!r.service) return;
      let tdata = map.get(r.service.id);
      if (!tdata) {
        tdata = { g: 0, r: 0 };
        map.set(r.service.id, tdata);
      }

      tdata.g += r.partySize;
      tdata.r += 1;
    });

    const sdata = rootState.settings.services.map((s) => ({ s, ...(map.get(s.id) ?? { g: 0, r: 0 }) }));

    // console.log('dashboardGuestsForService:', sdata);
    return sdata;
  },
  dashboardDayStats(state, localGetters, rootState, rootGetters): {
    reservations: number, tables: number, guests: number, lunch: number, dinner: number,
    labeledReservations: number, labeledGuests: number, serviceReservations: number, serviceGuests: number,
  } {
    const splitTime = rootState.settings.accountSettings.splitLunchDinnerTime ?? DEFAULT_SPLIT_LUNCH_DINNER_TIME;
    const hm = hourMinFromTimeText(splitTime) ?? hourMinFromTimeText(DEFAULT_SPLIT_LUNCH_DINNER_TIME)!;
    const splitDate = setDateHoursMinutes(rootState.update.date, hm.h, hm.m);

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];

    const tables = new Set<number>();
    let guests = 0;
    let lunch = 0;
    let dinner = 0;
    let labeledReservations = 0;
    let labeledGuests = 0;
    let serviceReservations = 0;
    let serviceGuests = 0;

    reservations.forEach((r) => {
      r.tabItems.forEach((t) => { tables.add(t.id); });
      guests += r.partySize;
      if (!isDateAfterDate(r.dateBegin, splitDate)) { lunch += r.partySize; } else dinner += r.partySize;

      if (r.label) {
        labeledReservations += 1;
        labeledGuests += r.partySize;
      }

      if (r.service) {
        serviceReservations += 1;
        serviceGuests += r.partySize;
      }
    });

    return {
      reservations: reservations.length,
      tables: tables.size,
      guests,
      lunch,
      dinner,
      labeledReservations,
      labeledGuests,
      serviceReservations,
      serviceGuests,
    };
  },
  dashboardGuestsForTagNotes(state, localGetters, rootState, rootGetters):
  {gr: { t: TagNote, g: number, r: number }[], trs: number} {
    const { tagNotes } = rootState.settings;
    const matchTagsText = new RegExp(tagNotes.map((tag) => `(${tag.dotText})`).join('|'), 'g');

    let trs = 0;
    const map = new Map<string, { g: number, r: number }>();

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      if (!r.notes) return;

      const matches = r.notes.match(matchTagsText);
      if (!matches) return;

      trs += 1;

      matches.forEach((mtg) => {
        let tdata = map.get(mtg);
        if (!tdata) {
          tdata = { g: 0, r: 0 };
          map.set(mtg, tdata);
        }
        tdata.g += r.partySize;
        tdata.r += 1;
      });
    });

    const gr = tagNotes.map((t) => ({ t, ...(map.get(t.dotText) ?? { g: 0, r: 0 }) }));

    // console.log('dashboardGuestsForTagNotes:', { gr, trs });
    return { gr, trs };
  },

  dashboardGuestsForLabels(state, localGetters, rootState, rootGetters):
  { gr: { l: Label, g: number, r: number }[], lrs: number } {
    const { labels } = rootState.settings;

    let lrs = 0;
    const map = new Map<number, { g: number, r: number }>();

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      if (!r.label) return;

      lrs += 1;

      let data = map.get(r.label.id);
      if (!data) {
        data = { g: 0, r: 0 };
        map.set(r.label.id, data);
      }
      data.g += r.partySize;
      data.r += 1;
    });

    const gr = labels.map((l) => ({ l, ...(map.get(l.id) ?? { g: 0, r: 0 }) }));

    // console.log('dashboardGuestsForLabels:', { gr, lrs });
    return { gr, lrs };
  },

  dashboardGuestsForSource(state, localGetters, rootState, rootGetters): { s: string, g: number, r: number }[] {
    const tmap = new Map<string, { g: number, r: number }>();
    tmap.set('online', { g: 0, r: 0 });
    tmap.set('user', { g: 0, r: 0 });

    const cmap = new Map<string, { g: number, r: number }>();
    cmap.set('none', { g: 0, r: 0 });

    const reservations = localGetters.dashboardFilteredReservations as Reservation[];
    reservations.forEach((r) => {
      // type
      const tkey = r.reservationType || 'other';
      let tdata = tmap.get(tkey);
      if (!tdata) {
        tdata = { g: 0, r: 0 };
        tmap.set(tkey, tdata);
      }
      tdata.g += r.partySize;
      tdata.r += 1;

      // campaign
      if (r.reservationType !== ReservationSource.Online) return; // campaign only for onlines

      const ckey = r.reservationCampaign || 'none';
      let cdata = tmap.get(ckey);
      if (!cdata) {
        cdata = { g: 0, r: 0 };
        cmap.set(ckey, cdata);
      }
      cdata.g += r.partySize;
      cdata.r += 1;
    });

    // type results
    const tentries = Array.from(tmap.entries());
    const tdata = [
      ...tentries.slice(0, 2).map((e) => ({ s: `t-${e[0]}`, g: e[1].g, r: e[1].r })), // online and user are first
      ...tentries.slice(2).map((e) => ({ s: `t-${e[0]}`, g: e[1].g, r: e[1].r }))
        .sort((o1, o2) => strcmp(o1.s, o2.s)), // all other sorted
    ];

    // campaing results
    const centries = Array.from(cmap.entries());
    const cdata = centries.map((e) => ({ s: `c-${e[0]}`, g: e[1].g, r: e[1].r })).sort((o1, o2) => strcmp(o1.s, o2.s));

    // results
    return [...tdata, ...cdata];
  },
};

const DashboardStore = {
  namespaced: false,
  state: new DashboardState(),
  mutations,
  actions,
  getters,
};

export default DashboardStore;
