/* eslint-disable no-param-reassign */
/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import {
  CampaignReports, FeedbackReports, FeedbackReportsBin, FeedbackReportsBinStars, GuestsReports, MsgReports, ReportsParams, ServiceReports, TabReports, WeekdayReports,
} from '@/grpc-pb/reports_service_pb';
import i18n from '@/plugins/i18n';
import { strcmp } from './common';
import { DateRange, DateRangePeriod } from './date-range';
import {
  areDatesEqual,
  dateByAddingDays,
  dateFromDateAndTimeIndex, daysInBetweenDates, hourMinFromSlot, hoursInBetweenDates, monthsInBetweenDates, setDateHoursMinutes, setDateYearMonth, yearsInBetweenDates,
} from './time-utils';

// eslint-disable-next-line import/prefer-default-export
export function reportsBinTypeFromDateRange(dateRange: DateRange): ReportsParams.ReportsBinType {
  switch (dateRange.period) {
    case DateRangePeriod.Custom: return areDatesEqual(dateRange.beginDate, dateRange.endDate) ? ReportsParams.ReportsBinType.OVER_DAY : ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.Today: return ReportsParams.ReportsBinType.OVER_DAY;
    case DateRangePeriod.Tomorrow: return ReportsParams.ReportsBinType.OVER_DAY;
    case DateRangePeriod.Yesterday: return ReportsParams.ReportsBinType.OVER_DAY;
    case DateRangePeriod.ThisWeek: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.NextWeek: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.LastWeek: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.ThisMonth: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.NextMonth: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.LastMonth: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.CustomMonth: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.Next30Days: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.Last30Days: return ReportsParams.ReportsBinType.BY_DAYS;
    case DateRangePeriod.ThisYear: return ReportsParams.ReportsBinType.OVER_YEAR;
    case DateRangePeriod.NextYear: return ReportsParams.ReportsBinType.OVER_YEAR;
    case DateRangePeriod.LastYear: return ReportsParams.ReportsBinType.OVER_YEAR;
    case DateRangePeriod.CustomYear: return ReportsParams.ReportsBinType.OVER_YEAR;
    default: return ReportsParams.ReportsBinType.BY_DAYS;
  }
}

export function reportsBinsFromDateRange(dateRange: DateRange): number {
  const begin = dateRange.beginDate;
  const end = dateByAddingDays(dateRange.endDate, 1);
  let bins = 0;

  const binType = reportsBinTypeFromDateRange(dateRange);
  switch (binType) {
    case ReportsParams.ReportsBinType.OVER_DAY: return 24;
    case ReportsParams.ReportsBinType.OVER_YEAR: return 12;

    case ReportsParams.ReportsBinType.BY_HOURS: bins = hoursInBetweenDates(end, begin); break;
    case ReportsParams.ReportsBinType.BY_DAYS: bins = daysInBetweenDates(end, begin); break;
    case ReportsParams.ReportsBinType.BY_MONTHS: bins = monthsInBetweenDates(end, begin); break;
    case ReportsParams.ReportsBinType.BY_YEARS: bins = yearsInBetweenDates(end, begin); break;

    default: return 0;
  }

  return bins + 1;
}

export function reportsValidateDateRange(dateRange: DateRange): string | undefined {
  const bins = reportsBinsFromDateRange(dateRange);
  if (bins < 1) return 'Date range mismatch';
  if (bins > 100) return 'Date range too wide';

  if (dateRange.beginDate < new Date(2000, 0)) return 'Date range begin too low';
  if (dateRange.endDate > new Date(2100, 0)) return 'Date range end too high';

  return undefined;
}

export enum CategoryType {
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export function reportsCategoryTypeFromBinType(binType: ReportsParams.ReportsBinType) {
  switch (binType) {
    case ReportsParams.ReportsBinType.BY_HOURS: return CategoryType.HOUR;
    case ReportsParams.ReportsBinType.BY_DAYS: return CategoryType.DAY;
    case ReportsParams.ReportsBinType.BY_MONTHS: return CategoryType.MONTH;
    case ReportsParams.ReportsBinType.BY_YEARS: return CategoryType.YEAR;
    case ReportsParams.ReportsBinType.OVER_DAY: return CategoryType.HOUR;
    case ReportsParams.ReportsBinType.OVER_YEAR: return CategoryType.MONTH;
    default: return CategoryType.DAY;
  }
}

export interface ChartData {
  category?: Date[],
  series?: string[],
  dataset?: number[][],
  totals?: number[],
  total?: number,
  categoryType?: CategoryType,
  avgDataset?: number[];
}

export type PieChartData = {value: number, name: string, unit?: string}[]

export function reportsDatesForSlots(date: Date): Date[] {
  return Array.from({ length: 4 * 18 }, (x, slot) => {
    const { h, m } = hourMinFromSlot(6 * 4 + slot);
    return setDateHoursMinutes(date, h, m);
  });
}

export function reportsCategoryFromReportsParams(params: ReportsParams.AsObject, startAt6 = true): { categoryType: CategoryType, category: Date[] } {
  const date = dateFromDateAndTimeIndex(params.beginDate);
  if (!date) return { categoryType: CategoryType.DAY, category: [] };

  // 24 hours
  if (params.binType === ReportsParams.ReportsBinType.OVER_DAY) {
    const categoryType = CategoryType.HOUR;
    const category = Array.from({ length: startAt6 ? 18 : 24 }, (x, hours) => setDateHoursMinutes(date, hours + (startAt6 ? 6 : 0), 0));
    return { categoryType, category };
  }

  // 12 months
  if (params.binType === ReportsParams.ReportsBinType.OVER_YEAR) {
    const categoryType = CategoryType.MONTH;
    const category = Array.from({ length: 12 }, (x, month) => setDateYearMonth(date, null, month));
    return { categoryType, category };
  }

  // x days
  if (params.binType === ReportsParams.ReportsBinType.BY_DAYS) {
    const categoryType = CategoryType.DAY;
    const category = Array.from({ length: params.bins }, (x, days) => dateByAddingDays(date, days));
    return { categoryType, category };
  }

  return { categoryType: CategoryType.DAY, category: [] };
}

export function reportsForMsgTypes(
  msgTypes: Set<string>,
  reportsParams: ReportsParams.AsObject,
  msgReports: Map<string, MsgReports.AsObject>,
): MsgReports.AsObject {
  // add source array values to dest array
  const addArray = (da: number[], sa: number[], offset: number, length: number) => {
    if (da.length < length) da.push(...Array(length - da.length).fill(0));
    for (let i = 0; i < length; i += 1) da[i] += sa[offset + i];
    return da;
  };

  // create reports
  const offset = 0;
  const length = reportsParams.bins;

  const reports: MsgReports.AsObject = {
    msgType: '',
    sentList: new Array(length).fill(0),
    errorsList: new Array(length).fill(0),
  };

  // process msgs from reports
  msgReports.forEach((mr, mt) => {
    // check msg is relevant
    if (!msgTypes.has(mt)) return;

    // add msgs reports
    addArray(reports.sentList, mr.sentList, offset, length);
    addArray(reports.errorsList, mr.errorsList, offset, length);
  });

  return reports;
}

export function reportsForTabs(
  tabs: Set<number>,
  reportsParams: ReportsParams.AsObject,
  tabReports: Map<number, TabReports.AsObject>,
  startAt6 = true,
): TabReports.AsObject {
  // add source array values to dest array
  const addArray = (da: number[], sa: number[], offset: number, length: number) => {
    if (da.length < length) da.push(...Array(length - da.length).fill(0));
    for (let i = 0; i < length; i += 1) da[i] += sa[offset + i];
    return da;
  };

  // add source obj lists to dest obj lists
  const addLists = (dobj: any, sobj: any, fields: string[], offset: number, length: number) => {
    if (!sobj) return dobj;
    fields.forEach((field) => addArray(dobj[field], sobj[field], offset, length));
    return dobj;
  };

  // add source obj lists to dest obj lists
  const createLists = (obj: any, fields: string[], length: number) => {
    fields.forEach((field) => { if (!obj[field]) obj[field] = new Array(length).fill(0); });
    return obj;
  };

  const guestsLists = ['reservationsList', 'guestsList', 'returningList', 'cancelationsList', 'noshowsList', 'partySizeSumsList', 'partySizeCountsList'];
  const campaignLists = ['reservationsList', 'guestsList', 'daysAdvanceSumsList', 'daysAdvanceCountsList'];
  const serviceLists = ['reservationsList', 'guestsList', 'revenueSumsList', 'revenueCountsList'];
  // const feedbackLists = ['overallSumsList', 'overallCountsList', 'foodSumsList', 'ambienceSumsList', 'serviceSumsList', 'breakdownCountsList'];

  // create reports
  let offset = 0;
  let length = reportsParams.bins;
  if (startAt6 && reportsParams.binType === ReportsParams.ReportsBinType.OVER_DAY) {
    offset = 6;
    length = 18;
  }

  // const feedbacksBinList = [...new Array(length)].map((e, i) => ({
  //   overall: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
  //   food: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
  //   service: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
  //   ambience: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
  // } as FeedbackReportsBin.AsObject));

  const reports: TabReports.AsObject = {
    tabId: 0,
    guests: createLists({}, guestsLists, length) as GuestsReports.AsObject,
    guestsDtc: createLists({}, guestsLists, length) as GuestsReports.AsObject,
    campaignsList: [],
    campaignsDtcList: [],
    servicesList: [],
    weekdaysList: Array.from({ length: 7 }, (x, i) => {
      const wd: WeekdayReports.AsObject = {
        weekday: i,
        guests: createLists({}, guestsLists, 18 * 4) as GuestsReports.AsObject,
        servicesList: [],
      };
      return wd;
    }),
    // feedbacks: createLists({}, feedbackLists, length) as FeedbackReports.AsObject,
    feedbacks: {
      binsList: [...new Array(length)].map((e, i) => ({
        overall: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
        food: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
        service: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
        ambience: { starsList: [0, 0, 0, 0, 0] } as FeedbackReportsBinStars.AsObject,
      } as FeedbackReportsBin.AsObject)),
    } as FeedbackReports.AsObject,
  };

  // process tabs from reports
  tabReports.forEach((tr, tid) => {
    // check tab is relevant
    if (!tabs.has(tid)) return;

    // fill guests reports
    addLists(reports.guests, tr.guests, guestsLists, offset, length);
    addLists(reports.guestsDtc, tr.guestsDtc, guestsLists, offset, length);
    // addLists(reports.feedbacks, tr.feedbacks, feedbackLists, offset, length);
    for (let bi = 0; bi < length; bi += 1) {
      for (let i = 0; i < 5; i += 1) {
        reports.feedbacks!.binsList[bi].overall!.starsList[i] += tr.feedbacks?.binsList[bi + offset].overall?.starsList[i] ?? 0;
        reports.feedbacks!.binsList[bi].food!.starsList[i] += tr.feedbacks?.binsList[bi + offset].food?.starsList[i] ?? 0;
        reports.feedbacks!.binsList[bi].service!.starsList[i] += tr.feedbacks?.binsList[bi + offset].service?.starsList[i] ?? 0;
        reports.feedbacks!.binsList[bi].ambience!.starsList[i] += tr.feedbacks?.binsList[bi + offset].ambience?.starsList[i] ?? 0;
      }
    }

    // fill campaign reports
    tr.campaignsList.forEach((c) => {
      let campaign = reports.campaignsList.find((rc) => rc.campaignName === c.campaignName);
      if (!campaign) {
        campaign = createLists({ campaignName: c.campaignName }, campaignLists, length) as CampaignReports.AsObject;
        reports.campaignsList.push(campaign);
      }
      addLists(campaign, c, campaignLists, offset, length);
    });

    tr.campaignsDtcList.forEach((c) => {
      let campaign = reports.campaignsDtcList.find((rc) => rc.campaignName === c.campaignName);
      if (!campaign) {
        campaign = createLists({ campaignName: c.campaignName }, campaignLists, length) as CampaignReports.AsObject;
        reports.campaignsDtcList.push(campaign);
      }
      addLists(campaign, c, campaignLists, offset, length);
    });

    // fill service reports
    tr.servicesList.forEach((s) => {
      let service = reports.servicesList.find((rs) => rs.serviceId === s.serviceId && rs.revenueCurrency === s.revenueCurrency);
      if (!service) {
        service = createLists({ serviceId: s.serviceId, serviceName: s.serviceName, revenueCurrency: s.revenueCurrency }, serviceLists, length) as ServiceReports.AsObject;
        reports.servicesList.push(service);
      }
      addLists(service, s, serviceLists, offset, length);
    });

    // fill weekdays reports
    tr.weekdaysList.forEach((wd, wdi) => {
      // find weekday
      const weekday = reports.weekdaysList[wdi];

      // fill guests reports
      addLists(weekday.guests, wd.guests, guestsLists, 6 * 4, 18 * 4);

      // fill service reports
      wd.servicesList.forEach((s) => {
        let service = weekday.servicesList.find((sls) => sls.serviceId === s.serviceId && sls.revenueCurrency === s.revenueCurrency);
        if (!service) {
          service = createLists({ serviceId: s.serviceId, serviceName: s.serviceName, revenueCurrency: s.revenueCurrency }, serviceLists, 18 * 4) as ServiceReports.AsObject;
          weekday.servicesList.push(service);
        }
        addLists(service, s, serviceLists, 6 * 4, 18 * 4);
      });
    });
  });

  // translate and sort campaigns
  reports.campaignsList.forEach((c) => {
    if (['user', 'online'].includes(c.campaignName)) c.campaignName = i18n.tc(`code.reservation_source.${c.campaignName}`);
  });
  reports.campaignsList.sort((c1, c2) => strcmp(c1.campaignName, c2.campaignName));

  // sort service
  const sortServices = (ss: ServiceReports.AsObject[]) => {
    const revenues = new Map<ServiceReports.AsObject, number>();
    ss.forEach((s) => { revenues.set(s, s.revenueSumsList.reduce((sum, n) => sum + n, 0)); });
    ss.sort((s1, s2) => revenues.get(s2)! - revenues.get(s1)!);
  };

  sortServices(reports.servicesList);
  reports.weekdaysList.forEach((wdl) => sortServices(wdl.servicesList));

  // append service currency if needed
  const addCurrency = new Set(reports.servicesList.map((sr) => sr.revenueCurrency)).size > 1;
  if (addCurrency) {
    reports.servicesList.forEach((s) => { s.serviceName += ` ${s.revenueCurrency.toUpperCase()}`; });
    reports.weekdaysList.forEach((wdl) => wdl.servicesList.forEach((s) => { s.serviceName += ` ${s.revenueCurrency.toUpperCase()}`; }));
  }

  console.log('reportsForTabs: ', reports);

  return reports;
}

// zip lists
export function reportsZipLists(lists: any[][], addIndex = true): any[][] {
  if (lists.length === 0) return [];
  const length = Math.max(...lists.map((list) => list.length));
  return addIndex
    ? Array.from({ length }, (_, i) => [i, ...lists.map((list) => list[i] ?? undefined)])
    : Array.from({ length }, (_, i) => lists.map((list) => list[i] ?? undefined));
}

// sums
export function reportsListSum(list: number[]): number {
  return list.reduce((sum, n) => sum + n, 0);
}

export function reportsListsSums(lists: number[][]): number[] {
  const length = Math.max(...lists.map((list) => list.length));
  return Array.from({ length }, ((_, i) => lists.map((list) => list[i] ?? 0).reduce((sum, n) => sum + n, 0)));
}

// sums with mask
export function reportsListSumWithMask(list: number[], mask: boolean[]): number {
  return list.reduce((sum, n, i) => sum + (mask[i] ? n : 0), 0);
}

export function reportsListsSumsWithMask(lists: number[][], mask: boolean[][]): number[] {
  const length = Math.max(...lists.map((list) => list.length));
  return Array.from({ length }, ((_, i) => lists.map((list, listi) => (mask[listi][i] && list[i]) || 0).reduce((sum, n) => sum + n, 0)));
}

// percentages
export function reportsListPercentages(nomList: number[], denList: number[]): number[] {
  return nomList.map((v, i) => (v === 0 ? 0 : (v * 100) / denList[i]));
}
export function reportsListPercentagesTotal(nomList: number[], denList: number[]): number {
  return (reportsListSum(nomList) * 100) / (reportsListSum(denList) || 1);
}
// averages
export function reportsListAverages(values: number[], counts: number[]): number[] {
  return values.map((v, i) => (v === 0 ? 0 : v / counts[i]));
}
export function reportsListAveragesTotal(values: number[], counts: number[]): number {
  return reportsListSum(values) / (reportsListSum(counts) || 1); // fix zero count sum
}

export function reportsListsAverages(valueLists: number[][], countLists: number[][]): number[] {
  if (valueLists.length === 0) return [];

  const valueSums = reportsListsSums(valueLists);
  const countSums = reportsListsSums(countLists);
  return reportsListAverages(valueSums, countSums);
}

export function reportsListsAveragesNoNeg(valueLists: number[][], countLists: number[][]): number[] {
  if (valueLists.length === 0) return [];

  const masks = valueLists.map((list) => list.map((val) => val >= 0));
  const valueSums = reportsListsSumsWithMask(valueLists, masks);
  const countSums = reportsListsSumsWithMask(countLists, masks);
  return reportsListAverages(valueSums, countSums);
}

export function reportsListsAveragesTotal(valueLists: number[][], countLists: number[][]): number {
  if (valueLists.length === 0) return 0;

  const valueSums = valueLists.map((l) => reportsListSum(l));
  const countSums = countLists.map((l) => reportsListSum(l));
  return reportsListSum(valueSums) / (reportsListSum(countSums) || 1); // fix zero count sum
}

export function reportsListsAveragesTotalNoNeg(valueLists: number[][], countLists: number[][]): number {
  if (valueLists.length === 0) return 0;

  const masks = valueLists.map((list) => list.map((val) => val >= 0));
  const valueSums = valueLists.map((l, listi) => reportsListSumWithMask(l, masks[listi]));
  const countSums = countLists.map((l, listi) => reportsListSumWithMask(l, masks[listi]));
  return reportsListSum(valueSums) / (reportsListSum(countSums) || 1); // fix zero count sum
}
