import { IDateAndTime } from '@/api/api-update';
import { boolFromValue } from '@/api/api-utils';
import {
  dateIndexFromDate, dateTimeIndexFromDateAndTimeIndex, timeIndexFromSlot, timeStringFromDateTimeIndex,
} from '@/services/time-utils';
import HourSlot from './HourSlot';

export interface IDTODateAndTime extends IDateAndTime { }

export default class DateAndTime extends HourSlot {
  id: number;

  iIsOpen?: boolean;

  isDeleted: boolean;

  constructor(o?: IDateAndTime) {
    super(o);
    this.id = Number(o?.dateAndTimeId) ?? 0;
    this.iIsOpen = boolFromValue(o?.isOpen);

    // this.dateTimeIndex = this.id;

    this.isDeleted = boolFromValue(o?.isDeleted) ?? false;
  }

  toDto(): IDTODateAndTime {
    const dto: IDTODateAndTime = {
      dateAndTimeId: this.id,
      isOpen: this.iIsOpen,
      minimumPartySize: this.minimumPartySize,
      maximumPartySize: this.maximumPartySize,
      maximumGuests: this.maximumGuests,
      maximumReservations: this.maximumReservations,
      fixedEndingInMinutes: this.fixedEndingInMinutes,
    };
    return dto;
  }

  static fromDateAndSlot(date: Date, slot: number) {
    const dt = new DateAndTime();
    const dateIndex = dateIndexFromDate(date);
    const timeIndex = timeIndexFromSlot(slot);
    dt.id = dateTimeIndexFromDateAndTimeIndex(dateIndex, timeIndex);
    return dt;
  }

  get dateTimeIndex(): number { return this.id; }

  get dateIndex(): number { return Math.floor(this.id / 10000); }

  get time(): string { return timeStringFromDateTimeIndex(this.id); }

  get timeIndex(): number { return Math.floor(this.id % 10000); }

  get isOpen(): boolean { return this.iIsOpen ?? false; }

  set isOpen(value: boolean) { this.iIsOpen = value; }
}
