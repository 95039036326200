import { defineComponent, PropType } from 'vue';
import Reservation from '@/model/Reservation';
import { performSaveAction } from '@/services/vue-utils';
import toast from '@/plugins/toast-plugin/toast';

import interact from 'interactjs';

export default defineComponent({
  props: {
    reservations: { type: Array as PropType<Array<Reservation>>, required: true },
    // tabs: { type: Array as PropType<Array<Tab>>, required: true },
  },
  mounted() {
    function moveEventElement(x: number, y: number, scroll: number, target: HTMLElement) {
      // eslint-disable-next-line no-param-reassign
      target.style.webkitTransform = `translate(${x}px, ${y + scroll}px)`;
      // eslint-disable-next-line no-param-reassign
      target.style.transform = `translate(${x}px, ${y + scroll}px)`;

      // update the posiion attributes
      target.setAttribute('data-x', `${x}`);
      target.setAttribute('data-y', `${y}`);
    }

    function resetEventElement(target: HTMLElement) {
      moveEventElement(0, 0, 0, target);
      const linkedReservations = document.querySelectorAll(
        `[data-resid='${target.dataset.resid}']`,
      );
      linkedReservations.forEach((elm) => {
        elm.classList.remove('linked-event');
      });
    }

    function dragMoveListener(this: any, evt: any) {
      evt.preventDefault();

      const { target } = evt;
      if (target.classList.contains('blocked')) return;

      // keep track of scroll offset so we can correctly compensate the move
      const { scrollTop } = document.getElementById('tt-scrollable-container')!;
      const originalScroll = parseFloat(target.dataset.scroll || '0');
      const scroll = (scrollTop - originalScroll);

      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + evt.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + evt.dy;
      moveEventElement(x, y, scroll, target);

      // update editor
      // console.log('Target:');
      // console.log(target);
      // console.log('-=-=-=-=-=-=-=-=-=-=-=-');

      // const elementsAtPoint = document.elementsFromPoint(evt.client.x, evt.client.y);
      const rect = target.getClientRects()[0];
      const elementsAtPoint = document.elementsFromPoint(rect.x + 15, rect.y + 15);
      const timeRow = elementsAtPoint.find((elm) => elm.classList.contains('tt-time-row')) as HTMLElement;
      // console.log(timeRow);

      if (!timeRow) {
        // resetEventElement(target);
        return;
      }

      const reservationId = parseInt(target.dataset.resid, 10);
      const tabId = parseInt(timeRow.dataset.tabid!, 10);
      const tabItemId = parseInt(timeRow.dataset.tabitemid!, 10);

      // console.log(`Reservation: ${reservationId}`);
      // console.log(`Tab: ${tabId}`);
      // console.log(`TabItem: ${tabItemId}`);

      this.moveDrag(reservationId, tabId, tabItemId);
    }

    async function dragEndListener(this: any, evt: any) {
      // console.log('### Drag End');
      // console.log(evt);

      const { target } = evt;
      // delayed removal of the flag that disallows the click listener
      // on tt-event to open the edit reservation window
      setTimeout(() => { target.dataset.dnd = '0'; }, 500);

      const linkedReservations = document.querySelectorAll(
        `[data-resid='${target.dataset.resid}']`,
      );
      linkedReservations.forEach((elm) => {
        elm.classList.remove('linked-event');
      });

      target.classList.remove('linked-event');
      target.classList.remove('is-dragging');
      // console.log('Target:');
      // console.log(target);
      // console.log('-=-=-=-=-=-=-=-=-=-=-=-');

      // const elementsAtPoint = document.elementsFromPoint(evt.client.x, evt.client.y);
      const rect = target.getClientRects()[0];
      const elementsAtPoint = document.elementsFromPoint(rect.x + 15, rect.y + 15);
      const timeRow = elementsAtPoint.find((elm) => elm.classList.contains('tt-time-row')) as HTMLElement;
      // console.log(timeRow);

      const reservationId = parseInt(target.dataset.resid, 10);

      if (!timeRow) {
        resetEventElement(target);
        this.cancelDrag();
        return;
      }

      const tabId = parseInt(timeRow.dataset.tabid!, 10);
      const tabItemId = parseInt(timeRow.dataset.tabitemid!, 10);
      const parentTabItemId = parseInt(target.parentElement.dataset.tabitemid!, 10);

      if (tabItemId === parentTabItemId) {
        // console.log('No change, just reset');
        resetEventElement(target);
        this.cancelDrag();
        return;
      }

      // console.log(`Reservation: ${reservationId}`);
      // console.log(`Tab: ${tabId}`);
      // console.log(`TabItem: ${tabItemId}`);

      // Snap element into grid before reservation is redrawn by API
      const x = (parseFloat(target.getAttribute('data-x')) || 0);
      const parentY = target.parentElement.getBoundingClientRect().top;

      moveEventElement(x, timeRow.getBoundingClientRect().top - parentY, 0, target);

      await this.endDrag(reservationId, tabId, tabItemId);

      resetEventElement(target);
    }

    interact('.tt-event')
      .on('hold', (evt: any) => {
        // console.log('### Hold');
        // console.log(evt);
        // console.log(evt.target);
        // console.log(evt.currentTarget);

        if (evt.buttons !== 1) return;

        const { target, currentTarget } = evt;
        const resId = parseInt(currentTarget.dataset.resid, 10);

        if (!this.canDrag(resId, false)) return;
        // this flag tells tt-event to not open reservation
        // editor in it's own click handler. This is b/c click handler
        // is already registered and we can nor remove or change it's order.
        currentTarget.setAttribute('data-dnd', '1');

        currentTarget.classList.add('is-dragging');
        currentTarget.setAttribute('data-scroll', document.getElementById('tt-scrollable-container')!.scrollTop);

        const linkedReservations = document.querySelectorAll(
          `[data-resid='${resId}']`,
        );
        // console.log('Hiding the following linked reservations:');
        // console.log(linkedReservations);
        linkedReservations.forEach((elm) => {
          elm.classList.add('linked-event');
        });
        // console.log('=-=-=-=-=-=-=-=-=-=-=-=');
      })
      .origin('body')
      .pointerEvents({
        holdDuration: 230,
      })
      .styleCursor(false)
      .draggable({
        hold: 250,
        autoScroll: {
          container: document.getElementById('tt-scrollable-container'),
          margin: 100,
          distance: 10,
          interval: 10,
          speed: 150,
        },
        lockAxis: 'y',
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: (x, y, elm) => {
              const contentElm = document.getElementsByClassName('tt-content')[0]!;
              const { top } = contentElm.getClientRects()[0];
              const { bottom, right } = contentElm.getBoundingClientRect();
              return {
                top, left: 0, bottom, right,
              };
            },
          }),
        ],
        listeners: {
          move: dragMoveListener.bind(this),
          end: dragEndListener.bind(this),
        },
      });
  },
  destroyed() {
    interact('.tt-event').unset();
  },
  methods: {
    canDrag(reservationId: number, hideErrors: boolean = false): boolean {
      const reservation = this.reservations.find((o) => o.id === reservationId);
      if (!reservation) return false;

      if (reservation.holdTabItems === true) {
        if (!hideErrors) toast.error(this.$i18n.tc('error.reservation_cannot_move_reservation'));
        return false;
      }
      if (reservation.partySize === 0) {
        if (!hideErrors) toast.error(this.$i18n.tc('error.reservation_cannot_move_block'));
        return false;
      }
      return true;
    },
    moveDrag(reservationId: number, tabId: number, tabItemId: number) {
      if (!this.$tstore.getters.isDragDrop) {
        const reservation = this.reservations.find((o: Reservation) => o.id === reservationId);
        this.startDragDrop(reservation);
      }

      this.moveDragDrop({ reservationId, tabId, tabItemId });
    },
    async endDrag(reservationId: number, tabId: number, tabItemId: number): Promise<boolean> {
      this.moveDragDrop({ reservationId, tabId, tabItemId });

      const { isDragDropValid } = this.$tstore.getters;
      const ok = isDragDropValid && await performSaveAction(
        undefined,
        async () => this.sendReservation({ reservation: this.$tstore.getters.dragDropReservation }),
      );

      const crs: Reservation[] = this.$tstore.getters.dragDropConflictReservations;
      const fcrs = crs.filter((r) => r.holdTabItems !== true);
      if (ok && fcrs.length > 0) {
        this.$emit('relocate-reservations', fcrs);
      }

      this.endDragDrop(ok);

      return ok;
    },
    cancelDrag(tabId: number, tabItemId: number) {
      this.endDragDrop(false);
    },
    // store actions
    startDragDrop(r: Reservation | undefined) {
      this.$store.dispatch('startDragDrop', r);
    },
    moveDragDrop(val: { reservationId: number, tabId: number, tabItemId: number }) {
      this.$store.dispatch('moveDragDrop', val);
    },
    endDragDrop(ok: boolean) {
      this.$store.dispatch('endDragDrop', ok);
    },
    sendReservation(val: {reservation: Reservation}) {
      this.$store.dispatch('sendReservation', val);
    },

  },
});
