import Vue, { defineComponent } from 'vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import { TranslateResult } from 'vue-i18n';
import ConfirmationDialog from './ConfirmationDialog.vue';

interface DialogAction {
  title: string
  action?: () => void
  color?: string
}

export default function showConfirmationDialog(
  title: string | TranslateResult | undefined = undefined,
  message: string | TranslateResult = '',
  confirmAction: DialogAction | undefined = undefined,
  cancelAction: DialogAction | undefined = undefined,
) {
  const DlgConst = defineComponent(ConfirmationDialog);
  const dialog = new DlgConst({
    vuetify,
    propsData: {
      visible: true,
      title: title ?? i18n.tc('title.confirmation'),
      message,
      confirmTitle: confirmAction?.title ?? i18n.tc('button.ok'),
      confirmColor: confirmAction?.color,
      cancelTitle: cancelAction?.title ?? i18n.tc('button.cancel'),
      hideCancel: cancelAction === undefined,
    },
  });

  dialog.$on('close', () => {
    dialog.$destroy();
    dialog.$el.remove();
  });

  if (confirmAction && confirmAction.action) {
    dialog.$on('confirm', confirmAction.action);
  }

  if (cancelAction && cancelAction.action) {
    dialog.$on('cancel', cancelAction.action);
  }

  const mountEl = document.createElement('div');
  document.body.appendChild(mountEl);
  dialog.$mount(mountEl);
}
