import { IDTOHandlebar } from '@/api/api-messenger';

export default class MessageHandlebar {
  id: number;

  name: string;

  expression: string;

  constructor(o: IDTOHandlebar) {
    this.id = o.handlebarId;
    this.name = o.name;
    this.expression = o.expression;
  }
}
