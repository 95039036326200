
import Vue, { PropType } from 'vue';
import FloorplanShapeSeat from './FloorplanShapeSeat.vue';
import { TableSeatType, ITableSize, ITableSeats } from './floorplan-types';

export default Vue.extend({
  name: 'FloorplanShapeTableRect',
  components: { FloorplanShapeSeat },
  props: {
    partySize: { type: Number, required: false, default: 0 },
    fillColor: { type: String, required: true },
    strokeColor: { type: String, required: true },
    seats: { type: Number, required: true },
    tableSize: { type: Object as PropType<ITableSize>, required: true },
    shape: { type: String as PropType<TableSeatType>, required: true },
  },
  data() { return { }; },
  computed: {
    tableSeats(): ITableSeats | null {
      let eSeatCount = 0;
      let wSeatCount = 0;

      if (this.shape === TableSeatType.HEADSEAT) {
        eSeatCount = this.seats === 2 ? 1 : Math.ceil((this.seats - 2) / 2);
        wSeatCount = this.seats - eSeatCount - 2;
      } else {
        eSeatCount = Math.ceil(this.seats / 2);
        wSeatCount = this.seats - eSeatCount;
      }

      const eSeatToDraw = Math.min(8, eSeatCount);
      const wSeatToDraw = Math.min(8, wSeatCount);
      const seatSpacing = (this.tableSize.h - (eSeatToDraw * 30)) / (eSeatToDraw + 1);
      const seatSpacingWidth = (this.tableSize.w - 30) / 2;

      const ts = {} as ITableSeats;

      let occupiedSeats = this.partySize;
      if (this.shape === TableSeatType.HEADSEAT && this.seats > 0) {
        ts.n = [{ origin: seatSpacingWidth, occupied: occupiedSeats > 0 }];
        occupiedSeats -= 1;
        if (this.seats >= 3) {
          ts.s = [{ origin: seatSpacingWidth, occupied: occupiedSeats > 0 }];
          occupiedSeats -= 1;
        }
      }

      let seatsForE = Math.ceil(occupiedSeats / 2);
      let seatsForW = occupiedSeats - seatsForE;

      ts.e = [];
      for (let i = 0; i < eSeatToDraw; i += 1) {
        ts.e.push({ origin: ((i + 1) * seatSpacing) + i * 30, occupied: seatsForE > 0 });
        ts.e[i].isOverflow = (eSeatCount > eSeatToDraw && i === 6);
        seatsForE -= 1;
      }

      ts.w = [];
      for (let i = 0; i < wSeatToDraw; i += 1) {
        ts.w.push({ origin: ((i + 1) * seatSpacing) + i * 30, occupied: seatsForW > 0 });
        ts.w[i].isOverflow = (wSeatCount > wSeatToDraw && i === 6);
        seatsForW -= 1;
      }

      return ts;
    },
  },
  watch: { },
  mounted() { },
  methods: { },
});

