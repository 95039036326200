
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { ScatterChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import { weekdayStartAtMondayFromDateDay } from '@/services/time-utils';
import ChartHelpTooltip from './ChartHelpTooltip.vue';

use([
  ScatterChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'AvgGuestsWeekdayChart',
  components: {
    VChart,
    ChartHelpTooltip,
  },
  props: {
    category: { type: Array as PropType<Array<Date>>, default: () => [] },
    dataset: { type: Array as PropType<Array<any>>, default: () => [] },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
    };
  },
  computed: { },
  watch: {
    category() { this.reset(); },
    dataset() { this.reset(); },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset() {
      const maxValue = this.dataset.map((e) => e[2]).reduce((p, c) => (p >= c ? p : c), 0);
      const remap = this.$localized.weekdays().reverse().map((w) => weekdayStartAtMondayFromDateDay(w.value));
      const series = [
        {
          type: 'scatter',
          itemStyle: {
            color: '#3355ff',
            opacity: 0.9,
          },
          symbolSize(val: any) {
            return (val[2] / maxValue) * 30;
          },
          data: this.dataset.map((v) => [v[0], remap[v[1]], v[2]]),
          animationDelay(idx: number) {
            return 6 - idx;
          },
        },
      ];

      return {
        grid: {
          left: '32px',
          top: '16px',
          right: '32px',
          bottom: '48px',
        },
        tooltip: {
          trigger: 'axis',
          formatter(params: Array<any>) {
            const body = params.map((p) => {
              const weekday = localized.longWeekdays().find(
                (w) => weekdayStartAtMondayFromDateDay(w.value) === remap[p.data[1]],
              );
              return `${weekday?.text}: <span class="font-weight-bold float-right ml-6">${p.data[2]}</span>`;
            }).join('<br/>');
            const head = `<div class="pb-2" style="font-size: 14px">${params[0].axisValueLabel}</div>`;
            console.log(params);
            return head + body;
          },
        },
        legend: {
          show: false,
          orient: 'horizontal',
          bottom: 'bottom',
        },
        xAxis: {
          type: 'category',
          data: this.category.map((d) => this.$localized.hourMinText(d)!),
          splitLine: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          data: this.$localized.weekdays().map((wk) => (wk.text)).reverse(),
        },
        series,
      };
    },
  },
});

