/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import Tab from '@/model/Tab';
import Reservation from '@/model/Reservation';
import { groupByField, cloneModel } from '@/model/model-utils';
import TabItem from '@/model/TabItem';

function allocateNoTabItemsReservations(reservations: Reservation[]): TabItem[] {
  const rss: Reservation[][] = []; // array of arrays of non overlapping reservations
  const tis: TabItem[] = []; // dummy tab items for no-tab-item reservations

  // for all no-tab-item reservations
  reservations.forEach((r) => {
    // find array to add reservation
    for (let i = 0; i < rss.length; i += 1) {
      // get last reservation from array and check end time
      const lastr = rss[i][rss[i].length - 1];
      if (lastr.timeEnd <= r.timeBegin) {
        r.tabItems = [lastr.tabItems[0]]; // add dummy tab item
        rss[i].push(r); // add reservation
        break; // continue with the next reservation
      }
    }

    // no array to add reservation found
    if (r.tabItems.length === 0) {
      // new dummy tab item
      const nti = new TabItem();
      nti.id = -(rss.length + 1);
      nti.order = nti.id;
      tis.push(nti);
      r.tabItems = [nti]; // add dummy tab item
      rss.push([r]); // add new array with this reservation
    }
  });

  return tis;
}

export function timetableEntities(tabs: Tab[], reservations: Reservation[]):
{ tabs: Tab[], reservations: Reservation[] } {
  const tmReservations: Reservation[] = []; // timetable reservations
  const notiReservations: Reservation[] = []; // clones of no-tab-item reservations

  // get timetable reservations and clones
  reservations.forEach((r) => {
    if (r.tabItems.length > 0) { tmReservations.push(r); return; }
    const cr = cloneModel(r);
    tmReservations.push(cr);
    notiReservations.push(cr);
  });

  // group no-tab-item reservations by tab
  const notiReservationsByTabs = groupByField(notiReservations, 'tab') as Map<Tab, Reservation[]>;

  // process tabs
  const tmTabs: Tab[] = []; // timetable tabs
  tabs.forEach((tab) => {
    // get no-tab-item reservations for tab
    const nrs = notiReservationsByTabs.get(tab);

    // no no-tab-item reservations
    if (!nrs) {
      // no need to change tab
      if (tab.tabItems.length > 0) tmTabs.push(tab);

      // empty tab, just add one tab item
      else {
        const nti = new TabItem();
        nti.id = -1;
        nti.order = nti.id;
        const ctab = cloneModel(tab);
        ctab.tabItems = [nti];
        tmTabs.push(ctab);
      }
      return;
    }

    // allocate no-tab-item reservations
    const ctab = cloneModel(tab);
    ctab.tabItems = [...allocateNoTabItemsReservations(nrs), ...tab.tabItems];
    tmTabs.push(ctab);
  });

  // unique tab item id
  let tiid = -1;
  tmTabs.forEach((t) => t.tabItems.forEach((ti) => {
    if (ti.id > 0) return;
    ti.id = tiid;
    tiid -= 1;
  }));

  return {
    tabs: tmTabs,
    reservations: tmReservations,
  };
}
