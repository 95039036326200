/* eslint-disable import/prefer-default-export */

export enum ReportsType {
  All = 0,

  Reservations = 1,
  Online = 2,
  Services = 4,
  Weekdays = 8,
  Messages = 0x10,
  Feedback = 0x20,
}
