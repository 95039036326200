import activityIndicator from '@/plugins/activity-indicator-plugin/activity-indicator';
import i18n from '@/plugins/i18n';
import toast from '@/plugins/toast-plugin/toast';
import store from '@/store/store';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';

export function actionShowError(error: any, errorPrefix?: string) {
  let errorText = errorPrefix;
  if (error.message && errorPrefix) {
    const pdot = errorPrefix.endsWith('.') ? '' : '.';
    const edot = error.message.endsWith('.') ? '' : '.';
    errorText = `${errorPrefix}${pdot} ${error.message}${edot}`;
  } else if (error.message) errorText = error.message;

  if (!errorText) errorText = i18n.tc('error.general');
  toast.error(errorText);
}

export async function performAction(
  successText: string | null,
  errorPrefix: string | null,
  action: () => Promise<void>,
  delayedSuccess?: boolean,
  rethrow?: boolean,
): Promise<boolean> {
  try {
    activityIndicator.show();
    await action();
    activityIndicator.hide();
    if (successText) {
      if (delayedSuccess) toast.successDelayed(successText); else toast.success(successText);
    }
    return true;
  } catch (e) {
    activityIndicator.hide();
    console.error('performAction catched error: ', e);
    if (errorPrefix !== null) actionShowError(e, errorPrefix);

    // let errorText = errorPrefix;
    // if (e.message) { errorText = `${errorPrefix}. ${e.message}.`; }
    // toast.error(errorText);
    if (rethrow) throw e;
    return false;
  }
}

export async function performActionEx<T>(
  action: () => Promise<T|null>,
  p?: {
    successText?: string,
    errorPrefix?: string,
    errorFnc?: (e: any) => void,
    delayed?: boolean,
    rethrow?: boolean,
    hideActivityIndicator?: boolean
  },
): Promise<T|null> {
  try {
    if (!p?.hideActivityIndicator) activityIndicator.show();
    const res = await action();
    if (!p?.hideActivityIndicator) activityIndicator.hide();
    if (p?.successText) {
      if (p?.delayed) toast.successDelayed(p?.successText); else toast.success(p?.successText);
    }
    return res;
  } catch (e) {
    activityIndicator.hide();
    console.error('performActionEx catched error: ', e);
    if (p?.errorFnc) p?.errorFnc(e); else if (p?.rethrow !== true) actionShowError(e, p?.errorPrefix);
    if (p?.rethrow) throw e;
    return null;
  }
}

export async function performDispatchAction<T>(
  dispatch: string,
  dispatchParams: any,
  p?: {
    successText?: string,
    errorPrefix?: string,
    errorFnc?: (e: any) => void,
    delayed?: boolean,
    rethrow?: boolean,
    streamLoad?: boolean,
  },
) {
  // eslint-disable-next-line no-param-reassign
  if (!p) p = {};

  // eslint-disable-next-line no-param-reassign
  if (!p.errorPrefix) p.errorPrefix = p.streamLoad ? i18n.tc('error.load_data') : i18n.tc('error.general');
  const errorFnc = p.errorFnc ?? ((e: any) => { if (p?.rethrow !== true) actionShowError(e, p?.errorPrefix); });
  // eslint-disable-next-line no-param-reassign
  dispatchParams = { ...dispatchParams, errorFnc };

  const action = () => store.dispatch(dispatch, dispatchParams);
  return performActionEx<T>(action, p);
}

export function confirmLeaveWithoutSaving(vue: Vue): boolean {
  // eslint-disable-next-line no-alert
  return window.confirm(i18n.tc('message.confirm_leave_without_saving'));
}

export async function performSaveAction(
  observer: any,
  action: () => Promise<void>,
  p?: { hideSuccess?: boolean },
): Promise<boolean> {
  const isValid = await (observer as InstanceType<typeof ValidationObserver> | undefined)?.validate() ?? true;
  if (!isValid) {
    toast.error(i18n.tc('error.validation_failed'));
    return false;
  }
  return performAction(
    p?.hideSuccess ? null : i18n.tc('message.success_changes_saved'),
    i18n.tc('error.saving_changes'),
    action,
  );
}

// ignore warning
// The.native modifier for v - on is only valid on components but it was used on<div>.
if (process.env.NODE_ENV === 'development') {
  const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
  Vue.config.warnHandler = (msg: any, vm: any, trace: any) => {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
      // eslint-disable-next-line no-param-reassign
      msg = null;
      // eslint-disable-next-line no-param-reassign
      vm = null;
      // eslint-disable-next-line no-param-reassign
      trace = null;
    }
  };
}
