
import Vue from 'vue';
import SearchList from '@/components/views/main/SearchList.vue';

export default Vue.extend({
  name: 'Search',
  components: {
    SearchList,
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
});

