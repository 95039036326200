
import Vue from 'vue';
import Sortable from 'sortablejs';
import { isModelEqualToModel, IModel, cloneModel } from '@/model/model-utils';
import { performSaveAction, confirmLeaveWithoutSaving } from '@/services/vue-utils';
import Tab from '@/model/Tab';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import InputDialog from '@/components/dialogs/InputDialog.vue';
import SettingsForm from '@/components/layouts/SettingsForm.vue';

export default Vue.extend({
  name: 'Sections',
  components: { InputDialog, SettingsForm },
  mixins: [SettingsBreadcrumbs],
  data() {
    return {
      newSectionDialog: false,
      newSectionName: '',
      sections: [] as Tab[],
      oldSections: [] as Tab[],
      unwatch: Function as (() => void),
      breadcrumbs: [],
      headers: [
        {
          text: '',
          width: '32',
          value: 'id',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.tab_name'),
          value: 'name',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.online'),
          width: '32',
          value: 'isOnline',
          align: 'start',
          sortable: false,
        },
      ],
    };
  },
  computed: { },
  created() {
    this.unwatch = this.$tstore.watch((state, getters) => getters.isLoaded, (newValue, oldValue) => {
      this.reset();
    });
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    this.reset();
  },
  methods: {
    changeOrder(event: Sortable.SortableEvent) {
      if (event.oldIndex === undefined || event.newIndex === undefined) return;
      const movedItem = this.sections.splice(event.oldIndex, 1)[0];
      this.sections.splice(event.newIndex, 0, movedItem);
      this.save();
    },
    async save() {
      const entities = this.sections;
      await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch('sendTabs', { entities }),
      );
      this.reset();
    },
    showNewSection() {
      this.reset();
      this.newSectionDialog = true;
      this.newSectionName = '';
    },
    async add(newSectionName: string) {
      const section = new Tab();
      section.name = newSectionName;
      const entities = this.$tstore.state.settings.tabs.map((o) => cloneModel(o));
      entities.push(section);

      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch('sendTabs', { entities }),
      );
      if (ok) {
        this.reset();
        const newSection = this.sections.filter((o) => o.name === newSectionName).pop();
        if (newSection) this.edit(newSection);
      } else {
        this.newSectionDialog = true;
      }
    },
    edit(section: Tab) {
      this.$router.push(`/settings/sections/${section.id}`);
    },
    reset() {
      const { tabs } = this.$tstore.state.settings;
      this.oldSections = tabs;
      this.sections = tabs.map((o) => cloneModel(o));
    },
  },
});
