

import Vue from 'vue';

import SimpleLineChart from '@/components/views/reports/SimpleLineChart.vue';
import StackedLineChart from '@/components/views/reports/StackedLineChart.vue';
import MiniDataChart from '@/components/views/reports/MiniDataChart.vue';
import ReportsToolbar from '@/components/views/reports/ReportsToolbar.vue';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import ReportsStore from '@/store/reports-store';
import { ChartData } from '@/services/reports-utils';
import { ReportsType } from '@/services/reports';

export default Vue.extend({
  name: 'ReportReservations',
  components: {
    SimpleLineChart,
    MiniDataChart,
    ReportsToolbar,
    StackedLineChart,
  },
  data() {
    return {};
  },
  computed: {
    compare(): boolean { return this.$tstore.getters.reportsComparePrevious; },
    reservations(): ChartData { return this.$tstore.getters.reportsReservations; },
    guests(): ChartData { return this.$tstore.getters.reportsGuests; },
    campaignReservations(): ChartData { return this.$tstore.getters.reportsCampaignsReservations; },
    campaignGuests(): ChartData { return this.$tstore.getters.reportsCampaignsGuests; },
    returning(): ChartData { return this.$tstore.getters.reportsReturning; },
    cancellations(): ChartData { return this.$tstore.getters.reportsCancelations; },
    noshows(): ChartData { return this.$tstore.getters.reportsNoshows; },
    partySizes(): ChartData { return this.$tstore.getters.reportsPartySizes; },
    compareSeries(): string[] {
      return this.compare
        ? [this.$i18n.tc('label.report_this_period'), this.$i18n.tc('label.report_previous_period')]
        : [this.$i18n.tc('label.report_this_period')];
    },
  },
  created() {
    loadStoreModule(this, 'reports', ReportsStore, async () => {
      this.$tstore.dispatch('updateReports', { reportsType: ReportsType.Reservations });
    });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'reports', async () => { this.$tstore.dispatch('resetReports'); });
  },
  methods: {},
});

