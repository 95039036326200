// source: reports_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.grpc.api.v1.CampaignReports', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackReports', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackReportsBin', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackReportsBinStars', null, global);
goog.exportSymbol('proto.grpc.api.v1.GuestsReports', null, global);
goog.exportSymbol('proto.grpc.api.v1.MsgReports', null, global);
goog.exportSymbol('proto.grpc.api.v1.MsgsReportsResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReportsParams', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReportsParams.ReportsBinType', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReportsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ServiceReports', null, global);
goog.exportSymbol('proto.grpc.api.v1.TabReports', null, global);
goog.exportSymbol('proto.grpc.api.v1.TabsReportsResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.WeekdayReports', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ReportsParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ReportsParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ReportsParams.displayName = 'proto.grpc.api.v1.ReportsParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ReportsRequest.displayName = 'proto.grpc.api.v1.ReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GuestsReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.GuestsReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.GuestsReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GuestsReports.displayName = 'proto.grpc.api.v1.GuestsReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.CampaignReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.CampaignReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.CampaignReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.CampaignReports.displayName = 'proto.grpc.api.v1.CampaignReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ServiceReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.ServiceReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.ServiceReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ServiceReports.displayName = 'proto.grpc.api.v1.ServiceReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.WeekdayReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.WeekdayReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.WeekdayReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.WeekdayReports.displayName = 'proto.grpc.api.v1.WeekdayReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackReportsBinStars = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.FeedbackReportsBinStars.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackReportsBinStars, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackReportsBinStars.displayName = 'proto.grpc.api.v1.FeedbackReportsBinStars';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackReportsBin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackReportsBin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackReportsBin.displayName = 'proto.grpc.api.v1.FeedbackReportsBin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.FeedbackReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackReports.displayName = 'proto.grpc.api.v1.FeedbackReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.TabReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.TabReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.TabReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.TabReports.displayName = 'proto.grpc.api.v1.TabReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MsgReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.MsgReports.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.MsgReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MsgReports.displayName = 'proto.grpc.api.v1.MsgReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.TabsReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.TabsReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.TabsReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.TabsReportsResponse.displayName = 'proto.grpc.api.v1.TabsReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MsgsReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.MsgsReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.MsgsReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MsgsReportsResponse.displayName = 'proto.grpc.api.v1.MsgsReportsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ReportsParams.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ReportsParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ReportsParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReportsParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    beginDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    binType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bins: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ReportsParams}
 */
proto.grpc.api.v1.ReportsParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ReportsParams;
  return proto.grpc.api.v1.ReportsParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ReportsParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ReportsParams}
 */
proto.grpc.api.v1.ReportsParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeginDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {!proto.grpc.api.v1.ReportsParams.ReportsBinType} */ (reader.readEnum());
      msg.setBinType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ReportsParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ReportsParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ReportsParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReportsParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeginDate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBinType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBins();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.grpc.api.v1.ReportsParams.ReportsBinType = {
  NONE: 0,
  BY_HOURS: 1,
  BY_DAYS: 2,
  BY_MONTHS: 3,
  BY_YEARS: 4,
  OVER_DAY: 12,
  OVER_YEAR: 14
};

/**
 * optional int32 begin_date = 2;
 * @return {number}
 */
proto.grpc.api.v1.ReportsParams.prototype.getBeginDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReportsParams} returns this
 */
proto.grpc.api.v1.ReportsParams.prototype.setBeginDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 end_date = 3;
 * @return {number}
 */
proto.grpc.api.v1.ReportsParams.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReportsParams} returns this
 */
proto.grpc.api.v1.ReportsParams.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ReportsBinType bin_type = 4;
 * @return {!proto.grpc.api.v1.ReportsParams.ReportsBinType}
 */
proto.grpc.api.v1.ReportsParams.prototype.getBinType = function() {
  return /** @type {!proto.grpc.api.v1.ReportsParams.ReportsBinType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.grpc.api.v1.ReportsParams.ReportsBinType} value
 * @return {!proto.grpc.api.v1.ReportsParams} returns this
 */
proto.grpc.api.v1.ReportsParams.prototype.setBinType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 bins = 5;
 * @return {number}
 */
proto.grpc.api.v1.ReportsParams.prototype.getBins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReportsParams} returns this
 */
proto.grpc.api.v1.ReportsParams.prototype.setBins = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.ReportsParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ReportsRequest}
 */
proto.grpc.api.v1.ReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ReportsRequest;
  return proto.grpc.api.v1.ReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ReportsRequest}
 */
proto.grpc.api.v1.ReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.ReportsParams;
      reader.readMessage(value,proto.grpc.api.v1.ReportsParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.ReportsParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportsParams params = 1;
 * @return {?proto.grpc.api.v1.ReportsParams}
 */
proto.grpc.api.v1.ReportsRequest.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.ReportsParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.ReportsParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.ReportsParams|undefined} value
 * @return {!proto.grpc.api.v1.ReportsRequest} returns this
*/
proto.grpc.api.v1.ReportsRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ReportsRequest} returns this
 */
proto.grpc.api.v1.ReportsRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ReportsRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.GuestsReports.repeatedFields_ = [3,4,5,6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GuestsReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GuestsReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GuestsReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GuestsReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    guestsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    returningList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    cancelationsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    noshowsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    partySizeSumsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    partySizeCountsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GuestsReports}
 */
proto.grpc.api.v1.GuestsReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GuestsReports;
  return proto.grpc.api.v1.GuestsReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GuestsReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GuestsReports}
 */
proto.grpc.api.v1.GuestsReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReservations(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGuests(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReturning(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCancelations(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNoshows(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPartySizeSums(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPartySizeCounts(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GuestsReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GuestsReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GuestsReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GuestsReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getGuestsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getReturningList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getCancelationsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getNoshowsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getPartySizeSumsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      8,
      f
    );
  }
  f = message.getPartySizeCountsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      9,
      f
    );
  }
};


/**
 * repeated int32 reservations = 3;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getReservationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setReservationsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addReservations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * repeated int32 guests = 4;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getGuestsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setGuestsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addGuests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearGuestsList = function() {
  return this.setGuestsList([]);
};


/**
 * repeated int32 returning = 5;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getReturningList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setReturningList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addReturning = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearReturningList = function() {
  return this.setReturningList([]);
};


/**
 * repeated int32 cancelations = 6;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getCancelationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setCancelationsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addCancelations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearCancelationsList = function() {
  return this.setCancelationsList([]);
};


/**
 * repeated int32 noshows = 7;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getNoshowsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setNoshowsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addNoshows = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearNoshowsList = function() {
  return this.setNoshowsList([]);
};


/**
 * repeated float party_size_sums = 8;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getPartySizeSumsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setPartySizeSumsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addPartySizeSums = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearPartySizeSumsList = function() {
  return this.setPartySizeSumsList([]);
};


/**
 * repeated float party_size_counts = 9;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.GuestsReports.prototype.getPartySizeCountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.setPartySizeCountsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.addPartySizeCounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GuestsReports} returns this
 */
proto.grpc.api.v1.GuestsReports.prototype.clearPartySizeCountsList = function() {
  return this.setPartySizeCountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.CampaignReports.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.CampaignReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.CampaignReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.CampaignReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.CampaignReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reservationsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    guestsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    daysAdvanceSumsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    daysAdvanceCountsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.CampaignReports}
 */
proto.grpc.api.v1.CampaignReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.CampaignReports;
  return proto.grpc.api.v1.CampaignReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.CampaignReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.CampaignReports}
 */
proto.grpc.api.v1.CampaignReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignName(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReservations(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGuests(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDaysAdvanceSums(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDaysAdvanceCounts(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.CampaignReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.CampaignReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.CampaignReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.CampaignReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getGuestsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getDaysAdvanceSumsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      5,
      f
    );
  }
  f = message.getDaysAdvanceCountsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      6,
      f
    );
  }
};


/**
 * optional string campaign_name = 2;
 * @return {string}
 */
proto.grpc.api.v1.CampaignReports.prototype.getCampaignName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.setCampaignName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 reservations = 3;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.CampaignReports.prototype.getReservationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.setReservationsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.addReservations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * repeated int32 guests = 4;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.CampaignReports.prototype.getGuestsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.setGuestsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.addGuests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.clearGuestsList = function() {
  return this.setGuestsList([]);
};


/**
 * repeated float days_advance_sums = 5;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.CampaignReports.prototype.getDaysAdvanceSumsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.setDaysAdvanceSumsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.addDaysAdvanceSums = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.clearDaysAdvanceSumsList = function() {
  return this.setDaysAdvanceSumsList([]);
};


/**
 * repeated float days_advance_counts = 6;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.CampaignReports.prototype.getDaysAdvanceCountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.setDaysAdvanceCountsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.addDaysAdvanceCounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.CampaignReports} returns this
 */
proto.grpc.api.v1.CampaignReports.prototype.clearDaysAdvanceCountsList = function() {
  return this.setDaysAdvanceCountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.ServiceReports.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ServiceReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ServiceReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ServiceReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ServiceReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    serviceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    revenueCurrency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reservationsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    guestsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    revenueSumsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    revenueCountsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ServiceReports}
 */
proto.grpc.api.v1.ServiceReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ServiceReports;
  return proto.grpc.api.v1.ServiceReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ServiceReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ServiceReports}
 */
proto.grpc.api.v1.ServiceReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevenueCurrency(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReservations(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGuests(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRevenueSums(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRevenueCounts(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ServiceReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ServiceReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ServiceReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ServiceReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRevenueCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getGuestsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getRevenueSumsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      6,
      f
    );
  }
  f = message.getRevenueCountsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      7,
      f
    );
  }
};


/**
 * optional int64 service_id = 1;
 * @return {number}
 */
proto.grpc.api.v1.ServiceReports.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string service_name = 2;
 * @return {string}
 */
proto.grpc.api.v1.ServiceReports.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string revenue_currency = 3;
 * @return {string}
 */
proto.grpc.api.v1.ServiceReports.prototype.getRevenueCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setRevenueCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 reservations = 4;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.ServiceReports.prototype.getReservationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setReservationsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.addReservations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * repeated int32 guests = 5;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.ServiceReports.prototype.getGuestsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setGuestsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.addGuests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.clearGuestsList = function() {
  return this.setGuestsList([]);
};


/**
 * repeated float revenue_sums = 6;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.ServiceReports.prototype.getRevenueSumsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setRevenueSumsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.addRevenueSums = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.clearRevenueSumsList = function() {
  return this.setRevenueSumsList([]);
};


/**
 * repeated float revenue_counts = 7;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.ServiceReports.prototype.getRevenueCountsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.setRevenueCountsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.addRevenueCounts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ServiceReports} returns this
 */
proto.grpc.api.v1.ServiceReports.prototype.clearRevenueCountsList = function() {
  return this.setRevenueCountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.WeekdayReports.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.WeekdayReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.WeekdayReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.WeekdayReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.WeekdayReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekday: jspb.Message.getFieldWithDefault(msg, 1, 0),
    guests: (f = msg.getGuests()) && proto.grpc.api.v1.GuestsReports.toObject(includeInstance, f),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.grpc.api.v1.ServiceReports.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.WeekdayReports}
 */
proto.grpc.api.v1.WeekdayReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.WeekdayReports;
  return proto.grpc.api.v1.WeekdayReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.WeekdayReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.WeekdayReports}
 */
proto.grpc.api.v1.WeekdayReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeekday(value);
      break;
    case 3:
      var value = new proto.grpc.api.v1.GuestsReports;
      reader.readMessage(value,proto.grpc.api.v1.GuestsReports.deserializeBinaryFromReader);
      msg.setGuests(value);
      break;
    case 5:
      var value = new proto.grpc.api.v1.ServiceReports;
      reader.readMessage(value,proto.grpc.api.v1.ServiceReports.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.WeekdayReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.WeekdayReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.WeekdayReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.WeekdayReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekday();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGuests();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.api.v1.GuestsReports.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.grpc.api.v1.ServiceReports.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 weekday = 1;
 * @return {number}
 */
proto.grpc.api.v1.WeekdayReports.prototype.getWeekday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.WeekdayReports} returns this
 */
proto.grpc.api.v1.WeekdayReports.prototype.setWeekday = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GuestsReports guests = 3;
 * @return {?proto.grpc.api.v1.GuestsReports}
 */
proto.grpc.api.v1.WeekdayReports.prototype.getGuests = function() {
  return /** @type{?proto.grpc.api.v1.GuestsReports} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.GuestsReports, 3));
};


/**
 * @param {?proto.grpc.api.v1.GuestsReports|undefined} value
 * @return {!proto.grpc.api.v1.WeekdayReports} returns this
*/
proto.grpc.api.v1.WeekdayReports.prototype.setGuests = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.WeekdayReports} returns this
 */
proto.grpc.api.v1.WeekdayReports.prototype.clearGuests = function() {
  return this.setGuests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.WeekdayReports.prototype.hasGuests = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ServiceReports services = 5;
 * @return {!Array<!proto.grpc.api.v1.ServiceReports>}
 */
proto.grpc.api.v1.WeekdayReports.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.ServiceReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.ServiceReports, 5));
};


/**
 * @param {!Array<!proto.grpc.api.v1.ServiceReports>} value
 * @return {!proto.grpc.api.v1.WeekdayReports} returns this
*/
proto.grpc.api.v1.WeekdayReports.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.grpc.api.v1.ServiceReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ServiceReports}
 */
proto.grpc.api.v1.WeekdayReports.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.grpc.api.v1.ServiceReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.WeekdayReports} returns this
 */
proto.grpc.api.v1.WeekdayReports.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.FeedbackReportsBinStars.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackReportsBinStars.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackReportsBinStars.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackReportsBinStars} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReportsBinStars.toObject = function(includeInstance, msg) {
  var f, obj = {
    starsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackReportsBinStars}
 */
proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackReportsBinStars;
  return proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackReportsBinStars} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackReportsBinStars}
 */
proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStars(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackReportsBinStars.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackReportsBinStars.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackReportsBinStars} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReportsBinStars.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 stars = 1;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.FeedbackReportsBinStars.prototype.getStarsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.FeedbackReportsBinStars} returns this
 */
proto.grpc.api.v1.FeedbackReportsBinStars.prototype.setStarsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.FeedbackReportsBinStars} returns this
 */
proto.grpc.api.v1.FeedbackReportsBinStars.prototype.addStars = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.FeedbackReportsBinStars} returns this
 */
proto.grpc.api.v1.FeedbackReportsBinStars.prototype.clearStarsList = function() {
  return this.setStarsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackReportsBin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackReportsBin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReportsBin.toObject = function(includeInstance, msg) {
  var f, obj = {
    overall: (f = msg.getOverall()) && proto.grpc.api.v1.FeedbackReportsBinStars.toObject(includeInstance, f),
    food: (f = msg.getFood()) && proto.grpc.api.v1.FeedbackReportsBinStars.toObject(includeInstance, f),
    service: (f = msg.getService()) && proto.grpc.api.v1.FeedbackReportsBinStars.toObject(includeInstance, f),
    ambience: (f = msg.getAmbience()) && proto.grpc.api.v1.FeedbackReportsBinStars.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackReportsBin}
 */
proto.grpc.api.v1.FeedbackReportsBin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackReportsBin;
  return proto.grpc.api.v1.FeedbackReportsBin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackReportsBin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackReportsBin}
 */
proto.grpc.api.v1.FeedbackReportsBin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.FeedbackReportsBinStars;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinaryFromReader);
      msg.setOverall(value);
      break;
    case 2:
      var value = new proto.grpc.api.v1.FeedbackReportsBinStars;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinaryFromReader);
      msg.setFood(value);
      break;
    case 3:
      var value = new proto.grpc.api.v1.FeedbackReportsBinStars;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    case 4:
      var value = new proto.grpc.api.v1.FeedbackReportsBinStars;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackReportsBinStars.deserializeBinaryFromReader);
      msg.setAmbience(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackReportsBin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackReportsBin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReportsBin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverall();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.FeedbackReportsBinStars.serializeBinaryToWriter
    );
  }
  f = message.getFood();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.api.v1.FeedbackReportsBinStars.serializeBinaryToWriter
    );
  }
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.api.v1.FeedbackReportsBinStars.serializeBinaryToWriter
    );
  }
  f = message.getAmbience();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpc.api.v1.FeedbackReportsBinStars.serializeBinaryToWriter
    );
  }
};


/**
 * optional FeedbackReportsBinStars overall = 1;
 * @return {?proto.grpc.api.v1.FeedbackReportsBinStars}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.getOverall = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackReportsBinStars} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.FeedbackReportsBinStars, 1));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackReportsBinStars|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
*/
proto.grpc.api.v1.FeedbackReportsBin.prototype.setOverall = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.clearOverall = function() {
  return this.setOverall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.hasOverall = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FeedbackReportsBinStars food = 2;
 * @return {?proto.grpc.api.v1.FeedbackReportsBinStars}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.getFood = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackReportsBinStars} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.FeedbackReportsBinStars, 2));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackReportsBinStars|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
*/
proto.grpc.api.v1.FeedbackReportsBin.prototype.setFood = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.clearFood = function() {
  return this.setFood(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.hasFood = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FeedbackReportsBinStars service = 3;
 * @return {?proto.grpc.api.v1.FeedbackReportsBinStars}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.getService = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackReportsBinStars} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.FeedbackReportsBinStars, 3));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackReportsBinStars|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
*/
proto.grpc.api.v1.FeedbackReportsBin.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.hasService = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FeedbackReportsBinStars ambience = 4;
 * @return {?proto.grpc.api.v1.FeedbackReportsBinStars}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.getAmbience = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackReportsBinStars} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.FeedbackReportsBinStars, 4));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackReportsBinStars|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
*/
proto.grpc.api.v1.FeedbackReportsBin.prototype.setAmbience = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackReportsBin} returns this
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.clearAmbience = function() {
  return this.setAmbience(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackReportsBin.prototype.hasAmbience = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.FeedbackReports.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    binsList: jspb.Message.toObjectList(msg.getBinsList(),
    proto.grpc.api.v1.FeedbackReportsBin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackReports}
 */
proto.grpc.api.v1.FeedbackReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackReports;
  return proto.grpc.api.v1.FeedbackReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackReports}
 */
proto.grpc.api.v1.FeedbackReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.FeedbackReportsBin;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackReportsBin.deserializeBinaryFromReader);
      msg.addBins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBinsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.api.v1.FeedbackReportsBin.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FeedbackReportsBin bins = 1;
 * @return {!Array<!proto.grpc.api.v1.FeedbackReportsBin>}
 */
proto.grpc.api.v1.FeedbackReports.prototype.getBinsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.FeedbackReportsBin>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.FeedbackReportsBin, 1));
};


/**
 * @param {!Array<!proto.grpc.api.v1.FeedbackReportsBin>} value
 * @return {!proto.grpc.api.v1.FeedbackReports} returns this
*/
proto.grpc.api.v1.FeedbackReports.prototype.setBinsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.api.v1.FeedbackReportsBin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.FeedbackReportsBin}
 */
proto.grpc.api.v1.FeedbackReports.prototype.addBins = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.api.v1.FeedbackReportsBin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.FeedbackReports} returns this
 */
proto.grpc.api.v1.FeedbackReports.prototype.clearBinsList = function() {
  return this.setBinsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.TabReports.repeatedFields_ = [4,5,6,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.TabReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.TabReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.TabReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.TabReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    guests: (f = msg.getGuests()) && proto.grpc.api.v1.GuestsReports.toObject(includeInstance, f),
    campaignsList: jspb.Message.toObjectList(msg.getCampaignsList(),
    proto.grpc.api.v1.CampaignReports.toObject, includeInstance),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.grpc.api.v1.ServiceReports.toObject, includeInstance),
    weekdaysList: jspb.Message.toObjectList(msg.getWeekdaysList(),
    proto.grpc.api.v1.WeekdayReports.toObject, includeInstance),
    guestsDtc: (f = msg.getGuestsDtc()) && proto.grpc.api.v1.GuestsReports.toObject(includeInstance, f),
    campaignsDtcList: jspb.Message.toObjectList(msg.getCampaignsDtcList(),
    proto.grpc.api.v1.CampaignReports.toObject, includeInstance),
    feedbacks: (f = msg.getFeedbacks()) && proto.grpc.api.v1.FeedbackReports.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.TabReports}
 */
proto.grpc.api.v1.TabReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.TabReports;
  return proto.grpc.api.v1.TabReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.TabReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.TabReports}
 */
proto.grpc.api.v1.TabReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTabId(value);
      break;
    case 3:
      var value = new proto.grpc.api.v1.GuestsReports;
      reader.readMessage(value,proto.grpc.api.v1.GuestsReports.deserializeBinaryFromReader);
      msg.setGuests(value);
      break;
    case 4:
      var value = new proto.grpc.api.v1.CampaignReports;
      reader.readMessage(value,proto.grpc.api.v1.CampaignReports.deserializeBinaryFromReader);
      msg.addCampaigns(value);
      break;
    case 5:
      var value = new proto.grpc.api.v1.ServiceReports;
      reader.readMessage(value,proto.grpc.api.v1.ServiceReports.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 6:
      var value = new proto.grpc.api.v1.WeekdayReports;
      reader.readMessage(value,proto.grpc.api.v1.WeekdayReports.deserializeBinaryFromReader);
      msg.addWeekdays(value);
      break;
    case 13:
      var value = new proto.grpc.api.v1.GuestsReports;
      reader.readMessage(value,proto.grpc.api.v1.GuestsReports.deserializeBinaryFromReader);
      msg.setGuestsDtc(value);
      break;
    case 14:
      var value = new proto.grpc.api.v1.CampaignReports;
      reader.readMessage(value,proto.grpc.api.v1.CampaignReports.deserializeBinaryFromReader);
      msg.addCampaignsDtc(value);
      break;
    case 21:
      var value = new proto.grpc.api.v1.FeedbackReports;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackReports.deserializeBinaryFromReader);
      msg.setFeedbacks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.TabReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.TabReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.TabReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.TabReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGuests();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.api.v1.GuestsReports.serializeBinaryToWriter
    );
  }
  f = message.getCampaignsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grpc.api.v1.CampaignReports.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.grpc.api.v1.ServiceReports.serializeBinaryToWriter
    );
  }
  f = message.getWeekdaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.grpc.api.v1.WeekdayReports.serializeBinaryToWriter
    );
  }
  f = message.getGuestsDtc();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.grpc.api.v1.GuestsReports.serializeBinaryToWriter
    );
  }
  f = message.getCampaignsDtcList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.grpc.api.v1.CampaignReports.serializeBinaryToWriter
    );
  }
  f = message.getFeedbacks();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.grpc.api.v1.FeedbackReports.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 tab_id = 1;
 * @return {number}
 */
proto.grpc.api.v1.TabReports.prototype.getTabId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.setTabId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GuestsReports guests = 3;
 * @return {?proto.grpc.api.v1.GuestsReports}
 */
proto.grpc.api.v1.TabReports.prototype.getGuests = function() {
  return /** @type{?proto.grpc.api.v1.GuestsReports} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.GuestsReports, 3));
};


/**
 * @param {?proto.grpc.api.v1.GuestsReports|undefined} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setGuests = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearGuests = function() {
  return this.setGuests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.TabReports.prototype.hasGuests = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated CampaignReports campaigns = 4;
 * @return {!Array<!proto.grpc.api.v1.CampaignReports>}
 */
proto.grpc.api.v1.TabReports.prototype.getCampaignsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.CampaignReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.CampaignReports, 4));
};


/**
 * @param {!Array<!proto.grpc.api.v1.CampaignReports>} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setCampaignsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grpc.api.v1.CampaignReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.CampaignReports}
 */
proto.grpc.api.v1.TabReports.prototype.addCampaigns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grpc.api.v1.CampaignReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearCampaignsList = function() {
  return this.setCampaignsList([]);
};


/**
 * repeated ServiceReports services = 5;
 * @return {!Array<!proto.grpc.api.v1.ServiceReports>}
 */
proto.grpc.api.v1.TabReports.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.ServiceReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.ServiceReports, 5));
};


/**
 * @param {!Array<!proto.grpc.api.v1.ServiceReports>} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.grpc.api.v1.ServiceReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ServiceReports}
 */
proto.grpc.api.v1.TabReports.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.grpc.api.v1.ServiceReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated WeekdayReports weekdays = 6;
 * @return {!Array<!proto.grpc.api.v1.WeekdayReports>}
 */
proto.grpc.api.v1.TabReports.prototype.getWeekdaysList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.WeekdayReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.WeekdayReports, 6));
};


/**
 * @param {!Array<!proto.grpc.api.v1.WeekdayReports>} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setWeekdaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.grpc.api.v1.WeekdayReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.WeekdayReports}
 */
proto.grpc.api.v1.TabReports.prototype.addWeekdays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.grpc.api.v1.WeekdayReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearWeekdaysList = function() {
  return this.setWeekdaysList([]);
};


/**
 * optional GuestsReports guests_dtc = 13;
 * @return {?proto.grpc.api.v1.GuestsReports}
 */
proto.grpc.api.v1.TabReports.prototype.getGuestsDtc = function() {
  return /** @type{?proto.grpc.api.v1.GuestsReports} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.GuestsReports, 13));
};


/**
 * @param {?proto.grpc.api.v1.GuestsReports|undefined} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setGuestsDtc = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearGuestsDtc = function() {
  return this.setGuestsDtc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.TabReports.prototype.hasGuestsDtc = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated CampaignReports campaigns_dtc = 14;
 * @return {!Array<!proto.grpc.api.v1.CampaignReports>}
 */
proto.grpc.api.v1.TabReports.prototype.getCampaignsDtcList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.CampaignReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.CampaignReports, 14));
};


/**
 * @param {!Array<!proto.grpc.api.v1.CampaignReports>} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setCampaignsDtcList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.grpc.api.v1.CampaignReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.CampaignReports}
 */
proto.grpc.api.v1.TabReports.prototype.addCampaignsDtc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.grpc.api.v1.CampaignReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearCampaignsDtcList = function() {
  return this.setCampaignsDtcList([]);
};


/**
 * optional FeedbackReports feedbacks = 21;
 * @return {?proto.grpc.api.v1.FeedbackReports}
 */
proto.grpc.api.v1.TabReports.prototype.getFeedbacks = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackReports} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.FeedbackReports, 21));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackReports|undefined} value
 * @return {!proto.grpc.api.v1.TabReports} returns this
*/
proto.grpc.api.v1.TabReports.prototype.setFeedbacks = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.TabReports} returns this
 */
proto.grpc.api.v1.TabReports.prototype.clearFeedbacks = function() {
  return this.setFeedbacks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.TabReports.prototype.hasFeedbacks = function() {
  return jspb.Message.getField(this, 21) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.MsgReports.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MsgReports.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MsgReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MsgReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MsgReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    msgType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sentList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    errorsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MsgReports}
 */
proto.grpc.api.v1.MsgReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MsgReports;
  return proto.grpc.api.v1.MsgReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MsgReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MsgReports}
 */
proto.grpc.api.v1.MsgReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsgType(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSent(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addErrors(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MsgReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MsgReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MsgReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MsgReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsgType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSentList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional string msg_type = 3;
 * @return {string}
 */
proto.grpc.api.v1.MsgReports.prototype.getMsgType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.setMsgType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 sent = 4;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.MsgReports.prototype.getSentList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.setSentList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.addSent = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.clearSentList = function() {
  return this.setSentList([]);
};


/**
 * repeated int32 errors = 5;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.MsgReports.prototype.getErrorsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.setErrorsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.addErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.MsgReports} returns this
 */
proto.grpc.api.v1.MsgReports.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.TabsReportsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.TabsReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.TabsReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.TabsReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.ReportsParams.toObject(includeInstance, f),
    tabReportsList: jspb.Message.toObjectList(msg.getTabReportsList(),
    proto.grpc.api.v1.TabReports.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.TabsReportsResponse}
 */
proto.grpc.api.v1.TabsReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.TabsReportsResponse;
  return proto.grpc.api.v1.TabsReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.TabsReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.TabsReportsResponse}
 */
proto.grpc.api.v1.TabsReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.ReportsParams;
      reader.readMessage(value,proto.grpc.api.v1.ReportsParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    case 2:
      var value = new proto.grpc.api.v1.TabReports;
      reader.readMessage(value,proto.grpc.api.v1.TabReports.deserializeBinaryFromReader);
      msg.addTabReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.TabsReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.TabsReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.TabsReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.ReportsParams.serializeBinaryToWriter
    );
  }
  f = message.getTabReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpc.api.v1.TabReports.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportsParams params = 1;
 * @return {?proto.grpc.api.v1.ReportsParams}
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.ReportsParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.ReportsParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.ReportsParams|undefined} value
 * @return {!proto.grpc.api.v1.TabsReportsResponse} returns this
*/
proto.grpc.api.v1.TabsReportsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.TabsReportsResponse} returns this
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TabReports tab_reports = 2;
 * @return {!Array<!proto.grpc.api.v1.TabReports>}
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.getTabReportsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.TabReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.TabReports, 2));
};


/**
 * @param {!Array<!proto.grpc.api.v1.TabReports>} value
 * @return {!proto.grpc.api.v1.TabsReportsResponse} returns this
*/
proto.grpc.api.v1.TabsReportsResponse.prototype.setTabReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpc.api.v1.TabReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.TabReports}
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.addTabReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpc.api.v1.TabReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.TabsReportsResponse} returns this
 */
proto.grpc.api.v1.TabsReportsResponse.prototype.clearTabReportsList = function() {
  return this.setTabReportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.MsgsReportsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MsgsReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MsgsReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MsgsReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.ReportsParams.toObject(includeInstance, f),
    msgReportsList: jspb.Message.toObjectList(msg.getMsgReportsList(),
    proto.grpc.api.v1.MsgReports.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MsgsReportsResponse}
 */
proto.grpc.api.v1.MsgsReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MsgsReportsResponse;
  return proto.grpc.api.v1.MsgsReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MsgsReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MsgsReportsResponse}
 */
proto.grpc.api.v1.MsgsReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.ReportsParams;
      reader.readMessage(value,proto.grpc.api.v1.ReportsParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    case 2:
      var value = new proto.grpc.api.v1.MsgReports;
      reader.readMessage(value,proto.grpc.api.v1.MsgReports.deserializeBinaryFromReader);
      msg.addMsgReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MsgsReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MsgsReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MsgsReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.ReportsParams.serializeBinaryToWriter
    );
  }
  f = message.getMsgReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpc.api.v1.MsgReports.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReportsParams params = 1;
 * @return {?proto.grpc.api.v1.ReportsParams}
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.ReportsParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.ReportsParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.ReportsParams|undefined} value
 * @return {!proto.grpc.api.v1.MsgsReportsResponse} returns this
*/
proto.grpc.api.v1.MsgsReportsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MsgsReportsResponse} returns this
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MsgReports msg_reports = 2;
 * @return {!Array<!proto.grpc.api.v1.MsgReports>}
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.getMsgReportsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.MsgReports>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.MsgReports, 2));
};


/**
 * @param {!Array<!proto.grpc.api.v1.MsgReports>} value
 * @return {!proto.grpc.api.v1.MsgsReportsResponse} returns this
*/
proto.grpc.api.v1.MsgsReportsResponse.prototype.setMsgReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpc.api.v1.MsgReports=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.MsgReports}
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.addMsgReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpc.api.v1.MsgReports, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.MsgsReportsResponse} returns this
 */
proto.grpc.api.v1.MsgsReportsResponse.prototype.clearMsgReportsList = function() {
  return this.setMsgReportsList([]);
};


goog.object.extend(exports, proto.grpc.api.v1);
