import { ReservationStatus } from '@/model/Reservation';

// eslint-disable-next-line import/prefer-default-export
export function colorForStatus(s: ReservationStatus): string {
  switch (s) {
    case ReservationStatus.Planned: return 'grey lighten-1';
    case ReservationStatus.Confirmed: return 'blue';
    case ReservationStatus.CheckedIn: return 'orange';
    case ReservationStatus.CheckedOut: return 'grey-50';
    case ReservationStatus.Seated: return 'green';
    case ReservationStatus.NonConfirmed: return 'purple';
    case ReservationStatus.Cancelled: return 'red';
    case ReservationStatus.Spam: return 'red';
    case ReservationStatus.Deleted: return 'red';
    case ReservationStatus.NoShow: return 'red';
    case ReservationStatus.WaitingList: return 'purple darken-4';
    default: return 'grey lighten-1';
  }
}

export const fullSwatchPalette = [
  ['#142266', '#1f3399', '#2944cc', '#3355ff', '#5c77ff', '#8599ff'],
  ['#660011', '#99001a', '#cc0022', '#ff002b', '#ff3355', '#ff8095'],
  ['#4c122c', '#992559', '#cc3176', '#FF3D94', '#ff77b4', '#ffb1d4'],
  ['#440d3e', '#891b7d', '#b624a6', '#e42dd0', '#ec6cde', '#f4abec'],
  ['#4c271d', '#994e3b', '#cc684e', '#ff8262', '#ffa891', '#ffcdc0'],
  ['#4c3a17', '#99742f', '#cc9b3e', '#ffc24e', '#ffd483', '#ffe7b8'],
  ['#3b4c00', '#638000', '#8bb300', '#c6ff00', '#ddff66', '#eeffb3'],
  ['#003121', '#006143', '#008259', '#00a26f', '#33b58c', '#80d1b7'],
  ['#0c3a3d', '#16666b', '#1f9199', '#359ca3', '#62b2b8', '#8fc8cc'],
];
