
import Vue from 'vue';

export default Vue.extend({
  name: 'ChartHelpTooltip',
  components: { },
  data() {
    return {};
  },
  watch: { },
  methods: { },
});

