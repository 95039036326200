/* eslint-disable no-param-reassign */
import moment from 'moment';
import { sprintf } from 'sprintf-js';

export const DTI_DATETIME_DATE_OFFSET = 10000;
export const DTI_DATE_LIMIT = 2400;
export const TIME_SLOTS_COUNT = (24 + 6) * 4;
export const MORNING_TIME_SLOTS_COUNT = 6 * 4;
export const DAY_TIME_SLOTS_COUNT = 24 * 4;
export const TIME_SLOT_MINUTES = 15;
export const TIME_SLOTS_PER_HOUR = 60 / TIME_SLOT_MINUTES;

function dateFromString(format: string, value?: string | number | null, utc = false): Date | null {
  if (!value) return null;
  const s = String(value);
  if (s.length !== format.length) return null;
  let m = moment(s, format);
  if (!m.isValid()) return null;
  if (utc) m = m.utcOffset(0, true);
  return m.toDate();
}

function stringFromDate(format: string, value?: Date | null): string | null {
  if (!value) return null;
  const m = moment(value);
  if (!m.isValid) return null;
  return m.format(format);
}

// date and time conversions
export function dateFromDateTimeString(s?: string | number): Date | null {
  return dateFromString('YYYYMMDDHHmm', s);
}

// export function dateFromDateTimeSecondsString(s?: string | number): Date | null {
//   return dateFromString('YYYYMMDDHHmmss', s);
// }

export function dateFromUTCDateTimeSecondsString(s?: string | number): Date | null {
  return dateFromString('YYYYMMDDHHmmss', s, true);
}

export function dateFromDateString(s?: string | number): Date | null {
  return dateFromString('YYYYMMDD', s);
}

export function dateFromDateString8601(s?: string | number): Date | null {
  return dateFromString('YYYY-MM-DD', s);
}

export function dateStringFromDate(d?: Date): string | null {
  return stringFromDate('YYYYMMDD', d);
}

export function dateStringFromDate8601(d?: Date): string | null {
  return stringFromDate('YYYY-MM-DD', d);
}

export function dateTimeStringFromDate(d?: Date): string | null {
  return stringFromDate('YYYYMMDDHHmm', d);
}

export function timeStringFromDate(date: Date | null): string | null {
  return stringFromDate('HH:mm', date);
}

// date and time helpers
export function hourMinFromTimeText(s: string): { h: number, m: number } | null {
  const [sh, sm, ...rest] = s.split(':');
  return sh && sm && !Number.isNaN(Number(sh)) && !Number.isNaN(Number(sm)) && rest.length === 0
    ? { h: Number(sh), m: Number(sm) }
    : null;
}

// TODO: replace by timeIndex or minutes
export function timeNumberFromText(s?: string): number {
  return s ? Number(s.replace(':', '0')) || 0 : 0;
}

// weekday number:  Sunday=1, Monday=2, ... Saturday=7
export function weekdayNumber(d: Date): number {
  return d.getDay() + 1;
}

export function weekdayNumberFromDateDay(day: number): number {
  return day + 1;
}

// weekday start at monday: Monday=0, ... Saturday=5, Sunday=6
export function weekdayStartAtMondayFromDateDay(day: number): number {
  return (day + 6) % 7;
}
export function weekdayStartAtMondayFromDateDay2(day: number): number {
  return (day + 6) % 7;
}

// date and time manipulations
// TODO: refactor names - not mutating fncs
export function dateFlooredToMinutes(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
}
export function dateFlooredToHours(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());
}
export function dateFlooredToDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function dateFlooredToWeek(date: Date, firstDayOfWeek: number): Date {
  const ndate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  return moment(date).add(-ndate.getDay() + firstDayOfWeek, 'days').toDate();
}

export function dateFlooredToMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function dateFlooredToYear(date: Date): Date {
  return new Date(date.getFullYear(), 0, 1);
}

export function dateByAddingSeconds(date: Date, seconds: number): Date {
  return moment(date).add(seconds, 'seconds').toDate();
}

export function dateByAddingMinutes(date: Date, minutes: number): Date {
  return moment(date).add(minutes, 'minutes').toDate();
}
// export function dateByAddingMilliseconds(date: Date, seconds: number) {
//   return moment(date).add(seconds, 'milliseconds').toDate();
// }

export function dateByAddingDays(date: Date, days: number): Date {
  return moment(date).add(days, 'days').toDate();
}

export function dateByAddingMonths(date: Date, months: number): Date {
  return moment(date).add(months, 'months').toDate();
}

export function dateByAddingYears(date: Date, years: number): Date {
  return moment(date).add(years, 'years').toDate();
}

export function dateDiff(d1: Date, d2: Date): number {
  return d1.getTime() - d2.getTime();
}

export function hoursInBetweenDates(d1: Date, d2: Date): number {
  d1 = dateByAddingDays(dateFlooredToDate(d1), 1); d2 = dateFlooredToMinutes(d2);
  return moment(d1).diff(moment(d2), 'hours');
}

export function daysInBetweenDates(d1: Date, d2: Date): number {
  d1 = dateByAddingDays(dateFlooredToDate(d1), 1); d2 = dateFlooredToDate(d2);
  return moment(d1).diff(moment(d2), 'days');
}

export function monthsInBetweenDates(d1: Date, d2: Date): number {
  d1 = dateByAddingDays(dateFlooredToMonth(d1), 1); d2 = dateFlooredToMonth(d2);
  return moment(d1).diff(moment(d2), 'months');
}

export function yearsInBetweenDates(d1: Date, d2: Date): number {
  d1 = dateByAddingDays(dateFlooredToYear(d1), 1); d2 = dateFlooredToYear(d2);
  return moment(d1).diff(moment(d2), 'years');
}

export function daysBetweenDates(d1: Date, d2: Date): number {
  d1 = dateFlooredToDate(d1); d2 = dateFlooredToDate(d2);
  return moment(d1).diff(moment(d2), 'days');
}

export function areDatesEqual(d1: Date, d2: Date): boolean {
  return d1.getTime() === d2.getTime();
}

export function isDateBeforeDate(d1: Date, d2: Date): boolean {
  return d1.getTime() < d2.getTime();
}

export function isDateAfterDate(d1: Date, d2: Date): boolean {
  return d1.getTime() > d2.getTime();
}

export function isDateSameIgnoringTime(d1: Date, d2: Date): boolean {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
}

export function dateWithYear(date: Date, year: number): Date {
  const newDate = new Date(date);
  newDate.setFullYear(year);
  return newDate;
}

// TODO: rename to dateWithHoursMinutes
export function setDateHoursMinutes(date: Date, hours: number, minutes: number): Date {
  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  newDate.setHours(hours, minutes, 0, 0);
  return newDate;
}

export function setDateYearMonth(date: Date, year?: number|null, month?: number|null, monthDay?: number|null): Date {
  const newDate = new Date(year ?? date.getFullYear(), month ?? date.getMonth(), monthDay ?? date.getDate());
  return newDate;
}

export function getDateHoursMinutes(date: Date): { h: number, m: number } {
  return { h: date.getHours(), m: date.getMinutes() };
}

export function isDateToday(d: Date): boolean {
  const today = new Date();
  return d.getDate() === today.getDate()
    && d.getMonth() === today.getMonth()
    && d.getFullYear() === today.getFullYear();
}

export function isDateThisYear(d: Date): boolean {
  const today = new Date();
  return d.getFullYear() === today.getFullYear();
}

export function isDateValid(d: Date): boolean {
  return moment(d).isValid();
}

// flored to 15 min slot
export function flooredMinutes(minutes: number, to: number = 15): number {
  return Math.floor(minutes / to) * to;
}

export function dateFlooredToXMinutes(date: Date, to: number = 15): Date {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    flooredMinutes(date.getMinutes(), to),
  );
}

// slot
export function minutesFromDate(date: Date): number {
  const { h, m } = getDateHoursMinutes(date);
  const mins = h < 6 ? (h + 24) * 60 + m : h * 60 + m;
  return mins;
}

export function minutesFromTimeString(s?: string): number {
  const ti = Number(s?.replace(':', '')) || 0;
  return Math.floor(ti / 100) * 60 + (ti % 100);
}

export function slotFromMinutes(mins: number): number {
  return Math.floor(mins / TIME_SLOT_MINUTES);
}

export function minutesFromSlot(stot: number): number {
  return stot * TIME_SLOT_MINUTES;
}

// export function beginSlotFromDate(date: Date): number {
//   return slotFromMinutes(minutesFromDate(date));
// }

// export function endSlotFromDate(date: Date): number {
//   return slotFromMinutes(minutesFromDate(date));
// }
export function hourMinFromSlot(slot: number): { h: number, m: number } {
  return { h: Math.floor(slot / TIME_SLOTS_PER_HOUR), m: (slot % TIME_SLOTS_PER_HOUR) * TIME_SLOT_MINUTES };
}

export function timeIndexFromSlot(slot: number): number {
  const { h, m } = hourMinFromSlot(slot);
  return h * 100 + m;
}

export function niceTimeTextFromSlot(slot: number): string {
  const { h, m } = hourMinFromSlot(slot);
  return sprintf('%02d:%02d', h % 24, m);
}

// time and date index
export function dateIndexFromDate(date: Date): number {
  const dateString = dateStringFromDate(date);
  if (!dateString) return 0;
  return Number(dateString);
}

export function dateTimeIndexFromDate(date: Date): number {
  const dateString = dateTimeStringFromDate(date);
  if (!dateString) return 0;
  return Number(dateString);
}

// from time string
export function timeIndexFromTime(s?: string): number {
  return s ? Number(s.replace(':', '')) || 0 : 0;
}

export function timeIndexFromMinutes(mins: number): number {
  return Math.floor(mins / 60) * 100 + (mins % 60);
}

export function minutesFromTimeIndex(ti: number): number {
  return Math.floor((ti % 10000) / 100) * 60 + (ti % 100);
}

export function timeStringFromDateTimeIndex(dti: number): string {
  return sprintf('%02d:%02d', (dti / 100) % 24, dti % 100);
}

export function dateFromDateAndTimeIndex(di: number, ti: number = 0): Date | null {
  if (ti < DTI_DATE_LIMIT) return dateFromDateTimeString(di * DTI_DATETIME_DATE_OFFSET + ti);

  const offset = Math.floor(ti / DTI_DATE_LIMIT);
  const date = dateFromDateTimeString(di * DTI_DATETIME_DATE_OFFSET + (ti % DTI_DATE_LIMIT));
  return date ? dateByAddingDays(date, offset) : null;
}

export function dateTimeIndexFromDateAndTimeIndex(di: number, ti: number = 0): number {
  if (ti < DTI_DATE_LIMIT) return di * DTI_DATETIME_DATE_OFFSET + (ti % DTI_DATE_LIMIT);

  const offset = Math.floor(ti / DTI_DATE_LIMIT);
  const date = dateFromDateTimeString(di * DTI_DATETIME_DATE_OFFSET + (ti % DTI_DATE_LIMIT));
  if (!date) return 0;

  const fixedDate = dateByAddingDays(date, offset);
  return Number(dateTimeStringFromDate(fixedDate));
}

export function unixFromDate(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

// unix timestamp in seconds of a date with
//  the same year, month and day in UTC,
// as is the year, month and day in locale timezone,
// of the provided date
export function unixUTCAsInLocalDateYMD(date?: Date): number | undefined {
  if (!date) return undefined;
  const unix = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) / 1000;
  return unix;
}

// date with
//  the same year, month and day in locale timezone,
// as is the year, month and day in UTC,
// of the date corresponding to the proviced unix timestamp in seconds
export function localDateAsInUnixUTCYMD(unix?: number): Date | undefined {
  if (!unix) return undefined;
  const date = new Date(unix * 1000);
  const utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return utc;
}
