
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import { performDispatchAction } from '@/services/vue-utils';
import ContactFilter from '@/grpc-api/model/contact-filter';
import { Filter } from '@/services/filter/filter';
import { cloneModel } from '@/model/model-utils';
import { FilterDescriptor } from '@/services/filter/filter-descriptor';
import { FilterConvert } from '@/services/filter/filter-convert';
import { FilterFieldRule, FilterRule } from '@/services/filter/filter-rule';
import { ValidationObserver } from 'vee-validate';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import ContactsFilterValueRow from './ContactsFilterValueRow.vue';

export default Vue.extend({
  name: 'ContactsFilterDialog',
  components: { ContactsFilterValueRow, ConfirmationDialog },
  mixins: [DialogClosable],
  props: {
    contactFilter: { type: Object as PropType<ContactFilter | null>, required: false, default: null },
  },
  data() {
    return {
      cf: new ContactFilter(),
      defineRules: [] as FilterFieldRule[],
      narrowRules: [] as FilterFieldRule[],
      excludeRules: [] as FilterFieldRule[],
      lastid: 0,
      contactCount: null as number | null,
      deleteFilterConfirmation: false,
      editValidation: true,
    };
  },
  computed: {
    descriptor(): FilterDescriptor {
      return ContactFilter.FilterDescriptor;
    },
    filter(): Filter | null {
      const trim = (rs: FilterFieldRule[]) => rs.filter((r) => this.descriptor.validateRule(r) === null);

      const defineRules = trim(this.defineRules);
      const narrowRules = trim(this.narrowRules);
      const excludeRules = trim(this.excludeRules);
      const filter = Filter.newFilterFrom3Rules({ defineRules, narrowRules, excludeRules });
      return filter;
    },
    isNewFilter(): boolean {
      return this.cf.id === 0;
    },
  },
  watch: {
    filter(filter: Filter) {
      this.previewContactCount();
      console.log('previewContactCount: ', this.filter, this.contactCount);
    },
  },
  mounted() {
    this.cf = this.contactFilter ? cloneModel(this.contactFilter) : new ContactFilter();

    let f = new Filter();
    if (this.cf.data) {
      try {
        f = FilterConvert.fromJson(this.cf.data);
      } catch (e) {
        console.log('Error loading filter: ', e);
      }
    }
    const rules = f.get3Rules();

    this.defineRules = [...rules.defineRules as FilterFieldRule[], new FilterFieldRule()];
    this.narrowRules = [...rules.narrowRules as FilterFieldRule[], new FilterFieldRule()];
    this.excludeRules = [...rules.excludeRules as FilterFieldRule[], new FilterFieldRule()];

    [...this.defineRules, ...this.narrowRules, ...this.excludeRules].forEach(
    // eslint-disable-next-line no-param-reassign
      (r) => { this.lastid += 1; r.id = this.lastid; },
    );
  },
  methods: {
    close() {
      this.$emit('close');
      this.$data.dialog = false;
    },
    addRule(index: number, rules: FilterRule[]) {
      const r = new FilterFieldRule();
      this.lastid += 1; r.id = this.lastid;
      rules.push(r);
    },
    removeRule(index: number, rules: FilterRule[]) {
      rules.splice(index, 1);
    },
    async save() {
      // enforce operand chek when save
      this.editValidation = false;
      await this.$nextTick();

      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
      const isValid = await observer.validate();

      // back to edit mode with no operand check
      // await this.$nextTick();
      // this.editValidation = true;

      if (!isValid) return;

      if (!this.filter) return;

      this.cf.data = FilterConvert.toJson(this.filter);
      const action = this.cf.id === 0 ? 'guestbookCreateContactFilter' : 'guestbookUpdateContactFilter';
      const contactFilter = await performDispatchAction<ContactFilter>(action, { contactFilter: this.cf });
      if (!contactFilter) return;

      this.$tstore.dispatch('guestbookApplyContactFilter', { contactFilter });

      this.close();

      // grpcClient.createContactFilter(this.cf, (data) => {}, (err) => {});
    },
    async previewContactCount() {
      if (!this.filter) { this.contactCount = null; return; }

      try {
        const count = await this.$tstore.dispatch('guestbookPreviewContactFilter', { filter: this.filter });
        this.contactCount = count ?? null;
      } catch (e) {
        this.contactCount = null;
      }
    },
    async deleteFilter() {
      const action = 'guestbookDeleteContactFilter';
      const contactFilter = await performDispatchAction<ContactFilter>(action, { contactFilter: this.cf });
      if (contactFilter) this.close();
    },
    ruleModified() {
      // back to edit mode with no operand check
      this.editValidation = true;
    },
  },
});
