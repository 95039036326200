
import OpeningHour from '@/model/OpeningHour';
import { niceTimeTextFromSlot, weekdayNumberFromDateDay } from '@/services/time-utils';
import { performSaveAction } from '@/services/vue-utils';
import Vue from 'vue';
import {
  guessIntervalFromHoursInWeekdays,
  hoursFromSimpleHoursInWeekdays, openingHoursToSimpleHoursInWeekdays, roundedSimpleHoursInWeekdays, SimpleHour,
} from '@/services/hours-utils';
import { isModelEqualToModel } from '@/model/model-utils';

export default Vue.extend({
  name: 'WelcomeStepHours',
  components: { },
  props: { },
  data() {
    return {
      oldHours: [] as OpeningHour[],
      bookingInterval: 15,
      bookingIntervals: [
        { text: this.$i18n.t('label.15_minutes'), value: 15 },
        { text: this.$i18n.t('label.30_minutes'), value: 30 },
      ],
      simpleHoursInWeekdays: [] as SimpleHour[], // in weekdays - starting from 1 (Mon === 1, Tue === 2, ....)
      unwatch: Function as (() => void),
    };
  },
  computed: {
    days(): {value: number, text: string}[] {
      return this.$localized.weekdays();
    },
    isChanged(): boolean {
      return !isModelEqualToModel(this.hours, this.oldHours);
    },
    is30minInterval(): boolean {
      return this.bookingInterval === 30;
    },
    slotStep(): number {
      return this.bookingInterval === 30 ? 2 : 1;
    },
    open(): boolean[] {
      return Array.from({ length: this.days.length }, (x, day) => {
        const weekdayNumber = weekdayNumberFromDateDay(day);
        const sh = this.simpleHoursInWeekdays[weekdayNumber] ?? new SimpleHour();
        return sh.isOpen;
      });
    },
    slots(): number[][] {
      return Array.from({ length: this.days.length }, (x, day) => {
        const weekdayNumber = weekdayNumberFromDateDay(day);
        const sh = this.simpleHoursInWeekdays[weekdayNumber] ?? new SimpleHour();
        return [sh.beginSlot, sh.endSlot];
      });
    },
    hours(): OpeningHour[] {
      return hoursFromSimpleHoursInWeekdays(this.simpleHoursInWeekdays, this.is30minInterval);
    },
  },
  watch: { },
  mounted() {
    this.reset();
  },
  created() {
    this.reset();
    this.unwatch = this.$tstore.watch((state, getters) => getters.isLoaded, (newValue, oldValue) => { this.reset(); });
  },
  beforeDestroy() {
    this.unwatch();
  },

  methods: {
    reset() {
      this.oldHours = this.$tstore.state.settings.openingHours;
      const hourMaps = this.$tstore.getters.openingHoursInWeekdaysByTimeIndex as Map<number, OpeningHour>[];

      const simpleHoursInWeekdays = openingHoursToSimpleHoursInWeekdays(hourMaps, this.is30minInterval);
      this.simpleHoursInWeekdays = simpleHoursInWeekdays;

      const bookingInterval = guessIntervalFromHoursInWeekdays(hourMaps);
      this.bookingInterval = bookingInterval;
    },
    changeSlot(day: number, slots: number[]) {
      const weekdayNumber = weekdayNumberFromDateDay(day);
      const sh = this.simpleHoursInWeekdays[weekdayNumber];
      [sh.beginSlot, sh.endSlot] = [...slots];
    },
    changeOpen(day: number, open: boolean) {
      const weekdayNumber = weekdayNumberFromDateDay(day);
      const sh = this.simpleHoursInWeekdays[weekdayNumber];
      sh.isOpen = open;
    },
    bookingIntervalChanged(interval: number) {
      if (!this.is30minInterval) return;
      this.simpleHoursInWeekdays = roundedSimpleHoursInWeekdays(this.simpleHoursInWeekdays);
    },
    // eslint-disable-next-line vue/no-unused-properties
    async submit() {
      if (!this.isChanged) {
        this.$emit('advance');
        return;
      }

      const entities = this.hours;

      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendOpeningHours', { entities }),
      );
      if (ok) {
        this.reset();
        this.$emit('advance');
      }
    },
    timeFromSlot(slot: number): string {
      return this.$localized.hourMinTextFrom24hr(niceTimeTextFromSlot(slot)) || '';
    },
  },
});
