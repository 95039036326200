import Vue from 'vue';
import i18n from '@/plugins/i18n';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class GiftcardsApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.giftcards', {
      categories: [GstplnAppCategory.Extensions],
      hasMenuItem: true,
      appConfigRoute: 'giftcards/settings',
      routeConfig:
      [
        {
          title: i18n.tc('app.giftcards.label.redeem'),
          titleKey: 'app.giftcards.label.redeem',
          route: 'redeem',
          f: () => import('@/components/apps/giftcards/GiftcardRedeemView.vue'),
        },
        {
          route: '/giftcard/:code',
          f: () => import('@/components/apps/giftcards/GiftcardRedeemView.vue'),
        },
        {
          title: i18n.tc('app.giftcards.label.transactions'),
          titleKey: 'app.giftcards.label.transactions',
          route: 'cards',
          f: () => import('@/components/apps/giftcards/GiftcardTxListView.vue'),
        },
        {
          title: i18n.tc('menu.settings'),
          titleKey: 'menu.settings',
          route: 'settings',
          f: () => import('@/components/apps/giftcards/GiftcardSettingsView.vue'),
        },
      ],
    });
  }
}
