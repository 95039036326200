import { getCurrentInstance } from 'vue';

export function useLocalized() {
  const { proxy } = getCurrentInstance()!;
  return proxy.$localized;
}

export function useVuetify() {
  const { proxy } = getCurrentInstance()!;
  return proxy.$vuetify;
}
export function useToast() {
  const { proxy } = getCurrentInstance()!;
  return proxy.$toast;
}

export function useAll() {
  const $localized = useLocalized();
  const $vuetify = useVuetify();
  const $toast = useToast();
  return {
    $vuetify, $localized, $toast,
  };
}
