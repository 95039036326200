import elasticlunr, { Index } from 'elasticlunr';
import Reservation from '@/model/Reservation';
import Contact from '@/grpc-api/model/contact';

interface SearchContact
{
  id: string;
  name?: string;
  companyName?: string;
  phone?: string;
  email?: string;
}

const ContactFields: (keyof SearchContact)[] = ['id', 'name', 'companyName', 'phone', 'email'];

function toSearchContact(c: Contact): SearchContact {
  return {
    id: String(c.id),
    name: c.name ?? undefined,
    companyName: c.companyName ?? undefined,
    phone: c.phone ?? undefined,
    email: c.email ?? undefined,
  };
}

function searchCreate(): Index<SearchContact> {
  const rindex = elasticlunr<SearchContact>((index) => {
    ContactFields.forEach((f) => index.addField(f));
    index.saveDocument(false);
    index.pipeline.reset();
  });
  return rindex;
}

let cindex = searchCreate();

export function searchContactAdd(contact: Contact[]) {
  contact.forEach((c) => {
    cindex.addDoc(toSearchContact(c));
  });
}

export function searchContactRemove(contact: Contact[]) {
  contact.forEach((c) => {
    cindex.removeDocByRef(c.id);
  });
}

export function searchContactReset() {
  cindex = searchCreate();
}

export function searchForContacts(query: string, contactsById: Map<number, Contact>) {
  if (query.trim().length < 3) return [];

  const config = {
    expand: true,
    bool: 'AND' as elasticlunr.Bool,
  };

  const res = cindex.search(query, config);

  console.log('searchForContacts: ', query, config, res);
  return res.flatMap((c) => contactsById.get(Number(c.ref)) ?? []);
}
