var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'fp-object noselect',
    {'fp-draggable': _vm.selected},
    {'selected': _vm.selected},
    {'editable': _vm.editable},
    {'theme--dark': _vm.$vuetify.theme.dark}
  ],style:(`
  width: ${(_vm.tableSize.h + 60)}px;
  height: ${(_vm.tableSize.h + 60)}px;
  transform: matrix(${_vm.zoom}, 0, 0, ${_vm.zoom}, ${_vm.x*_vm.zoom}, ${_vm.y*_vm.zoom})`),on:{"contextmenu":function($event){$event.preventDefault();return _vm.onRightClick.apply(null, arguments)},"click":function($event){$event.stopPropagation();return _vm.select.apply(null, arguments)}}},[_c('div',{staticClass:"fp-toolbar"},[(_vm.selected && _vm.editable)?[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.rotateCcw}},[_c('v-icon',[_vm._v(" mdi-rotate-left ")])],1),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.rotateCw}},[_c('v-icon',[_vm._v(" mdi-rotate-right ")])],1)]:_vm._e()],2),_c('div',{staticClass:"fp-inner"},[_c('svg',{staticStyle:{"overflow":"visible"},attrs:{"width":"100%","height":"100%","viewBox":`0 0 ${_vm.tableSize.w + 40} ${_vm.tableSize.h + 40}`}},[(_vm.isBlock || _vm.reservation?.holdTabItems)?_c('time-table-striped-fill',{attrs:{"pattern-id":`fp-pattern-stripe-${_vm.reservation?.id}`,"pattern-transform":`rotate(${45-_vm.a})`,"fill-light":_vm.fillColor,"fill-dark":_vm.darkenColor(_vm.fillColor, _vm.isBlock ? 30 : 15)}}):_vm._e(),_c('g',{attrs:{"transform":`rotate(${_vm.a}, ${(_vm.tableSize.w + 40)/2}, ${(_vm.tableSize.h + 40)/2})`}},[(_vm.tabItem.shape === 'SQUARED' || _vm.tabItem.shape === 'HEADSEAT')?_c('floorplan-shape-table-rect',{attrs:{"fill-color":_vm.fill,"stroke-color":_vm.strokeColor,"party-size":_vm.reservation !== undefined ? _vm.reservation.partySize : 0,"table-size":_vm.tableSize,"shape":_vm.tabItem.shape,"seats":_vm.tabItem.seats,"type":_vm.type,"block":_vm.isBlock}}):(_vm.tabItem.shape === 'ROUNDED')?_c('floorplan-shape-table-round',{attrs:{"party-size":_vm.reservation !== undefined ? _vm.reservation.partySize : 0,"fill-color":_vm.fill,"stroke-color":_vm.strokeColor,"table-size":_vm.tableSize,"seats":_vm.tabItem.seats,"block":_vm.isBlock}}):_vm._e()],1),_c('text',{staticClass:"fp-table-title",style:(`font-size: ${_vm.titleFontSize}px;
        stroke: ${_vm.titleStrokeColor};`),attrs:{"x":"50%","y":_vm.titleYpos,"dominant-baseline":"central","text-anchor":"middle","fill":_vm.titleColor}},[_vm._v(_vm._s(_vm.title))]),(_vm.subtitle !== null)?_c('text',{staticClass:"fp-table-subtitle",style:(`stroke: ${_vm.subtitleStrokeColor};`),attrs:{"x":"50%","y":_vm.subtitleYpos,"dominant-baseline":"central","text-anchor":"middle","fill":_vm.subtitleColor}},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),(_vm.partySizeTitle !== null)?_c('text',{staticClass:"fp-table-subtitle",style:(`stroke: ${_vm.subtitleStrokeColor};`),attrs:{"x":"50%","y":_vm.partySizeYpos,"dominant-baseline":"central","text-anchor":"middle","fill":_vm.subtitleColor}},[_vm._v(_vm._s(_vm.partySizeTitle))]):_vm._e(),_c('v-icon',[_vm._v("mdi-crown")])],1)]),_c('div',{staticClass:"fp-toolbar"},[(_vm.selected && _vm.editable)?[_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3735098154)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{on:{"click":function($event){return _vm.changeTableShape('HEADSEAT')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.table_type_squared')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeTableShape('SQUARED')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.table_type_headseat')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeTableShape('ROUNDED')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.table_type_rounded')))])],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.removeTable}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('button.remove'))+" ")])],1)],1)],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }