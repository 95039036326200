/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

export type FilterOperandType = string | number | Date | boolean | null;
export const FilterOperandSeparator: string = '@@sep@@';

export function isEmptyOperand(operand: FilterOperandType | undefined, zeroAsNull = false) {
  if (operand === undefined || operand === null || operand === '') return true;

  if (typeof operand === 'string' && operand.trim() === '') return true;

  if (zeroAsNull && operand === 0) return true;

  return false;
}

export function fixOperand(operand: FilterOperandType | undefined, zeroAsNull = false) {
  if (operand === undefined || operand === null || operand === '') return null;

  if (typeof operand === 'string') return operand.trim() || null;

  if (zeroAsNull && operand === 0) return null;

  return operand;
}

export abstract class Operation {
  neg = false;

  abstract check(value: FilterOperandType): boolean
}

export class NoOperation extends Operation {
  check(value: FilterOperandType) { return false; }
}

export abstract class FieldOperation extends Operation {
  operand: FilterOperandType = null;

  constructor(operand: FilterOperandType) {
    super();

    this.operand = fixOperand(operand);
  }
}

export class IsSetFieldOperation extends FieldOperation {
  neg = false;

  constructor() {
    super(null);
  }

  check(value: FilterOperandType | undefined) {
    return this.neg !== (value === true);
  }
}

export class IsEmptyFieldOperation extends FieldOperation {
  neg = false;

  constructor() {
    super(null);
  }

  check(value: FilterOperandType | undefined) {
    return this.neg !== (fixOperand(value) === null);
  }
}

export class EqualFieldOperation extends FieldOperation {
  neg = false;

  check(value: FilterOperandType | undefined) {
    return this.neg !== (value === fixOperand(this.operand));
  }
}

export class ContainsFieldOperation extends FieldOperation {
  neg = false;

  constructor(operand: FilterOperandType) {
    super(operand);
    if (typeof this.operand !== 'string') this.operand = null;
  }

  check(value: FilterOperandType) {
    if (typeof value !== 'string' || this.operand === null) return false;
    return this.neg !== value.includes(this.operand as string);
  }
}

export class GreaterThenFieldOperation extends FieldOperation {
  neg = false;

  constructor(operand: FilterOperandType) {
    super(operand);
    if (typeof this.operand !== 'number') this.operand = null;
  }

  check(value: FilterOperandType) {
    if (typeof value !== 'number' || this.operand === null) return false;
    return this.neg !== (value > (this.operand as number));
  }
}

export class LessThenFieldOperation extends FieldOperation {
  neg = false;

  check(value: FilterOperandType) {
    if (typeof value !== 'number' || this.operand === null) return false;
    return this.neg !== (value < (this.operand as number));
  }
}
