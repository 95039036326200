import { ITabItem } from '@/api/api-update';
import { numberFromValue } from '@/api/api-utils';
import { uniqueMinId } from './model-utils';

export const TAB_ITEM_DUMMY_ID = 0; // dummy tab items has id <= 0

export interface IDTOTabItem extends ITabItem { }

export default class TabItem {
  id: number;

  order: number;

  no: string;

  seats: number;

  shape?: string;

  posX?: number;

  posY?: number;

  rotation?: number;

  constructor(o?: ITabItem) {
    this.id = o?.itemId ?? 0;
    this.order = o?.itemOrder ?? 0;
    this.no = o?.itemNo ?? '';
    this.seats = o?.seats ?? 0;
    this.shape = o?.shape;
    this.posX = numberFromValue(o?.posX);
    this.posY = numberFromValue(o?.posY);
    this.rotation = numberFromValue(o?.rotation);
  }

  toDto(): IDTOTabItem {
    const dto: IDTOTabItem = {
      itemId: this.id,
      itemOrder: this.order,
      itemNo: this.no,
      seats: this.seats,
      shape: this.shape,
      posX: this.posX,
      posY: this.posY,
      rotation: this.rotation,
    };
    return dto;
  }

  get isDummy() { return this.id <= TAB_ITEM_DUMMY_ID; }
}

export function suggestedTabItem(tabItems: TabItem[]): TabItem {
  const lastNo = tabItems.slice(-1)[0]?.no ?? '0';
  const nextNo = parseInt(lastNo, 10) + 1;
  return new TabItem({
    itemId: uniqueMinId(tabItems),
    itemNo: nextNo ? String(nextNo) : '1',
    seats: tabItems.slice(-1)[0]?.seats ?? 4,
  });
}
