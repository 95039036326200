import { IEmployee } from '@/api/api-update';

export interface IDTOEmployee extends IEmployee { }

export default class Employee {
  id: number;

  name: string;

  order: number;

  active: boolean = true; // TODO: not used yet

  constructor(o?: IEmployee) {
    this.id = o?.employeeId ?? 0;
    this.name = o?.employeeName ?? '';
    this.order = o?.employeeOrder ?? 0;
  }

  toDto(): IDTOEmployee {
    const dto: IDTOEmployee = {
      employeeId: this.id,
      employeeName: this.name,
      employeeOrder: this.order,
    };
    return dto;
  }
}
