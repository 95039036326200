
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'SimpleLineChart',
  components: {
    VChart,
  },
  props: {
    title: { type: String, required: true },
    categoryType: { type: String as PropType<string>, default: 'day' },
    category: { type: Array as PropType<Array<Date>>, default: () => [] },
    series: { type: Array as PropType<Array<string>>, default: () => [] },
    dataset: { type: Array as PropType<Array<[number, number, number?]>>, default: () => [] },
    totals: { type: Array as PropType<Array<number>>, default: () => [] },
    inverseMetric: { type: Boolean, required: false, default: false },
    compare: { type: Boolean, required: false, default: false },
    unit: { type: String, default: '' },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
    };
  },
  computed: {
    compareValues(): boolean {
      return this.compare && this.totals.length === 2;
    },
    isImproved(): boolean {
      return this.inverseMetric ? this.totals[0] < this.totals[1] : this.totals[0] > this.totals[1];
    },
    isSame(): boolean {
      return this.totals[0] === this.totals[1];
    },
    totalValues(): string[] {
      const totalValues = [this.totals[0] ?? 0, this.totals[1] ?? 0];
      return totalValues.map((v) => this.$i18n.n(v));
    },
    categoryData(): string[] {
      switch (this.categoryType) {
        case 'hour': return this.category.map((d) => this.$localized.hourMinText(d)!);
        case 'month': return this.category.map((d) => this.$localized.monthText(d)!);
        default: return this.category.map((d) => this.$localized.shortDayMonthText(d)!);
      }
    },
  },
  watch: {
    categoryType() { this.reset(); },
    category() { this.reset(); },
    series() { this.reset(); },
    dataset() { this.reset(); },
  },
  created() {
    this.reset();
  },

  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const series = [] as any[];
      const colors = ['#3355ff', '#FF8262'];

      this.series.forEach((name, i) => {
        series.push(
          {
            type: 'line',
            smooth: 0,
            name,
            lineStyle: {
              width: 3,
            },
            itemStyle: {
              color: colors[i],
            },
            areaStyle: {
              color: colors[i],
              opacity: i === 0 ? 0.3 : 0,
            },
            encode: {
              x: 'date',
              y: [`series${i}`],
            },
          },
        );
      });
      return {
        grid: {
          left: '32px',
          top: '16px',
          right: '2%',
        },
        dataset: {
          source: this.dataset,
          dimensions: ['date', 'series0', 'series1'],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
          extraCssText: 'min-width: 200px; max-width: 600px',
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
        },
        xAxis: {
          // splitNumber: 16,
          type: 'category',
          data: this.categoryData,
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
          splitLine: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis: {
          type: 'value',
        },
        series,
      };
    },

  },
});

