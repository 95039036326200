
import Vue from 'vue';

export default Vue.extend({
  name: 'WelcomeStepWelcome',
  components: { },
  props: { },
  data() {
    return { };
  },
  computed: { },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    submit() {
      this.$emit('advance');
    },
  },
});
