import * as pbm from '@/grpc-pb//reservation_service_pb';
import * as pbs from '@/grpc-pb/service_manager_service_pb';

import ApiService from '@/model/Service';
import Tab from '@/model/Tab';
import {
  dateFlooredToDate, localDateAsInUnixUTCYMD, unixFromDate, unixUTCAsInLocalDateYMD,
} from '@/services/time-utils';
import Currency from './currency';

export interface ReservationServiceMapping {
  tabsById: Map<number, Tab>,
  currenciesById: Map<number, Currency>,
}

export interface ReservationServiceBlackout {
  blackoutStartDate: Date;
  blackoutEndDate: Date;
}

export default class ReservationService extends ApiService {
  description: string = '';

  tags: string[] = [];

  imageUrl: string = '';

  localStartDate?: Date;

  localEndDate?: Date;

  localStartTime?: string;

  localEndTime?: string;

  minPartySize?: number;

  maxPartySize?: number;

  weekDays: number[] = [];

  tab?: Tab;

  duration?: number;

  locale?: string;

  code?: string;

  price?: number;

  isPricePerPerson?: boolean;

  currency?: Currency;

  isPromoted: boolean = false;

  isRequired: boolean = false;

  minMinutesInAdvance?: number;

  minMinutesToModify?: number;

  // blackoutStartDate?: Date;

  // blackoutEndDate?: Date;

  maxParallelBookings?: number;

  isActive: boolean = true;

  isDeleted: boolean = false;

  isDeposit: boolean = true;

  blackouts: ReservationServiceBlackout[] = [];

  static fromGrpcResponse(r: pbs.ReservationServiceResponse, m?: ReservationServiceMapping): ReservationService {
    const s = r.getService();
    return ReservationService.fromGrpcModel(s, m);
  }

  static fromGrpcModel(o?: pbm.ReservationService, m?: ReservationServiceMapping): ReservationService {
    const s = new ReservationService();

    s.id = o?.getId() ?? 0;
    s.title = o?.getTitle() ?? '';
    s.description = o?.getDescription() ?? '';
    s.tags = o?.getTagsList() ?? [];
    s.imageUrl = o?.getImageUrl() ?? '';
    s.order = o?.getOrder() ?? 0;
    s.localStartDate = localDateAsInUnixUTCYMD(o?.getStartDate());
    s.localEndDate = localDateAsInUnixUTCYMD(o?.getEndDate());
    s.localStartTime = o?.getStartTime() || undefined;
    s.localEndTime = o?.getEndTime() || undefined;
    s.minPartySize = o?.getMinPartySize() || undefined;
    s.maxPartySize = o?.getMaxPartySize() || undefined;
    s.weekDays = o?.getWeekDayList() ?? [];
    s.duration = o?.getDuration() || undefined;
    s.locale = o?.getLocale() || undefined;
    s.code = o?.getCode() || undefined;
    s.price = o?.getPrice() || undefined;
    s.isPricePerPerson = o?.getIsPricePerPerson() || false;
    s.isPromoted = o?.getIsPromoted() ?? false;
    s.isRequired = o?.getIsRequired() ?? false;
    s.minMinutesInAdvance = o?.getMinMinutesInAdvance() || undefined;
    s.minMinutesToModify = o?.getMinMinutesToModify() || undefined;
    // s.blackoutStartDate = localDateAsInUnixUTCYMD(o?.getBlackoutStartDate());
    // s.blackoutEndDate = localDateAsInUnixUTCYMD(o?.getBlackoutEndDate());
    s.maxParallelBookings = o?.getMaxParallelBookings() || undefined;
    s.isActive = o?.getIsActive() ?? true;
    s.isDeleted = o?.getIsDeleted() ?? false;
    s.color = o?.getColor().toUpperCase() || '#FF3D94';
    s.isDeposit = o?.getIsDeposit() ?? false;

    s.tab = m?.tabsById.get(o?.getTabId() ?? NaN);
    s.currency = m?.currenciesById.get(o?.getCurrencyId() ?? NaN);

    s.weekDays.sort();

    s.blackouts = o?.getBlackoutsList()?.map((bo) => ({
      blackoutStartDate: localDateAsInUnixUTCYMD(bo?.getBlackoutStartDate()) ?? dateFlooredToDate(new Date()),
      blackoutEndDate: localDateAsInUnixUTCYMD(bo?.getBlackoutEndDate()) ?? dateFlooredToDate(new Date()),
    })) ?? [];

    return s;
  }

  toGrpcModel(): pbm.ReservationService {
    const o = new pbm.ReservationService();

    o.setId(this.id);

    if (this.id) o.setId(this.id);
    if (this.title) o.setTitle(this.title);
    if (this.description) o.setDescription(this.description);
    if (this.tags) o.setTagsList(this.tags);
    if (this.imageUrl) o.setImageUrl(this.imageUrl);
    if (this.order) o.setOrder(this.order);
    if (this.localStartDate) o.setStartDate(unixUTCAsInLocalDateYMD(this.localStartDate)!);
    if (this.localEndDate) o.setEndDate(unixUTCAsInLocalDateYMD(this.localEndDate)!);
    if (this.localStartTime) o.setStartTime(this.localStartTime);
    if (this.localEndTime) o.setEndTime(this.localEndTime);
    if (this.minPartySize) o.setMinPartySize(this.minPartySize);
    if (this.maxPartySize) o.setMaxPartySize(this.maxPartySize);
    if (this.weekDays) o.setWeekDayList(this.weekDays);
    if (this.tab) o.setTabId(this.tab.id);
    if (this.duration) o.setDuration(this.duration);
    if (this.locale) o.setLocale(this.locale);
    if (this.price) o.setPrice(this.price);
    if (this.isPricePerPerson) o.setIsPricePerPerson(this.isPricePerPerson);
    if (this.currency) o.setCurrencyId(this.currency.id);
    if (this.code) o.setCode(this.code);

    if (this.isPromoted) o.setIsPromoted(this.isPromoted);
    if (this.isRequired) o.setIsRequired(this.isRequired);
    if (this.minMinutesInAdvance) o.setMinMinutesInAdvance(this.minMinutesInAdvance);
    if (this.minMinutesToModify) o.setMinMinutesToModify(this.minMinutesToModify);
    // if (this.blackoutStartDate) o.setBlackoutStartDate(unixUTCAsInLocalDateYMD(this.blackoutStartDate)!);
    // if (this.blackoutEndDate) o.setBlackoutEndDate(unixUTCAsInLocalDateYMD(this.blackoutEndDate)!);
    if (this.maxParallelBookings) o.setMaxParallelBookings(this.maxParallelBookings);
    if (this.isActive) o.setIsActive(this.isActive);
    if (this.isDeleted) o.setIsDeleted(this.isDeleted);
    if (this.color) o.setColor(this.color);
    if (this.isDeposit) o.setIsDeposit(this.isDeposit);

    if (this.blackouts && this.blackouts.length >= 0) {
      o.setBlackoutsList(this.blackouts.map((b) => {
        const bo = new pbm.ReservationServiceBlackout();
        bo.setBlackoutStartDate(unixUTCAsInLocalDateYMD(b.blackoutStartDate)!);
        bo.setBlackoutEndDate(unixUTCAsInLocalDateYMD(b.blackoutEndDate)!);
        return bo;
      }));
    }

    return o;
  }

  static compare(o1: ReservationService, o2: ReservationService): number {
    const res = o1.order - o2.order;
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  clone(): ReservationService {
    const copy = new ReservationService();
    Object.assign(copy, this);
    copy.tags = Array.from(this.tags);
    copy.blackouts = Array.from(this.blackouts);
    return copy;
  }
}
