
import Vue from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import RegisterAccountStep from '@/components/views/login/RegisterAccountStep.vue';
import VerifyAccountStep from '@/components/views/login/VerifyAccountStep.vue';

export default Vue.extend({
  name: 'CreateAccountDialog',
  components: { RegisterAccountStep, VerifyAccountStep },
  mixins: [DialogClosable],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isRegistered(): boolean {
      return this.$tstore.getters.isRegistered;
    },
    isLoggedIn(): boolean {
      return this.$tstore.getters.isLoggedIn;
    },
    isSignup(): boolean {
      return this.$route.query.signup !== undefined;
    },
    step(): string {
      console.log('step isLoggedIn isRegistered: ', this.$tstore.getters.isLoggedIn, this.$tstore.getters.isRegistered);
      if (!this.isLoggedIn || this.isSignup) return 'register';
      if (!this.isRegistered) return 'verify';
      return 'verify';
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
});
