
import Vue from 'vue';

export default Vue.extend({
  name: 'FloorplanShapeOverflow',
  props: {
    x: { type: Number, required: true },
    y: { type: Number, required: true },
    horizontal: { type: Boolean, required: false, default: false },
    strokeColor: { type: String, required: true },
  },
  data() {
    return { };
  },
});

