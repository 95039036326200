export function boolFromValue(s?: string | boolean, defaultValue?: boolean): boolean | undefined {
  if (s === undefined) return defaultValue;
  if (s === true || s === 'True' || s === 'true' || s === '1') return true;
  if (s === false || s === 'False' || s === 'false' || s === '0') return false;
  return defaultValue;
}

export function numberFromValue(s?: string | number, defaultValue?: number): number | undefined {
  if (s === undefined) return defaultValue;
  if (s === 0 || s === '0') return 0;
  return Number(s) || defaultValue;
}
