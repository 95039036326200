
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import Tab from '@/model/Tab';
import TabItem from '@/model/TabItem';
import { cloneModel, isModelEqualToModel, uniqueMinId } from '@/model/model-utils';
import { performSaveAction, confirmLeaveWithoutSaving } from '@/services/vue-utils';

import FloorplanCanvas from '@/components/views/floorplan/FloorplanCanvas.vue';
import FloorplanEditSidebar from '@/components/views/floorplan/FloorplanEditSidebar.vue';

export default Vue.extend({
  name: 'FloorplanEditDialog',
  components: { FloorplanCanvas, FloorplanEditSidebar },
  mixins: [DialogClosable],
  props: {
    tab: { type: Object as PropType<Tab>, required: true },
  },
  data() {
    return {
      tabItems: [] as TabItem[],
      oldTabItems: [] as TabItem[],
      selectedTabItemIds: [] as number[],
      sidebarToggle: false,

      zoom: 1,
      zoomLevels: [
        { value: 0.25, text: '25%' },
        { value: 0.5, text: '50%' },
        { value: 0.75, text: '75%' },
        { value: 1, text: '100%' },
        { value: 1.25, text: '125%' },
        { value: 1.50, text: '150%' },
        { value: 1.75, text: '175%' }],
    };
  },
  computed: {
    isChanged(): boolean {
      return !isModelEqualToModel(this.tabItems, this.oldTabItems);
    },
    sidebarVisible(): boolean {
      return !this.compact || this.sidebarToggle;
    },
    compact(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    visible(value: boolean) {
      if (!value) return;
      this.reset();
    },
  },
  methods: {
    reset() {
      const tab = this.$tstore.getters.tabsById.get(this.tab.id) as Tab | undefined;
      this.tabItems = (tab?.tabItems ?? []).map((o) => cloneModel(o));
      this.oldTabItems = tab?.tabItems ?? [];
    },
    tabItemSelected(tabItemId: number) {
      this.selectedTabItemIds = [tabItemId];
      this.sidebarToggle = false;
    },
    placeTabItem(i: number, t: TabItem) {
      (this.$refs.fpCanvas as any).placeTable(t);
    },
    close() {
      this.$emit('close');
    },
    cancel() {
      this.close();
    },
    async save() {
      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendFloorplanTabItems', {
          entities: this.tabItems,
          tabId: this.tab.id,
        }),
      );

      if (ok) {
        this.close();
      }
    },
  },
});
