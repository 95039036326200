/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class SimpleCampaignApp extends GstplnApp {
  constructor(vue: Vue, id: string, p?: { categories?: GstplnAppCategory[], allowedCountries?: string[]}) {
    super(vue, id, {
      categories: p?.categories,
      allowedCountries: p?.allowedCountries,
      configComponentImportFnc: () => import('@/components/apps/simpleCampaignApp/SettingsDialog.vue'),
      configBeforeInstall: false,
    });
  }
}
