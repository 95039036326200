import { GetterTree, MutationTree, ActionTree } from 'vuex';
import httpClient from '@/api/http-client';
import Reservation, { ReservationType } from '@/model/Reservation';
import TabItem from '@/model/TabItem';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';
import { InvalidStatuses } from '@/util/status';
import IRootState, { IReservationSuggestionsState } from './store-state';

// reservation significantly modified?
function areSame(r1: Reservation | null, r2: Reservation | null) {
  return r1?.id === r2?.id
    && r1?.tab === r2?.tab
    && r1?.dateBegin === r2?.dateBegin
    && r1?.dateEnd === r2?.dateEnd
    && r1?.partySize === r2?.partySize
    && r1?.tabItems.length === r2?.tabItems.length
    && r1?.tabItems.every((ti) => r2?.tabItems.includes(ti))
    && r1?.status === r2?.status;
}

function isRelevant(r: Reservation | null) {
  return r !== null && r.tabItems.length === 0 && r.partySize > 0 && !InvalidStatuses.includes(r.status);
}

export class ReservationSuggestionsState implements IReservationSuggestionsState {
  reservation: Reservation | null = null;

  tabItems: TabItem[] | null = null;

  requestCount = 0;
}

const mutations = <MutationTree<IReservationSuggestionsState>>{
  RESET(state: ReservationSuggestionsState) {
    Object.assign(state, new ReservationSuggestionsState());
  },
  SET_SUGGESTIONS_RESERVATION(state: ReservationSuggestionsState, reservation: Reservation | null) {
    state.reservation = reservation ? cloneModel(reservation) : null;
  },
  CHANGE_SUGGESTIONS_REQUEST_COUNT(state: ReservationSuggestionsState, increment: boolean) {
    state.requestCount = increment ? state.requestCount + 1 : state.requestCount - 1;
  },
  SET_SUGGESTIONS_RESULTS(
    state: ReservationSuggestionsState,
    p: { tabItems: TabItem[] | null },
  ) {
    if (p.tabItems) state.tabItems = p.tabItems;
  },
  RESET_SUGGESTIONS_RESULTS(state: ReservationSuggestionsState) {
    state.tabItems = null;
  },
};

const actions = <ActionTree<IReservationSuggestionsState, IRootState>>{
  async suggest({
    state, commit, rootState, rootGetters,
  }, p: { reservation: Reservation | null }) {
    console.log('suggest:', p.reservation, state.reservation);

    if (p.reservation?.isBlock) {
      commit('RESET_SUGGESTIONS_RESULTS');
      return;
    }

    const isModified = !areSame(state.reservation, p.reservation);

    // set suggestions reservation (even if not modified)
    commit('SET_SUGGESTIONS_RESERVATION', p.reservation);

    // reset results when modified
    if (isModified) {
      commit('RESET_SUGGESTIONS_RESULTS');
    }

    // ask for suggestions?
    if (!(rootState.settings.accountSettings.tableAllocation ?? false)) return;
    if (!isModified || !isRelevant(state.reservation)) return;

    // do api request
    commit('CHANGE_SUGGESTIONS_REQUEST_COUNT', true);

    const reservation = cloneModel(p.reservation!);

    setTimeout(async () => {
      try {
        if (!isModelEqualToModel(reservation, p.reservation!)) return; // reservation has changed -> skip
        const dto = p.reservation!.toDto();

        const tabItemIds = await httpClient.suggestTables(dto);

        console.log('SUGGESTIONS results:', tabItemIds);

        if (areSame(state.reservation, p.reservation)) {
          const tabItemsById = rootGetters.tabItemsById as Map<number, TabItem>;
          const tabItems = [] as TabItem[];
          tabItemIds.forEach((id) => { const ti = tabItemsById.get(id); if (ti) tabItems.push(ti); });
          tabItems.sort((o1, o2) => o1.order - o2.order);
          commit('SET_SUGGESTIONS_RESULTS', { tabItems });
        }
      } catch (e) {
        console.log('SUGGESTIONS API error:', e);
      } finally {
        commit('CHANGE_SUGGESTIONS_REQUEST_COUNT', false);
      }
    }, 500);
  },
};

const getters = <GetterTree<IReservationSuggestionsState, IRootState>>{
  suggestedTabItems(state, localGetters, rootState, rootGetters): TabItem[] | null {
    return state.tabItems;
  },
  isSuggestingTabItems(state, localGetters, rootState, rootGetters): boolean {
    return state.requestCount !== 0 && isRelevant(state.reservation);
  },
};

const ReservationSuggestionStore = {
  namespaced: false,
  state: new ReservationSuggestionsState(),
  mutations,
  actions,
  getters,
};

export default ReservationSuggestionStore;
