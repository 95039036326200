/* eslint-disable object-curly-newline */
/* eslint-disable vue/max-len */
/* eslint-disable max-len */

import { GetterTree, MutationTree, ActionTree } from 'vuex';
import GiftcardProduct from '@/grpc-api/model/giftcard-product';
import grpcClient from '@/grpc-api/grpc-client';
import Currency from '@/grpc-api/model/currency';
import { DateRange } from '@/services/date-range';
import GiftcardTransaction, { GiftcardTransactionFilter, GiftcardTransactionType, areGiftcardTransactionFiltersSame, defaultGiftcardTransactionFilter } from '@/grpc-api/model/giftcard-transaction';
import { dateIndexFromDate } from '@/services/time-utils';
import Giftcard, { GiftcardMapping } from '@/grpc-api/model/giftcard';
import IRootState, { IGiftcardsState } from './store-state';

export class GiftcardsState implements IGiftcardsState {
  product: GiftcardProduct | null = null;

  currencies: Currency[] = [];

  isLoaded: boolean = false;

  transactionFilter: GiftcardTransactionFilter = defaultGiftcardTransactionFilter();

  transactions: GiftcardTransaction[] = [];

  areTransactionsLoaded: boolean = false;
}

const mutations = <MutationTree<IGiftcardsState>>{
  RESET(state: GiftcardsState) {
    Object.assign(state, new GiftcardsState());
  },
  RESET_GIFTCARDS(state: GiftcardsState) {
    Object.assign(state, new GiftcardsState());
  },
  RESET_GIFTCARD_TRANSACTIONS(state: GiftcardsState) {
    state.transactions = [];
    state.areTransactionsLoaded = false;
  },
  UPDATE_GIFTCARDS(state: GiftcardsState, p: {
    product?: GiftcardProduct | null, currencies?: Currency[], isLoaded?: boolean,
    transactionDateRange?: DateRange, transactionTypes?: GiftcardTransactionType[], transactionCodes?: string[], transactionCode?: string,
    transactions?: GiftcardTransaction[], areTransactionsLoaded?: boolean, }) {
    if (p.product !== undefined) state.product = p.product;
    if (p.currencies !== undefined) state.currencies = p.currencies;
    if (p.isLoaded !== undefined) state.isLoaded = p.isLoaded;

    console.log('giftcards product:', p.product);
    console.log('giftcards currencies:', p.currencies);

    if (p.transactionDateRange !== undefined) state.transactionFilter.dateRange = p.transactionDateRange;
    if (p.transactionTypes !== undefined) state.transactionFilter.types = p.transactionTypes;
    if (p.transactionCodes !== undefined) state.transactionFilter.codes = p.transactionCodes;
    if (p.transactionCode !== undefined) state.transactionFilter.code = p.transactionCode;

    if (p.transactions !== undefined) state.transactions.push(...p.transactions);
    if (p.areTransactionsLoaded !== undefined) state.areTransactionsLoaded = p.areTransactionsLoaded;

    console.log('giftcards transactions:', p.transactions);
  },
};

const actions = <ActionTree<IGiftcardsState, IRootState>>{
  resetGiftcards({ commit }) {
    commit('RESET_GIFTCARDS');
  },
  async loadGiftcardProduct({ state, commit, rootGetters }, p?: { force: true }): Promise<{ currencies: Currency[], product: GiftcardProduct } | undefined> {
    if (!rootGetters.isLoaded) return undefined;
    if (p?.force !== true && state.isLoaded) return { currencies: state.currencies, product: state.product! };

    const currencies = await grpcClient.listCurrencies();
    const product = await grpcClient.getGiftcardProduct();

    commit('UPDATE_GIFTCARDS', { currencies, product, isLoaded: true });

    return { currencies, product };
  },
  async updateGiftcardProduct({ state, commit, rootGetters }, p:{ product: GiftcardProduct }): Promise<{ currencies: Currency[], product: GiftcardProduct } | undefined> {
    if (!state.isLoaded) return undefined;
    const product = await grpcClient.updateGiftcardProduct(p.product);
    commit('UPDATE_GIFTCARDS', { product });

    return { currencies: state.currencies, product };
  },
  async loadGiftcardTransactions({ state, commit, rootGetters }) {
    if (!rootGetters.isLoaded || !state.isLoaded) return;

    commit('RESET_GIFTCARD_TRANSACTIONS');

    const thisFilter = { ...state.transactionFilter };

    const { dateRange, types, codes: filterCodes, code: filterCode } = state.transactionFilter;
    const codes = filterCodes.map((c) => c.replaceAll('-', ''));
    const code = filterCode.replaceAll('-', '');

    const beginDateIndex = dateIndexFromDate(dateRange.beginDate);
    const endDateIndex = dateIndexFromDate(dateRange.endDate);

    await grpcClient.listGiftcardTransactions(
      { beginDateIndex, endDateIndex, types, codes, code },
      (data: GiftcardTransaction[]) => {
        if (!areGiftcardTransactionFiltersSame(thisFilter, state.transactionFilter)) return;
        commit('UPDATE_GIFTCARDS', { transactions: data });
      },
    );
    if (!areGiftcardTransactionFiltersSame(thisFilter, state.transactionFilter)) return;
    commit('UPDATE_GIFTCARDS', { areTransactionsLoaded: true });
  },
  async updateGiftcardTransactions({ state, commit, dispatch }, p: { dateRange?: DateRange, types?: GiftcardTransactionType[], codes?: string[], code?: string }) {
    // save new params and reset transctions
    commit('UPDATE_GIFTCARDS', { transactionDateRange: p.dateRange, transactionTypes: p.types, transactionCodes: p.codes, transactionCode: p.code });

    // load transactions
    await dispatch('loadGiftcardTransactions');
  },
  async resetGiftcardTransactions({ state, commit, dispatch }) {
    const defaultFilter = defaultGiftcardTransactionFilter();
    await dispatch('updateGiftcardTransactions', { ...defaultFilter });
  },
  async getGiftcard({ state, commit, rootGetters }, p: { id?: number, code?: string }): Promise<Giftcard|undefined> {
    if (!rootGetters.isLoaded || !state.isLoaded) return undefined;
    const giftcard = await grpcClient.getGiftcard(p, rootGetters as GiftcardMapping);
    return giftcard;
  },
  async redeemGiftcard({ state, commit, rootGetters }, p: { id: number, code: string, amount: number, balance: number, idempotencyKey?: string }): Promise<Giftcard|undefined> {
    if (!rootGetters.isLoaded || !state.isLoaded) return undefined;
    const giftcard = await grpcClient.redeemGiftcard(p.id, p.code, p.amount, p.balance, { idempotencyKey: p.idempotencyKey }, rootGetters as GiftcardMapping);
    return giftcard;
  },
};

const getters = <GetterTree<IGiftcardsState, IRootState>>{
  giftcardsProduct(state, localGetters, rootState, rootGetters): GiftcardProduct | null {
    return state.product;
  },
  isGiftcardsLoaded(state) {
    return state.isLoaded;
  },
  giftcardsTransactionFilter(state) {
    return state.transactionFilter;
  },
  isGiftcardsTransactionFilter(state) {
    const defaultFilter = defaultGiftcardTransactionFilter();
    return !areGiftcardTransactionFiltersSame(state.transactionFilter, defaultFilter);
  },
  areGiftcardTransactionsLoaded(state) {
    return state.areTransactionsLoaded;
  },
  giftcardTransactions(state) {
    return state.transactions;
  },
};

const ReservationSuggestionStore = {
  namespaced: false,
  state: new GiftcardsState(),
  mutations,
  actions,
  getters,
};

export default ReservationSuggestionStore;
