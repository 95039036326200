import ApiAttachment from '@/model/Attachment';
import * as pbm from '@/grpc-pb/reservation_pb';

export default class Attachment extends ApiAttachment {
  static fromGrpcModel(o?: pbm.ReservationAttachment): Attachment {
    const e = new Attachment();

    e.name = o?.getName() ?? '';
    e.url = o?.getUrl() ?? '';

    return e;
  }
}
