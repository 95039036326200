/* eslint-disable no-param-reassign */
import {
  GetterTree, MutationTree, ActionTree, Commit,
} from 'vuex';
import httpClient from '@/api/http-client';
import storage, { IStorageData } from '@/services/local-storage';
import UserAccount from '@/model/UserAccount';
import { IUpdate } from '@/api/api-update';
import { searchReset } from '@/services/search';
import { mergeToModelEntities } from '@/model/model-utils';
import ReportsCache from '@/services/reports-cache';
import IRootState, { ISessionState, ISetStoreState } from './store-state';

interface IAccountData {
  state: ISetStoreState,
  data: IStorageData;
}
const cache = new Map<number, IAccountData>();

function resetState(commit: Commit, hard = false) {
  if (hard) { cache.clear(); }

  storage.clear();
  searchReset();
  ReportsCache.reset();

  commit('RESET');
  commit('search/RESET');
  commit('nameSearch/RESET');
  commit('phoneSearch/RESET');
  commit('emailSearch/RESET');
}

function cacheState(sstate: ISetStoreState) {
  // strip down reactive watchers
  // const s = {} as {[key: string]: any};
  // Object.keys(sstate).forEach((key) => {
  //   s[key] = { ...(sstate as any)[key] };
  // });
  // const state = s as ISetStoreState;

  const state: ISetStoreState = {
    session: { ...sstate.session },
    update: { ...sstate.update },
    reservations: { ...sstate.reservations },
    codes: { ...sstate.codes },
    settings: { ...sstate.settings },
  };
  const data = { ...storage.getData() };
  cache.set(sstate.settings.account.id, { state, data });
}

function retrieveState(commit: Commit, id: number): boolean {
  const accountData = cache.get(id);
  if (!accountData) return false;

  console.log('retrieveState: ', accountData);

  storage.setData(accountData.data);
  commit('SET', { state: accountData.state });
  // commit('search/SET', { state });
  // commit('nameSearch/SET', { state });
  // commit('phoneSearch/SET', { state });
  // commit('emailSearch/SET', { state });
  return true;
}

export class SessionState implements ISessionState {
  token: string | null = storage.getToken();

  username: string | null = storage.getUsername();

  timestamp: string | null = null;

  unixTimestamp: string | null = null;

  userAccounts: UserAccount[] = [];

  fcmToken: string | null = storage.getFcmToken();
}

const mutations = <MutationTree<ISessionState>>{
  RESET(state: SessionState) {
    Object.assign(state, new SessionState());
  },
  SET(state: SessionState, p: { state: ISetStoreState }) {
    if (!p.state.session) return;
    console.log('SET session:', p.state.session);
    Object.assign(state, p.state.session);
  },
  SET_USERNAME(state: SessionState, username: string | null) {
    if (state.username === username) return;
    state.username = username;
    storage.setUsername(username);
  },
  SET_TOKEN(state: SessionState, token: string | null) {
    if (state.token === token) return;
    state.token = token;
    storage.setToken(token);
  },
  SET_TIMESTAMP(state: SessionState, p: { timestamp?: string | null, unixTimestamp?: string | null}) {
    if (p.timestamp !== undefined) state.timestamp = p.timestamp;
    if (p.unixTimestamp !== undefined) state.unixTimestamp = p.unixTimestamp;
  },
  UPDATE_USER_ACCOUNTS(state: SessionState, p: { userAccounts: UserAccount[] }) {
    state.userAccounts = p.userAccounts;
    console.log('userAccounts:', state.userAccounts);
  },
  SET_FCM_TOKEN(state: SessionState, fcmToken: string | null) {
    if (state.fcmToken === fcmToken) return;
    state.fcmToken = fcmToken;
    storage.setFcmToken(fcmToken);
  },
};

const actions = <ActionTree<ISessionState, IRootState>>{
  async login({ commit, dispatch }, p: { username: string, password: string }) {
    await httpClient.login(p.username, p.password);
    commit('SET_USERNAME', p.username);
    // dispatch('startAutoUpdate'); // done in main.vue in initialLoad()
  },

  async logout({ commit, dispatch }) {
    try {
      await httpClient.logout();
    } finally {
      resetState(commit, true);
    }
  },

  switchAccount({ rootState, commit }, p: { id: number, username: string, token: string }) {
    cacheState(rootState);
    resetState(commit);
    retrieveState(commit, p.id);
    commit('SET_TOKEN', p.token);
    commit('SET_USERNAME', p.username);
    // dispatch('startAutoUpdate'); // done in AccountSwitcher in changeAccount()
  },

  async openAccount({ state, commit, dispatch }, p: {
    username: string,
    password: string,
    firstname: string,
    companyName: string,
    phone: string,
    locale: string,
    promoCode: string }) {
    await httpClient.openAccount(p.username, p.password, p.firstname, p.companyName, p.phone, p.locale, p.promoCode);
    commit('SET_USERNAME', p.username);
    // dispatch('startAutoUpdate'); // done in RegisterAccountStep in submit()
  },
  async register(
    { state, commit, dispatch },
    p: { username: string, code: string },
  ) {
    await httpClient.register(p.username, p.code);
  },
  async deleteAccount({ state, commit, dispatch }, p?: {reason? : string}) {
    await httpClient.deleteAccount(p?.reason);
    resetState(commit, true);
  },
  updateUserAccounts({ state, commit }, update: IUpdate) {
    if (!update.userAccounts) return;

    console.log('updateUserAccounts');

    const userAccounts = mergeToModelEntities(
      UserAccount,
      update.isFullUpdate ? [] : state.userAccounts,
      update.userAccounts,
      { stringOrderField: 'accountName' },
    );

    this.commit('UPDATE_USER_ACCOUNTS', { userAccounts });
  },

};

const getters = <GetterTree<ISessionState, IRootState>>{
  isLoggedIn(state: SessionState, localGetters: any, rootState: any) {
    return state.token !== null;
  },
  isRegistered(state, localGetters, rootState) {
    return rootState.settings.account.isRegistered === true;
  },
  isLoggedInAndRegistered(state: SessionState, localGetters: any, rootState: any) {
    return state.token !== null && rootState.settings.account.isRegistered === true;
  },
  token(state: SessionState, localGetters: any, rootState: any) {
    return state.token;
  },
  username(state: SessionState, localGetters: any, rootState: any) {
    return state.username;
  },
  userAccounts(state: SessionState, localGetters: any, rootState: any) {
    return state.userAccounts;
  },
  fcmToken(state: SessionState, localGetters: any, rootState: any) {
    return state.fcmToken;
  },
};

const SessionStore = {
  namespaced: false,
  state: new SessionState(),
  mutations,
  actions,
  getters,
};

export default SessionStore;
