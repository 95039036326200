
import Vue, { PropType } from 'vue';
import ContactAutocompleteRow from '@/components/views/reservation/ContactAutocompleteRow.vue';
import { mapActions } from 'vuex';
import Contact from '@/model/Contact';
import { isModelEqualToModel } from '@/model/model-utils';
import { SearchType } from '@/services/search';
import { removeInvisibleUnicodeCharacters } from '@/util/common';

export default Vue.extend({
  name: 'ContactAutocomplete',
  components: { ContactAutocompleteRow },
  props: {
    focusName: { type: Boolean, required: false, default: false },
    contact: { type: Object as PropType<Contact>, required: true },
  },
  data() {
    return {
      searchName: null as string | null,
      searchPhone: null as string | null,
      searchEmail: null as string | null,
      contactName: null as Contact | null,
      contactPhone: null as Contact | null,
      contactEmail: null as Contact | null,
      searchContactChanged: false,
    };
  },
  computed: {
    defaultCC(): string {
      const { account } = this.$tstore.state.settings;
      return this.contact.country?.code || account.countryCode || '';
    },
    totalVisitsText(): string {
      return this.contact.reservationCount && this.contact.reservationCount > 0
        ? this.$i18n.t('label.total_visits_x', [this.contact.reservationCount]) as string
        : '';
    },
    hasContact(): boolean {
      return !isModelEqualToModel(this.contact, new Contact());
    },
    nameContacts(): Contact[] {
      const isNameSearch = this.searchContactChanged
        && this.$tstore.state.nameSearch.type === SearchType.ContactName;
      const contacts: Contact[] = isNameSearch ? this.$tstore.getters['nameSearch/searchContacts'] : [];
      return (this.contact.name === undefined || this.contact.name === '')
        ? contacts.filter((o) => o.id !== this.contact!.id)
        : [this.contact, ...contacts.filter((o) => o.id !== this.contact!.id)];
    },
    phoneContacts(): Contact[] {
      const isPhoneSearch = this.searchContactChanged
       && this.$tstore.state.phoneSearch.type === SearchType.ContactPhone;
      const contacts: Contact[] = isPhoneSearch ? this.$tstore.getters['phoneSearch/searchContacts'] : [];
      return (this.contact.phone === undefined || this.contact.phone === '')
        ? contacts.filter((o) => o.id !== this.contact!.id)
        : [this.contact, ...contacts.filter((o) => o.id !== this.contact!.id)];
    },
    emailContacts(): Contact[] {
      const isEmailSearch = this.searchContactChanged
       && this.$tstore.state.emailSearch.type === SearchType.ContactPhone;
      const contacts: Contact[] = isEmailSearch ? this.$tstore.getters['emailSearch/searchContacts'] : [];
      return (this.contact.email === undefined || this.contact.email === '')
        ? contacts.filter((o) => o.id !== this.contact!.id)
        : [this.contact, ...contacts.filter((o) => o.id !== this.contact!.id)];
    },
    isSearchingForName(): boolean {
      return this.$tstore.getters['nameSearch/isSearching'];
    },
    isSearchingForPhone(): boolean {
      return this.$tstore.getters['phoneSearch/isSearching'];
    },
    isSearchingForEmail(): boolean {
      return this.$tstore.getters['emailSearch/isSearching'];
    },
  },
  watch: {
    async searchName(newValue: string, oldValue: string) {
      const searchNameWasEmpty = !oldValue;
      if (newValue === this.contact.name) { return; }

      if (searchNameWasEmpty && this.searchName?.length === 1) {
        this.searchName = this.searchName.toUpperCase();
      }
      (this.$refs.nameValidationProvider as any).syncValue(this.searchName);
      (this.$refs.nameValidationProvider as any).validate();

      setTimeout(() => this.searchContactNameChanged(this.searchName || ''), 100);
    },
    async searchPhone(newValue: string, oldValue: string) {
      if (newValue === this.contact.phone) { return; }

      this.searchPhone = this.removeInvisibleUnicodeCharacters(this.searchPhone || '');
      (this.$refs.phoneValidationProvider as any).syncValue(this.searchPhone);
      (this.$refs.phoneValidationProvider as any).validate();

      setTimeout(() => this.searchContactPhoneChanged(this.searchPhone || ''), 100);
    },
    async searchEmail(newValue: string, oldValue: string) {
      if (newValue === this.contact.email) { return; }

      this.searchEmail = this.removeInvisibleUnicodeCharacters(this.searchEmail || '');
      (this.$refs.emailValidationProvider as any).syncValue(this.searchEmail);
      (this.$refs.emailValidationProvider as any).validate();

      setTimeout(() => this.searchContactEmailChanged(this.searchEmail || ''), 100);
    },
    contact: {
      deep: true,
      handler() {
        this.syncContactWithFields();
      },
    },
  },
  mounted() {
    this.syncContactWithFields();
  },
  methods: {
    ...mapActions([
      'sendEditReservation', 'confirmCloseEditReservation', 'closeEditReservation']),
    removeInvisibleUnicodeCharacters(input: string): string {
      return removeInvisibleUnicodeCharacters(input).trim();
    },
    contactBlur(event: FocusEvent) {
      console.log('contactBlur: ', event);
      this.searchContactChanged = false;
    },
    contactChanged(contact: Contact | null) {
      // this was causing error in the store, so hopefully nothing will break
      if (contact === null) {
        return;
      }
      this.$emit('update-contact', contact);
    },
    searchContactNameChanged(text: string) {
      console.log('searchContactNameChanged: ', text);
      this.searchContactChanged = true;
      this.$tstore.dispatch('nameSearch/search', { query: text ?? '', type: SearchType.ContactName });
      this.$emit('update-name', text);
    },
    searchContactPhoneChanged(text: string) {
      console.log('searchContactPhoneChanged: ', text);
      this.searchContactChanged = true;
      this.$tstore.dispatch('phoneSearch/search', { query: text ?? '', type: SearchType.ContactPhone });
      this.$emit('update-phone', text);
    },
    searchContactEmailChanged(text: string) {
      console.log('searchContactEmailChanged: ', text);
      this.searchContactChanged = true;
      this.$tstore.dispatch('emailSearch/search', { query: text ?? '', type: SearchType.ContactPhone });
      this.$emit('update-email', text);
    },
    syncContactWithFields() {
      if (this.contact.name !== null && this.contact.name !== '') {
        this.contactName = this.contact;
      } else {
        this.contactName = null;
      }
      if (this.contact.phone !== null && this.contact.phone !== '') {
        this.contactPhone = this.contact;
      } else {
        this.contactPhone = null;
      }
      if (this.contact.email !== null && this.contact.email !== '') {
        this.contactEmail = this.contact;
      } else {
        this.contactEmail = null;
      }
      (this.$refs.nameValidationProvider as any).syncValue(this.contact.name);
      (this.$refs.nameValidationProvider as any).validate();
      (this.$refs.phoneValidationProvider as any).syncValue(this.contact.phone);
      (this.$refs.phoneValidationProvider as any).validate();
      (this.$refs.emailValidationProvider as any).syncValue(this.contact.email);
      (this.$refs.emailValidationProvider as any).validate();
    },
  },
});
