import { ITab } from '@/api/api-update';
import { boolFromValue } from '@/api/api-utils';
import { toModelEntities } from './model-utils';
import TabItem from './TabItem';
import DurationRule from './DurationRule';

export interface IDTOTab extends ITab { }

export default class Tab {
  id: number;

  order: number;

  name: string;

  duration?: number;

  minDuration: number;

  capacity: number;

  tabItems: TabItem[];

  usingWeekdaysAndTimes?: boolean;

  bookingCap: number;

  constructor(o?: ITab) {
    this.id = o?.tabId ?? 0;
    this.order = o?.tabOrder ?? 0;
    this.name = o?.tabName ?? '';
    this.duration = o?.tabDuration;
    this.minDuration = o?.tabDurationMinimum ?? 0;
    this.capacity = o?.tabCapacity ?? 0;
    this.usingWeekdaysAndTimes = boolFromValue(o?.usingWeekdaysAndTimes);
    this.tabItems = toModelEntities(TabItem, o?.tabItems);
    this.bookingCap = o?.bookingCap ?? 0;
  }

  toDto(): IDTOTab {
    const dto: IDTOTab = {
      tabId: this.id,
      tabOrder: this.order,
      tabName: this.name,
      tabDuration: this.duration ?? undefined,
      tabDurationMinimum: this.minDuration ?? undefined,
      tabCapacity: this.capacity,
      usingWeekdaysAndTimes: this.usingWeekdaysAndTimes,
      // tabItems: this.tabItems?.map((o) => o.toDto()),
      bookingCap: this.bookingCap ?? undefined,
    };
    return dto;
  }

  get value() { return this.id; }

  get text() { return this.name; }
}
