import { defineComponent } from 'vue';
import moment from 'moment';
import storage from '@/services/local-storage';

export default defineComponent({
  props: {
    zoom: { type: Number, required: false, default: 1 },
    // eslint-disable-next-line vue/no-unused-properties
    dayPart: { type: Number || null, required: true },
    gotoDayPart: { type: Number || null, required: true },
    slotWidth: { type: Number, required: true },
    timelineStart: { type: Date, required: true },
  },
  data() {
    return {
      isScrolling: 0,
      currentScrollOffset: 0,
    };
  },
  computed: {
    asideWidth(): number {
      return this.zoom < 1 ? 60 : 70;
    },
  },
  watch: {
    gotoDayPart() {
      if (this.gotoDayPart === null) { return; }
      const scrollElm = (this.$refs.scrollableContainer as HTMLElement);
      if (this.gotoDayPart === -1) {
        // 6 being the scrollbar + half of the line width
        this.scrollTo(this.currentTimeOffset() - ((scrollElm.offsetWidth - this.asideWidth) / 2) + 6, 220);
        return;
      }
      this.$emit('update:goto-day-part', null);
      this.scrollTo(this.offsetForDayPart(), 220);
    },
  },
  mounted() {
    const scrollElm = (this.$refs.scrollableContainer as HTMLElement);
    const position = storage.getTimetablePosition();

    this.$nextTick(() => {
      if (position === null) {
        scrollElm.scrollLeft = this.currentTimeOffset() - ((scrollElm.offsetWidth - this.asideWidth) / 2) + 6;
      } else {
        scrollElm.scrollLeft = position;
      }
    });

    scrollElm.addEventListener('scroll', this.scrollListener);
  },
  beforeDestroy() {
    const scrollElm = (this.$refs.scrollableContainer as HTMLElement);
    scrollElm.removeEventListener('scroll', this.scrollListener);
    storage.setTimetablePosition(this.currentScrollOffset);
  },
  methods: {
    currentTimeOffset(): number {
      const diff = moment().diff(this.timelineStart, 'minutes');
      return diff * (this.slotWidth / 30.0);
    },
    scrollTo(to:number, duration: number) {
      const element = document.getElementById('tt-scrollable-container')!;
      const start = element.scrollLeft;
      const change = to - start;
      const increment = 20;
      let currentTime = 0;

      const easeInOutQuad = (time:number, b:number, c:number, d:number) => {
        let t = time / (d / 2);
        if (t < 1) return (c / 2) * t * t + b;
        t -= 1;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollLeft = val;
        if (currentTime < duration) {
          window.setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    },
    offsetForDayPart(): number {
      const hourOffset = this.gotoDayPart - moment(this.timelineStart).get('hour');
      return hourOffset * (this.slotWidth * 2) + 2;
    },
    dayPartForOffset(o: number):number {
      const currentHour = o / (this.slotWidth * 2) + moment(this.timelineStart).get('hour');
      if (currentHour < 12) { return 6; } // morning
      if (currentHour < 18) { return 12; } // afternoon
      return 18; // evening
    },
    scrollListener(evt: any) {
      window.clearTimeout(this.isScrolling);

      this.isScrolling = window.setTimeout(() => {
        const scrollElm = (this.$refs.scrollableContainer as HTMLElement);
        this.currentScrollOffset = scrollElm.scrollLeft;
        const dPart = this.dayPartForOffset(this.currentScrollOffset);
        this.$emit('update:day-part', dPart);
      }, 100);
    },
  },
});
