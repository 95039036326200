/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/message_pb';

export enum MessageType {
  EMAIL = 'email',
  SMS = 'sms',
}

export default class Message {
  messageType: MessageType = MessageType.EMAIL;

  reservationId?: number;

  feedbackId?: number;

  subject?: string;

  body?: string;

  sendBcc: boolean = false;

  constructor(o?: { messageType?: MessageType, reservationId?: number, feedbackId?: number, subject?: string, body?: string, sendBcc?: boolean}) {
    if (o?.messageType) this.messageType = o.messageType;
    if (o?.reservationId) this.reservationId = o.reservationId;
    if (o?.feedbackId) this.feedbackId = o.feedbackId;
    if (o?.subject) this.subject = o.subject;
    if (o?.body) this.body = o.body;
    if (o?.sendBcc) this.sendBcc = o.sendBcc;
  }

  toGrpcModel(): pbm.Message {
    const o = new pbm.Message();

    switch (this.messageType) {
      case MessageType.EMAIL: o.setMessageType(pbm.Message.MessageType.SENDGRID); break;
      case MessageType.SMS: o.setMessageType(pbm.Message.MessageType.SMS); break;
      default: o.setMessageType(pbm.Message.MessageType.SENDGRID); break;
    }

    if (this.reservationId) o.setReservationId(this.reservationId);
    if (this.feedbackId) o.setFeedbackId(this.feedbackId);
    if (this.subject) o.setSubject(this.subject);
    if (this.body) o.setBody(this.body);
    o.setSendBcc(this.sendBcc ?? false);

    return o;
  }
}
