import * as pbm from '@/grpc-pb/app_pb';
import * as pbs from '@/grpc-pb/apps_service_pb';

import ApiApp from '@/model/AppStoreApp';

export default class App extends ApiApp {
  params?: string;

  static fromGrpcResponse(r: pbs.AppResponse): App {
    const a = r.getApp();
    return App.fromGrpcModel(a);
  }

  static fromGrpcModel(o?: pbm.App): App {
    const a = new App();

    a.id = o?.getId() ?? 0;
    a.name = o?.getName() ?? '';
    a.isInstalled = o?.getIsinstalled() ?? false;
    a.params = o?.getParams() || undefined;

    return a;
  }

  toGrpcModel(): pbm.App {
    const o = new pbm.App();

    o.setId(this.id);
    if (this.name) o.setName(this.name);
    if (this.isInstalled) o.setIsinstalled(this.isInstalled);
    if (this.params) o.setParams(this.params);

    return o;
  }
}
