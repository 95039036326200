import { PluginObject, VueConstructor } from 'vue';
import ActivityIndicatorPlugin from './activity-indicator';

interface IActivityIndicatorPluginOptions {}

declare global {
  interface Window {
    Vue: VueConstructor;
  }
}

const version = '1.0.0';

const plugin: PluginObject<IActivityIndicatorPluginOptions> = {
  install: (Vue): void => {
    if (plugin.installed) {
      return;
    }
    plugin.installed = true;

    let activity = ActivityIndicatorPlugin;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$activity = activity;

    Object.defineProperties(Vue.prototype, {
      $activity: {
        get() {
          return activity;
        },
        set(a: typeof ActivityIndicatorPlugin) {
          activity = a;
        },
      },
    });
  },
  version,
};

export default plugin;
