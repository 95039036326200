export enum MessageType {
  SendGrid = 'SendGrid',
  APNS = 'APNS',
  MessageBird = 'MessageBird',
  Selligent = 'Selligent',
  SMTP = 'SMTP',
}

export const EmailMessageTypes = new Set([MessageType.SendGrid, MessageType.SMTP]);
export const SmsMessageTypes = new Set([MessageType.MessageBird]);

export enum MessageLogStatus {
  Blocked = 'Blocked',
  Bounce = 'Bounce',
  Failure = 'Failure',
  Invalid = 'Invalid',
  SendX = '_send',
  Send = 'Send',
  Delivered = 'Delivered',
}
