import Reservation from '@/model/Reservation';
import { MutationTree, GetterTree, ActionTree } from 'vuex';
import TabItem from '@/model/TabItem';
import Tab from '@/model/Tab';
import { suggestedDropTabItems } from '@/services/drag-drop-utils';
import { endDateForReservation } from '@/services/reservation-utils';
import { cloneModel } from '@/model/model-utils';
import { overbookedReservationsForReservation } from '@/services/reservation-validation';
import IRootState, { IDragDropState } from './store-state';

export class DragDropState implements IDragDropState {
  reservation: Reservation | null = null;

  tab: Tab | null = null;

  tabItems: TabItem[] = [];
}

const mutations = <MutationTree<IDragDropState>>{
  RESET(state: DragDropState) {
    Object.assign(state, new DragDropState());
  },
  SET_DRAGDROP_RESERVATION(state: DragDropState, reservation?: Reservation | null) {
    state.reservation = reservation ?? null;
  },
  SET_DRAGDROP_TAB_TABITEMS(state: DragDropState, p: { tab?: Tab | null, tabItems?: TabItem[]}) {
    state.tab = p.tab ?? null;
    state.tabItems = p.tabItems ?? [];
  },
};

const actions = <ActionTree<IDragDropState, IRootState>>{
  startDragDrop({ state, commit, rootGetters }, reservation: Reservation | undefined) {
    commit('SET_DRAGDROP_RESERVATION', reservation);
  },
  moveDragDrop({
    state, commit, dispatch, rootGetters,
  }, p: { tabId: number, tabItemId: number }) {
    if (state.reservation === null) return;

    const tab = rootGetters.tabsById.get(p.tabId);
    if (!tab) {
      commit('SET_DRAGDROP_TAB_TABITEMS');
      return;
    }

    const tabItems = suggestedDropTabItems(state.reservation.partySize, tab, p.tabItemId);

    commit('SET_DRAGDROP_TAB_TABITEMS', { tab, tabItems });
  },
  endDragDrop({ commit, dispatch }, ok: boolean) {
    if (ok) {
      dispatch('setReservationFromDragDropReservation');
    }
    commit('SET_DRAGDROP_RESERVATION', null);
  },
  updateReservation({ state, rootState, commit }) {
    if (!state.reservation || state.reservation.id === 0) return;

    state.reservation = rootState.reservations.reservationsById[state.reservation.id] ?? null;
  },
};

const getters = <GetterTree<IDragDropState, IRootState>>{
  isDragDrop(state, localGetters, rootState, rootGetters) {
    return state.reservation !== null;
  },
  isDragDropValid(state, localGetters, rootState, rootGetters) {
    return state.reservation !== null && state.tab !== null && state.tabItems.length > 0;
  },
  isDragDropChanged(state, localGetters, rootState, rootGetters) {
    return state.reservation?.tab?.id !== state.tab?.id
      || state.reservation?.tabItems.length !== state.tabItems.length
      || state.reservation?.tabItems.some((ti, i) => ti.id !== state.tabItems[i].id);
  },
  dragDropTab(state, localGetters, rootState, rootGetters): Tab | null {
    return state.tab;
  },
  dragDropTabItems(state, localGetters, rootState, rootGetters): TabItem[] {
    return state.tabItems;
  },
  dragDropDateEnd(state, localGetters, rootState, rootGetters): Date | null {
    if (state.reservation === null) return null;
    // const rules = rootState.settings.durationRules;
    // const date = endDateForReservation(state.reservation.dateBegin, state.tab, state.reservation.partySize, rules);
    const date = state.reservation.dateEnd;
    return date;
  },
  dragDropReservation(state, localGetters, rootState, rootGetters): Reservation | null {
    if (state.reservation === null) return null;

    const dr = cloneModel(state.reservation);

    dr.dateEnd = localGetters.dragDropDateEnd;
    dr.updateSlotsAndTimes();
    dr.tab = state.tab;
    dr.tabItems = state.tabItems;

    return dr;
  },
  dragDropConflictReservations(state, localGetters, rootState, rootGetters): Reservation[] {
    if (state.reservation === null) return [];
    return overbookedReservationsForReservation(
      state.reservation,
      rootGetters.dayValidReservationsAndBlocksByTabItemAndSlot,
    );
  },
};

const DragDropStore = {
  namespaced: false,
  state: new DragDropState(),
  mutations,
  actions,
  getters,
};

export default DragDropStore;
