import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { IUpdate } from '@/api/api-update';
import Country, { countries } from '@/model/Country';
import Locale from '@/model/Locale';
import TimeZone from '@/model/TimeZone';
import { toModelEntities, toMapByField } from '@/model/model-utils';
import { strcmp } from '@/services/common';
import IRootState, { ICodesState, ISetStoreState } from './store-state';

export class CodesState implements ICodesState {
  locales: Locale[] = [];

  timezones: TimeZone[] = [];

  countries: Country[] = countries;
}

const mutations = <MutationTree<ICodesState>>{
  RESET(state: CodesState) {
    Object.assign(state, new CodesState());
  },
  SET(state: CodesState, p: { state: ISetStoreState }) {
    if (!p.state.codes) return;
    console.log('SET codes:', p.state.codes);
    Object.assign(state, p.state.codes);
  },
  UPDATE_CODES(state: CodesState, p: { locales: Locale[], timezones: TimeZone[] }) {
    state.locales = p.locales;
    state.timezones = p.timezones;
  },
};

const actions = <ActionTree<ICodesState, IRootState>>{
  updateCodes({ commit }, update: IUpdate) {
    if (!update.locales && !update.timeZones) return;
    if (!update.isFullUpdate) throw Error('updateCodes: not full update');

    console.log('updateCodes');

    const p = {
      locales: toModelEntities(Locale, update.locales, { stringOrderField: 'locale' }),
      timezones: toModelEntities(TimeZone, update.timeZones, { stringOrderField: 'name' }),
    };

    commit('UPDATE_CODES', p);
  },
};

const getters = <GetterTree<ICodesState, IRootState>>{
  localesByLocale(state) { return toMapByField(state.locales, 'locale'); },
  countryByCode(state) { return toMapByField(state.countries, 'code'); },
  sortedLocales(state) { return state.locales.sort((o1, o2) => strcmp(o1.text, o2.text)); },
  sortedCountries(state) { return state.countries.sort((o1, o2) => strcmp(o1.text, o2.text)); },
  sortedTimezones(state) { return state.timezones.sort((o1, o2) => strcmp(o1.text, o2.text)); },
};

const CodesStore = {
  namespaced: false,
  state: new CodesState(),
  mutations,
  actions,
  getters,
};

export default CodesStore;
