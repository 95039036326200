
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import { cloneModel } from '@/model/model-utils';
import Contact from '@/grpc-api/model/contact';
import ContactsMergeRow from './ContactsMergeRow.vue';

export default Vue.extend({
  name: 'ContactsMergeDialog',
  components: { ContactsMergeRow },
  mixins: [DialogClosable],
  props: {
    contacts: { type: Array as PropType<Array<Contact>>, required: true, default: () => [] },
  },
  data() {
    return {
      contact: new Contact(),
      sideSelection: new Map<string, number>(),
      fieldDescriptors: [
        { field: 'name', label: this.$i18n.tc('label.name'), type: 'string' },
        { field: 'companyName', label: this.$i18n.tc('label.company'), type: 'string' },
        { field: 'email', label: this.$i18n.tc('label.email'), type: 'string' },
        { field: 'phone', label: this.$i18n.tc('label.phone'), type: 'string' },

        { field: 'notes', label: this.$i18n.tc('label.note'), type: 'string' },
        { field: 'isVIP', label: this.$i18n.tc('label.vip'), type: 'boolean' },
        { field: 'title', label: this.$i18n.tc('label.title'), type: 'string' },
        { field: 'newsletterSubscription', label: this.$i18n.tc('label.newsletter_subscription'), type: 'boolean' },

        { field: 'address', label: this.$i18n.tc('label.address'), type: 'string' },
        { field: 'postalCode', label: this.$i18n.tc('label.zip_code'), type: 'string' },
        { field: 'state', label: this.$i18n.tc('label.state'), type: 'string' },
        { field: 'city', label: this.$i18n.tc('label.city'), type: 'string' },
        { field: 'country', label: this.$i18n.tc('label.country'), type: 'string' },
        { field: 'preferredLanguage', label: this.$i18n.tc('label.preferred_language'), type: 'string' },
        { field: 'dateOfBirth', label: this.$i18n.tc('label.birthday'), type: 'string' },

        {
          field: 'updated', label: this.$i18n.tc('label.updated'), type: 'date', readOnly: true,
        },
        {
          field: 'visitCount', label: this.$i18n.tc('label.visits'), type: 'number', readOnly: true,
        },
        {
          field: 'lastVisit', label: this.$i18n.tc('label.last_visit'), type: 'date', readOnly: true,
        },
      ],
    };
  },
  computed: {
    displayedFieldDescriptors(): ({ field: string, label: string, type: string, readOnly?: boolean })[] {
      const descriptors = this.fieldDescriptors.filter(
        (fd) => this.contacts.some((c: any) => c[fd.field] !== (this.contact as any)[fd.field] || fd.readOnly === true),
      );
      return descriptors;
    },
    allOptionsSame(): boolean {
      return this.displayedFieldDescriptors.length === 0;
    },
    allOptionsMerged(): boolean {
      return this.displayedFieldDescriptors.every(
        (fd) => this.sideSelection.get(fd.field) !== undefined || fd?.readOnly,
      );
    },
  },
  watch: {
  },
  mounted() {
    this.contact = cloneModel(this.contacts[0]);
    this.fieldDescriptors.forEach((fd) => {
      if (fd.readOnly) return;
      const index = this.contacts.findIndex(
        (c: any) => c[fd.field] !== undefined && c[fd.field] !== null && c[fd.field] !== '' && c[fd.field] !== 0,
      );
      if (index < 0) return;
      this.sideSelection.set(fd.field, index);
    });
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    save() {
      const contact = new Contact();
      this.fieldDescriptors.forEach((fd) => {
        if (fd.readOnly) return;
        const index = this.sideSelection.get(fd.field);
        if (index !== undefined) (contact as any)[fd.field] = (this.contacts[index] as any)[fd.field];
      });
      this.$emit('merge', contact);
    },
    sideSelected(field: string, event: { field: string, side: number }) {
      this.sideSelection.set(field, event.side);
      this.sideSelection = new Map(this.sideSelection);
      (this.contact as any)[field] = (this.contacts[event.side] as any)[field];
    },
    fieldValue(field: string, contact: Contact) {
      const fd = this.fieldDescriptors.find((o) => o.field === field);
      const value = (contact as any)[field];

      if (!fd || value === undefined || value === null) return value;

      if (value.text !== undefined) return value.text;

      switch (fd.type) {
        case 'date': return this.$localized.shortDateText(value as Date);
        case 'boolean': return value === true ? this.$i18n.tc('label.true') : this.$i18n.tc('label.false');
        default: return value;
      }
    },
  },
});
