

import Vue from 'vue';

export default Vue.extend({
  name: 'GuestListPlaceholder',
  props: {
    title: { type: String, required: false, default: '' },
    buttonTitle: { type: String, required: false, default: null },
  },
  data() {
    return { };
  },
});

