
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import { ValidationObserver } from 'vee-validate';
import DayNote from '@/model/DayNote';
import { cloneModel } from '@/model/model-utils';

export default Vue.extend({
  mixins: [DialogClosable],
  props: {
    dayNote: { type: Object as PropType<DayNote>, required: true },
  },
  data: () => ({
    text: '',
    color: '#ffffff00',
    swatches: [
      ['#ffffff00', '#0011A3ff', '#8599ffff'],
      ['#4c000dff', '#ff002bff', '#ff99aaff'],
      ['#664e1fff', '#ffc24eff', '#ffe1a7ff'],
      ['#003121ff', '#00A26Fff', '#99dac5ff'],
      ['#663427ff', '#FF8262ff', '#ffcdc0ff'],
      ['#440d3eff', '#E42DD0ff', '#f7c0f1ff'],
    ],
  }),
  watch: {
    // value() {
    //   this.dayNote = this.value;
    // },
    // hack to ensure input field is focused & keyboard visible even on iOS
    visible(val) {
      if (!val) {
        return;
      }
      setTimeout(() => {
        const tfElm = ((this.$refs.inputField as Vue).$refs.input as HTMLInputElement);
        tfElm.focus();
      }, 250);
    },
  },
  created() {
    this.text = this.dayNote.text ?? '';
    let color = this.dayNote.color ? this.dayNote.color?.toLowerCase() : '#ffffff00';
    if (color.length === 7) color += 'ff';
    this.color = color;
  },
  methods: {
    close() {
      this.$emit('close', true);
    },
    async onClickOk() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) return;

      const value = cloneModel(this.dayNote);
      value.text = this.text;
      value.color = this.color.toLowerCase().startsWith('#ffffff') ? undefined : this.color.toLowerCase().slice(0, 7);
      this.$emit('confirm', value);
    },
    async onClickCancel() {
      this.close();
    },
  },
});
