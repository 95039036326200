/* eslint-disable vue/max-len */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */

import Reservation, { ReservationSource, ReservationType } from '@/model/Reservation';
import i18n from '@/plugins/i18n';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import Diff from 'text-diff';
import {
  EmailMessageTypes, MessageLogStatus, SmsMessageTypes, MessageType,
} from './messages';
import { dateFromDateTimeString, timeStringFromDate, unixFromDate } from './time-utils';

export interface History {
  records: HistoryRecord[]
  messages: HistoryMessage[]
}

export interface HistoryAttachment {
  attachment_name: string
  attachment_url: string
}
export interface HistoryRecord {
  reservation_id?: number
  contact_id?: number
  contact_name?: string
  contact_email?: string
  contact_phone?: string
  tab_id?: number
  tab_name?: string
  service_id?: number
  service_name?: string
  dt_begin?: number
  dt_end?: number
  end_fixed?: boolean
  party_size?: number
  hold_tab_items?: boolean
  reservation_note?: string
  reservation_status?: string
  reservation_label?: string
  campaign_id?: number
  campaign_name?: string
  reservation_campaign?: string
  dt_pre_reserved?: number
  is_starred?: boolean
  is_flagged?: boolean
  employee_edit_id?: number
  employee_edit?: string
  dt_create?: number
  dt_update?: number
  reserved_tab_item_ids?: number[],
  reserved_tab_items?: string[],
  reservation_attachments?: HistoryAttachment[],
  booking_type?: string
}

export interface HistoryMessage {
  recipient?: string
  status?: string
  is_undeliverable?: boolean
  message_type?: string
  event_type?: string
  event_name?: string
  dt_create?: number
  dt_update?: number
}

type HistoryRecordKey = keyof HistoryRecord

export enum HistoryItemType {
  Record = 'record',
  Message = 'message',
}

export enum HistoryItemSubtype {
  Other = 'other',
  Created = 'created',
  Modified = 'modified',
  Email = 'email',
  SMS = 'sms',
}

export enum HistoryItemFlag {
  None = 0,

  StatusSend = 0x0100,
  StatusBlocked = 0x0200,
  StatusBounced = 0x0400,
  StatusFailure = 0x0800,
  StatusInvalid = 0x1000,
  StatusUndeliverable = 0x2000,
  StatusDelivered = 0x4000,

  TypeOnline = 0x100000
}

export interface HistoryItemChange {
  field: string
  fieldKey: string
  textKey?: string
  textParams?: {
    value?: string
    htmlValue?: string
    newValue?: string
    oldValue?: string
  }
  excludeFields?: string[]
}

export interface HistorySubItem {
  textKey?: string
  textParams?: {}
}
export interface HistoryItem {
  type: HistoryItemType
  subtype: HistoryItemSubtype
  flags: HistoryItemFlag
  date: string
  originator?: string
  textKey: string
  textParams?: {
    eventName?: string
    recipient?: string
    status?: string
  }
  changes?: HistoryItemChange[]
  subItems?: HistorySubItem[]
  timeIndex: number
  hidden?: boolean
}

function valueText(field: HistoryRecordKey, value: any, hr?: HistoryRecord): string {
  switch (field) {
    case 'dt_begin':
    case 'dt_end':
    case 'dt_pre_reserved':
    {
      const date = dateFromDateTimeString(value);
      return timeStringFromDate(date) ?? 'N/A';
    }

    case 'reservation_status': return i18n.tc(`code.status.${value}`);
    case 'reservation_note': return `"${value}"`;
    case 'reserved_tab_items': return value.join(', ');
    case 'booking_type': return i18n.tc(`code.booking_type.${value}`);

    default:
      break;
  }
  return String(value);
}

function simpleCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord, p?: { key?: string, pkey?: string, ivals?: any[] }): HistoryItemChange[] {
  const newValue = hr[field] ? valueText(field, hr[field], hr) : undefined;
  const oldValue = phr[field] ? valueText(field, phr[field], phr) : undefined;
  let fieldKey = `code.history_field.${p?.key ?? field}`;
  let textKey = 'N/A';

  // ignore values
  if (p?.ivals && (p?.ivals.includes(newValue) || p?.ivals.includes(oldValue))) return [];

  // plural, value assumed to be array
  if (p?.pkey && (((hr[field] as any)?.length ?? 0) >= 2 || ((phr[field] as any)?.length ?? 0) >= 2)) {
    fieldKey = `code.history_field.${p?.pkey}`;
  }

  if (!newValue && oldValue) textKey = 'message.history_item_change_removed_value';
  else if (newValue && !oldValue) textKey = 'message.history_item_change_added_value';
  else if (newValue !== oldValue) textKey = 'message.history_item_change_modified_values';
  else return [];

  return [{
    field, fieldKey, textKey, textParams: { newValue, oldValue, value: newValue ?? oldValue },
  }];
}

function bookingTypeCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord): HistoryItemChange[] {
  const defaultBookingType = (r: HistoryRecord) => (r?.party_size === 0 ? ReservationType.Block : ReservationType.Reservation);

  const newValue = valueText(field, hr[field] ?? defaultBookingType(hr));
  const oldValue = valueText(field, phr[field] ?? defaultBookingType(phr));
  const fieldKey = `code.history_field.${field}`;
  let textKey = 'N/A';

  if (newValue !== oldValue) textKey = 'message.history_item_change_modified_values';
  else return [];

  return [{
    field, fieldKey, textKey, textParams: { newValue, oldValue, value: newValue ?? oldValue },
  }];
}

function idCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord, key: string, excludeFields: string[]): HistoryItemChange[] {
  const fieldKey = `code.history_field.${key}`;
  let textKey = 'N/A';

  if (!hr[field] && phr[field]) textKey = 'message.history_item_change_removed';
  else if (hr[field] && !phr[field]) textKey = 'message.history_item_change_added';
  else if (hr[field] !== phr[field]) textKey = 'message.history_item_change_modified';
  else return [];

  return [{
    field, fieldKey, textKey, excludeFields,
  }];
}

function boolCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord): HistoryItemChange[] {
  const fieldKey = `code.history_field.${field}`;
  let textKey = 'N/A';

  if (!hr[field] && phr[field]) textKey = 'message.history_item_change_unset';
  else if (hr[field] && !phr[field]) textKey = 'message.history_item_change_set';
  else return [];

  return [{ field, fieldKey, textKey }];
}

function tabItemsCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord): HistoryItemChange[] {
  // added and removed tab ritems
  const old = new Set<string>();
  phr.reserved_tab_items?.forEach((ti) => old.add(ti));
  const added: string[] = [];
  hr.reserved_tab_items?.forEach((ti) => (old.has(ti) ? old.delete(ti) : added.push(ti)));
  const removed = Array.from(old.values());

  // populate changes
  const changes: HistoryItemChange[] = [];

  if (added.length > 0) {
    const fieldKey = added.length === 1 ? 'code.history_field.reserved_tab_item' : 'code.history_field.reserved_tab_items';
    const textKey = 'message.history_item_change_added_value';
    const value = added.join(', ');
    changes.push({
      field, fieldKey, textKey, textParams: { value },
    });
  }

  if (removed.length > 0) {
    const fieldKey = removed.length === 1 ? 'code.history_field.reserved_tab_item' : 'code.history_field.reserved_tab_items';
    const textKey = 'message.history_item_change_removed_value';
    const value = removed.join(', ');
    changes.push({
      field, fieldKey, textKey, textParams: { value },
    });
  }

  return changes;
}

function tabItemsIdCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord): HistoryItemChange[] {
  // added and removed tab item ids
  const old = new Set<number>();
  phr.reserved_tab_item_ids?.forEach((tid) => old.add(tid));
  const added: number[] = [];
  hr.reserved_tab_item_ids?.forEach((tid) => (old.has(tid) ? old.delete(tid) : added.push(tid)));
  const removed = Array.from(old.values());

  // return change
  if (added.length > 0 || removed.length > 0) {
    const fieldKey = (added.length + removed.length) === 1 ? 'code.history_field.reserved_tab_item' : 'code.history_field.reserved_tab_items';
    const textKey = 'message.history_item_change_modified';
    const excludeFields = ['reserved_tab_items'];
    return [{
      field, fieldKey, textKey, excludeFields,
    }];
  }

  return [];
}

function attachmentCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord): HistoryItemChange[] {
  // added and removed attachments
  const old = new Map<string, HistoryAttachment>();
  phr.reservation_attachments?.forEach((att) => old.set(att.attachment_url, att));

  const added: HistoryAttachment[] = [];
  hr.reservation_attachments?.forEach((att) => (old.has(att.attachment_url) ? old.delete(att.attachment_url) : added.push(att)));

  // modified attachments
  const removed = new Set<string>();
  old.forEach((att) => removed.add(att.attachment_name));

  const modifiedNames: string[] = [];
  const addedNames: string[] = [];
  added.forEach((att) => (removed.has(att.attachment_name)
    ? (removed.delete(att.attachment_name), modifiedNames.push(att.attachment_name))
    : addedNames.push(att.attachment_name)));
  const removedNames = Array.from(removed.values());

  // populate changes
  const changes: HistoryItemChange[] = [];

  if (addedNames.length > 0) {
    const fieldKey = addedNames.length === 1 ? 'code.history_field.reservation_attachment' : 'code.history_field.reservation_attachments';
    const textKey = 'message.history_item_change_added_value';
    const value = addedNames.join(', ');
    changes.push({
      field, fieldKey, textKey, textParams: { value },
    });
  }

  if (modifiedNames.length > 0) {
    const fieldKey = modifiedNames.length === 1 ? 'code.history_field.reservation_attachment' : 'code.history_field.reservation_attachments';
    const textKey = 'message.history_item_change_modified_value';
    const value = modifiedNames.join(', ');
    changes.push({
      field, fieldKey, textKey, textParams: { value },
    });
  }

  if (removedNames.length > 0) {
    const fieldKey = removedNames.length === 1 ? 'code.history_field.reservation_attachment' : 'code.history_field.reservation_attachments';
    const textKey = 'message.history_item_change_removed_value';
    const value = removedNames.join(', ');
    changes.push({
      field, fieldKey, textKey, textParams: { value },
    });
  }

  return changes;
}

function dateCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord, key?: string): HistoryItemChange[] {
  const dateValueText = (value: any): string => {
    const date = dateFromDateTimeString(value);
    return localized.dateText(date) ?? 'N/A';
  };

  const newValue = hr[field] ? dateValueText(hr[field]) : undefined;
  const oldValue = phr[field] ? dateValueText(phr[field]) : undefined;
  const fieldKey = `code.history_field.${key ?? field}`;
  let textKey = 'N/A';

  if (!newValue && oldValue) textKey = 'message.history_item_change_removed_value';
  else if (newValue && !oldValue) textKey = 'message.history_item_change_added_value';
  else if (newValue !== oldValue) textKey = 'message.history_item_change_modified_values';
  else return [];

  return [{
    field, fieldKey, textKey, textParams: { newValue, oldValue, value: newValue ?? oldValue },
  }];
}

function diffCheck(field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord, key?: string): HistoryItemChange[] {
  let newValue = (hr[field] ?? undefined) as string | undefined;
  let oldValue = (phr[field] ?? undefined) as string | undefined;
  const fieldKey = `code.history_field.${key ?? field}`;
  let textKey = 'N/A';

  if (!newValue && oldValue) textKey = 'message.history_item_change_removed_value';
  else if (newValue && !oldValue) textKey = 'message.history_item_change_added_value';
  else if (newValue !== oldValue) textKey = 'message.history_item_change_modified_value';
  else return [];

  // html diff
  newValue = (newValue ?? '').replaceAll('\\n', '\n');
  oldValue = (oldValue ?? '').replaceAll('\\n', '\n');

  const d = new Diff();
  const diffs = d.main(oldValue, newValue);
  d.cleanupSemantic(diffs);
  const htmlValue = d.prettyHtml(diffs);

  return [{
    field,
    fieldKey,
    textKey,
    textParams: {
      newValue, oldValue, value: newValue ?? oldValue, htmlValue,
    },
  }];
}

type changeCheckFnc = (field: HistoryRecordKey, hr: HistoryRecord, phr: HistoryRecord) => HistoryItemChange[];
const changeChecks = new Map<string, changeCheckFnc>(Object.entries({
  // 'reservation_id': simpleCheck,
  contact_id: (field, hr, phr) => idCheck(field, hr, phr, 'contact', ['contact_name', 'contact_email', 'contact_phone']),
  contact_name: simpleCheck,
  contact_email: simpleCheck,
  contact_phone: simpleCheck,
  tab_id: (field, hr, phr) => idCheck(field, hr, phr, 'tab', ['tab_name']),
  tab_name: simpleCheck,
  service_id: (field, hr, phr) => idCheck(field, hr, phr, 'service', ['service_name']),
  service_name: simpleCheck,
  dt_begin: (field, hr, phr) => [...dateCheck(field, hr, phr, 'reservation_date'), ...simpleCheck(field, hr, phr)],
  dt_end: simpleCheck,
  end_fixed: boolCheck,
  party_size: simpleCheck,
  hold_tab_items: boolCheck,
  reservation_note: diffCheck,
  reservation_status: (field, hr, phr) => simpleCheck(field, hr, phr, { ivals: [valueText('reservation_status', 'PENDING')] }),
  reservation_label: simpleCheck,
  campaign_id: (field, hr, phr) => idCheck(field, hr, phr, 'campaign', ['campaign_id']),
  reservation_campaign: simpleCheck,
  // dt_pre_reserved: simpleCheck,
  is_starred: boolCheck,
  is_flagged: boolCheck,
  // 'employee_edit_id': simpleCheck,
  // 'employee_edit': simpleCheck,
  // 'dt_create': simpleCheck,
  // 'dt_update': simpleCheck,
  reserved_tab_item_ids: tabItemsIdCheck,
  reserved_tab_items: (field, hr, phr) => simpleCheck(field, hr, phr, { key: 'reserved_tab_item', pkey: 'reserved_tab_items' }), // simpleCheck, // tabItemsCheck,
  reservation_attachments: attachmentCheck,
  booking_type: bookingTypeCheck,
}));

function historyRecordToItem(hr: HistoryRecord, phr?: HistoryRecord): HistoryItem {
  // changes
  let changes = [] as HistoryItemChange[];
  if (phr) {
    changeChecks.forEach((chf, f) => changes.push(...chf(f as HistoryRecordKey, hr, phr)));
  }

  // remove changes if their exclude fields are present
  const fields = new Set<string>();
  changes.forEach((ch) => fields.add(ch.field));
  changes = changes.filter((ch) => !ch.excludeFields || !ch.excludeFields.some((ef) => fields.has(ef)));

  const isCreated = hr.dt_update === hr.dt_create;
  const subtype = isCreated ? HistoryItemSubtype.Created : HistoryItemSubtype.Modified;
  const textKey = isCreated ? 'message.history_item_created' : 'message.history_item_modified';
  const originator = isCreated ? hr.employee_edit ?? hr.campaign_name : hr.employee_edit;

  return {
    type: HistoryItemType.Record,
    subtype,
    flags: HistoryItemFlag.None,
    date: localized.shortDateTimeText(new Date((hr.dt_update ?? 0) * 1000)) ?? 'N/A',
    originator,
    textKey,
    changes,
    timeIndex: hr.dt_update ?? 0,
    hidden: changes.length === 0,
  };
}

function historyMessageToItem(mr: HistoryMessage): HistoryItem {
  let textKey = 'message.history_item_message';
  let hidden = false;
  let subtype = HistoryItemSubtype.Other;
  if (SmsMessageTypes.has(mr.message_type as MessageType)) {
    subtype = HistoryItemSubtype.SMS;
    textKey = 'message.history_item_message_sms';
  } else if (EmailMessageTypes.has(mr.message_type as MessageType)) {
    subtype = HistoryItemSubtype.Email;
    textKey = 'message.history_item_message_email';
  } else hidden = true;

  let subTextKey = '';
  if (mr.is_undeliverable) {
    subTextKey = mr.status && mr.status !== MessageLogStatus.Send && mr.status !== MessageLogStatus.SendX
      ? 'message.history_item_message_undeliverable_status'
      : 'message.history_item_message_undeliverable';
  }

  // subitems
  const subItems = [] as HistorySubItem[];

  // event name
  const eventNameKey = mr.event_type ? `code.message_event.${mr.event_type}` : 'message.history_item_message_event_manual_message';
  const eventNameText = i18n.tc(eventNameKey);
  const eventName = eventNameKey !== eventNameText ? eventNameText : mr.event_name; // undefined if no event_type

  // recipient
  const { recipient } = mr;
  if (recipient) subItems.push({ textKey: 'message.history_item_message_recipient' });

  // status
  let status = undefined as string | undefined;

  /* eslint-disable no-bitwise */
  let flags = HistoryItemFlag.None;
  switch (mr.status) {
    case MessageLogStatus.Send: flags |= HistoryItemFlag.StatusSend; break;
    case MessageLogStatus.SendX: flags |= HistoryItemFlag.StatusSend; break;
    case MessageLogStatus.Blocked: flags |= HistoryItemFlag.StatusBlocked; break;
    case MessageLogStatus.Bounce: flags |= HistoryItemFlag.StatusBlocked; break;
    case MessageLogStatus.Failure: flags |= HistoryItemFlag.StatusFailure; break;
    case MessageLogStatus.Invalid: flags |= HistoryItemFlag.StatusInvalid; break;
    case MessageLogStatus.Delivered: flags |= HistoryItemFlag.StatusDelivered; break;
    default:
  }

  if (mr.is_undeliverable) flags |= HistoryItemFlag.StatusUndeliverable;
  /* eslint-enable no-bitwise */

  if (mr.status && ![MessageLogStatus.Send, MessageLogStatus.SendX].includes(mr.status as MessageLogStatus)) {
    subItems.push({ textKey: 'message.history_item_message_status' });

    const statusKey = `code.message_log_status.${mr.status.toLowerCase()}`;
    const statusText = i18n.tc(statusKey);
    status = statusKey !== statusText ? statusText : undefined;
  }

  if (mr.is_undeliverable && !status) {
    subItems.push({ textKey: 'message.history_item_message_status' });
    status = i18n.tc('code.message_log_status.undeliverable');
  }

  return {
    type: HistoryItemType.Message,
    subtype,
    flags,
    date: localized.shortDateTimeText(new Date((mr.dt_update ?? 0) * 1000)) ?? 'N/A',
    textKey,
    textParams: { eventName, recipient, status },
    subItems: subItems.length > 0 ? subItems : undefined,
    timeIndex: mr.dt_update ?? 0,
    hidden,
  };
}

export function historyToItems(reservation: Reservation, history: History): HistoryItem[] {
  // record items
  const historyRecords = history.records.sort((a, b) => a.dt_update! - b.dt_update!);
  let keepItems = 1; // always keep the first item (do not hide create item)
  let campaignName = undefined as string | undefined;

  // fix missing create item
  if (reservation.dateCreated) { // reservation has date created (always true)
    const dt_create = unixFromDate(reservation.dateCreated);

    if (historyRecords.length > 0) {
      // records start with update item
      const diff = historyRecords[0].dt_create! - historyRecords[0].dt_update!;
      if (Math.abs(diff) < 2) {
        // only 2s difference -> ensure dt_crate === dt_update
        historyRecords[0].dt_update = historyRecords[0].dt_create;
      } else {
        // no create item -> create one from copy
        historyRecords.splice(0, 0, {
          ...historyRecords[0], dt_create, dt_update: dt_create, employee_edit: undefined, employee_edit_id: undefined,
        });
        keepItems += 1; // keep the second update item (do not hide)
        campaignName = historyRecords[1].campaign_name;
      }
    } else {
      // no records -> insert record
      historyRecords.push({ dt_create, dt_update: dt_create });
    }
  }

  let ritems = historyRecords.map((_, i, records) => historyRecordToItem(records[i], records[i - 1]));
  ritems = ritems.filter((hi, i) => hi.hidden !== true || i < keepItems);

  // fix create item
  if (ritems.length > 0 && ritems[0].subtype === HistoryItemSubtype.Created) {
    if (reservation.reservationType === ReservationSource.Online) {
      // eslint-disable-next-line no-bitwise
      ritems[0].flags |= HistoryItemFlag.TypeOnline;
      if (!ritems[0].originator) ritems[0].originator = campaignName;
    }
  }

  // message items
  const historyMessages = history.messages.sort((a, b) => a.dt_update! - b.dt_update!);
  let mitems = historyMessages.map((m) => historyMessageToItem(m));
  mitems = mitems.filter((mi) => mi.hidden !== true);

  // sort items by time and item type
  const iorder = new Map([[HistoryItemType.Record, 0], [HistoryItemType.Message, 1]]);
  const siorder = new Map([[HistoryItemSubtype.Email, 0], [HistoryItemSubtype.SMS, 1]]);
  const items = [...ritems, ...mitems].sort((i1, i2) => {
    let diff = i1.timeIndex - i2.timeIndex;
    if (!diff) diff = iorder.get(i1.type)! - iorder.get(i2.type)!;
    if (!diff) diff = siorder.get(i1.subtype)! - siorder.get(i2.subtype)!;
    return -diff;
  });

  return items;
}
