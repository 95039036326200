
import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableInputDialog',
  props: {
    type: { type: String, default: 'input' },
    items: { type: Array, default: () => [] as Array<any> },
    rules: { type: String, default: '' },
    label: { type: String, default: '' },
    value: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    dialog(val: boolean) {
      if (!val) { this.$emit('input', this.value); }
    },
  },
  methods: {
    updateValue(value: string) {
      this.$emit('input', value);
      // Auto dismiss on selected value in combobox
      if (this.type === 'select') {
        (this.$refs.dialog as Vue & { save: (val: any)=>void }).save(value);
      }
    },
  },
});
