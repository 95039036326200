import { dateFromDateAndTimeIndex } from '@/services/time-utils';
import DateAndTime from './DateAndTime';
import DayNote from './DayNote';
import Reservation, { ReservationStatus } from './Reservation';

export default class DateSlot {
  dateIndex: number;

  datesAndTimes: DateAndTime[] = [];

  reservations: Reservation[] = [];

  dayNotes: DayNote[] = [];

  confirmedCount: number = 0;

  nonConfirmedCount: number = 0;

  get date(): Date {
    return dateFromDateAndTimeIndex(this.dateIndex)!;
  }

  constructor(dateIndex: number) {
    this.dateIndex = dateIndex;
  }

  updateCounts() {
    this.confirmedCount = 0;
    this.nonConfirmedCount = 0;
    this.reservations.forEach((r) => {
      if ([ReservationStatus.Planned, ReservationStatus.Confirmed].includes(r.status)) this.confirmedCount += 1;
      if ([ReservationStatus.NonConfirmed].includes(r.status)) this.nonConfirmedCount += 1;
    });
  }
}
