

import Account from '@/model/Account';
import AccountSetting from '@/model/AccountSettings';
import DateAndTime from '@/model/DateAndTime';
import OpeningHour from '@/model/OpeningHour';
import Tab from '@/model/Tab';
import { minutesFromTimeString, slotFromMinutes, weekdayNumberFromDateDay } from '@/services/time-utils';
import { isOnlySimpleHours } from '@/services/hours-utils';
import Vue from 'vue';

enum StepType {
  CONTACT_INFO,
  TABLES,
  FLOORPLAN,
  ONLINE_RESERVATION,
  BRAND,
  OPENING_HOURS,
}

export default Vue.extend({
  name: 'OnboardingSteps',
  props: {
    fabVisible: { type: Boolean, required: true },
  },
  data() {
    return {
      menuExpanded: false,
      steps: [
        { type: StepType.CONTACT_INFO, text: this.$i18n.t('label.onboarding_contact_info'), value: false },
        { type: StepType.TABLES, text: this.$i18n.t('label.onboarding_add_tables'), value: false },
        { type: StepType.OPENING_HOURS, text: this.$i18n.t('label.onboarding_opening_hours'), value: false },
        // { type: StepType.FLOORPLAN, text: 'Set Up Floorplan', value: false },
        // { type: StepType.ONLINE_RESERVATION, text: 'Make Online Reservation', value: false },
        { type: StepType.BRAND, text: this.$i18n.t('label.onboarding_add_branding'), value: false },
      ],
    };
  },

  computed: {
    tabs(): Tab[] {
      const { tabs } = this.$tstore.state.settings;
      return tabs.sort((a, b) => a.order - b.order);
    },
    hostedFormUrl(): string {
      return this.$tstore.getters.hostedWidgetURL ?? 'N/A';
    },
    /*
    accountSettings(): AccountSetting | null {
      return this.$tstore.state.settings.accountSettings;
    },
    isContactInfo(): boolean {
      const a = this.$tstore.state.settings.account;
      const fields: (keyof Account)[] = [
        'accountName', 'streetAddress', 'city', 'postalCode', 'countryCode', 'countryState', 'locale', 'timeZoneId'];
      return fields.every((f) => a[f] !== undefined && a[f] !== '' && a[f] !== 0);
    },
    isFloorplan(): boolean {
      const { tabs } = this.$tstore.state.settings;
      return tabs.some((t) => t.tabItems.some((ti) => ti.posX && ti.posY));
    },
    isTables(): boolean {
      if (this.isFloorplan) return true;

      const { tabs } = this.$tstore.state.settings;
      if (tabs.length === 0) return false; // no tab
      if (tabs.length !== 2) return true; // not 2 tabs

      const tab = tabs[0];
      if (tabs[0].tabItems.length === 0 && tabs[1].tabItems.length === 0) return false; // no tab item
      if (tabs[0].tabItems.length !== 10) return true; // not 4 tab items
      if (tabs[1].tabItems.length !== 5) return true; // not 4 tab items
      if (tabs[0].tabItems.reduce((sum, ti) => sum + ti.seats, 0) !== 4 * 2 + 1 * 5 + 2 * 4 + 3 * 3) return true;
      if (tabs[1].tabItems.reduce((sum, ti) => sum + ti.seats, 0) !== 5 * 2) return true;

      return false;
    },
    isOnlineReservation(): boolean {
      const reservations = Object.values(this.$tstore.state.reservations.reservationsById) as Reservation[];
      return reservations.some((r) => r.type === ReservationType.Online);
    },
    isBrand(): boolean {
      const a = this.$tstore.state.settings.account;
      return Boolean(a.logoLink && a.color && a.color !== '#3355FF');
    },
    isOpeningHours(): boolean {
      // any special hour?
      const datesAndTimes = this.$tstore.getters.datesAndTimes as DateAndTime[];
      if (datesAndTimes.length > 0) return true;

      // changed opening hour? (18:00 - 21:30, every 30 min)
      const hourMaps = this.$tstore.getters.openingHoursInWeekdaysByTimeIndex as Map<number, OpeningHour>[];
      const bs = slotFromMinutes(minutesFromTimeString('18:00'));
      const es = slotFromMinutes(minutesFromTimeString('22:00'));

      return this.$localized.weekdays().some((day) => {
        const weekdayNumber = weekdayNumberFromDateDay(day.value);
        const hourMap = hourMaps[weekdayNumber];
        const hours = Array.from(hourMaps[weekdayNumber].values()).sort((o1, o2) => o1.timeIndex - o2.timeIndex);

        if (hours.length !== (es - bs) / 2) return true;

        for (let si = bs, i = 0; si < es; si += 2, i += 1) {
          if (hours[i].slot !== si) return true;
        }

        return false;
      });
    },
    */
    isStep(): Map<number, boolean> {
      const { accountSettings } = this.$tstore.state.settings;

      const map = new Map<number, boolean>();
      map.set(StepType.CONTACT_INFO, accountSettings.useSetupContact !== true);
      // map.set(StepType.FLOORPLAN, this.isFloorplan);
      map.set(StepType.TABLES, accountSettings.useSetupTables !== true);
      // map.set(StepType.ONLINE_RESERVATION, this.isOnlineReservation);
      map.set(StepType.BRAND, accountSettings.useSetupBranding !== true);
      map.set(StepType.OPENING_HOURS, accountSettings.useSetupHours !== true);

      return map;
    },
    unfinishedSteps(): number {
      return this.steps.map((s) => (s.value ? 0 : 1) as number).reduce((acc, cur) => acc + cur);
    },
  },
  watch: {
    fabVisible() {
      if (this.fabVisible) {
        this.menuExpanded = true;
      }
    },
    isStep: {
      immediate: true,
      handler(map: Map<number, boolean>) {
        this.steps.forEach((s) => {
          // Vue.set(s, 'value', map.get(s.type) ?? false);
        // eslint-disable-next-line no-param-reassign
          s.value = map.get(s.type) ?? false;
        });
      },
    },
    unfinishedSteps() {
      if (this.unfinishedSteps === 0) {
        this.$emit('hide-fab');
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.unfinishedSteps === 0) {
        this.$emit('hide-fab');
      }
    });
  },
  methods: {
    doStepAction(type: StepType) {
      switch (type) {
        case StepType.CONTACT_INFO:
        case StepType.BRAND:
          this.$router.push('/settings/general');
          break;
        case StepType.OPENING_HOURS:
          this.$router.push(isOnlySimpleHours(
            this.$tstore.getters.openingHoursInWeekdaysByTimeIndex,
          ) ? '/settings/simple-hours' : '/settings/hours');
          break;
        case StepType.FLOORPLAN:
          this.$router.push(`/settings/sections/${this.tabs[0].id}/?floorplan=true`);
          break;
        case StepType.TABLES:
          this.$router.push(`/settings/sections/${this.tabs[0].id}/?tables=true`);
          break;
        case StepType.ONLINE_RESERVATION:
          window.open(this.hostedFormUrl, 'windowName');
          break;
        default:
      }
    },
    toggleMenu() {
      this.menuExpanded = !this.menuExpanded;
      this.$emit('expand', this.menuExpanded);
    },
  },
});
