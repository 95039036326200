
import Vue from 'vue';

import ReportsToolbar from '@/components/views/reports/ReportsToolbar.vue';
import WeekdaysBarChart from '@/components/views/reports/WeekdaysBarChart.vue';
import AvgGuestsWeekdayChart from '@/components/views/reports/AvgGuestsWeekdayChart.vue';
import { ChartData, reportsValidateDateRange } from '@/services/reports-utils';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import ReportsStore from '@/store/reports-store';
import { ReportsType } from '@/services/reports';
import {
  DateRange, isWholeWeeks, makeWholeWeeks,
} from '@/services/date-range';
import { performActionEx } from '@/services/vue-utils';

export default Vue.extend({
  name: 'ReportWeekdays',
  components: {
    ReportsToolbar,
    AvgGuestsWeekdayChart,
    WeekdaysBarChart,
  },
  data() {
    return {};
  },
  computed: {
    isAccurate(): boolean {
      const dr = this.$tstore.state.reports.dateRange as DateRange;
      const ndr = makeWholeWeeks(dr, this.$localized.firstDayOfWeek());
      const error = reportsValidateDateRange(ndr);
      return error !== undefined || isWholeWeeks(dr.beginDate, dr.endDate, this.$localized.firstDayOfWeek());
    },
    averageGuests(): { dataset?: [number, number, number][], category?: Date[] } {
      return this.$tstore.getters.reportsWeekdaysAverageGuests;
    },
    services(): ChartData {
      return this.$tstore.getters.reportsWeekdaysServices;
    },
    servicesRevenue(): ChartData {
      return this.$tstore.getters.reportsWeekdaysServicesRevenue;
    },
    tabs(): ChartData {
      return this.$tstore.getters.reportsWeekdaysTabs;
    },
  },
  created() {
    loadStoreModule(this, 'reports', ReportsStore, async () => {
      this.$tstore.dispatch('updateReports', { reportsType: ReportsType.Weekdays });
    });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'reports', async () => { this.$tstore.dispatch('resetReports'); });
  },
  methods: {
    async makeAccurate() {
      const dr = this.$tstore.state.reports.dateRange as DateRange;
      const ndr = makeWholeWeeks(dr, this.$localized.firstDayOfWeek());

      await performActionEx(
        async () => this.$tstore.dispatch('setReportsDateRange', ndr),
        { errorPrefix: this.$i18n.tc('error.load_data'), hideActivityIndicator: true },
      );
    },
  },
});
