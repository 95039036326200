
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import Sortable from 'sortablejs';
import httpClient from '@/api/http-client';
import DataTableInputDialog from '@/components/dialogs/DataTableInputDialog.vue';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import {
  IApi,
  IModel,
  cloneModel,
  isModelEqualToModel,
  uniqueMinId,
} from '@/model/model-utils';
import { performSaveAction } from '@/services/vue-utils';

export default Vue.extend({
  name: 'SettingsList',
  components: { DataTableInputDialog, SettingsForm },
  mixins: [SettingsBreadcrumbs],
  props: {
    extraColumn: { type: Object, required: false, default: null },
    nameHeaderText: { type: String as PropType<string>, required: true },
    noDataText: { type: String, required: true },
    nameField: { type: String as PropType<string>, default: 'text' },
    storeEntities: { type: Array as PropType<IModel[]>, required: true },
    onSave: { type: Function as PropType<((entities: IModel[]) => Promise<void>)>, required: true },
    onAdd: { type: Function as PropType<(()=>IModel)>, required: true },
    onIsChangedChange: { type: Function as PropType<((value: boolean)=>IModel) | null>, default: null },
  },
  data() {
    return {
      entities: [] as IModel[],
      oldEntities: [] as IModel[],
      extraColumnItem: this.extraColumn !== null ? `item.${this.extraColumn.value}` : 'fake-column',
      unwatch: Function as (() => void),
      headers: [
        {
          text: '',
          width: '32',
          value: 'id',
          align: 'start',
          sortable: false,
        },
        {
          text: this.nameHeaderText,
          value: 'name',
          align: 'start',
          sortable: false,
        },
        this.extraColumn !== null ? this.extraColumn : { sortable: false },
        {
          text: '',
          width: '32',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      breadcrumbs: [],
    };
  },
  computed: {
    isChanged(): boolean {
      return !isModelEqualToModel(this.entities, this.oldEntities);
    },
  },
  watch: {
    isChanged(value: boolean, oldValue: boolean) {
      if (this.onIsChangedChange) { this.onIsChangedChange(value); }
    },
  },
  created() {
    this.unwatch = this.$tstore.watch(
      (state, getters) => getters.isLoaded,
      (newValue, oldValue) => {
        this.reset();
      },
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    this.reset();
  },
  methods: {
    changeOrder(event: Sortable.SortableEvent) {
      if (event.oldIndex === undefined || event.newIndex === undefined) return;
      const movedItem = this.entities.splice(event.oldIndex, 1)[0];
      this.entities.splice(event.newIndex, 0, movedItem);
    },
    add() {
      const e = this.onAdd();
      e.id = uniqueMinId(this.entities);
      this.entities.push(e);
    },
    remove(id: number) {
      this.entities = this.entities.filter((o: IModel) => o.id !== id);
    },
    reset() {
      this.oldEntities = this.storeEntities;
      this.entities = this.storeEntities.map((o) => cloneModel(o));
    },
    async save() {
      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.onSave(this.entities),
      );

      if (ok) {
        this.reset();
      }
    },
  },
});
