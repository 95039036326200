

import Vue, { PropType } from 'vue';
import DayNote from '@/model/DayNote';
import { contrastForColor } from '@/services/color-utils';

export default Vue.extend({
  name: 'DayNoteBar',
  components: { },
  props: {
    compact: { type: Boolean, required: false, default: false },
    onDelete: { type: Function as PropType<((dayNote: DayNote) => void)>, required: true },
    onEdit: { type: Function as PropType<((dayNote: DayNote) => void)>, required: true },
  },
  data() {
    return { };
  },
  computed: {
    dayNotes(): DayNote[] {
      return this.$tstore.getters.dayDayNotes;
    },
  },
  methods: {
    barColor(dn: DayNote): string {
      if (!dn.color) return this.$vuetify.theme.dark ? '#000000' : '#eeeeee';
      return dn.color;
    },
    textColor(dn: DayNote): string {
      return contrastForColor(this.barColor(dn));
    },
  },
});

