
import Vue, { PropType } from 'vue';
import QrcodeWithLogos from 'qrcode-with-logos';
import DialogClosable from '@/mixins/dialog-closable';
import { hostedWidgetURL } from '@/services/configuration';

export default Vue.extend({
  name: 'WebsiteQrDialog',
  components: { },
  mixins: [DialogClosable],
  props: {
    hostedFormUrl: { type: String as PropType<string>, required: true },
  },
  data() {
    return {
      qrcode: null as any,
      qrcodeDescription: this.$i18n.t('label.qrcode_message_placeholder'),
    };
  },
  computed: {},
  watch: { },
  mounted() {
    setTimeout(this.createCode, 100);
  },
  methods: {
    createCode() {
      const logo = (process.env.VUE_APP_QRCODE_WITH_LOGO === 'true' ? { src: '/assets/qrcode-logo.svg' } : undefined);
      this.qrcode = new QrcodeWithLogos({
        canvas: document.getElementById('qr-canvas') as HTMLCanvasElement,
        content: this.hostedFormUrl,
        width: this.$vuetify.breakpoint.xsOnly ? 300 : 380,
        logo,
      });
      this.qrcode.toCanvas();
    },
    print() {
      const dataUrl = (document.getElementById('qr-canvas') as HTMLCanvasElement).toDataURL();
      const newWin = window.open('', 'windowName', 'height=1024,width=682');
      newWin!.document.write(`
      <html>
      <body style="font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif"
            onload="window.print()">
        <center><img src="${dataUrl}"/></center>
        <h3 style="text-align: center">
          ${this.qrcodeDescription}
        </h3>
      </body>
      </html>`);
      newWin!.document.close();
      newWin!.focus();
    },
    saveImage() {
      console.log(this.qrcode);
      this.qrcode.toImage().then(() => {
        setTimeout(() => {
          this.qrcode.downloadImage('QRCode');
        }, 100);
      });
    },
  },
});
