export function strcmp(s1: string, s2: string): number {
  return s1.localeCompare(s2);
}

export function findLast<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): T | undefined {
  let l = array.length;
  while (l) {
    l -= 1;
    if (predicate(array[l], l, array)) { return array[l]; }
  }
  return undefined;
}

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l) {
    l -= 1;
    if (predicate(array[l], l, array)) { return l; }
  }
  return -1;
}

export function clamp(number: number, lower: number | undefined, upper: number | undefined) {
  let cnumber = number;
  if (upper !== undefined) {
    cnumber = number <= upper ? number : upper;
  }
  if (lower !== undefined) {
    cnumber = number >= lower ? number : lower;
  }
  return cnumber;
}
