import Tab from '@/model/Tab';
import { DEFAULT_TAB_DURATION } from '@/services/configuration';
import {
  timeNumberFromText, timeStringFromDate, dateByAddingMinutes,
  isDateBeforeDate, isDateAfterDate, dateFlooredToXMinutes,
} from '@/services/time-utils';
import DurationRule from '@/model/DurationRule';

export function durationForReservation(durationRules: DurationRule[], tab?:
Tab, partySize?: number, startTime?: string) {
  let duration = tab?.duration ?? DEFAULT_TAB_DURATION;
  if (!partySize || !tab || !startTime) return duration;

  const startTimeNumber = timeNumberFromText(startTime);
  if (!startTimeNumber) return duration;

  durationRules.forEach((dr) => {
    if (dr.tabId !== tab.id) return;
    if (dr.timeNumberFrom && startTimeNumber < dr.timeNumberFrom) return; // no rule.timeFrom -> no timeFrom check
    if (dr.timeNumberTo && startTimeNumber > dr.timeNumberTo) return; // no rule.timeTo -> no rule.timeTo check
    if (partySize < dr.partySizeFrom! || partySize > dr.partySizeTo!) return;
    duration += dr.additionalDuration;
  });

  return duration > 0 ? duration : 0;
}

export function endDateForReservation(
  dateBegin: Date,
  tab: Tab | null,
  partySize: number,
  durationRules: DurationRule[],
) {
  const time = timeStringFromDate(dateBegin)!;
  const duration = durationForReservation(durationRules, tab ?? undefined, partySize, time);
  const dateEnd = dateByAddingMinutes(dateBegin, duration);

  return dateEnd;
}

export function isOngoingReservation(r: { dateBegin: Date, dateEnd: Date }) {
  const now = new Date();
  const offset = 15;
  const dateBegin = dateByAddingMinutes(r.dateBegin, -offset);
  const dateEnd = dateByAddingMinutes(r.dateEnd, offset);
  return !(isDateBeforeDate(now, dateBegin) || isDateAfterDate(now, dateEnd));
}

export function isWalkInReservation(r: { dateBegin: Date }) {
  const now = new Date();
  const minutes = 30;
  const min = dateFlooredToXMinutes(now, minutes);
  const max = dateByAddingMinutes(min, minutes);
  return !isDateBeforeDate(r.dateBegin, min) && isDateBeforeDate(r.dateBegin, max);
}

export function endDateForCheckedOut(r: { dateBegin: Date, dateEnd: Date, fixedEnding: boolean }): Date | null {
  const now = new Date();
  if (isDateBeforeDate(now, r.dateBegin) || isDateAfterDate(now, r.dateEnd)) return null;

  let dateEnd = dateFlooredToXMinutes(now);
  while (!isDateAfterDate(dateEnd, r.dateBegin)) dateEnd = dateByAddingMinutes(dateEnd, 15);
  if (!isDateBeforeDate(dateEnd, r.dateEnd)) return null;

  return dateEnd;
}
