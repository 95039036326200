
import Vue, { PropType } from 'vue';
import { MenuItem } from '@/util/menu';

export default Vue.extend({
  name: 'MainMenuGroup',
  components: { },
  props: {
    miniVariant: { type: Boolean, required: false, default: false },
    menuItems: { type: Array as PropType<Array<MenuItem>>, required: false, default: () => [] },
    title: { type: String, required: true },
    icon: { type: String, required: true },
  },
  data() {
    return {
      group: false,
    };
  },
  computed: {
  },
  methods: {
    clicked() {
      this.$emit('menu-expand');
    },
  },
});
