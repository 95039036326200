/* eslint-disable no-underscore-dangle */
import store from '@/store/store';
import { Module } from 'vuex';
import IStoreState from '@/store/store-state';

interface Client {
  vue: Vue;
  reloadAction?: () => Promise<void>;
}

const clientsByModule = new Map<string, Client[]>();

store.watch((state, getters) => getters.isLoaded, async (value: boolean, oldValue: boolean) => {
  if (!value || value === oldValue) return;

  const clients = Array.from(clientsByModule.values());
  for (let i = 0; i < clients.length; i += 1) {
    for (let j = 0; j < clients[i].length; j += 1) {
      const client = clients[i][j];
      // eslint-disable-next-line no-await-in-loop
      if (client.reloadAction) await client.reloadAction();
    }
  }
});

export async function loadStoreModule<S>(
  vue: Vue,
  name: string,
  module: Module<S, IStoreState>,
  reloadAction?: () => Promise<void>,
) {
  // client already exists?
  const clients = clientsByModule.get(name) ?? [];
  if (clients.some((c) => c.vue === vue)) return;

  // register module
  if (clients.length === 0) {
    // store.registerModule([name], module);
    clientsByModule.set(name, clients);
  }

  if (store.getters.isLoaded && reloadAction) reloadAction();

  // save client
  clients.push({ vue, reloadAction });

  console.log('loadStoreModule:', clients);
}

export async function unloadStoreModule(vue: Vue, name: string, resetAction?: () => Promise<void>) {
  // delay uninstall
  await new Promise((resolve) => { setTimeout(resolve, 1000); });

  // find client
  const clients = clientsByModule.get(name) ?? [];
  const index = clients.findIndex((c) => c.vue === vue);
  if (index < 0) return;
  const client = clients.splice(index, 1)[0];

  // unregister module if no client
  if (clients.length === 0) {
    if (resetAction) resetAction();
    // store.unregisterModule(name);
    clientsByModule.delete(name);
  }

  console.log('unloadStoreModule:', clients);
}
