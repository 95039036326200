
import Vue from 'vue';
import Reservation, { ReservationStatus } from '@/model/Reservation';
import GuestList from '@/components/views/guests/GuestList.vue';
import GuestFilter from '@/components/views/guests/GuestFilter.vue';
import GuestFilterCompactBar from '@/components/views/guests/GuestFilterCompactBar.vue';
import ReservationsToolbar from '@/components/views/main/ReservationsToolbar.vue';
import KeyboardControl from '@/mixins/keyboard-control';
import GuestListPlaceholder from '@/components/views/guests/GuestListPlaceholder.vue';

enum PlaceholderType { NONE, NOT_LOADED, NO_RESERVATION, NOT_OPEN, CLEAR_FILTER, ALL_FILTER }

export default Vue.extend({
  name: 'Guests',
  components: {
    GuestList,
    ReservationsToolbar,
    GuestFilter,
    GuestFilterCompactBar,
    GuestListPlaceholder,
  },
  mixins: [KeyboardControl],
  data() {
    return {
      filterVisible: false,
    };
  },

  computed: {
    reservations(): Reservation[] {
      return this.$tstore.getters.filteredReservationsNoBlocks;
    },
    reservationsByTime(): Map<string, Reservation[]> {
      return this.$tstore.getters.filteredReservationsNoBlocksByTimeText;
    },
    hasFilter(): boolean {
      return this.$tstore.getters.isFilter;
    },
    stats() {
      return this.$tstore.getters.dayStats;
    },
    showLunchDinnerStats() {
      return this.$tstore.state.settings.accountSettings.showLunchDinner ?? false;
    },
    showNotOpen(): boolean { return this.placeholderType === PlaceholderType.NOT_OPEN; },
    showNoReservation(): boolean { return this.placeholderType === PlaceholderType.NO_RESERVATION; },
    showClearFilter(): boolean { return this.placeholderType === PlaceholderType.CLEAR_FILTER; },
    showAllFilter(): boolean { return this.placeholderType === PlaceholderType.ALL_FILTER; },
    placeholderType() {
      const {
        isDateLoaded, isDayOpen,
        isDayReservationNoBlock, isDayValidForTodayReservationNoBlock, isDayFilteredReservationNoBlock,
      } = this.$tstore.getters;

      if (!isDateLoaded) return PlaceholderType.NOT_LOADED; // day reservation not loaded -> not loaded placehoder
      if (isDayFilteredReservationNoBlock) return PlaceholderType.NONE; // some reservations passed filter -> no p.
      if (isDayValidForTodayReservationNoBlock) return PlaceholderType.CLEAR_FILTER; // some valid res. -> clear f. p.
      if (isDayReservationNoBlock) return PlaceholderType.ALL_FILTER; // some invalid reservations -> all filter p.
      if (!isDayOpen) return PlaceholderType.NOT_OPEN; // not open -> not open placehoder
      return PlaceholderType.NO_RESERVATION; // is open but no reservation -> no reservation placeholder
    },
  },
  watch: {
    hasFilter() {
      if (this.hasFilter) { return; }
      this.filterVisible = false;
    },
  },
  methods: {
    filterBtnClicked() {
      if (this.hasFilter && this.$vuetify.breakpoint.smAndUp) {
        this.$tstore.dispatch('resetFilter');
      } else {
        this.filterVisible = !this.filterVisible;
      }
    },
    clearFilter() {
      this.$tstore.dispatch('resetFilter');
    },
    applyAllFilter() {
      this.$tstore.dispatch('applyAllFilter');
    },
    applyWaitlistFilter() {
      this.$tstore.dispatch('applyFilter', { status: ReservationStatus.WaitingList });
    },
  },
});

