import store from '@/store/store';
import { dateByAddingMinutes, dateFlooredToMinutes } from './time-utils';
import Timer from './timer';

let timer: Timer;

async function update() {
  const now = await store.dispatch('updateNow') as Date;
  const next = dateFlooredToMinutes(dateByAddingMinutes(now, 1));
  timer.schedule(next);
}

timer = new Timer(async () => {
  await update();
});

// eslint-disable-next-line import/prefer-default-export
export async function startTodayUpdatesAsync() {
  return update();
}
