import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { dateByAddingSeconds } from '@/services/time-utils';
import { UpdateOriginator } from '@/services/store-utils';
import IRootState, { IPushUpdateState } from './store-state';

export class PushUpdateState implements IPushUpdateState {
  requestTimestamp: number = 0;

  processTimestamp: number = 0;

  counter: number = 0;
}

const mutations = <MutationTree<IPushUpdateState>>{
  RESET(state: PushUpdateState) {
    Object.assign(state, new PushUpdateState());
  },
  PUSH_SET_TIMESTAMP(state: PushUpdateState, p: { requestTimestamp?: number, processTimestamp?: number }) {
    if (p.requestTimestamp !== undefined) state.requestTimestamp = p.requestTimestamp;
    if (p.processTimestamp !== undefined) state.processTimestamp = p.processTimestamp;
  },
  PUSH_INCREMENT_COUNTER(state: PushUpdateState) {
    state.counter += 1;
  },
};

const actions = <ActionTree<IPushUpdateState, IRootState>>{
  setPushUpdateTimestamp({ state, commit, rootGetters }, p: { timestamp: number }) {
    if (state.requestTimestamp >= p.timestamp) return;
    commit('PUSH_SET_TIMESTAMP', { requestTimestamp: p.timestamp });
  },
  async performPushUpdate({
    state, commit, dispatch, getters,
  }) {
    if (!getters.pushUpdateRequestTimestamp) return;

    const processTimestamp = state.requestTimestamp;

    try {
      await dispatch('update', { originator: UpdateOriginator.Push });
    } finally {
      commit('PUSH_INCREMENT_COUNTER');
      if (state.processTimestamp < processTimestamp) commit('PUSH_SET_TIMESTAMP', { processTimestamp });
    }
  },
};

const getters = <GetterTree<IPushUpdateState, IRootState>>{
  pushUpdateRequestTimestamp(state, localGetters, rootState, rootGetters): number | null {
    // update not allowed now -> wait
    if (!rootState.autoUpdate.on || rootState.update.processing || rootState.update.updating) return null;

    // push update already attempted
    if (state.processTimestamp === state.requestTimestamp) return null;

    // push update not needed
    const updateTimestamp = parseFloat(rootState.session.unixTimestamp ?? '0');
    if (!updateTimestamp || updateTimestamp >= state.requestTimestamp) return null;

    return state.requestTimestamp;
  },
};

const PushUpdateStore = {
  namespaced: false,
  state: new PushUpdateState(),
  mutations,
  actions,
  getters,
};

export default PushUpdateStore;
