import store from '@/store/store';
import Reservation, { ReservationStatus, ReservationType } from '@/model/Reservation';
import Tab from '@/model/Tab';
import TabItem from '@/model/TabItem';
import VueRouter from 'vue-router';
import Contact from '@/model/Contact';
import i18n from '@/plugins/i18n';
import { isDateSameIgnoringTime } from './time-utils';
import { performAction } from './vue-utils';

export async function tryEditReservation(
  router: VueRouter,
  reservation?: Reservation | number,
  rp?: {
    tab?: Tab,
    tabItems?: TabItem[],
    dateBegin?: Date,
    timeSlot?: number,
    partySize?: number,
    status?: ReservationStatus
    contact?: Contact,
    bookingType?: ReservationType,
  },
  p?: {
    changeToDate?: Date,
    forceGuestsRoute?: boolean,
  },
): Promise<boolean> {
  const confirm = await store.dispatch('canCloseEditReservation');
  if (!confirm) return false;

  // force guests view
  if (p?.forceGuestsRoute && router.currentRoute.name !== 'guests') {
    router.push('/guests');
  }

  // change date
  let changeToDate = p?.changeToDate;
  if (!changeToDate && p?.forceGuestsRoute && reservation && typeof reservation !== 'number') {
    changeToDate = reservation.dateBegin;
  }
  if (changeToDate && !isDateSameIgnoringTime(changeToDate, store.state.update.date)) {
    const res = await performAction(
      null,
      i18n.tc('error.load_data'),
      () => store.dispatch('changeToDate', changeToDate),
    );
    if (!res) return false;
  }

  // find reservation from id
  if (reservation && typeof reservation === 'number') {
    // eslint-disable-next-line no-param-reassign
    reservation = store.state.reservations.reservationsById[reservation];
    if (!reservation) return false;
  }

  // show reservation editor
  store.dispatch('editReservation', { reservation, ...rp });
  return true;
}

export async function tryCloseEditReservation(): Promise<boolean> {
  const confirm = await store.dispatch('canCloseEditReservation');
  if (!confirm) return false;
  store.dispatch('closeEditReservation');
  return true;
}
