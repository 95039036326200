// source: reservation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var contact_pb = require('./contact_pb.js');
goog.object.extend(proto, contact_pb);
goog.exportSymbol('proto.grpc.api.v1.Reservation', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReservationAttachment', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReservationStatus', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReservationType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ReservationAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ReservationAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ReservationAttachment.displayName = 'proto.grpc.api.v1.ReservationAttachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.Reservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.Reservation.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.Reservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.Reservation.displayName = 'proto.grpc.api.v1.Reservation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ReservationAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ReservationAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ReservationAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReservationAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ReservationAttachment}
 */
proto.grpc.api.v1.ReservationAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ReservationAttachment;
  return proto.grpc.api.v1.ReservationAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ReservationAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ReservationAttachment}
 */
proto.grpc.api.v1.ReservationAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ReservationAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ReservationAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ReservationAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReservationAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpc.api.v1.ReservationAttachment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationAttachment} returns this
 */
proto.grpc.api.v1.ReservationAttachment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.grpc.api.v1.ReservationAttachment.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationAttachment} returns this
 */
proto.grpc.api.v1.ReservationAttachment.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.Reservation.repeatedFields_ = [29,31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.Reservation.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.Reservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.Reservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.Reservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contactId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tabId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    serviceId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dtBegin: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dtEnd: jspb.Message.getFieldWithDefault(msg, 9, 0),
    endFixed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    partySize: jspb.Message.getFieldWithDefault(msg, 11, 0),
    holdTabItems: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    reservationType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    reservationStatus: jspb.Message.getFieldWithDefault(msg, 14, 0),
    reservationLabel: jspb.Message.getFieldWithDefault(msg, 15, ""),
    reservationCampaign: jspb.Message.getFieldWithDefault(msg, 16, ""),
    dtPreReserved: jspb.Message.getFieldWithDefault(msg, 17, 0),
    isStarred: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    isFlagged: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    isUnread: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    employeeIdCreate: jspb.Message.getFieldWithDefault(msg, 21, 0),
    employeeIdLastEdit: jspb.Message.getFieldWithDefault(msg, 22, 0),
    dtCreate: jspb.Message.getFieldWithDefault(msg, 23, 0),
    dtUpdate: jspb.Message.getFieldWithDefault(msg, 24, 0),
    syncIsNeeded: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    noteText: jspb.Message.getFieldWithDefault(msg, 26, ""),
    paymentInfo: jspb.Message.getFieldWithDefault(msg, 27, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 28, 0),
    tabItemsIdsList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
    contact: (f = msg.getContact()) && contact_pb.Contact.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto.grpc.api.v1.ReservationAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.Reservation}
 */
proto.grpc.api.v1.Reservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.Reservation;
  return proto.grpc.api.v1.Reservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.Reservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.Reservation}
 */
proto.grpc.api.v1.Reservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTabId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServiceId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtBegin(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtEnd(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEndFixed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPartySize(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHoldTabItems(value);
      break;
    case 13:
      var value = /** @type {!proto.grpc.api.v1.ReservationType} */ (reader.readEnum());
      msg.setReservationType(value);
      break;
    case 14:
      var value = /** @type {!proto.grpc.api.v1.ReservationStatus} */ (reader.readEnum());
      msg.setReservationStatus(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationLabel(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCampaign(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtPreReserved(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStarred(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFlagged(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUnread(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmployeeIdCreate(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEmployeeIdLastEdit(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCreate(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtUpdate(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncIsNeeded(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteText(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentInfo(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 29:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTabItemsIds(values[i]);
      }
      break;
    case 30:
      var value = new contact_pb.Contact;
      reader.readMessage(value,contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 31:
      var value = new proto.grpc.api.v1.ReservationAttachment;
      reader.readMessage(value,proto.grpc.api.v1.ReservationAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.Reservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.Reservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.Reservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.Reservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContactId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTabId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDtBegin();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDtEnd();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEndFixed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPartySize();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getHoldTabItems();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getReservationType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getReservationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getReservationLabel();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReservationCampaign();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDtPreReserved();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getIsStarred();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getIsFlagged();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIsUnread();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getEmployeeIdCreate();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getEmployeeIdLastEdit();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getDtCreate();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getDtUpdate();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getSyncIsNeeded();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getNoteText();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPaymentInfo();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getTabItemsIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      29,
      f
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      contact_pb.Contact.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.grpc.api.v1.ReservationAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 account_id = 2;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 contact_id = 3;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getContactId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setContactId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 tab_id = 4;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getTabId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setTabId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 campaign_id = 5;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 service_id = 6;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string reservation_code = 7;
 * @return {string}
 */
proto.grpc.api.v1.Reservation.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 dt_begin = 8;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getDtBegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setDtBegin = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 dt_end = 9;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getDtEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setDtEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool end_fixed = 10;
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.getEndFixed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setEndFixed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 party_size = 11;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getPartySize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setPartySize = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool hold_tab_items = 12;
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.getHoldTabItems = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setHoldTabItems = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional ReservationType reservation_type = 13;
 * @return {!proto.grpc.api.v1.ReservationType}
 */
proto.grpc.api.v1.Reservation.prototype.getReservationType = function() {
  return /** @type {!proto.grpc.api.v1.ReservationType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.grpc.api.v1.ReservationType} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setReservationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional ReservationStatus reservation_status = 14;
 * @return {!proto.grpc.api.v1.ReservationStatus}
 */
proto.grpc.api.v1.Reservation.prototype.getReservationStatus = function() {
  return /** @type {!proto.grpc.api.v1.ReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.grpc.api.v1.ReservationStatus} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setReservationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string reservation_label = 15;
 * @return {string}
 */
proto.grpc.api.v1.Reservation.prototype.getReservationLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setReservationLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string reservation_campaign = 16;
 * @return {string}
 */
proto.grpc.api.v1.Reservation.prototype.getReservationCampaign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setReservationCampaign = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 dt_pre_reserved = 17;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getDtPreReserved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setDtPreReserved = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool is_starred = 18;
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.getIsStarred = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setIsStarred = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool is_flagged = 19;
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.getIsFlagged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setIsFlagged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool is_unread = 20;
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.getIsUnread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setIsUnread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int64 employee_id_create = 21;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getEmployeeIdCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setEmployeeIdCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int64 employee_id_last_edit = 22;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getEmployeeIdLastEdit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setEmployeeIdLastEdit = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 dt_create = 23;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getDtCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setDtCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 dt_update = 24;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getDtUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setDtUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional bool sync_is_needed = 25;
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.getSyncIsNeeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setSyncIsNeeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional string note_text = 26;
 * @return {string}
 */
proto.grpc.api.v1.Reservation.prototype.getNoteText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setNoteText = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string payment_info = 27;
 * @return {string}
 */
proto.grpc.api.v1.Reservation.prototype.getPaymentInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setPaymentInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional int32 rating = 28;
 * @return {number}
 */
proto.grpc.api.v1.Reservation.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * repeated int64 tab_items_ids = 29;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.Reservation.prototype.getTabItemsIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 29));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.setTabItemsIdsList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.addTabItemsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.clearTabItemsIdsList = function() {
  return this.setTabItemsIdsList([]);
};


/**
 * optional Contact contact = 30;
 * @return {?proto.grpc.api.v1.Contact}
 */
proto.grpc.api.v1.Reservation.prototype.getContact = function() {
  return /** @type{?proto.grpc.api.v1.Contact} */ (
    jspb.Message.getWrapperField(this, contact_pb.Contact, 30));
};


/**
 * @param {?proto.grpc.api.v1.Contact|undefined} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
*/
proto.grpc.api.v1.Reservation.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.Reservation.prototype.hasContact = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated ReservationAttachment attachments = 31;
 * @return {!Array<!proto.grpc.api.v1.ReservationAttachment>}
 */
proto.grpc.api.v1.Reservation.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.ReservationAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.ReservationAttachment, 31));
};


/**
 * @param {!Array<!proto.grpc.api.v1.ReservationAttachment>} value
 * @return {!proto.grpc.api.v1.Reservation} returns this
*/
proto.grpc.api.v1.Reservation.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.grpc.api.v1.ReservationAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ReservationAttachment}
 */
proto.grpc.api.v1.Reservation.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.grpc.api.v1.ReservationAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.Reservation} returns this
 */
proto.grpc.api.v1.Reservation.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * @enum {number}
 */
proto.grpc.api.v1.ReservationStatus = {
  NONE: 0,
  PLANNED: 1,
  SEATED: 2,
  CHECKED_IN: 3,
  CHECKED_OUT: 4,
  CONFIRMED: 5,
  NON_CONFIRMED: 6,
  WAITING_LIST: 7,
  CANCELLED: 8,
  NOSHOW: 9,
  SPAM: 10,
  DELETED: 11,
  PENDING: 12
};

/**
 * @enum {number}
 */
proto.grpc.api.v1.ReservationType = {
  NONE: 0,
  ONLINE: 1,
  USER: 2
};

goog.object.extend(exports, proto.grpc.api.v1);
