

import Vue from 'vue';
import ReservationsToolbar from '@/components/views/main/ReservationsToolbar.vue';
import FloorplanCanvas from '@/components/views/floorplan/FloorplanCanvas.vue';
import FloorplanViewSidebar from '@/components/views/floorplan/FloorplanViewSidebar.vue';
import TabItem from '@/model/TabItem';
import Reservation from '@/model/Reservation';
import Tab from '@/model/Tab';
import { mapBySlotToMapByTimeText, toSortedMapBySlot } from '@/services/reservations-utils';
import {
  DAY_TIME_SLOTS_COUNT, MORNING_TIME_SLOTS_COUNT,
  minutesFromDate, minutesFromSlot, slotFromMinutes, niceTimeTextFromSlot,
} from '@/services/time-utils';
import { tryCloseEditReservation, tryEditReservation } from '@/services/reservation-edit-utils';
import storage from '@/services/local-storage';
import { clamp } from '@/services/common';
import KeyboardControl from '@/mixins/keyboard-control';
import { durationForReservation } from '@/services/reservation-utils';
import TableAllocationCTA from '@/components/util/TableAllocationCTA.vue';

export default Vue.extend({
  name: 'Floor',
  components: {
    FloorplanCanvas,
    FloorplanViewSidebar,
    ReservationsToolbar,
    TableAllocationCTA,
  },
  mixins: [KeyboardControl],
  data() {
    const isToday = this.$tstore.getters.isToday as boolean;
    return {
      zoom: 1,
      zoomLevels: [
        { value: 0.25, text: '25%' },
        { value: 0.5, text: '50%' },
        { value: 0.75, text: '75%' },
        { value: 1, text: '100%' },
        { value: 1.25, text: '125%' },
        { value: 1.50, text: '150%' },
        { value: 1.75, text: '175%' }],
      timeSlot: MORNING_TIME_SLOTS_COUNT,
      selectedTabItemIds: undefined as number[] | undefined,
      selectedResId: undefined as number | undefined,
      canvasMaximized: false,
      timeTracking: isToday, // should be false if not today
      timeTrackingInterval: 0 as number,
    };
  },
  computed: {
    editedReservationId(): number | undefined {
      return this.$tstore.getters.editReservation?.id;
    },
    isLoaded(): boolean {
      return this.$tstore.getters.isLoaded;
    },
    tabs(): Tab[] {
      return this.$tstore.state.settings.tabs;
    },
    tab(): Tab | null {
      return this.$tstore.state.filter.floorplanTab ?? this.tabs.find(Boolean) ?? null;
    },
    tabItems(): TabItem[] {
      return this.tab?.tabItems ?? [];
    },
    reservations(): Reservation[] {
      const all = this.$tstore.getters.dayValidReservationsAndBlocks as Reservation[];
      console.log('reservations: ', all.filter((o) => o.tab === this.tab));
      return all.filter((o) => o.tab === this.tab);
    },
    reservationsByTime(): Map<string, Reservation[]> {
      const reservationsBySlot = toSortedMapBySlot(this.reservations);
      const reservationsByTime = mapBySlotToMapByTimeText(reservationsBySlot);
      return reservationsByTime;
    },
    ongoingReservationsForTabItem(): Map<number, Reservation[]> {
      const map = new Map<number, Array<Reservation>>();
      this.reservations.forEach((r) => {
        if (r.slotBegin > this.timeSlot || r.slotEnd < this.timeSlot) return; // not ongoing
        r.tabItems.forEach((ti) => {
          map.set(ti.id, (map.get(ti.id) ?? []).concat(r));
        });
      });
      return map;
    },
    upcomingReservationsForTabItem(): Map<number, Reservation[]> {
      const duration = durationForReservation([], this.tab ?? undefined);

      const map = new Map<number, Array<Reservation>>();
      this.reservations.forEach((r) => {
        if (r.slotBegin <= this.timeSlot) return; // past or ongoing
        if (minutesFromSlot(r.slotBegin - this.timeSlot) >= duration) return; // far enough

        r.tabItems.forEach((ti) => {
          map.set(ti.id, (map.get(ti.id) ?? []).concat(r));
        });
      });
      return map;
    },
    overbookedTabItemIds(): number[] {
      const ovebooked = new Set<number>();
      this.ongoingReservationsForTabItem.forEach((rs, tid) => { if (rs && rs.length > 1) ovebooked.add(tid); });
      return Array.from(ovebooked.values());
    },
    timeSlotText(): string {
      return niceTimeTextFromSlot(this.timeSlot);
    },
    timeSlotMin(): number {
      return MORNING_TIME_SLOTS_COUNT;
    },
    timeSlotMax(): number {
      return DAY_TIME_SLOTS_COUNT - 1;
    },
    compact(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },
    date(): Date {
      return this.$tstore.state.update.date;
    },
    isToday(): boolean {
      return this.$tstore.getters.isToday;
    },
  },
  watch: {
    timeTracking() {
      this.timeTrackerUpdate(this.timeTracking);
    },
    reservations: {
      deep: true,
      handler() {
        this.timeSlot = this.isToday ? this.nowSlot() : this.slotForFirstReservation();
      },
    },
    date() {
      console.log('watch: date update');
      this.timeTracking = this.$tstore.getters.isToday;
      this.timeSlot = this.isToday ? this.nowSlot() : this.slotForFirstReservation();
    },
    zoom() {
      storage.setFloorplanZoom(this.zoom);
    },
    editedReservationId() {
      // deselect table when reseditor has been closed
      if (this.editedReservationId === undefined) {
        this.selectedTabItemIds = [];
      }
    },
  },
  beforeDestroy() {
    this.timeTrackerUpdate(false);
  },
  mounted() {
    this.tab = this.tabs[0] ?? null;
    this.timeSlot = this.isToday ? this.nowSlot() : this.slotForFirstReservation();
    this.timeTrackerUpdate(this.timeTracking);
    this.canvasMaximized = this.compact;

    const zoom = storage.getFloorplanZoom();
    if (zoom) this.zoom = zoom;
  },
  methods: {
    async tabItemSelected(tabItemId?: number, reservations?: Reservation[]) {
      const reservation = reservations?.find(Boolean);
      this.selectedResId = reservation?.id;

      if (reservation) {
        this.selectedTabItemIds = reservation.tabItems.map((t) => t.id);
        if (this.$tstore.getters.editReservation?.id === reservation?.id) {
          await tryCloseEditReservation();
        } else {
          await tryEditReservation(this.$router, reservation);
        }
      } else if (tabItemId !== undefined) {
        const tabItems = this.tabItems.filter((t) => t.id === tabItemId);
        const partySize = tabItems.map((ti) => ti.seats).reduce((prev, next) => prev + next);
        const res = await tryEditReservation(this.$router, undefined, {
          tab: this.tab ?? undefined,
          tabItems,
          timeSlot: this.timeSlot,
          partySize,
        });
        if (res) {
          this.selectedTabItemIds = [tabItemId];
        }
      } else if (await tryCloseEditReservation()) {
        this.selectedTabItemIds = [];
      }
    },
    async reservationSelected(reservationId: number) {
      this.selectedResId = reservationId;
      const reservation = this.reservations.find((r) => r.id === reservationId);

      if (reservation === undefined || reservation.tabItems.length === 0) {
        this.selectedTabItemIds = [];
      } else {
        this.selectedTabItemIds = reservation.tabItems.map((t) => t.id);
        this.timeSlot = reservation.slotBegin;
        this.timeTracking = false;
      }

      await tryCloseEditReservation();
    },
    timeSlotChanged(timeSlot: number) {
      this.timeTracking = false;
      this.selectedResId = undefined;
      this.selectedTabItemIds = [];
    },
    tabChanged(tab: Tab) {
      this.$tstore.dispatch('applyFilter', { floorplanTab: tab });
    },
    setupFloorplan() {
      this.$router.push(`/settings/sections/${this.tab!.id}?floorplan=true`);
    },
    nowSlot() {
      const date = new Date();
      const time = minutesFromDate(date);
      const slot = slotFromMinutes(time);
      return clamp(slot, this.timeSlotMin, this.timeSlotMax);
    },
    timeTrackingClick() {
      this.timeTracking = !this.timeTracking;
      if (this.timeTracking && this.isToday) {
        this.timeSlot = this.nowSlot();
      }
    },
    timeTrackerUpdate(start: boolean) {
      if (start) {
        this.timeTrackingInterval = window.setInterval(() => {
          this.handleTimeTracker();
        }, 10000);
      } else {
        window.clearInterval(this.timeTrackingInterval);
      }
    },
    handleTimeTracker() {
      if (this.timeTracking && this.isToday) {
        this.timeSlot = this.nowSlot();
      }
    },
    slotForFirstReservation(): number {
      if (this.reservations.length === 0) {
        return MORNING_TIME_SLOTS_COUNT;
      }
      const sorted = toSortedMapBySlot(this.reservations);
      const firstSlot = sorted.keys().next().value;
      return firstSlot;
    },
  },
});

