
import Vue from 'vue';

export default Vue.extend({
  name: 'ValidatedTextField',
  props: {
    validationMode: { type: String, required: false, default: 'aggressive' },
    value: { type: String, required: true },
    rules: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: 'text' },
  },
  data() {
    return {
      val: this.value,
    };
  },
  computed: {

  },
  watch: {
    value(value: string) {
      this.val = value;
    },
    val() {
      this.$emit('input', this.val);
    },
  },
  methods: {
  },
});
