import activityIndicator from '@/plugins/activity-indicator-plugin/activity-indicator';
import { computed, getCurrentInstance, ref } from 'vue';

const activityCounter = ref(0);

export default function useActivityIndicator() {
  const showActivity = (on: boolean) => {
    if (on) { activityCounter.value += 1; } else if (!on && activityCounter.value > 0) activityCounter.value -= 1;
    console.log(`Activity Counter: ${activityCounter.value}`);
  };

  const overlay = computed(() => activityCounter.value > 0);

  activityIndicator.show = () => showActivity(true);
  activityIndicator.hide = () => showActivity(false);
  getCurrentInstance()?.proxy.$root.$on('activity-indicator', showActivity);

  return { overlay, showActivity };
}
