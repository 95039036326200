/**
 * @fileoverview gRPC-Web generated client stub for grpc.api.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.1
// source: giftcards_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var empty_pb = require('./empty_pb.js')

var giftcard_pb = require('./giftcard_pb.js')

var giftcard_product_pb = require('./giftcard_product_pb.js')

var giftcard_transaction_pb = require('./giftcard_transaction_pb.js')
const proto = {};
proto.grpc = {};
proto.grpc.api = {};
proto.grpc.api.v1 = require('./giftcards_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.GiftcardsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.EmptyRequest,
 *   !proto.grpc.api.v1.GiftcardsProductResponse>}
 */
const methodDescriptor_GiftcardsService_getProduct = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.GiftcardsService/getProduct',
  grpc.web.MethodType.UNARY,
  empty_pb.EmptyRequest,
  proto.grpc.api.v1.GiftcardsProductResponse,
  /**
   * @param {!proto.grpc.api.v1.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.GiftcardsProductResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.GiftcardsProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServiceClient.prototype.getProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/getProduct',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_getProduct,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.GiftcardsProductResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient.prototype.getProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/getProduct',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_getProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.GiftcardsProductRequets,
 *   !proto.grpc.api.v1.GiftcardsProductResponse>}
 */
const methodDescriptor_GiftcardsService_updateProduct = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.GiftcardsService/updateProduct',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.GiftcardsProductRequets,
  proto.grpc.api.v1.GiftcardsProductResponse,
  /**
   * @param {!proto.grpc.api.v1.GiftcardsProductRequets} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.GiftcardsProductResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.GiftcardsProductRequets} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.GiftcardsProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServiceClient.prototype.updateProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/updateProduct',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_updateProduct,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.GiftcardsProductRequets} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.GiftcardsProductResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient.prototype.updateProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/updateProduct',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_updateProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.GiftcardsListTransactionsRequest,
 *   !proto.grpc.api.v1.GiftcardsListTransactionsResponse>}
 */
const methodDescriptor_GiftcardsService_listTransactions = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.GiftcardsService/listTransactions',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.GiftcardsListTransactionsRequest,
  proto.grpc.api.v1.GiftcardsListTransactionsResponse,
  /**
   * @param {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.GiftcardsListTransactionsResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsListTransactionsResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServiceClient.prototype.listTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/listTransactions',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_listTransactions);
};


/**
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsListTransactionsResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient.prototype.listTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/listTransactions',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_listTransactions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.GiftcardsListGiftcardsRequest,
 *   !proto.grpc.api.v1.GiftcardsListGiftcardsResponse>}
 */
const methodDescriptor_GiftcardsService_listGiftcards = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.GiftcardsService/listGiftcards',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.GiftcardsListGiftcardsRequest,
  proto.grpc.api.v1.GiftcardsListGiftcardsResponse,
  /**
   * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.GiftcardsListGiftcardsResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsListGiftcardsResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServiceClient.prototype.listGiftcards =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/listGiftcards',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_listGiftcards);
};


/**
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsListGiftcardsResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient.prototype.listGiftcards =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/listGiftcards',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_listGiftcards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.GiftcardsGetGiftcardRequest,
 *   !proto.grpc.api.v1.GiftcardsGiftcardResponse>}
 */
const methodDescriptor_GiftcardsService_getGiftcard = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.GiftcardsService/getGiftcard',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.GiftcardsGetGiftcardRequest,
  proto.grpc.api.v1.GiftcardsGiftcardResponse,
  /**
   * @param {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.GiftcardsGiftcardResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.GiftcardsGiftcardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsGiftcardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServiceClient.prototype.getGiftcard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/getGiftcard',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_getGiftcard,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.GiftcardsGiftcardResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient.prototype.getGiftcard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/getGiftcard',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_getGiftcard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest,
 *   !proto.grpc.api.v1.GiftcardsGiftcardResponse>}
 */
const methodDescriptor_GiftcardsService_redeemGiftcard = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.GiftcardsService/redeemGiftcard',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest,
  proto.grpc.api.v1.GiftcardsGiftcardResponse,
  /**
   * @param {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.GiftcardsGiftcardResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.GiftcardsGiftcardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.GiftcardsGiftcardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.GiftcardsServiceClient.prototype.redeemGiftcard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/redeemGiftcard',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_redeemGiftcard,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.GiftcardsGiftcardResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.GiftcardsServicePromiseClient.prototype.redeemGiftcard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.GiftcardsService/redeemGiftcard',
      request,
      metadata || {},
      methodDescriptor_GiftcardsService_redeemGiftcard);
};


module.exports = proto.grpc.api.v1;

