
import Vue from 'vue';
import Reservation, { ReservationSource, ReservationType } from '@/model/Reservation';
import Tab from '@/model/Tab';
import TabItem from '@/model/TabItem';
import ContactMessageDialog from '@/components/views/reservation/ContactMessageDialog.vue';
import ReservationHistoryDialog from '@/components/views/reservation/ReservationHistoryDialog.vue';
import EmployeeDialog from '@/components/views/reservation/EmployeeDialog.vue';
import ContactDetailsDialog from '@/components/views/reservation/ContactDetailsDialog.vue';
import FileUploadDialog from '@/components/views/reservation/FileUploadDialog.vue';
import ReservationResultsDialog from '@/components/views/reservation/ReservationResultsDialog.vue';
import ContactAutocomplete from '@/components/views/reservation/ContactAutocomplete.vue';
import PartySizePicker from '@/components/pickers/PartySizePicker.vue';
import TabItemMenu from '@/components/views/reservation/TabItemMenu.vue';
import TagNoteMenu from '@/components/views/reservation/TagNoteMenu.vue';
import TagNote from '@/model/TagNote';
import { mapActions } from 'vuex';
import { performDispatchAction, performSaveAction } from '@/services/vue-utils';
import unsavedGuard from '@/mixins/unsaved-guard';
import Contact from '@/model/Contact';
import { tryCloseEditReservation } from '@/services/reservation-edit-utils';
import {
  ReservationError, reservationErrorTexts, isReservationErrors, Warnings, ReservationErrorType,
  DateBeginErrors, DateEndErrors, TabErrors, TabItemsErrors, DateErrors,
  NameErrors, PhoneErrors, PartySizeErrors, EmailErrors,
} from '@/services/reservation-validation';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import Service from '@/model/Service';
import { isDateSameIgnoringTime, isDateToday, timeStringFromDate } from '@/services/time-utils';
import Attachment from '@/model/Attachment';
import { History } from '@/services/reservation-history';
import showConfirmationDialog from '@/components/dialogs/confirmation-dialog';
import Label from '@/model/Label';
import ReservationStatusSelect from './ReservationStatusSelect.vue';
import ReservationItemSelect from './ReservationItemSelect.vue';
import ReservationChips from '../guests/ReservationChips.vue';

enum SubmitBookingType {
  none,
  save,
  cancel,
  duplicate,
}

export default Vue.extend({
  name: 'Reservation',
  components: {
    ReservationChips,
    EmployeeDialog,
    PartySizePicker,
    TabItemMenu,
    ContactDetailsDialog,
    TagNoteMenu,
    ConfirmationDialog,
    ContactAutocomplete,
    FileUploadDialog,
    ReservationResultsDialog,
    ReservationHistoryDialog,
    ReservationStatusSelect,
    ReservationItemSelect,
    ContactMessageDialog,
  },
  mixins: [unsavedGuard],
  props: {
    isVisible: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      contactMessageDialog: false,
      employeeDialogVisible: false,
      contactDialogVisible: false,
      warningsDialogVisible: false,
      cancelBookingConfirmationVisible: false,
      clearContactConfirmationVisible: false,
      duplicateBookingDialogVisible: false,
      duplicateBookingDate: new Date(),
      confirmationMessage: '',

      submitBookingType: SubmitBookingType.none,
      attachmentsPanels: undefined as number | undefined,
      fileUploadDialog: false,

      history: null as History | null,
      showHistoryDialogVisible: false,

      contactWithReservations: null as Contact | null,
      contactReservations: null as Reservation[] | null,
      reservationResultsDialog: false,

      extraErrorMessages: new Map<string, string[]>(),

      reservationTypes: [
        { text: this.$i18n.tc('code.booking_type.reservation'), value: ReservationType.Reservation },
        { text: this.$i18n.tc('code.booking_type.walkin'), value: ReservationType.Walkin },
        { text: this.$i18n.tc('code.booking_type.waitlist'), value: ReservationType.Waitlist },
        { text: this.$i18n.tc('code.booking_type.block'), value: ReservationType.Block },
      ],
    };
  },
  computed: {
    r(): Reservation {
      return this.$tstore.getters.editReservation ?? Reservation.emptyReservation();
    },
    oldr(): Reservation {
      return this.$tstore.getters.originalReservation ?? Reservation.emptyReservation();
    },
    isChanged(): boolean {
      return this.$tstore.getters.isChangedEditReservation || this.isSuggestedTabItems;
    },
    isNew(): boolean {
      return this.r.isNew;
    },
    isUnread(): boolean {
      return this.r.isUnread;
    },
    isErrors(): boolean {
      return isReservationErrors(this.validationErrors);
    },
    tabs(): Tab[] { return this.$tstore.state.settings.tabs; },
    suggestedTabItems(): TabItem[] | null {
      return this.$tstore.getters.suggestedTabItems;
    },
    isSuggestedTabItems(): boolean {
      return this.suggestedTabItems !== null && this.suggestedTabItems.length > 0;
    },
    isSuggestingTabItems(): boolean {
      return this.$tstore.getters.isSuggestingTabItems;
    },
    tabItemsText(): string {
      if (this.r.tabItems.length !== 0) {
        return this.r.tabItems.map((t) => t.no).join(', ');
      }
      if (this.suggestedTabItems !== null && this.suggestedTabItems.length > 0) {
        return this.suggestedTabItems.map((t) => t.no).join(', ');
      }
      if (!this.tableAllocationDisabled && !this.r.isBlock) return this.$i18n.tc('label.automatic');
      return '';
    },
    timeBegin(): string {
      return timeStringFromDate(this.r.dateBegin) ?? '';
    },
    timeEnd(): string {
      return timeStringFromDate(this.r.dateEnd) ?? '';
    },
    employeeNameIsNeeded(): boolean {
      return this.$tstore.getters.employeeNameIsNeeded;
    },
    tableAllocationDisabled(): boolean {
      return !(this.$tstore.state.settings.accountSettings.tableAllocation ?? false);
    },
    dateCreated(): string | null {
      if (!this.r.dateCreated) return null;
      return this.$localized.smartDateText(this.r.dateCreated);
    },
    dateEdited(): string | null {
      if (!this.r.dateEdited) return null;
      return this.$localized.smartDateText(this.r.dateEdited);
    },
    employeeCreated(): string | null {
      if (this.r.reservationType === ReservationSource.Online) {
        return `(${this.r.reservationCampaign || this.$i18n.tc('code.reservation_source.online')})`;
      }
      if (!this.r.employeeCreated) return null;
      return `(${this.r.employeeCreated?.name})`;
    },
    employeeEdited(): string | null {
      if (!this.r.employeeEdited) return null;
      return `(${this.r.employeeEdited?.name})`;
    },
    validationErrors(): ReservationError[] {
      const errors: ReservationError[] = [];
      errors.push(...this.$tstore.getters.reservationValidationErrors);
      errors.push(...this.$tstore.getters.reservationAvailabilityValidationErrors);
      if (this.overbookedTabItems.length > 0) {
        errors.push({ type: ReservationErrorType.TabItemsOverbooked });
      }
      if (this.suggestedTabItems !== null && this.suggestedTabItems.length === 0) {
        errors.push({ type: ReservationErrorType.AvailabilityNoAllocation });
      }
      if (this.tableAllocationDisabled && this.r.tabItems.length === 0) {
        errors.push({ type: ReservationErrorType.TabItemsMissing });
      }
      return errors;
    },
    overbookedTabItems(): TabItem[] {
      return this.$tstore.getters.reservationOverbookedTabItems;
    },
    unsavedChangesDialogVisible(): boolean {
      return this.$tstore.getters.isConfirmCloseEditReservation;
    },
    contactMessageDialogEnabled(): boolean {
      return this.$tstore.getters.isReservationMessageAllowed;
    },
    services(): Service[] {
      return this.$tstore.state.settings.services;
    },
    labels(): Label[] {
      const { labels } = this.$tstore.state.settings;
      const oldl = this.oldr.label;
      if (!this.oldr.label) return labels;
      return labels.find((l) => l.id === oldl?.id) ? labels : [oldl!, ...labels];
    },
    reservationTypeItems() {
      if (this.r.bookingType === ReservationType.Walkin) { return this.reservationTypes; }
      return this.reservationTypes.filter((ri) => !(ri.value === ReservationType.Walkin
      && !isDateSameIgnoringTime(new Date(), this.$tstore.state.update.date)));
    },
    storeDate() {
      return this.$tstore.state.update.date;
    },
  },
  watch: {
    storeDate() {
      if (this.r.bookingType === ReservationType.Walkin
       && !isDateSameIgnoringTime(new Date(), this.storeDate)) {
        this.r.bookingType = ReservationType.Reservation;
        this.reservationTypeChanged(ReservationType.Reservation);
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown);
  },
  beforeDestroy() {
    window.addEventListener('keydown', this.keyDown);
  },
  methods: {
    ...mapActions([
      'sendEditReservation', 'confirmCloseEditReservation', 'closeEditReservation']),
    keyDown(evt: KeyboardEvent) {
      if (!this.isVisible || evt.target !== document.body) { return; }
      switch (evt.keyCode) {
        case 13:
          this.saveBooking();
          break;
        case 27:
          this.close();
          break;
        default:
          break;
      }
    },
    dateChanged(date: Date) {
      this.$tstore.dispatch('setReservationDate', date);
      this.$tstore.dispatch('keepReservationChangeToDate', date);
    },
    timeBeginChanged(time: string) {
      this.$tstore.dispatch('setReservationTimeBegin', time);
    },
    timeEndChanged(time: string) {
      this.$tstore.dispatch('setReservationTimeEnd', time);
    },
    tabChanged(tab: Tab) {
      this.$tstore.dispatch('setReservationTab', tab);
    },
    partySizeChanged(partySize: number) {
      this.$tstore.dispatch('setReservationPartySize', partySize);
    },
    reservationTypeChanged(reservationType: ReservationType) {
      this.$tstore.dispatch('setReservationType', reservationType);
    },
    clearContact() {
      this.$tstore.dispatch('setReservationContact', new Contact());
      this.clearContactConfirmationVisible = false;
    },
    errorMessages(key: string, warning: boolean = false) {
      const errors = this.validationErrors.filter((e) => (
        warning ? Warnings.includes(e.type) : !Warnings.includes(e.type)));

      let messages: string[] = [];

      switch (key) {
        case 'partySize': messages = reservationErrorTexts(PartySizeErrors, errors); break; // not needed
        case 'date': messages = reservationErrorTexts(DateErrors, errors); break;// not needed
        case 'tab': messages = reservationErrorTexts(TabErrors, errors); break;
        case 'tabItems': messages = reservationErrorTexts(TabItemsErrors, errors); break;
        case 'timeBegin': messages = reservationErrorTexts(DateBeginErrors, errors); break;
        case 'timeEnd': messages = reservationErrorTexts(DateEndErrors, errors); break;
        case 'name': messages = reservationErrorTexts(NameErrors, errors); break; // not needed
        case 'phone': messages = reservationErrorTexts(PhoneErrors, errors); break; // not needed
        case 'email': messages = reservationErrorTexts(EmailErrors, errors); break; // not needed
        default:
      }

      const extraMessages = this.extraErrorMessages.get(key) ?? [];
      return [...extraMessages, ...messages];
    },
    async cancelBooking() {
      this.submitBookingType = SubmitBookingType.cancel;
      this.cancelBookingConfirmationVisible = true;
    },
    async duplicateBooking() {
      this.submitBookingType = SubmitBookingType.duplicate;
      this.duplicateBookingDate = this.r.dateBegin;
      this.duplicateBookingDialogVisible = true;
    },
    async saveBooking() {
      this.submitBookingType = SubmitBookingType.save;
      this.submitSaveOrDuplicate();
    },
    async submitSaveOrDuplicate() {
      this.duplicateBookingDialogVisible = false;

      if (isReservationErrors(this.validationErrors)) {
        return;
      }

      // show confirmation for warnings
      const warninigs = reservationErrorTexts(Warnings, this.validationErrors);
      if (warninigs.length) {
        this.confirmationMessage = warninigs.join('\n');
        this.warningsDialogVisible = true;
        return;
      }

      this.submit();
    },
    async submit() {
      this.cancelBookingConfirmationVisible = false;
      this.warningsDialogVisible = false;

      // show employee dialog
      if (!this.employeeDialogVisible && this.employeeNameIsNeeded) {
        this.employeeDialogVisible = true;
        return;
      }
      this.employeeDialogVisible = false;

      // save
      let ok = false;
      const p = {
        cancel: false,
        duplicate: false,
        dateBegin: this.duplicateBookingDate,
        suggestedTabItems: this.suggestedTabItems,
      };

      if (this.submitBookingType === SubmitBookingType.cancel) p.cancel = true;
      if (this.submitBookingType === SubmitBookingType.duplicate) p.duplicate = true;

      ok = await performSaveAction(
        this.$refs.observer,
        async () => this.sendEditReservation(p),
      );
      if (ok) this.closeEditReservation();
      else {
        // for duplicate booking
        this.$tstore.dispatch('keepReservationChangeToDate', this.r.dateBegin);
      }
    },
    async close() {
      await tryCloseEditReservation();
    },
    discardChanges() {
      this.confirmCloseEditReservation(true);
    },
    dismissChanges() {
      this.confirmCloseEditReservation(false);
    },
    addTagNote(tag: TagNote) {
      const fieldVue = this.$refs.note as Vue;
      const field = fieldVue.$refs.input as HTMLTextAreaElement;

      const tagValue = ` ${tag.dotText} `;

      // FIXME: Check this on IE Browser
      if (field.selectionStart || field.selectionStart === 0) {
        const startPos = field.selectionStart;
        const endPos = field.selectionEnd;
        const text = this.r.notes || '';

        this.r.notes = text.substring(0, startPos)
        + tagValue
        + text.substring(endPos, text.length);
      }
    },
    formatReservationDate(d: Date): string | null {
      return this.$localized.dayMonthText(d);
    },
    duplicateBookingDateChanged(date: Date) {
      this.duplicateBookingDate = date;
      this.$tstore.dispatch('keepReservationChangeToDate', date);
    },
    duplicateBookingCanceled() {
      this.$tstore.dispatch('keepReservationChangeToDate', this.r.dateBegin);
    },
    deleteAttachment(index: number) {
      showConfirmationDialog(
        this.$i18n.tc('label.attachments'),
        this.$i18n.tc('message.confirm_delete_attachment'),
        {
          title: this.$i18n.tc('button.yes'),
          action: () => {
            const attachments = [...this.r.attachments];
            attachments.splice(index, 1);
            this.$tstore.dispatch('setReservationAttachments', attachments);
          },
        },
        { title: this.$i18n.tc('button.cancel') },
      );
    },
    attachmentsUploaded(uploads: { name: string, url: string }[]) {
      console.log('attachmentsUploaded: ', uploads);

      const attachments = [...this.r.attachments];

      uploads.forEach((f) => {
        const a = new Attachment();
        a.name = f.name;
        a.url = f.url;
        attachments.push(a);
      });
      this.$tstore.dispatch('setReservationAttachments', attachments);

      this.attachmentsPanels = 0;
    },
    async showContactReservations() {
      const reservations = await performDispatchAction<Reservation[]>('getContactReservations', undefined);
      if (!reservations) return;
      this.contactWithReservations = this.r.contact;
      this.contactReservations = reservations;
      this.reservationResultsDialog = true;
    },
    async showHistory() {
      const history = await performDispatchAction<History>('getReservationHistory', undefined);
      if (!history) return;
      this.history = history;
      this.showHistoryDialogVisible = true;
    },

    // Autocomplete
    updateName(val: string) {
      this.r.contact.name = val;
    },
    updateEmail(val: string) {
      this.r.contact.email = val;
    },
    updatePhone(val: string) {
      this.r.contact.phone = val;
    },
    updateContact(contact: Contact) {
      this.$tstore.dispatch('setReservationContact', contact);
    },
    clearContactClicked() {
      if (this.isNew) {
        this.clearContact();
      } else {
        this.clearContactConfirmationVisible = true;
      }
    },
  },
});
