/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

import {
  dateByAddingDays, dateByAddingMonths, dateByAddingYears, dateFlooredToDate,
  dateFlooredToMonth, dateFlooredToWeek, dateFlooredToYear, dateWithYear,
} from '../time-utils';
import { FieldOperation, FilterOperandType } from './filter-rule-operation';

export enum FilterDateOperand {
  Today = '@@tday@@',
  Tomorrow = '@@nday@@',
  Yesterday = '@@pday@@',
  ThisWeek = '@@tweek@@',
  NextWeek = '@@nweek@@',
  PreviousWeek = '@@pweek@@',
  ThisMonth = '@@tmonth@@',
  NextMonth = '@@nmonth@@',
  PreviousMonth = '@@pmonth@@',
  ThisYear = '@@tyear@@',
  NextYear = '@@nyear@@',
  PreviousYear = '@@pyear@@',

  Separator = '@@sep@@',
}

export const FilterDateOperands = [
  FilterDateOperand.Today,
  FilterDateOperand.Tomorrow,
  FilterDateOperand.Yesterday,
  FilterDateOperand.ThisWeek,
  FilterDateOperand.NextWeek,
  FilterDateOperand.PreviousWeek,
  FilterDateOperand.ThisMonth,
  FilterDateOperand.NextMonth,
  FilterDateOperand.PreviousMonth,
  FilterDateOperand.ThisYear,
  FilterDateOperand.NextYear,
  FilterDateOperand.PreviousYear,
];
export const FilterDateNoYearOperands = [
  FilterDateOperand.Today,
  FilterDateOperand.Tomorrow,
  FilterDateOperand.Yesterday,
  FilterDateOperand.ThisWeek,
  FilterDateOperand.NextWeek,
  FilterDateOperand.PreviousWeek,
  FilterDateOperand.ThisMonth,
  FilterDateOperand.NextMonth,
  FilterDateOperand.PreviousMonth,
];
export const FilterDateOperandsWithSeparators = [
  FilterDateOperand.Today,
  FilterDateOperand.Tomorrow,
  FilterDateOperand.Yesterday,
  FilterDateOperand.Separator,
  FilterDateOperand.ThisWeek,
  FilterDateOperand.NextWeek,
  FilterDateOperand.PreviousWeek,
  FilterDateOperand.Separator,
  FilterDateOperand.ThisMonth,
  FilterDateOperand.NextMonth,
  FilterDateOperand.PreviousMonth,
  FilterDateOperand.Separator,
  FilterDateOperand.ThisYear,
  FilterDateOperand.NextYear,
  FilterDateOperand.PreviousYear,
];
export const FilterDateNoYearOperandsWithSeparators = [
  FilterDateOperand.Today,
  FilterDateOperand.Tomorrow,
  FilterDateOperand.Yesterday,
  FilterDateOperand.Separator,
  FilterDateOperand.ThisWeek,
  FilterDateOperand.NextWeek,
  FilterDateOperand.PreviousWeek,
  FilterDateOperand.Separator,
  FilterDateOperand.ThisMonth,
  FilterDateOperand.NextMonth,
  FilterDateOperand.PreviousMonth,
];

export abstract class FieldDateOperation extends FieldOperation {
  now: Date;

  begin: Date;

  end: Date;

  beginTime: number;

  endTime: number;

  constructor(operand: FilterDateOperand, now: Date, firstDayOfWeek: number) {
    super(operand);

    this.now = now;
    this.begin = new Date();
    this.end = new Date();

    switch (operand) {
      case FilterDateOperand.Today:
        this.begin = dateFlooredToDate(now); this.end = dateByAddingDays(this.begin, 1); break;
      case FilterDateOperand.Yesterday:
        this.begin = dateByAddingDays(dateFlooredToDate(now), -1); this.end = dateByAddingDays(this.begin, 1); break;
      case FilterDateOperand.Tomorrow:
        this.begin = dateByAddingDays(dateFlooredToDate(now), 1); this.end = dateByAddingDays(this.begin, 1); break;

      case FilterDateOperand.ThisWeek:
        this.begin = dateFlooredToWeek(now, firstDayOfWeek);
        this.end = dateByAddingDays(this.begin, 7);
        break;
      case FilterDateOperand.PreviousWeek:
        this.begin = dateByAddingDays(dateFlooredToWeek(now, firstDayOfWeek), -7);
        this.end = dateByAddingDays(this.begin, 7);
        break;
      case FilterDateOperand.NextWeek:
        this.begin = dateByAddingDays(dateFlooredToWeek(now, firstDayOfWeek), -7);
        this.end = dateByAddingDays(this.begin, 7);
        break;

      case FilterDateOperand.ThisMonth:
        this.begin = dateFlooredToMonth(now);
        this.end = dateByAddingMonths(this.begin, 1);
        break;
      case FilterDateOperand.PreviousMonth:
        this.begin = dateByAddingMonths(dateFlooredToMonth(now), -1);
        this.end = dateByAddingMonths(this.begin, 1);
        break;
      case FilterDateOperand.NextMonth:
        this.begin = dateByAddingMonths(dateFlooredToMonth(now), 1);
        this.end = dateByAddingMonths(this.begin, 1);
        break;

      case FilterDateOperand.ThisYear:
        this.begin = dateFlooredToYear(now);
        this.end = dateByAddingYears(this.begin, 1);
        break;
      case FilterDateOperand.PreviousYear:
        this.begin = dateByAddingYears(dateFlooredToYear(now), -1);
        this.end = dateByAddingYears(this.begin, 1);
        break;
      case FilterDateOperand.NextYear:
        this.begin = dateByAddingYears(dateFlooredToYear(now), 1);
        this.end = dateByAddingYears(this.begin, 1);
        break;

      default: break;
    }

    this.beginTime = this.begin.getTime();
    this.endTime = this.end.getTime();
  }

  static isDateOperand(operand: FilterOperandType) {
    return typeof operand === 'string' && FilterDateOperands.includes(operand as FilterDateOperand);
  }
}

export class EqualDateFieldOperation extends FieldDateOperation {
  check(value: FilterOperandType | undefined) {
    if (value === undefined || value === null) return false;
    const time = (value as Date).getTime();
    if (!time) return false;

    return this.neg !== (time >= this.beginTime && time < this.endTime);
  }
}

export class EqualDateNoYearFieldOperation extends FieldDateOperation {
  check(value: FilterOperandType | undefined) {
    if (value === undefined || value === null) return false;

    const time = dateWithYear(value as Date, this.now.getFullYear()).getTime();
    if (!time) return false;

    return this.neg !== (time >= this.beginTime && time < this.endTime);
  }
}
