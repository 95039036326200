import toast from '@/plugins/toast-plugin/toast';
import { getCurrentInstance, ref } from 'vue';
import { TranslateResult } from 'vue-i18n';

export default function useToastInit() {
  // snackbar
  const snackbar = ref(false);
  const snackbarText = ref('' as string | TranslateResult);
  const snackbarIcon = ref('');
  const snackbarColor = ref('');
  const snackbarTextColor = ref('');
  const snackbarTimeout = ref(2000);

  const showSuccess = (messge: string | TranslateResult) => {
    snackbarColor.value = 'success';
    snackbarText.value = messge;
    snackbarTimeout.value = 2000;
    snackbarIcon.value = 'mdi-check';
    snackbarTextColor.value = 'white';
    snackbar.value = true;
  };

  const showError = (messge: string | TranslateResult) => {
    snackbarColor.value = 'error';
    snackbarText.value = messge;
    snackbarTimeout.value = 5000;
    snackbarIcon.value = 'mdi-alert';
    snackbarTextColor.value = 'white';
    snackbar.value = true;
  };

  const showSuccessDelayed = (messge: string | TranslateResult) => {
    snackbarColor.value = 'warning';
    snackbarText.value = messge;
    snackbarTimeout.value = 5000;
    snackbarIcon.value = 'mdi-clock-time-three-outline';
    snackbarTextColor.value = 'black';
    snackbar.value = true;
  };

  toast.success = showSuccess;
  toast.error = showError;
  toast.successDelayed = showSuccessDelayed;

  getCurrentInstance()?.proxy.$root.$on('alert-success', showSuccess);
  getCurrentInstance()?.proxy.$root.$on('alert-error', showError);
  getCurrentInstance()?.proxy.$root.$on('alert-delayed', showSuccessDelayed);

  return {
    snackbar, snackbarText, snackbarIcon, snackbarColor, snackbarTextColor, snackbarTimeout,
  };
}
