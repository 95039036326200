/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class ExportApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.export', {
      categories: [GstplnAppCategory.Extensions],
      hasMenuItem: true,
      routeConfig:
      [{ route: '', f: () => import('@/components/apps/export/Export.vue') }],
    });
  }
}
