
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import Sortable from 'sortablejs';
import Tab from '@/model/Tab';
import TabItem from '@/model/TabItem';
import { mapActions } from 'vuex';
import Allocation from '@/model/Allocation';
import { cloneModel, isModelEqualToModel, uniqueMinId } from '@/model/model-utils';
import { performSaveAction, confirmLeaveWithoutSaving } from '@/services/vue-utils';
import { AllocationsDialogMode } from './TabAllocationsDialog.vue';

export default Vue.extend({
  name: 'GuestsAllocationsDialog',
  components: { },
  mixins: [DialogClosable],
  props: {
    partySize: { type: Number, required: true },
    mode: { type: Number as PropType<AllocationsDialogMode>, required: true },
  },
  data() {
    return {
      deleteDialogVisible: false,
      headers: [
        {
          text: '',
          width: '32',
          value: 'id',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.tables'),
          value: 'allocatedTabItems',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.seats'),
          value: 'totalSeats',
          align: 'end',
          sortable: false,
        },
        {
          text: '',
          width: '32',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      allocations: [] as Allocation[],
      oldAllocations: [] as Allocation[],
      selectedItemId: 0,
      forbid: false,
    };
  },
  computed: {
    allowReorder(): boolean {
      return this.mode !== AllocationsDialogMode.DisallowedAllocations;
    },
    allowForbid(): boolean {
      return this.mode !== AllocationsDialogMode.DisallowedAllocations;
    },
    allocationsByPartySize(): Map<number, Allocation[]> {
      switch (this.mode) {
        case AllocationsDialogMode.AllowedAllocations:
          return this.$tstore.getters.allowedAllocationsByPartySize;
        case AllocationsDialogMode.DisallowedAllocations:
          return this.$tstore.getters.disallowedAllocationsByPartySize;
        default: return new Map<number, Allocation[]>();
      }
    },
  },
  watch: {
    visible(value: boolean) {
      if (!value) return;
      this.reset();
    },
  },
  methods: {
    ...mapActions(['sendAllocations']),
    reset() {
      this.oldAllocations = this.allocationsByPartySize.get(this.partySize) ?? [];
      this.allocations = this.oldAllocations.map((o: Allocation) => cloneModel(o));
    },
    async changeOrder(event: Sortable.SortableEvent) {
      if (event.oldIndex === undefined || event.newIndex === undefined) return;
      const movedItem = this.allocations.splice(event.oldIndex, 1)[0];
      this.allocations.splice(event.newIndex, 0, movedItem);
      await this.save(this.allocations);
      this.reset();
    },
    showDeleteDialog(id: number) {
      this.selectedItemId = id;
      this.forbid = false;
      this.deleteDialogVisible = true;
    },
    async remove() {
      let allocations: Allocation[] = [];

      if (this.forbid) {
        allocations = this.allocations.map((o) => {
          if (o.id !== this.selectedItemId) return o;
          const no = cloneModel(o);
          no.isForbidden = true;
          return no;
        });
      } else {
        allocations = this.allocations.filter((o) => o.id !== this.selectedItemId);
      }

      const ok = await this.save(allocations);
      if (!ok) return;

      this.deleteDialogVisible = false;
      this.reset();
    },
    async save(allocations: Allocation[]) {
      const isForbidden = this.mode === AllocationsDialogMode.DisallowedAllocations;
      return performSaveAction(
        this.$refs.observer,
        async () => this.sendAllocations({ entities: allocations, partySize: this.partySize, isForbidden }),
      );
    },
    close() {
      this.$emit('close');
    },
  },
});
