import { ILabel } from '@/api/api-update';

export interface IDTOLabel extends ILabel { }

export default class Label {
  id: number;

  text: string;

  order: number;

  color: string;

  get value() { return this.id; }

  constructor(o?: ILabel) {
    this.id = o?.labelId ?? 0;
    this.text = o?.labelText ?? '';
    this.order = o?.labelOrder ?? 0;
    this.color = o?.labelColor?.toUpperCase() ?? '#FFC24E';
  }

  toDto(): IDTOLabel {
    const dto: IDTOLabel = {
      labelId: this.id,
      labelText: this.text,
      labelOrder: this.order,
      labelColor: this.color,
    };
    return dto;
  }
}
