
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'NumericListCard',
  components: { },
  props: {
    title: { type: String, required: true },
    count: { type: String, required: true },
    listTitles: { type: Object as PropType<Array<string>>, required: true },
    listValues: {
      type: Object as PropType<Array<{text: string, guests: number, reservations: number, color?:string}>>,
      required: true,
    },
    noValuesLabel: { type: String, required: false, default: '' },
    showDetails: { type: Boolean, required: false, default: false },
  },
  data() {
    return { };
  },
  computed: { },
  mounted() { },
  methods: { },
});

