

import Vue from 'vue';
import MainToolbar from '@/components/views/main/MainToolbar.vue';
import ContactsFilterDialog from '@/components/views/contacts/ContactsFilterDialog.vue';
import ContactsMergeDialog from '@/components/views/contacts/ContactsMergeDialog.vue';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import { actionShowError, performAction, performDispatchAction } from '@/services/vue-utils';
import GuestbookStore from '@/store/guestbook-store';
import Contact from '@/grpc-api/model/contact';
import ContactFilter from '@/grpc-api/model/contact-filter';
import { cloneModel, toMapById } from '@/model/model-utils';
import ContactDetailsDialog from '@/components/views/reservation/ContactDetailsDialog.vue';
import ReservationResultsDialog from '@/components/views/reservation/ReservationResultsDialog.vue';
import { tryEditReservation } from '@/services/reservation-edit-utils';
import Reservation from '@/model/Reservation';
import EmployeeDialog from '@/components/views/reservation/EmployeeDialog.vue';
import { dialCodeByCountry } from '@/model/Country';
import { ApiErrorCode } from '@/api/api-error';
import showConfirmationDialog from '../dialogs/confirmation-dialog';

export default Vue.extend({
  name: 'Contacts',
  components: {
    MainToolbar,
    EmployeeDialog,
    ContactsFilterDialog,
    ContactDetailsDialog,
    ContactsMergeDialog,
    ReservationResultsDialog,
  },
  mixins: [],
  data() {
    return {
      // unableToDeleteContactsWarning: false,
      editContactFilter: null as ContactFilter | null,
      employeeDialogVisible: false,
      employeeDialogSubmitAction: undefined as (() => void) | undefined,
      contactDialogVisible: false,
      mergeDialogVisible: false,
      filterDialogVisible: false,
      reservationsByContactDialog: false,
      selectedContacts: [] as Contact[],
      editedContact: null as Contact | null,
      contactReservations: [] as Reservation[],
      headers: [
        { text: this.$i18n.t('label.name'), align: 'start', value: 'name' },
        { text: this.$i18n.t('label.company'), value: 'companyName' },
        { text: this.$i18n.t('label.email'), value: 'email' },
        { text: this.$i18n.t('label.phone_number'), value: 'phone' },
        { text: this.$i18n.t('label.last_visit'), value: 'lastVisit', filterable: false },
        { text: this.$i18n.t('label.visits'), value: 'visitCount', filterable: false },
        {
          text: '', value: 'actions', width: 80, filterable: false,
        },
      ],
      filterFieldExpanded: false,
      markDuplicities: false,
      sortBy: 'visitCount',
      sortDesc: true,
      colors: [
        '#9e9e9e22',
        '#3355ff22',
        '#E42DD022',
        '#FF3D9422',
        '#FF826222',
        '#FFC24E22',
        '#F9F87122',
        '#88883E22',
        '#00A26F22',
        '#E9002122',
        '#61FBD522',
        '#9D5B0022',
        '#312D4A22',
        '#1A5A5F22',
        '#1F919922',
        '#A92DC822',
        '#6A000F22',
        '#D1C7FF22',
        '#0011A322',
        '#F3738522',
        '#1C1A2722',
        '#938BB722',
      ],
    };
  },
  computed: {
    contacts(): Contact[] {
      // return this.$tstore.getters.guestbookFilteredContacts;
      return this.$tstore.getters.guestbookVisibleContacts;
    },
    contactFilters(): (ContactFilter | { divider: true })[] {
      return [
        ...ContactFilter.DefaultFilters,
        { divider: true },
        ...this.$tstore.state.guestbook.contactFilters,
      ];
    },
    customContactFilters(): ContactFilter[] {
      return this.$tstore.state.guestbook.contactFilters;
    },
    contactFilter(): ContactFilter {
      return this.$tstore.state.guestbook?.contactFilter ?? null;
    },
    editFilterDisabled(): boolean {
      return ContactFilter.DefaultFilters.some((cf) => cf.id === this.contactFilter.id);
    },
    isDefaultFilter(): boolean {
      return this.contactFilter === ContactFilter.DefaultFilter;
    },
    colorsForEmail(): Map<string, string> {
      const mails = this.$tstore.getters.guestbookDuplicatedMails as string[];
      const colors = new Map<string, string>();
      mails.forEach((k, i) => colors.set(k, this.colors[i % this.colors.length]));
      return colors;
    },
    colorsForPhone(): Map<string, string> {
      const phones = this.$tstore.getters.guestbookDuplicatedPhones as string[];
      const colors = new Map<string, string>();
      phones.forEach((k, i) => colors.set(k, this.colors[i % this.colors.length]));
      return colors;
    },
    contactEmailColors(): Map<number, string> {
      const colors = new Map<number, string>();
      this.contacts.forEach((c) => { colors.set(c.id, this.colorsForEmail.get(c.normalizedEmail()) ?? '.'); });
      return colors;
    },
    contactPhoneColors(): Map<number, string> {
      const countryCode = this.$tstore.state.settings.account.countryCode ?? '';
      const dialCode = dialCodeByCountry.get(countryCode) ?? '';
      const colors = new Map<number, string>();
      this.contacts.forEach((c) => { colors.set(c.id, this.colorsForPhone.get(c.normalizedPhone(dialCode)) ?? '.'); });
      return colors;
    },
    itemCount(): number {
      const itemCount = this.$tstore.getters.guestbookVisibleCount as number;
      return itemCount;
    },
    searchTerm(): string {
      return this.$tstore.state.guestbook.fullTextFilterQuery;
    },
    isReservationsAllowed(): boolean {
      return this.$tstore.getters.isReservationsAllowed;
    },
    isSettingsAllowed(): boolean {
      return this.$tstore.getters.isSettingsAllowed;
    },
  },
  watch: {
    contactFilter() {
      this.selectedContacts = [];
    },
  },
  created() {
    loadStoreModule(this, 'guestbook', GuestbookStore, async () => { this.load(); });
  },
  async beforeDestroy() {
    unloadStoreModule(this, 'guestbook', async () => { this.$tstore.dispatch('resetGuestbook'); });

    await performAction(
      null,
      this.$i18n.tc('error.load_data'),
      async () => this.$tstore.dispatch('update'),
    );
  },
  methods: {
    async load() {
      if (this.$tstore.getters.isGuestbookLoaded) return;
      await performDispatchAction('loadGuestbook', {}, { streamLoad: true });
    },
    editContact(contact: Contact) {
      this.editedContact = contact;
      this.contactDialogVisible = true;
    },
    createContact(contact: Contact) {
      this.editedContact = new Contact();
      this.contactDialogVisible = true;
    },
    showEmpoyeeDialogIfNeeded(submitAction: ()=>void) {
      const employeeNameIsNeeded = this.$tstore.getters.employeeNameIsNeeded as boolean;
      if (!this.employeeDialogVisible && employeeNameIsNeeded) {
        this.employeeDialogVisible = true;
        this.employeeDialogSubmitAction = submitAction;
        return;
      }
      this.employeeDialogVisible = false;
      submitAction();
    },
    async saveContact(newContact: Contact) {
      const submitAction = async () => {
        const action = newContact.id === 0 ? 'guestbookCreateContact' : 'guestbookUpdateContact';
        const ok = await performDispatchAction(action, { contact: newContact });
        if (!ok) return;
        this.editedContact = null;
        this.contactDialogVisible = false;
      };

      if (newContact.id === 0) submitAction();
      else this.showEmpoyeeDialogIfNeeded(submitAction);
    },
    showDeleteContactsConfirmation() {
      showConfirmationDialog(
        this.$i18n.tc('title.delete_contacts'),
        this.$i18n.t('message.confirm_delete_x_contacts', [this.selectedContacts.length]) as string,
        {
          title: this.$i18n.tc('button.ok'),
          action: () => { this.deleteContacts(); },
        },
        { title: this.$i18n.tc('button.cancel') },
      );
    },
    async deleteContacts() {
      const submitAction = async () => {
        const contacts = this.selectedContacts;
        try {
          const ok = await performDispatchAction(
            'guestbookDeleteContacts',
            { contacts },
            { rethrow: true },
          );
          this.selectedContacts = [];
        } catch (e: any) {
          if (e.code === ApiErrorCode.contact_upcomming_last_visit) {
            showConfirmationDialog(this.$i18n.tc('title.delete_contacts'), e.message);
          } else {
            actionShowError(e);
          }
        }
      };
      this.showEmpoyeeDialogIfNeeded(submitAction);
    },
    async showMergeContacts() {
      const contacts = this.selectedContacts;
      const selectedContacts: Contact[] | null = await performDispatchAction('guestbookSelectedContacts', { contacts });
      if (!selectedContacts || selectedContacts.length < 2) return;

      const map = toMapById(selectedContacts);
      this.selectedContacts = this.selectedContacts.flatMap((sc) => map.get(sc.id) ?? []);
      this.mergeDialogVisible = true;
    },
    async mergeContacts(contact: Contact) {
      const submitAction = async () => {
        const contacts = this.selectedContacts;
        const ok = await performDispatchAction('guestbookMergeContacts', { contact, contacts });
        if (!ok) return;
        this.selectedContacts = [];
        this.mergeDialogVisible = false;
      };
      this.showEmpoyeeDialogIfNeeded(submitAction);
    },
    addFilter() {
      this.editContactFilter = null;
      this.filterDialogVisible = true;
    },
    editFilter() {
      this.editContactFilter = this.contactFilter;
      this.filterDialogVisible = true;
    },
    contactFilterSelected(filter: ContactFilter) {
      this.selectedContacts = [];
      this.$tstore.dispatch('guestbookApplyContactFilter', { contactFilter: filter });
    },
    resetSelection() {
      this.selectedContacts = [];
    },
    async newContactReservation(contact: Contact) {
      await tryEditReservation(this.$router, undefined, { contact: cloneModel(contact) });
    },
    async listContactReservations(contact: Contact) {
      const reservations = await performDispatchAction<Reservation[]>('guestbookContactReservations', { contact });
      if (reservations) {
        this.editedContact = contact;
        this.contactReservations = reservations;
        this.reservationsByContactDialog = true;
      }
    },
    pagination(p :{
      itemsLength: number,
      itemsPerPage: number
      page: number,
      pageCount: number,
      pageStart: number,
      pageStop: number,
    }) {
      console.log('guestbook pagination: ', p);
      this.selectedContacts = [];
      this.$tstore.dispatch('updateGuestbookPage', { pageSize: p.itemsPerPage, pageStart: p.pageStart });
    },
    updatePage(p: {
      page: number,
      itemsPerPage: number,
      sortBy: string[],
      sortDesc: boolean[],
      groupBy: string[],
      groupDesc: boolean[],
      multiSort: boolean,
      mustSort: boolean,
    }) {
      console.log('guestbook updatePage: ', p);
      // this.$tstore.dispatch('updateGuestbookPage', { pageSize: p.itemsPerPage, pageStart: p.page * p.itemsPerPage });
    },
    updateSortBy(field: string) {
      this.selectedContacts = [];
      this.$tstore.dispatch('updateGuestbookSort', { sortType: field });
    },
    updateSortDesc(desc: boolean) {
      this.selectedContacts = [];
      this.$tstore.dispatch('updateGuestbookSort', { sortDesc: desc });
    },
    noSort(items: Contact[]): Contact[] {
      return items;
    },
    updateSearchTerm(query: string) {
      this.$tstore.dispatch('updateGuestbookFullTextFilter', { query });
    },
  },
});

