import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { IUpdate } from '@/api/api-update';
import httpClient from '@/api/http-client';

import Reservation, { ReservationMapping, ReservationStatus } from '@/model/Reservation';
import Employee from '@/model/Employee';
import DayNote from '@/model/DayNote';

import {
  setDateHoursMinutes, hourMinFromTimeText, isDateAfterDate,
} from '@/services/time-utils';
import { toMapByField } from '@/model/model-utils';

import DateAndTime from '@/model/DateAndTime';
import TimeSlot from '@/model/TimeSlot';
import { InvalidStatusesForToday, InvalidStatuses } from '@/util/status';
import { updateDaySlotEntities } from '@/model/date-slot-utils';
import DateSlot from '@/model/DateSlot';
import { DEFAULT_SPLIT_LUNCH_DINNER_TIME } from '@/services/configuration';
import { searchUpdateReservations } from '@/services/search';
import {
  overbookedReservationsAndTabItemsForReservationId,
  toReservationsDuringSlot, toReservationsByTabItemAndSlot,
} from '@/services/reservation-validation';
import OpeningHour from '@/model/OpeningHour';
import { timeSlotsFromHours } from '@/services/timestlot-utils';
import { performUpdatesAction, UpdateOriginator } from '@/services/store-utils';
import IRootState, { IReservationsState, ISetStoreState } from './store-state';

export class ReservationsState implements IReservationsState {
  dateSlotsByDateIndex: { [dateIndex: number]: DateSlot } = {};

  reservationsById: { [id: number]: Reservation } = {};

  dayNotesById: { [id: number]: DayNote } = {};

  datesAndTimesById: { [id: number]: DateAndTime } = {};
}

const mutations = <MutationTree<IReservationsState>>{
  RESET(state: ReservationsState) {
    Object.assign(state, new ReservationsState());
  },
  SET(state: ReservationsState, p: { state: ISetStoreState }) {
    if (!p.state.reservations) return;
    console.log('SET reservations:', p.state.reservations);
    searchUpdateReservations(Object.values(p.state.reservations.reservationsById), state.reservationsById);
    Object.assign(state, p.state.reservations);
  },
  RESET_RESERVATIONS(state: ReservationsState) {
    Object.assign(state, new ReservationsState());
  },
  UPDATE_DAYNOTES(state: ReservationsState, p: { dayNotes: DayNote[], isFullUpdate: false }) {
    updateDaySlotEntities(p.dayNotes, 'dayNotes', state.dayNotesById, state.dateSlotsByDateIndex, p);
    console.log('dayNotes: ', state.dayNotesById);
  },
  UPDATE_DATESANDTIMES(state: ReservationsState, p: { datesAndTimes: DateAndTime[], isFullUpdate: false }) {
    updateDaySlotEntities(
      p.datesAndTimes,
      'datesAndTimes',
      state.datesAndTimesById,
      state.dateSlotsByDateIndex,
      p,
    );
    console.log('datesAndTimes:', state.datesAndTimesById);
  },
  UPDATE_RESERVATIONS(state: ReservationsState, p: { reservations: Reservation[], isFullUpdate: false }) {
    searchUpdateReservations(p.reservations, state.reservationsById);

    const indices = updateDaySlotEntities(
      p.reservations,
      'reservations',
      state.reservationsById,
      state.dateSlotsByDateIndex,
      p,
    );
    indices.forEach((di) => state.dateSlotsByDateIndex[di].updateCounts());
    console.log('reservations:', state.reservationsById);
  },
  REFRESH_RESERVATIONS(state: ReservationsState, p: { mapping: ReservationMapping }) {
    Object.values(state.reservationsById).forEach((r) => r.refresh(p.mapping));
    console.log('reservations refreshed:', state.reservationsById);
  },
};

const actions = <ActionTree<IReservationsState, IRootState>>{
  resetReservations({
    state, rootGetters, commit, dispatch,
  }, update: IUpdate) {
    commit('RESET_RESERVATIONS');
  },
  updateReservations({
    state, rootState, rootGetters, commit, dispatch,
  }, update: IUpdate) {
    // detect full update
    const { isFullUpdate } = update;
    if (isFullUpdate) commit('RESET_RESERVATIONS');

    // datesAndTimes
    if (update.datesAndTimes && update.datesAndTimes.length > 0) {
      console.log('updateDatesAndTimes');
      const datesAndTimes = (update.datesAndTimes ?? []).map((o) => new DateAndTime(o));
      commit('UPDATE_DATESANDTIMES', { datesAndTimes, isFullUpdate });
    }

    // dayNotes
    if (update.dayNotes && update.dayNotes.length > 0) {
      console.log('updateDayNotes');
      const dayNotes = update.dayNotes.map((o) => new DayNote(o));
      commit('UPDATE_DAYNOTES', { dayNotes, isFullUpdate });
    }

    // reservations
    if (update.reservations && update.reservations.length > 0) {
      console.log('updateReservations:', update.reservations);

      const reservations = update.reservations.map((o) => new Reservation(o, rootGetters as ReservationMapping));

      // add to search index first
      // searchUpdateReservations(reservations, state.reservationsById);

      commit('UPDATE_RESERVATIONS', { reservations, isFullUpdate });

      // update edited reservation
      dispatch('updateReservation');

      // update based on changed reservation dates
      const dateIndices = new Set<number>();
      reservations.forEach((r) => dateIndices.add(r.dateIndex));
      if (rootState.reports?.isLoaded) {
        dispatch('updateReservationDates', Array.from(dateIndices.values()));
      }
    }
  },
  refresh({ state, commit, rootGetters }, update: IUpdate) {
    console.log('reservations store refresh');

    if (update.isFullUpdate) return;

    if (
      !update.tabs
      && !update.services
      && !update.employees
      && !update.labels) return;

    const mapping = rootGetters as ReservationMapping;
    commit('REFRESH_RESERVATIONS', { mapping });
  },

  async sendReservation(
    { state, commit, dispatch },
    p: { reservation: Reservation, employee?: Employee, forgetTabItems?: boolean, relocate?: boolean },
  ) {
    const dr = p.reservation.toDto();
    dr.employeeId = p.employee && p.employee.id;
    dr.employeeName = p.employee && p.employee.name;
    dr.forgetTabItems = p.forgetTabItems;

    if (p.relocate) dr.reservedTabItems = undefined;

    console.log('sendReservation: ', dr);

    await performUpdatesAction(
      dispatch,
      () => httpClient.sendReservation(dr),

      { originator: UpdateOriginator.Reservation },
    );
  },
  async sendDateAndTime({ state, commit, dispatch }, p: { entity: DateAndTime, delete: boolean }) {
    const dto = p.entity.toDto();
    if (p.delete) dto.isDeleted = true;
    await performUpdatesAction(
      dispatch,
      () => httpClient.sendDateAndTime(dto),

      { originator: UpdateOriginator.DateAndTime },
    );
  },
  async sendDayNote({ state, commit, dispatch }, p: { entity: DayNote }) {
    console.log('sendDayNote: ', p);
    const dto = p.entity.toDto();
    console.log('sendDayNote: ', dto);
    await performUpdatesAction(
      dispatch,
      () => httpClient.sendDayNote(dto),

      { originator: UpdateOriginator.DayNote },
    );
  },
};

const getters = <GetterTree<IReservationsState, IRootState>>{
  dateSlot(state, localGetters, rootState, rootGetters) {
    const { dateIndex } = rootState.update;
    const dateSlot = state.dateSlotsByDateIndex[dateIndex] ?? new DateSlot(dateIndex);
    console.log('dateSlot: ', dateSlot);
    return dateSlot;
  },
  datesAndTimes(state, localGetters, rootState, rootGetters) {
    return Object.values(state.datesAndTimesById);
  },
  datesAndTimesById(state, localGetters, rootState, rootGetters): Map<number, DateAndTime> {
    const map = new Map<number, DateAndTime>();
    Object.values(state.datesAndTimesById).forEach((dt) => map.set(dt.dateTimeIndex, dt));
    return map;
  },
  unreadReservationsByDate(state, localGetters, rootState, rootGetters) {
    const map = new Map<Date, Reservation[]>();
    const sortedDateSlots = Object.values(state.dateSlotsByDateIndex).sort((a, b) => a.dateIndex - b.dateIndex);
    sortedDateSlots.forEach((ds) => {
      const reservations = ds.reservations.filter((o) => o.isUnread === true);
      if (reservations.length === 0) return;
      map.set(ds.date, reservations);
    });
    console.log('unread reservations: ', map);
    return map;
  },
  unreadReservationsCount(state, localGetters, rootState, rootGetters) {
    const unreadMap: Map<Date, Reservation[]> = localGetters.unreadReservationsByDate;
    let count = 0;
    unreadMap.forEach((rs) => { count += rs.length; });
    return count;
  },
  dayDayNotes(state, localGetters, rootState, rootGetters) {
    const dayNotes = localGetters.dateSlot.dayNotes.filter((o: DayNote) => o.text) as DayNote[];
    const sortedDayNotes = dayNotes.sort((o1, o2) => o1.order - o2.order);
    console.log('day day notes: ', sortedDayNotes);
    return sortedDayNotes;
  },
  // all day reservations
  dayReservations(state, localGetters, rootState, rootGetters) {
    const { reservations } = localGetters.dateSlot;
    console.log('day reservations: ', reservations);
    return reservations;
  },
  // day no invalid reservations + blocks (tables view)
  dayValidReservationsAndBlocks(state, localGetters, rootState, rootGetters) {
    const predicate = (r: Reservation) => !InvalidStatuses.includes(r.status);
    return localGetters.dayReservations.filter(predicate);
  },
  // day reservations no blocks (guests view)
  dayReservationsNoBlocks(state, localGetters, rootState, rootGetters) {
    const predicate = (r: Reservation) => !r.isBlock;
    return localGetters.dayReservations.filter(predicate);
  },
  // day no invalid reservations no blocks (reservation validation)
  dayValidReservationsNoBlocks(state, localGetters, rootState, rootGetters) {
    const predicate = (r: Reservation) => !InvalidStatuses.includes(r.status) && !r.isBlock;
    return localGetters.dayReservations.filter(predicate);
  },
  isDayReservationNoBlock(state, localGetters) {
    const predicate = (r: Reservation) => !r.isBlock;
    return localGetters.dayReservations.some(predicate);
  },
  // day no invalid reservations for today no blocks (guests view)
  isDayValidForTodayReservationNoBlock(state, localGetters, rootState, rootGetters) {
    const invalidStatuses = rootGetters.isToday ? InvalidStatusesForToday : InvalidStatuses;
    const predicate = (r: Reservation) => !invalidStatuses.includes(r.status) && !r.isBlock;
    return localGetters.dayReservations.some(predicate);
  },
  // waiting list order
  orderForWaitingReservation(state, localGetters, rootState, rootGetters): Map<number, number> {
    const predicate = (r: Reservation) => r.status === ReservationStatus.WaitingList;
    const waitingReservations = (localGetters.dayReservationsNoBlocks as Reservation[]).filter(predicate);
    const sortedWaitingReservations = waitingReservations.sort(
      (r1, r2) => {
        const diff = (r1.dateCreated?.getTime() ?? r1.id) - (r2.dateCreated?.getTime() ?? r2.id);
        return diff !== 0 ? diff : r1.id - r2.id;
      },
    );

    const map = new Map<number, number>();
    sortedWaitingReservations.forEach((r, i) => { map.set(r.id, i + 1); });
    return map;
  },
  // availability
  dayTimeSlots(state, localGetters, rootState, rootGetters) {
    return timeSlotsFromHours(
      rootState.update.date,
      rootGetters.openingHoursInWeekdaysByTimeIndex,
      localGetters.datesAndTimesById,
    );
  },
  dayTimeSlotsBySlot(state, localGetters, rootState, rootGetters) {
    return toMapByField(localGetters.dayTimeSlots, 'slot');
  },
  isDayOpen(state, localGetters, rootState, rootGetters) {
    return localGetters.dayTimeSlots.some((o: TimeSlot) => o.isOpen);
  },
  // day stats
  dayStats(state, localGetters, rootState, rootGetters): {
    reservations: number, tables: number, guests: number, lunch: number, dinner: number
  } {
    const splitTime = rootState.settings.accountSettings.splitLunchDinnerTime ?? DEFAULT_SPLIT_LUNCH_DINNER_TIME;
    const hm = hourMinFromTimeText(splitTime) ?? hourMinFromTimeText(DEFAULT_SPLIT_LUNCH_DINNER_TIME)!;
    const splitDate = setDateHoursMinutes(rootState.update.date, hm.h, hm.m);

    const reservations: Reservation[] = rootGetters.filteredReservationsNoBlocks ?? [];

    const tables = new Set<number>();
    let guests = 0;
    let lunch = 0;
    let dinner = 0;

    reservations.forEach((r) => {
      r.tabItems.forEach((t) => { tables.add(t.id); });
      guests += r.partySize;
      if (!isDateAfterDate(r.dateBegin, splitDate)) { lunch += r.partySize; } else dinner += r.partySize;
    });

    return {
      reservations: reservations.length,
      tables: tables.size,
      guests,
      lunch,
      dinner,
    };
  },
  // overbooking
  dayOverbookedReservationsAndTabItemsForReservationId(state, localGetters)
    : {
      overbookedReservationsForReservationId: Map<number, Set<number>>;
      overbookedTabItemsForReservationId: Map<number, Set<number>>;
    } {
    return overbookedReservationsAndTabItemsForReservationId(localGetters.dayValidReservationsAndBlocks);
  },
  dayOverbookedTabItemsForReservationId(state, localGetters): Map<number, Set<number>> {
    return localGetters.dayOverbookedReservationsAndTabItemsForReservationId.overbookedTabItemsForReservationId;
  },
  dayOverbookedReservationsForReservationId(state, localGetters): Map<number, Set<number>> {
    return localGetters.dayOverbookedReservationsAndTabItemsForReservationId.overbookedReservationsForReservationId;
  },
  dayOverbookedFlagsForReservationId(state, localGetters): Map<number, boolean> {
    const ormap = localGetters.dayOverbookedReservationsForReservationId as Map<number, Set<number>>;
    const ofmap = new Map<number, boolean>();

    ormap.forEach((orset, orid) => {
      const overbooked = (Array.from(orset) ?? []).some((rid) => rid < orid);
      if (overbooked) ofmap.set(orid, true);
    });

    return ofmap;
  },
  dayValidReservationsAndBlocksDuringSlot(state, localGetters, rootState, rootGetters): Map<number, Reservation[]> {
    return toReservationsDuringSlot(rootGetters.dayValidReservationsAndBlocks);
  },
  dayValidReservationsAndBlocksByTabItemAndSlot(state, localGetters, rootState, rootGetters)
    : Map<number, Reservation[]> {
    return toReservationsByTabItemAndSlot(rootGetters.dayValidReservationsAndBlocks);
  },
  // date slot stats
  openWeekdays(state, localGetters, rootState, rootGetters): boolean[] {
    const days = rootGetters.openingHoursInWeekdaysByTime as Set<Map<string, OpeningHour>>[];
    return days.map((set) => set.size !== 0);
  },
  openDatesByDateIndex(state, localGetters, rootState, rootGetters): Map<number, boolean> {
    const map = new Map<number, boolean>();
    Object.values(state.dateSlotsByDateIndex).forEach((ds) => {
      if (ds.datesAndTimes.length === 0) return;
      if (ds.datesAndTimes.some((dt) => dt.isOpen === true)) { map.set(ds.dateIndex, true); }
    });

    return map;
  },
};

const ReservationsStore = {
  namespaced: false,
  state: new ReservationsState(),
  mutations,
  actions,
  getters,
};

export default ReservationsStore;
