import { dateDiff } from './time-utils';

export default class Timer {
  timerId: NodeJS.Timeout | null = null;

  scheduleDate: Date | null = null;

  lastUpdateDate: Date | null = null;

  action: () => void;

  constructor(action: () => void) {
    this.action = action;
  }

  schedule(nextUpdateDate?: Date | null): Date | null {
    // stop timer if needed
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
      this.scheduleDate = null;
    }

    // no next update
    if (!nextUpdateDate) return null;

    // schedule next update
    let timeout = dateDiff(nextUpdateDate, new Date());
    if (timeout < 0) timeout = 0;
    this.timerId = setTimeout(() => {
      this.action();
      this.lastUpdateDate = new Date();
    }, timeout + 100); // add 100ms

    this.scheduleDate = nextUpdateDate;
    return this.scheduleDate;
  }
}
