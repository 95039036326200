import i18n from '@/plugins/i18n';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import store from '@/store/store';
import { computed, ref } from 'vue';

export function useMinutesInAdvanceItems() {
  return ref([...new Array(1440)].map((e, i) => (
    { value: i + 1, text: i18n.t('label.x_min', [i + 1]) }
  )));
}
export function useSeatingTimeItems() {
  return ref([...new Array(24)].map((e, i) => ({
    value: (i + 1) * 15,
    text: (localized.durationFromSeconds((i + 1) * 15 * 60)),
  })));
}

export function useTimeInAdvanceItems() {
  return ref([
    ...[0, 1, 5, 10, 15, 20, 30, 45, 60, 90], // 0-90min
    ...[...new Array(23)].map((e, i) => ((i + 1) * 60)), // 2-23 hours
    ...[...new Array(10)].map((e, i) => ((i + 1) * 60 * 24)), // 1-10 days
  ].map((e, i) => ({ value: e, text: localized.durationFromSeconds(e * 60) })));
}

export function partysizes() {
  return [...new Array(500)].map((e, i) => i + 1);
}

export function useBookingCaps() {
  return [0, 15, 30, 45, 60, 90, 120].map((t) => ({
    value: t,
    text: (localized.durationFromSeconds(t * 60)),
  }));
}

export function useOnlineTabs() {
  return computed(() => store.state.settings.tabs.filter((s) => s.usingWeekdaysAndTimes));
}

export function useLanguages() {
  return computed(() => store.state.codes.locales);
}

export function emptySeatItems() {
  return [...new Array(11)].map((e, i) => (i));
}

export function useDaysInAdvanceItems() {
  return [...new Array(730)].map((e, i) => ({
    value: i + 1, text: i18n.tc('label.x_days', (i + 1)),
  }));
}
