import store from '@/store/store';
import { onBeforeUnmount, onMounted } from 'vue';

export default function useStoreWatch(resetCb: () => void, immediate: boolean = false) {
  const unwatch = store.watch((_, getters) => getters.isLoaded, (_, __) => { resetCb(); }, { immediate });

  onBeforeUnmount(() => unwatch());

  onMounted(() => resetCb());
}
