
import { cloneModel } from '@/model/model-utils';
import Reservation, { ReservationStatus } from '@/model/Reservation';
import { tryEditReservation } from '@/services/reservation-edit-utils';
import { endDateForCheckedOut } from '@/services/reservation-utils';
import { performSaveAction } from '@/services/vue-utils';
import { nextStatusItems, nextStatusItemsBlock } from '@/util/status';
import Vue, { PropType } from 'vue';
import { mapActions } from 'vuex';
import { colorForStatus } from '@/components/helpers/colors';

export default Vue.extend({
  name: 'StatusContextMenu',
  components: { },
  props: {
    visible: { type: Boolean, required: true },
    x: { type: Number, required: true },
    y: { type: Number, required: true },
    reservation: { type: Object as PropType<Reservation | null>, required: true },
  },
  data() {
    return {
      isVisible: this.visible,
    };
  },
  computed: {
    items() {
      if (!this.reservation) return [];
      return this.reservation.isBlock
        ? nextStatusItemsBlock(this.reservation.status)
        : nextStatusItems(this.reservation.status);
    },
    endDateTextForCheckedOut(): string | null {
      if (!this.reservation) return null;

      const endDate = endDateForCheckedOut(this.reservation);
      const endDateText = this.$localized.hourMinText(endDate);
      if (!endDateText) return null;
      const text = this.$i18n.t('label.ending_time_will_be_x', [endDateText]).toString();
      return text;
    },
  },
  watch: {
    visible() {
      this.isVisible = this.visible;
    },
    isVisible() {
      if (this.isVisible === false) {
        this.$emit('close');
      }
    },
  },
  mounted() { },
  methods: {
    ...mapActions(['editReservation', 'sendReservation', 'setReservationFromContextStatusChange']),
    async setReservationStatus(status: ReservationStatus) {
      if (!this.reservation) { return; }

      const r = cloneModel(this.reservation);
      r.setStatus(status as ReservationStatus);
      const ok = await performSaveAction(
        undefined,
        async () => this.sendReservation({ reservation: r }),
      );
      if (ok) this.setReservationFromContextStatusChange(r);
    },
    async editReservationClicked() {
      if (!this.reservation) { return; }
      await tryEditReservation(this.$router, this.reservation);
    },
    isCheckedOutStatus(s: ReservationStatus): boolean {
      return s === ReservationStatus.CheckedOut;
    },
    colorForStatus(s: any): string {
      return colorForStatus(s.value);
    },
  },
});

