import { IDayNote } from '@/api/api-update';
import { dateStringFromDate, dateFromDateString } from '@/services/time-utils';

export interface IDTODayNote extends IDayNote { }

export default class DayNote {
  id: number;

  date?: Date;

  text?: string;

  color?: string;

  dateIndex: number;

  dateTimeIndex: number;

  isDeleted: boolean = false; // never deleted, to satisfy IWithDateTimeIndex

  dtLastEdit?: number;

  dtCreate?: number;

  constructor(o?: IDTODayNote) {
    this.id = o?.dayNoteId ?? 0;
    this.date = dateFromDateString(o?.dayNoteDate) ?? undefined;
    this.text = o?.dayNoteText;
    this.color = o?.dayNoteColor;
    this.dtLastEdit = o?.dtLastEdit;
    this.dtCreate = o?.dtCreate;

    this.dateIndex = Number(o?.dayNoteDate);
    this.dateTimeIndex = this.dateIndex * 10000;
  }

  toDto(): IDTODayNote {
    const dto: IDTODayNote = {
      dayNoteId: this.id,
      dayNoteDate: dateStringFromDate(this.date) ?? undefined,
      dayNoteText: this.text,
      dayNoteColor: this.color,
    };
    return dto;
  }

  get order(): number {
    return this.dtCreate ? this.dtCreate : 0;
  }
}
