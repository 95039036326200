import { dateDiff, areDatesEqual } from './time-utils';

export default class RepeatableTimer {
  timerId: NodeJS.Timeout | null = null;

  timeout: number | null = null;

  action: () => Promise<void>;

  constructor(action: () => Promise<void>) {
    this.action = action;
  }

  stop() {
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = null;
    this.timeout = null;
  }

  start(timeout: number) {
    this.stop();
    this.timeout = timeout;

    if (!this.timeout) return;

    // schedule next update
    this.timerId = setTimeout(async () => {
      await this.action();
      if (this.timeout) this.start(this.timeout);
    }, this.timeout);
  }
}
