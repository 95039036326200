/* eslint-disable max-len */
/* eslint-disable vue/max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

import {
  FilterFieldRule, FilterFieldType, FilterRule, FilterRuleType,
} from './filter-rule';
import {
  FilterDateNoYearOperands,
  FilterDateNoYearOperandsWithSeparators, FilterDateOperand, FilterDateOperands, FilterDateOperandsWithSeparators,
} from './filter-rule-date-operation';
import { FilterOperandSeparator, FilterOperandType, isEmptyOperand } from './filter-rule-operation';

export type ListInfo<T> = { text: string, value: T, divider?: boolean }[]

export enum FilterSpecialCategoryOperandType {
  Country = 'country',
  Language = 'language',
}

const FilterSpecialCategoryOperandTypes = [FilterSpecialCategoryOperandType.Country, FilterSpecialCategoryOperandType.Language];

export const FilterStringRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.Contains, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];
export const FilterNumberRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.LessThen, FilterRuleType.GreaterThen, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];
export const FilterBooleanRuleTypes = [FilterRuleType.IsSet, FilterRuleType.IsNotSet];
export const FilterDateRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];
export const FilterCathegoricalRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];

export class FilterDescriptor {
  ruleDescriptors: FilterRuleDescriptor[] = [];

  constructor(ruleDescriptors: FilterRuleDescriptor[]) {
    this.ruleDescriptors = ruleDescriptors;
  }

  validateRule(rule: FilterFieldRule, checkOperand = true): string | null {
    if (rule.field.trim() === '') return `Field ${rule.field} is not specified`;
    const ruleDescriptor = this.ruleDescriptors.find((rd) => rd.field === rule.field);
    if (!ruleDescriptor) return `Rule for field ${rule.field} is not allowed`;
    return ruleDescriptor.validateRule(rule, checkOperand);
  }

  allowedFieldsInfo(): ListInfo<string> {
    return this.ruleDescriptors.map((rd) => ({ text: rd.field, value: rd.field }));
  }
}

export class FilterRuleDescriptor {
  id: number = 0;

  field: string = '';

  fieldtype: FilterFieldType = FilterFieldType.None;

  allowedRuleTypes: FilterRuleType[] = [];

  allowedOperands: FilterOperandType[] | null | FilterSpecialCategoryOperandType = null;

  constructor(field: string, fieldtype: FilterFieldType, ruleTypes: FilterRuleType[], operands?: FilterOperandType[] | FilterSpecialCategoryOperandType) {
    this.field = field;
    this.fieldtype = fieldtype;
    this.allowedRuleTypes = ruleTypes;
    this.allowedOperands = operands ?? null;

    if (fieldtype === FilterFieldType.Date && !this.allowedOperands) { this.allowedOperands = FilterDateOperandsWithSeparators; }
    if (fieldtype === FilterFieldType.DateNoYear && !this.allowedOperands) { this.allowedOperands = FilterDateNoYearOperandsWithSeparators; }
  }

  validateRule(rule: FilterFieldRule, checkOperand = true): string | null {
    if (rule.field === '') return 'Field is not specified';
    if (this.field !== rule.field) return `Field '${rule.field}' is not allowed`;

    if (rule.type === FilterRuleType.None) return `Operation for field '${rule.field}' is not specified`;
    if (!this.allowedRuleTypes.includes(rule.type)) return `Operation for field '${rule.field}' is not allowed`;

    // no operand check when empty while editing
    if (!checkOperand && isEmptyOperand(rule.operand)) return null;

    return this.validateOperand(rule);
  }

  validateOperand(rule: FilterFieldRule): string | null {
    if (FilterRule.isFilterFieldNoOperandType(rule.type)) {
      if (rule.operand === undefined || rule.operand === null) return null;
      return `Value for field '${rule.field}' is not allowed`;
    }

    if (isEmptyOperand(rule.operand)) return `Value for field '${rule.field}' is not specified`;
    if (this.allowedOperands) {
      if (typeof this.allowedOperands === 'string') {
        if (this.specialCategoryOperandsType() === null) return `Value for field '${rule.field}' is not allowed`; // should be special operand
      } else if (!this.allowedOperands.includes(rule.operand)) return `Value for field '${rule.field}' is not allowed`; // should be included in allowed options
    } else {
      if (FilterRuleDescriptor.isSpecialOperand(rule.operand)) return `Value for field '${rule.field}' is not allowed`;

      switch (this.fieldtype) {
        case FilterFieldType.Boolean: return `Value for field '${rule.field}' is not allowed`; break;

        case FilterFieldType.String:
          if (typeof rule.operand !== 'string') return `Value for field '${rule.field}' is not allowed`; break;
        case FilterFieldType.Number:
          if (typeof rule.operand !== 'number') return `Value for field '${rule.field}' is not allowed`; break;

        case FilterFieldType.Date:
          if (!FilterDateOperands.includes(rule.operand as FilterDateOperand)) return `Value for field '${rule.field}' is not allowed`; break;
        case FilterFieldType.DateNoYear:
          if (!FilterDateNoYearOperands.includes(rule.operand as FilterDateOperand)) return `Value for field '${rule.field}' is not allowed`; break;

        default: return `Value for field '${rule.field}' is not allowed`; break;
      }
    }

    return null;
  }

  static isSpecialOperand(operand: FilterOperandType) {
    if (typeof operand !== 'string') return false;
    return operand.startsWith('@@') && operand.endsWith('@@');
  }

  allowedRuleTypesInfo(): ListInfo<FilterRuleType> {
    return this.allowedRuleTypes.map((rt) => ({ text: FilterRuleDescriptor.ruleTypeText(this.fieldtype, rt), value: rt }));
  }

  specialCategoryOperandsType(): FilterSpecialCategoryOperandType | null {
    if (typeof this.allowedOperands !== 'string' || !FilterSpecialCategoryOperandTypes.includes(this.allowedOperands)) return null;
    return this.allowedOperands; // single string means special category
  }

  allowedOperandsInfo(): ListInfo<FilterOperandType> | null {
    if (!this.allowedOperands) return null;
    if (typeof this.allowedOperands === 'string') return null;

    return this.allowedOperands.map((o) => ({ text: FilterRuleDescriptor.operandText(this.fieldtype, o), value: o, divider: o === FilterOperandSeparator ? true : undefined }));
  }

  static operandText(fieldType: FilterFieldType, operand: FilterOperandType): string {
    if (fieldType === FilterFieldType.Date || fieldType === FilterFieldType.DateNoYear) {
      switch (operand) {
        case FilterDateOperand.Today: return 'Today';
        case FilterDateOperand.Tomorrow: return 'Tomorrow';
        case FilterDateOperand.Yesterday: return 'Yesterday';
        case FilterDateOperand.ThisWeek: return 'This Week';
        case FilterDateOperand.NextWeek: return 'Next Week';
        case FilterDateOperand.PreviousWeek: return 'Last Week';
        case FilterDateOperand.ThisMonth: return 'This Month';
        case FilterDateOperand.NextMonth: return 'Next Month';
        case FilterDateOperand.PreviousMonth: return 'Last Month';
        case FilterDateOperand.ThisYear: return 'This Year';
        case FilterDateOperand.NextYear: return 'Next Year';
        case FilterDateOperand.PreviousYear: return 'Last Year';
        default: return String(operand);
      }
    }

    if (!operand) return '';
    return String(operand);
  }

  static dateOperandText(fieldType: FilterFieldType, operand: FilterOperandType): string {
    if (!operand) return '';
    return String(operand);
  }

  static ruleTypeText(fieldType: FilterFieldType, ruleType: FilterRuleType): string {
    const textop = ([FilterFieldType.String, FilterFieldType.Date, FilterFieldType.DateNoYear].includes(fieldType));

    switch (ruleType) {
      case FilterRuleType.Equal: return textop ? 'is' : '=';
      case FilterRuleType.NotEqual: return textop ? 'is not' : '<>';
      case FilterRuleType.Contains: return 'contains';
      case FilterRuleType.LessThen: return '<';
      case FilterRuleType.GreaterThen: return '>';
      case FilterRuleType.IsSet: return 'is set';
      case FilterRuleType.IsNotSet: return 'is not set';
      case FilterRuleType.IsNotEmpty: return 'is not empty';
      case FilterRuleType.IsEmpty: return 'is empty';
      default: return fieldType as string;
    }
  }
}
