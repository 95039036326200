import { TranslateResult } from 'vue-i18n';

/* eslint-disable class-methods-use-this */
export interface IToastPlugin {

  success(message: string | TranslateResult): void;
  successDelayed(message: string | TranslateResult): void;
  error(message: string | TranslateResult): void;

}

class ToastPlugin {
  success: (message: string | TranslateResult) => void = (s: string | TranslateResult) => {};

  successDelayed: (message: string | TranslateResult) => void = (s: string | TranslateResult) => {};

  error: (message: string | TranslateResult) => void = (s: string | TranslateResult) => {};
}

export default new ToastPlugin();
