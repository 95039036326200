
import Vue, { PropType } from 'vue';
import FloorplanTable from '@/components/views/floorplan/FloorplanTable.vue';
import TabItem from '@/model/TabItem';
import { cloneModel } from '@/model/model-utils';
import Reservation from '@/model/Reservation';
import StatusContextMenu from '@/components/reservation/StatusContextMenu.vue';
import EditableCanvas from './mixins/editable-canvas';
import { TableSeatType } from './floorplan-types';

export default Vue.extend({
  name: 'FloorplanCanvas',
  components: {
    FloorplanTable,
    StatusContextMenu,
  },
  mixins: [EditableCanvas],
  props: {
    editable: { type: Boolean, required: false, default: false },
    tabItems: { type: Object as PropType<Array<TabItem>>, required: true },
    ongoingReservations: {
      type: Object as PropType<Map<number, Reservation[]> | undefined>,
      required: false,
      default: undefined,
    },
    upcomingReservations: {
      type: Object as PropType<Map<number, Reservation[]> | undefined>,
      required: false,
      default: undefined,
    },
    selectedTabItemIds: { type: Array as PropType<Array<Number>>, required: false, default: () => [] },
    overbookedTabItemIds: { type: Array as PropType<Array<Number>>, required: false, default: () => [] },
    zoom: { type: Number, required: false, default: 1 },
    isLoaded: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      ti: [] as TabItem[],
      contextMenu: {
        reservation: null as null | Reservation,
        visible: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    canvasOffset(): string {
      let marginLeft = 0;
      let marginTop = 0;

      this.ti.forEach((t) => {
        if (t.posX === undefined || t.posY === undefined) { return; }

        const tSize = this.sizeForTabItem(t);
        const x1 = (t.posX - tSize / 2);
        const y1 = (t.posY - tSize / 2);

        if (x1 < marginLeft) {
          marginLeft = x1;
        }
        if (y1 < marginTop) {
          marginTop = y1;
        }
      });

      marginLeft = Math.abs(marginLeft) * this.zoom;
      marginTop = Math.abs(marginTop) * this.zoom;
      const width = this.editable ? '3000px' : `calc(100% - ${marginLeft}px)`;
      const height = this.editable ? '3000px' : `calc(100% - ${marginTop}px)`;

      return `margin-left: ${marginLeft}px; margin-top: ${marginTop}px; height: ${height}; width: ${width}`;
    },
    isSetUp(): boolean {
      if (this.editable) { return true; }
      return this.ti.find((t) => (t.posX !== undefined && t.posY !== undefined)) !== undefined;
    },
  },

  watch: {
    tabItems() {
      this.ti = [];
      this.tabItems.forEach((t) => { this.ti.push(cloneModel(t)); });
    },
    selectedTabItemIds() {
      if (this.selectedTabItemIds.length === 0) { return; }
      const selectedTabItemId = this.selectedTabItemIds[0];
      const tabItemVue = (this.$refs[`tabitem-${selectedTabItemId}`] as Vue[])[0];
      const tabItemEl = tabItemVue.$el as any;

      if (typeof tabItemEl.scrollIntoViewIfNeeded === 'function') {
        tabItemEl.scrollIntoViewIfNeeded(true);
      }
      // TODO: maybe provide custom implementation for firefox browsers
    },
  },

  beforeMount() {
    this.tabItems.forEach((t) => { this.ti.push(cloneModel(t)); });
  },

  mounted() { },

  methods: {
    tabItemSelected(tabItemId: number) {
      this.$emit('selected', tabItemId, this.reservationsForTable(tabItemId));
    },
    deselect() {
      this.$emit('selected', undefined);
    },

    isTabItemSelected(tabItemId: number): boolean {
      if (this.selectedTabItemIds === undefined) { return false; }
      return (this.selectedTabItemIds as number[]).includes(tabItemId);
    },
    isTabItemOverbooked(tabItemId: number): boolean {
      if (this.overbookedTabItemIds === undefined) { return false; }
      return (this.overbookedTabItemIds as number[]).includes(tabItemId);
    },
    sizeForTabItem(ti: TabItem): number {
      if (ti.shape === TableSeatType.SQUARED) {
        if (ti.seats <= 1) { return 80 + 60; }
        if (ti.seats <= 2) { return 80 + 60; }
        return Math.min(300, 80 + Math.trunc((ti.seats - 1) / 2.0) * 40.0) + 60;
      }
      if (ti.shape === TableSeatType.HEADSEAT) {
        if (ti.seats <= 1) { return 40 + 60; }
        if (ti.seats <= 2) { return 80 + 60; }
        return Math.min(300, 80 + Math.trunc((ti.seats - 3) / 2.0) * 40.0) + 60;
      }
      if (ti.shape === TableSeatType.ROUNDED) {
        if (ti.seats <= 1) { return 40 + 60; }
        if (ti.seats <= 2) { return 60 + 60; }
        return Math.min(300, 40 + Math.trunc((ti.seats - 1) / 2.0) * 20.0) + 60;
      }
      return 0;
    },
    reservationsForTable(tabItemId: number): Reservation[] | undefined {
      return this.ongoingReservations?.get(tabItemId);
    },
    upcomingReservationsForTable(tabItemId: number): Reservation[] | undefined {
      if (this.ongoingReservations?.get(tabItemId)) return undefined;
      return this.upcomingReservations?.get(tabItemId);
    },
    showContextMenu(r: Reservation, evt: MouseEvent) {
      if (!r) { return; }
      this.contextMenu.x = evt.clientX;
      this.contextMenu.y = evt.clientY;
      this.contextMenu.reservation = r;
      this.contextMenu.visible = true;
    },
  },
});

