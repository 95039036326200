import { sprintf } from 'sprintf-js';
import moment from 'moment';
import { IOpeningHour } from '@/api/api-update';
import {
  timeNumberFromText, timeIndexFromTime, timeIndexFromSlot, timeStringFromDateTimeIndex, minutesFromTimeIndex,
  slotFromMinutes,
} from '@/services/time-utils';
import HourSlot from './HourSlot';

const date = '2019-07-01';
const offset = 5;
const duration = 15;

export interface IDTOOpeningHour extends IOpeningHour { }

export default class OpeningHour extends HourSlot {
  weekdayNumber: number;

  time: string;

  timeIndex: number = 0;

  constructor(o?: IOpeningHour) {
    super(o);

    this.weekdayNumber = o?.weekdayNumber ?? 0;
    this.time = o?.time || '';
    this.minimumPartySize = o?.minimumPartySize;
    this.maximumPartySize = o?.maximumPartySize;
    this.maximumGuests = o?.maximumGuests;
    this.maximumReservations = o?.maximumReservations;
    this.fixedEndingInMinutes = o?.fixedEndingInMinutes;
    this.timeIndex = timeIndexFromTime(this.time);
  }

  toDto(): IDTOOpeningHour {
    const dto: IDTOOpeningHour = {
      weekdayNumber: this.weekdayNumber,
      time: this.time,
      minimumPartySize: this.minimumPartySize,
      maximumPartySize: this.maximumPartySize,
      maximumGuests: this.maximumGuests,
      maximumReservations: this.maximumReservations,
      fixedEndingInMinutes: this.fixedEndingInMinutes,
    };
    return dto;
  }

  static fromDateString(timeString: string) {
    const m = moment(timeString, 'YYYY-MM-DD HH:mm');
    const day = (m.day() % 7) + 1;

    const h = new OpeningHour();
    h.weekdayNumber = day;
    h.time = timeString.substr(11);
    h.timeIndex = timeIndexFromTime(h.time);

    return h;
  }

  static fromWeekdayAndSlot(weekday: number, slot: number) {
    const h = new OpeningHour();
    h.weekdayNumber = weekday;
    h.timeIndex = timeIndexFromSlot(slot);
    h.time = timeStringFromDateTimeIndex(h.timeIndex);
    return h;
  }

  // IModel entity
  get id() {
    if (this.time.length !== 5) return 0;
    return (100000 * this.weekdayNumber + timeNumberFromText(this.time)) || 0;
  }

  get order(): number {
    return this.id;
  }

  set order(number) {
    // eslint-disable-next-line no-unused-expressions
    this;
  }

  // calendar event
  get start(): string {
    const wd = (this.weekdayNumber + offset) % 7;
    const day = moment(date).add(wd, 'days').format('YYYY-MM-DD');
    return `${day} ${this.time}`;
  }

  get end(): string {
    return moment(this.start).add(duration, 'minutes').format('YYYY-MM-DD HH:mm');
  }

  // eslint-disable-next-line class-methods-use-this
  get isOpen(): boolean { return true; }

  get slot(): number {
    return slotFromMinutes(minutesFromTimeIndex(this.timeIndex));
  }
}
