/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/giftcard_product_pb';
import * as pbs from '@/grpc-pb/giftcards_service_pb';
import Currency from './currency';

export interface GiftcardProductMapping {
}

export default class GiftcardProduct {
  id: number = 0;

  code?: string;

  title?: string;

  description?: string;

  bgUrl?: string;

  serviceId?: number;

  locale?: string;

  enabled?: boolean;

  bgColor?: string;

  expireMonths?: number;

  showLogo?: boolean;

  footerText?: string;

  amounts: number[] = [];

  currency?: string;

  stripeAccountExist?: boolean;

  stripeAccountActive?: boolean;

  static fromGrpcResponse(r: pbs.GiftcardsProductResponse, m?: GiftcardProductMapping): GiftcardProduct {
    const o = r.getProduct();
    return GiftcardProduct.fromGrpcModel(o, m);
  }

  static fromGrpcModel(o?: pbm.GiftcardProduct, m?: GiftcardProductMapping): GiftcardProduct {
    const p = new GiftcardProduct();

    p.id = o?.getId() ?? 0;
    p.code = o?.getCode();
    p.title = o?.getTitle();
    p.description = o?.getDescription();
    p.bgUrl = o?.getBackgroundImageUrl();
    p.serviceId = o?.getServiceId();
    p.locale = o?.getLocale();
    p.enabled = o?.getIsActive();
    p.bgColor = o?.getColor();
    p.expireMonths = o?.getExpirePeriodInMonths();
    p.showLogo = o?.getShowLogo();
    p.footerText = o?.getFooterText();
    p.amounts = o?.getPricesList() ?? [];
    p.currency = o?.getCurrency();
    p.stripeAccountExist = o?.getStripeAccountExist();
    p.stripeAccountActive = o?.getStripeAccountActive();

    return p;
  }

  toGrpcModel(): pbm.GiftcardProduct {
    const o = new pbm.GiftcardProduct();

    o.setId(this.id);

    if (this.code) o.setCode(this.code);
    if (this.title) o.setTitle(this.title);
    if (this.description) o.setDescription(this.description);
    if (this.bgUrl) o.setBackgroundImageUrl(this.bgUrl);
    if (this.serviceId) o.setServiceId(this.serviceId);
    if (this.locale) o.setLocale(this.locale);
    if (this.enabled) o.setIsActive(this.enabled);
    if (this.bgColor) o.setColor(this.bgColor);
    if (this.expireMonths) o.setExpirePeriodInMonths(this.expireMonths);
    if (this.showLogo) o.setShowLogo(this.showLogo);
    if (this.footerText) o.setFooterText(this.footerText);
    if (this.amounts) o.setPricesList(this.amounts);
    if (this.currency) o.setCurrency(this.currency);

    return o;
  }

  clone(): GiftcardProduct {
    const copy = new GiftcardProduct();
    Object.assign(copy, this);
    copy.amounts = Array.from(this.amounts);
    return copy;
  }
}
