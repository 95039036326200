import { isSafariOS } from '@/util/common';
import { computed, ref } from 'vue';

export default function useBrowserHacks() {
  // Microsoft Edge Scrollbar fix
  const applyBrowserhackScrollbar = ref(false);

  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  if (scrollbarWidth > 0) {
    document.documentElement.classList.add('ms-fixed-scrollbar');
    applyBrowserhackScrollbar.value = true;
  }

  document.body.removeChild(scrollDiv);

  // Apply scroll bar fix on ios
  const browserhackClass = computed(() => (isSafariOS() ? 'gp-app-wrap-iosfix' : ''));

  return { applyBrowserhackScrollbar, browserhackClass };
}
