import {
  dateFromDateAndTimeIndex, niceTimeTextFromSlot,
} from './time-utils';

interface IWithDateTimeIndex { id: number; dateIndex: number; slot: number }

function compare<T extends IWithDateTimeIndex>(o1: T, o2: T) {
  const diff = o1.slot - o2.slot;
  if (diff !== 0) return diff;
  return o1.id - o2.id;
}

interface IWithSlot { slot: number; }

// sorted map with entities by date index
export function toSortedMapByDateIndex<T extends IWithDateTimeIndex>(items: T[], sorted = false): Map<number, T[]> {
  const map = new Map<number, T[]>();
  const sortedItems = sorted ? items : items.sort((o1, o2) => compare(o1, o2));

  let dateIndex = 0;
  let entities: T[] = [];

  sortedItems.forEach((o) => {
    if (dateIndex !== 0 && dateIndex !== o.dateIndex) {
      map.set(dateIndex, entities);
      entities = [];
    }
    entities.push(o);
    dateIndex = o.dateIndex;
  });

  if (entities.length > 0) map.set(dateIndex, entities);

  return map;
}

// sorted map with entities by slot
// eslint-disable-next-line @typescript-eslint/no-shadow
export function toSortedMapBySlot<T extends IWithDateTimeIndex, IWithSlot>(
  items: T[],
  sorted = false,
): Map<number, T[]> {
  const map = new Map<number, T[]>();
  const sortedItems = sorted ? items : items.sort((o1, o2) => compare(o1, o2));

  let slot = 0;
  let entities: T[] = [];

  sortedItems.forEach((o) => {
    if (slot !== 0 && slot !== o.slot) {
      map.set(slot, entities);
      entities = [];
    }
    entities.push(o);
    slot = o.slot;
  });

  if (entities.length > 0) map.set(slot, entities);

  return map;
}

// sorted map with entities by date index to map by date
export function mapByDateIndexToMapByDate<T extends IWithDateTimeIndex>(
  mapByDateIndex: Map<number, T[]>,
): Map<Date, T[]> {
  const mapByDate = new Map<Date, T[]>();

  mapByDateIndex.forEach((rs, index) => {
    const date = dateFromDateAndTimeIndex(index);
    if (date) mapByDate.set(date, rs);
  });

  return mapByDate;
}

// sorted map with entities by slot to map by time string
// eslint-disable-next-line @typescript-eslint/no-shadow
export function mapBySlotToMapByTimeText<T extends IWithDateTimeIndex, IWithSlot>(
  mapByDateTimeIndex: Map<number, T[]>,
): Map<string, T[]> {
  const mapByTime = new Map<string, T[]>();

  mapByDateTimeIndex.forEach((rs, slot) => {
    const time = niceTimeTextFromSlot(slot);
    mapByTime.set(time, rs);
  });

  return mapByTime;
}
