import { IDTOMessageTemplate } from '@/api/api-messenger';

export default class MessageTemplate {
  id: number;

  messageTypeId?: number;

  messageLayoutId?: number;

  messageDescription?: string;

  locale?: string;

  subject?: string;

  body?: string;

  isDefault: boolean;

  isDeleted: boolean;

  constructor(o?: IDTOMessageTemplate) {
    this.id = o?.messageTemplateId ?? 0;
    this.messageTypeId = o?.messageTypeId;
    this.messageLayoutId = o?.messageLayoutId;
    this.messageDescription = o?.messageDescription;
    this.locale = o?.locale;
    this.subject = o?.subject;
    this.body = o?.body;
    this.isDefault = o?.isDefault ?? false;
    this.isDeleted = o?.isDeleted ?? false;
  }

  toDto(): IDTOMessageTemplate {
    const dto: IDTOMessageTemplate = {
      messageTemplateId: this.id > 0 ? this.id : 0,
      messageTypeId: this.messageTypeId,
      messageLayoutId: this.messageLayoutId,
      messageDescription: this.messageDescription,
      locale: this.locale,
      subject: this.subject,
      body: this.body,
      isDefault: this.isDefault,
      isDeleted: this.isDeleted,
    };
    return dto;
  }
}
