// source: reservation_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.grpc.api.v1.ReservationService', null, global);
goog.exportSymbol('proto.grpc.api.v1.ReservationServiceBlackout', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ReservationServiceBlackout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ReservationServiceBlackout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ReservationServiceBlackout.displayName = 'proto.grpc.api.v1.ReservationServiceBlackout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ReservationService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.ReservationService.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.ReservationService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ReservationService.displayName = 'proto.grpc.api.v1.ReservationService';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ReservationServiceBlackout.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ReservationServiceBlackout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ReservationServiceBlackout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReservationServiceBlackout.toObject = function(includeInstance, msg) {
  var f, obj = {
    blackoutStartDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blackoutEndDate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ReservationServiceBlackout}
 */
proto.grpc.api.v1.ReservationServiceBlackout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ReservationServiceBlackout;
  return proto.grpc.api.v1.ReservationServiceBlackout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ReservationServiceBlackout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ReservationServiceBlackout}
 */
proto.grpc.api.v1.ReservationServiceBlackout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBlackoutStartDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBlackoutEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ReservationServiceBlackout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ReservationServiceBlackout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ReservationServiceBlackout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReservationServiceBlackout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlackoutStartDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBlackoutEndDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 blackout_start_date = 1;
 * @return {number}
 */
proto.grpc.api.v1.ReservationServiceBlackout.prototype.getBlackoutStartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationServiceBlackout} returns this
 */
proto.grpc.api.v1.ReservationServiceBlackout.prototype.setBlackoutStartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 blackout_end_date = 2;
 * @return {number}
 */
proto.grpc.api.v1.ReservationServiceBlackout.prototype.getBlackoutEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationServiceBlackout} returns this
 */
proto.grpc.api.v1.ReservationServiceBlackout.prototype.setBlackoutEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.ReservationService.repeatedFields_ = [7,8,35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ReservationService.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ReservationService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ReservationService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReservationService.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    weekDayList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    startDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    endDate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    minPartySize: jspb.Message.getFieldWithDefault(msg, 11, 0),
    maxPartySize: jspb.Message.getFieldWithDefault(msg, 12, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 13, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tabId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 16, 0),
    locale: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isPromoted: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    isCreditcardRequired: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    price: jspb.Message.getFieldWithDefault(msg, 21, 0),
    isPricePerPerson: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    currencyId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    dtCreated: jspb.Message.getFieldWithDefault(msg, 24, 0),
    dtUpdate: jspb.Message.getFieldWithDefault(msg, 25, 0),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    minMinutesInAdvance: jspb.Message.getFieldWithDefault(msg, 27, 0),
    minMinutesToModify: jspb.Message.getFieldWithDefault(msg, 28, 0),
    maxParallelBookings: jspb.Message.getFieldWithDefault(msg, 31, 0),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    color: jspb.Message.getFieldWithDefault(msg, 33, ""),
    isDeposit: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    blackoutsList: jspb.Message.toObjectList(msg.getBlackoutsList(),
    proto.grpc.api.v1.ReservationServiceBlackout.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ReservationService}
 */
proto.grpc.api.v1.ReservationService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ReservationService;
  return proto.grpc.api.v1.ReservationService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ReservationService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ReservationService}
 */
proto.grpc.api.v1.ReservationService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekDay(values[i]);
      }
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartDate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndDate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinPartySize(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPartySize(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTabId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPromoted(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCreditcardRequired(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPricePerPerson(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrencyId(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCreated(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtUpdate(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinMinutesInAdvance(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinMinutesToModify(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxParallelBookings(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeposit(value);
      break;
    case 35:
      var value = new proto.grpc.api.v1.ReservationServiceBlackout;
      reader.readMessage(value,proto.grpc.api.v1.ReservationServiceBlackout.deserializeBinaryFromReader);
      msg.addBlackouts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ReservationService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ReservationService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ReservationService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ReservationService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getWeekDayList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getStartDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getMinPartySize();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMaxPartySize();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTabId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsPromoted();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIsCreditcardRequired();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getIsPricePerPerson();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getCurrencyId();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getDtCreated();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getDtUpdate();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getMinMinutesInAdvance();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getMinMinutesToModify();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getMaxParallelBookings();
  if (f !== 0) {
    writer.writeInt32(
      31,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getIsDeposit();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getBlackoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.grpc.api.v1.ReservationServiceBlackout.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 order = 3;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image_url = 6;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string tags = 7;
 * @return {!Array<string>}
 */
proto.grpc.api.v1.ReservationService.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated int32 week_day = 8;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.ReservationService.prototype.getWeekDayList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setWeekDayList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.addWeekDay = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.clearWeekDayList = function() {
  return this.setWeekDayList([]);
};


/**
 * optional int64 start_date = 9;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getStartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 end_date = 10;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 min_party_size = 11;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getMinPartySize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setMinPartySize = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 max_party_size = 12;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getMaxPartySize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setMaxPartySize = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string start_time = 13;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string end_time = 14;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 tab_id = 15;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getTabId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setTabId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 duration = 16;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string locale = 17;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_promoted = 18;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsPromoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsPromoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool is_required = 19;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool is_creditcard_required = 20;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsCreditcardRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsCreditcardRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int64 price = 21;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool is_price_per_person = 22;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsPricePerPerson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsPricePerPerson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional int64 currency_id = 23;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getCurrencyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setCurrencyId = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 dt_created = 24;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getDtCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setDtCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 dt_update = 25;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getDtUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setDtUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool is_deleted = 26;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional int32 min_minutes_in_advance = 27;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getMinMinutesInAdvance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setMinMinutesInAdvance = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int32 min_minutes_to_modify = 28;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getMinMinutesToModify = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setMinMinutesToModify = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 max_parallel_bookings = 31;
 * @return {number}
 */
proto.grpc.api.v1.ReservationService.prototype.getMaxParallelBookings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setMaxParallelBookings = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional bool is_active = 32;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional string color = 33;
 * @return {string}
 */
proto.grpc.api.v1.ReservationService.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional bool is_deposit = 34;
 * @return {boolean}
 */
proto.grpc.api.v1.ReservationService.prototype.getIsDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.setIsDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * repeated ReservationServiceBlackout blackouts = 35;
 * @return {!Array<!proto.grpc.api.v1.ReservationServiceBlackout>}
 */
proto.grpc.api.v1.ReservationService.prototype.getBlackoutsList = function() {
  return /** @type{!Array<!proto.grpc.api.v1.ReservationServiceBlackout>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.api.v1.ReservationServiceBlackout, 35));
};


/**
 * @param {!Array<!proto.grpc.api.v1.ReservationServiceBlackout>} value
 * @return {!proto.grpc.api.v1.ReservationService} returns this
*/
proto.grpc.api.v1.ReservationService.prototype.setBlackoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.grpc.api.v1.ReservationServiceBlackout=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.ReservationServiceBlackout}
 */
proto.grpc.api.v1.ReservationService.prototype.addBlackouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.grpc.api.v1.ReservationServiceBlackout, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.ReservationService} returns this
 */
proto.grpc.api.v1.ReservationService.prototype.clearBlackoutsList = function() {
  return this.setBlackoutsList([]);
};


goog.object.extend(exports, proto.grpc.api.v1);
