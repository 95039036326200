
import Vue from 'vue';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import {
  dateByAddingDays, dateTimeIndexFromDateAndTimeIndex, dateIndexFromDate,
  MORNING_TIME_SLOTS_COUNT, DAY_TIME_SLOTS_COUNT,
  dateStringFromDate8601, dateFromDateString8601, timeIndexFromSlot, weekdayNumberFromDateDay,
} from '@/services/time-utils';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { performSaveAction } from '@/services/vue-utils';
import OpeningHour from '@/model/OpeningHour';
import DateAndTime from '@/model/DateAndTime';
import TimeSlot from '@/model/TimeSlot';
import HourSlotProfile, { profilesFromHours } from '@/model/HourSlotProfile';
import HourSlotsGrid, { HourSlotMenuItemType } from '@/components/views/settings/HourSlotsGrid.vue';
import HourSlot from '@/model/HourSlot';

export default Vue.extend({
  name: 'SpecialHours',
  components: {
    ConfirmationDialog, SettingsForm, HourSlotsGrid,
  },
  mixins: [SettingsBreadcrumbs],
  data() {
    return {
      date: new Date(),
      timeMenu: false,
      closeConfirmation: false,
      resetConfirmation: false,
      breadcrumbs: [],
      unwatch: Function as (() => void),
    };
  },
  computed: {
    dateString(): string {
      return dateStringFromDate8601(this.date)!;
    },
    hourSlots(): (TimeSlot | null)[] {
      const ohmap = new Map<number, OpeningHour>();
      const weekdayNumber = weekdayNumberFromDateDay(this.date.getDay());
      const openingHours = this.$tstore.state.settings.openingHours as OpeningHour[];
      openingHours.forEach((o) => { if (o.weekdayNumber === weekdayNumber) ohmap.set(o.timeIndex, o); });

      const shmap = new Map<number, DateAndTime>();
      const dateIndex = dateIndexFromDate(this.date);
      const dateSlot = this.$tstore.state.reservations.dateSlotsByDateIndex[dateIndex];
      (dateSlot?.datesAndTimes ?? []).forEach((o) => { shmap.set(o.timeIndex, o); });

      const slots: (TimeSlot|null)[] = [];
      for (let si = MORNING_TIME_SLOTS_COUNT; si < DAY_TIME_SLOTS_COUNT; si += 1) {
        const ti = timeIndexFromSlot(si);
        const oh = ohmap.get(ti) ?? undefined;
        const sh = shmap.get(ti) ?? undefined;
        const dti = dateTimeIndexFromDateAndTimeIndex(dateIndex, ti);

        const ts = oh || sh ? new TimeSlot(si, dti, sh, oh) : null;

        slots.push(ts);
      }

      return slots;
    },
    profilesById(): Map<number, HourSlotProfile> {
      const openingHours = this.$tstore.state.settings.openingHours as OpeningHour[];
      const datesAndTimes = this.$tstore.getters.datesAndTimes as DateAndTime[];
      return profilesFromHours([...openingHours, ...datesAndTimes]);
    },
    profiles(): HourSlotProfile[] {
      return Array.from(this.profilesById.values());
    },
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    async profileSelected(hourIndex: number, type: number, IDOrHourSlot: number | HourSlot) {
      const slotIndex = hourIndex + MORNING_TIME_SLOTS_COUNT;
      const dateAndTime = DateAndTime.fromDateAndSlot(this.date, slotIndex);
      dateAndTime.isOpen = true;
      let profile: HourSlotProfile | undefined;

      let isDeleted = false;

      switch (type) {
        case HourSlotMenuItemType.CLOSED:
        // closed - closed special hour
          dateAndTime.isOpen = false;
          break;
        case HourSlotMenuItemType.OPEN:
        // just open - use empty special hour
          break;
        case HourSlotMenuItemType.CUSTOM:
        // existing custom - fill and use special hour from existing profile
          profile = this.profilesById.get(IDOrHourSlot as number);
          dateAndTime.setParams(profile ?? {});
          break;
        case HourSlotMenuItemType.CUSTOM_NEW:
        // new custom - fill and use special hour from values
          dateAndTime.setParams(IDOrHourSlot as HourSlot);
          break;
        case HourSlotMenuItemType.RESET:
        // reset - remove special hour and return !
          isDeleted = true;
          break;
        default:
          break;
      }

      // save hour
      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendDateAndTime', { entity: dateAndTime, isDeleted }),
      );
    },
    updateDate(value: string) {
      this.timeMenu = false;
      this.date = dateFromDateString8601(value)!;
    },
    prevDay() {
      this.date = dateByAddingDays(new Date(this.date), -1);
    },
    nextDay() {
      this.date = dateByAddingDays(new Date(this.date), 1);
    },
    closeSlots() {
      this.closeOrResetSlots(true);
    },
    resetSlots() {
      this.closeOrResetSlots(false);
    },
    async closeOrResetSlots(close: boolean) {
      const di = dateIndexFromDate(this.date);
      const dti = dateTimeIndexFromDateAndTimeIndex(di, 0);

      const dt = new DateAndTime();
      dt.id = dti;
      dt.isOpen = !close;

      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendDateAndTime', { entity: dt }),
      );
    },
  },
});

