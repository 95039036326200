
import Vue, { PropType } from 'vue';
import moment from 'moment';

export default Vue.extend({
  name: 'TimeTableIndicator',
  props: {
    slotWidth: { type: Number, required: true },
    asideWidth: { type: Number, required: true },
    timelineStart: { type: Object as PropType<Date>, required: true },
    timelineEnd: { type: Object as PropType<Date>, required: true },
  },
  data() {
    return {
      left: this.asideWidth,
      refreshInterval: 0 as number,
      initialRefreshTimeout: 0 as number,
      hide: false,
    };
  },
  computed: {
    date(): Date {
      return this.$tstore.state.update.date;
    },
    hideIndicator(): string {
      return this.hide ? 'display: none;' : '';
    },
  },
  watch: {
    slotWidth() {
      this.updatePosition();
    },
    date() {
      this.updatePosition();
    },
  },
  beforeDestroy() {
    document.getElementById('tt-scrollable-container')?.removeEventListener('scroll', this.scrollListener);

    window.clearInterval(this.refreshInterval);
    window.clearTimeout(this.initialRefreshTimeout);
  },
  mounted() {
    this.updatePosition();

    document.getElementById('tt-scrollable-container')!.addEventListener('scroll', this.scrollListener);

    this.refreshInterval = window.setInterval(() => {
      this.updatePosition();
    }, 10000);

    this.initialRefreshTimeout = window.setTimeout(() => {
      this.updatePosition();
    }, 2000);
  },
  methods: {
    updatePosition() {
      if (this.timelineEnd.getTime() < new Date().getTime()) {
        this.left = -100;
        return;
      }
      const diff = moment().diff(this.timelineStart, 'minutes');
      this.left = diff * (this.slotWidth / 30.0) + this.asideWidth;
      this.scrollListener();
    },
    scrollListener() {
      const { scrollLeft } = document.getElementById('tt-scrollable-container')!;
      this.hide = (scrollLeft + (this.asideWidth - 2)) > this.left;
    },
  },
});
