
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import DialogClosable from '@/mixins/dialog-closable';
import { mapActions } from 'vuex';
import Employee from '@/model/Employee';

export default Vue.extend({
  name: 'EmployeeDialog',
  mixins: [DialogClosable],
  props: {

  },
  data: () => ({
    selection: null as number | null,
    newEmployeeName: '',
    rememberMe: false,
  }),
  computed: {
    employees(): Employee[] {
      return this.$tstore.state.settings.employees;
    },
  },
  watch: {
    visible(value: boolean) {
      if (value) {
        this.reset();
      }
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions(['setLastEmployee']),
    reset() {
      this.selection = null;
      if (this.$refs.observer) (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();

      const { lastEmployee, rememberEmployee } = this.$tstore.state.reservation;
      console.log('lastEmployee, rememberEmployee:', lastEmployee, rememberEmployee);

      this.rememberMe = rememberEmployee;
      if (!rememberEmployee) { // not remember -> null selection
        this.selection = null;
      } else if (lastEmployee?.id === 0) { // remember but last employee was the newly created one -> find him
        this.selection = this.employees.find((o) => o.name === lastEmployee?.name)?.id ?? null;
      } else { // set the last id
        this.selection = lastEmployee?.id ?? null;
      }

      this.newEmployeeName = '';
    },
    async save() {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

      // Manually validate in case the user tries to create a new employee from the provided field
      if (this.selection === 0 && this.newEmployeeName.length < 2) {
        observer.setErrors(
          { employee: [this.$i18n.tc('error.no_employee_selected')] },
        );
        return;
      }

      const isValid = await observer.validate();
      if (!isValid) { return; }

      console.log(this.selection);

      const remember = this.rememberMe;
      const employee = this.selection === 0 // new user has ID 0
        ? new Employee({ employeeId: 0, employeeName: this.newEmployeeName })
        : this.employees.find(((e) => e.id === this.selection));

      this.setLastEmployee({ employee, remember });

      this.$emit('selected', { employee, remember });
    },
    cancel() {
      this.$emit('close', false);
    },
  },
});
