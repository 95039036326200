
import Vue, { PropType } from 'vue';
import FloorplanShapeOverflow from './FloorplanShapeOverflow.vue';

export default Vue.extend({
  name: 'FloorplanShapeSeat',
  components: {
    FloorplanShapeOverflow,
  },
  mixins: [],
  props: {
    x: { type: Number, required: true },
    y: { type: Number, required: true },
    occupied: { type: Boolean, required: false, default: false },
    strokeColor: { type: String, required: true },
    orientation: { type: String, required: true },
    overflow: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
    };
  },
  computed: {
    horizontal(): boolean {
      return this.orientation === 'n' || this.orientation === 's';
    },
    path(): string {
      switch (this.orientation) {
        case 'n':
          return 'c 5 -12.5, 25 -12.5, 30 0';
        case 'e':
          return 'c -12.5 5, -12.5 25, 0 30';
        case 's':
          return 'c 5 12.5, 25 12.5, 30 0';
        case 'w':
          return 'c 12.5 5, 12.5 25, 0 30';
        default:
          return '';
      }
    },
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },
});

