import * as pbm from '@/grpc-pb/contact_pb';
import * as pbs from '@/grpc-pb/contact_manager_service_pb';

import ApiContact, { ContactMapping, ContactTitle } from '@/model/Contact';
import { strcmp } from '@/services/common';
import { dateFromDateString, dateFromDateTimeString, dateStringFromDate } from '@/services/time-utils';

// eslint-disable-next-line no-use-before-define
export type ContactSortFncType = (o1: Contact, o2: Contact) => number;

export enum ContactSortType {
  Name = 'name',
  CompanyName = 'companyName',
  Email = 'email',
  Phone = 'phone',
  LastVisit = 'lastVisit',
  VisitCount = 'visitCount',
}

export default class Contact extends ApiContact {
  lastVisit?: Date;

  visitCount?: number;

  isDeleted?: boolean;

  updated?: Date;

  static fromGrpcResponse(r: pbs.ContactListResponse, m?: ContactMapping): Contact {
    const c = r.getContact();
    return Contact.fromGrpcModel(c, m);
  }

  static fromGrpcModel(o?: pbm.Contact, m?: ContactMapping): Contact {
    const c = new Contact();

    c.id = o?.getId() ?? 0;
    c.name = o?.getName() ?? '';
    c.phone = o?.getPhone() ?? '';
    c.email = o?.getEmail();
    c.address = o?.getAddress();
    c.postalCode = o?.getPostalcode();
    c.city = o?.getCity();
    c.state = o?.getState();
    c.companyName = o?.getCompanyname();
    c.reservationCount = o?.getReservationcount();
    c.isVIP = o?.getIsvip();
    c.preferredLanguage = o?.getPreferredlanguage();
    c.dateOfBirth = dateFromDateString(o?.getBirthday()) ?? undefined;
    c.newsletterSubscription = o?.getNewslettersubscription();
    c.notes = o?.getNotes();
    c.title = o?.getTitle() as ContactTitle;
    c.lastVisit = dateFromDateTimeString(o?.getLastvisit()) ?? undefined;
    c.visitCount = o?.getVisitcount();
    c.isDeleted = o?.getIsdeleted();

    c.country = m?.countryByCode.get(o?.getCountry() ?? '');

    c.updated = o?.getDtupdate() ? new Date(o.getDtupdate() * 1000) : undefined;

    return c;
  }

  toGrpcModel(): pbm.Contact {
    const o = new pbm.Contact();

    o.setId(this.id);

    if (this.name) o.setName(this.name);
    if (this.phone) o.setPhone(this.phone);
    if (this.email) o.setEmail(this.email);
    if (this.address) o.setAddress(this.address);
    if (this.city) o.setCity(this.city);
    if (this.state) o.setState(this.state);
    if (this.companyName) o.setCompanyname(this.companyName);

    if (this.reservationCount) o.setReservationcount(this.reservationCount);
    if (this.isVIP) o.setIsvip(this.isVIP);
    if (this.preferredLanguage) o.setPreferredlanguage(this.preferredLanguage);

    if (this.dateOfBirth) o.setBirthday(parseInt(dateStringFromDate(this.dateOfBirth) ?? '', 10));
    if (this.newsletterSubscription) o.setNewslettersubscription(this.newsletterSubscription);
    if (this.notes) o.setNotes(this.notes);
    if (this.title) o.setTitle(this.title);
    if (this.country) o.setCountry(this.country.code);

    if (this.isDeleted) o.setIsdeleted(this.isDeleted);

    return o;
  }

  static compare(o1: Contact, o2: Contact): number {
    return Contact.compareName(o1, o2);
  }

  static compareName(o1: Contact, o2: Contact): number {
    const res = strcmp(o1.name ?? '', o2.name ?? '');
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static compareCompany(o1: Contact, o2: Contact): number {
    const res = strcmp(o1.companyName ?? '', o2.companyName ?? '');
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static compareEmail(o1: Contact, o2: Contact): number {
    const res = strcmp(o1.email ?? '', o2.email ?? '');
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static comparePhone(o1: Contact, o2: Contact): number {
    const res = strcmp(o1.phone ?? '', o2.phone ?? '');
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static compareLastVisit(o1: Contact, o2: Contact): number {
    const res = (o1.lastVisit ?? new Date(0)).getTime() - (o2.lastVisit ?? new Date(0)).getTime();
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static compareVisitCount(o1: Contact, o2: Contact): number {
    const res = (o1.visitCount ?? 0) - (o2.visitCount ?? 0);
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static compareFnc(type: ContactSortType): ContactSortFncType {
    switch (type) {
      case ContactSortType.Name: return Contact.compareName;
      case ContactSortType.CompanyName: return Contact.compareCompany;
      case ContactSortType.Email: return Contact.compareEmail;
      case ContactSortType.Phone: return Contact.comparePhone;
      case ContactSortType.LastVisit: return Contact.compareLastVisit;
      case ContactSortType.VisitCount: return Contact.compareVisitCount;
      default: return Contact.compareName;
    }
  }

  static compareFncDesc(type: ContactSortType): ContactSortFncType {
    const fnc = Contact.compareFnc(type);
    return (o1: Contact, o2: Contact) => -fnc(o1, o2);
  }

  normalizedEmail(): string {
    if (!this.email) return '';
    return this.email.toLowerCase();
  }

  normalizedPhone(dialCode: string): string {
    if (!this.phone || !dialCode) return '';
    let phone = this.phone.replace(/[^\d+]/g, '');
    if (phone.length < 6 || phone.startsWith('+') || phone.startsWith('00')) return phone;
    if (phone.startsWith('0')) phone = phone.slice(2);
    return dialCode + phone;
  }
}
