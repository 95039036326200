

import Vue from 'vue';
import Reservation from '@/model/Reservation';
import GuestResultsList from '@/components/views/guests/GuestResultsList.vue';
import { SearchType } from '@/services/search';

export default Vue.extend({
  name: 'SearchList',
  components: { GuestResultsList },
  data() {
    return {
      query: '',
      mode: 0,
    };
  },
  computed: {
    resultsByDate(): Map<Date, Reservation[]> {
      return this.mode === 0
        ? this.$tstore.getters['search/searchDayReservationsByDate']
        : this.$tstore.getters['search/searchAllReservationsByDate'];
    },
    dense(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isSearching(): boolean {
      return this.$tstore.getters['search/isSearching'];
    },
  },
  watch: {
    query() {
      const query = this.query ?? '';
      this.$tstore.dispatch('search/search', { query, type: SearchType.AllReservations });
    },
  },
});

