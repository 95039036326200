/* eslint-disable vue/no-unused-properties */
import { defineComponent, PropType } from 'vue';
import Reservation, { ReservationStatus, ReservationType } from '@/model/Reservation';
import Tab from '@/model/Tab';
import TabItem, { TAB_ITEM_DUMMY_ID } from '@/model/TabItem';
import { mapActions } from 'vuex';
import {
  setDateHoursMinutes, hourMinFromSlot, dateByAddingDays,
} from '@/services/time-utils';
import TimeSlot from '@/model/TimeSlot';
import { isWalkInReservation, endDateForReservation } from '@/services/reservation-utils';
import { performSaveAction } from '@/services/vue-utils';
import { cloneModel } from '@/model/model-utils';
import { tryEditReservation } from '@/services/reservation-edit-utils';

// decision time between start of dragging to create new reservation and start of pan
const minTouchHoldDuration = 300;

// time during which mouse movement is ignored after the reservation is created
// this removes jitter / contraction of new reservation straight after being created
const minStartDragTimeout = 200;

const asideWidth = 70;
const headerHeight = 57;
const scrollLockZoneSize = 60;

const temporaryReservationId = -1000;

export default defineComponent({
  props: {
    date: { type: Object as PropType<Date>, required: true },
    // reservations: { type: Array as PropType<Array<Reservation>>, required: true },
    tabs: { type: Array as PropType<Array<Tab>>, required: true },
    timeSlots: { type: Array as PropType<Array<TimeSlot>>, required: true },
    slotWidth: { type: Number, required: true },
  },
  data() {
    return {
      unwatchEditReservation: null as (() => void) | null,
      temporaryReservation: null as Reservation | null,
      longTouchCb: null as number | null,
      temporaryCtx: {
        lastSlot: 0,
        originTabItemId: 0,
        originTabId: 0,
        originTimeSlot: 0,
        currentTabItemId: 0,
        currentTimeSlot: 0,
        created: 0,
      },
      scrollLock: false,
    };
  },
  computed: {
    tabItemIndexById(): Map<number, number> {
      const map = new Map<number, number>();
      this.tabItems.forEach((o, i) => map.set(o.id, i));
      return map;
    },
    tabItems(): TabItem[] {
      return this.tabs.flatMap((tab) => tab.tabItems);
    },
  },
  watch: {
    temporaryCtx: {
      deep: true,
      handler() {
        this.updateTemporaryHandler();
      },
    },
  },
  beforeDestroy() {
    if (this.unwatchEditReservation) this.unwatchEditReservation();
  },
  mounted() {
    this.unwatchEditReservation = this.$tstore.watch(
      (state, getters) => getters.editReservation,
      (newValue: Reservation | null, oldValue: Reservation | null) => {
        if (!newValue && oldValue) { this.cancelEdit(); }
        if (newValue && this.temporaryReservation && this.temporaryReservation.id !== newValue.id) {
          this.cancelEdit();
        }
      },
    );
  },
  methods: {
    ...mapActions(['editReservation', 'sendReservation']),
    forceScroll(direction: string) {
      setTimeout(() => {
        if (!this.scrollLock) { return; }
        const scrollableElm = document.getElementById('tt-scrollable-container')!;
        if (direction === 'left') {
          scrollableElm.scrollLeft -= 1;
        } else if (direction === 'right') {
          scrollableElm.scrollLeft += 1;
        } else if (direction === 'top') {
          scrollableElm.scrollTop -= 1;
        } else if (direction === 'down') {
          scrollableElm.scrollTop += 1;
        }
        this.forceScroll(direction);
      }, 100);
    },
    updateTemporaryHandler() {
      // no tab? clear temporary reservation
      const tab = this.tabs.find((o) => o.id === this.temporaryCtx.originTabId);
      if (!tab) {
        this.clearTemporaryReservation();
        return;
      }

      let beginIndex = this.tabItemIndexById.get(this.temporaryCtx.originTabItemId) ?? -1;
      const endIndex = this.tabItemIndexById.get(this.temporaryCtx.currentTabItemId) ?? beginIndex;

      const tabItems = [] as TabItem[];
      let tabItemIndex = tab.tabItems.findIndex((o: TabItem) => o.id === this.temporaryCtx.originTabItemId);
      let tabItem = tab.tabItems[tabItemIndex];
      if (tabItem) tabItems.push(tabItem);

      while (beginIndex !== endIndex && tabItem) {
        const incr = beginIndex <= endIndex ? 1 : -1;
        beginIndex += incr;
        tabItemIndex += incr;
        tabItem = tab.tabItems[tabItemIndex];
        if (tabItem) tabItems.push(tabItem);
      }
      // find slots
      const beginSlot = this.temporaryCtx.originTimeSlot;
      const endSlot = this.temporaryCtx.currentTimeSlot || null;

      this.updateTemporaryReservation(tab, tabItems, beginSlot, endSlot);
    },
    // onStart(x: number, tabId: number, tabItemId: number) {
    // onStart(target: HTMLElement, timeSlot: number) {
    onStart(clientX: number, clientY: number) {
      const target = document.elementsFromPoint(clientX, clientY).find(
        (e) => e.classList.contains('tt-time-row'),
      ) as HTMLElement;
      if (target === null) { return; }

      const rect = target.getBoundingClientRect();
      const timeSlot = this.slotForPosition(clientX - rect.left);

      const tabItemId = parseInt(target.dataset.tabitemid || '-1', 10);
      const tabId = parseInt(target.dataset.tabid || '-1', 10);
      if (tabItemId <= TAB_ITEM_DUMMY_ID || tabId <= 0) { return; } // dummy tab items or parse error

      // console.log(`[drag-drop] -=-=- Started: timeSlot:${timeSlot} TabId: ${tabId} TabItemId: ${tabItemId} =-=-`);

      this.temporaryCtx.originTabItemId = tabItemId;
      this.temporaryCtx.originTabId = tabId;
      this.temporaryCtx.originTimeSlot = timeSlot;
      this.temporaryCtx.currentTabItemId = 0;
      this.temporaryCtx.currentTimeSlot = 0;
      this.temporaryCtx.created = new Date().getTime();
    },
    onDrag(x: number, y: number) {
      // console.log(`[drag-drop] -=-=-=- Drag to: ${x}; ${y} =-=-=-`);
      this.scrollLock = false;

      // exit if we do not have any new reservation
      // or too soon after initial click
      if (!this.isTemporaryReservation()
        || new Date().getTime() - this.temporaryCtx.created < minStartDragTimeout) return;

      // HANDLE VERTICAL DRAG
      // get the row under cursor
      // tabItemId from the row
      const overElem = document.elementsFromPoint(x, y).find(
        (elm) => elm.classList.contains('tt-time-row'),
      );
      if (overElem === undefined) { return; }
      const tabItemId = parseInt((overElem as HTMLElement).dataset.tabitemid!, 10);

      // HANDLE HORIZONTAL DRAG
      // get true X position
      const { left, top } = document.getElementsByClassName('tt-content')[0].getBoundingClientRect();

      const scrollElm = document.getElementById('tt-scrollable-container')!;
      const { width, height } = scrollElm.getBoundingClientRect();
      const xOffset = x - left - scrollElm.scrollLeft;
      const yOffset = y - top - scrollElm.scrollTop;

      if (xOffset < scrollLockZoneSize) {
        this.scrollLock = true;
        this.forceScroll('left');
        return;
      }
      if (xOffset + asideWidth > width - scrollLockZoneSize) {
        this.scrollLock = true;
        this.forceScroll('right');
        return;
      }
      if (yOffset < scrollLockZoneSize) {
        this.scrollLock = true;
        this.forceScroll('top');
        return;
      }
      console.log(`Yo: ${yOffset} height: ${height}`);
      if (yOffset + headerHeight > height - scrollLockZoneSize) {
        this.scrollLock = true;
        this.forceScroll('down');
        return;
      }

      const timeSlot = this.slotForPosition(x - left);

      if (tabItemId > TAB_ITEM_DUMMY_ID) this.temporaryCtx.currentTabItemId = tabItemId;
      this.temporaryCtx.currentTimeSlot = timeSlot;
    },
    async onEnd() {
      this.scrollLock = false;
      await this.editTemporaryReservation();
    },
    slotForPosition(x: number): number {
      const i = Math.floor(x / this.slotWidth);
      const { slot } = this.timeSlots[i];
      // console.log(`[drag-drop] Clicked slot: ${slot}`);

      return slot;
    },
    cancelEdit() {
      // console.log('[drag-drop] cancelEdit');
      this.clearTemporaryReservation();
    },
    // MOUSE EVENTS
    rowMouseDown(evt: MouseEvent) {
      // console.log('[drag-drop] MOUSE DOWN', evt);
      if (evt.buttons !== 1) { return; }

      // prevent conflict with drag and drop, don't fire if event started on tt-event
      let elm = evt.target as HTMLElement;
      while (elm.parentElement !== null) {
        if (elm.classList.contains('tt-event')) {
          return;
        }
        elm = elm.parentElement;
      }

      this.onStart(evt.clientX, evt.clientY);
    },
    rowMouseMoved(evt: MouseEvent) {
      // console.log('[drag-drop] -=-=MOUSE MOVE-=-=-', evt);

      if (evt.buttons !== 1) return;
      evt.preventDefault();
      this.onDrag(evt.clientX, evt.clientY);
    },
    rowMouseUp(evt: MouseEvent) {
      // console.log('[drag-drop] RowMouseUp: ', evt);
      this.onEnd();
    },
    mouseLeave(evt: MouseEvent) {
      // console.log('[drag-drop] mouseLeave: ', evt);
      this.scrollLock = false;
    },
    // TOUCH EVENTS
    touchStart(evt: TouchEvent) {
      // console.log('[drag-drop] -=-=TOUCH START-=-=-', evt);
      // prevent conflict with drag and drop, don't fire if event started on tt-event
      let elm = evt.target as HTMLElement;
      while (elm.parentElement !== null) {
        if (elm.classList.contains('tt-event')) {
          return;
        }
        elm = elm.parentElement;
      }

      this.longTouchCb = window.setTimeout(() => {
        this.longTouchCb = null;
        evt.preventDefault();

        const { clientX, clientY } = evt.targetTouches[0];
        this.onStart(clientX, clientY);
      }, minTouchHoldDuration);
    },
    touchMoved(evt: any) {
      // console.log('[drag-drop] -=-=TOUCH MOVED-=-=-', evt);

      const rect = evt.target.getClientRects()[0];
      const elementsAtPoint = document.elementsFromPoint(rect.x, rect.y);
      const timeRow = elementsAtPoint.find((elm) => elm.classList.contains('is-dragging')) as HTMLElement;
      if (timeRow !== undefined) {
        evt.preventDefault();
        return;
      }

      if (this.longTouchCb !== null) {
        window.clearTimeout(this.longTouchCb);
        this.longTouchCb = null;
      }
      if (!this.isTemporaryReservation()) {
        return;
      }
      evt.preventDefault();

      // console.log('[drag-drop] -=-=TOUCH MOVE-=-=-', evt);
      const { clientX, clientY } = evt.targetTouches[0];
      this.onDrag(clientX, clientY);
    },
    touchEnd(evt: TouchEvent) {
      // console.log('[drag-drop] -=-=TOUCH END-=-=-', evt);

      // In case the click lasted shorther than the wait period
      // we need to ensure there is temporaryReservation
      // before we invoke onEnd
      if (this.temporaryReservation === null && this.longTouchCb !== null) {
        clearTimeout(this.longTouchCb);
        this.longTouchCb = null;
        const { clientX, clientY } = evt.changedTouches[0];
        this.onStart(clientX, clientY);
        this.updateTemporaryHandler();
      }
      this.onEnd();
    },
    // temorary reservation
    isTemporaryReservation() {
      return this.temporaryReservation !== null;
    },
    clearTemporaryReservation() {
      this.temporaryReservation = null;
    },
    updateTemporaryReservation(tab: Tab, tabItems: TabItem[], beginSlot: number, endSlot: number | null) {
      const r = this.temporaryReservation ?? new Reservation();
      r.id = temporaryReservationId;

      tabItems.sort((o1, o2) => o1.order - o2.order);
      r.tabItems = tabItems;
      r.partySize = r.tabItems.map((t) => t.seats).reduce((p, c) => p + c, 0);
      r.tab = tab;

      // is end slot defined? (mouse move detected)
      if (endSlot && beginSlot !== endSlot) {
        // eslint-disable-next-line no-param-reassign
        if (beginSlot > endSlot) [beginSlot, endSlot] = [endSlot, beginSlot]; endSlot += 2;

        const { h: bh, m: bm } = hourMinFromSlot(beginSlot);
        let date = setDateHoursMinutes(this.date, bh % 24, bm);
        if (bh > 24) date = dateByAddingDays(date, 1);
        r.dateBegin = date;

        const { h: eh, m: em } = hourMinFromSlot(endSlot);
        date = setDateHoursMinutes(this.date, eh % 24, em);
        if (eh > 24) date = dateByAddingDays(date, 1);
        r.dateEnd = date;
      } else {
        const { h: bh, m: bm } = hourMinFromSlot(beginSlot);
        let date = setDateHoursMinutes(this.date, bh % 24, bm);
        if (bh > 24) date = dateByAddingDays(date, 1);
        r.dateBegin = date;

        const rules = this.$tstore.state.settings.durationRules;
        r.dateEnd = endDateForReservation(r.dateBegin, tab, r.partySize, rules);
      }

      // recompute time slots and minutes
      r.updateSlotsAndTimes();

      // check walkin
      const isWalkIn = isWalkInReservation(r);
      r.status = isWalkIn ? ReservationStatus.Seated : ReservationStatus.Planned;
      r.bookingType = isWalkIn ? ReservationType.Walkin : ReservationType.Reservation;
      // console.log('!!!!!!!!!');
      // console.log(this.temporaryReservation);
      this.temporaryReservation = r;

      // don't open and sync reservation dialog when compact view
      if (!this.$vuetify.breakpoint.mdAndDown) {
        // open and sync reservation dialog only if no changes were made or tmp. res is edited
        if (this.$tstore.getters.editReservation?.id === temporaryReservationId) {
          this.editReservation(this.temporaryReservation);
        }
      }
    },
    async editTemporaryReservation() {
      if (this.temporaryReservation === null) return;

      if (isWalkInReservation(this.temporaryReservation)) {
        const reservation = cloneModel(this.temporaryReservation);
        const ok = await performSaveAction(
          undefined,
          async () => this.sendReservation({ reservation }),
        );
        this.temporaryReservation = null;
      } else {
        const ok = await tryEditReservation(this.$router, this.temporaryReservation);
        if (!ok) this.clearTemporaryReservation();
      }
    },
    // store actions
    sendReservation(val: {reservation: Reservation}) {
      this.$store.dispatch('sendReservation', val);
    },
    editReservation(reservation: Reservation) {
      this.$store.dispatch('editReservation', reservation);
    },
  },
});
