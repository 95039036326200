import { ApiError } from '@/api/api-error';
import { RpcError, ClientReadableStream, StatusCode } from 'grpc-web';

export type FromGrpcResponseFnc<M, RESP> = (resp: RESP, params?: any) => M;
export type OnErrorFnc = (error: ApiError) => void;
export type OnSingleDataFnc<M> = (data: M) => void;
export type OnMultipleDataFnc<M> = (data: M[]) => void;

export type UnaryCallbackFnc<M, RESP> = (error: RpcError, resp: RESP) => void;

export type ResolveFnc<M> = (value: M) => void;
export type RejectFnc = (reason?: any) => void;

const MIN_DATA_COUNT = 50;
const MAX_DATA_COUNT = 1000;

export async function grpcStreamOp<M, RESP>(
  ModelType: { fromGrpcResponse: FromGrpcResponseFnc<M, RESP> },
  service: any,
  method: string,
  metadata: any,
  req: any,
  fromGrpcRespParams: any,
  onData: OnMultipleDataFnc<M>,
  onError: OnErrorFnc,
  waitForEnd = false,
) {
  let dataCount = MIN_DATA_COUNT;

  let resolved = false;
  let resolve: (() => void);
  let reject: ((reason?: any) => void);
  const promise = new Promise<void>((res, rej) => { resolve = res; reject = rej; });

  let data: M[] = [];

  const complete = (reason?: any) => {
    if (resolved) return;
    if (reason) reject(reason); else resolve();
    resolved = true;
  };

  const handleData = (response: RESP) => {
    try {
      data.push(ModelType.fromGrpcResponse(response, fromGrpcRespParams));
      if (data.length < dataCount) return;

      dataCount *= 2;
      if (dataCount > MAX_DATA_COUNT) dataCount = MAX_DATA_COUNT;
      onData(data);
    } catch (error) {
      const apiError = ApiError.fromError(error);
      onError(apiError);
      complete(apiError);
    }
    data = [];

    if (!waitForEnd) complete();
  };

  const handleError = (error: RpcError) => {
    const apiError = ApiError.fromError(error);
    onError(apiError);
    complete(apiError);
  };

  const handleEnd = () => {
    try {
      if (data.length > 0) onData(data);
      onData([]);
    } catch (error) {
      const apiError = ApiError.fromError(error);
      onError(apiError);
      complete(apiError);
    }
    complete();
  };

  const stream = service[method](req, metadata) as ClientReadableStream<RESP>;

  stream.on('data', (response) => {
    // console.log('stream data: ', response);
    handleData(response);
  });
  stream.on('metadata', (meta) => {
    console.log('stream metadata: ', meta);
  });
  stream.on('status', (status) => {
    console.log('stream status: ', status);
  });
  stream.on('end', () => {
    console.log('stream end');
    handleEnd();
  });
  stream.on('error', (error) => {
    console.log('stream error: ', error);
    handleError(error);
    stream.cancel();
  });

  return promise;
}

// read all data from sream op then return them all
export async function grpcStreamOpAll<M, RESP>(
  ModelType: { fromGrpcResponse: FromGrpcResponseFnc<M, RESP> },
  service: any,
  method: string,
  metadata: any,
  req: any,
  fromGrpcRespParams?: any,
) {
  const allData: M[] = [];
  const onData = (data: M[]) => { allData.push(...data); };
  const onError = () => {};

  await grpcStreamOp(ModelType, service, method, metadata, req, fromGrpcRespParams, onData, onError, true);
  return allData;
}

export async function grpcUnaryOp<M, RESP>(
  ModelType: { fromGrpcResponse: FromGrpcResponseFnc<M, RESP> },
  servis: any,
  method: string,
  metadata: any,
  req: any,
  fromGrpcRespParams: any,
  onData?: OnSingleDataFnc<M>,
  onError?: OnErrorFnc,
) {
  let resolve: ((e: M) => void);
  let reject: ((reason?: any) => void);
  const promise = new Promise<M>((res, rej) => { resolve = res; reject = rej; });

  const unaryCallback = (error: RpcError, resp: RESP) => {
    if (error && error.code !== StatusCode.OK) {
      const apiError = ApiError.fromError(error);
      if (onError) onError(apiError);
      reject(apiError);
    } else {
      const e = ModelType.fromGrpcResponse(resp, fromGrpcRespParams);
      if (onData) onData(e);
      resolve(e);
    }
  };

  const stream = servis[method](req, metadata, unaryCallback) as ClientReadableStream<RESP>;

  stream.on('data', (response) => {
    console.log('stream data: ', response);
  });
  stream.on('metadata', (meta) => {
    console.log('stream metadata: ', meta);
  });
  stream.on('status', (status) => {
    console.log('stream status: ', status);
  });
  stream.on('end', () => {
    console.log('stream end');
  });
  stream.on('error', (error) => {
    console.log('stream error: ', error);
  });

  return promise;
}

export async function grpcUnaryOpEx(
  transformFnc: ((resp: any, params?: any) => any) | null,
  servis: any,
  method: string,
  metadata: any,
  req: any,
  fncParams: any,
  onData?: OnSingleDataFnc<any>,
  onError?: OnErrorFnc,
) {
  let resolve: ((e: any) => void);
  let reject: ((reason?: any) => void);
  const promise = new Promise((res, rej) => { resolve = res; reject = rej; });

  const unaryCallback = (error: RpcError, resp: any) => {
    if (error && error.code !== StatusCode.OK) {
      const apiError = ApiError.fromError(error);
      if (onError) onError(apiError);
      reject(apiError);
    } else {
      const e = resp && transformFnc ? transformFnc(resp, fncParams) : resp;
      if (onData) onData(e);
      resolve(e);
    }
  };

  const stream = servis[method](req, metadata, unaryCallback) as ClientReadableStream<any>;

  stream.on('data', (response) => {
    console.log('stream data: ', response);
  });
  stream.on('metadata', (meta) => {
    console.log('stream metadata: ', meta);
  });
  stream.on('status', (status) => {
    console.log('stream status: ', status);
  });
  stream.on('end', () => {
    console.log('stream end');
  });
  stream.on('error', (error) => {
    console.log('stream error: ', error);
  });

  return promise;
}
