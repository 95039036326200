/* eslint-disable @typescript-eslint/indent */
import elasticlunr, { Index } from 'elasticlunr';
import Reservation from '@/model/Reservation';

export enum SearchType {
  None,
  DayReservations,
  AllReservations,
  ContactName,
  ContactPhone,
  ContactEmail,
}

interface SearchReservation
{
  id: string;
  name?: string;
  phone?: string;
  email?: string;
  notes?: string;
  contactNotes?: string;
}

const ReservationFields: (keyof SearchReservation)[] = ['id', 'name', 'phone', 'email', 'notes', 'contactNotes'];

function toSearchReservation(r: Reservation): SearchReservation {
  return {
    id: String(r.id),
    name: r.contact.name ?? undefined,
    phone: r.contact.phone ?? undefined,
    email: r.contact.email ?? undefined,
    notes: r.notes ? `$$ ${r.notes}` : undefined,
    contactNotes: r.contact.notes ? `$$ ${r.contact.notes}` : undefined,
  };
}

function searchCreate(): Index<SearchReservation> {
  const rindex = elasticlunr<SearchReservation>((index) => {
    ReservationFields.forEach((f) => index.addField(f));
    index.saveDocument(false);
    index.pipeline.reset();
    index.pipeline.add((token: string, i: number, tokens: string[]): string | void | null | undefined => {
      if (i === 0 || tokens[0] !== '$$') return token;

      const token2 = elasticlunr.stopWordFilter(token);
      if (!token2) return undefined;

      const token3 = elasticlunr.stemmer(token2);
      if (!token3) return undefined;

      return token3;
    });
  });
  return rindex;
}

let rindex = searchCreate();

// export function searchAddReservation(r: Reservation) {
//   rindex.addDoc(toSearchReservation(r));
// }

// export function searchUpdateReservation(r: Reservation) {
//   rindex.updateDoc(toSearchReservation(r));
// }

export function searchUpdateReservations(reservations: Reservation[], reservationsById: { [id: number]: Reservation }) {
  reservations.forEach((r) => {
    const rdoc = toSearchReservation(r);
    if (reservationsById[r.id]) rindex.updateDoc(rdoc);
    else rindex.addDoc(rdoc);
  });
}

export function searchReset() {
  rindex = searchCreate();
}

export function searchForReservations(
query: string,
type: SearchType,
  reservationsById: { [id: number]: Reservation },
) {
  if (query.trim().length < 3) return [];

  const config = {
    fields: {
      name: { boost: 0 },
      phone: { boost: 0 },
      email: { boost: 0 },
      notes: { boost: 0 },
      contactNotes: { boost: 0 },
    },
    expand: true,
    bool: 'AND' as elasticlunr.Bool,
  };

  switch (type) {
  case SearchType.ContactName: config.fields.name.boost = 1; break;
  case SearchType.ContactPhone: config.fields.phone.boost = 1; break;
  case SearchType.ContactEmail: config.fields.email.boost = 1; break;
  case SearchType.AllReservations:
  case SearchType.DayReservations:
    config.fields.name.boost = 1;
    config.fields.phone.boost = 1;
    config.fields.email.boost = 1;
    config.fields.notes.boost = 1;
    config.fields.contactNotes.boost = 1;
    break;
  default:
    break;
  }

  let res = rindex.search(query, config);
  if (type === SearchType.AllReservations || type === SearchType.DayReservations) {
    const resmap = new Map<number, number>((res.map((r) => [Number(r.ref), r.score])));
    const resft = rindex.search(`$$ ${query}`, config);
    resft.forEach((r) => {
      if ((resmap.get(Number(r.ref)) ?? 0) < r.score) return;
      resmap.set(Number(r.ref), r.score);
    });
    res = Array.from(resmap.entries()).map((re) => ({ ref: String(re[0]), score: re[1] }));
    res = res.sort((r1, r2) => -r1.score + r2.score);
  }

  console.log('searchForReservations: ', query, config, res);

  const reservations = res.map((r) => reservationsById[Number(r.ref)]);
  return reservations;
}
