
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'PartySizePicker',
  props: {
    value: { type: Number, required: true },
    label: { type: String, required: false, default: '' },
    hint: { type: String, required: false, default: null },
  },
  data() {
    return {
      partySize: this.value,
      partySizes: [...new Array(501)].map((e, i) => (
        { value: i, text: this.$i18n.t('label.x_persons', [i]), disabled: i <= 5 })),
    };
  },
  computed: {
    otherPartySizeButtonText(): string {
      return this.partySize > 5 ? `: ${this.partySize}` : '';
    },
  },
  watch: {
    value() {
      this.partySize = this.value;
    },
    partySize() {
      if (this.partySize === this.value) return;
      this.$emit('input', this.partySize);
    },
  },
  methods: { },
});
