
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';

export default Vue.extend({
  name: 'ModalDialog',
  mixins: [DialogClosable],
  props: {
    title: { type: String, required: false, default: '' },
    maxWidth: { type: Number, required: false, default: 600 },
    persistent: { type: Boolean, required: false, default: false },
    cardClass: { type: String, required: false, default: null },
    cardHeight: { type: Number, required: false, default: null },
    cardMaxHeight: { type: String, required: false, default: null },
    scrollable: { type: Boolean, required: false, default: false },
    contentClass: { type: String, required: false, default: null },
    containerClass: { type: String, required: false, default: 'pa-md-5 pa-0' },
    fluid: { type: Boolean, required: false, default: false },
    fullscreen: { type: Boolean, required: false, default: false },
  },
  data() {
    return { };
  },
  watch: { },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    scrollToBottom() {
      const scrollDiv = this.$refs.scrollContent as HTMLElement;
      scrollDiv.scrollTop = scrollDiv.scrollHeight + 60;
    },
  },
});
