import { GetterTree, MutationTree, ActionTree } from 'vuex';
import Tab from '@/model/Tab';
import Reservation, { ReservationStatus } from '@/model/Reservation';
import { setDateHoursMinutes, hourMinFromTimeText } from '@/services/time-utils';
import { InvalidStatusesForToday, InvalidStatuses } from '@/util/status';
import { toSortedMapBySlot, mapBySlotToMapByTimeText } from '@/services/reservations-utils';
import IRootState, { IFilterState } from './store-state';

const FILTER_DEFAULT_FROM = '06:00';
const FILTER_DEFAULT_TO = '24:00';
const FILTER_DEFAULT_STATUS = ReservationStatus.Valid;
const FILTER_DEFAULT_TAB = null;

export class FilterState implements IFilterState {
  from: string = FILTER_DEFAULT_FROM;

  to: string = FILTER_DEFAULT_TO;

  status: ReservationStatus = FILTER_DEFAULT_STATUS;

  tab: Tab | null = FILTER_DEFAULT_TAB;

  tablesTab: Tab | null = FILTER_DEFAULT_TAB;

  floorplanTab: Tab | null = FILTER_DEFAULT_TAB;
}

const mutations = <MutationTree<IFilterState>>{
  SET_FILTER(
    state: IFilterState,
    p: { from?: string | null, to?: string | null, status?: ReservationStatus | null,
      tab?: Tab | null, tablesTab?: Tab | null, floorplanTab?: Tab | null },
  ) {
    if (p.from !== undefined) state.from = p.from ?? FILTER_DEFAULT_FROM;
    if (p.to !== undefined) state.to = p.to ?? FILTER_DEFAULT_TO;
    if (p.status !== undefined) state.status = p.status ?? FILTER_DEFAULT_STATUS;
    if (p.tab !== undefined) state.tab = p.tab ?? FILTER_DEFAULT_TAB;
    if (p.tablesTab !== undefined) state.tablesTab = p.tablesTab ?? FILTER_DEFAULT_TAB;
    if (p.floorplanTab !== undefined) state.floorplanTab = p.floorplanTab ?? FILTER_DEFAULT_TAB;
  },
  RESET(state: FilterState) {
    Object.assign(state, new FilterState());
  },
  RESET_FILTER(state: FilterState) {
    Object.assign(state, new FilterState());
  },
};

const actions = <ActionTree<IFilterState, IRootState>>{
  applyFilter(
    { state, commit, dispatch },
    p: { from?: string | null, to?: string | null, status?: ReservationStatus | null, tab?: Tab | null },
  ) {
    commit('SET_FILTER', p);
  },
  resetFilter({ state, commit, dispatch }) {
    commit('RESET_FILTER');
  },
  applyAllFilter({ state, commit, dispatch }) {
    commit('RESET_FILTER');
    commit('SET_FILTER', { status: ReservationStatus.All });
  },
};

const getters = <GetterTree<IFilterState, IRootState>>{
  filteredReservationsNoBlocks(state, localGetters, rootState, rootGetters): Reservation[] {
    const fhm = hourMinFromTimeText(state.from) ?? hourMinFromTimeText(FILTER_DEFAULT_FROM)!;
    const fromDate = setDateHoursMinutes(rootState.update.date, fhm.h, fhm.m);

    const thm = hourMinFromTimeText(state.to) ?? hourMinFromTimeText(FILTER_DEFAULT_TO)!;
    const toDate = setDateHoursMinutes(rootState.update.date, thm.h, thm.m);

    const { tab, status } = state;

    let invalid: ReservationStatus[];
    if (status === ReservationStatus.Valid) {
      invalid = rootGetters.isToday ? InvalidStatusesForToday : InvalidStatuses;
    }

    const dayReservationsNoBlocks = rootGetters.dayReservationsNoBlocks as Reservation[];
    const filteredReservations = dayReservationsNoBlocks.filter((r: Reservation) => {
      if (r.dateBegin < fromDate) return false;
      if (r.dateBegin > toDate) return false;
      if (tab && r.tab !== tab) return false;

      if (!r.status || status === ReservationStatus.All) return true;
      if (status !== ReservationStatus.Valid) return r.status === status;
      if (invalid.includes(r.status)) return false;

      return true;
    });

    console.log('filtered reservations:', filteredReservations);

    return filteredReservations;
  },
  isFilterFrom(state): boolean { return state.from !== FILTER_DEFAULT_FROM; },
  isFilterTo(state): boolean { return state.to !== FILTER_DEFAULT_TO; },
  isFilterStatus(state): boolean { return state.status !== FILTER_DEFAULT_STATUS; },
  isFilterTab(state): boolean { return state.tab !== FILTER_DEFAULT_TAB; },
  isFilter(state, localGetters, rootState, rootGetters): boolean {
    return !(
      state.from === FILTER_DEFAULT_FROM
      && state.to === FILTER_DEFAULT_TO
      && state.status === FILTER_DEFAULT_STATUS
      && state.tab === FILTER_DEFAULT_TAB
    );
  },
  isDayFilteredReservationNoBlock(state, localGetters) {
    return localGetters.filteredReservationsNoBlocks.length > 0;
  },
  filteredReservationsNoBlocksBySlot(state, localGetters) {
    const reservations: Reservation[] = localGetters.filteredReservationsNoBlocks;
    return toSortedMapBySlot(reservations);
  },
  filteredReservationsNoBlocksByTimeText(state, localGetters) {
    const mapByDateIndex = localGetters.filteredReservationsNoBlocksBySlot as Map<number, Reservation[]>;
    return mapBySlotToMapByTimeText(mapByDateIndex);
  },
};

const FilterStore = {
  namespaced: false,
  state: new FilterState(),
  mutations,
  actions,
  getters,
};

export default FilterStore;
