// source: feedback_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var empty_pb = require('./empty_pb.js');
goog.object.extend(proto, empty_pb);
var feedback_pb = require('./feedback_pb.js');
goog.object.extend(proto, feedback_pb);
var reservation_pb = require('./reservation_pb.js');
goog.object.extend(proto, reservation_pb);
var feedback_settings_pb = require('./feedback_settings_pb.js');
goog.object.extend(proto, feedback_settings_pb);
goog.exportSymbol('proto.grpc.api.v1.FeedbackFeedbackResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackGetFeedbackRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackGetReservationRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackListFeedbackResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackListFeedbacksParams', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackListFeedbacksRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackReservationResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackSetSettingsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.FeedbackSettingsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackGetFeedbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackGetFeedbackRequest.displayName = 'proto.grpc.api.v1.FeedbackGetFeedbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackFeedbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackFeedbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackFeedbackResponse.displayName = 'proto.grpc.api.v1.FeedbackFeedbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackListFeedbacksParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackListFeedbacksParams.displayName = 'proto.grpc.api.v1.FeedbackListFeedbacksParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackListFeedbacksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackListFeedbacksRequest.displayName = 'proto.grpc.api.v1.FeedbackListFeedbacksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackListFeedbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackListFeedbackResponse.displayName = 'proto.grpc.api.v1.FeedbackListFeedbackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackGetReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackGetReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackGetReservationRequest.displayName = 'proto.grpc.api.v1.FeedbackGetReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackReservationResponse.displayName = 'proto.grpc.api.v1.FeedbackReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackSetSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackSetSettingsRequest.displayName = 'proto.grpc.api.v1.FeedbackSetSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.FeedbackSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.FeedbackSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.FeedbackSettingsResponse.displayName = 'proto.grpc.api.v1.FeedbackSettingsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackGetFeedbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedbackId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackGetFeedbackRequest}
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackGetFeedbackRequest;
  return proto.grpc.api.v1.FeedbackGetFeedbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackGetFeedbackRequest}
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeedbackId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackGetFeedbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedbackId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 feedback_id = 1;
 * @return {number}
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.prototype.getFeedbackId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.FeedbackGetFeedbackRequest} returns this
 */
proto.grpc.api.v1.FeedbackGetFeedbackRequest.prototype.setFeedbackId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackFeedbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackFeedbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedback: (f = msg.getFeedback()) && feedback_pb.Feedback.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackFeedbackResponse}
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackFeedbackResponse;
  return proto.grpc.api.v1.FeedbackFeedbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackFeedbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackFeedbackResponse}
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new feedback_pb.Feedback;
      reader.readMessage(value,feedback_pb.Feedback.deserializeBinaryFromReader);
      msg.setFeedback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackFeedbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackFeedbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedback();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      feedback_pb.Feedback.serializeBinaryToWriter
    );
  }
};


/**
 * optional Feedback feedback = 1;
 * @return {?proto.grpc.api.v1.Feedback}
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.prototype.getFeedback = function() {
  return /** @type{?proto.grpc.api.v1.Feedback} */ (
    jspb.Message.getWrapperField(this, feedback_pb.Feedback, 1));
};


/**
 * @param {?proto.grpc.api.v1.Feedback|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackFeedbackResponse} returns this
*/
proto.grpc.api.v1.FeedbackFeedbackResponse.prototype.setFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackFeedbackResponse} returns this
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.prototype.clearFeedback = function() {
  return this.setFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackFeedbackResponse.prototype.hasFeedback = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackListFeedbacksParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    beginDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sinceDtCreate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksParams}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackListFeedbacksParams;
  return proto.grpc.api.v1.FeedbackListFeedbacksParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksParams}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeginDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSinceDtCreate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackListFeedbacksParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeginDate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSinceDtCreate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int32 begin_date = 1;
 * @return {number}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.getBeginDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksParams} returns this
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.setBeginDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end_date = 2;
 * @return {number}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksParams} returns this
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 since_dt_create = 3;
 * @return {number}
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.getSinceDtCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksParams} returns this
 */
proto.grpc.api.v1.FeedbackListFeedbacksParams.prototype.setSinceDtCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackListFeedbacksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.FeedbackListFeedbacksParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksRequest}
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackListFeedbacksRequest;
  return proto.grpc.api.v1.FeedbackListFeedbacksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksRequest}
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.FeedbackListFeedbacksParams;
      reader.readMessage(value,proto.grpc.api.v1.FeedbackListFeedbacksParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackListFeedbacksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.FeedbackListFeedbacksParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional FeedbackListFeedbacksParams params = 1;
 * @return {?proto.grpc.api.v1.FeedbackListFeedbacksParams}
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackListFeedbacksParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.FeedbackListFeedbacksParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackListFeedbacksParams|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} returns this
*/
proto.grpc.api.v1.FeedbackListFeedbacksRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbacksRequest} returns this
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackListFeedbacksRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackListFeedbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackListFeedbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedback: (f = msg.getFeedback()) && feedback_pb.Feedback.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbackResponse}
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackListFeedbackResponse;
  return proto.grpc.api.v1.FeedbackListFeedbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackListFeedbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbackResponse}
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new feedback_pb.Feedback;
      reader.readMessage(value,feedback_pb.Feedback.deserializeBinaryFromReader);
      msg.setFeedback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackListFeedbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackListFeedbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedback();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      feedback_pb.Feedback.serializeBinaryToWriter
    );
  }
};


/**
 * optional Feedback feedback = 1;
 * @return {?proto.grpc.api.v1.Feedback}
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.prototype.getFeedback = function() {
  return /** @type{?proto.grpc.api.v1.Feedback} */ (
    jspb.Message.getWrapperField(this, feedback_pb.Feedback, 1));
};


/**
 * @param {?proto.grpc.api.v1.Feedback|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackListFeedbackResponse} returns this
*/
proto.grpc.api.v1.FeedbackListFeedbackResponse.prototype.setFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackListFeedbackResponse} returns this
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.prototype.clearFeedback = function() {
  return this.setFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackListFeedbackResponse.prototype.hasFeedback = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackGetReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackGetReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedbackId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reservationId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackGetReservationRequest}
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackGetReservationRequest;
  return proto.grpc.api.v1.FeedbackGetReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackGetReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackGetReservationRequest}
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFeedbackId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReservationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackGetReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackGetReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedbackId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReservationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 feedback_id = 1;
 * @return {number}
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.prototype.getFeedbackId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.FeedbackGetReservationRequest} returns this
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.prototype.setFeedbackId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 reservation_id = 2;
 * @return {number}
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.prototype.getReservationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.FeedbackGetReservationRequest} returns this
 */
proto.grpc.api.v1.FeedbackGetReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservation: (f = msg.getReservation()) && reservation_pb.Reservation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackReservationResponse}
 */
proto.grpc.api.v1.FeedbackReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackReservationResponse;
  return proto.grpc.api.v1.FeedbackReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackReservationResponse}
 */
proto.grpc.api.v1.FeedbackReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new reservation_pb.Reservation;
      reader.readMessage(value,reservation_pb.Reservation.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      reservation_pb.Reservation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Reservation reservation = 1;
 * @return {?proto.grpc.api.v1.Reservation}
 */
proto.grpc.api.v1.FeedbackReservationResponse.prototype.getReservation = function() {
  return /** @type{?proto.grpc.api.v1.Reservation} */ (
    jspb.Message.getWrapperField(this, reservation_pb.Reservation, 1));
};


/**
 * @param {?proto.grpc.api.v1.Reservation|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackReservationResponse} returns this
*/
proto.grpc.api.v1.FeedbackReservationResponse.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackReservationResponse} returns this
 */
proto.grpc.api.v1.FeedbackReservationResponse.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackReservationResponse.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackSetSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackSetSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && feedback_settings_pb.FeedbackSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackSetSettingsRequest}
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackSetSettingsRequest;
  return proto.grpc.api.v1.FeedbackSetSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackSetSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackSetSettingsRequest}
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new feedback_settings_pb.FeedbackSettings;
      reader.readMessage(value,feedback_settings_pb.FeedbackSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackSetSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackSetSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      feedback_settings_pb.FeedbackSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional FeedbackSettings settings = 1;
 * @return {?proto.grpc.api.v1.FeedbackSettings}
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.prototype.getSettings = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackSettings} */ (
    jspb.Message.getWrapperField(this, feedback_settings_pb.FeedbackSettings, 1));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackSettings|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackSetSettingsRequest} returns this
*/
proto.grpc.api.v1.FeedbackSetSettingsRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackSetSettingsRequest} returns this
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackSetSettingsRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.FeedbackSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.FeedbackSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.FeedbackSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && feedback_settings_pb.FeedbackSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.FeedbackSettingsResponse}
 */
proto.grpc.api.v1.FeedbackSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.FeedbackSettingsResponse;
  return proto.grpc.api.v1.FeedbackSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.FeedbackSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.FeedbackSettingsResponse}
 */
proto.grpc.api.v1.FeedbackSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new feedback_settings_pb.FeedbackSettings;
      reader.readMessage(value,feedback_settings_pb.FeedbackSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.FeedbackSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.FeedbackSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.FeedbackSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.FeedbackSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      feedback_settings_pb.FeedbackSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional FeedbackSettings settings = 1;
 * @return {?proto.grpc.api.v1.FeedbackSettings}
 */
proto.grpc.api.v1.FeedbackSettingsResponse.prototype.getSettings = function() {
  return /** @type{?proto.grpc.api.v1.FeedbackSettings} */ (
    jspb.Message.getWrapperField(this, feedback_settings_pb.FeedbackSettings, 1));
};


/**
 * @param {?proto.grpc.api.v1.FeedbackSettings|undefined} value
 * @return {!proto.grpc.api.v1.FeedbackSettingsResponse} returns this
*/
proto.grpc.api.v1.FeedbackSettingsResponse.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.FeedbackSettingsResponse} returns this
 */
proto.grpc.api.v1.FeedbackSettingsResponse.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.FeedbackSettingsResponse.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.grpc.api.v1);
