
import Vue, { PropType } from 'vue';

import { TableSeatType, ITableSize } from './floorplan-types';

export default Vue.extend({
  name: 'FloorplanTablePreview',
  components: {

  },
  mixins: [],
  props: {
    seats: { type: Number, required: true },
    shape: { type: String as PropType<TableSeatType>, required: true },
  },
  data() {
    return {
      width: 110,
      height: 60,
    };
  },
  computed: {
    tableSize(): ITableSize {
      const scale = this.seats <= 4 ? 0.29 : 0.20;
      if (this.shape === TableSeatType.SQUARED) {
        if (this.seats <= 1) { return { w: 40 * scale, h: 40 * scale }; }
        if (this.seats <= 2) { return { w: 80 * scale, h: 80 * scale }; }
        return { w: 80 * scale, h: Math.min(300, (80 + Math.trunc((this.seats - 1) / 2.0) * 40.0)) * scale };
      }
      if (this.shape === TableSeatType.HEADSEAT) {
        if (this.seats <= 1) { return { w: 40 * scale, h: 40 * scale }; }
        if (this.seats <= 2) { return { w: 80 * scale, h: 80 * scale }; }
        return { w: 80 * scale, h: Math.min(300, (80 + Math.trunc((this.seats - 3) / 2.0) * 40.0)) * scale };
      }
      if (this.shape === TableSeatType.ROUNDED) {
        if (this.seats <= 1) { return { w: 40 * scale, h: 40 * scale }; }
        if (this.seats <= 2) { return { w: 60 * scale, h: 60 * scale }; }
        return {
          w: Math.min(300, (40 + Math.trunc((this.seats - 1) / 2.0) * 20.0)) * scale,
          h: Math.min(300, (40 + Math.trunc((this.seats - 1) / 2.0) * 20.0)) * scale,
        };
      }
      return { w: 0, h: 0 };
    },

    fillColor(): string {
      return this.$vuetify.theme.dark ? '#1E1E2B' : '#f9f9f9';
    },
    strokeColor(): string {
      return this.$vuetify.theme.dark ? '#D6D6D8' : '#171721';
    },
  },
});

