import * as pbm from '@/grpc-pb/editor_template_pb';
import * as pbs from '@/grpc-pb/contact_manager_service_pb';
import { strcmp } from '@/services/common';

export default class EditorTemplate {
  id: number = 0;

  name: string = '';

  design?: string;

  template?: string;

  created: Date = new Date();

  updated: Date = new Date();

  isDeleted?: boolean;

  order: number = 0;

  get value() { return this.id; }

  get text() { return this.name; }

  static fromGrpcResponse(r: pbs.EditorTemplateListResponse): EditorTemplate {
    const e = r.getEditortemplate();
    return EditorTemplate.fromGrpcModel(e);
  }

  static compare(o1: EditorTemplate, o2: EditorTemplate): number {
    const res = strcmp(o1.name ?? '', o2.name ?? '');
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  constructor(o?: { id?: number, name: string, design?: string, template?: string}) {
    this.id = o?.id ?? 0;
    this.name = o?.name ?? '';
    this.design = o?.design ?? '';
    this.template = o?.template ?? '';
  }

  static fromGrpcModel(o?: pbm.EditorTemplate): EditorTemplate {
    const e = new EditorTemplate();

    e.id = o?.getId() ?? 0;
    e.name = o?.getName() || 'N/A';
    e.design = o?.getDesign() ?? '';
    e.template = o?.getTemplate() ?? '';
    e.created = o?.getDtcreate() ? new Date(o.getDtcreate() * 1000) : new Date();
    e.updated = o?.getDtupdate() ? new Date(o.getDtupdate() * 1000) : new Date();
    e.isDeleted = o?.getIsdeleted();

    return e;
  }

  toGrpcModel(): pbm.EditorTemplate {
    const o = new pbm.EditorTemplate();

    o.setId(this.id);
    o.setName(this.name);
    if (this.design) o.setDesign(this.design);
    if (this.template) o.setTemplate(this.template);
    if (this.isDeleted) o.setIsdeleted(this.isDeleted);

    return o;
  }
}
