
import Vue, { PropType } from 'vue';
import Sortable from 'sortablejs';
import DialogClosable from '@/mixins/dialog-closable';
import { performSaveAction } from '@/services/vue-utils';
import { isModelEqualToModel, cloneModel } from '@/model/model-utils';
import DataTableInputDialog from '@/components/dialogs/DataTableInputDialog.vue';
import Tab from '@/model/Tab';
import TabItem, { suggestedTabItem } from '@/model/TabItem';

export default Vue.extend({
  name: 'TablesDialog',
  components: { DataTableInputDialog },
  mixins: [DialogClosable],
  props: {
    tab: { type: Object as PropType<Tab>, required: true },
  },
  data() {
    return {
      tables: [] as TabItem[],
      oldTables: [] as TabItem[],
      headers: [
        {
          text: '',
          width: '32',
          value: 'id',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.name'),
          value: 'name',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.seats'),
          width: '80',
          value: 'seats',
          align: 'start',
          sortable: false,
        },
        {
          text: '',
          width: '32',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      seats: [...new Array(99)].map((e, i) => (i + 1)),
    };
  },
  computed: {
    isChanged(): boolean {
      return !isModelEqualToModel(this.tables, this.oldTables);
    },
  },
  watch: {
    visible() {
      this.reset();
    },
  },
  mounted() {
  },
  methods: {
    changeOrder(event: Sortable.SortableEvent) {
      if (event.oldIndex === undefined || event.newIndex === undefined) return;
      const movedItem = this.tables.splice(event.oldIndex, 1)[0];
      this.tables.splice(event.newIndex, 0, movedItem);
    },
    reset() {
      const tab = this.$tstore.getters.tabsById.get(this.tab.id) as Tab | undefined;
      this.tables = (tab?.tabItems ?? []).map((o :TabItem) => cloneModel(o));
      this.oldTables = tab?.tabItems ?? [];
    },
    add() {
      const table = suggestedTabItem(this.tables);
      this.tables.push(table);

      (this.$refs.dialog as Vue & { scrollToBottom: ()=>void }).scrollToBottom();
    },
    remove(id: number) {
      this.tables = this.tables.filter((o) => o.id !== id);
    },
    close() {
      this.$emit('close');
    },
    cancel() {
      this.close();
    },
    async save() {
      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch('sendTabItems', {
          entities: this.tables,
          tabId: this.tab.id,
        }),
      );

      if (ok) {
        this.close();
      }
    },
  },
});
