/* eslint-disable indent */
import Reservation, { ReservationType } from '@/model/Reservation';
import {
  setDateHoursMinutes, hourMinFromTimeText, dateByAddingDays,
  minutesFromDate, slotFromMinutes, isDateAfterDate,
} from '@/services/time-utils';
import { MIN_BEGIN_TIME, MAX_BEGIN_TIME, MAX_END_TIME } from '@/services/configuration';
import TabItem from '@/model/TabItem';
import i18n from '@/plugins/i18n';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import Tab from '@/model/Tab';
import TimeSlot from '@/model/TimeSlot';

export enum ReservationErrorType {
  TabMissing,
  TabItemsMissing,
  BlockTabItemsMissing,
  // TabItemsNoCapacity,
  TabItemsOverbooked,
  PartySizeMissing,
  PartySizeHigh,
  DateBeginMissing,
  DateBeginLow,
  DateBeginHigh,
  DateEndMissing,
  DateEndLow,
  DateEndHigh,
  NameMissing,
  PhoneMissing,
  EmailMissing,

  AvailabilityMaxGuestsExceeded,
  AvailabilityMaxReservationsExceeded,
  AvailabilityOutsideHours,
  AvailabilityNoCapacity,
  AvailabilityNoAllocation,
}

export const Warnings = [
  ReservationErrorType.TabItemsMissing,
  ReservationErrorType.PartySizeHigh,
  ReservationErrorType.TabItemsOverbooked,
  ReservationErrorType.NameMissing,
  ReservationErrorType.PhoneMissing,
  ReservationErrorType.EmailMissing,

  ReservationErrorType.AvailabilityMaxGuestsExceeded,
  ReservationErrorType.AvailabilityMaxReservationsExceeded,
  ReservationErrorType.AvailabilityOutsideHours,
  ReservationErrorType.AvailabilityNoCapacity,
  ReservationErrorType.AvailabilityNoAllocation,
];

export const PartySizeErrors = [
  ReservationErrorType.PartySizeHigh,
];

export const TabErrors = [
  ReservationErrorType.TabMissing, ReservationErrorType.AvailabilityNoCapacity];

export const TabItemsErrors = [
  ReservationErrorType.BlockTabItemsMissing, ReservationErrorType.AvailabilityNoAllocation,
  ReservationErrorType.TabItemsOverbooked,
];

export const DateErrors = [];

export const DateBeginErrors = [
  ReservationErrorType.DateBeginMissing, ReservationErrorType.DateBeginLow, ReservationErrorType.DateBeginHigh,
  ReservationErrorType.AvailabilityMaxGuestsExceeded, ReservationErrorType.AvailabilityMaxReservationsExceeded,
  ReservationErrorType.AvailabilityOutsideHours,
];

export const DateEndErrors = [
  ReservationErrorType.DateEndMissing, ReservationErrorType.DateEndLow, ReservationErrorType.DateEndHigh,
];

export const NameErrors = [
  ReservationErrorType.NameMissing,
];

export const PhoneErrors = [
  ReservationErrorType.PhoneMissing,
];

export const EmailErrors = [
  ReservationErrorType.EmailMissing,
];

export interface ReservationError {
  type: ReservationErrorType,
  params?: Array<any>,
}

export function reservationErrorText(error: ReservationError) {
  const params = (error.params ?? []).map((param) => {
    if (param instanceof Date) return localized.hourMinText(param) ?? 'N/A';
    return param;
  });

  switch (error.type) {
    case ReservationErrorType.TabMissing: return i18n.t('label.select_section', params);
    case ReservationErrorType.TabItemsMissing: return i18n.t('error.reservation_no_tab_items_selected', params);
    case ReservationErrorType.BlockTabItemsMissing: return i18n.t('error.reservation_no_tab_items_selected', params);
    // case ReservationErrorType.TabItemsNoCapacity: return i18n.t('error.party_size_too_large', params);
    case ReservationErrorType.TabItemsOverbooked: return i18n.t('error.reservation_table_overbooked', params);
    case ReservationErrorType.PartySizeMissing: return i18n.t('error.reservation_no_party_size_selected', params);
    case ReservationErrorType.PartySizeHigh: return i18n.t('error.party_size_too_large', params);

    case ReservationErrorType.DateBeginMissing: return i18n.t('error.reservation_no_start_time_selected', params);
    case ReservationErrorType.DateBeginLow:
      return i18n.t('error.reservation_invalid_start_time_cannot_start_before_x', params);
    case ReservationErrorType.DateBeginHigh:
      return i18n.t('error.reservation_invalid_start_time_cannot_start_after_x', params);

    case ReservationErrorType.DateEndMissing: return i18n.t('error.reservation_no_ending_time_selected', params);
    case ReservationErrorType.DateEndLow:
      return i18n.t('error.reservation_invalid_end_time_cannot_end_before_x', params);
    case ReservationErrorType.DateEndHigh:
      return i18n.t('error.reservation_invalid_end_time_cannot_end_after_x', params);

    case ReservationErrorType.NameMissing: return i18n.t('error.reservation_no_name_selected', params);
    case ReservationErrorType.PhoneMissing: return i18n.t('error.reservation_no_phone_selected', params);
    case ReservationErrorType.EmailMissing: return i18n.t('error.reservation_no_email_selected', params);

    case ReservationErrorType.AvailabilityMaxGuestsExceeded:
      return i18n.t('error.reservation_max_guests_exceeded', params);
    case ReservationErrorType.AvailabilityMaxReservationsExceeded:
      return i18n.t('error.reservation_max_reservations_exceeded', params);
    case ReservationErrorType.AvailabilityOutsideHours: return i18n.t('error.reservation_outside_hours', params);
    case ReservationErrorType.AvailabilityNoCapacity: return i18n.t('error.reservation_no_capacity', params);
    case ReservationErrorType.AvailabilityNoAllocation: return i18n.t('error.reservation_no_allocation', params);

    default: return null;
  }
}

export function isReservationWarnings(errors: ReservationError[]) {
  return errors.find((e) => Warnings.includes(e.type)) !== undefined;
}

export function isReservationErrors(errors: ReservationError[]) {
  return errors.find((e) => !Warnings.includes(e.type)) !== undefined;
}

export function reservationErrorTexts(types: ReservationErrorType[], errors: ReservationError[]) {
  const texts: Array<string> = [];

  errors.forEach((e) => {
    if (!types.includes(e.type)) return;
    const text = reservationErrorText(e);
    texts.push(text as string);
  });

  return texts;
}

// export function reservationErrorsText(types: ReservationErrorType[], errors: Set<ReservationError>) {
//   const texts: Array<string> = [];

//   errors.forEach((e) => {
//     if (!types.includes(e.type)) return;
//     const text = reservationErrorText(e);
//     texts.push(text as string);
//   });

//   return texts.length > 0 ? texts.join('\n') : null;
// }

export function validateReservation(r: Reservation): ReservationError[] {
  const errors: ReservationError[] = [];

  // tab
  if (r.tab === undefined) errors.push({ type: ReservationErrorType.TabMissing });

  // party size
  if (r.partySize === undefined) errors.push({ type: ReservationErrorType.PartySizeMissing });

  // tab items
  if (r.isBlock && r.tabItems.length === 0) {
    errors.push({ type: ReservationErrorType.BlockTabItemsMissing });
  }

  // dates
  const minbt = hourMinFromTimeText(MIN_BEGIN_TIME)!;
  const maxbt = hourMinFromTimeText(MAX_BEGIN_TIME)!;
  const maxet = hourMinFromTimeText(MAX_END_TIME)!;

  const minbd = setDateHoursMinutes(r.dateBegin, minbt.h, minbt.m);
  const maxbd = setDateHoursMinutes(r.dateBegin, maxbt.h, maxbt.m);
  // const mined = isDateAfterDate(r.dateBegin, minbd) ? r.dateBegin : minbd;
  const maxed = dateByAddingDays(setDateHoursMinutes(r.dateBegin, maxet.h, maxet.m), 1);

  if (isDateAfterDate(minbd, r.dateBegin)) errors.push({ type: ReservationErrorType.DateBeginLow, params: [minbd] });
  if (isDateAfterDate(r.dateBegin, maxbd)) errors.push({ type: ReservationErrorType.DateBeginHigh, params: [maxbd] });
  // if (isDateAfterDate(mined, r.dateEnd)) errors.push({ type: ReservationErrorType.DateEndLow, params: [mined] });
  if (isDateAfterDate(r.dateEnd, maxed)) errors.push({ type: ReservationErrorType.DateEndHigh, params: [maxed] });

  // tab items capacity
  if (!r.isBlock && r.tabItems.length !== 0 && r.partySize > r.seats) {
    errors.push({ type: ReservationErrorType.PartySizeHigh, params: [r.seats] });
  }

  // contact for new reservation
  if (!r.isBlock && r.bookingType !== ReservationType.Walkin && r.isNew) {
    if (!r.contact.name) errors.push({ type: ReservationErrorType.NameMissing });
    if (!r.contact.phone) errors.push({ type: ReservationErrorType.PhoneMissing });
    if (!r.contact.email) errors.push({ type: ReservationErrorType.EmailMissing });
  }

  return errors;
}

export function validateReservationAvailability(
  reservation: Reservation,
  timeSlot: TimeSlot,
  reservations: Reservation[],
  reservationsDuringSlot: Map<number, Reservation[]>,
  p?: { defaultMaxGuestsPerTime: number, defaultMaxReservationsPerTime: number },
)
  : ReservationError[] {
  const errors: ReservationError[] = [];

  // time slot open and max guests per time
  if (!timeSlot.isOpen) {
    errors.push({ type: ReservationErrorType.AvailabilityOutsideHours });
  } else {
    const maximumGuests = timeSlot.maximumGuests || p?.defaultMaxGuestsPerTime;
    const maximumReservations = timeSlot.maximumReservations || p?.defaultMaxReservationsPerTime;

    if (maximumGuests || maximumReservations) { // must be nonzero
      let totalGuests = 0;
      let totalReservations = 0;
      reservations.forEach((r) => {
        if (r.slotBegin === reservation.slotBegin && r.id !== reservation.id) {
          totalGuests += r.partySize;
          totalReservations += 1;
        }
      });

      totalGuests += reservation.partySize;
      totalReservations += 1;

      if (maximumGuests && totalGuests > maximumGuests) {
        errors.push({
          type: ReservationErrorType.AvailabilityMaxGuestsExceeded,
          params: [totalGuests, maximumGuests],
        });
      }

      if (maximumReservations && totalReservations > maximumReservations) {
        errors.push({
          type: ReservationErrorType.AvailabilityMaxReservationsExceeded,
          params: [totalReservations, maximumReservations],
        });
      }
    }
  }

  if (reservation.tab && reservation.tab.usingWeekdaysAndTimes && reservation.tab.capacity > 0) {
    for (let si = reservation.slotBegin; si <= reservation.slotEnd; si += 1) {
      const srs = reservationsDuringSlot.get(si) ?? []; // get reservations for slot

      let guests = (srs.reduce((sum, sr) => {
        if (sr.id === reservation.id || sr.tab?.id !== reservation.tab?.id || sr.slotEnd <= si) return sum;
        return sum + sr.partySize;
      }, 0));
      guests += reservation.partySize;
      if (guests > reservation.tab.capacity) {
        errors.push({
          type: ReservationErrorType.AvailabilityNoCapacity,
          params: [guests, reservation.tab.capacity],
        });
        break;
      }
    }
  }

  return errors;
}

export function toReservationsByTabItemAndSlot(reservations: Array<Reservation>): Map<number, Array<Reservation>> {
  const map = new Map<number, Array<Reservation>>();
  reservations.forEach((r) => {
    r.tabItems.forEach((ti) => {
      for (let si = r.slotBegin; si <= r.capSlotEnd; si += 1) {
        const key = si + ti.id * 1000;
        map.set(key, (map.get(key) ?? []).concat(r));
      }
    });
  });
  return map;
}

export function overbookedTabItemsForReservation(r: Reservation, map: Map<number, Reservation[]>): TabItem[] {
  const tabItems: TabItem[] = [];
  r.tabItems.forEach((ti) => {
    for (let si = r.slotBegin; si <= r.capSlotEnd; si += 1) {
      const srs = map.get(si + ti.id * 1000); // get reservations for slot and tab item
      if (srs && srs.find((sr) => (sr.id !== r.id && sr.capTimeEnd > r.timeBegin && sr.timeBegin < r.capTimeEnd))) {
        tabItems.push(ti);
        break;
      }
    }
  });

  return tabItems;
}

export function overbookedReservationsForReservation(r: Reservation, map: Map<number, Reservation[]>): Reservation[] {
  const reservations = new Set<Reservation>();

  r.tabItems.forEach((ti) => {
    for (let si = r.slotBegin; si <= r.capSlotEnd; si += 1) {
      const srs = map.get(si + ti.id * 1000); // get reservations for slot and tab item
      if (srs) {
        const osrs = srs.filter((sr) => (sr.id !== r.id && sr.capTimeEnd > r.timeBegin && sr.timeBegin < r.capTimeEnd));
        osrs.forEach((osr) => { reservations.add(osr); });
      }
    }
  });

  return Array.from(reservations);
}

export function overbookedReservationsAndTabItemsForReservationId(reservations: Reservation[]) {
  const rmap = new Map<number, Set<number>>();
  const timap = new Map<number, Set<number>>();

  toReservationsByTabItemAndSlot(reservations).forEach((rs, key) => {
    if (rs.length <= 1) return;
    for (let i = 0; i < rs.length - 1; i += 1) {
      const r = rs[i];
      for (let j = i + 1; j < rs.length; j += 1) {
        const sr = rs[j];
        if (sr.capTimeEnd > r.timeBegin && sr.timeBegin < r.capTimeEnd) {
          // r reservation overbooked reservation ids
          let set = rmap.get(r.id);
          if (!set) { set = new Set<number>(); rmap.set(r.id, set); }
          set.add(sr.id);

          // sr reservation overbooked reservation ids
          set = rmap.get(sr.id);
          if (!set) { set = new Set<number>(); rmap.set(sr.id, set); }
          set.add(r.id);

          // r reservation overbooked tab item ids
          set = timap.get(r.id);
          if (!set) { set = new Set<number>(); timap.set(r.id, set); }
          set.add(Math.floor(key / 1000));

          // sr reservation overbooked tab item ids
          set = timap.get(sr.id);
          if (!set) { set = new Set<number>(); timap.set(sr.id, set); }
          set.add(Math.floor(key / 1000));
        }
      }
    }
  });

  return {
    overbookedReservationsForReservationId: rmap,
    overbookedTabItemsForReservationId: timap,
  };
}

export function toReservationsDuringSlot(reservations: Array<Reservation>): Map<number, Array<Reservation>> {
  const map = new Map<number, Array<Reservation>>();
  reservations.forEach((r) => {
    for (let si = r.slotBegin; si <= r.capSlotEnd; si += 1) {
      const key = si;
      map.set(key, (map.get(key) ?? []).concat(r));
    }
  });
  return map;
}

export function unavailableTabItemsForReservation(r: Reservation, map: Map<number, Reservation[]>): TabItem[] {
  // const timeBegin = minutesFromDate(r.dateBegin);
  // const timeEnd = minutesFromDate(r.dateEnd);
  // const slotBegin = slotFromMinutes(timeBegin);
  // const slotEnd = slotFromMinutes(timeEnd - 1);

  const tabItems = new Set<TabItem>();
  for (let si = r.slotBegin; si <= r.capSlotEnd; si += 1) {
    const srs = map.get(si); // get reservations for slot and tab item
    // eslint-disable-next-line no-continue
    if (!srs) continue;

    srs.forEach((sr) => {
      if (sr.id !== r.id) {
        sr.tabItems.forEach((ti) => {
          tabItems.add(ti);
        });
      }
    });
  }

  return Array.from(tabItems);
}
