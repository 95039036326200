/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/giftcard_pb';

export default class GiftcardRecipient {
  name?: string;

  email?: string;

  dtDelivery?: Date;

  giftcardMessage?: string;

  static fromGrpcModel(o?: pbm.GiftcardRecipient, m?: any): GiftcardRecipient {
    const p = new GiftcardRecipient();

    p.email = o?.getEmail();
    p.name = o?.getName();
    p.dtDelivery = o?.getDtDelivery() ? new Date(o.getDtDelivery() * 1000) : undefined;
    p.giftcardMessage = o?.getGiftcardMessage();

    return p;
  }
}
