import { niceTimeTextFromSlot, timeIndexFromSlot } from '@/services/time-utils';
import DateAndTime from './DateAndTime';
import OpeningHour from './OpeningHour';
import HourSlot from './HourSlot';

export default class TimeSlot extends HourSlot {
  dateAndTimeIndex: number;

  slot: number;

  dateAndTime?: DateAndTime;

  openingHour?: OpeningHour;

  constructor(slot: number, dateAndTimeIndex: number, dateAndTime?: DateAndTime, openingHour?: OpeningHour) {
    super({});
    this.slot = slot;
    this.dateAndTimeIndex = dateAndTimeIndex;
    this.dateAndTime = dateAndTime;
    this.openingHour = openingHour;

    this.updateHourSlots();
  }

  get time(): string { return niceTimeTextFromSlot(this.slot); }

  get timeIndex(): number { return timeIndexFromSlot(this.slot); }

  // get isOpen(): boolean {
  //   return this.dateAndTime?.isOpen
  //     || (this.openingHour !== undefined && this.dateAndTime?.isOpen !== false);
  // }

  get isSpecial(): boolean {
    if (this.dateAndTime === undefined) return false;

    const ohOpen = this.openingHour !== undefined;
    const shOpen = this.dateAndTime.isOpen !== false;
    return ohOpen !== shOpen;
  }

  get hour(): HourSlot|undefined { return this.dateAndTime ?? this.openingHour; }

  get isOpen(): boolean {
    if (this.dateAndTime) return this.dateAndTime.isOpen === true;
    return this.openingHour !== undefined;
  }

  updateHourSlots() {
    this.minimumPartySize = this.hour?.minimumPartySize;
    this.maximumPartySize = this.hour?.maximumPartySize;
    this.maximumGuests = this.hour?.maximumGuests;
    this.maximumReservations = this.hour?.maximumReservations;
    this.fixedEndingInMinutes = this.hour?.fixedEndingInMinutes;
  }
}
