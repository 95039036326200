

import Vue, { PropType } from 'vue';
import Reservation from '@/model/Reservation';
import { mapActions } from 'vuex';
import { tryEditReservation, tryCloseEditReservation } from '@/services/reservation-edit-utils';
import { isOngoingReservation } from '@/services/reservation-utils';
import GuestListRow from './GuestListRow.vue';
import GuestListHeaderRow from './GuestListHeaderRow.vue';

export default Vue.extend({
  name: 'GuestList',
  components: { GuestListRow, GuestListHeaderRow },
  props: {
    reservationsByTime: { type: Map as PropType<Map<string, Reservation[]>>, required: true },
  },
  data: () => ({
    selected: undefined as undefined | number,
  }),
  computed: {
    edited(): number | undefined {
      return this.$tstore.getters.editReservation?.id;
    },
    waitingListOrderForReservation(): Map<number, number> {
      const orderForWaitingReservation = this.$tstore.getters.orderForWaitingReservation as Map<number, number>;
      return orderForWaitingReservation;
    },
    overbookedTabItemsForReservation(): Map<number, Set<number>|undefined> {
      return this.$tstore.getters.dayOverbookedTabItemsForReservationId;
    },
  },
  watch: {
    edited() {
      this.selected = this.edited;
    },
  },
  mounted() {
    this.selected = this.$tstore.getters.editReservation?.id;
  },
  methods: {
    async selectedChange(reservationId: number | undefined) {
      this.selected = reservationId;

      const { reservationsById } = this.$tstore.state.reservations;
      const reservation = reservationId ? reservationsById[reservationId] : undefined;
      if (reservation === undefined) await tryCloseEditReservation();
      else {
        await tryEditReservation(this.$router, reservation);
      }
    },
  },
});

