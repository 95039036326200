
import Vue, { PropType } from 'vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import PasswordTextField from '@/components/forms/PasswordTextField.vue';
import { performAction } from '@/services/vue-utils';
import DialogClosable from '@/mixins/dialog-closable';
import { ValidationObserver } from 'vee-validate';
import toast from '@/plugins/toast-plugin/toast';

export default Vue.extend({
  name: 'ChangePasswordDialog',
  components: { ConfirmationDialog, PasswordTextField },
  mixins: [DialogClosable],
  props: {
    username: { type: String as PropType<string>, required: true },
  },
  data() {
    return {
      password: '',
      newPassword: '',
      confirmPassword: '',
      resetConfirmation: false,
    };
  },
  computed: { },
  watch: { },
  methods: {
    reset() {
      (this.$refs.form as Vue & { reset: () => void }).reset();
      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
    },
    async save() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        toast.error(this.$i18n.tc('error.validation_failed'));
        return;
      }

      const ok = await performAction(
        this.$i18n.tc('message.your_password_has_been_updated'),
        this.$i18n.tc('error.changing_password'),
        async () => this.$tstore.dispatch('changePassword', {
          username: this.username,
          password: this.password,
          newPassword: this.newPassword,
        }),
      );
      if (ok) {
        this.reset();
        this.$emit('close');
      }
    },
    cancel() {
      this.reset();
      this.$emit('close');
    },
    async resetPassword() {
      const ok = await performAction(
        this.$i18n.tc('message.success_new_password_sent'),
        this.$i18n.tc('error.changing_password'),
        async () => this.$tstore.dispatch('resetPassword', {
          username: this.username,
          password: this.password,
          newPassword: this.newPassword,
        }),
      );

      if (ok) {
        this.resetConfirmation = false;
      }
    },
  },
});
