// eslint-disable-next-line max-classes-per-file
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import httpClient, { IHttpClientState } from '@/api/http-client';
import grpcClient, { IGrpcClientState } from '@/grpc-api/grpc-client';
import Reservation from '@/model/Reservation';
import SessionStore from './session-store';
import UpdateStore from './update-store';
import ReservationsStore from './reservations-store';
import CodesStore from './codes-store';
import SettingsStore from './settings-store';
import AutoUpdateStore from './autoupdate-store';
import FilterStore from './filter-store';
import IStoreState from './store-state';
import {
  SearchStore, NameStore, PhoneStore, EmailStore,
} from './search-store';
import ReservationStore from './reservation-store';
import ReservationSuggestionStore from './reservation-suggestions-store';
import DragDropStore from './drag-drop-store';
import DashboardStore from './dashboard-store';
import BillingStore from './billing-store';
import ReportsStore from './reports-store';
import PushUpdateStore from './pushupdate-store';
import MessageCenterStore from './message-center-store';
import AllocationsStore from './allocations-store';
import GuestbookStore from './guestbook-store';
import CampaignFilterStore from './campaign-filter-store';
import ServiceManagerStore from './service-manager-store';
import GiftcardsStore from './giftcards-store';
import FeedbackStore from './feedback-store';

Vue.use(Vuex);

const store = new Vuex.Store<IStoreState>(
  {
    // strict: (process.env.NODE_ENV === 'development'),
    modules: {
      session: SessionStore,
      update: UpdateStore,
      reservations: ReservationsStore,
      codes: CodesStore,
      settings: SettingsStore,
      autoUpdate: AutoUpdateStore,
      pushUpdate: PushUpdateStore,
      filter: FilterStore,
      search: SearchStore,
      nameSearch: NameStore,
      phoneSearch: PhoneStore,
      emailSearch: EmailStore,
      reservation: ReservationStore,
      reservationSuggestions: ReservationSuggestionStore,
      dragDrop: DragDropStore,

      messageCenter: MessageCenterStore,
      allocations: AllocationsStore,
      guestbook: GuestbookStore,
      serviceManager: ServiceManagerStore,
      campaignFilter: CampaignFilterStore,
      billing: BillingStore,
      dashboard: DashboardStore,
      reports: ReportsStore,
      giftcards: GiftcardsStore,
      feedback: FeedbackStore,
    },
  } as StoreOptions<IStoreState>,
);

// force http client to read and write session data through store
httpClient.state = new class implements IHttpClientState {
  // eslint-disable-next-line class-methods-use-this
  get store() { return store; }

  get token() { return this.store.state.session.token ?? undefined; }

  set token(token: string | undefined) { this.store.commit('SET_TOKEN', token); }

  get unixTimestamp() { return this.store.state.session.unixTimestamp ?? undefined; }

  set unixTimestamp(uts: string | undefined) { this.store.commit('SET_TIMESTAMP', { unixTimestamp: uts ?? null }); }

  get timestamp() { return this.store.state.session.timestamp ?? undefined; }

  set timestamp(ts: string | undefined) { this.store.commit('SET_TIMESTAMP', { timestamp: ts ?? null }); }

  get accountID() { return this.store.state.settings.account.id || undefined; }

  get fcmToken() { return this.store.state.session.fcmToken || undefined; }
}();

// force http client to read and write session data through store
grpcClient.state = new class implements IGrpcClientState {
  // eslint-disable-next-line class-methods-use-this
  get store() { return store; }

  get token() { return this.store.state.session.token ?? undefined; }

  set token(token: string | undefined) { this.store.commit('SET_TOKEN', token); }
}();

export default store;

export { DashboardAllTabs, DashboardOnlineTabs } from './dashboard-store';

// watch for edited reservation changes
store.watch<Reservation|null>(
  (state) => state.reservation.editedReservation,
  (reservation) => { store.dispatch('suggest', { reservation }); },
  { deep: true },
);
