/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {
  dateFromDateAndTimeIndex, dateIndexFromDate,
} from '@/services/time-utils';
import Tab from '@/model/Tab';
import i18n from '@/plugins/i18n';
import grpcClient from '@/grpc-api/grpc-client';
import {
  CampaignReports, GuestsReports, FeedbackReports, TabsReportsResponse, ReportsParams, ServiceReports, MsgReports, MsgsReportsResponse, TabReports, WeekdayReports,
} from '@/grpc-pb/reports_service_pb';
import { toMapByField } from '@/model/model-utils';
import { DateRange, DateRangePeriod, dateRangeFromPeriod } from '@/services/date-range';
import {
  reportsBinTypeFromDateRange, reportsForTabs, reportsListsSums,
  reportsDatesForSlots,
  reportsZipLists,
  reportsListPercentages,
  reportsListPercentagesTotal,
  reportsListSum,
  reportsListAveragesTotal,
  ChartData,
  PieChartData,
  reportsListAverages,
  CategoryType,
  reportsCategoryFromReportsParams,
  reportsForMsgTypes,
  reportsListsAveragesNoNeg,
  reportsListsAveragesTotalNoNeg,
} from '@/services/reports-utils';
import { ReportsType } from '@/services/reports';
import ReportsCache from '@/services/reports-cache';
import { EmailMessageTypes, SmsMessageTypes } from '@/services/messages';
import IRootState, { IReportsState } from './store-state';

export const ReportsAllTabs = new Tab({ tabId: -1, tabName: i18n.tc('label.all_sections') });
export const ReportsOnlineTabs = new Tab({ tabId: -2, tabName: i18n.tc('label.online_sections') });

export class ReportsState implements IReportsState {
  reportsType: ReportsType = ReportsType.All;

  dateRange: DateRange = dateRangeFromPeriod(DateRangePeriod.ThisMonth);

  tab: Tab = ReportsAllTabs;

  byDtCreate: boolean = false;

  campaignName: string | null = null;

  isLoaded: boolean = false;

  tabReports: Map<number, TabReports.AsObject> | null = null;

  previousTabReports: Map<number, TabReports.AsObject> | null = null;

  msgReports: Map<string, MsgReports.AsObject> | null = null;

  tabReportsParams: ReportsParams.AsObject | null = null;

  previousTabReportsParams: ReportsParams.AsObject | null = null;

  msgReportsParams: ReportsParams.AsObject | null = null;
}

const mutations = <MutationTree<IReportsState>>{
  RESET(state: ReportsState) {
    Object.assign(state, new ReportsState());
  },
  RESET_REPORTS(state: ReportsState) {
    Object.assign(state, new ReportsState());
  },
  UPDATE_REPORTS(state: ReportsState, p: {
    reportsType?: ReportsType, dateRange?: DateRange,
    tab?: Tab, byDtCreate?: boolean, campaignName?: string,
    isLoaded?: boolean
  }) {
    if (p.reportsType !== undefined) state.reportsType = p.reportsType;
    if (p.dateRange !== undefined) state.dateRange = p.dateRange;
    if (p.tab !== undefined) state.tab = p.tab;
    if (p.byDtCreate !== undefined) state.byDtCreate = p.byDtCreate;
    if (p.campaignName !== undefined) state.campaignName = p.campaignName;
    if (p.isLoaded !== undefined) state.isLoaded = p.isLoaded;
    console.log('UPDATE_REPORTS: ', state.tab, state.byDtCreate, state.campaignName, state.dateRange, state.isLoaded);
  },
  UPDATE_REPORTS_TABS_MSGS(state: ReportsState, p: {
    tabReports?: Map<number, TabReports.AsObject>,
    previousTabReports?: Map<number, TabReports.AsObject>,
    msgReports?: Map<string, MsgReports.AsObject>,
    tabReportsParams?: ReportsParams.AsObject,
    previousTabReportsParams?: ReportsParams.AsObject,
    msgReportsParams?: ReportsParams.AsObject,
  }) {
    if (p.tabReports !== undefined) state.tabReports = p.tabReports;
    if (p.previousTabReports !== undefined) state.previousTabReports = p.previousTabReports;
    if (p.msgReports !== undefined) state.msgReports = p.msgReports;
    if (p.tabReportsParams !== undefined) state.tabReportsParams = p.tabReportsParams;
    if (p.previousTabReportsParams !== undefined) state.previousTabReportsParams = p.previousTabReportsParams;
    if (p.msgReportsParams !== undefined) state.msgReportsParams = p.msgReportsParams;
    console.log('UPDATE_REPORTS_TABS_MSGS: ', state.tabReportsParams, state.previousTabReportsParams, state.msgReportsParams);
  },
};

const actions = <ActionTree<IReportsState, IRootState>>{
  async resetReports({ commit }) {
    commit('RESET_REPORTS');
  },
  async updateReports({ state, commit, dispatch }, p: { reportsType?: ReportsType, dateRange?: DateRange, tab?: Tab, byDtCreate?: boolean, campaignName?: string }) {
    const {
      reportsType, dateRange, tab, byDtCreate, campaignName,
    } = { ...p };

    // save changes
    commit('UPDATE_REPORTS', {
      reportsType, dateRange, tab, byDtCreate, campaignName,
    });

    if (!state.reportsType || !state.dateRange) return;

    // fetch params
    const {
      beginDate, endDate, previousBeginDate, previousEndDate,
    } = state.dateRange;

    const bdi = dateIndexFromDate(beginDate);
    const edi = dateIndexFromDate(endDate);
    const pbdi = previousBeginDate ? dateIndexFromDate(previousBeginDate) : undefined;
    const pedi = previousEndDate ? dateIndexFromDate(previousEndDate) : undefined;
    const bt = reportsBinTypeFromDateRange(state.dateRange);

    // find data in cache
    let isLoaded = false;
    let tabReports: Map<number, TabReports.AsObject> | null = null;
    let previousTabReports: Map<number, TabReports.AsObject> | null = null;
    let msgReports: Map<string, MsgReports.AsObject> | null = null;
    let tabReportsParams: ReportsParams.AsObject | null = null;
    let previousTabReportsParams: ReportsParams.AsObject | null = null;
    let msgReportsParams: ReportsParams.AsObject | null = null;

    if (state.reportsType === ReportsType.Messages) {
      // msg reports
      const entry = ReportsCache.findMsgReports(bdi, edi, bt, false);
      if (entry) {
        msgReports = entry.reports;
        msgReportsParams = entry.params;
        isLoaded = true;
      }
    } else {
      const exact = true; // state.reportsType === ReportsType.Weekdays;

      // tab reports
      const entry = ReportsCache.findTabReports(bdi, edi, bt, exact);
      if (entry) {
        tabReports = entry.reports;
        tabReportsParams = entry.params;
        isLoaded = true;
      }

      // previous tab reports
      if ([ReportsType.Reservations, ReportsType.Feedback].includes(state.reportsType) && pbdi && pedi) {
        const pentry = ReportsCache.findTabReports(pbdi, pedi, bt, exact);
        if (pentry) {
          previousTabReports = pentry.reports;
          previousTabReportsParams = pentry.params;
        } else {
          isLoaded = false;
        }
      }
    }

    commit('UPDATE_REPORTS_TABS_MSGS', {
      tabReports,
      previousTabReports,
      msgReports,
      tabReportsParams,
      previousTabReportsParams,
      msgReportsParams,
    });

    commit('UPDATE_REPORTS', { isLoaded });

    if (!isLoaded) await dispatch('loadReports');
  },
  async loadReports({
    state, commit, dispatch, rootGetters,
  }) {
    if (!rootGetters.isLoaded) return;

    // fetch params
    const {
      beginDate, endDate, previousBeginDate, previousEndDate,
    } = state.dateRange;

    const bdi = dateIndexFromDate(beginDate);
    const edi = dateIndexFromDate(endDate);
    const pbdi = previousBeginDate ? dateIndexFromDate(previousBeginDate) : undefined;
    const pedi = previousEndDate ? dateIndexFromDate(previousEndDate) : undefined;
    const bt = reportsBinTypeFromDateRange(state.dateRange);

    // load data
    let tabReports: Map<number, TabReports.AsObject> | undefined;
    let previousTabReports: Map<number, TabReports.AsObject> | undefined;
    let msgReports: Map<string, MsgReports.AsObject> | undefined;
    let tabReportsParams: ReportsParams.AsObject | undefined;
    let previousTabReportsParams: ReportsParams.AsObject | undefined;
    let msgReportsParams: ReportsParams.AsObject | undefined;

    let tabReportsPromise: Promise<TabsReportsResponse> | undefined;
    let msgReportsPromise: Promise<MsgsReportsResponse> | undefined;
    let previousTabReportsPromise: Promise<TabsReportsResponse> | undefined;

    if (state.reportsType === ReportsType.Messages) {
      // msg reports
      if (!state.msgReports) { msgReportsPromise = grpcClient.getMsgsReports(bdi, edi, bt); }
    } else {
      // tab reports
      if (!state.tabReports) { tabReportsPromise = grpcClient.getTabsReports(bdi, edi, bt); }
      // previous tab reports
      if (!state.previousTabReports && pbdi && pedi) { previousTabReportsPromise = grpcClient.getTabsReports(pbdi, pedi, bt); }
    }

    // await and proces responses
    if (msgReportsPromise) {
      const msgResponse = await msgReportsPromise;
      msgReports = toMapByField(msgResponse.toObject().msgReportsList, 'msgType');
      msgReportsParams = msgResponse.getParams()!.toObject();
      ReportsCache.addMsgReports(msgReports, msgReportsParams);
    }

    if (tabReportsPromise) {
      const guestResponse = await tabReportsPromise;
      tabReports = toMapByField(guestResponse.toObject().tabReportsList, 'tabId');
      tabReportsParams = guestResponse.getParams()!.toObject();
      ReportsCache.addTabReports(tabReports, tabReportsParams);
    }

    if (previousTabReportsPromise) {
      const guestResponse = await previousTabReportsPromise;
      previousTabReports = toMapByField(guestResponse.toObject().tabReportsList, 'tabId');
      previousTabReportsParams = guestResponse.getParams()!.toObject();
      ReportsCache.addTabReports(previousTabReports, previousTabReportsParams);
    }
    commit('UPDATE_REPORTS_TABS_MSGS', {
      tabReports,
      previousTabReports,
      msgReports,
      tabReportsParams,
      previousTabReportsParams,
      msgReportsParams,
    });

    commit('UPDATE_REPORTS', { isLoaded: true });
  },
  async setReportsDateRange({ dispatch }, dateRange: DateRange) {
    await dispatch('updateReports', { dateRange });
  },
  async setReportsTab({ dispatch }, tab: Tab) {
    await dispatch('updateReports', { tab });
  },
  async setReportsCampaignName({ dispatch }, campaignName: string) {
    await dispatch('updateReports', { campaignName });
  },
  async setReportsByDtCreate({ dispatch }, byDtCreate: boolean) {
    await dispatch('updateReports', { byDtCreate });
  },
  // eslint-disable-next-line no-empty-pattern
  updateReservationDates({ }, dateIndices: number[]) {
    ReportsCache.removeModified(dateIndices);
  },
};

const getters = <GetterTree<IReportsState, IRootState>>{
  isReportsLoaded(state: ReportsState, localGetters: any, rootState: any, rootGetters: any) {
    return state.isLoaded && rootGetters.isLoaded;
  },
  reportsType(state: ReportsState): ReportsType {
    return state.reportsType;
  },
  reportsComparePrevious(state: ReportsState): boolean {
    return state.dateRange.previousBeginDate !== undefined && state.dateRange.previousEndDate !== undefined;
  },
  msgReportsCategory(state: ReportsState): { categoryType: CategoryType, category: Date[] } {
    if (!state.msgReportsParams) return { categoryType: CategoryType.DAY, category: [] };
    return reportsCategoryFromReportsParams(state.msgReportsParams, false);
  },
  tabReportsCategory(state: ReportsState): { categoryType: CategoryType, category: Date[] } {
    if (!state.tabReportsParams) return { categoryType: CategoryType.DAY, category: [] };
    return reportsCategoryFromReportsParams(state.tabReportsParams);
  },
  reportsEmails(state: ReportsState, localGetters: any): ChartData {
    if (!state.msgReportsParams || !state.msgReports) return {};

    const { categoryType, category } = localGetters.msgReportsCategory as { categoryType: CategoryType, category: Date[] };
    const reports = reportsForMsgTypes(EmailMessageTypes, state.msgReportsParams, state.msgReports);

    const dataset = reportsZipLists([reports.sentList, reports.errorsList]);
    const totals = [reportsListSum(reports.sentList), reportsListSum(reports.errorsList)];

    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsSms(state: ReportsState, localGetters: any): ChartData {
    if (!state.msgReportsParams || !state.msgReports) return {};

    const { categoryType, category } = localGetters.msgReportsCategory as { categoryType: CategoryType, category: Date[] };
    const reports = reportsForMsgTypes(SmsMessageTypes, state.msgReportsParams, state.msgReports);

    const dataset = reportsZipLists([reports.sentList, reports.errorsList]);
    const totals = [reportsListSum(reports.sentList), reportsListSum(reports.errorsList)];

    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsFilteredTabs(state: ReportsState, localGetters: any, rootState: any, rootGetters: any) {
    // valid tabs
    const tabs = new Set<number>();
    rootState.settings.tabs.forEach((t: Tab) => {
      if (state.tab === ReportsAllTabs) tabs.add(t.id);
      else if (state.tab === ReportsOnlineTabs) {
        if (t.usingWeekdaysAndTimes) tabs.add(t.id);
      } else if (state.tab.id === t.id) tabs.add(t.id);
    });

    return tabs;
  },
  reportsTabReports(state: ReportsState, localGetters: any, rootState: any, rootGetters: any) {
    if (!state.tabReportsParams || !state.tabReports) return null;
    const tabs = localGetters.reportsFilteredTabs as Set<number>;
    return reportsForTabs(tabs, state.tabReportsParams, state.tabReports);
  },
  reportsPreviousTabReports(state: ReportsState, localGetters: any, rootState: any, rootGetters: any) {
    if (!state.previousTabReportsParams || !state.previousTabReports) return null;
    const tabs = localGetters.reportsFilteredTabs as Set<number>;
    return reportsForTabs(tabs, state.previousTabReportsParams, state.previousTabReports);
  },
  // guests
  reportsGuests(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const guests = (state.byDtCreate ? localGetters.reportsTabReports!.guestsDtc : localGetters.reportsTabReports!.guests) as GuestsReports.AsObject;
    const pguests = (state.byDtCreate ? localGetters.reportsPreviousTabReports?.guestsDtc : localGetters.reportsPreviousTabReports?.guests) as GuestsReports.AsObject;
    const lists = pguests ? [guests.guestsList, pguests.guestsList] : [guests.guestsList];
    const dataset = reportsZipLists(lists);
    const totals = lists.map((l) => reportsListSum(l));

    console.log('reportsGuests: ', { category, dataset, totals });
    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsReservations(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const guests = (state.byDtCreate ? localGetters.reportsTabReports!.guestsDtc : localGetters.reportsTabReports!.guests) as GuestsReports.AsObject;
    const pguests = (state.byDtCreate ? localGetters.reportsPreviousTabReports?.guestsDtc : localGetters.reportsPreviousTabReports?.guests) as GuestsReports.AsObject;
    const lists = pguests ? [guests.reservationsList, pguests.reservationsList] : [guests.reservationsList];
    const dataset = reportsZipLists(lists);
    const totals = lists.map((l) => reportsListSum(l));

    console.log('reportsGuests: ', { category, dataset, totals });
    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsReturning(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const guests = (state.byDtCreate ? localGetters.reportsTabReports!.guestsDtc : localGetters.reportsTabReports!.guests) as GuestsReports.AsObject;
    const pguests = (state.byDtCreate ? localGetters.reportsPreviousTabReports?.guestsDtc : localGetters.reportsPreviousTabReports?.guests) as GuestsReports.AsObject;

    const dataset = reportsZipLists(pguests
      ? [reportsListPercentages(guests.returningList, guests.reservationsList), reportsListPercentages(pguests.returningList, pguests.reservationsList)]
      : [reportsListPercentages(guests.returningList, guests.reservationsList)]);

    const totals = pguests
      ? [reportsListPercentagesTotal(guests.returningList, guests.reservationsList), reportsListPercentagesTotal(pguests.returningList, pguests.reservationsList)]
      : [reportsListPercentagesTotal(guests.returningList, guests.reservationsList)];

    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsCancelations(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const guests = (state.byDtCreate ? localGetters.reportsTabReports!.guestsDtc : localGetters.reportsTabReports!.guests) as GuestsReports.AsObject;
    const pguests = (state.byDtCreate ? localGetters.reportsPreviousTabReports?.guestsDtc : localGetters.reportsPreviousTabReports?.guests) as GuestsReports.AsObject;

    const dataset = reportsZipLists(pguests
      ? [reportsListPercentages(guests.cancelationsList, guests.reservationsList), reportsListPercentages(pguests.cancelationsList, pguests.reservationsList)]
      : [reportsListPercentages(guests.cancelationsList, guests.reservationsList)]);

    const totals = pguests
      ? [reportsListPercentagesTotal(guests.cancelationsList, guests.reservationsList), reportsListPercentagesTotal(pguests.cancelationsList, pguests.reservationsList)]
      : [reportsListPercentagesTotal(guests.cancelationsList, guests.reservationsList)];

    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsNoshows(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const guests = (state.byDtCreate ? localGetters.reportsTabReports!.guestsDtc : localGetters.reportsTabReports!.guests) as GuestsReports.AsObject;
    const pguests = (state.byDtCreate ? localGetters.reportsPreviousTabReports?.guestsDtc : localGetters.reportsPreviousTabReports?.guests) as GuestsReports.AsObject;

    const dataset = reportsZipLists(pguests
      ? [reportsListPercentages(guests.noshowsList, guests.reservationsList), reportsListPercentages(pguests.noshowsList, pguests.reservationsList)]
      : [reportsListPercentages(guests.noshowsList, guests.reservationsList)]);

    const totals = pguests
      ? [reportsListPercentagesTotal(guests.noshowsList, guests.reservationsList), reportsListPercentagesTotal(pguests.noshowsList, pguests.reservationsList)]
      : [reportsListPercentagesTotal(guests.noshowsList, guests.reservationsList)];

    return {
      categoryType, category, dataset, totals,
    };
  },
  reportsPartySizes(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const guests = (state.byDtCreate ? localGetters.reportsTabReports!.guestsDtc : localGetters.reportsTabReports!.guests) as GuestsReports.AsObject;
    const pguests = (state.byDtCreate ? localGetters.reportsPreviousTabReports?.guestsDtc : localGetters.reportsPreviousTabReports?.guests) as GuestsReports.AsObject;

    const dataset = reportsZipLists(pguests
      ? [reportsListAverages(guests.partySizeSumsList, guests.partySizeCountsList), reportsListAverages(pguests.partySizeSumsList, pguests.partySizeCountsList)]
      : [reportsListAverages(guests.partySizeSumsList, guests.partySizeCountsList)]);

    const totals = pguests
      ? [reportsListAveragesTotal(guests.partySizeSumsList, guests.partySizeCountsList), reportsListAveragesTotal(pguests.partySizeSumsList, pguests.partySizeCountsList)]
      : [reportsListAveragesTotal(guests.partySizeSumsList, guests.partySizeCountsList)];

    return {
      categoryType, category, dataset, totals,
    };
  },
  // online
  reportsCampaignsGuests(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const campaigns = state.byDtCreate
      ? localGetters.reportsTabReports!.campaignsDtcList as CampaignReports.AsObject[]
      : localGetters.reportsTabReports!.campaignsList as CampaignReports.AsObject[];

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const series = campaigns.map((c: CampaignReports.AsObject) => c.campaignName);
    const dataset = campaigns.map((c: CampaignReports.AsObject) => c.guestsList);
    const totals = dataset.map((l) => reportsListSum(l));
    const total = reportsListSum(totals);

    return {
      categoryType, category, series, dataset, totals, total,
    };
  },
  reportsCampaignsReservations(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const campaigns = state.byDtCreate
      ? localGetters.reportsTabReports!.campaignsDtcList as CampaignReports.AsObject[]
      : localGetters.reportsTabReports!.campaignsList as CampaignReports.AsObject[];

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const series = campaigns.map((c: CampaignReports.AsObject) => c.campaignName);
    const dataset = campaigns.map((c: CampaignReports.AsObject) => c.reservationsList);
    const totals = dataset.map((l) => reportsListSum(l));
    const total = reportsListSum(totals);

    return {
      categoryType, category, series, dataset, totals, total,
    };
  },
  reportsCampaignsDaysAdvance(state: ReportsState, localGetters: any): { dataset?: [Date, number][], total?: number } {
    if (!localGetters.reportsTabReports) return {};

    const dates = localGetters.tabReportsCategory.category as Date[];
    let daysAdvanceList: number[];
    let total: number;

    const campaigns = state.byDtCreate
      ? localGetters.reportsTabReports!.campaignsDtcList as CampaignReports.AsObject[]
      : localGetters.reportsTabReports!.campaignsList as CampaignReports.AsObject[];

    const campaign = campaigns.find((c) => c.campaignName === state.campaignName);

    if (campaign) {
      daysAdvanceList = reportsListsAveragesNoNeg([campaign.daysAdvanceSumsList], [campaign.daysAdvanceCountsList]);
      total = reportsListsAveragesTotalNoNeg([campaign.daysAdvanceSumsList], [campaign.daysAdvanceCountsList]);
    } else {
      const valueLists = campaigns.map((c) => c.daysAdvanceSumsList);
      const countLists = campaigns.map((c) => c.daysAdvanceCountsList);
      daysAdvanceList = reportsListsAveragesNoNeg(valueLists, countLists);
      total = reportsListsAveragesTotalNoNeg(valueLists, countLists);
    }

    const dataset = reportsZipLists([dates, daysAdvanceList], false) as [Date, number][];

    return { dataset, total };
  },
  // services
  reportsServices(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const servicesReports = localGetters.reportsTabReports!.servicesList as ServiceReports.AsObject[];
    const series = servicesReports.map((s) => s.serviceName);
    const lists = servicesReports.map((s: ServiceReports.AsObject) => s.reservationsList);
    const dataset = lists;
    const totals = lists.map((l) => reportsListSum(l));

    return {
      categoryType, category, series, dataset, totals,
    };
  },
  reportsServicesRevenue(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const servicesReports = localGetters.reportsTabReports!.servicesList as ServiceReports.AsObject[];
    const revenueListsByCurrencies = new Map<string, number[][]>();
    servicesReports.forEach((sr) => {
      let list = revenueListsByCurrencies.get(sr.revenueCurrency);
      if (!list) { list = []; revenueListsByCurrencies.set(sr.revenueCurrency, list); }
      list.push(sr.revenueSumsList);
    });

    const unsortedCurrencies = Array.from(revenueListsByCurrencies.keys());
    const unsortedDataset = Array.from(revenueListsByCurrencies.values()).map((rlbc) => reportsListsSums(rlbc));
    const unsortedTotals = unsortedDataset.map((list) => reportsListSum(list));
    const indeces = unsortedTotals.map((sum, i) => ({ sum, i })).sort((sumi1, sumi2) => sumi2.sum - sumi1.sum).map((sumi) => sumi.i);
    const series = indeces.map((i) => unsortedCurrencies[i]);
    const dataset = indeces.map((i) => unsortedDataset[i]);
    const totals = indeces.map((i) => unsortedTotals[i]);

    return {
      categoryType, category, series, dataset, totals,
    };
  },
  reportsServicesPie(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): {
    datasetByReservations?: PieChartData,
    datasetByGuests?: PieChartData,
    datasetByRevenue?: PieChartData,
  } {
    if (!localGetters.reportsTabReports) return {};

    const servicesReports = localGetters.reportsTabReports!.servicesList as ServiceReports.AsObject[];

    const datasetByReservations = servicesReports.map((sr) => ({ value: reportsListSum(sr.reservationsList), name: sr.serviceName }));
    const datasetByGuests = servicesReports.map((sr) => ({ value: reportsListSum(sr.guestsList), name: sr.serviceName }));
    const datasetByRevenue = servicesReports.map((sr) => ({ value: reportsListSum(sr.revenueSumsList), name: sr.serviceName, unit: sr.revenueCurrency || ' ' }));

    return { datasetByReservations, datasetByGuests, datasetByRevenue };
  },
  // weekdays
  reportsWeekdaysAverageGuests(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): { dataset?: [number, number, number][], category?: Date[] } {
    if (!localGetters.reportsTabReports) return {};

    // category
    const date = dateFromDateAndTimeIndex(state.tabReportsParams!.beginDate)!;
    const category = reportsDatesForSlots(date);

    // dataset
    const dataset = [] as [number, number, number][];
    const weekdaysReports = localGetters.reportsTabReports!.weekdaysList as WeekdayReports.AsObject[];
    weekdaysReports.forEach((wr, wd) => {
      const list = wr.guests?.guestsList ?? [];
      list.forEach((value, si) => {
        dataset.push([si, wd, value]);
      });
    });

    return { category, dataset };
  },
  reportsWeekdaysServices(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    // series from service reports
    const servicesReports = localGetters.reportsTabReports!.servicesList as ServiceReports.AsObject[];
    const series = servicesReports.map((sr) => sr.serviceName);

    // fetch weekday sums for every service
    const weekdaysReports = localGetters.reportsTabReports!.weekdaysList as WeekdayReports.AsObject[];
    const dataset = servicesReports.map((sr) => {
      const weekdaySums = weekdaysReports.map((wr) => {
        const fwdsr = wr.servicesList.find((wdsr) => (wdsr.serviceId === sr.serviceId) && (wdsr.revenueCurrency === sr.revenueCurrency));
        return reportsListSum(fwdsr?.reservationsList ?? []);
      });
      return weekdaySums;
    });

    return { series, dataset };
  },
  reportsWeekdaysServicesRevenue(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): { series?: string[], dataset?: number[][] } {
    if (!localGetters.reportsTabReports) return {};

    // series from services revenue chart data (sorted by amount)
    const reportsServicesRevenue = localGetters.reportsServicesRevenue as ChartData;
    const series = reportsServicesRevenue.series ?? []; // currencies

    // fetch weekday revenue sums for every currency
    const weekdaysReports = localGetters.reportsTabReports!.weekdaysList as WeekdayReports.AsObject[];
    const dataset = series.map((curr) => {
      const weekdaySums = weekdaysReports.map((wr) => {
        // weekday services with curr
        const fwdsrs = wr.servicesList.filter((wdsr) => wdsr.revenueCurrency === curr);
        return reportsListSum(fwdsrs.map((fwdsr) => reportsListSum(fwdsr.revenueSumsList)));
      });
      return weekdaySums;
    });

    return { series, dataset };
  },
  reportsWeekdaysTabs(state: ReportsState, localGetters: any, rootState: any, rootGetters: any): { series?: string[], dataset?: number[][] } {
    if (!localGetters.reportsTabReports) return {};

    const allTabs = rootState.settings.tabs as Tab[];
    const tabIds = localGetters.reportsFilteredTabs as Set<number>;
    const tabs = allTabs.filter((t) => tabIds.has(t.id));

    // series
    const series = tabs.map((t) => t.name);

    // fetch weekday sums for every tab
    const dataset = tabs.map((t) => {
      const weekdaysReports = state.tabReports!.get(t.id)?.weekdaysList;
      if (!weekdaysReports) return [0, 0, 0, 0, 0, 0, 0];

      const weekdaySums = weekdaysReports.map((wr) => {
        // actual tab weekday services
        const list = wr.guests?.guestsList; // might be undefined - direclty from api
        return list ? reportsListSum(list) : 0;
      });
      return weekdaySums;
    });

    return { series, dataset };
  },
  // feedback
  // reportsAllRatings(state: ReportsState, localGetters: any): ChartData {
  //   if (!localGetters.reportsTabReports) return {};

  //   const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
  //   const feedbacks = localGetters.reportsTabReports!.feedbacks as FeedbackReports.AsObject;
  //   const pfeedbacks = localGetters.reportsPreviousTabReports?.feedbacks as FeedbackReports.AsObject;

  //   const dataset = reportsZipLists(pfeedbacks
  //     ? [reportsListAverages(feedbacks.overallSumsList, feedbacks.overallCountsList), reportsListAverages(pfeedbacks.overallSumsList, pfeedbacks.overallCountsList)]
  //     : [
  //       reportsListAverages(feedbacks.overallSumsList, feedbacks.overallCountsList),
  //       reportsListAverages(feedbacks.foodSumsList, feedbacks.breakdownCountsList),
  //       reportsListAverages(feedbacks.serviceSumsList, feedbacks.breakdownCountsList),
  //       reportsListAverages(feedbacks.ambienceSumsList, feedbacks.breakdownCountsList),
  //     ]);

  //   const totals = pfeedbacks
  //     ? [reportsListAveragesTotal(feedbacks.overallSumsList, feedbacks.overallCountsList), reportsListAveragesTotal(pfeedbacks.overallSumsList, pfeedbacks.overallCountsList)]
  //     : [
  //       reportsListAveragesTotal(feedbacks.overallSumsList, feedbacks.overallCountsList),
  //       reportsListAveragesTotal(feedbacks.foodSumsList, feedbacks.breakdownCountsList),
  //       reportsListAveragesTotal(feedbacks.serviceSumsList, feedbacks.breakdownCountsList),
  //       reportsListAveragesTotal(feedbacks.ambienceSumsList, feedbacks.breakdownCountsList),
  //     ];

  //   return {
  //     categoryType, category, dataset, totals,
  //   };
  // },
  reportsOverallRatings(state: ReportsState, localGetters: any): ChartData {
    if (!localGetters.reportsTabReports) return {};

    const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
    const feedbacks = localGetters.reportsTabReports!.feedbacks as FeedbackReports.AsObject;
    const pfeedbacks = localGetters.reportsPreviousTabReports?.feedbacks as FeedbackReports.AsObject;

    // const dataset = feedbacks.binsList.map((bin) => bin.overall?.starsList ?? [0, 0, 0, 0, 0]);
    // const dataset = feedbacks.binsList.reduce((sets, bin) => sets.forEach((s, i) => s + (bin.overall?.starsList[i] ?? 0)), [[], [], [], [], []]);
    const dataset = [1, 2, 3, 4, 5].map((_, i) => feedbacks.binsList.map((bin) => bin.overall?.starsList[i] ?? 0));
    const totals = feedbacks.binsList.reduce((sums, bin) => sums.map((s, i) => s + (bin.overall?.starsList[i] ?? 0)), [0, 0, 0, 0, 0]);
    const avgDataset = feedbacks.binsList.map((bin) => ((bin.overall?.starsList ?? []).reduce((wsum, cnt, rti) => wsum + (rti + 1) * cnt, 0) / (bin.overall?.starsList ?? []).reduce((sum, cnt) => sum + cnt, 0)) || 0);

    return {
      categoryType, category, dataset, totals, avgDataset,
    };
  },
  // reportsFoodRatings(state: ReportsState, localGetters: any): ChartData {
  //   if (!localGetters.reportsTabReports) return {};

  //   const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
  //   const feedbacks = localGetters.reportsTabReports!.feedbacks as FeedbackReports.AsObject;
  //   const pfeedbacks = localGetters.reportsPreviousTabReports?.feedbacks as FeedbackReports.AsObject;

  //   const dataset = reportsZipLists(pfeedbacks
  //     ? [reportsListAverages(feedbacks.foodSumsList, feedbacks.breakdownCountsList), reportsListAverages(pfeedbacks.foodSumsList, pfeedbacks.breakdownCountsList)]
  //     : [reportsListAverages(feedbacks.foodSumsList, feedbacks.breakdownCountsList)]);

  //   const totals = pfeedbacks
  //     ? [reportsListAveragesTotal(feedbacks.foodSumsList, feedbacks.breakdownCountsList), reportsListAveragesTotal(pfeedbacks.foodSumsList, pfeedbacks.breakdownCountsList)]
  //     : [reportsListAveragesTotal(feedbacks.foodSumsList, feedbacks.breakdownCountsList)];

  //   return {
  //     categoryType, category, dataset, totals,
  //   };
  // },
  // reportsServiceRatings(state: ReportsState, localGetters: any): ChartData {
  //   if (!localGetters.reportsTabReports) return {};

  //   const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
  //   const feedbacks = localGetters.reportsTabReports!.feedbacks as FeedbackReports.AsObject;
  //   const pfeedbacks = localGetters.reportsPreviousTabReports?.feedbacks as FeedbackReports.AsObject;

  //   const dataset = reportsZipLists(pfeedbacks
  //     ? [reportsListAverages(feedbacks.serviceSumsList, feedbacks.breakdownCountsList), reportsListAverages(pfeedbacks.serviceSumsList, pfeedbacks.breakdownCountsList)]
  //     : [reportsListAverages(feedbacks.serviceSumsList, feedbacks.breakdownCountsList)]);

  //   const totals = pfeedbacks
  //     ? [reportsListAveragesTotal(feedbacks.serviceSumsList, feedbacks.breakdownCountsList), reportsListAveragesTotal(pfeedbacks.serviceSumsList, pfeedbacks.breakdownCountsList)]
  //     : [reportsListAveragesTotal(feedbacks.serviceSumsList, feedbacks.breakdownCountsList)];

  //   return {
  //     categoryType, category, dataset, totals,
  //   };
  // },
  // reportsAmbienceRatings(state: ReportsState, localGetters: any): ChartData {
  //   if (!localGetters.reportsTabReports) return {};

  //   const { categoryType, category } = localGetters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
  //   const feedbacks = localGetters.reportsTabReports!.feedbacks as FeedbackReports.AsObject;
  //   const pfeedbacks = localGetters.reportsPreviousTabReports?.feedbacks as FeedbackReports.AsObject;

  //   const dataset = reportsZipLists(pfeedbacks
  //     ? [reportsListAverages(feedbacks.ambienceSumsList, feedbacks.breakdownCountsList), reportsListAverages(pfeedbacks.ambienceSumsList, pfeedbacks.breakdownCountsList)]
  //     : [reportsListAverages(feedbacks.ambienceSumsList, feedbacks.breakdownCountsList)]);

  //   const totals = pfeedbacks
  //     ? [reportsListAveragesTotal(feedbacks.ambienceSumsList, feedbacks.breakdownCountsList), reportsListAveragesTotal(pfeedbacks.ambienceSumsList, pfeedbacks.breakdownCountsList)]
  //     : [reportsListAveragesTotal(feedbacks.ambienceSumsList, feedbacks.breakdownCountsList)];

  //   return {
  //     categoryType, category, dataset, totals,
  //   };
  // },
};

const ReportsStore = {
  namespaced: false,
  state: new ReportsState(),
  mutations,
  actions,
  getters,
};

export default ReportsStore;
