import { IUser } from '@/api/api-update';
import { dateFromUTCDateTimeSecondsString } from '@/services/time-utils';
import { boolFromValue, numberFromValue } from '@/api/api-utils';

export interface IDTOUser extends IUser { }

export default class User {
  id: number;

  username: string;

  name?: string;

  email?: string;

  isRegistered?: boolean;

  allowSignIn?: boolean;

  allowReservations?: boolean;

  allowAvailability?: boolean;

  allowDayNotes?: boolean;

  allowSettings?: boolean;

  allowBilling?: boolean;

  sharedUser?: boolean;

  newsletterSubscription?: boolean;

  privacyScreenTimeout: number;

  dtLastEdit?: Date;

  constructor(o?: IUser) {
    this.id = o?.userId ?? 0;
    this.name = o?.name;
    this.username = o?.username ?? 'N/A';
    this.email = o?.email;
    this.isRegistered = boolFromValue(o?.isRegistered);
    this.allowSignIn = boolFromValue(o?.allowSignIn);
    this.allowReservations = boolFromValue(o?.allowReservations);
    this.allowAvailability = boolFromValue(o?.allowAvailability);
    this.allowDayNotes = boolFromValue(o?.allowDayNotes);
    this.allowSettings = boolFromValue(o?.allowSettings);
    this.allowBilling = boolFromValue(o?.allowBilling);
    this.sharedUser = boolFromValue(o?.sharedUser);
    this.newsletterSubscription = boolFromValue(o?.newsletterSubscription);
    this.privacyScreenTimeout = numberFromValue(o?.privacyScreenTimeout) ?? 0;
    this.dtLastEdit = dateFromUTCDateTimeSecondsString(o?.dtLastEdit) ?? undefined;
  }

  toDto(): IDTOUser {
    const dto: IDTOUser = {
      ...this,
      userId: this.id,
      id: undefined,
      dtLastEdit: undefined,
    };
    return dto;
  }
}
