/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class BookDinnersApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.bookdinners', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
      configComponentImportFnc: () => import('@/components/apps/bookdinners/SettingsDialog.vue'),
    });
  }
}
