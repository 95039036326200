import { ITagNote } from '@/api/api-update';

export interface IDTOTagNote extends ITagNote { }

export default class TagNote {
  id: number;

  text: string;

  order: number;

  constructor(o?: ITagNote) {
    this.id = o?.tagNoteId ?? 0;
    this.text = o?.tagNoteText ?? '';
    this.order = o?.tagNoteOrder ?? 0;
  }

  toDto(): IDTOTagNote {
    const dto: IDTOTagNote = {
      tagNoteId: this.id,
      tagNoteText: this.text,
      tagNoteOrder: this.order,
    };
    return dto;
  }

  get dotText(): string {
    return `• ${this.text}`;
  }
}
