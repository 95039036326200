
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import httpClient from '@/api/http-client';
import { performSaveAction } from '@/services/vue-utils';

interface UploadedFile {
  file: File;
}

export default Vue.extend({
  name: 'FileUploadDialog',
  mixins: [DialogClosable],
  props: { },
  data() {
    return {
      fileRecords: [] as UploadedFile[],
    };
  },
  computed: { },
  watch: {
    visible(value: boolean) {
      if (value) { this.reset(); }
    },
    fileRecords() {
      if (!this.fileRecords || this.fileRecords.length === 0) {
        return;
      }
      console.log(this.fileRecords);
    },
  },
  methods: {
    onBeforeDelete(fileRecord: UploadedFile) {
      const i = this.fileRecords.indexOf(fileRecord);
      this.fileRecords.splice(i, 1);
      const k = this.fileRecords.indexOf(fileRecord);
      if (k !== -1) this.fileRecords.splice(k, 1);
    },
    reset() {
      this.fileRecords = [];
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    async upload() {
      if (!this.fileRecords || this.fileRecords.length === 0) {
        return;
      }

      let filenames: string[] = [];
      let urls: string[] = [];

      const ok = await performSaveAction(
        undefined,
        async () => {
          filenames = this.fileRecords.map((fr) => fr.file.name);
          const key = this.$tstore.state.settings.account.serviceManagerAccessKey ?? '';
          const futureUrls = this.fileRecords.map((fr) => httpClient.uploadAttachmentFile(fr.file, key));
          urls = await Promise.all(futureUrls);
        },
      );

      if (!ok) return;

      const uploads: { name: string, url: string }[] = [];
      for (let i = 0; i < this.fileRecords.length; i += 1) {
        uploads.push({ name: filenames[i], url: urls[i] });
      }
      this.close();
      this.$emit('input', uploads);
    },
  },
});
