
import Vue, { PropType, defineComponent } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-2';
import { Handlebar } from '@/services/handlebar-tiptap-extension';
import { textWithHandlebarsToHtml, htmlToTextWithHandlebars } from '@/services/handlebar-utils';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import MessageHandlebar from '@/model/MessageHandlebar';
import InputDialog from '@/components/dialogs/InputDialog.vue';
import { TranslateResult } from 'vue-i18n';

const __default__ = defineComponent({
  name: 'RichTextEditor',
  components: {
    EditorContent,
    InputDialog,
  },
  props: {
    value: { type: String, required: false, default: '' },
    dense: { type: Boolean, required: false, default: false },
    richtextAllowed: { type: Boolean, required: false, default: false },
    encodeLinebreak: { type: Boolean, required: false, default: false },
    errorMessages: { type: Array<String>, required: false, default: [] },
    hint: { type: String, required: false, default: '' },
    height: { type: String, required: false, default: '200px' },
    label: { type: [String, Object] as PropType<string|TranslateResult>, required: false, default: undefined },
  },
  data() {
    return {
      editor: undefined as undefined | Editor,
      linkInputDialog: false,
      linkUrl: undefined as undefined | string,
    };
  },
  computed: {
    dark(): boolean { return this.$vuetify.theme.dark; },
    isBoldMark(): boolean { return this.editor?.isActive('bold') || false; },
    isItalicMark(): boolean { return this.editor?.isActive('italic') || false; },
    isHyperlink(): boolean { return this.editor?.isActive('link') || false; },
    handlebars(): MessageHandlebar[] {
      return this.$tstore.state.messageCenter.handlebars;
    },
    handlebarsById(): Map<number, MessageHandlebar> {
      return this.$tstore.getters.handlebarsById;
    },
  },
  watch: {
    value() {
      if (this.editor?.getHTML() === textWithHandlebarsToHtml(this.value, this.handlebars)) { return; }
      this.editor?.commands.setContent(textWithHandlebarsToHtml(this.value, this.handlebars));
    },
  },
  created() {
    // @ts-ignore
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Link,
        Handlebar,
      ],
      content: textWithHandlebarsToHtml(this.value, this.handlebars),
      onUpdate: ({ editor }) => {
        const content = editor.getHTML();
        let newContent = htmlToTextWithHandlebars(content, this.handlebarsById);
        if (this.encodeLinebreak && newContent) {
          newContent = newContent.replaceAll(/\n/g, '<br />');
        }
        this.$emit('input', newContent);
      },
    });
  },
  beforeDestroy() {
    this.editor?.destroy();
    this.editor = undefined;
  },
  methods: {
    editorToggleLink(href: string) {
      this.editor?.commands.toggleLink({ href, target: '_blank' });
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "02590ce5": (_vm.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__