
import Vue from 'vue';

export default Vue.extend({
  name: 'HideableBadge',
  props: {
    visible: { type: Boolean, required: false, default: true },
    content: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: 'primary' },
  },
  data() {
    return {};
  },
  computed: { },
  methods: { },
});

