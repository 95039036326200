import { IDTOAllocation } from '@/api/api-allocations';
import Tab from './Tab';
import TabItem from './TabItem';

export interface IDTOSendAllocation {
  allocationId: number;
  allocationOrder: number;
  allocatedTabItems: string;
  isForbidden: boolean;
}

export interface AllocationMapping {
  tabsById: Map<number, Tab>;
  tabItemsById: Map<number, TabItem>;
}

export default class Allocation {
  id: number;

  tab: Tab;

  partySize: number = 0;

  order: number = 0;

  tabItems: TabItem[] = [];

  isForbidden: boolean;

  constructor(o?: IDTOAllocation, m?: AllocationMapping) {
    this.id = o?.allocationId ?? 0;
    this.partySize = o?.partySize ?? 0;
    this.order = o?.allocationOrder ?? 0;
    this.isForbidden = o?.isForbidden ?? false;
    this.tab = m?.tabsById?.get(o?.tabId ?? NaN) ?? new Tab();

    const allocatedTabItems = o?.allocatedTabItems ?? [];
    this.tabItems = allocatedTabItems.flatMap((ti) => m?.tabItemsById?.get(ti) ?? []);
    this.tabItems.sort((o1, o2) => o1.order - o2.order);
  }

  toDto(): IDTOSendAllocation {
    const dto: IDTOSendAllocation = {
      allocationId: this.id > 0 ? this.id : 0,
      allocationOrder: this.order,
      allocatedTabItems: this.tableIdsString,
      isForbidden: this.isForbidden,
    };
    return dto;
  }

  clone(): Allocation {
    const copy = new Allocation();
    Object.assign(copy, this);
    copy.tabItems = Array.from(this.tabItems);
    return copy;
  }

  get seats(): number {
    return this.tabItems.map((o) => o.seats).reduce((a, b) => a + b, 0);
  }

  get isError(): boolean {
    return this.seats === 0 || this.seats < this.partySize;
  }

  get tableNos(): string[] {
    return this.tabItems.map((o) => o.no);
  }

  get tableIdsString(): string {
    return this.tabItems.map((o) => o.id).join(',');
  }
}
