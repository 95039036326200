// source: contact_manager_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var contact_pb = require('./contact_pb.js');
goog.object.extend(proto, contact_pb);
var employee_pb = require('./employee_pb.js');
goog.object.extend(proto, employee_pb);
var contact_filter_pb = require('./contact_filter_pb.js');
goog.object.extend(proto, contact_filter_pb);
var editor_template_pb = require('./editor_template_pb.js');
goog.object.extend(proto, editor_template_pb);
goog.exportSymbol('proto.grpc.api.v1.ContactFilterIdRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactFilterListRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactFilterListResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactFilterRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactFilterResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactIdRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactListRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactListResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ContactResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.DeleteContactsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.EditorTemplateIdRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.EditorTemplateListRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.EditorTemplateListResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.EditorTemplateRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.EditorTemplateResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.MergeContactsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.SearchContactsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.SelectedContactsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactRequest.displayName = 'proto.grpc.api.v1.ContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactIdRequest.displayName = 'proto.grpc.api.v1.ContactIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactListRequest.displayName = 'proto.grpc.api.v1.ContactListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactResponse.displayName = 'proto.grpc.api.v1.ContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactListResponse.displayName = 'proto.grpc.api.v1.ContactListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactFilterRequest.displayName = 'proto.grpc.api.v1.ContactFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactFilterIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactFilterIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactFilterIdRequest.displayName = 'proto.grpc.api.v1.ContactFilterIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactFilterListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactFilterListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactFilterListRequest.displayName = 'proto.grpc.api.v1.ContactFilterListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactFilterResponse.displayName = 'proto.grpc.api.v1.ContactFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ContactFilterListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ContactFilterListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ContactFilterListResponse.displayName = 'proto.grpc.api.v1.ContactFilterListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.EditorTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.EditorTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.EditorTemplateRequest.displayName = 'proto.grpc.api.v1.EditorTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.EditorTemplateIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.EditorTemplateIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.EditorTemplateIdRequest.displayName = 'proto.grpc.api.v1.EditorTemplateIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.EditorTemplateListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.EditorTemplateListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.EditorTemplateListRequest.displayName = 'proto.grpc.api.v1.EditorTemplateListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.EditorTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.EditorTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.EditorTemplateResponse.displayName = 'proto.grpc.api.v1.EditorTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.EditorTemplateListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.EditorTemplateListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.EditorTemplateListResponse.displayName = 'proto.grpc.api.v1.EditorTemplateListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.SelectedContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.SelectedContactsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.SelectedContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.SelectedContactsRequest.displayName = 'proto.grpc.api.v1.SelectedContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MergeContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.MergeContactsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.MergeContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MergeContactsRequest.displayName = 'proto.grpc.api.v1.MergeContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.DeleteContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.DeleteContactsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.DeleteContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.DeleteContactsRequest.displayName = 'proto.grpc.api.v1.DeleteContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.SearchContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.SearchContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.SearchContactsRequest.displayName = 'proto.grpc.api.v1.SearchContactsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && contact_pb.Contact.toObject(includeInstance, f),
    employee: (f = msg.getEmployee()) && employee_pb.Employee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactRequest}
 */
proto.grpc.api.v1.ContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactRequest;
  return proto.grpc.api.v1.ContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactRequest}
 */
proto.grpc.api.v1.ContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contact_pb.Contact;
      reader.readMessage(value,contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 3:
      var value = new employee_pb.Employee;
      reader.readMessage(value,employee_pb.Employee.deserializeBinaryFromReader);
      msg.setEmployee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contact_pb.Contact.serializeBinaryToWriter
    );
  }
  f = message.getEmployee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      employee_pb.Employee.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.grpc.api.v1.Contact}
 */
proto.grpc.api.v1.ContactRequest.prototype.getContact = function() {
  return /** @type{?proto.grpc.api.v1.Contact} */ (
    jspb.Message.getWrapperField(this, contact_pb.Contact, 1));
};


/**
 * @param {?proto.grpc.api.v1.Contact|undefined} value
 * @return {!proto.grpc.api.v1.ContactRequest} returns this
*/
proto.grpc.api.v1.ContactRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactRequest} returns this
 */
proto.grpc.api.v1.ContactRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Employee employee = 3;
 * @return {?proto.grpc.api.v1.Employee}
 */
proto.grpc.api.v1.ContactRequest.prototype.getEmployee = function() {
  return /** @type{?proto.grpc.api.v1.Employee} */ (
    jspb.Message.getWrapperField(this, employee_pb.Employee, 3));
};


/**
 * @param {?proto.grpc.api.v1.Employee|undefined} value
 * @return {!proto.grpc.api.v1.ContactRequest} returns this
*/
proto.grpc.api.v1.ContactRequest.prototype.setEmployee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactRequest} returns this
 */
proto.grpc.api.v1.ContactRequest.prototype.clearEmployee = function() {
  return this.setEmployee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactRequest.prototype.hasEmployee = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    employee: (f = msg.getEmployee()) && employee_pb.Employee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactIdRequest}
 */
proto.grpc.api.v1.ContactIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactIdRequest;
  return proto.grpc.api.v1.ContactIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactIdRequest}
 */
proto.grpc.api.v1.ContactIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = new employee_pb.Employee;
      reader.readMessage(value,employee_pb.Employee.deserializeBinaryFromReader);
      msg.setEmployee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEmployee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      employee_pb.Employee.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.grpc.api.v1.ContactIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ContactIdRequest} returns this
 */
proto.grpc.api.v1.ContactIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Employee employee = 3;
 * @return {?proto.grpc.api.v1.Employee}
 */
proto.grpc.api.v1.ContactIdRequest.prototype.getEmployee = function() {
  return /** @type{?proto.grpc.api.v1.Employee} */ (
    jspb.Message.getWrapperField(this, employee_pb.Employee, 3));
};


/**
 * @param {?proto.grpc.api.v1.Employee|undefined} value
 * @return {!proto.grpc.api.v1.ContactIdRequest} returns this
*/
proto.grpc.api.v1.ContactIdRequest.prototype.setEmployee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactIdRequest} returns this
 */
proto.grpc.api.v1.ContactIdRequest.prototype.clearEmployee = function() {
  return this.setEmployee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactIdRequest.prototype.hasEmployee = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactListRequest}
 */
proto.grpc.api.v1.ContactListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactListRequest;
  return proto.grpc.api.v1.ContactListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactListRequest}
 */
proto.grpc.api.v1.ContactListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && contact_pb.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactResponse}
 */
proto.grpc.api.v1.ContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactResponse;
  return proto.grpc.api.v1.ContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactResponse}
 */
proto.grpc.api.v1.ContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contact_pb.Contact;
      reader.readMessage(value,contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contact_pb.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.grpc.api.v1.Contact}
 */
proto.grpc.api.v1.ContactResponse.prototype.getContact = function() {
  return /** @type{?proto.grpc.api.v1.Contact} */ (
    jspb.Message.getWrapperField(this, contact_pb.Contact, 1));
};


/**
 * @param {?proto.grpc.api.v1.Contact|undefined} value
 * @return {!proto.grpc.api.v1.ContactResponse} returns this
*/
proto.grpc.api.v1.ContactResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactResponse} returns this
 */
proto.grpc.api.v1.ContactResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && contact_pb.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactListResponse}
 */
proto.grpc.api.v1.ContactListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactListResponse;
  return proto.grpc.api.v1.ContactListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactListResponse}
 */
proto.grpc.api.v1.ContactListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contact_pb.Contact;
      reader.readMessage(value,contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contact_pb.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.grpc.api.v1.Contact}
 */
proto.grpc.api.v1.ContactListResponse.prototype.getContact = function() {
  return /** @type{?proto.grpc.api.v1.Contact} */ (
    jspb.Message.getWrapperField(this, contact_pb.Contact, 1));
};


/**
 * @param {?proto.grpc.api.v1.Contact|undefined} value
 * @return {!proto.grpc.api.v1.ContactListResponse} returns this
*/
proto.grpc.api.v1.ContactListResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactListResponse} returns this
 */
proto.grpc.api.v1.ContactListResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactListResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactfilter: (f = msg.getContactfilter()) && contact_filter_pb.ContactFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactFilterRequest}
 */
proto.grpc.api.v1.ContactFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactFilterRequest;
  return proto.grpc.api.v1.ContactFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactFilterRequest}
 */
proto.grpc.api.v1.ContactFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contact_filter_pb.ContactFilter;
      reader.readMessage(value,contact_filter_pb.ContactFilter.deserializeBinaryFromReader);
      msg.setContactfilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactfilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contact_filter_pb.ContactFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContactFilter contactFilter = 1;
 * @return {?proto.grpc.api.v1.ContactFilter}
 */
proto.grpc.api.v1.ContactFilterRequest.prototype.getContactfilter = function() {
  return /** @type{?proto.grpc.api.v1.ContactFilter} */ (
    jspb.Message.getWrapperField(this, contact_filter_pb.ContactFilter, 1));
};


/**
 * @param {?proto.grpc.api.v1.ContactFilter|undefined} value
 * @return {!proto.grpc.api.v1.ContactFilterRequest} returns this
*/
proto.grpc.api.v1.ContactFilterRequest.prototype.setContactfilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactFilterRequest} returns this
 */
proto.grpc.api.v1.ContactFilterRequest.prototype.clearContactfilter = function() {
  return this.setContactfilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactFilterRequest.prototype.hasContactfilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactFilterIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactFilterIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactFilterIdRequest}
 */
proto.grpc.api.v1.ContactFilterIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactFilterIdRequest;
  return proto.grpc.api.v1.ContactFilterIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactFilterIdRequest}
 */
proto.grpc.api.v1.ContactFilterIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactFilterIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactFilterIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.grpc.api.v1.ContactFilterIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ContactFilterIdRequest} returns this
 */
proto.grpc.api.v1.ContactFilterIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactFilterListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactFilterListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactFilterListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactFilterListRequest}
 */
proto.grpc.api.v1.ContactFilterListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactFilterListRequest;
  return proto.grpc.api.v1.ContactFilterListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactFilterListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactFilterListRequest}
 */
proto.grpc.api.v1.ContactFilterListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactFilterListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactFilterListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactFilterListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactfilter: (f = msg.getContactfilter()) && contact_filter_pb.ContactFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactFilterResponse}
 */
proto.grpc.api.v1.ContactFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactFilterResponse;
  return proto.grpc.api.v1.ContactFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactFilterResponse}
 */
proto.grpc.api.v1.ContactFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contact_filter_pb.ContactFilter;
      reader.readMessage(value,contact_filter_pb.ContactFilter.deserializeBinaryFromReader);
      msg.setContactfilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactfilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contact_filter_pb.ContactFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContactFilter contactFilter = 1;
 * @return {?proto.grpc.api.v1.ContactFilter}
 */
proto.grpc.api.v1.ContactFilterResponse.prototype.getContactfilter = function() {
  return /** @type{?proto.grpc.api.v1.ContactFilter} */ (
    jspb.Message.getWrapperField(this, contact_filter_pb.ContactFilter, 1));
};


/**
 * @param {?proto.grpc.api.v1.ContactFilter|undefined} value
 * @return {!proto.grpc.api.v1.ContactFilterResponse} returns this
*/
proto.grpc.api.v1.ContactFilterResponse.prototype.setContactfilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactFilterResponse} returns this
 */
proto.grpc.api.v1.ContactFilterResponse.prototype.clearContactfilter = function() {
  return this.setContactfilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactFilterResponse.prototype.hasContactfilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ContactFilterListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ContactFilterListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ContactFilterListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactfilter: (f = msg.getContactfilter()) && contact_filter_pb.ContactFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ContactFilterListResponse}
 */
proto.grpc.api.v1.ContactFilterListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ContactFilterListResponse;
  return proto.grpc.api.v1.ContactFilterListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ContactFilterListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ContactFilterListResponse}
 */
proto.grpc.api.v1.ContactFilterListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new contact_filter_pb.ContactFilter;
      reader.readMessage(value,contact_filter_pb.ContactFilter.deserializeBinaryFromReader);
      msg.setContactfilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ContactFilterListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ContactFilterListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ContactFilterListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ContactFilterListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactfilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      contact_filter_pb.ContactFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContactFilter contactFilter = 2;
 * @return {?proto.grpc.api.v1.ContactFilter}
 */
proto.grpc.api.v1.ContactFilterListResponse.prototype.getContactfilter = function() {
  return /** @type{?proto.grpc.api.v1.ContactFilter} */ (
    jspb.Message.getWrapperField(this, contact_filter_pb.ContactFilter, 2));
};


/**
 * @param {?proto.grpc.api.v1.ContactFilter|undefined} value
 * @return {!proto.grpc.api.v1.ContactFilterListResponse} returns this
*/
proto.grpc.api.v1.ContactFilterListResponse.prototype.setContactfilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ContactFilterListResponse} returns this
 */
proto.grpc.api.v1.ContactFilterListResponse.prototype.clearContactfilter = function() {
  return this.setContactfilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ContactFilterListResponse.prototype.hasContactfilter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.EditorTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.EditorTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.EditorTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    editortemplate: (f = msg.getEditortemplate()) && editor_template_pb.EditorTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.EditorTemplateRequest}
 */
proto.grpc.api.v1.EditorTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.EditorTemplateRequest;
  return proto.grpc.api.v1.EditorTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.EditorTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.EditorTemplateRequest}
 */
proto.grpc.api.v1.EditorTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new editor_template_pb.EditorTemplate;
      reader.readMessage(value,editor_template_pb.EditorTemplate.deserializeBinaryFromReader);
      msg.setEditortemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.EditorTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.EditorTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.EditorTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditortemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      editor_template_pb.EditorTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditorTemplate editorTemplate = 1;
 * @return {?proto.grpc.api.v1.EditorTemplate}
 */
proto.grpc.api.v1.EditorTemplateRequest.prototype.getEditortemplate = function() {
  return /** @type{?proto.grpc.api.v1.EditorTemplate} */ (
    jspb.Message.getWrapperField(this, editor_template_pb.EditorTemplate, 1));
};


/**
 * @param {?proto.grpc.api.v1.EditorTemplate|undefined} value
 * @return {!proto.grpc.api.v1.EditorTemplateRequest} returns this
*/
proto.grpc.api.v1.EditorTemplateRequest.prototype.setEditortemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.EditorTemplateRequest} returns this
 */
proto.grpc.api.v1.EditorTemplateRequest.prototype.clearEditortemplate = function() {
  return this.setEditortemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.EditorTemplateRequest.prototype.hasEditortemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.EditorTemplateIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.EditorTemplateIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.EditorTemplateIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.EditorTemplateIdRequest}
 */
proto.grpc.api.v1.EditorTemplateIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.EditorTemplateIdRequest;
  return proto.grpc.api.v1.EditorTemplateIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.EditorTemplateIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.EditorTemplateIdRequest}
 */
proto.grpc.api.v1.EditorTemplateIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.EditorTemplateIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.EditorTemplateIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.EditorTemplateIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 2;
 * @return {number}
 */
proto.grpc.api.v1.EditorTemplateIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.EditorTemplateIdRequest} returns this
 */
proto.grpc.api.v1.EditorTemplateIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.EditorTemplateListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.EditorTemplateListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.EditorTemplateListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.EditorTemplateListRequest}
 */
proto.grpc.api.v1.EditorTemplateListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.EditorTemplateListRequest;
  return proto.grpc.api.v1.EditorTemplateListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.EditorTemplateListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.EditorTemplateListRequest}
 */
proto.grpc.api.v1.EditorTemplateListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.EditorTemplateListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.EditorTemplateListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.EditorTemplateListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.EditorTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.EditorTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.EditorTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    editortemplate: (f = msg.getEditortemplate()) && editor_template_pb.EditorTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.EditorTemplateResponse}
 */
proto.grpc.api.v1.EditorTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.EditorTemplateResponse;
  return proto.grpc.api.v1.EditorTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.EditorTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.EditorTemplateResponse}
 */
proto.grpc.api.v1.EditorTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new editor_template_pb.EditorTemplate;
      reader.readMessage(value,editor_template_pb.EditorTemplate.deserializeBinaryFromReader);
      msg.setEditortemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.EditorTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.EditorTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.EditorTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditortemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      editor_template_pb.EditorTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditorTemplate editorTemplate = 1;
 * @return {?proto.grpc.api.v1.EditorTemplate}
 */
proto.grpc.api.v1.EditorTemplateResponse.prototype.getEditortemplate = function() {
  return /** @type{?proto.grpc.api.v1.EditorTemplate} */ (
    jspb.Message.getWrapperField(this, editor_template_pb.EditorTemplate, 1));
};


/**
 * @param {?proto.grpc.api.v1.EditorTemplate|undefined} value
 * @return {!proto.grpc.api.v1.EditorTemplateResponse} returns this
*/
proto.grpc.api.v1.EditorTemplateResponse.prototype.setEditortemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.EditorTemplateResponse} returns this
 */
proto.grpc.api.v1.EditorTemplateResponse.prototype.clearEditortemplate = function() {
  return this.setEditortemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.EditorTemplateResponse.prototype.hasEditortemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.EditorTemplateListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.EditorTemplateListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.EditorTemplateListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    editortemplate: (f = msg.getEditortemplate()) && editor_template_pb.EditorTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.EditorTemplateListResponse}
 */
proto.grpc.api.v1.EditorTemplateListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.EditorTemplateListResponse;
  return proto.grpc.api.v1.EditorTemplateListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.EditorTemplateListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.EditorTemplateListResponse}
 */
proto.grpc.api.v1.EditorTemplateListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new editor_template_pb.EditorTemplate;
      reader.readMessage(value,editor_template_pb.EditorTemplate.deserializeBinaryFromReader);
      msg.setEditortemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.EditorTemplateListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.EditorTemplateListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.EditorTemplateListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.EditorTemplateListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditortemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      editor_template_pb.EditorTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditorTemplate editorTemplate = 1;
 * @return {?proto.grpc.api.v1.EditorTemplate}
 */
proto.grpc.api.v1.EditorTemplateListResponse.prototype.getEditortemplate = function() {
  return /** @type{?proto.grpc.api.v1.EditorTemplate} */ (
    jspb.Message.getWrapperField(this, editor_template_pb.EditorTemplate, 1));
};


/**
 * @param {?proto.grpc.api.v1.EditorTemplate|undefined} value
 * @return {!proto.grpc.api.v1.EditorTemplateListResponse} returns this
*/
proto.grpc.api.v1.EditorTemplateListResponse.prototype.setEditortemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.EditorTemplateListResponse} returns this
 */
proto.grpc.api.v1.EditorTemplateListResponse.prototype.clearEditortemplate = function() {
  return this.setEditortemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.EditorTemplateListResponse.prototype.hasEditortemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.SelectedContactsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.SelectedContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.SelectedContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.SelectedContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.SelectedContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.SelectedContactsRequest}
 */
proto.grpc.api.v1.SelectedContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.SelectedContactsRequest;
  return proto.grpc.api.v1.SelectedContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.SelectedContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.SelectedContactsRequest}
 */
proto.grpc.api.v1.SelectedContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.SelectedContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.SelectedContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.SelectedContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.SelectedContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 ids = 2;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.SelectedContactsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.SelectedContactsRequest} returns this
 */
proto.grpc.api.v1.SelectedContactsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.SelectedContactsRequest} returns this
 */
proto.grpc.api.v1.SelectedContactsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.SelectedContactsRequest} returns this
 */
proto.grpc.api.v1.SelectedContactsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.MergeContactsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MergeContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MergeContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MergeContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && contact_pb.Contact.toObject(includeInstance, f),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    employee: (f = msg.getEmployee()) && employee_pb.Employee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MergeContactsRequest}
 */
proto.grpc.api.v1.MergeContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MergeContactsRequest;
  return proto.grpc.api.v1.MergeContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MergeContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MergeContactsRequest}
 */
proto.grpc.api.v1.MergeContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new contact_pb.Contact;
      reader.readMessage(value,contact_pb.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 3:
      var value = new employee_pb.Employee;
      reader.readMessage(value,employee_pb.Employee.deserializeBinaryFromReader);
      msg.setEmployee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MergeContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MergeContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MergeContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      contact_pb.Contact.serializeBinaryToWriter
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getEmployee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      employee_pb.Employee.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.grpc.api.v1.Contact}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.getContact = function() {
  return /** @type{?proto.grpc.api.v1.Contact} */ (
    jspb.Message.getWrapperField(this, contact_pb.Contact, 1));
};


/**
 * @param {?proto.grpc.api.v1.Contact|undefined} value
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
*/
proto.grpc.api.v1.MergeContactsRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 ids = 2;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional Employee employee = 3;
 * @return {?proto.grpc.api.v1.Employee}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.getEmployee = function() {
  return /** @type{?proto.grpc.api.v1.Employee} */ (
    jspb.Message.getWrapperField(this, employee_pb.Employee, 3));
};


/**
 * @param {?proto.grpc.api.v1.Employee|undefined} value
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
*/
proto.grpc.api.v1.MergeContactsRequest.prototype.setEmployee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MergeContactsRequest} returns this
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.clearEmployee = function() {
  return this.setEmployee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MergeContactsRequest.prototype.hasEmployee = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.DeleteContactsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.DeleteContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.DeleteContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.DeleteContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    employee: (f = msg.getEmployee()) && employee_pb.Employee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.DeleteContactsRequest}
 */
proto.grpc.api.v1.DeleteContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.DeleteContactsRequest;
  return proto.grpc.api.v1.DeleteContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.DeleteContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.DeleteContactsRequest}
 */
proto.grpc.api.v1.DeleteContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 3:
      var value = new employee_pb.Employee;
      reader.readMessage(value,employee_pb.Employee.deserializeBinaryFromReader);
      msg.setEmployee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.DeleteContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.DeleteContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.DeleteContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getEmployee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      employee_pb.Employee.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 ids = 2;
 * @return {!Array<number>}
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.api.v1.DeleteContactsRequest} returns this
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.DeleteContactsRequest} returns this
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.DeleteContactsRequest} returns this
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional Employee employee = 3;
 * @return {?proto.grpc.api.v1.Employee}
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.getEmployee = function() {
  return /** @type{?proto.grpc.api.v1.Employee} */ (
    jspb.Message.getWrapperField(this, employee_pb.Employee, 3));
};


/**
 * @param {?proto.grpc.api.v1.Employee|undefined} value
 * @return {!proto.grpc.api.v1.DeleteContactsRequest} returns this
*/
proto.grpc.api.v1.DeleteContactsRequest.prototype.setEmployee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.DeleteContactsRequest} returns this
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.clearEmployee = function() {
  return this.setEmployee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.DeleteContactsRequest.prototype.hasEmployee = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.SearchContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.SearchContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.SearchContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.SearchContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.SearchContactsRequest}
 */
proto.grpc.api.v1.SearchContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.SearchContactsRequest;
  return proto.grpc.api.v1.SearchContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.SearchContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.SearchContactsRequest}
 */
proto.grpc.api.v1.SearchContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.SearchContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.SearchContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.SearchContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.SearchContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string filter = 1;
 * @return {string}
 */
proto.grpc.api.v1.SearchContactsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.SearchContactsRequest} returns this
 */
proto.grpc.api.v1.SearchContactsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.grpc.api.v1);
