import MessageHandlebar from '@/model/MessageHandlebar';
import i18n from '@/plugins/i18n';

const className = 'handlebar';

export function textWithHandlebarsToHtml(text: String | null, handlebars:MessageHandlebar[]): string {
  if (!text) return '<p></p>';

  let newtext = text;

  handlebars.forEach((handlebar) => {
    const name = i18n.t(`code.handlebar.${handlebar.name}`);
    const r = `<span data-handlebar-id="${handlebar.id}" label="${name}" class="${className}">${name}</span>`;
    newtext = newtext.replace(new RegExp(handlebar.expression, 'g'), r);
  });

  newtext = newtext.replace(/(?:\r\n|\r|\n)/g, '</p><p>');
  return `<p>${newtext}</p>`;
}

export function htmlToTextWithHandlebars(
  text: string | null,
  handlebarsById: Map<number, MessageHandlebar>,
): string | null {
  if (!text) return null;
  let newtext = text;
  if (newtext.startsWith('<p>')) newtext = newtext.substring(3);
  if (newtext.endsWith('</p>')) newtext = newtext.substring(0, newtext.length - 4);
  newtext = newtext.replace(/<\/p><p>/g, '\n');

  const regexp = `<span data-handlebar-id="([\\d]+)" label="[^"]*" class="${className}">([^<]*)</span>`;

  // eslint-disable-next-line no-constant-condition
  while (1) {
    const result = newtext.match(regexp);
    if (!result) break;
    const [match, id, name] = [...result];
    const replacement = handlebarsById.get(Number(id))?.expression ?? name ?? '';
    newtext = newtext.replace(new RegExp(match, 'g'), replacement);
  }
  return newtext;
}
