/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import { toMapById } from '@/model/model-utils';

export interface IModel<PBT, PBR, MAP> {
  new?(): IModel<PBT, PBR, MAP>,
  id: number,
  isDeleted?: boolean,
  // compare(o: IModel<PBT, PBR, MAP>): number,
  // fromGrpcResponse(r: PBR, m?: MAP): IModel<PBT, PBR, MAP>,
  // fromGrpcModel(o?: PBT, m?: MAP): IModel<PBT, PBR, MAP>,
}

function findIndex<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], startIndex: number, predicate: (o: T) => boolean): number {
  for (let i = startIndex; i < entities.length; i += 1) { if (predicate(entities[i])) return i; }
  return -1;
}

function sortedIndex<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], entity: T, startIndex: number, compare: (o1: T, o2: T) => number, equal = false): number {
  let low = startIndex;
  let high = entities.length;
  if (high === 0) return equal ? -1 : 0;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);
    const res = compare(entities[mid], entity);
    if (res === 0) return mid;
    if (res < 0) low = mid + 1;
    else high = mid;
  }

  return equal ? -1 : high;
}

function testSortedModelEntities<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], compare: (o1: T, o2: T) => number) {
  for (let i = 1; i < entities.length; i += 1) {
    if (compare(entities[i - 1], entities[i]) > 0) throw Error('entities in wrong order!');
  }
}

/*
export function pushToSortedModelEntities<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], newEntities: T[], compare: (o1: T, o2: T) => number) {
  newEntities.sort(compare);

  let index = -1;
  newEntities.forEach((o) => {
    if (o.isDeleted === true) return;
    index = sortedIndex(entities, o, index + 1, compare);
    entities.splice(index, 0, o);
  });

  // debug test sorted
  if (process.env.NODE_ENV === 'development') testSortedModelEntities(entities, compare);
}

export function mergeSortedModelEntity<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], entity: T, compare: (o1: T, o2: T) => number) {
  // find as if unsorted, order of new entity migt be different
  const i = entities.findIndex((o) => o.id === entity.id);

  // existing entity -> remove
  if (i >= 0) entities.splice(i, 1);

  // nondeleted entity -> insert
  if (entity.isDeleted !== true) {
    const index = sortedIndex(entities, entity, 0, compare);
    entities.splice(index, 0, entity);
  }

  // debug test sorted
  if (process.env.NODE_ENV === 'development') testSortedModelEntities(entities, compare);
}

export function deleteModelEntity<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], entity: T, compare: (o1: T, o2: T) => number) {
  const i = entities.findIndex((o) => o.id === entity.id);
  if (i >= 0) entities.splice(i, 1);

  // debug test sorted
  if (process.env.NODE_ENV === 'development') testSortedModelEntities(entities, compare);
}
*/

export function mergeSortedModelEntities<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], newEntities: T[], compare: (o1: T, o2: T) => number): T[] {
  const addedEntities = [] as T[];

  let index = -1;
  newEntities.forEach((o) => {
    if (o.isDeleted) return;
    index = sortedIndex(entities, o, index + 1, compare);
    entities.splice(index, 0, o);
    addedEntities.push(o);
  });

  // debug test sorted
  if (process.env.NODE_ENV === 'development') testSortedModelEntities(entities, compare);

  return addedEntities;
}

export function deleteModelEntities<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(entities: T[], deleteEntities: T[]) {
  // find as if unsorted, order of new entities might be different
  const newEntitiesById = toMapById(deleteEntities);
  const deletedEntities = [] as T[];

  let index = 0;
  for (;;) {
    index = findIndex(entities, index, (o) => newEntitiesById.has(o.id));
    if (index < 0) break;
    deletedEntities.push(...entities.splice(index, 1));
  }

  return deletedEntities;
}

export function addToCountMap<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(map: Map<string, number>, entities: T[], keyf: (o: T)=>string) {
  entities.forEach((e) => {
    const key = keyf(e);
    if (key) map.set(key, (map.get(key) ?? 0) + 1);
  });
}

export function removeFromCountMap<PBT, PBR, MAP, T extends IModel<PBT, PBR, MAP>>(map: Map<string, number>, entities: T[], keyf: (o: T)=>string) {
  entities.forEach((e) => {
    const key = keyf(e);
    if (key) map.set(key, (map.get(key) ?? 1) - 1);
  });
}
