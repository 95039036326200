
import Vue, { PropType } from 'vue';
import moment from 'moment';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { performAction, performSaveAction } from '@/services/vue-utils';
import DurationRule from '@/model/DurationRule';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';
import DialogClosable from '@/mixins/dialog-closable';
import { ValidationObserver } from 'vee-validate';

export default Vue.extend({
  name: 'EditDurationRuleDialog',
  components: { ConfirmationDialog },
  mixins: [DialogClosable],
  props: {
    durationRule: { type: Object as PropType<DurationRule>, required: true },
  },
  data() {
    return {
      deleteConfirmation: false,
      rule: new DurationRule(),
      oldRule: new DurationRule(),
      guestsItems: [...new Array(99)].map((e, i) => ({ value: i + 1, text: this.$i18n.t('label.x_guests', [i + 1]) })),
      additionalTimeItems: [...new Array(7)].map((e, i) => {
        const minutes = i === 0 ? i - 1 * 15 : i * 15;
        const text = this.$localized.durationFromSeconds(minutes * 60);
        return { text, value: minutes };
      }),

    };
  },
  computed: {
    allDay(): boolean {
      const date = moment(this.rule.timeFrom ?? '', 'HH:mm');
      return !date.isValid();
    },
    isNew(): boolean {
      return !this.rule.id;
    },
    isChanged(): boolean {
      return !isModelEqualToModel(this.rule, this.oldRule);
    },

  },
  watch: {
    visible() {
      this.reset();
    },
  },
  mounted() {

  },
  methods: {
    reset() {
      this.rule = cloneModel(this.durationRule);
      this.oldRule = cloneModel(this.durationRule);
    },
    close() {
      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
      this.$emit('close');
    },
    async save() {
      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch('sendDurationRule', { entity: this.rule }),
      );

      if (ok) {
        this.close();
      }
    },
    cancel() {
      this.close();
    },
    async remove() {
      const ok = await performAction(
        this.$tc('message.success_rule_deleted'),
        this.$tc('error.deleting_rule'),
        async () => this.$tstore.dispatch('sendDurationRule', { entity: this.rule, delete: true }),
      );
      if (ok) {
        this.close();
      }
    },
    changeAllDay(toAllDay: boolean) {
      this.rule.timeFrom = toAllDay ? '' : '10:00';
      this.rule.timeTo = toAllDay ? '' : '14:00';
    },
  },
});
