
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import DialogClosable from '@/mixins/dialog-closable';
import { performAction } from '@/services/vue-utils';
import toast from '@/plugins/toast-plugin/toast';

export default Vue.extend({
  name: 'ResetPasswordDialog',
  mixins: [DialogClosable],
  data() {
    return {
      email: '',
      dialog: false,
    };
  },
  computed: {

  },
  watch: {
    dialog(value, oldValue) {

    },
  },
  methods: {
    reset() {
      (this.$refs.form as Vue & { reset: () => void }).reset();
      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
    },
    cancel() {
      this.reset();
      this.dialog = false;
    },
    async resetPassword() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        toast.error(this.$i18n.tc('error.validation_failed'));
        return;
      }

      const ok = await performAction(
        this.$i18n.tc('message.success_new_password_sent'),
        this.$i18n.tc('error.changing_password'),
        async () => this.$tstore.dispatch('resetPassword', { username: this.email }),
      );

      if (ok) {
        this.reset();
        this.dialog = false;
      }
    },
  },
});
