import { IApp } from '@/api/api-update';
import { boolFromValue } from '@/api/api-utils';

export interface IDTOApp extends IApp {
  isDeleted?: string | boolean;
}

export default class AppStoreApp {
  id: number;

  name: string;

  showAsMenuItem: boolean;

  isInstalled: boolean;

  constructor(o?: IApp) {
    this.id = o?.appId ?? 0;
    this.name = o?.appName ?? '';
    this.showAsMenuItem = boolFromValue(o?.showAsMenuItem) ?? false;
    this.isInstalled = boolFromValue(o?.isInstalled) ?? false;
  }

  toDto(): IDTOApp {
    const dto: IDTOApp = {
      appId: this.id,
      isInstalled: this.isInstalled,
    };
    return dto;
  }
}
