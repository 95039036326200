
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'PasswordTextField',
  props: {
    value: { type: String as PropType<string>, required: true },
    label: { type: String as PropType<string>, required: true },
  },
  data() {
    return {
      passwordStrengthScore: 0,
      val: this.value,
    };
  },
  computed: {
    passwordLength(): number {
      return (this.val || '').length;
    },
    strengthIndex(): number {
      const strength = this.passwordStrengthScore;
      if (strength <= 5) {
        return 0;
      }
      if (strength <= 7) {
        return 1;
      }
      return 2;
    },
    indicatorColor(): string {
      const colors = ['red', 'orange', 'success'];
      return colors[this.strengthIndex];
    },
    passwordStrength(): string {
      const strengths = [
        this.$i18n.tc('label.weak_password'),
        this.$i18n.tc('label.good_password'),
        this.$i18n.tc('label.secure_password'),
      ];
      return strengths[this.strengthIndex];
    },
  },
  watch: {
    value(value: string) {
      this.val = value;
    },
    val() {
      const password = this.val || '';
      const { length } = password;

      let strength = Math.min(length, 5);

      // 6 letters, one letter, one number
      const goodPasswordRx = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/;
      if (password.match(goodPasswordRx)) {
        strength += 2;
      }

      // 8 letters, one uppercase, one lowercase, one number, one special char.
      const securePasswordRx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\d\w]).{8,}$/;
      if (password.match(securePasswordRx)) {
        strength = 10;
      }

      this.passwordStrengthScore = strength;
      this.$emit('input', this.val);
    },
  },
});
