
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';

export default Vue.extend({
  name: 'ContactsMergeRow',
  components: { },
  mixins: [DialogClosable],
  props: {
    field: { type: String as PropType<string>, required: true },
    label: { type: String as PropType<string>, required: true },
    values: { type: Object as PropType<Array<string|number|boolean>>, required: true },
    selectedSide: { type: Number as PropType<number>, required: true },
    readOnly: { type: Boolean as PropType<boolean>, required: false, default: false },
  },
  data() {
    return { };
  },
  computed: { },
  watch: { },
  methods: {
    sideSelected(side: number) {
      if (this.readOnly) return;
      this.$emit('side-selected', { field: this.field, side });
    },
  },
});
