import { render, staticRenderFns } from "./RatingsBarChart.vue?vue&type=template&id=6b1b8686&scoped=true&"
import script from "./RatingsBarChart.vue?vue&type=script&lang=ts&"
export * from "./RatingsBarChart.vue?vue&type=script&lang=ts&"
import style0 from "./RatingsBarChart.vue?vue&type=style&index=0&id=6b1b8686&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1b8686",
  null
  
)

export default component.exports