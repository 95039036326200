
import Vue, { PropType } from 'vue';

import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'ServicesRevenueChart',
  components: {
    VChart,
  },
  props: {
    categoryType: { type: String as PropType<string>, default: 'day' },
    category: { type: Array as PropType<Array<Date>>, default: () => [] },
    series: { type: Array as PropType<Array<string>>, default: () => [] },
    dataset: { type: Array as PropType<Array<Array<number>>>, default: () => [] },
    totals: { type: Array as PropType<Array<number>>, default: () => [] },
    seriesRevenue: { type: Array as PropType<Array<string>>, default: () => [] },
    datasetRevenue: { type: Array as PropType<Array<Array<number>>>, default: () => [] },
  },
  data() {
    return {
      option: (this as any).createDataset(),
      themeDark,
      themeLight,
    };
  },
  computed: {
    categoryData(): string[] {
      switch (this.categoryType) {
        case 'hour': return this.category.map((d) => this.$localized.hourMinText(d)!);
        case 'month': return this.category.map((d) => this.$localized.monthText(d)!);
        default: return this.category.map((d) => this.$localized.shortDayMonthText(d)!);
      }
    },
    dark(): boolean {
      return this.$vuetify.theme.dark;
    },
    totalValue(): number {
      return this.totals.reduce((sum, n) => sum + n, 0);
    },
  },
  watch: {
    dark() { this.reset(); },
    categoryType() { this.reset(); },
    category() { this.reset(); },
    series() { this.reset(); },
    dataset() { this.reset(); },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const series = [] as any[];

      this.series.forEach((name, i) => series.push({
        name,
        type: 'bar',
        stack: 'Total',
        smooth: 0.15,
        areaStyle: {},
        symbol: 'roundRect',
        emphasis: {
          focus: 'series',
        },
        data: this.dataset[i],
        yAxisIndex: 0,
      }));

      this.seriesRevenue.forEach((name, i) => series.push({
        name: this.seriesRevenue.length === 1
          ? this.$i18n.t('label.report_revenue')
          : `${this.$i18n.t('label.report_revenue')} (${name.toUpperCase()})`,
        type: 'line',
        smooth: 0.25,
        areaStyle: {
          color: 'transparent',
        },
        lineStyle: {
          color: ['#ffc24e', '#e42dd0', '#00a26f'][i % 3],
          width: 3,
        },
        itemStyle: {
          color: ['#ffc24e', '#e42dd0', '#00a26f'][i % 3],
        },
        symbol: 'roundRect',
        emphasis: {
          focus: 'series',
        },
        data: this.datasetRevenue[i],
        yAxisIndex: 1,
      }));

      return {
        grid: {
          left: '8px',
          top: '16px',
          right: '2%',
          containLabel: true,
        },
        dataset: {
          source: this.dataset,
          dimensions: ['date', 'thisYear', 'lastYear'],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
          confine: false,
          position(pos: number[], params: any, dom: any, rect: any, size: {contentSize: number[], viewSize: number[]}) {
            return {
              left: pos[0] + (pos[0] < size.viewSize[0] * 0.75 ? 60 : -size.contentSize[0] - 60),
              top: pos[1] - 40,
            };
          },
          appendToBody: true,
          extraCssText: 'min-width: 200px; max-width: 600px; z-index: 1000',
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
          type: 'scroll',
        },
        xAxis: {
          type: 'category',
          data: this.categoryData,
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
          splitLine: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',
          },
        ],
        series,
      };
    },
  },
});

