
import Vue, { PropType } from 'vue';
import Reservation, { ReservationStatus } from '@/model/Reservation';
import { darkenColor } from '@/services/color-utils';
import TabItem from '@/model/TabItem';
import TimeTableStripedFill from '@/components/views/tables/TimeTableStripedFill.vue';
import FloorplanShapeTableRect from './FloorplanShapeTableRect.vue';
import FloorplanShapeTableRound from './FloorplanShapeTableRound.vue';
import { TableSeatType, ITableSize } from './floorplan-types';

export default Vue.extend({
  name: 'FloorplanTable',
  components: {
    FloorplanShapeTableRect,
    FloorplanShapeTableRound,
    TimeTableStripedFill,
  },
  mixins: [],
  props: {
    tabItem: { type: Object as PropType<TabItem>, required: true },
    editable: { type: Boolean, required: false, default: false },
    reservations: { type: Object as PropType<Reservation[] | undefined>, required: false, default: undefined },
    upcomingReservations: { type: Object as PropType<Reservation[] | undefined>, required: false, default: undefined },
    selected: { type: Boolean, required: false, default: false },
    overbooked: { type: Boolean, required: false, default: false },
    zoom: { type: Number, required: false, default: 1 },
  },
  data() {
    return { };
  },
  computed: {
    title(): string {
      return `${this.tabItem.no}`;
    },
    reservation(): Reservation | undefined {
      return this.reservations?.find(Boolean);
    },
    upcomingReservation(): Reservation | undefined {
      return this.upcomingReservations?.find(Boolean);
    },
    // Colors
    fill(): string {
      if (this.isBlock || this.reservation?.holdTabItems) {
        return `url(#fp-pattern-stripe-${this.reservation?.id})`;
      }
      return this.fillColor;
    },
    fillColor(): string {
      if (this.upcomingReservation) {
        return this.$vuetify.theme.dark ? '#3F3F49' : '#C6C6C8';
      }

      if (this.reservation === undefined) {
        return this.$vuetify.theme.dark ? '#1E1E2B' : '#f9f9f9';
      }

      if (this.isBlock) {
        return this.$vuetify.theme.dark ? '#666668' : '#C6C6C8';
      }

      if (this.overbooked) {
        return '#FF002B';
      }

      // TODO MAYBE refactor with Timetable, it's the same colors probably
      switch (this.reservation.status) {
        case ReservationStatus.CheckedIn: return '#FF8262'; // 'orange';
        case ReservationStatus.Seated: return '#00A26F'; // 'green';
        case ReservationStatus.NonConfirmed: return '#E42DD0'; // 'purple';
        case ReservationStatus.CheckedOut: return '#858593'; // gray-50
        default: return '#3355FF'; // primary;
      }
    },
    // Partysize & Name
    partySizeTitle(): string | null {
      if (this.isBlock || this.upcomingReservation?.isBlock) {
        return this.$i18n.tc('label.blocked');
      }
      if (this.reservation) {
        return `${this.reservation.partySize} p. ${this.reservation.nameText}`;
      }
      if (this.upcomingReservation) {
        return `${this.upcomingReservation.partySize} p.`;
      }
      return null;
    },
    partySizeYpos(): number {
      return this.subtitleYpos + 13;
    },
    // Subtitle
    subtitle(): string | null {
      if (this.reservation) {
        const start = this.$localized.hourMinText(this.reservation.dateBegin);
        const end = this.$localized.hourMinText(this.reservation.dateEnd);
        return `${start} - ${end}`;
      } if (this.upcomingReservation) {
        const start = this.$localized.hourMinText(this.upcomingReservation.dateBegin);
        return `${this.$i18n.t('label.next')} ${start}`;
      }
      return null;
    },
    subtitleYpos(): number {
      return (this.tableSize.h + 42) / 2;
    },
    subtitleColor(): string {
      return this.titleColor;
    },
    subtitleStrokeColor(): string {
      return this.strokeColor;
    },
    // TITLE
    titleColor(): string {
      if (this.subtitle === null) {
        return this.$vuetify.theme.dark ? '#D6D6D8' : '#171721';
      }
      return 'white';
    },
    titleYpos(): number | string {
      if (this.subtitle === null) {
        return '50%';
      }
      return ((this.tableSize.h + 60) / 2) - 26;
    },
    titleStrokeColor(): string {
      if (this.subtitle === null) {
        return 'transparent';
      }
      return this.strokeColor;
    },
    strokeColor(): string {
      if ((!this.upcomingReservation && this.reservation === undefined) || this.isBlock) {
        return this.$vuetify.theme.dark ? '#D6D6D8' : '#171721';
      }
      return darkenColor(this.fillColor, this.$vuetify.theme.dark ? -41 : 51);
    },

    // Position
    x(): number {
      return (this.tabItem.posX || 0) - ((this.tableSize.h + 60) / 2);
    },
    y(): number {
      return (this.tabItem.posY || 0) - ((this.tableSize.h + 60) / 2);
    },
    a(): number {
      return this.tabItem.rotation || 0;
    },

    // Font Sizes
    titleFontSize(): number {
      if (this.subtitle !== null) {
        return 20;
      }
      if (this.tabItem.shape === TableSeatType.ROUNDED) {
        return this.tabItem.seats <= 4 ? 28 : 42;
      }
      return this.tabItem.seats <= 4 ? 36 : 42;
    },

    //
    tableSize(): ITableSize {
      const { seats } = this.tabItem;
      if (this.tabItem.shape === TableSeatType.SQUARED) {
        if (seats <= 1) { return { w: 40, h: 40 }; }
        if (seats <= 2) { return { w: 80, h: 80 }; }
        return { w: 80, h: Math.min(300, 80 + Math.trunc((seats - 1) / 2.0) * 40.0) };
      }
      if (this.tabItem.shape === TableSeatType.HEADSEAT) {
        if (seats <= 1) { return { w: 40, h: 40 }; }
        if (seats <= 2) { return { w: 80, h: 80 }; }
        return { w: 80, h: Math.min(300, 80 + Math.trunc((seats - 3) / 2.0) * 40.0) };
      }
      if (this.tabItem.shape === TableSeatType.ROUNDED) {
        if (seats <= 1) { return { w: 40, h: 40 }; }
        if (seats <= 2) { return { w: 60, h: 60 }; }
        return {
          w: Math.min(300, 40 + Math.trunc((seats - 1) / 2.0) * 20.0),
          h: Math.min(300, 40 + Math.trunc((seats - 1) / 2.0) * 20.0),
        };
      }
      return { w: 0, h: 0 };
    },
    isBlock(): boolean {
      return this.reservation !== undefined && this.reservation.isBlock;
    },
  },
  methods: {
    select() {
      this.$emit('selected', this.tabItem.id);
    },
    rotateCcw() {
      this.$emit('rotate', this.tabItem.id, -22.5);
    },
    rotateCw() {
      this.$emit('rotate', this.tabItem.id, 22.5);
    },
    changeTableShape(shape: string) {
      this.$emit('change-shape', this.tabItem.id, shape);
    },
    removeTable() {
      this.$emit('remove', this.tabItem.id);
    },
    onRightClick(e: MouseEvent) {
      this.$emit('context-menu', this.reservation, e);
    },
    darkenColor(c: string, n: number): string {
      return darkenColor(c, n);
    },
  },
});

