

import Vue, { PropType } from 'vue';
import Reservation from '@/model/Reservation';
import GuestListRow from '@/components/views/guests/GuestListRow.vue';
import GuestListHeaderRow from '@/components/views/guests/GuestListHeaderRow.vue';
import { tryEditReservation } from '@/services/reservation-edit-utils';

export default Vue.extend({
  name: 'GuestResultsList',
  components: { GuestListRow, GuestListHeaderRow },
  props: {
    reservations: { type: Map as PropType<Map<Date, Reservation[]>>, required: true },
    showUnread: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      selected: undefined as undefined | number,
    };
  },
  computed: {
    reservationEntries(): [Date, Reservation[]][] {
      const entries = Array.from(this.reservations.entries());
      const sortedEntries = entries.sort((o1, o2) => o2[0].getTime() - o1[0].getTime());
      return sortedEntries;
    },
  },
  methods: {
    async selectedChange() {
      if (this.selected === undefined) { return; }

      const reservationId = this.selected;
      const res = Array.from(this.reservations.values()).flat(1).find((r) => r.id === reservationId);
      if (res === undefined) { return; }

      const retVal = await tryEditReservation(this.$router, res, {}, { forceGuestsRoute: true });

      Vue.nextTick(() => {
        this.selected = undefined;
      });

      this.$emit('close', retVal);
    },
  },
});

