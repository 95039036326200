
import Vue from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import HourSlot from '@/model/HourSlot';

export default Vue.extend({
  mixins: [DialogClosable],
  data() {
    return {
      partySizeList: [...new Array(200)].map((e, i) => (i + 1)),
      endingTimeList: [...new Array(24)].map((e, i) => ((i + 1) * 15)),
      hs: new HourSlot(),
    };
  },
  computed: {
    isChanged(): boolean {
      return this.hs.isCustom;
    },
  },
  mounted() {
  },
  methods: {
    close() {
      (this.$refs.form as Vue & { reset: () => void }).reset();
      this.$emit('close');
    },
    cancel() {
      this.close();
    },
    save() {
      this.$emit('save-result', this.hs);
      this.close();
    },
  },
});
