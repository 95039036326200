
import Vue, { PropType } from 'vue';
import { dateStringFromDate8601, dateFromDateString8601 } from '@/services/time-utils';

export default Vue.extend({
  name: 'DatePickerField',
  props: {
    value: { type: Object as PropType<Date>, required: true },
    formatFn: { type: Function as PropType<((d: Date) => string)>, required: false, default: undefined },
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: 'date' },
    dense: { type: Boolean, required: false, default: false },
    hideDetails: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, required: false, default: false },
    errorMessages: { type: Array, required: false, default: null },
    contentClass: { type: String, required: false, default: '' },
    minDate: { type: Object as PropType<Date|undefined>, required: false, default: undefined },
  },
  data() {
    return {
      date: dateStringFromDate8601(this.value),
      menu: null,
    };
  },
  computed: {
    formattedDate(): string | null {
      if (this.formatFn !== undefined) {
        const d = dateFromDateString8601(this.date!)!;
        return this.formatFn(d);
      }
      return this.date;
    },
  },
  watch: {
    value() {
      this.date = dateStringFromDate8601(this.value);
    },
  },
  methods: {
    clear() {
      this.date = '';
      this.updateValue();
    },
    fmtDateStringFromDate8601(d: Date | undefined) {
      return d ? dateStringFromDate8601(d) : undefined;
    },
    updateValue() {
      this.$emit('input', dateFromDateString8601(this.date!));
    },
  },
});
