import { GetterTree, MutationTree, ActionTree } from 'vuex';
import httpClient from '@/api/http-client';
import {
  toSaveModelItems, mergeToModelEntities, groupByField,
} from '@/model/model-utils';
import Tab from '@/model/Tab';
import Allocation, { IDTOSendAllocation, AllocationMapping } from '@/model/Allocation';
import IRootState, { IAllocationsState } from './store-state';

export class AllocationsState implements IAllocationsState {
  allocations: Allocation[] = [];

  tab: Tab = new Tab();

  isLoaded: boolean = false;
}

const mutations = <MutationTree<IAllocationsState>>{
  RESET(state: AllocationsState) {
    Object.assign(state, new AllocationsState());
  },
  RESET_ALLOCATIONS(state: AllocationsState) {
    Object.assign(state, new AllocationsState());
  },
  UPDATE_ALLOCATIONS(state: AllocationsState, p: { allocations: Allocation[], tab: Tab }) {
    console.log('UPDATE_ALLOCATIONS');

    state.allocations = p.allocations;
    state.tab = p.tab;

    console.log('allocations:', state.allocations);
  },
};

const actions = <ActionTree<IAllocationsState, IRootState>>{
  async resetAllocations({ commit }) {
    commit('RESET_ALLOCATIONS');
  },
  async loadAllocations({
    commit, state, rootGetters,
  }, p: { tabId: number }) {
    if (!rootGetters.isLoaded) return;

    const updates = await httpClient.loadAllocations(p.tabId);
    const allocations = mergeToModelEntities(
      Allocation,
      [],
      updates.allocations,
      { mapping: rootGetters as AllocationMapping },
    );

    const tab = rootGetters.tabsById.get(p.tabId) ?? new Tab();
    commit('UPDATE_ALLOCATIONS', { allocations, tab });
  },
  async sendAllocations(
    { state, commit, rootGetters },
    p: { entities: Allocation[], partySize: number, isForbidden?: boolean },
  ) {
    const oldEntities = state.allocations.filter(
      (o) => o.partySize === p.partySize && (p.isForbidden === undefined || o.isForbidden === p.isForbidden),
    );
    const dtos = toSaveModelItems<IDTOSendAllocation, Allocation>(p.entities, oldEntities);

    const updates = await httpClient.sendAllocations(state.tab.id, p.partySize, dtos);

    const allocations = mergeToModelEntities(
      Allocation,
      state.allocations,
      updates.allocations,
      { mapping: rootGetters as AllocationMapping },
    );

    commit('UPDATE_ALLOCATIONS', { allocations, tab: state.tab });
  },
};

const getters = <GetterTree<IAllocationsState, IRootState>>{
  allowedAllocationsByPartySize(state) {
    const allocations = state.allocations.filter((o) => o.isForbidden === false).sort((o1, o2) => o1.order - o2.order);
    return groupByField(allocations, 'partySize');
  },
  disallowedAllocationsByPartySize(state) {
    const allocations = state.allocations.filter((o) => o.isForbidden === true).sort((o1, o2) => o1.order - o2.order);
    return groupByField(allocations, 'partySize');
  },
};

const AllocationsStore = {
  namespaced: false,
  state: new AllocationsState(),
  mutations,
  actions,
  getters,
};

export default AllocationsStore;
