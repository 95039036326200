
import httpClient from '@/api/http-client';
import ValidatedTextField from '@/components/forms/ValidatedTextField.vue';
import Account from '@/model/Account';
import Country from '@/model/Country';
import Locale from '@/model/Locale';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';
import TimeZone from '@/model/TimeZone';
import { performSaveAction } from '@/services/vue-utils';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import toast from '@/plugins/toast-plugin/toast';

export default Vue.extend({
  name: 'WelcomeStepAccount',
  components: { ValidatedTextField },
  props: {

  },
  data() {
    return {
      a: new Account(),
      olda: new Account(),
      unwatch: Function as (() => void),
    };
  },
  computed: {
    isChanged(): boolean {
      return !isModelEqualToModel(this.a, this.olda);
    },
    countries(): Country[] {
      return this.$tstore.getters.sortedCountries;
    },
    locales(): Locale[] {
      return this.$tstore.getters.sortedLocales;
    },
    timezones(): TimeZone[] {
      return this.$tstore.getters.sortedTimezones;
    },
    defaultCC(): string {
      return this.a.countryCode || '';
    },
  },
  mounted() {
    this.reset();
  },
  created() {
    this.unwatch = this.$tstore.watch((state, getters) => getters.isLoaded, (newValue, oldValue) => { this.reset(); });
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    reset() {
      const a = this.$tstore.state.settings.account;
      this.a = cloneModel(a);
      this.olda = a;

      if (!this.a.countryCode) this.autofillCountry();
    },
    async autofillCountry() {
      try {
        const countryCode = await httpClient.getCountryCode();
        if (!countryCode || this.a.countryCode) return; // no country code detected or no need to update it any more
        Vue.set(this.a, 'countryCode', countryCode); // autofill country code
      } catch (e) {
        console.log('Autofill country code error: ', e);
      }
    },
    // eslint-disable-next-line vue/no-unused-properties
    async submit() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        toast.error(this.$i18n.tc('error.validation_failed'));
        return;
      }

      if (!this.isChanged) {
        this.$emit('advance');
        return;
      }

      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch('sendAccount', { entity: this.a }),
      );
      if (ok) {
        this.reset();
        this.$emit('advance');
      }
    },
  },
});
