export default {
  guestplan: {
    themes: {
      light: {
        primary: '#3355FF',
        secondary: '#1C1A27',
        accent: '#FF8262',
        brand: '#3355FF',
        error: '#FF002B',
        info: '#3355FF',
        success: '#00A26F',
        warning: '#FFC24E',
      },
      dark: {
        primary: '#fff',
        secondary: '#1C1A27',
        accent: '#FF8262',
        brand: '#3355FF',
        error: '#FF002B',
        info: '#3355FF',
        success: '#00A26F',
        warning: '#FFC24E',
      },
    },
  },
  booq: {
    themes: {
      light: {
        primary: '#3355FF',
        secondary: '#1C1A27',
        accent: '#FF8262',
        brand: '#C7017F',
        error: '#FF002B',
        info: '#3355FF',
        success: '#00A26F',
        warning: '#FFC24E',
      },
      dark: {
        primary: '#fff',
        secondary: '#1C1A27',
        accent: '#FF8262',
        brand: '#C7017F',
        error: '#FF002B',
        info: '#3355FF',
        success: '#00A26F',
        warning: '#FFC24E',
      },
    },
  },
} as any;
