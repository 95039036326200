import { GetterTree, MutationTree, ActionTree } from 'vuex';
import httpClient from '@/api/http-client';
import Campaign from '@/model/Campaign';
import {
  toModelEntities, toMapById, mergeToModelEntities,
} from '@/model/model-utils';
import CampaignFilter, { CampaignFilterMapping, compareCampaignFilter } from '@/model/CampaignFilter';
import IRootState, { ICampaignFilterState, IMessageCenterState } from './store-state';

export class CampaignFilterState implements ICampaignFilterState {
  campaigns: Campaign[] = [];

  campaignFilters: CampaignFilter[] = [];

  isLoaded: boolean = false;
}

const mutations = <MutationTree<ICampaignFilterState>>{
  RESET(state: CampaignFilterState) {
    Object.assign(state, new CampaignFilterState());
  },
  RESET_CAMPAIGN_FILTER(state: CampaignFilterState) {
    Object.assign(state, new CampaignFilterState());
  },
  UPDATE_CAMPAIGN_FILTER(state: CampaignFilterState, p: { campaigns: Campaign[], campaignFilters: CampaignFilter[] }) {
    console.log('UPDATE_CAMPAIGN_FILTER');

    if (p.campaigns) state.campaigns = p.campaigns;
    if (p.campaignFilters) state.campaignFilters = p.campaignFilters;
    state.isLoaded = true;

    console.log('Campaign filter campaigns:', state.campaigns);
    console.log('Campaign filter filters:', state.campaignFilters);
  },
};

const actions = <ActionTree<ICampaignFilterState, IRootState>>{
  async resetCampaignFilter({ commit }) {
    commit('RESET_CAMPAIGN_FILTER');
  },
  async loadCampaignFilter({ commit, state, rootGetters }) {
    if (!rootGetters.isLoaded) return;

    const update = await httpClient.loadCampaignFilter();

    const campaigns = toModelEntities(Campaign, update.campaigns, { stringOrderField: 'description' });
    const campaignsById = toMapById(campaigns);
    const campaignFilters = toModelEntities(
      CampaignFilter,
      update.campaignFilters,
      { compareFn: compareCampaignFilter, mapping: { campaignsById } as CampaignFilterMapping },
    );

    commit('UPDATE_CAMPAIGN_FILTER', { campaigns, campaignFilters });
  },
  async sendCampaignFilter({ state, commit, rootGetters }, p: { campaignFilter: CampaignFilter, isDeleted?: boolean }) {
    const dto = p.campaignFilter.toDto();
    if (p.isDeleted === true) dto.isDeleted = true;

    const update = await httpClient.sendCampaignFilter(dto);

    const campaignsById = toMapById(state.campaigns);
    const campaignFilters = mergeToModelEntities(
      CampaignFilter,
      state.campaignFilters,
      update.campaignFilters,
      { compareFn: compareCampaignFilter, mapping: { campaignsById } as CampaignFilterMapping },
    );

    commit('UPDATE_CAMPAIGN_FILTER', { campaignFilters });
  },
};

const getters = <GetterTree<ICampaignFilterState, IRootState>>{
  isCampaignFilterLoaded(state: CampaignFilterState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded && rootGetters.isLoaded;
  },
};

const CampaignFilterStore = {
  namespaced: false,
  state: new CampaignFilterState(),
  mutations,
  actions,
  getters,
};

export default CampaignFilterStore;
