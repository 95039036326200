
import Vue from 'vue';
import UnsavedGuard from '@/mixins/unsaved-guard';
import SettingsList from '@/components/views/settings/SettingsList.vue';
import TagNote from '@/model/TagNote';

export default Vue.extend({
  name: 'TagNotes',
  components: { SettingsList },
  mixins: [UnsavedGuard],
  data() { return { changed: false }; },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    isChanged(): boolean { return this.changed; },
  },
  methods: {
    async save(entities: TagNote[]) { await this.$tstore.dispatch('sendTagNotes', { entities }); },
    add(): TagNote { return new TagNote(); },
    isChangedChange(changed: boolean) { this.changed = changed; },
  },
});

