
import Vue, { PropType } from 'vue';
import TagNote from '@/model/TagNote';

export default Vue.extend({
  name: 'TagNoteMenu',
  components: { },
  props: { },
  data() {
    return { };
  },
  computed: {
    tagNotes(): TagNote[] { return this.$tstore.state.settings.tagNotes; },
  },
  methods: {
    selected(t: TagNote) {
      this.$emit('selected', t);
    },
  },
});
