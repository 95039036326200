/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import GstplnApp, { GstplnAppCategory } from '../app';

export default class DyflexisApp extends GstplnApp {
  constructor(vue: Vue) {
    super(vue, 'app.dyflexis', {
      categories: [GstplnAppCategory.Integrations],
      allowedCountries: ['NL'],
      hasMenuItem: false,
      configComponentImportFnc: () => import('@/components/apps/dyflexis/SettingsDialog.vue'),
    });
  }
}
