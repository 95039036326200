import Vue, { defineComponent } from 'vue';
import interact from 'interactjs';
import TabItem from '@/model/TabItem';
import { BoundingBox, TableSeatType } from '../floorplan-types';

const FP_CANVAS_SNAP = 20;

export default defineComponent({
  props: {
    editable: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      ti: [] as TabItem[],
      originTopScroll: 0,
      originLeftScroll: 0,
      zoom: 1,
    };
  },
  watch: {
    zoom() {
      this.destroyInteract();
      this.initInteract();
    },
  },

  mounted() {
    this.initInteract();
  },
  beforeDestroy() {
    this.destroyInteract();
  },
  methods: {
    initInteract() {
      if (!this.editable) { return; }

      interact('.fp-draggable')
        // .on('hold', (evt: any) => {
        // // console.log('[fp-canvas] Hold');
        // })
        .draggable({
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: FP_CANVAS_SNAP * this.zoom, y: FP_CANVAS_SNAP * this.zoom }),
              ],
              offset: 'parent',
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),
          ],
          inertia: false,
          autoScroll: {
            container: document.getElementById('fp-canvas-wrapper')!,
            margin: 80,
            distance: 80,
            interval: 1000,
            speed: 400,
          },
        })
        .on('dragstart', (evt) => {
        // const { target } = evt;
        // const ref = target.dataset.ref || '';

          const canvasWrapperElm = document.getElementById('fp-canvas-wrapper')! as HTMLDivElement;
          this.originTopScroll = canvasWrapperElm.scrollTop;
          this.originLeftScroll = canvasWrapperElm.scrollLeft;
        })
        // .on('move', (evt) => {

        // })
        .on('dragmove', (evt) => {
          const { target } = evt;
          const tabItemId = parseInt(target.dataset.ref, 10);
          if (tabItemId === undefined) { return; }

          const canvasWrapperElm = document.getElementById('fp-canvas-wrapper')! as HTMLDivElement;
          const diffY = canvasWrapperElm.scrollTop - this.originTopScroll;
          const diffX = canvasWrapperElm.scrollLeft - this.originLeftScroll;

          this.moveTabItem(
            tabItemId,
            evt.modifiers[0].target.x - evt.modifiers[0].target.offset.x + diffX,
            (evt.modifiers[0].target.y - evt.modifiers[0].target.offset.y + diffY),
          );
        })
        .on('dragend', (evt) => {
          console.log('dragend');

          this.originTopScroll = 0;
          this.originLeftScroll = 0;

          const { target } = evt;
          const tabId = parseInt(target.dataset.ref, 10);
          if (tabId === undefined) { return; }

          this.modelChanged(tabId);
        });
    },
    destroyInteract() {
      interact('.fp-draggable').unset();
    },
    moveTabItem(tabItemId: number, x: number, y: number) {
      // console.log(`[fp-canvas] Moved Item ${tabItemId}; delta ${x} ${y}`);

      const tabItem = this.ti.find((t) => t.id === tabItemId);
      if (!tabItem) { return; }

      // console.log(`[fp-canvas] Moved Item from ${tabItem.posX} ${tabItem.posY}`);
      tabItem.posX = x / this.zoom + this.sizeForTabItem(tabItem) / 2;
      tabItem.posY = y / this.zoom + this.sizeForTabItem(tabItem) / 2;

      // console.log(`[fp-canvas] Moved Item to ${tabItem.posX} ${tabItem.posY}`);
    },

    // eslint-disable-next-line vue/no-unused-properties
    rotateTable(tabItemId: number, angle: number) {
      // console.log(`[fp-canvas] Rotated Item ${tabItemId}; angle ${angle}`);

      const tabItem = this.ti.find((t) => t.id === tabItemId);
      if (!tabItem) { return; }

      tabItem.rotation = (tabItem.rotation || 0) + angle;
      this.$emit('update:tabItems', this.ti);
    },

    // eslint-disable-next-line vue/no-unused-properties
    removeTable(tabItemId: number) {
      const tabItem = this.ti.find((t) => t.id === tabItemId);
      if (!tabItem) { return; }

      tabItem.posX = undefined;
      tabItem.posY = undefined;
      this.$emit('update:tabItems', this.ti);
    },

    // eslint-disable-next-line vue/no-unused-properties
    changeTableShape(tabId: number, shape: string) {
      const tabItem = this.ti.find((t) => t.id === tabId);
      if (!tabItem) { return; }

      tabItem.shape = shape;
      this.$emit('update:tabItems', this.ti);
    },

    // eslint-disable-next-line vue/no-unused-properties
    placeTable(tabItem: TabItem) {
      // console.log(`[fp-canvas] Placing TabItem: ${tabItem.id}`);

      // eslint-disable-next-line no-param-reassign
      tabItem.shape = TableSeatType.SQUARED;

      const canvasWrapperElm = document.getElementById('fp-canvas-wrapper')! as HTMLDivElement;
      const maxCanvasRect = canvasWrapperElm.getBoundingClientRect();
      // (this.$refs.fpCanvasDiv as HTMLDivElement).getBoundingClientRect();
      // console.log(maxCanvasRect);

      const i = this.ti.findIndex((t) => t.id === tabItem.id);

      const size = this.sizeForTabItem(tabItem);
      // console.log(tabItem);
      // console.log(`Size: ${size}`);

      for (let y = 0; ; y += FP_CANVAS_SNAP / 2) {
        for (let x = 0; ; x += FP_CANVAS_SNAP / 2) {
          const bbox = new BoundingBox(x, y, size, size);

          // eslint-disable-next-line no-continue
          if (bbox.x1 < 0) { continue; }
          if (bbox.y1 < 0) { break; }
          if (bbox.x2 > maxCanvasRect.width) { break; }

          if (!this.isOverlappingTables(tabItem, bbox)) {
            // eslint-disable-next-line no-param-reassign
            tabItem.posX = x; tabItem.posY = y; tabItem.rotation = 0;

            Vue.set(this.ti, i, tabItem);
            this.$emit('update:tabItems', this.ti);
            return;
          }
        }
      }
    },
    isOverlappingTables(tabItem: TabItem, bbox: BoundingBox): boolean {
      return this.ti.some((t) => {
        if (t.id !== tabItem.id && t.posX !== undefined && t.posY !== undefined) {
          // console.log(`Evaluating overlap: ${t.id}`);
          // console.log(`Candidate (bbox): ${bbox.x1}, ${bbox.y1}, ${bbox.x2}, ${bbox.y2}`);
          const tSize = this.sizeForTabItem(t);
          const tBbox = new BoundingBox(t.posX || 0, t.posY || 0, tSize, tSize);
          // console.log(`existing (Tbox): ${tBbox.x1}, ${tBbox.y1}, ${tBbox.x2}, ${tBbox.y2}`);

          if (bbox.overlaps(tBbox)) { return true; }
        }
        return false;
      });
    },

    modelChanged(tabItemId: number) {
      // console.log(`[fp-canvas] Drag Ended: ${tabItemId}`);
      this.$emit('update:tabItems', this.ti);
    },

    sizeForTabItem(ti: TabItem): number {
      if (ti.shape === TableSeatType.SQUARED) {
        if (ti.seats <= 1) { return 80 + 60; }
        if (ti.seats <= 2) { return 80 + 60; }
        return Math.min(300, 80 + Math.trunc((ti.seats - 1) / 2.0) * 40.0) + 60;
      }
      if (ti.shape === TableSeatType.HEADSEAT) {
        if (ti.seats <= 1) { return 40 + 60; }
        if (ti.seats <= 2) { return 80 + 60; }
        return Math.min(300, 80 + Math.trunc((ti.seats - 3) / 2.0) * 40.0) + 60;
      }
      if (ti.shape === TableSeatType.ROUNDED) {
        if (ti.seats <= 1) { return 40 + 60; }
        if (ti.seats <= 2) { return 60 + 60; }
        return Math.min(300, 40 + Math.trunc((ti.seats - 1) / 2.0) * 20.0) + 60;
      }
      return 0;
    },

  },

});
