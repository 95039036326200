import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { dateByAddingSeconds } from '@/services/time-utils';
import { UpdateOriginator } from '@/services/store-utils';
import IRootState, { IAutoUpdateState } from './store-state';

const AUTO_UPDATE_TIME_INTERVAL_MIN = 10.0;
const AUTO_UPDATE_TIME_INTERVAL_DEFAULT = 10.0;
const PUSH_UPDATE_MIN_DELAY = 2.0;

export class AutoUpdateState implements IAutoUpdateState {
  on: boolean = false;

  scheduleDate: Date | null = null;

  counter: number = 0;
}

const mutations = <MutationTree<IAutoUpdateState>>{
  RESET(state: AutoUpdateState) {
    Object.assign(state, new AutoUpdateState());
  },
  AUTO_SET_SCHEDULED(state: AutoUpdateState, scheduleDate: Date) {
    state.scheduleDate = scheduleDate;
  },
  AUTO_START_STOP(state: AutoUpdateState, on: boolean) {
    state.on = on;
  },
  AUTO_INCREMENT_COUNTER(state: AutoUpdateState, on: boolean) {
    state.counter += 1;
  },
};

const actions = <ActionTree<IAutoUpdateState, IRootState>>{
  startAutoUpdate({ state, commit, rootGetters }): Date | null {
    if (state.on || !rootGetters.isLoggedIn) return null;
    commit('AUTO_START_STOP', true);

    if (!state.on) return null;
    return new Date();
  },
  stopAutoUpdate({ state, commit }, scheduleDate: Date) {
    commit('AUTO_START_STOP', false);
  },
  autoUpdateSetScheduled({ state, commit }, scheduleDate: Date) {
    commit('AUTO_SET_SCHEDULED', scheduleDate);
  },
  async performAutoUpdate({
    state, commit, dispatch, getters,
  }) {
    if (!state.on) {
      console.log('performAutoUpdate ERROR: autoupdate was off', state.on);
      return;
    }

    if (!state.scheduleDate || state.scheduleDate > new Date()) {
      console.log('performAutoUpdate ERROR: autoupdate was scheduled for later', state.scheduleDate);
      return;
    }

    try {
      await dispatch('update', { originator: UpdateOriginator.Auto });
    } finally {
      commit('AUTO_INCREMENT_COUNTER');
    }
  },
};

const getters = <GetterTree<IAutoUpdateState, IRootState>>{
  autoUpdateNextDate(state, localGetters, rootState, rootGetters): Date | null {
    if (!state.on) return null;

    const lastUpdateDate = rootState.update.updateDate;
    if (!lastUpdateDate) return new Date();

    const offset = localGetters.autoUpdateTimeInterval as number;
    return dateByAddingSeconds(lastUpdateDate, offset);
  },
  autoUpdateTimeInterval(state, localGetters, rootState, rootGetters) {
    const forcedAutoUpdateTimeInterval = process.env.VUE_APP_FORCED_AUTO_UPDATE_TIME_INTERVAL;
    if (forcedAutoUpdateTimeInterval) return forcedAutoUpdateTimeInterval;

    const autoUpdateTimeInterval = rootState.update.autoUpdateTimeInterval ?? AUTO_UPDATE_TIME_INTERVAL_DEFAULT;
    return autoUpdateTimeInterval < AUTO_UPDATE_TIME_INTERVAL_MIN
      ? AUTO_UPDATE_TIME_INTERVAL_MIN
      : autoUpdateTimeInterval;
  },
};

const AutoUpdateStore = {
  namespaced: false,
  state: new AutoUpdateState(),
  mutations,
  actions,
  getters,
};

export default AutoUpdateStore;
