/* eslint-disable class-methods-use-this */
import { Node } from '@tiptap/vue-2';
import { mergeAttributes } from '@tiptap/core';

export interface HandlebarOptions {}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    handlebar: {
      insertHandlebar: (options: { id: string, label: string }) => ReturnType,
    }
  }
}

export const Handlebar = Node.create<HandlebarOptions>({
  name: 'handlebar',
  group: 'inline',
  atom: true,
  inline: true,
  selectable: false,

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, { class: 'handlebar' }),
      `${node.attrs.label}`,
    ];
  },

  addAttributes() {
    return {
      id: {
        parseHTML: (elm) => elm.getAttribute('data-handlebar-id'),
        renderHTML: (attributes) => ({
          'data-handlebar-id': attributes.id,
        }),
      },
      label: {
        parseHTML: (elm) => elm.innerText,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'span[data-handlebar-id]' }];
  },

  addCommands() {
    return {
      insertHandlebar: (options) => ({ commands }) => commands.insertContent({
        type: this.name,
        attrs: options,
      }),
    };
  },

});
