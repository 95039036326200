/* eslint-disable @typescript-eslint/indent */
import Vue, { defineComponent } from 'vue';
import { performAction } from '@/services/vue-utils';
import { tryEditReservation } from '@/services/reservation-edit-utils';
import debounce from 'lodash.debounce';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DebouncedFunc } from 'lodash';

export default defineComponent({
  data() {
    return {
      debouncePreviousDay: undefined as undefined | DebouncedFunc <() => Promise<void>>,
      debounceNextDay: undefined as undefined | DebouncedFunc <() => Promise<void>>,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown);
    this.debouncePreviousDay = debounce(this.previousDay, 100, { leading: true, trailing: false });
    this.debounceNextDay = debounce(this.nextDay, 100, { leading: true, trailing: false });
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDown);
  },
  methods: {
    keyDown(evt: KeyboardEvent) {
      if (evt.target !== document.body) { return; }
      // GIP: Do not allow keyboard navigation when editor is active
      if (this.$tstore.getters.isEditReservation) { return; }
      switch (evt.keyCode) {
      case 37:
        this.debouncePreviousDay!();
        break;
      case 39:
        this.debounceNextDay!();
        break;
      case 32:
        this.createNewReservation();
        break;
      default:
        break;
      }
    },
    async nextDay() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('changeToNextDate'),
        );
    },
    async previousDay() {
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('changeToPreviousDate'),
        );
    },
    async createNewReservation() {
      await tryEditReservation(this.$router);
    },
  },
});
