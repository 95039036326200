
import Vue from 'vue';
import { Route } from 'vue-router';

export default Vue.extend({
  name: 'MenuItem',
  props: {
    icon: { type: String, required: false, default: null },
    badge: { type: String, required: false, default: null },
    labelBadge: { type: String, required: false, default: null },
    title: { type: String, required: false, default: '' },
    to: { type: String, required: false, default: null },
    href: { type: String, required: false, default: null },
    target: { type: String, required: false, default: null },
    link: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    tooltip: { type: Boolean, required: false, default: false },
    filledIcon: { type: String, required: false, default: null },
  },
  data() {
    return {
      value: this.$router.currentRoute.path === this.to,
    };
  },
  computed: {
    colorForIcon(): string {
      if (this.$vuetify.theme.dark && this.disabled) {
        return 'primary darken-3';
      }
      if (this.disabled) {
        return 'grey lighten-2';
      }
      return '';
    },
  },
  watch: {
    $route(to: Route, from: Route) {
      this.value = to.path === this.to;
    },
  },
  methods: {
    onClick() {
      this.$emit('click', true);
    },
  },
});

