
import Vue from 'vue';

export default Vue.extend({
  name: 'HelpHeader',
  props: { },
  data() {
    return {};
  },
  computed: {
    helpText(): string | null {
      const key = `help.settings.${this.$router.currentRoute.name}`;
      // console.log(key);
      return this.$i18n.tc(key) === key ? null : this.$i18n.tc(key);
    },
  },
});
