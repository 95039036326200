
import Vue from 'vue';

export default Vue.extend({
  name: 'GuestListHeaderRow',
  props: {
    title: { type: String, required: true },
  },
  computed: {
    textColor(): string {
      return this.$vuetify.theme.dark ? 'grey--text' : 'secondary--text';
    },
    backgroundColor(): string {
      return this.$vuetify.theme.dark ? 'black' : 'grey lighten-3';
    },
  },
});
