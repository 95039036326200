
import Vue from 'vue';
import User from '@/model/User';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';

export default Vue.extend({
  name: 'Users',
  components: { SettingsForm },
  mixins: [SettingsBreadcrumbs],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t('label.first_name'),
          value: 'name',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.username'),
          value: 'username',
          align: 'start',
          sortable: false,
          mdAndUp: true,
        },
        {
          text: this.$i18n.t('label.email'),
          value: 'email',
          align: 'start',
          sortable: false,
        },
      ],
      breadcrumbs: [],
    };
  },
  computed: {
    computedHeaders(): any[] {
      return this.headers.filter((e) => (!e.mdAndUp || (e.mdAndUp && this.$vuetify.breakpoint.mdAndUp)));
    },
    users(): User[] {
      const { users } = this.$tstore.state.settings;
      const activeUser = this.$tstore.getters.activeUser as User | undefined;
      if (activeUser) return [activeUser, ...users.filter((u) => u.id !== activeUser.id)];
      return users;
    },
  },

  methods: {
    edit(o: User, prop: any) {
      this.$router.push(`/settings/users/${o.id}`);
    },
    add() {
      this.$router.push('/settings/users/new');
    },
    isActive(o: User) {
      const activeUser = this.$tstore.getters.activeUser as User | undefined;
      return o.id === activeUser?.id;
    },
  },
});
