
import Vue, { PropType } from 'vue';
import moment from 'moment';

export default Vue.extend({
  name: 'TimeTableClosedSlot',
  props: {
    begin: { type: Date, required: true },
    end: { type: Date || null, required: false, default: null },
    slotWidth: { type: Number, required: true },
    rowHeight: { type: Number, required: true },
    rowSpan: { type: Number, required: false, default: 1 },
    timelineStart: { type: Date, required: true },
  },
  data() { return {}; },
  computed: {
    left(): number {
      // relative pos from left, difference between timeline and res. start
      const startTime = moment(this.begin);
      const minutesFromStart = moment.duration(startTime.diff(this.timelineStart)).asMinutes();
      const offsetFromStart = (minutesFromStart / 30) * this.slotWidth;
      return offsetFromStart;
    },
    width(): string {
      const startTime = moment(this.begin);
      if (this.end === null) { return `calc(100% - ${this.left}px`; }

      const endTime = moment(this.end);
      const duration = moment.duration(endTime.diff(startTime)).asMinutes();
      return `${(duration / 30) * this.slotWidth}px`;
    },
    height(): number {
      return (this.rowSpan * this.rowHeight) - 1;
    },
  },
});
