import { IAttachment } from '@/api/api-update';

export interface IDTOAttachment extends IAttachment { }

export default class Attachment {
  name: string;

  url: string;

  constructor(o?: IAttachment) {
    this.name = o?.attachmentName ?? 'N/A';
    this.url = o?.attachmentUrl ?? 'N/A';
  }

  toDto(): IDTOAttachment {
    const dto: IDTOAttachment = {
      attachmentName: this.name,
      attachmentUrl: this.url,
    };
    return dto;
  }
}
