/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

export interface IDTOMessengerResponse {
  warning?: string;
  smile?: string;
  update?: IDTOMessengerUpdate;
}

export interface IDTOMessageRulesResponse {
  warning?: string;
  smile?: string;
  update?: IDTOMessageRulesUpdate;
}

export interface IDTOMessageRulesUpdate {
  messageRules?: IDTOMessageRule[];
}

export interface IDTOMessengerUpdate {
  eventTypes?: IDTOEventType[];
  campaigns?: IDTOCampaign[];
  messageTypes: IDTOMessageType[];
  handlebars?: IDTOHandlebar[];
  messageRules?: IDTOMessageRule[];
}

export interface IDTOCampaign {
  campaignId: number;
  description?: string;
}

export interface IDTOEventType {
  eventTypeId: number;
  eventType: string;
  eventName: string;
}

export interface IDTOHandlebar {
  handlebarId: number;
  name: string;
  expression: string;
}

export interface IDTOMessageRule {
  messageRuleId: number;
  campaignId?: number;
  serviceId?: number;
  tabId?: number;
  eventTypeId?: number;
  messageTypeId?: number;
  sendBcc: boolean;
  dtCreate?: string;
  dtUpdate?: string;
  isDeleted?: boolean;
  isDefault: boolean;
  isInactive: boolean;
  sendAtSpecificTime?: string;
  sendDaysAfter?: number;
  sendDaysInAdvance?: number;
  messageTemplates?: IDTOMessageTemplate[];
}

export interface IDTOMessageTemplate {
  messageTemplateId: number;
  messageTypeId?: number;
  messageLayoutId?: number;
  messageDescription?: string;
  locale?: string;
  subject?: string;
  body?: string;
  dtCreate?: string;
  dtUpdate?: string;
  isDeleted?: boolean;
  isDefault?: boolean;
}

export interface IDTOMessageType {
  messageTypeId: number;
  messageType: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class MessengerConvert {
  public static toMessangerResponse(json: string): IDTOMessengerResponse {
    return cast(JSON.parse(json), r('IDTOMessengerResponse'));
  }

  public static toMessageRulesResponse(json: string): IDTOMessageRulesResponse {
    return cast(JSON.parse(json), r('IDTOMessageRulesResponse'));
  }

  public static messangerResponseToJson(value: IDTOMessengerResponse): string {
    return JSON.stringify(uncast(value, r('IDTOMessengerResponse')), null, 2);
  }
}

function invalidValue(typ: any, val: any): never {
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) { }
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue('array', val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(typ: any, val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue('Date', val);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue('object', val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps);
      }
    });
    return result;
  }

  if (typ === 'any') return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === 'object' && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers') ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems') ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty('props') ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(typ, val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  IDTOMessageRulesResponse: o([
    { json: 'warning', js: 'warning', typ: u(undefined, '') },
    { json: 'smile', js: 'smile', typ: u(undefined, '') },
    { json: 'update', js: 'update', typ: u(undefined, r('IDTOMessageRulesUpdate')) },
  ], false),
  IDTOMessengerResponse: o([
    { json: 'warning', js: 'warning', typ: u(undefined, '') },
    { json: 'smile', js: 'smile', typ: u(undefined, '') },
    { json: 'update', js: 'update', typ: u(undefined, r('IDTOMessengerUpdate')) },
  ], false),
  IDTOMessageRulesUpdate: o([
    { json: 'messageRules', js: 'messageRules', typ: u(undefined, a(r('IDTOMessageRule'))) },
  ], false),
  IDTOMessengerUpdate: o([
    { json: 'eventTypes', js: 'eventTypes', typ: u(undefined, a(r('IDTOEventType'))) },
    { json: 'campaigns', js: 'campaigns', typ: u(undefined, a(r('IDTOCampaign'))) },
    { json: 'messageTypes', js: 'messageTypes', typ: u(undefined, a(r('IDTOMessageType'))) },
    { json: 'handlebars', js: 'handlebars', typ: u(undefined, a(r('IDTOHandlebar'))) },
    { json: 'messageRules', js: 'messageRules', typ: u(undefined, a(r('IDTOMessageRule'))) },
  ], false),
  IDTOCampaign: o([
    { json: 'campaignId', js: 'campaignId', typ: 0 },
    { json: 'description', js: 'description', typ: '' },
  ], false),
  IDTOEventType: o([
    { json: 'eventTypeId', js: 'eventTypeId', typ: 0 },
    { json: 'eventType', js: 'eventType', typ: '' },
    { json: 'eventName', js: 'eventName', typ: '' },
  ], false),
  IDTOHandlebar: o([
    { json: 'handlebarId', js: 'handlebarId', typ: 0 },
    { json: 'name', js: 'name', typ: '' },
    { json: 'expression', js: 'expression', typ: '' },
  ], false),
  IDTOMessageRule: o([
    { json: 'messageRuleId', js: 'messageRuleId', typ: 0 },
    { json: 'campaignId', js: 'campaignId', typ: 0 },
    { json: 'serviceId', js: 'serviceId', typ: 0 },
    { json: 'tabId', js: 'tabId', typ: 0 },
    { json: 'eventTypeId', js: 'eventTypeId', typ: 0 },
    { json: 'messageTypeId', js: 'messageTypeId', typ: 0 },
    { json: 'sendBcc', js: 'sendBcc', typ: true },
    { json: 'isDefault', js: 'isDefault', typ: u(undefined, true) },
    { json: 'isInactive', js: 'isInactive', typ: u(undefined, true) },
    { json: 'messageTemplates', js: 'messageTemplates', typ: u(undefined, a(r('IDTOMessageTemplate'))) },
    { json: 'sendAtSpecificTime', js: 'sendAtSpecificTime', typ: u(undefined, '') },
    { json: 'sendDaysInAdvance', js: 'sendDaysInAdvance', typ: u(undefined, 0) },
    { json: 'sendDaysAfter', js: 'sendDaysAfter', typ: u(undefined, 0) },
    { json: 'isDeleted', js: 'isDeleted', typ: u(undefined, true) },
  ], false),
  IDTOMessageTemplate: o([
    { json: 'messageTemplateId', js: 'messageTemplateId', typ: 0 },
    { json: 'messageTypeId', js: 'messageTypeId', typ: 0 },
    { json: 'messageLayoutId', js: 'messageLayoutId', typ: 0 },
    { json: 'messageDescription', js: 'messageDescription', typ: '' },
    { json: 'locale', js: 'locale', typ: '' },
    { json: 'subject', js: 'subject', typ: '' },
    { json: 'body', js: 'body', typ: '' },
    { json: 'isDefault', js: 'isDefault', typ: u(undefined, true) },
    { json: 'isDeleted', js: 'isDeleted', typ: u(undefined, true) },
  ], false),
  IDTOMessageType: o([
    { json: 'messageTypeId', js: 'messageTypeId', typ: 0 },
    { json: 'messageType', js: 'messageType', typ: '' },
  ], false),
};
