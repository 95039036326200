import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { IUpdate } from '@/api/api-update';
import httpClient from '@/api/http-client';

import {
  dateStringFromDate, dateFlooredToDate, dateByAddingDays, dateFromDateTimeString,
  isDateAfterDate, dateIndexFromDate, areDatesEqual, isDateBeforeDate,
} from '@/services/time-utils';
import { UpdateOriginator } from '@/services/store-utils';
import IRootState, { ISetStoreState, IUpdateState } from './store-state';

function isDateLoaded(state: IUpdateState, date: Date) {
  if (areDatesEqual(date, state.date) && state.isDateLoaded) return true;
  if (state.oldestReservationDate && isDateBeforeDate(date, state.oldestReservationDate)) return false;
  if (state.latestReservationDate && isDateAfterDate(date, state.latestReservationDate)) return false;
  if (areDatesEqual(date, state.date)) return state.isLoaded;
  return false;
}

export class UpdateState implements IUpdateState {
  autoUpdateTimeInterval: number = 0;

  oldestReservationDate: Date | null = null;

  latestReservationDate: Date | null = null;

  date: Date = dateFlooredToDate(new Date());

  now: Date = new Date();

  dateIndex: number = dateIndexFromDate(this.date);

  updating: number = 0;

  isLoaded: boolean = false;

  isDateLoaded: boolean = false;

  updateError: Error | null = null;

  startUpdateDate: Date | null = null;

  updateDate: Date | null = null;

  updateErrorDate: Date | null = null;

  updateSuccessDate: Date | null = null;

  updateOriginator: string = UpdateOriginator.None;

  processing: number = 0;

  startProcessDate: Date | null = null;

  processDate: Date | null = null;

  processOriginator: string = UpdateOriginator.None;
}

const mutations = <MutationTree<IUpdateState>>{
  RESET(state: UpdateState) {
    Object.assign(state, new UpdateState());
  },
  SET(state: UpdateState, p: { state: ISetStoreState }) {
    if (!p.state.update) return;
    console.log('SET update:', p.state.update);
    Object.assign(state, p.state.update);
    state.updateDate = null;
  },
  START_PROCESSING(state: UpdateState, p: { originator: string }) {
    state.processing += 1;
    state.startProcessDate = new Date();
    state.processOriginator = p.originator;
  },
  END_PROCESSING(state: UpdateState, p: {
    autoUpdateTimeInterval?: number, oldestReservationDate?: Date, latestReservationDate?: Date, originator: string
  }) {
    state.processing -= 1;
    state.processDate = new Date();
    state.processOriginator = p.originator;

    if (p.autoUpdateTimeInterval) state.autoUpdateTimeInterval = p.autoUpdateTimeInterval;
    if (p.oldestReservationDate !== undefined) {
      state.oldestReservationDate = dateFlooredToDate(dateByAddingDays(p.oldestReservationDate, 1));
    }
    if (p.latestReservationDate !== undefined) {
      state.latestReservationDate = dateFlooredToDate(dateByAddingDays(p.latestReservationDate, -1));
    }

    state.isDateLoaded = true;
    state.isLoaded = true;
  },
  CHANGE_DATE(state: UpdateState, date: Date) {
    state.date = dateFlooredToDate(date);
    state.dateIndex = dateIndexFromDate(state.date);
    state.isDateLoaded = false;
  },
  START_UPDATING(state: UpdateState, p: { originator: string}) {
    state.updating += 1;
    state.startUpdateDate = new Date();
    state.updateOriginator = p.originator;
  },
  END_UPDATING(state: UpdateState, p: { error?: Error, originator: string}) {
    state.updating -= 1;
    state.updateError = p.error ?? null;
    state.updateDate = new Date();
    if (p.error) state.updateErrorDate = state.updateDate;
    else state.updateSuccessDate = state.updateDate;
    state.updateOriginator = p.originator;
  },
  UPDATE_NOW(state: UpdateState) {
    state.now = new Date();
    console.log('UPDATE_NOW: ', state.now);
  },
};

const actions = <ActionTree<UpdateState, IRootState>>{
  async update({
    state, commit, dispatch, rootGetters, rootState,
  }, p?: { date?: Date, originator?: string}) {
    if (!rootGetters.isLoggedIn/* ?? state.isUpdating */) return;

    const originator = p?.originator ?? UpdateOriginator.Normal;

    commit('START_PROCESSING', { originator });
    commit('START_UPDATING', { originator });
    try {
      let dateString: string | undefined;
      if (p?.date && !isDateLoaded(state, p.date)) dateString = dateStringFromDate(p.date) ?? undefined;

      const updates = await httpClient.getUpdates(dateString);
      dispatch('processUpdates', { updates, originator });

      commit('END_UPDATING', { originator });
    } catch (error) {
      commit('END_UPDATING', { error, originator });
      commit('END_PROCESSING', { originator });
      throw error;
    }
  },
  async changeToDate({ state, commit, dispatch }, date) {
    commit('CHANGE_DATE', date);
    if (!isDateLoaded(state, date)) await dispatch('update', { date }); else await dispatch('update');
  },
  async changeToNextDate({ state, commit, dispatch }) {
    await dispatch('changeToDate', dateByAddingDays(state.date, 1));
  },
  async changeToPreviousDate({ state, commit, dispatch }) {
    await dispatch('changeToDate', dateByAddingDays(state.date, -1));
  },
  startProcessUpdates({ state, commit, dispatch }, p: { originator?: string }) {
    const originator = p.originator ?? UpdateOriginator.Other;
    commit('START_PROCESSING', { originator });
  },
  processUpdatesError({ state, commit, dispatch }, p: { originator?: string }) {
    const originator = p.originator ?? UpdateOriginator.Other;
    commit('END_PROCESSING', { originator });
  },
  processUpdates({ state, commit, dispatch }, p: { updates: IUpdate, noroot?: boolean, originator?: string }) {
    console.log('processUpdates - is full updates:', p.updates.isFullUpdate);

    dispatch('updateAccount', p.updates);
    dispatch('updateUserAccounts', p.updates);
    dispatch('updateCodes', p.updates);
    dispatch('updateTabs', p.updates);
    dispatch('updateSettings', p.updates);
    dispatch('updateReservations', p.updates);

    // core entities are updated, refresh dependent entities
    dispatch('refresh', p.updates);

    if (p.noroot !== true) {
      const originator = p.originator ?? UpdateOriginator.Other;

      const autoUpdateTimeInterval = Number(p.updates.autoUpdateTimeInterval) || 0;

      const zeroOrIgnore = p.updates.isFullUpdate ? null : undefined;
      const oldestReservationDate = dateFromDateTimeString(p.updates.oldestReservationDate) ?? zeroOrIgnore;
      const latestReservationDate = dateFromDateTimeString(p.updates.latestReservationDate) ?? zeroOrIgnore;

      commit('END_PROCESSING', {
        autoUpdateTimeInterval, oldestReservationDate, latestReservationDate, originator,
      });
    }
  },
  refresh({ state, commit }, update: IUpdate) {
    console.log('update store refresh');
  },
  updateNow({ state, commit, dispatch }) {
    commit('UPDATE_NOW');
    return state.now;
  },
};

const getters = <GetterTree<IUpdateState, IRootState>>{
  selectedDate(state) { return state.date; },
  isUpdating(state) { return state.updating > 0; },
  isLoaded(state) { return state.isLoaded; },
  isDateLoaded(state) { return isDateLoaded(state, state.date); },
  now(state) { return state.now; },
  nowDate(state) { return dateFlooredToDate(state.now); },
  isToday(state, localGetters) { return areDatesEqual(state.date, localGetters.nowDate); },
  isTodayOrFuture(state, localGetters) { return !isDateBeforeDate(state.date, localGetters.nowDate); },
  isThisYear(state, localGetters) { return state.date.getFullYear() === localGetters.nowDate.getFullYear(); },
};

const UpdateStore = {
  namespaced: false,
  state: new UpdateState(),
  mutations,
  actions,
  getters,
};

export default UpdateStore;
