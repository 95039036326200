import { ref } from 'vue';

export default function usePwa() {
  const isPwa = ref(false);

  window.addEventListener('DOMContentLoaded', () => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      isPwa.value = true;
    }
  });

  isPwa.value = window.matchMedia('(display-mode: standalone)').matches;

  return {
    isPwa,
  };
}
