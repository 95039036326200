
import Vue from 'vue';
import Reservation, { ReservationType } from '@/model/Reservation';
import TabItem from '@/model/TabItem';

export default Vue.extend({
  name: 'TabItemMenu',
  components: { },
  props: { },
  data() {
    return {
      menu: false,
      lastTabItems: [] as TabItem[],
      autoTabItems: false,
    };
  },
  computed: {
    r(): Reservation {
      return this.$tstore.getters.editReservation ?? new Reservation();
    },
    tabItems(): TabItem[] {
      return this.r.tab?.tabItems ?? [];
    },
    reservationTabItems(): TabItem[] {
      return this.r.tabItems ?? [];
    },
    selectedTabItems(): TabItem[] {
      return this.r.tabItems;
    },
    unavailableTabItems(): TabItem[] {
      return this.$tstore.getters.reservationUnavailableTabItems;
    },
    holdTabItems(): boolean {
      return this.r.holdTabItems;
    },
    holdTabItemsVisible(): boolean {
      return !this.r.isBlock;
    },
    selectAllChecked(): boolean {
      return this.r.tabItems.length === this.tabItems.length;
    },
    tableAllocationDisabled(): boolean {
      return !(this.$tstore.state.settings.accountSettings.tableAllocation ?? false);
    },
    autoTabItemsVisible(): boolean {
      return !this.r.isBlock;
    },
    autoTabItemsDisabled(): boolean {
      return this.holdTabItems || this.tableAllocationDisabled || this.r.isBlock;
    },
    tabItemsDisabled(): boolean {
      return this.holdTabItems && !this.r.isBlock;
    },
  },
  watch: {
    menu(value: boolean) {
      if (value) this.reset();
    },
    tableAllocationDisabled() {
      this.updateAutoTabItems();
    },
    reservationTabItems() {
      this.updateAutoTabItems();
    },
  },
  methods: {
    updateAutoTabItems() {
      this.autoTabItems = this.r.tabItems.length === 0
      && !this.tableAllocationDisabled
      && !this.r.isBlock;
    },
    selectAll() {
      if (this.selectAllChecked) {
        this.$tstore.dispatch('setReservationTabItems', []);
      } else {
        this.$tstore.dispatch('setReservationTabItems', this.tabItems);
      }
    },
    reset() {
      this.lastTabItems = [];
      this.updateAutoTabItems();
    },
    checkboxComparator(lhs: TabItem, rhs: TabItem):boolean {
      return lhs.id === rhs.id;
    },
    isUnavailable(tabItem: TabItem) {
      return this.unavailableTabItems.find((o) => o.id === tabItem.id);
    },
    toggleCheckbox(tabItem: TabItem) {
      const tabItems = Array.from(this.r.tabItems);
      const index = tabItems.findIndex((ti) => ti.id === tabItem.id);
      if (index >= 0) tabItems.splice(index, 1);
      else tabItems.push(tabItem);
      this.$tstore.dispatch('setReservationTabItems', tabItems);
    },
    holdTabItemsChanged() {
      console.log('XXX holdTabChanged');
      this.$tstore.dispatch('setReservationHoldTabItems', !this.holdTabItems);
    },
    autoTabItemsChanged() {
      if (this.autoTabItems) {
        this.lastTabItems = Array.from(this.r.tabItems);
        this.$tstore.dispatch('setReservationTabItems', []);
      } else {
        this.$tstore.dispatch('setReservationTabItems', this.lastTabItems);
      }
    },
  },
});
