import HourSlot from './HourSlot';

export default class HourSlotProfile {
  readonly minimumPartySize?: number;

  readonly maximumPartySize?: number;

  readonly maximumGuests?: number;

  readonly maximumReservations?: number;

  readonly fixedEndingInMinutes?: number;

  readonly id: number;

  constructor(o: HourSlot) {
    this.minimumPartySize = o.minimumPartySize;
    this.maximumPartySize = o.maximumPartySize;
    this.maximumGuests = o.maximumGuests;
    this.maximumReservations = o.maximumReservations;
    this.fixedEndingInMinutes = o.fixedEndingInMinutes;
    this.id = o.profileId;
  }
}

export function profilesFromHours(hourSlots: (HourSlot | null)[]): Map<number, HourSlotProfile> {
  const profiles = new Map<number, HourSlotProfile>();
  hourSlots.forEach((o) => {
    if (o === null || !o.profileId || profiles.has(o.profileId)) return;
    profiles.set(o.profileId, new HourSlotProfile(o));
  });
  return profiles;
}

function hashCode(s: string): number {
  let h = 0xdeadbeef;
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i < s.length; i += 1) { h = Math.imul(h ^ s.charCodeAt(i), 2654435761); } return (h ^ h >>> 16) >>> 0;
}

export function colorsForProfiles(profiles: HourSlotProfile[], colors: string[]): Map<number, string> {
  const profileForColorIndex = new Map<number, HourSlotProfile>();
  const colorsByProfileId = new Map<number, string>();

  // 1st round
  profiles.forEach((p) => {
    const colorIndex = hashCode(String(p.id)) % colors.length;
    if (profileForColorIndex.has(colorIndex)) return;
    profileForColorIndex.set(colorIndex, p);
    colorsByProfileId.set(p.id, colors[colorIndex]);
  });

  const remainingProfiles = profiles.filter((p) => !colorsByProfileId.has(p.id));
  const remainingColors = colors.filter((c) => !Array.from(colorsByProfileId.values()).includes(c));

  // 2nd round
  while (remainingProfiles.length && remainingColors.length) {
    colorsByProfileId.set(remainingProfiles.pop()!.id, remainingColors.pop()!);
  }

  // 3rd round
  remainingProfiles.forEach((p) => {
    const colorIndex = hashCode(String(p.id)) % colors.length;
    colorsByProfileId.set(p.id, colors[colorIndex]);
  });

  return colorsByProfileId;
}
