import storage from '@/services/local-storage';
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

export const supportedLanguages = [
  { value: 'en', text: 'English' },
  { value: 'nl', text: 'Nederlands' },
  { value: 'de', text: 'Deutsch' },
  { value: 'it', text: 'Italiano' },
  { value: 'fr', text: 'Français' },
  { value: 'cs', text: 'Čeština' },
  { value: 'sk', text: 'Slovenčina' },
];
export const extraDocsLocales = ['nl'];
export const engLocales = ['en-AU', 'en-CA', 'en-GB', 'en-IE', 'en-IL', 'en-IN', 'en-NZ', 'en-SG', 'en-US'];
export const supportedLocales = [
  ...engLocales,
  'nl-NL', 'nl-BE',
  'it-IT',
  'de-DE',
  'fr-FR', 'fr-BE', 'fr-CA', 'fr-CH', 'fr-LU', 'fr-MC',
  'cs-CZ',
  'sk-SK',
];
export const extraLocales = ['xx'];

const langsAndLocales = [...supportedLanguages.map((l) => l.value), ...supportedLocales];

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};

  const supported = [...supportedLanguages.map((l) => l.value), ...extraLocales];

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    const locale = matched && matched.length > 1 && matched[1];
    if (locale && supported.includes(locale)) {
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function preferredLanguage(): string | null {
  const supported = supportedLanguages.map((l) => l.value);

  // prefer language from local storage if the key exists
  const savedLanguage = storage.getLanguage();
  if (savedLanguage !== null && langsAndLocales.includes(savedLanguage)) {
    return savedLanguage;
  }

  const lang = (navigator.languages[0] || '').split('-')[0] || null;
  if (lang !== null && supported.includes(lang)) {
    return lang;
  }
  return 'en';
}

const defaultDateFormats = {
  // date
  full: {
    weekday: 'long', day: 'numeric', month: 'long', year: 'numeric',
  },
  long: {
    day: 'numeric', month: 'long', year: 'numeric',
  },
  'long-no-year': {
    weekday: 'long', day: 'numeric', month: 'long',
  },
  short: {
    weekday: 'short', day: 'numeric', month: 'short', year: 'numeric',
  },
  shorter: {
    day: 'numeric', month: 'short', year: 'numeric',
  },
  'short-no-year': {
    weekday: 'short', day: 'numeric', month: 'short',
  },
  'short-weekday-year': {
    weekday: 'short', day: 'numeric', month: 'short', year: 'numeric',
  },
  'short-no-weekday-no-year': { day: 'numeric', month: 'short' },
  'month-year': { month: 'long', year: 'numeric' },
  // singles
  weekday: { weekday: 'long' },
  month: { month: 'long' },
  // date + time
  'long-datetime': {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  'short-datetime': {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  'compact-datetime': {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
};

export const dateTimeFormats = langsAndLocales.reduce((a, val) => ({ ...a, [val]: defaultDateFormats }), {});

export default new VueI18n({
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    sk: (choice, choicesLength) => {
      if (choice <= 1) { return choice; }
      if (choice > 1 && choice < 5) { return 2; }
      return 0;
    },
    cs: (choice, choicesLength) => {
      if (choice <= 1) { return choice; }
      if (choice > 1 && choice < 5) { return 2; }
      return 0;
    },
  },
  locale: preferredLanguage() || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: [process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en', 'xx'],
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
    sk: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
  },
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});
