
import Vue, { PropType } from 'vue';
import TabItem from '@/model/TabItem';
import FloorplanTablePreview from '@/components/views/floorplan/FloorplanTablePreview.vue';

export default Vue.extend({
  name: 'FloorplanEditSidebar',
  components: {
    FloorplanTablePreview,
  },
  props: {
    tabItems: { type: Object as PropType<Array<TabItem>>, required: true },
    selectedTabItemIds: { type: Object as PropType<Array<number>>, required: false, default: undefined },
  },
  data() {
    return {
      selected: undefined as number | undefined,
    };
  },
  watch: {
    selectedTabItemIds() {
      [this.selected] = this.selectedTabItemIds;
    },
  },
  methods: {
    selectedChange(tabItemId: number) {
      if (this.selected === undefined) { return; }
      this.$emit('selected', tabItemId);
    },
    placeTabItem(i: number, t: TabItem) {
      this.$emit('place-tabitem', i, t);
    },
  },
});
