import { IUserAccount } from '@/api/api-update';

export default class UserAccount {
  id: number;

  token: string;

  accountName: string;

  color: string;

  constructor(o?: IUserAccount) {
    this.id = o?.accountId ?? 0;
    this.token = o?.token ?? '';
    this.accountName = o?.accountName ?? 'N/A';
    this.color = o?.color?.toUpperCase() ?? '#3355FF';
  }
}
