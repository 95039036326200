/* eslint-disable brace-style */
import * as pbm from '@/grpc-pb/dashboard_pb';
import * as pbs from '@/grpc-pb/dashboard_service_pb';
import {
  dateFromDateAndTimeIndex, DAY_TIME_SLOTS_COUNT, minutesFromTimeString, MORNING_TIME_SLOTS_COUNT, slotFromMinutes,
} from '@/services/time-utils';

interface PR { // party size result
  p: number; d: number; c: number; m?: number;
  xs: number; xe?:boolean; // xs - extra section id. xe - last pr
}
interface TR { t: string; pr: PR[]; } // tab result
interface SR { s: number; tr: TR[]; } // slot result

export enum DashboardAvailabilityFlag {
  YES = 0,
  MIXED = 1,
  NO_LIMITED = 2,
  NO_ALLOCATIONS = 3,
  NO_CLOSED = 4,
}

export enum DashboardLimitReason {
  None = 'none',
  Capacity = 'capacity',
  MaxGuestsDefault = 'max_guests_default',
  MaxReservationsDefault = 'max_reservations_default',
  MinPartySizeDefault = 'min_party_size_default',
  MaxPartySizeDefault = 'max_party_size_default',
  MaxGuestsOpeningHour = 'max_guests_opening_hour',
  MaxGuestsSpecialHour = 'max_guests_special_hour',
  MaxReservationsOpeningHour = 'max_reservations_opening_hour',
  MaxReservationsSpecialHour = 'max_reservations_special_hour',
  ClosedOpeningHour = 'closed_opening_hour',
  ClosedSpecialHour = 'closed_special_hour',
  MinPartySizeOpeningHour = 'min_party_size_opening_hour',
  MinPartySizeSpecialHour = 'min_party_size_special_hour',
  MaxPartySizeOpeningHour = 'max_party_size_opening_hour',
  MaxPartySizeSpecialHour = 'max_party_size_special_hour',
}

export interface DashboardChartData {
  si: number, ps: number, flag: DashboardAvailabilityFlag, prs: PR[], lr: DashboardLimitReason, lrp?: number,
}

export interface DashboardLimitData {
  // s: number, i: number, ri: number,
  // il: number, ril: number, sl: number,
  // ilr: DashboardLimitReason, rilr: DashboardLimitReason, slr: DashboardLimitReason,
  // imin: number, imax: number,
  // iminr: DashboardLimitReason, imaxr: DashboardLimitReason,
  s: number, // seated guests
  i: number, // incoming guests
  ri: number, // incoming reservations
  ts: number, // total seated guests (all sections)
  ti: number, // total incoming guests (all sections)
  tri: number, // total incoming reservations (all sections)
  sl: number, // seated limit
  il: number, // incoming limit
  ril: number, // incoming reservations limit
  slr: DashboardLimitReason.None,
  ilr: DashboardLimitReason.None,
  rilr: DashboardLimitReason.None,
  imin: number,
  imax: number,
  iminr: DashboardLimitReason.None,
  imaxr: DashboardLimitReason.None,
}

export default class DashboardAvailability {
  date: Date | null = null;

  weekday: number = 0;

  isAvailable: boolean = false;

  data?: string;

  static fromGrpcResponse(r: pbs.DashboardAvailabilityResponse): DashboardAvailability {
    const a = r.getAvailability();
    return DashboardAvailability.fromGrpcModel(a);
  }

  static fromGrpcModel(o?: pbm.DashboardAvailability): DashboardAvailability {
    const a = new DashboardAvailability();

    a.date = dateFromDateAndTimeIndex(o?.getDate() ?? 0) ?? null;
    a.weekday = o?.getWeekday() ?? 0;
    a.isAvailable = o?.getIsavailable() ?? false;
    a.data = o?.getData() || undefined;

    return a;
  }

  toChartData(tabids: number[], slotLimits: DashboardLimitData[], lastps = 12): DashboardChartData[] {
    // lastps - last party size for special X+ row
    if (!this.data) return [];
    const json = JSON.parse(this.data);

    // create map with slot and party size key and section(s) availability results as value
    let maxps = lastps;
    const prmap = new Map<number, PR[]>();
    const srs: SR[] = json.sr ?? [];
    srs.forEach((sr) => {
      if (!tabids.includes(sr.s)) return;

      sr?.tr.forEach((tr) => {
        const si = slotFromMinutes(minutesFromTimeString(tr.t));
        tr.pr?.forEach((pr, i, prs) => {
          // add section id and last results flag
          // eslint-disable-next-line no-param-reassign
          pr.xs = sr.s; pr.xe = i === prs.length - 1 ? true : undefined;
          const key = pr.p * 100 + si;
          prmap.set(key, [...(prmap.get(key) ?? []), pr]);
          if (maxps < pr.p) maxps = pr.p;
        });
      });
    });

    // convert sections availability results to chart flag
    const chartFlag = (prs: PR[]) => {
      if (!prs || prs.length === 0) return DashboardAvailabilityFlag.NO_ALLOCATIONS;

      // ALL sections available for at least DEFAULT time duration
      // if (prs.every((pr) => pr.c >= pr.d)) return DashboardAvailabilityFlag.YES;

      // at least ONE section available for at least DEFAULT time duration
      if (prs.some((pr) => pr.c >= pr.d)) return DashboardAvailabilityFlag.YES;

      // at least ONE section available for at least MINIMUM time duration
      if (prs.some((pr) => pr.m && pr.c >= pr.m)) return DashboardAvailabilityFlag.MIXED;

      return DashboardAvailabilityFlag.NO_ALLOCATIONS;
    };

    // prepare slot availabilities
    const data = [] as DashboardChartData[];
    const daySlotCount = DAY_TIME_SLOTS_COUNT - MORNING_TIME_SLOTS_COUNT;
    [...new Array(daySlotCount)].forEach((_, si) => {
      const osa = new Map<number, PR>(); // old sections availabilities
      const results: { flag: DashboardAvailabilityFlag, lr: DashboardLimitReason, lrp: number, prs: PR[]}[] = [];
      results.push({ // dummy data for 0 partu size
        flag: DashboardAvailabilityFlag.NO_CLOSED, // flags for party sizes
        lr: DashboardLimitReason.None, // limit reasons for closed slots for party sizes
        lrp: 0, // limit reason param (limit as number)
        prs: [], // computed party size availability results
      });

      // get limit data for slot
      const ld = slotLimits[si + MORNING_TIME_SLOTS_COUNT];
      // if (ld.il !== 0) {
      //   console.log('ld.il <> 0: ', ld);
      // }

      // calculate flags
      for (let ps = 1; ps <= maxps; ps += 1) {
        // compute availability flag and reason
        let flag = DashboardAvailabilityFlag.NO_CLOSED;
        let lr = DashboardLimitReason.None;
        let lrp = 0;

        // update sections availabilities with new party size data
        const nsa = prmap.get(ps * 100 + si + MORNING_TIME_SLOTS_COUNT); // retrieve current sections availabilities
        nsa?.forEach((pr) => osa.set(pr.xs, pr)); // merge into old sections availabilities
        const prs = [...osa.values()]; // all party size results
        nsa?.filter((pr) => pr.xe === true).forEach((pr) => osa.delete(pr.xs)); // remove flagged as last

        // show slot as closed incoming guests = 0, or min or max party size
        // check incoming guests limit = 0 -> closed slot
        if (ld.il === 0) { lr = ld.ilr; lrp = 0; }
        // check min or max slot party size
        else if (ps < ld.imin) { lr = ld.iminr; lrp = ld.imin; }
        else if (ps > ld.imax) { lr = ld.imaxr; lrp = ld.imax; }
        // not closed slot
        else {
          flag = DashboardAvailabilityFlag.NO_LIMITED;

          // check incoming guests limit
          if (ps > ld.il - ld.i) { lr = ld.ilr; lrp = ld.il; }
          // check incoming reservations limit
          else if (ld.ri >= ld.ril) { lr = ld.rilr; lrp = ld.ri; }
          // check seated guests limit
          else if (ld.sl && ld.s >= ld.sl) { lr = ld.slr; lrp = ld.sl; }
          // calculate from party size results
          else flag = chartFlag(prs);
        }

        results.push({
          flag, lr, lrp, prs,
        });
      }

      // fully shown party sizes - separate row per party size (below X+ row)
      for (let ps = 1; ps < lastps; ps += 1) {
        data.push({ si, ps, ...results[ps] });
      }

      // extra partry sizes - special X+ row
      let flag = DashboardAvailabilityFlag.NO_ALLOCATIONS;
      let lr = DashboardLimitReason.None;
      let lrp = 0;
      results.splice(0, lastps);

      // no result -> no availability
      if (results.length === 0) flag = DashboardAvailabilityFlag.NO_ALLOCATIONS;

      // all spots closed -> closed (and use the first spot limit reason)
      else if (results.every((r) => r.flag === DashboardAvailabilityFlag.NO_CLOSED)) {
        flag = DashboardAvailabilityFlag.NO_CLOSED; lr = results[0].lr; lrp = results[0].lrp;
      }

      // all spots limited -> limited (and use the first spot limit reason)
      else if (results.every((r) => r.flag === DashboardAvailabilityFlag.NO_LIMITED)) {
        flag = DashboardAvailabilityFlag.NO_LIMITED; lr = results[0].lr; lrp = results[0].lrp;
      }

      // some spot available -> available
      else if (results.some((r) => r.flag === DashboardAvailabilityFlag.YES)) {
        flag = DashboardAvailabilityFlag.YES;
      }

      // some spot mixed -> mixed
      else if (results.some((r) => r.flag === DashboardAvailabilityFlag.MIXED)) {
        flag = DashboardAvailabilityFlag.MIXED;
      }

      const prs = results.flatMap((r) => r.prs);
      data.push({
        si, ps: lastps, flag, lr, lrp, prs,
      });
    });

    return data;
  }
}
