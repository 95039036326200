

import Vue, { PropType } from 'vue';
import runes from 'runes';
import { contrastForColor } from '@/services/color-utils';

export default Vue.extend({
  name: 'AccountAvatar',
  props: {
    accountName: { type: String, required: true },
    color: { type: String, required: true },
  },
  data() {
    return { };
  },
  computed: {
    accountInitials(): string {
      if (this.accountName === undefined || this.accountName.length === 0) {
        return 'GP';
      }
      const accName = this.accountName.split(' ');
      if (accName.length === 1) {
        return runes.substr(this.accountName, 0, 2);
      }
      return `${runes.substr(accName[0], 0, 1)}${runes.substr(accName[accName.length - 1], 0, 1)}`;
    },
    textColor(): string {
      if (this.color === 'brand') { return '#ffffff'; }
      return contrastForColor(this.color);
    },
  },
  methods: {},
});

