
import Vue, { PropType } from 'vue';
import Sortable from 'sortablejs';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

import { performAction } from '@/services/vue-utils';
import { isModelEqualToModel, cloneModel } from '@/model/model-utils';
import Tab from '@/model/Tab';
import UnsavedGuard from '@/mixins/unsaved-guard';
import DurationRule from '@/model/DurationRule';
import DialogClosable from '@/mixins/dialog-closable';
import EditDurationRuleDialog from './EditDurationRuleDialog.vue';

export default Vue.extend({
  name: 'DurationRulesDialog',
  components: { ConfirmationDialog, EditDurationRuleDialog },
  mixins: [UnsavedGuard, DialogClosable],
  props: {
    section: { type: Object as PropType<Tab>, required: true },
  },
  data() {
    return {
      editRuleDialog: false,
      editedRule: new DurationRule(),
      deleteConfirmation: false,
      rules: [] as DurationRule[],
      oldRules: [] as DurationRule[],
      headers: [
        {
          text: this.$i18n.t('label.party_size'),
          value: 'partySizeFrom',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.time'),
          value: 'timeFrom',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.additional_time'),
          width: '32',
          value: 'additionalDuration',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          width: '32',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    isChanged(): boolean {
      return !isModelEqualToModel(this.rules, this.oldRules);
    },
  },
  watch: {
    visible() {
      this.reset();
    },
  },
  created() {
  },
  mounted() {

  },
  methods: {
    reset() {
      const rules = this.$tstore.state.settings.durationRules.filter(
        (o: DurationRule) => o.tabId === this.section.id,
      );
      this.rules = rules.map((o) => cloneModel(o));
      this.oldRules = rules;

      this.editRuleDialog = false;
    },
    add() {
      const rule = new DurationRule();
      rule.tabId = this.section.id;
      this.editedRule = rule;
      this.editRuleDialog = true;
    },
    edit(rule : DurationRule, prop: any) {
      this.editedRule = rule;
      this.editRuleDialog = true;
    },
    closeEditRuleDialog() {
      this.editRuleDialog = false;
      this.reset();
    },
    async deleteRule() {
      const ok = await performAction(
        this.$tc('message.success_rule_deleted'),
        this.$tc('error.deleting_rule'),
        async () => this.$tstore.dispatch('sendDurationRule', { entity: this.editedRule, delete: true }),
      );
      if (ok) {
        this.reset();
      }
    },
    confirmRuleDelete(id: number) {
      const rule = this.rules.find((o: DurationRule) => o.id === id);
      if (!rule) return;
      this.editedRule = rule;
      this.deleteConfirmation = true;
    },
    done() {
      this.$emit('close');
    },
  },
});
