export const DEFAULT_PARTY_SIZE = 2; // default party size
export const DEFAULT_TAB_DURATION = 2.5 * 60; // default tab duration in minutes

export const MIN_BEGIN_TIME = '06:00'; // minimum reservation start time
export const MAX_BEGIN_TIME = '23:45'; // maximum reservation start time
export const MAX_END_TIME = '05:00'; // minimum reservation end time (the next day)

export const DEFAULT_SPLIT_LUNCH_DINNER_TIME = '14:00'; // default lunch/dinner split time

export const DEFAULT_TIMETABLE_START_TIME = '10:00'; // maximum start time for timetable
export const DEFAULT_TIMETABLE_END_TIME = '18:00'; // minimum end time for timetable

export const WIDGET_DOMAIN = 'guestplan.io'; // widget domain

export const SERVIE_WIDGET_DOMAIN = 'widget.guestplan.com'; // widget domain

export function hostedWidgetURL(p: {
  subdomain?: string, apiKey?: string, serviceCode?: string, locale?: string,
}): string | undefined {
  if (p.apiKey && p.serviceCode) {
    // widget with service
    let url = `https://${SERVIE_WIDGET_DOMAIN}/?id=${p.serviceCode}0${p.apiKey}`;
    if (p.locale) url = `${url}&locale=${p.locale}`;
    return url;
  }

  if (p.subdomain) {
    // widget with custom subdomain
    return `https://${p.subdomain}.${WIDGET_DOMAIN}`;
  }

  if (p.apiKey) {
    // widget with api key
    return `https://${WIDGET_DOMAIN}/?i=${p.apiKey}`;
  }

  return undefined;
}
