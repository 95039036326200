
import Vue from 'vue';
import UnsavedGuard from '@/mixins/unsaved-guard';
import SettingsList from '@/components/views/settings/SettingsList.vue';
import Label from '@/model/Label';
import { fullSwatchPalette } from '../helpers/colors';

export default Vue.extend({
  name: 'Labels',
  components: { SettingsList },
  mixins: [UnsavedGuard],
  data() {
    return {
      changed: false,
      swatches: fullSwatchPalette,
      extraColumn: {
        text: this.$i18n.t('label.color'),
        width: '28',
        align: 'center',
        value: 'color',
        sortable: false,
        isSelectable: false,
      },
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    isChanged(): boolean { return this.changed; },
  },
  methods: {
    async save(entities: Label[]) { await this.$tstore.dispatch('sendLabels', { entities }); },
    add(): Label { return new Label(); },
    isChangedChange(changed: boolean) { this.changed = changed; },
  },
});

