
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import VChart from 'vue-echarts';
import { weekdayStartAtMondayFromDateDay } from '@/services/time-utils';

use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'WeekdaysBarChart',
  components: {
    VChart,
  },
  props: {
    title: { type: String, required: true },
    series: { type: Array as PropType<Array<string>>, default: () => [] },
    dataset: { type: Array as PropType<Array<Array<number>>>, default: () => [] },
    seriesRevenue: { type: Array as PropType<Array<string>>, default: () => [] },
    datasetRevenue: { type: Array as PropType<Array<number>>, default: () => [] },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
    };
  },
  computed: {

  },
  watch: {
    category() { this.reset(); },
    series() { this.reset(); },
    dataset() { this.reset(); },
  },
  created() {
    this.reset();
  },

  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const remap = this.$localized.weekdays().reverse().map((w) => weekdayStartAtMondayFromDateDay(w.value));

      const series = [] as any[];

      this.series.forEach((name, i) => {
        const data = [0, 0, 0, 0, 0, 0, 0];
        this.dataset[i].forEach((v, j) => { data[remap[j]] = v; });
        series.push({
          name,
          type: 'bar',
          stack: 'Total',
          smooth: 0.15,
          areaStyle: {},
          symbol: 'roundRect',
          emphasis: {
            focus: 'series',
          },
          data,
        });
      });

      this.seriesRevenue.forEach((name, i) => {
        const data = [0, 0, 0, 0, 0, 0, 0];
        this.datasetRevenue.forEach((v, j) => { data[remap[j]] = v; });
        series.push(
          {
            name: this.seriesRevenue.length === 1
              ? this.$i18n.t('label.report_revenue')
              : `${this.$i18n.t('label.report_revenue')} (${name.toUpperCase()})`,
            type: 'bar',
            smooth: 0.25,
            areaStyle: {
              color: 'transparent',
            },
            lineStyle: {
              color: ['#ffc24e', '#e42dd0', '#00a26f'][i % 3],
              width: 3,
            },
            itemStyle: {
              color: ['#ffc24e', '#e42dd0', '#00a26f'][i % 3],
            },
            symbol: 'roundRect',
            emphasis: {
              focus: 'series',
            },
            data,
          },
        );
      });

      return {
        grid: {
          left: '32px',
          top: '16px',
          right: '16px',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
            axis: 'y',
          },
          extraCssText: 'min-width: 200px; max-width: 600px',
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          bottom: 'bottom',
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
          splitLine: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          data: this.$localized.weekdays().map((wk) => (wk.text)).reverse(),
        },
        series,
      };
    },
  },
});

