import { GetterTree, MutationTree, ActionTree } from 'vuex';
import Reservation, {
  ReservationMapping, ReservationStatus, ReservationSource, ReservationType,
} from '@/model/Reservation';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';
import Employee from '@/model/Employee';
import {
  setDateHoursMinutes, flooredMinutes, getDateHoursMinutes, dateByAddingDays,
  hourMinFromTimeText, isDateAfterDate, hourMinFromSlot, areDatesEqual, dateFlooredToXMinutes,
} from '@/services/time-utils';
import { DEFAULT_PARTY_SIZE, MIN_BEGIN_TIME } from '@/services/configuration';
import {
  ReservationError, validateReservation, validateReservationAvailability,
  overbookedTabItemsForReservation, unavailableTabItemsForReservation,
} from '@/services/reservation-validation';
import Tab from '@/model/Tab';
import Contact from '@/model/Contact';
import storage from '@/services/local-storage';
import TabItem from '@/model/TabItem';
import TimeSlot from '@/model/TimeSlot';
import DurationRule from '@/model/DurationRule';
import Attachment from '@/model/Attachment';
import httpClient from '@/api/http-client';
import grpcClient from '@/grpc-api/grpc-client';
import { History, HistoryMessage, HistoryRecord } from '@/services/reservation-history';
import Message from '@/grpc-api/model/message';
import IRootState, { IReservationState } from '@/store/store-state';

export class ReservationState implements IReservationState {
  originalReservation: Reservation | null = null;

  editedReservation: Reservation | null = null;

  lastEmployee: Employee | null = storage.getLastEmployee();

  rememberEmployee: boolean = storage.getLastEmployee() !== null;

  forgetTabItems: boolean = false;

  keepOpen: boolean = false;

  confirmResolver: ((value: boolean) => void) | null = null;
}

const mutations = <MutationTree<IReservationState>>{
  RESET(state: ReservationState) {
    Object.assign(state, new ReservationState());
  },
  SET_RESERVATION(state: ReservationState, reservation: Reservation | undefined) {
    console.log('SET_RESERVATION: ', reservation);
    state.originalReservation = reservation || new Reservation();
    state.editedReservation = reservation ? cloneModel(reservation) : new Reservation();
    state.forgetTabItems = false;

    state.keepOpen = false;
    state.confirmResolver = null;
  },
  RESET_RESERVATION(state: ReservationState) {
    console.log('RESET_RESERVATION');
    state.originalReservation = null;
    state.editedReservation = null;
    state.forgetTabItems = false;

    state.keepOpen = false;
    state.confirmResolver = null;
  },
  UPDATE_ORIGINAL_RESERVATION(state: ReservationState, originalReservation: Reservation) {
    console.log('UPDATE_ORIGINAL_RESERVATION');
    if (!state.editedReservation) return;
    state.originalReservation = originalReservation;
    // dateEdited fix
    state.editedReservation.dateEdited = state.originalReservation.dateEdited;
  },
  SET_RESERVATION_ELEMENT(state: ReservationState, p: {
    partySize?: number, tab?: Tab, tabItems?: TabItem[], holdTabItems? :boolean,
    status?: ReservationStatus, reservationType?: ReservationType,
    date: Date, dateBegin: Date, dateEnd: Date, contact?: Contact,
    attachments?: Attachment[],
    durationRules?: DurationRule[],
  }) {
    console.log('SET_RESERVATION_ELEMENT: ', p);
    if (!state.editedReservation) return;

    if (p.partySize !== undefined) state.editedReservation.partySize = p.partySize;
    if (p.tab !== undefined) state.editedReservation.tab = p.tab;
    if (p.tabItems !== undefined) {
      state.editedReservation.tabItems = p.tabItems;
      state.editedReservation.tabItems.sort((o1, o2) => o1.order - o2.order);
    }
    if (p.holdTabItems !== undefined && !state.editedReservation.isBlock) {
      state.editedReservation.holdTabItems = p.holdTabItems;
    }
    if (p.status !== undefined) state.editedReservation.status = p.status;
    if (p.date !== undefined) state.editedReservation.setDate(p.date);
    if (p.dateBegin !== undefined) state.editedReservation.dateBegin = p.dateBegin;
    if (p.dateEnd !== undefined) state.editedReservation.dateEnd = p.dateEnd;
    if (p.contact !== undefined) state.editedReservation.contact = p.contact;
    if (p.attachments !== undefined) state.editedReservation.attachments = p.attachments;
    if (p.reservationType !== undefined) {
      state.editedReservation.bookingType = p.reservationType;

      if (p.reservationType === ReservationType.Reservation) {
        if (!p.status) state.editedReservation.status = ReservationStatus.Planned;
        if (!p.partySize && state.editedReservation.partySize === 0) {
          state.editedReservation.partySize = DEFAULT_PARTY_SIZE;
        }
      }

      if (p.reservationType === ReservationType.Block) {
        state.editedReservation.partySize = 0;
        state.editedReservation.status = ReservationStatus.Planned;
      } else if (state.editedReservation.partySize === 0) {
        state.editedReservation.partySize = state.editedReservation.tabItems.map(
          (o) => o.seats,
        ).reduce((a, b) => a + b, 0);
      }
      if (p.reservationType === ReservationType.Walkin) {
        state.editedReservation.dateBegin = dateFlooredToXMinutes(new Date(), 30);
        state.editedReservation.status = ReservationStatus.Seated;
        if (!p.partySize && state.editedReservation.partySize === 0) {
          state.editedReservation.partySize = DEFAULT_PARTY_SIZE;
        }
      }

      if (p.reservationType === ReservationType.Waitlist) {
        state.editedReservation.status = ReservationStatus.WaitingList;
        if (!p.partySize && state.editedReservation.partySize === 0) {
          state.editedReservation.partySize = DEFAULT_PARTY_SIZE;
        }
      }
    }

    // tab items must belong to the tab
    if ((p.tab !== undefined || p.tabItems !== undefined) && state.editedReservation.tabItems != null) {
      state.editedReservation.tabItems = state.editedReservation.tabItems.filter(
        (ti) => state.editedReservation?.tab?.tabItems.find((tti) => ti.id === tti.id),
      );
    }

    if (p.dateBegin !== undefined || p.dateEnd !== undefined || p.reservationType === ReservationType.Walkin) {
      state.editedReservation.updateSlotsAndTimes();
    }
    if (p.durationRules !== undefined && (!state.editedReservation.fixedEnding || p.dateBegin !== undefined
      || p.reservationType === ReservationType.Walkin)) {
      state.editedReservation.updateEndDateForReservation(p.durationRules);
    }
  },

  SET_EMPLOYEE(state: ReservationState, p: { employee: Employee, remember: boolean }) {
    console.log('SET_EMPLOYEE: ', p);

    state.lastEmployee = p.employee;
    state.rememberEmployee = p.remember;

    if (state.lastEmployee.id <= 0) state.lastEmployee.id = 0;

    storage.setLastEmployee(p.remember ? p.employee : null);
  },
  SET_FORGET(state: ReservationState, forget: boolean) {
    console.log('SET_FORGET: ', forget);
    state.forgetTabItems = forget;
  },
  SET_KEEP_OPEN(state: ReservationState, keepOpen: boolean) {
    console.log('SET_KEEP_OPEN: ', keepOpen);
    state.keepOpen = keepOpen;
  },
  SET_CONFIRM(state: ReservationState, confirmResolver: ((value: boolean) => void) | null) {
    console.log('SET_CONFIRM: ', confirmResolver != null);
    state.confirmResolver = confirmResolver;
  },
};

const actions = <ActionTree<IReservationState, IRootState>>{
  editReservation({
    state, commit, rootState, rootGetters, dispatch,
  }, p: {
    reservation: Reservation
  } | {
    reservation: undefined,
    tab?: Tab,
    tabItems?: TabItem[],
    dateBegin?: Date,
    timeSlot?: number,
    partySize?: number,
    status?: ReservationStatus,
    contact?: Contact,
    bookingType?: ReservationType,
  }) {
    if (!rootGetters.isLoaded) return;

    let r: Reservation;

    if (!p.reservation) {
      let hm = getDateHoursMinutes(p.dateBegin ?? new Date());
      if (p.timeSlot) hm = hourMinFromSlot(p.timeSlot);
      if (hm.h < 6) hm = hourMinFromTimeText(MIN_BEGIN_TIME)!;

      r = new Reservation();
      r.partySize = p.partySize ?? DEFAULT_PARTY_SIZE;
      r.tab = p.tab ?? rootState.settings.tabs.find(Boolean) ?? null; // first tab or undefined;
      r.tabItems = p.tabItems ?? [];
      r.tabItems.sort((o1, o2) => o1.order - o2.order);
      r.status = p.status ?? ReservationStatus.Planned;
      r.dateBegin = setDateHoursMinutes(p.dateBegin ?? rootState.update.date, hm.h, flooredMinutes(hm.m));
      if (p.contact) r.contact = p.contact;

      r.bookingType = p.bookingType || ReservationType.Reservation;

      r.updateSlotsAndTimes();
      r.updateEndDateForReservation(rootState.settings.durationRules);
    } else {
      r = cloneModel(p.reservation);
      // remove tab items with id <= 0 (floating reservations)
      r.tabItems = r.tabItems.filter((ti) => ti.id > 0);
    }

    commit('SET_RESERVATION', r);
  },
  closeEditReservation({ state, commit, dispatch }) {
    commit('RESET_RESERVATION');
  },
  canCloseEditReservation({ state, commit, getters }): Promise<boolean> {
    if (state.keepOpen) return Promise.resolve(false);
    if (!getters.isChangedEditReservation) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>((resolve) => { commit('SET_CONFIRM', resolve); });
  },
  confirmCloseEditReservation({ state, commit, getters }, confirm: boolean) {
    if (state.confirmResolver) state.confirmResolver(confirm);
    commit('SET_CONFIRM', null);
  },
  async sendEditReservation(
    {
      state, commit, dispatch, getters,
    },
    p: { cancel?: boolean, duplicate?: boolean, dateBegin?: Date, suggestedTabItems?: TabItem[] },
  ) {
    if (!state.editedReservation) return;

    const r = cloneModel(state.editedReservation);

    if (p.duplicate) { r.id = 0; r.setDate(p.dateBegin ?? r.dateBegin); }
    if (p.cancel) r.status = ReservationStatus.Cancelled;
    if (p.suggestedTabItems && r.tabItems.length === 0) r.tabItems = p.suggestedTabItems;
    if (r.isBlock) { r.partySize = 0; }

    const employee = getters.reservationLastEmployee ?? undefined as Employee | undefined;
    await dispatch('sendReservation', {
      reservation: r,
      employee,
      forgetTabItems: state.forgetTabItems ?? undefined,
    });
  },

  // update original reservation from api update
  updateReservation({
    state, rootState, commit, dispatch,
  }) {
    if (!state.editedReservation || !state.originalReservation || state.originalReservation.id <= 0) return;

    const originalReservation = rootState.reservations.reservationsById[state.originalReservation.id]
      ?? new Reservation();
    commit('UPDATE_ORIGINAL_RESERVATION', originalReservation);
  },

  setLastEmployee({ state, commit, dispatch }, p: { employee: Employee | null, remember: boolean }) {
    commit('SET_EMPLOYEE', p);
  },
  setForgetTabItems({ state, commit, dispatch }, forget: boolean) {
    commit('SET_FORGET', forget);
  },
  setReservationContact({ state, commit, dispatch }, contact: Contact) {
    commit('SET_RESERVATION_ELEMENT', { contact: cloneModel(contact) });
  },

  setReservationPartySize({ state, commit, rootState }, partySize: number) {
    commit('SET_RESERVATION_ELEMENT', { partySize, durationRules: rootState.settings.durationRules });
  },
  setReservationType({ state, commit, rootState }, reservationType: ReservationType) {
    const durationRules = reservationType === ReservationType.Walkin ? rootState.settings.durationRules : undefined;
    commit('SET_RESERVATION_ELEMENT', { reservationType, durationRules });
  },
  setReservationTab({ state, commit, rootState }, tab: Tab) {
    commit('SET_RESERVATION_ELEMENT', { tab, durationRules: rootState.settings.durationRules });
  },
  setReservationTabItems({ state, commit, rootState }, tabItems: TabItem[]) {
    commit('SET_RESERVATION_ELEMENT', { tabItems });
  },
  setReservationHoldTabItems({ state, commit, rootState }, holdTabItems: boolean) {
    commit('SET_RESERVATION_ELEMENT', { holdTabItems });
  },
  setReservationStatus({ state, commit, rootState }, status: ReservationStatus) {
    commit('SET_RESERVATION_ELEMENT', { status });
  },
  setReservationDate({ state, commit, rootState }, date: Date) {
    commit('SET_RESERVATION_ELEMENT', { date });
  },
  setReservationAttachments({ state, commit, rootState }, attachments: Attachment[]) {
    commit('SET_RESERVATION_ELEMENT', { attachments });
  },
  setReservationTimeBegin({ state, commit, rootState }, time: string) {
    if (!state.editedReservation) return;

    const hm = hourMinFromTimeText(time);
    if (!hm) return;

    const dateBegin = setDateHoursMinutes(state.editedReservation.dateBegin, hm.h, hm.m);

    commit('SET_RESERVATION_ELEMENT', { dateBegin, durationRules: rootState.settings.durationRules });
  },
  setReservationTimeEnd({ state, commit, rootState }, time: string) {
    if (!state.editedReservation) return;

    const hm = hourMinFromTimeText(time);
    if (!hm) return;

    const { dateBegin } = state.editedReservation;
    let dateEnd = setDateHoursMinutes(dateBegin, hm.h, hm.m);
    if (!isDateAfterDate(dateEnd, dateBegin)) dateEnd = dateByAddingDays(dateEnd, +1);

    commit('SET_RESERVATION_ELEMENT', { dateEnd });
  },

  setReservationFromDragDropReservation({ state, commit, rootGetters }) {
    const ddr = rootGetters.dragDropReservation;
    if (!ddr || !state.editedReservation || ddr.id !== state.editedReservation.id) return;

    const {
      tab, tabItems, dateBegin, dateEnd,
    } = ddr;
    commit('SET_RESERVATION_ELEMENT', {
      tab, tabItems, dateBegin, dateEnd,
    });
  },
  setReservationFromContextStatusChange({ state, commit, rootGetters }, r: Reservation) {
    if (!state.editedReservation || !state.originalReservation || r.id !== state.editedReservation.id) return;

    // change also end date if affected by status change
    const dateEnd = areDatesEqual(state.originalReservation.dateEnd, r.dateEnd) ? undefined : r.dateEnd;

    commit('SET_RESERVATION_ELEMENT', { status: r.status, dateEnd });
  },
  async keepReservationChangeToDate({ state, commit, dispatch }, date) {
    commit('SET_KEEP_OPEN', true);
    try {
      await dispatch('changeToDate', date);
    } finally {
      commit('SET_KEEP_OPEN', false);
    }
  },
  async getContactReservations({ state, commit, rootGetters }): Promise<Reservation[]> {
    const contact = state.editedReservation?.contact;

    if (!contact?.id) return [];

    const query = `contactId:${contact.id}`;
    const update = await httpClient.search(query);
    const reservations = (update.reservations ?? []).map(
      (o) => new Reservation(o, rootGetters as ReservationMapping),
    );

    return reservations;
  },
  async getReservationHistory({ state, commit, rootGetters }): Promise<History> {
    if (!state.editedReservation || !state.originalReservation || state.originalReservation.id <= 0) {
      return { records: [], messages: [] };
    }

    const { records, messages } = await grpcClient.getReservationHistory(state.originalReservation.id);

    return {
      records: records.map((s) => JSON.parse(s) as HistoryRecord) ?? [],
      messages: messages.map((s) => JSON.parse(s) as HistoryMessage) ?? [],
    };
  },
  // reservation message
  async sendReservationMessage({ state, commit, rootGetters }, p: { message: Message }) {
    await grpcClient.sendMessage(p.message);
    return true;
  },
};

const getters = <GetterTree<IReservationState, IRootState>>{
  editReservation(state, localGetters, rootState, rootGetters) {
    const isddv = rootGetters.isDragDropValid;
    const ddr = rootGetters.dragDropReservation;
    if (!isddv || !ddr || !state.editedReservation || ddr.id !== state.editedReservation.id) {
      return state.editedReservation;
    }

    const er = cloneModel(state.editedReservation);

    er.tab = ddr.tab;
    er.tabItems = ddr.tabItems;
    er.dateBegin = ddr.dateBegin;
    er.dateEnd = ddr.dateEnd;
    er.updateSlotsAndTimes();

    return er;
  },
  originalReservation(state, localGetters, rootState, rootGetters) {
    return state.originalReservation;
  },
  isEditReservation(state, localGetters, rootState, rootGetters) {
    return state.editedReservation !== null;
  },
  editReservationId(state, localGetters, rootState, rootGetters) {
    return state.editedReservation?.id;
  },
  isChangedEditReservation(state, localGetters, rootState, rootGetters) {
    if (!state.editedReservation || !state.originalReservation) return false;
    return !isModelEqualToModel(state.editedReservation, state.originalReservation);
  },
  keepEditReservation(state, localGetters, rootState, rootGetters) {
    return state.keepOpen;
  },
  isConfirmCloseEditReservation(state, localGetters, rootState, rootGetters) {
    return state.confirmResolver !== null;
  },
  // basic validation
  reservationValidationErrors(state, localGetters, rootState, rootGetters): ReservationError[] {
    if (!state.editedReservation) return [];
    return validateReservation(state.editedReservation);
  },
  // availability validation
  reservationAvailabilityValidationErrors(state, localGetters, rootState, rootGetters): ReservationError[] {
    if (!state.editedReservation) return [];

    const reservations = rootGetters.dayValidReservationsNoBlocks as Reservation[];
    const timeSlot = rootGetters.dayTimeSlotsBySlot.get(state.editedReservation.slotBegin) as TimeSlot;
    const reservationsDuringSlot = rootGetters.dayValidReservationsAndBlocksDuringSlot as Map<number, Reservation[]>;
    const defaultMaxGuestsPerTime = rootState.settings.accountSettings.defaultMaxGuestsPerTime as number;
    const defaultMaxReservationsPerTime = rootState.settings.accountSettings.defaultMaxReservationsPerTime as number;

    return validateReservationAvailability(
      state.editedReservation,
      timeSlot,
      reservations,
      reservationsDuringSlot,
      { defaultMaxGuestsPerTime, defaultMaxReservationsPerTime },
    );
  },
  reservationOverbookedTabItems(state, localGetters, rootState, rootGetters): TabItem[] {
    if (!state.editedReservation) return [];
    const reservationsByTabItemAndSlot = rootGetters.dayValidReservationsAndBlocksByTabItemAndSlot;
    return overbookedTabItemsForReservation(state.editedReservation, reservationsByTabItemAndSlot);
  },
  reservationUnavailableTabItems(state, localGetters, rootState, rootGetters): TabItem[] {
    if (!state.editedReservation) return [];
    const reservationsDuringSlot = rootGetters.dayValidReservationsAndBlocksDuringSlot as Map<number, Reservation[]>;
    return unavailableTabItemsForReservation(state.editedReservation, reservationsDuringSlot);
  },
  reservationLastEmployee(state, localGetters, rootState, rootGetters): Employee | null {
    const employeeNameIsNeeded = rootGetters.employeeNameIsNeeded as boolean;
    const employee = employeeNameIsNeeded ? state.lastEmployee : null;
    return employee;
  },
  isReservationMessageAllowed(state, localGetters, rootState, rootGetters) {
    return state.originalReservation
      && (state.originalReservation.contact.email || state.originalReservation.contact.phone)
      && state.originalReservation.contact.email === state.editedReservation?.contact.email
      && state.originalReservation.contact.phone === state.editedReservation?.contact.phone;
  },
};

const ReservationStore = {
  namespaced: false,
  state: new ReservationState(),
  mutations,
  actions,
  getters,
};

export default ReservationStore;
