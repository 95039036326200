/* eslint-disable brace-style */
import store from '@/store/store';
import AccountSetting from '@/model/AccountSettings';
import vuetify from '@/plugins/vuetify';
import storage from '@/services/local-storage';

import { isModelEqualToModel } from '@/model/model-utils';
import {
  hourMinFromTimeText, setDateHoursMinutes, dateByAddingDays,
} from './time-utils';
import Timer from './timer';

let isDarkAllowed = true;

function isDark(accountSettings: AccountSetting): { dark?: boolean, next?: Date } {
  const { autoDarkMode, autoDarkModeStart, autoDarkModeEnd } = accountSettings;
  if (!autoDarkMode || !autoDarkModeStart || !autoDarkModeEnd) return { };

  const shm = hourMinFromTimeText(autoDarkModeStart);
  const ehm = hourMinFromTimeText(autoDarkModeEnd);
  if (!shm || !ehm) return {};

  const today = new Date();
  const start = setDateHoursMinutes(today, shm.h, shm.m);
  const end = setDateHoursMinutes(today, ehm.h, ehm.m);

  // same start and end -> dark mode
  if (start.getTime() === end.getTime()) return { dark: true };

  // start < end
  if (start.getTime() < end.getTime()) {
    // today < start < end -> LIGHT
    if (today.getTime() < start.getTime()) return { dark: false, next: start };

    // start <= today < end -> DARK
    if (today.getTime() < end.getTime()) return { dark: true, next: end };

    // start < end <= today -> LIGHT
    if (end.getTime() <= today.getTime()) return { dark: false, next: dateByAddingDays(start, 1) };
  }

  // end < start
  else {
    // today < end < start -> dark mode
    if (today.getTime() < end.getTime()) return { dark: true, next: end };

    // end <= today < start -> dark mode
    if (today.getTime() < start.getTime()) return { dark: false, next: start };

    // end < start <= today -> dark mode
    if (start.getTime() <= today.getTime()) return { dark: true, next: dateByAddingDays(end, 1) };
  }

  return {};
}

// change dark mode using account and schedule next update
function changeDark(accountSettings: AccountSetting) {
  const { dark, next } = isDark(accountSettings);
  console.log('autodarkmode changed: ', dark, next);
  if (dark !== undefined && isDarkAllowed) {
    if (vuetify.framework.theme.dark !== dark) vuetify.framework.theme.dark = dark;
  }
  // eslint-disable-next-line no-use-before-define
  timer.schedule(next);
}

// change dark mode using account settings upon time change
const timer = new Timer(() => {
  changeDark(store.state.settings.accountSettings);
});

// change dark mode using new account settings upon change
store.watch<AccountSetting>((state) => state.settings.accountSettings, (value, oldValue) => {
  if (isModelEqualToModel(value, oldValue)) return;

  changeDark(value);
});

// change dark mode using stored account settings upon creation
const accountSetting = storage.getAccountSetting();
if (accountSetting) changeDark(accountSetting);

// eslint-disable-next-line import/prefer-default-export
export function setDarkAllowed(allow: boolean) {
  if (isDarkAllowed === allow) return;
  isDarkAllowed = allow;

  if (!isDarkAllowed) vuetify.framework.theme.dark = false;
  else changeDark(store.state.settings.accountSettings);
}
