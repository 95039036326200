import { render, staticRenderFns } from "./OpeningHours.vue?vue&type=template&id=47b1243f&scoped=true&"
import script from "./OpeningHours.vue?vue&type=script&lang=ts&"
export * from "./OpeningHours.vue?vue&type=script&lang=ts&"
import style0 from "./OpeningHours.vue?vue&type=style&index=0&id=47b1243f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b1243f",
  null
  
)

export default component.exports