
import Vue, { PropType } from 'vue';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';
import RichTextEditor from '@/components/editors/RichTextEditor.vue';
import MessageTemplate from '@/model/MessageTemplate';
import Locale from '@/model/Locale';
import MessageRule from '@/model/MessageRule';
import DialogClosable from '@/mixins/dialog-closable';
import { ValidationObserver } from 'vee-validate';
import ValidatedTextField from '@/components/forms/ValidatedTextField.vue';
import { count } from 'sms-length';
import { MessageTypeChannel } from '@/model/MessageType';
import MessageHandlebar from '@/model/MessageHandlebar';
import toast from '@/plugins/toast-plugin/toast';
import { strcmp } from '@/services/common';

export default Vue.extend({
  name: 'MessageCenterTemplateDialog',
  components: { RichTextEditor, ValidatedTextField },
  mixins: [DialogClosable],
  props: {
    template: { type: Object as PropType<MessageTemplate>, required: true },
    rule: { type: Object as PropType<MessageRule>, required: true },
  },
  data() {
    return {
      t: cloneModel(this.template),
      oldt: cloneModel(this.template),
    };
  },
  computed: {
    locales(): Locale[] {
      const map = new Set<string>();
      this.rule.templates.forEach((o) => { if (!o.isDefault) map.add(o.locale ?? ''); });
      return this.$tstore.state.codes.locales
        .filter((o) => !map.has(o.locale))
        .sort((a: Locale, b: Locale) => strcmp(a.text, b.text));
    },
    isChanged(): boolean {
      return !isModelEqualToModel(this.t, this.oldt);
    },
    isNew(): boolean {
      return this.template.locale === undefined;
    },
    title(): string {
      return (this.template.locale && this.$tstore.getters.localesByLocale.get(this.template.locale)?.language)
      ?? this.$i18n.tc('title.new_template');
    },
    isSMS(): boolean {
      return this.rule.messageType?.channel === MessageTypeChannel.SMS;
    },
    smsMessageLength(): string {
      const c = count(this.t.body || '');
      return this.$i18n.t(
        'label.approx_sms_messages',
        [c.length, Math.min(603, c.characterPerMessage * (c.messages || 1)), c.messages],
      ) as string;
    },
    handlebars(): MessageHandlebar[] {
      return this.$tstore.state.messageCenter.handlebars;
    },
    placeholders(): {id: number, name: string}[] {
      const handlebars = this.handlebars.map((o) => ({ id: o.id, name: this.$i18n.tc(`code.handlebar.${o.name}`) }));
      return handlebars;
    },
  },
  watch: {
    visible() { },
  },
  created() {
    if (!this.t.locale) {
      const { locale } = this.$tstore.state.settings.account;
      if (!this.rule.templates.find((o) => !o.isDefault && o.locale === locale)) {
        this.t.locale = locale;
        this.localeChanged();
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async save() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) { toast.error(this.$i18n.tc('error.validation_failed')); return; }
      this.$emit('update-template', this.t);
    },
    cancel() {
      this.close();
    },
    localeChanged() {
      const template = this.rule.templates.find((o) => o.locale === this.t.locale) ?? new MessageTemplate();
      if (!template) return;

      this.t.body = template.body;
      this.t.subject = template.subject;
    },
  },
});
