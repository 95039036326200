/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import grpcClient from '@/grpc-api/grpc-client';
import { deleteModelEntities, mergeSortedModelEntities } from '@/grpc-api/model/model-utils';
import { OnErrorFnc } from '@/services/grpc-client-utils';
import { searchContactReset } from '@/services/contact-search';
import { ApiError } from '@/api/api-error';
import ReservationService, { ReservationServiceMapping } from '@/grpc-api/model/reservation-service';
import Currency from '@/grpc-api/model/currency';
import { toMapById } from '@/model/model-utils';
import IRootState, { IServiceManagerState } from './store-state';

export class ServiceManagerState implements IServiceManagerState {
  services: ReservationService[] = [];

  currencies: Currency[] = [];

  isLoaded: boolean = false;

  isFullyLoaded: boolean = false;
}

const mutations = <MutationTree<IServiceManagerState>>{
  RESET(state: ServiceManagerState) {
    Object.assign(state, new ServiceManagerState());
    searchContactReset();
  },
  RESET_SERVICE_MANAGER(state: ServiceManagerState) {
    Object.assign(state, new ServiceManagerState());
    searchContactReset();
  },
  UPDATE_SERVICE_MANAGER(state: ServiceManagerState, p: { services?: ReservationService[], currencies?: Currency[], isLoaded?: boolean, isFullyLoaded?: boolean }) {
    if (p.services) {
      deleteModelEntities(state.services, p.services);
      mergeSortedModelEntities(state.services, p.services, ReservationService.compare);
    }
    if (p.currencies) state.currencies = p.currencies;

    if (p.isLoaded) state.isLoaded = p.isLoaded;
    if (p.isFullyLoaded) state.isFullyLoaded = p.isFullyLoaded;

    console.log('services=', p.services);
    console.log('currencies=', p.currencies);
  },
};

const actions = <ActionTree<IServiceManagerState, IRootState>>{
  async resetServiceManager({ commit }) {
    commit('RESET_SERVICE_MANAGER');
  },
  async loadServiceManager({ commit, rootGetters }) {
    if (!rootGetters.isLoaded) return;

    let isLoaded = false;
    let isFullyLoaded = false;

    commit('RESET_SERVICE_MANAGER');

    const currencies = await grpcClient.listCurrencies();
    commit('UPDATE_SERVICE_MANAGER', { currencies });

    await grpcClient.listReservationServices(
      (data: ReservationService[]) => { isLoaded = true; commit('UPDATE_SERVICE_MANAGER', { services: data, isLoaded, isFullyLoaded }); },
      rootGetters as ReservationServiceMapping,
    );
    isLoaded = true;
    isFullyLoaded = true;
    commit('UPDATE_SERVICE_MANAGER', { isLoaded, isFullyLoaded });
  },
  async serviceManagerReorderServices({ commit, rootGetters }, p: { services: ReservationService[] }) {
    await grpcClient.reorderReservationServices(
      p.services,
      (services: ReservationService[]) => { commit('UPDATE_SERVICE_MANAGER', { services }); },
      rootGetters as ReservationServiceMapping,
    );
  },
  async serviceManagerUpdateService({ commit, rootGetters }, p: { service: ReservationService }) {
    const returnedService = await grpcClient.updateReservationService(
      p.service,
      (services: ReservationService[]) => { commit('UPDATE_SERVICE_MANAGER', { services }); },
      rootGetters as ReservationServiceMapping,
    );
    return true;
  },
};

const getters = <GetterTree<IServiceManagerState, IRootState>>{
  isServiceManagerLoaded(state: ServiceManagerState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded && rootGetters.isLoaded;
  },
  serviceManagertServices(state: ServiceManagerState, localGetters: any, rootState: any, rootGetters: any): ReservationService[] {
    return state.services;
  },
  currenciesById(state: ServiceManagerState, localGetters: any, rootState: any, rootGetters: any): Map<number, Currency> {
    return toMapById(state.currencies);
  },
};

const ServiceManagerStore = {
  namespaced: false,
  state: new ServiceManagerState(),
  mutations,
  actions,
  getters,
};

export default ServiceManagerStore;
