import * as pbm from '@/grpc-pb/reservation_pb';
import * as pbs from '@/grpc-pb/feedback_service_pb';

import ApiReservation, {
  ReservationMapping, ReservationSource, ReservationStatus, ReservationType,
} from '@/model/Reservation';
import { dateFromDateTimeString } from '@/services/time-utils';
import { ContactMapping } from '@/model/Contact';
import Label from '@/model/Label';
import { fixedPaymentInfo } from '@/services/billing-utils';
import Contact from './contact';
import Attachment from './attachment';

export default class Reservation extends ApiReservation {
  static fromGrpcResponse(r: pbs.FeedbackReservationResponse, m?: ReservationMapping): Reservation {
    const c = r.getReservation();
    return Reservation.fromGrpcModel(c, m);
  }

  static fromGrpcModel(o?: pbm.Reservation, m?: ReservationMapping): Reservation {
    const reservationTypeValues = ['', ReservationSource.Online, ReservationSource.User];
    const reservationStatusValues = ['',
      ReservationStatus.Planned,
      ReservationStatus.Seated,
      ReservationStatus.CheckedIn,
      ReservationStatus.CheckedOut,
      ReservationStatus.Confirmed,
      ReservationStatus.NonConfirmed,
      ReservationStatus.WaitingList,
      ReservationStatus.Cancelled,
      ReservationStatus.NoShow,
      ReservationStatus.Spam,
      ReservationStatus.Deleted,
      'pending',
    ];

    const r = new Reservation();

    r.id = o?.getId() ?? 0;
    r.partySize = o?.getPartySize() ?? 0;
    r.dateBegin = dateFromDateTimeString(o?.getDtBegin()) ?? new Date();
    r.dateEnd = dateFromDateTimeString(o?.getDtEnd()) ?? new Date();
    r.fixedEnding = o?.getEndFixed() ?? false;
    r.holdTabItems = o?.getHoldTabItems() ?? false;
    r.reservationType = reservationTypeValues[o?.getReservationType() ?? 0] as ReservationSource;
    r.code = o?.getReservationCode() ?? null;
    r.status = reservationStatusValues[o?.getReservationStatus() ?? 0] as ReservationStatus;
    r.isStarred = o?.getIsStarred() ?? false;
    r.isFlagged = o?.getIsFlagged() ?? false;
    r.isUnread = o?.getIsUnread() ?? false;
    r.dateCreated = o ? new Date(o.getDtCreate() * 1000) : new Date();
    r.dateEdited = o ? new Date(o.getDtUpdate() * 1000) : new Date();

    r.tab = m?.tabsById.get(o?.getTabId() ?? NaN) ?? null;
    r.service = m?.servicesById.get(o?.getServiceId() ?? NaN) ?? null;
    r.employeeCreated = m?.employeesById.get(o?.getEmployeeIdCreate() ?? NaN) ?? null;
    r.employeeEdited = m?.employeesById.get(o?.getEmployeeIdLastEdit() ?? NaN) ?? null;
    r.reservationCampaign = o?.getReservationCampaign() ?? null;

    r.label = o?.getReservationLabel()
      ? m?.labelsByText.get(o?.getReservationLabel()) ?? new Label({ labelText: o?.getReservationLabel() })
      : null;

    const reservedTabItems = o?.getTabItemsIdsList() ?? [];
    r.tabItems = reservedTabItems.flatMap((ti) => m?.tabItemsById?.get(ti) ?? []);
    r.tabItems.sort((o1, o2) => o1.order - o2.order);

    r.contact = Contact.fromGrpcModel(o?.getContact(), m as ContactMapping);

    r.notes = o?.getNoteText() || null;
    r.paymentInfo = o?.getPaymentInfo() ? fixedPaymentInfo(o.getPaymentInfo()) : undefined;
    r.attachments = (o?.getAttachmentsList() ?? []).map((a) => Attachment.fromGrpcModel(a));

    r.rating = o?.getRating() ?? null;

    r.bookingType = ReservationType.Reservation;
    r.updateBookingType();
    r.updateSlotsAndTimes();

    return r;
  }
}
