
import Vue, { PropType } from 'vue';
import 'echarts';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import { localize } from 'vee-validate';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'HourlyGuestsCard',
  components: {
    VChart,
  },
  props: {
    datasetSeated: { type: Object as PropType<Array<any>>, required: true },
    datasetIncoming: { type: Object as PropType<Array<any>>, required: true },
    datasetSeatedLimit: { type: Object as PropType<Array<any>>, required: true },
    datasetIncomingLimit: { type: Object as PropType<Array<any>>, required: true },
    datasetSeatedLimitVisible: { type: Boolean as PropType<boolean>, required: false, default: true },
    datasetIncomingLimitVisible: { type: Boolean as PropType<boolean>, required: false, default: true },
  },
  data() {
    return {
      option: (this as any).createDataset(),
      themeDark,
      themeLight,
    };
  },
  computed: {
    dark(): boolean {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    dark() {
      this.updateDataset();
    },
    datasetSeated: {
      deep: true,
      handler() {
        this.updateDataset();
      },
    },
    datasetIncoming: {
      deep: true,
      handler() {
        this.updateDataset();
      },
    },
    datasetIncomingLimit: {
      deep: true,
      handler() {
        this.updateDataset();
      },
    },
    datasetSeatedLimit: {
      deep: true,
      handler() {
        this.updateDataset();
      },
    },
    datasetSeatedLimitVisible() {
      this.updateDataset();
    },
    datasetIncomingLimitVisible() {
      this.updateDataset();
    },
  },

  methods: {
    createDataset(): any {
      const dsIncoming = this.datasetIncoming.map((val, i) => {
        if (val[1] > this.datasetIncomingLimit[i][1] && this.datasetIncomingLimitVisible) {
          return {
            value: val,
            itemStyle: {
              color: '#FF002B',
            },
          };
        }
        return val;
      });
      const dsSeated = this.datasetSeated.map((val, i) => {
        if (val[1] > this.datasetSeatedLimit[i][1] && this.datasetSeatedLimitVisible) {
          return {
            value: val,
            itemStyle: {
              color: '#ff8095',
            },
          };
        }
        return val;
      });

      const incomingLimitGradient = this.$vuetify.theme.dark
        ? [{ offset: 0, color: '#F3F5FF18' }, { offset: 1, color: '#F9FAFF02' }]
        : [{ offset: 0, color: '#3355ff18' }, { offset: 1, color: '#3355ff02' }];

      const series = [
        {
          name: this.$i18n.t('label.seated_guests'),
          type: 'bar',
          barGap: '-100%',
          zlevel: 9,
          data: dsSeated,
          itemStyle: {
            color: '#8599ff',
          },
        },
        {
          name: this.$i18n.t('label.incoming_guests'),
          type: 'bar',
          barGap: '-100%',
          zlevel: 10,
          data: dsIncoming,
          itemStyle: {
            color: '#3355ff',
          },
        }] as any[];

      if (this.datasetSeatedLimitVisible) {
        series.push(
          {
            name: this.$i18n.t('label.online_capacity'),
            type: 'line',
            step: 'middle',
            zlevel: 2,
            data: this.datasetSeatedLimit,
            symbol: 'none',
            itemStyle: {
              color: '#ff3d94',
            },
            lineStyle: {
              color: '#ff3d94',
            },
          },
        );
      }

      if (this.datasetIncomingLimitVisible) {
        series.push(
          {
            name: this.$i18n.t('label.max_guests_limit'),
            type: 'line',
            step: 'middle',
            zlevel: 1,
            data: this.datasetIncomingLimit,
            symbol: 'none',
            emphasis: {
              scale: false,
              lineStyle: {
                width: 0.5,
              },
            },
            itemStyle: {
              color: '#3355ff18',
            },
            lineStyle: {
              color: this.$vuetify.theme.dark ? 'white' : '#1C1A27',
              width: 0.5,
              type: [2, 4],
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: incomingLimitGradient,
              },
            },
          },
        );
      }

      return {
        grid: {
          left: '32px',
          top: '16px',
          right: '16px',
          bottom: '56px',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
          formatter(params: any) {
            const tooltipString = [] as any[];
            const d = params[0].data[0] ?? params[0].data.value[0] as Date;
            const t = `<p><b>${localized.shortDayMonthText(d)} ${localized.hourMinText(d)}</b></p>`;
            tooltipString.push(t);
            params.forEach((p: any) => {
              const cont = `${p.marker} ${p.seriesName}: ${p.value[1] ?? '-'}<br/>`;
              tooltipString.push(cont);
            });
            return tooltipString.join('');
          },
          extraCssText: 'min-width: 200px; max-width: 600px',
        },
        legend: {
          bottom: 'bottom',
          selectedMode: false,
        },
        xAxis: {
          splitNumber: 16,
          type: 'time',
          splitLine: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
          minorTick: {
            show: true,
            splitNumber: 4,
          },
          axisLabel: {
            formatter: (d: Date) => localized.hourMinText(d),
          },
        },
        yAxis: {
          type: 'value',
        },
        series,
      };
    },
    updateDataset() {
      this.option = this.createDataset();
    },
  },
});

