import * as pbm from '@/grpc-pb/employee_pb';

import ApiEmployee from '@/model/Employee';

export default class Employee extends ApiEmployee {
  toGrpcModel(): pbm.Employee {
    const o = new pbm.Employee();

    o.setId(this.id);
    o.setName(this.name);
    o.setOrder(this.order);

    return o;
  }

  static fromApiModel(o: ApiEmployee) {
    const e = new Employee();
    e.id = o.id;
    e.name = o.name;
    e.order = o.order;
    return e;
  }
}
