import GstplnApp from '@/components/apps/app';
import AppStoreApp from '@/model/AppStoreApp';

let apps: GstplnApp[] = [];

export function initializeApps(as: GstplnApp[]) {
  apps = as;
}

export function installApps(asas: AppStoreApp[]) {
  apps.forEach((app) => {
    let isInstalled = false;

    const a = asas.find((asa) => asa.name === app.apiName);
    if (a) isInstalled = a.isInstalled;

    // if (process.env.VUE_APP_SHOW_ALL_APPS === 'true') isInstalled = true;

    try {
      if (app.installed && !isInstalled) app.uninstall();
      if (!app.installed && isInstalled) app.install();
    } catch (e) {
      console.log('installApps error', e);
    }
  });
}

export function activeApps(asas: AppStoreApp[]): GstplnApp[] {
  if (process.env.VUE_APP_SHOW_ALL_APPS === 'true') return apps;

  const disabledApps = process.env.VUE_APP_HIDE_APPS?.split(',') || [];
  return apps.flatMap((app) => {
    const a = asas.find((asa) => asa.name === app.apiName && !disabledApps.includes(app.id));
    return a === undefined ? [] : app;
  });
}
