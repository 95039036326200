import { ref } from 'vue';
import useActivityIndicator from './activity-indicator';

export default function usePwaUpdate() {
  const { showActivity } = useActivityIndicator();

  const refreshing = ref(false);
  const registration = ref(null as null | ServiceWorkerRegistration);
  const updateAvailable = ref(false);

  // Called when the user accepts the update
  const refreshApp = () => {
    updateAvailable.value = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration.value || !registration.value.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  };

  // Listen for SW update notification
  const updateBecameAvailable = (event: any) => {
    registration.value = event.detail;
    updateAvailable.value = true;
  };

  // Prevent multiple refreshes
  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing.value) return;
      refreshing.value = true;
      showActivity(true);
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  document.addEventListener('swUpdated', updateBecameAvailable, { once: true });

  // Force Check for update every app activation
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      console.log('Checking for updates...');
      navigator.serviceWorker.getRegistration().then(
        (r) => (r ? r.update() : console.log('Service Worker Not Found!')),
      );
    }
  });

  return { updateAvailable, refreshApp };
}
