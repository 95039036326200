
import Vue, { PropType } from 'vue';
import 'moment-range';
import {
  dateStringFromDate8601, dateFromDateString8601, areDatesEqual, dateFlooredToDate,
} from '@/services/time-utils';
import {
  DateRange, dateRangeDatesFromPeriod, DateRangePeriod,
  periodFromDateRangeDates, shiftedDateRangeFromPeriodAndRange, formattedDateRange,
  DateRangePickerItem, DateRangePeriodsPast, DateRangePeriods, createPickerItems,
} from '@/services/date-range';
import { reportsValidateDateRange } from '@/services/reports-utils';
import debounce from 'lodash.debounce';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DebouncedFunc } from 'lodash';

export default Vue.extend({
  name: 'DateRangePickerField',
  props: {
    value: { type: Object as PropType<DateRange>, required: true },
    previousPeriodDisabled: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    type: { type: String, required: false, default: 'date' },
    dense: { type: Boolean, required: false, default: false },
    hideDetails: { type: Boolean, required: false, default: true },
    outlined: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, required: false, default: false },
    errorMessages: { type: Array, required: false, default: null },
    contentClass: { type: String, required: false, default: '' },
    contentStyle: { type: String, required: false, default: 'min-width: 110px; max-width: 190px' },
    disableFuture: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      date: null as null | string[],
      beginDate: dateFlooredToDate(new Date()),
      endDate: dateFlooredToDate(new Date()),
      pickerDate: undefined as undefined | string,
      previousBeginDate: undefined as Date | undefined,
      previousEndDate: undefined as Date | undefined,
      period: DateRangePeriod.Today,
      previousPeriod: false,
      menu: false,
      debouncedApply: undefined as undefined | DebouncedFunc <() => Promise<void>>,
    };
  },
  computed: {
    periods(): DateRangePeriod[] {
      return this.disableFuture ? DateRangePeriodsPast : DateRangePeriods;
    },
    items(): DateRangePickerItem[] {
      return createPickerItems(this.periods);
    },
    selectPeriod(): DateRangePeriod {
      if (this.period.startsWith('custom')) return DateRangePeriod.Custom;
      return this.period;
    },
    editedDateRange(): DateRange {
      const dateRange: DateRange = {
        period: this.period,
        firstDayOfWeek: this.$localized.firstDayOfWeek(),
        beginDate: this.beginDate,
        endDate: this.endDate,
        previousBeginDate: this.previousBeginDate,
        previousEndDate: this.previousEndDate,
      };
      return dateRange;
    },
    formattedCurrentDateRange(): string {
      return formattedDateRange(this.value).main;
    },
    formattedEditedDateRange(): string {
      return formattedDateRange(this.editedDateRange).main;
    },
    formattedEditedPreviousDateRange(): string {
      return formattedDateRange(this.editedDateRange).previous;
    },
    editedError(): string | undefined {
      return reportsValidateDateRange(this.editedDateRange);
    },
  },
  watch: {
    value(value: DateRange) {
      this.reset();
    },
    date() {
      this.pickerDate = this.date === null || this.date.length === 0 ? undefined : this.date[0];
    },
  },
  created() {
    this.reset();
    this.debouncedApply = debounce(this.apply, 100);
  },
  methods: {
    clear() {
      this.reset();
    },
    reset() {
      this.beginDate = this.value.beginDate;
      this.endDate = this.value.endDate;
      this.period = periodFromDateRangeDates(
        this.periods,
        this.beginDate,
        this.endDate,
        this.$localized.firstDayOfWeek(),
      );
      this.previousPeriod = this.value.previousBeginDate !== undefined && this.value.previousEndDate !== undefined;

      if (areDatesEqual(this.beginDate, this.endDate)) {
        this.date = [dateStringFromDate8601(this.beginDate)!];
      } else {
        this.date = [dateStringFromDate8601(this.beginDate)!, dateStringFromDate8601(this.endDate)!];
      }

      this.recalculatePreviousPeriod();
    },
    async dateChanged(date: string[]|null) {
      if (!date) return;
      if (date.length === 1) {
        this.beginDate = dateFromDateString8601(date[0])!;
        this.endDate = this.beginDate;
      } else if (date.length === 2) {
        this.beginDate = dateFromDateString8601(date[0])!;
        this.endDate = dateFromDateString8601(date[1])!;

        if (this.beginDate > this.endDate) {
          this.date = [date[1], date[0]];
          [this.beginDate, this.endDate] = [this.endDate, this.beginDate];
        }
      }

      this.period = periodFromDateRangeDates(
        this.periods,
        this.beginDate,
        this.endDate,
        this.$localized.firstDayOfWeek(),
      );
      this.recalculatePreviousPeriod();

      await this.debouncedApply!();
    },
    async periodChanged(period: DateRangePeriod) {
      if (period !== DateRangePeriod.Custom) {
        const { begin, end } = dateRangeDatesFromPeriod(period, this.$localized.firstDayOfWeek());

        this.beginDate = begin;
        this.endDate = end;
        this.period = period;

        if (areDatesEqual(this.beginDate, this.endDate)) {
          this.date = [dateStringFromDate8601(this.beginDate)!];
        } else {
          this.date = [dateStringFromDate8601(this.beginDate)!, dateStringFromDate8601(this.endDate)!];
        }
      }

      this.recalculatePreviousPeriod();

      await this.debouncedApply!();
    },
    async shiftPeriod(previous = true) {
      const { begin, end, period } = shiftedDateRangeFromPeriodAndRange(
        this.periods,
        this.period,
        this.beginDate,
        this.endDate,
        this.$localized.firstDayOfWeek(),
        previous,
      );

      this.beginDate = begin;
      this.endDate = end;
      this.period = period;

      if (areDatesEqual(this.beginDate, this.endDate)) {
        this.date = [dateStringFromDate8601(this.beginDate)!];
      } else {
        this.date = [dateStringFromDate8601(this.beginDate)!, dateStringFromDate8601(this.endDate)!];
      }

      this.recalculatePreviousPeriod();

      await this.debouncedApply!();
    },
    async previousPeriodChanged() {
      this.recalculatePreviousPeriod();
      await this.debouncedApply!();
    },
    recalculatePreviousPeriod() {
      if (!this.previousPeriod) {
        this.previousBeginDate = undefined;
        this.previousEndDate = undefined;
      } else {
        const { begin, end } = shiftedDateRangeFromPeriodAndRange(
          this.periods,
          this.period,
          this.beginDate,
          this.endDate,
          this.$localized.firstDayOfWeek(),
        );
        this.previousBeginDate = begin;
        this.previousEndDate = end;
      }
    },
    apply() {
      if (this.editedError) return;

      const dateRange: DateRange = {
        period: this.period,
        firstDayOfWeek: this.$localized.firstDayOfWeek(),
        beginDate: this.beginDate,
        endDate: this.endDate,
        previousBeginDate: this.previousBeginDate,
        previousEndDate: this.previousEndDate,
      };
      this.$emit('input', dateRange);
    },
  },
});
