
import { hostedWidgetURL, WIDGET_DOMAIN } from '@/services/configuration';
import Campaign from '@/grpc-api/model/campaign';
import Vue from 'vue';
import { performAction } from '@/services/vue-utils';
import grpcClient from '@/grpc-api/grpc-client';
import InputDialog from '@/components/dialogs/InputDialog.vue';
import { cloneModel } from '@/model/model-utils';
import { extend } from 'vee-validate';
import { ApiErrorCode } from '@/api/api-error';

export default Vue.extend({
  name: 'WelcomeStepOnline',
  components: { InputDialog },
  props: { },
  data() {
    return {
      widgetSubdomain: null as string | null,
      campaign: null as Campaign | null,
      urlEditorVisible: false,
    };
  },
  computed: {
    widgetDomain(): string {
      return `.${WIDGET_DOMAIN}`;
    },
    hostedFormUrl(): string {
      const subdomain = this.campaign?.subdomain;
      return subdomain ? hostedWidgetURL({ subdomain }) : this.$tstore.getters.hostedWidgetURL ?? 'N/A';
    },
    widgetDocumentationURL(): string {
      return this.$tstore.state.settings.account.widgetDocumentationURL || '';
    },
  },
  watch: { },
  mounted() {
    extend('unique_widget_url', {
      validate: async (value, _) => {
        if (this.campaign === null || value === undefined) return true;
        const nc = cloneModel(this.campaign);
        nc.subdomain = value;
        return this.testCampaign(nc);
      },
      lazy: true,
    });

    // this.reset();
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    async reset() {
      this.campaign = null;
      this.widgetSubdomain = null;
      if (!this.$tstore.getters.isLoaded) return;

      await this.loadCampaign();
    },

    async openURLEditor() {
      const ok = await this.loadCampaign();
      if (ok) this.urlEditorVisible = true;
    },
    closeURLEditor() {
      this.urlEditorVisible = false;
    },
    async saveURLEditor() {
      if (!this.campaign) { this.urlEditorVisible = false; return; }

      const nc = cloneModel(this.campaign);
      nc.subdomain = this.widgetSubdomain ?? undefined;

      const ok = await this.updateCampaign(nc);
      if (ok) this.urlEditorVisible = false;
    },
    async loadCampaign(): Promise<boolean> {
      const ok = await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => {
          this.campaign = await grpcClient.getDefaultCampaign();
          this.widgetSubdomain = this.campaign.subdomain ?? null;
        },
      );
      return ok;
    },
    async testCampaign(c: Campaign): Promise<string|boolean> {
      try {
        await grpcClient.testDefaultCampaign(c);
      } catch (e: any) {
        if (e.code === ApiErrorCode.already_exists || e.code === ApiErrorCode.subdomain_already_exists) {
          return this.$i18n.tc('error.url_already_taken');
        }
        if (e.code === ApiErrorCode.invalid_argument || e.code === ApiErrorCode.subdomain_invalid) {
          return this.$i18n.tc('error.url_not_allowed');
        }
        return true;
      }
      return true;
    },
    async updateCampaign(c: Campaign): Promise<boolean> {
      const ok = await performAction(
        null,
        this.$i18n.tc('error.save_data'),
        async () => {
          this.campaign = await grpcClient.updateDefaultCampaign(c);
        },
      );
      return ok;
    },
  },
});
