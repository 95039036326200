
import Vue, { PropType } from 'vue';
import FloorplanShapeSeat from './FloorplanShapeSeat.vue';
import { ITableSize, ITableSeats } from './floorplan-types';

export default Vue.extend({
  name: 'FloorplanShapeTableRound',
  components: { FloorplanShapeSeat },
  props: {
    fillColor: { type: String, required: true },
    strokeColor: { type: String, required: true },
    seats: { type: Number, required: true },
    partySize: { type: Number, required: false, default: 0 },
    tableSize: { type: Object as PropType<ITableSize>, required: true },
  },
  data() { return { }; },
  computed: {
    tableSeats(): ITableSeats | null {
      const seatsToDraw = Math.min(28, this.seats);
      const anglePerSeat = 360 / seatsToDraw;

      const ts = {} as ITableSeats;
      ts.n = [];
      let occupied = this.partySize;
      for (let i = 0; i < seatsToDraw; i += 1) {
        ts.n.push({ origin: 0, rotation: anglePerSeat * i, occupied: occupied > 0 });
        occupied -= 1;
      }
      if (seatsToDraw < this.seats) {
        ts.n[1].isOverflow = true;
      }
      return ts;
    },
  },
  watch: { },
  mounted() { },
  methods: { },
});

