import { IDateAndTime, IOpeningHour } from '@/api/api-update';
import { sprintf } from 'sprintf-js';

export default class HourSlot {
  minimumPartySize?: number;

  maximumPartySize?: number;

  maximumGuests?: number;

  maximumReservations?: number;

  fixedEndingInMinutes?: number;

  // eslint-disable-next-line class-methods-use-this
  get isSpecial(): boolean { return false; }

  // eslint-disable-next-line class-methods-use-this
  get isOpen(): boolean { return false; }

  // eslint-disable-next-line class-methods-use-this
  // get timeIndex(): number { return 0; }

  // profile id
  get profileId(): number {
    const text = sprintf(
      '%03d%03d%03d%03d%03d',
      this.minimumPartySize ?? 0,
      this.maximumPartySize ?? 0,
      this.maximumGuests ?? 0,
      this.maximumReservations ?? 0,
      this.fixedEndingInMinutes ?? 0,
    );
    return Number(text);
  }

  constructor(o?: IOpeningHour | IDateAndTime) {
    this.minimumPartySize = o?.minimumPartySize;
    this.maximumPartySize = o?.maximumPartySize;
    this.maximumGuests = o?.maximumGuests;
    this.maximumReservations = o?.maximumReservations;
    this.fixedEndingInMinutes = o?.fixedEndingInMinutes;
  }

  get isCustom(): boolean {
    return Boolean(
      this.minimumPartySize ?? this.maximumPartySize
      ?? this.maximumGuests ?? this.maximumReservations
      ?? this.fixedEndingInMinutes,
    );
  }

  clearParams() {
    delete this.minimumPartySize;
    delete this.maximumPartySize;
    delete this.maximumGuests;
    delete this.maximumReservations;
    delete this.fixedEndingInMinutes;
  }

  setParams(p: {
    minimumPartySize?: number, maximumPartySize?: number, maximumGuests?: number, maximumReservations?: number,
    fixedEndingInMinutes?: number
  }) {
    this.minimumPartySize = p.minimumPartySize;
    this.maximumPartySize = p.maximumPartySize;
    this.maximumGuests = p.maximumGuests;
    this.maximumReservations = p.maximumReservations;
    this.fixedEndingInMinutes = p.fixedEndingInMinutes;
  }
}
