import { ITimeZone } from '@/api/api-update';

export default class TimeZone {
  id: number;

  name: string;

  countryCode: string;

  constructor(o: ITimeZone) {
    this.id = o.timeZoneId ?? 0;
    this.countryCode = o.countryCode ?? '';
    this.name = o.timeZoneName ?? '';
  }

  get value() { return this.id; }

  get text() { return this.name; }
}
