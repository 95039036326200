
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'MiniPieChart',
  components: {
    VChart,
  },
  props: {
    title: { type: String, required: true },
    dataset: { type: Array as PropType<Array<{value: number, name: string, unit?: string}>>, default: () => [] },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
    };
  },
  computed: {
  },
  watch: {
    dataset() { this.reset(); },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      return {
        grid: {
          left: '8px',
          top: '8px',
          right: '8px',
          bottom: '8px',
        },
        tooltip: {
          trigger: 'item',
          formatter: (params:any, ticket: string) => `
            ${params.marker} ${params.name}:
            <span class="font-weight-bold">${this.$i18n.n(params.value)} (${params.percent}%)</span>`,

        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              overflow: 'break',
              width: 150,
            },
            labelLayout: {
              width: 150,
            },
            itemStyle: {
              borderRadius: 8,
              borderColor: '#fff',
              borderWidth: 2,
            },
            data: this.dataset,
          },

        ],
      };
    },
  },
});

