import {
  Customer, Invoice, Product, Subscription, PaymentMethod, SetupIntent, Price, Country, ProductType,
  SMSProduct, Currency,
} from '@/api/api-billing';
import httpClient from '@/api/http-client';
import { maxSubscriptionTierLevel, preferedCountryCurrency, toDTOCustomer } from '@/services/billing-utils';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import IRootState, { IBillingState } from './store-state';

function fixPrice(products: Product[], price: Price) {
  let product = products.find((pr) => pr.ID === price.productID);

  // fallback
  if (!product) {
    product = products.find((pr) => pr.prices?.some((p) => p.ID === price.ID));
  }

  // eslint-disable-next-line no-param-reassign
  price.product = product;
}

function fixInvoices(products: Product[], invoices: Invoice[]) {
  invoices.forEach((i) => {
    i.lines?.forEach((il) => {
      if (!il.price) return;
      fixPrice(products, il.price);
    });
  });
}

export class BillingState implements IBillingState {
  isLoaded: boolean = false;

  products: Product[] = [];

  customer: Customer | null = null;

  invoices: Invoice[] = [];

  paymentMethods: PaymentMethod[] = [];

  setupIntent: SetupIntent | null = null;

  country: Country | null = null;

  smsProduct: SMSProduct | null = null;

  invoicesLimit: number | null = 11;
}

const mutations = <MutationTree<IBillingState>>{
  RESET(state: BillingState) {
    Object.assign(state, new BillingState());
  },
  RESET_BILLING(state: BillingState) {
    Object.assign(state, new BillingState());
  },
  UPDATE_BILLING(
    state: BillingState,
    p: {
      customer?: Customer | null,
      products?: Product[],
      invoices?: Invoice[],
      paymentMethods?: PaymentMethod[],
      setupIntent: SetupIntent | null
      subscriptionProducts: Product[],
      smsProduct: SMSProduct | null,
      country?: Country | null,
    },
  ) {
    if (p.products) state.products = p.products;
    if (p.customer !== undefined) {
      if (p.customer?.subscription?.price) fixPrice(state.products, p.customer?.subscription?.price);
      if (p.customer?.subscription?.scheduledPrice) fixPrice(state.products, p.customer?.subscription?.scheduledPrice);
      if (p.customer?.openInvoices) fixInvoices(state.products, p.customer?.openInvoices);
      state.customer = p.customer;
    }
    if (p.paymentMethods) state.paymentMethods = p.paymentMethods;
    if (p.setupIntent) state.setupIntent = p.setupIntent;
    if (p.invoices && p.invoices.length > 0) {
      fixInvoices(state.products, p.invoices);
      const latest = p.invoices[p.invoices.length - 1];
      const latestIndex = state.invoices.findIndex((o) => o.created! < latest.created!);
      const tokeep = latestIndex > 0 ? state.invoices.slice(latestIndex) : [];
      state.invoices = [...p.invoices, ...tokeep];
    }
    if (p.smsProduct !== undefined) state.smsProduct = p.smsProduct;
    if (p.country !== undefined) state.country = p.country;

    if (state.customer !== null && state.customer.currency === '') state.customer.currency = undefined;

    state.isLoaded = true;

    console.log('Billing products:', state.products);
    console.log('Billing customer:', state.customer);
    console.log('Billing invoices:', state.invoices);
    console.log('Billing sms info:', state.smsProduct);
    console.log('Billing payment methods:', state.paymentMethods);
    console.log('Billing setup intent:', state.setupIntent);
    console.log('Billing geoip country:', state.country);
  },
  BILLING_SET_INVOICES_LIMIT(state: BillingState, limit: number|null) {
    state.invoicesLimit = limit;
  },
  UPDATE_BILLING_INVOICES(state: BillingState, p: { invoices: Invoice[]}) {
    fixInvoices(state.products, p.invoices);

    p.invoices.forEach((ni) => {
      const i = state.invoices.findIndex((oi) => oi.ID === ni.ID);
      if (i >= 0) {
        state.invoices.splice(i, 1, ni);
      } else {
        state.invoices = [ni, ...state.invoices];
      }
    });
  },
};

const actions = <ActionTree<IBillingState, IRootState>>{
  async resetBilling({ state, commit, rootGetters }) {
    commit('RESET_BILLING');
  },
  async billingSetInvoicesLimit({
    state, commit, rootGetters, dispatch,
  }, p: { limit: number | null }) {
    if (!state.invoicesLimit || (p.limit && p.limit <= state.invoicesLimit)) return;
    commit('BILLING_SET_INVOICES_LIMIT', p.limit ? p.limit + 1 : null);
    await dispatch('billingLoadInvoices');
  },
  async loadBilling({
    state, commit, rootGetters, rootState, dispatch,
  }, p?: { force: boolean }) {
    if (!rootGetters.isLoaded) return;
    const isBillingAllowed = rootGetters.isBillingAllowed as boolean;
    if (!isBillingAllowed) {
      const customer = await httpClient.getCustomer();
      commit('UPDATE_BILLING', { customer });
    } else {
      const invoicesLimit = state.invoicesLimit ?? undefined;
      const {
        products, customer, invoices, paymentMethods, smsProduct, country,
      } = await httpClient.getBilling({ invoicesLimit });

      commit('UPDATE_BILLING', {
        products, customer, invoices, paymentMethods, smsProduct, country,
      });

      // email fix
      const { email } = rootState.settings.account;

      if (state.customer?.ID && !state.customer.email && email) {
        try {
          const cust = toDTOCustomer(state.customer);
          cust.email = email;
          await dispatch('billingUpdateCustomer', { customer: cust });
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  // async loadProducts({ state, commit, rootGetters }) {
  //   if (!rootGetters.isLoaded) return;
  //   const products = await httpClient.getProducts();
  //   commit('UPDATE_BILLING', { products });
  // },
  // async loadCustomer({ state, commit, rootGetters }) {
  //   if (!rootGetters.isLoaded) return;
  //   const customer = await httpClient.getCustomer();
  //   commit('UPDATE_BILLING', { customer });
  // },
  async billingLoadInvoices({ state, commit, rootGetters }) {
    if (!rootGetters.isLoaded) return;
    const limit = state.invoicesLimit ?? undefined;
    console.log('billingLoadInvoices', limit);
    const invoices = await httpClient.getInvoices({ limit });
    commit('UPDATE_BILLING', { invoices });
  },
  async billingCreateCustomer({ state, commit, rootGetters }, p: { customer: Customer }): Promise<Customer | null> {
    if (!rootGetters.isLoaded) return null;
    const customer = await httpClient.createCustomer(p.customer);
    commit('UPDATE_BILLING', { customer });
    return customer;
  },
  async billingUpdateCustomer({ state, commit, rootGetters }, p: { customer: Customer }): Promise<Customer | null> {
    if (!rootGetters.isLoaded) return null;
    const customer = await httpClient.updateCustomer(p.customer);
    commit('UPDATE_BILLING', { customer });
    return customer;
  },
  async billingLoadSMSProduct({ commit, rootGetters }, p: { smsProduct: SMSProduct }): Promise<SMSProduct | null> {
    if (!rootGetters.isLoaded) return null;
    const smsProduct = await httpClient.getSMSProduct();
    commit('UPDATE_BILLING', { smsProduct });
    return smsProduct;
  },
  async billingCreateSMSProduct({ commit, rootGetters }, p: { smsProduct: SMSProduct }): Promise<SMSProduct | null> {
    if (!rootGetters.isLoaded) return null;
    const smsProduct = await httpClient.createSMSProduct(p.smsProduct);
    commit('UPDATE_BILLING', { smsProduct });
    return smsProduct;
  },
  async billingUpdateSMSProduct({ commit, rootGetters }, p: { smsProduct: SMSProduct }): Promise<SMSProduct | null> {
    if (!rootGetters.isLoaded) return null;
    const smsProduct = await httpClient.updateSMSProduct(p.smsProduct);
    commit('UPDATE_BILLING', { smsProduct });
    return smsProduct;
  },
  async billingCreateSubscription(
    { state, commit, rootGetters },
    p: {
      priceID: string, paymentMethodTypes?: string[], idempotencyKey?: string, deactivatePaymentMethod?: boolean,
    },
  ): Promise<Customer | null> {
    if (!rootGetters.isLoaded) return null;
    const customer = await httpClient.createSubscription(p);
    const createdAfter = state.invoices.find(Boolean)?.created;
    const limit = state.invoicesLimit ?? undefined;
    const invoices = await httpClient.getInvoices({ createdAfter, limit });
    commit('UPDATE_BILLING', { customer, invoices });
    return customer;
  },
  async billingUpdateSubscription(
    { state, commit, rootGetters },
    p: { priceID?: string, paymentMethodTypes?: string[], idempotencyKey?: string, prorationDate?: number},
  ): Promise<Customer | null> {
    if (!rootGetters.isLoaded) return null;
    const customer = await httpClient.updateSubscription(p);
    const createdAfter = state.invoices.find(Boolean)?.created;
    const limit = state.invoicesLimit ?? undefined;
    const invoices = await httpClient.getInvoices({ createdAfter, limit });
    commit('UPDATE_BILLING', { customer, invoices });
    return customer;
  },
  async billingCancelSubscription({ state, commit, rootGetters }): Promise<Customer | null> {
    if (!rootGetters.isLoaded) return null;
    const customer = await httpClient.cancelSubscription();
    const createdAfter = state.invoices.find(Boolean)?.created;
    const limit = state.invoicesLimit ?? undefined;
    const invoices = await httpClient.getInvoices({ createdAfter, limit });
    commit('UPDATE_BILLING', { customer, invoices });
    return customer;
  },
  async billingDeleteSubscription({ state, commit, rootGetters }): Promise<Customer | null> {
    if (!rootGetters.isLoaded) return null;
    const customer = await httpClient.deleteSubscription();
    const createdAfter = state.invoices.find(Boolean)?.created;
    const limit = state.invoicesLimit ?? undefined;
    const invoices = await httpClient.getInvoices({ createdAfter, limit });
    commit('UPDATE_BILLING', { customer, invoices });
    return customer;
  },
  async billingPreviewSubscription({ state, commit, rootGetters }, p: { priceID: string }): Promise<Invoice | null> {
    if (!rootGetters.isLoaded) return null;

    const invoice = await httpClient.previewSubscription(p);
    fixInvoices(state.products, [invoice]);

    return invoice;
  },
  async billingAddPaymentMethod({ state, commit, rootGetters }, p: { priceID: string }): Promise<SetupIntent | null> {
    if (!rootGetters.isLoaded) return null;
    const setupIntent = await httpClient.addPaymentMethod();
    commit('UPDATE_BILLING', { setupIntent });
    return setupIntent;
  },
  async billingFinishPaymentMethod(
    { rootGetters, commit },
    p: { intentID: string, activate?: boolean },
  ): Promise<SetupIntent | null> {
    if (!rootGetters.isLoaded) return null;
    const intent = await httpClient.finishPaymentMethod(p);

    const customer = await httpClient.getCustomer();
    const paymentMethods = await httpClient.getPaymentMethods();
    commit('UPDATE_BILLING', { customer, paymentMethods });

    return intent;
  },
  async billingGetPaymentMethod(
    { rootGetters, commit },
    p: { paymentMethodID: string },
  ): Promise<PaymentMethod | null> {
    if (!rootGetters.isLoaded) return null;
    const paymentMethod = await httpClient.getPaymentMethod(p.paymentMethodID);
    return paymentMethod;
  },
  async billingDeletePaymentMethod({ state, commit, rootGetters }, p: { paymentMethodID: string }): Promise<void> {
    if (!rootGetters.isLoaded) return;
    await httpClient.deletePaymentMethod(p.paymentMethodID);
    const paymentMethods = await httpClient.getPaymentMethods();
    commit('UPDATE_BILLING', { paymentMethods });
  },
  async billingActivatePaymentMethod({ state, commit, rootGetters }, p: { paymentMethodID: string }): Promise<void> {
    if (!rootGetters.isLoaded) return;
    const customer = await httpClient.activatePaymentMethod(p.paymentMethodID);
    const paymentMethods = await httpClient.getPaymentMethods();
    commit('UPDATE_BILLING', { customer, paymentMethods });
  },
  async billingCreatePaymentInvoice(
    { state, rootGetters, commit },
    p: { priceIDs: string[] },
  ): Promise<Invoice | null> {
    if (!rootGetters.isLoaded) return null;
    const invoice = await httpClient.createPaymentInvoice(p);
    commit('UPDATE_BILLING_INVOICES', { invoices: [invoice] });
    return invoice;
  },
  async billingFinalizePaymentInvoice(
    { state, rootGetters, commit },
    p: { invoiceID: string, paymentMethodTypes: string[], confirm: boolean },
  ): Promise<Invoice | null> {
    if (!rootGetters.isLoaded) return null;
    const invoice = await httpClient.finalizePaymentInvoice(p);
    commit('UPDATE_BILLING_INVOICES', { invoices: [invoice] });
    return invoice;
  },
  async billingFinishPaymentInvoice(
    { state, rootGetters, commit },
    p: { invoiceID: string, activate?: boolean, bankTransfer?: boolean },
  ): Promise<Invoice | null> {
    if (!rootGetters.isLoaded) return null;
    const invoice = await httpClient.finishPaymentInvoice(p);
    if (p.bankTransfer === true) {
      // ignore the latest error from previous charge attemp
      if (invoice.paymentIntent) {
        invoice.paymentIntent.lastDeclineCode = undefined;
        invoice.paymentIntent.lastError = undefined;
        invoice.paymentIntent.lastErrorType = undefined;
        invoice.paymentIntent.lastErrorCode = undefined;
      }
    } else {
      // remove bank transfer instructions if present
      // eslint-disable-next-line no-lonely-if
      if (invoice.paymentIntent?.nextAction?.displayIBANBankTransferInstructions) {
        invoice.paymentIntent.nextAction.displayIBANBankTransferInstructions = undefined;
      }
    }

    commit('UPDATE_BILLING_INVOICES', { invoices: [invoice] });

    const [customer, paymentMethods, smsProduct] = await Promise.all([
      httpClient.getCustomer(),
      httpClient.getPaymentMethods(),
      httpClient.getSMSProduct(),
    ]);

    commit('UPDATE_BILLING', { customer, paymentMethods, smsProduct });

    return invoice;

    // const isSubscription = invoice?.lines?.some((l) => l.price?.product?.type === ProductType.Subscription);
    // let subscription: Subscription | undefined;

    // if (p.activate || isSubscription) {
    //   const customer = await httpClient.getCustomer();
    //   const paymentMethods = await httpClient.getPaymentMethods();
    //   subscription = customer?.subscription;
    //   commit('UPDATE_BILLING', { customer, paymentMethods });
    // }

    // return { invoice, subscription };
  },
  async billingDeletePaymentInvoice({ rootGetters }, p: { invoiceID: string }): Promise<Invoice | null> {
    if (!rootGetters.isLoaded) return null;
    const invoice = await httpClient.deletePaymentInvoice(p);
    return invoice;
  },
  async billingGetInvoice({ state, rootGetters, commit }, p: { invoiceID: string }): Promise<Invoice | null> {
    if (!rootGetters.isLoaded) return null;
    const invoice = await httpClient.getInvoice(p);

    // // finalize bank transfer invoice if needed
    // if (state.customer?.customerBalanceStatus === CustomerBalanceStatus.Granted
    //   && invoice.paymentMethodTypes?.includes(PaymentMethodType.CustomerBalance)
    //   && invoice.paymentIntent?.nextAction?.displayIBANBankTransferInstructions === undefined) {
    //   invoice = await httpClient.finalizePaymentInvoice(
    //     { ...p, paymentMethodTypes: [PaymentMethodType.CustomerBalance], confirm: true },
    //   );
    // }

    commit('UPDATE_BILLING_INVOICES', { invoices: [invoice] });

    return invoice;
  },
};

const getters = <GetterTree<IBillingState, IRootState>>{
  billingSubscriptionPriceProducts(state: BillingState, localGetters: any): Product[] {
    const products = state.products.filter((p) => p.type === ProductType.Subscription && p.active);
    products.sort((a, b) => (a.subscriptionTierLevel ?? 0) - (b.subscriptionTierLevel ?? 0));
    return products;
  },
  billingMaxSubscriptionTierLevel(state: BillingState, localGetters: any): number {
    return maxSubscriptionTierLevel(localGetters.billingSubscriptionPriceProducts);
  },
  billingSMSPriceProduct(state: BillingState, localGetters: any): Product | null {
    return state.products.find((p) => p.type === ProductType.SMS && p.active) ?? null;
  },
  billingCurrencies(state: BillingState, localGetters: any): string[] {
    const currencySet = new Set<string>();
    state.products.forEach((product) => {
      product.prices?.forEach((price) => currencySet.add(price.currency!));
    });
    return Array.from(currencySet);
  },
  billingCustomer(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Customer | null {
    return state.customer ?? null;
  },
  billingSubscriptionDueInvoices(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Invoice[] {
    return state.customer?.openInvoices?.filter((i) => i.overDue) ?? [];
  },
  billingSubscriptionOpenInvoices(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Invoice[] {
    return state.customer?.openInvoices?.filter((i) => i.generatedBySubscription) ?? [];
  },
  billingSubscription(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Subscription | null {
    return state.customer?.subscription ?? null;
  },
  billingInvoices(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Invoice[] {
    return state.invoices;
  },
  billingLatestInvoice(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Invoice | null {
    return state.invoices.find(Boolean) ?? null;
  },
  billingPaymentMethods(state: BillingState, localGetters: any, rootState: any, rootGetters: any): PaymentMethod[] {
    return state.paymentMethods;
  },
  isBillingLoaded(state: BillingState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded && rootGetters.isLoaded;
  },
  isBillingCustomer(state: BillingState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return !!state.customer?.ID;
  },
  billingGuessedCountry(state: BillingState, localGetters: any, rootState: any, rootGetters: any): Country | null {
    return state.country ?? null;
  },
  billingGuessedCurrency(state: BillingState, localGetters: any, rootState: any, rootGetters: any): string {
    const { account } = rootState.settings;
    const customer = state.customer ?? null;
    const country = state.country ?? null;
    const currencies = localGetters.billingCurrencies as string[];

    const currs = [
      customer?.currency,
      preferedCountryCurrency(country?.countryCode),
      preferedCountryCurrency(customer?.country),
      preferedCountryCurrency(account.countryCode),
      Currency.EUR as string,
    ];

    for (let ci = 0; ci < currs.length; ci += 1) {
      const curr = currs[ci];
      if (curr && currencies.includes(curr)) return curr;
    }

    return Currency.EUR as string;
  },
  billingSMSProduct(state: BillingState, localGetters: any, rootState: any, rootGetters: any): SMSProduct | null {
    return state.smsProduct;
  },
  billingAllInvoicesLoaded(state: BillingState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return !state.invoicesLimit || state.invoices.length < state.invoicesLimit;
  },
};

const BillingStore = {
  namespaced: false,
  state: new BillingState(),
  mutations,
  actions,
  getters,
};

export default BillingStore;
