import { IUpdate } from '@/api/api-update';

export enum UpdateOriginator {
  None = 'none', // initial state

  Auto = 'auto', // update api call, auto updates
  Push = 'push', // update api call, push updates
  Normal = 'up', // update api call

  Other = 'xxx', // updates from other api call
  Reservation = 'res', // updates from reservation change api call
  DateAndTime = 'dt',
  DayNote = 'dn',
  SettingsSingle = 'sse',
  SettingsList = 'sle',
  SettingsTabItems = 'sti',
  SettingsFloorplan = 'sfp'
}

export async function performUpdatesAction(
  dispatch: any,
  action: () => Promise<IUpdate>,
  p?: { originator?: string},
): Promise<IUpdate|undefined> {
  let updates: IUpdate | undefined;
  const originator = p?.originator ?? UpdateOriginator.Other;
  dispatch('startProcessUpdates', { originator });
  try {
    updates = await action();
    dispatch('processUpdates', { updates, originator });
  } catch (e) {
    dispatch('processUpdatesError', { originator });
    throw e;
  }
  return updates;
}
