import { defineComponent } from 'vue';
import i18n from '@/plugins/i18n';
import { useRouter } from 'vue-router/composables';
import { TranslateResult } from 'vue-i18n';

export function useSettingsBreadcrumbs(currentPageTitle: string | TranslateResult | undefined = undefined) {
  const router = useRouter();

  const breadcrumbs = [];
  const pieces = router.currentRoute.path.split('/');

  let resolved = '';
  pieces.forEach((part, i) => {
    if (i === pieces.length - 1) { return; }
    if (part === '') { return; }
    resolved = `${resolved}/${part}`;
    const r = router.resolve(resolved);

    breadcrumbs.push({
      text: i18n.t(r.route.meta?.titleKey) || '',
      exact: true,
      to: { name: r.route.name },
    });
  });

  breadcrumbs.push({
    text: currentPageTitle || i18n.t(router.currentRoute.meta?.titleKey) || '',
    disabled: true,
  });

  return breadcrumbs;
}

export default defineComponent({
  props: { },
  data() {
    return {
      breadcrumbs: [] as any[],
    };
  },
  created() {
    this.breadcrumbs = useSettingsBreadcrumbs();
  },
});
