
import Vue from 'vue';
import MainToolbar from '@/components/views/main/MainToolbar.vue';
import DateRangePickerField from '@/components/pickers/DateRangePickerField.vue';
import ReportsStore, { ReportsAllTabs, ReportsOnlineTabs } from '@/store/reports-store';
import Tab from '@/model/Tab';
import { performAction, performActionEx } from '@/services/vue-utils';
import { DateRange } from '@/services/date-range';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import { ReportsType } from '@/services/reports';

export default Vue.extend({
  name: 'ReportsToolbar',
  components: { MainToolbar, DateRangePickerField },
  props: {

  },
  data() {
    return {
      showByItems: [
        { text: this.$i18n.t('label.report_booking_date'), value: 'by_booking' },
        { text: this.$i18n.t('label.report_checkin_date'), value: 'by_checkin' }],
    };
  },
  computed: {
    tab(): Tab {
      return this.$tstore.state.reports.tab;
    },
    dateRange(): DateRange {
      return this.$tstore.state.reports.dateRange;
    },
    showBy(): string {
      const reportsbyDtCreate = this.$tstore.state.reports.byDtCreate as boolean;
      return reportsbyDtCreate ? 'by_booking' : 'by_checkin';
    },
    allowComparePrevious(): boolean {
      const reportsType = this.$tstore.state.reports.reportsType as ReportsType;
      return [ReportsType.Reservations, ReportsType.Feedback].includes(reportsType);
    },
    allowShowBy(): boolean {
      const reportsType = this.$tstore.state.reports.reportsType as ReportsType;
      return [ReportsType.Reservations].includes(reportsType);
    },
    tabs(): any[] {
      return [
        ReportsOnlineTabs, ReportsAllTabs,
        { divider: true },
        ...this.$tstore.state.settings.tabs,
      ];
    },
  },
  watch: { },
  created() {
    loadStoreModule(this, 'reports', ReportsStore, async () => {});
  },
  beforeDestroy() {
    unloadStoreModule(this, 'reports', async () => { this.$tstore.dispatch('resetReports'); });
  },
  methods: {
    fmtDate(d: Date): string | null {
      const thisYear = (new Date()).getFullYear() === d.getFullYear();
      return thisYear ? this.$localized.shortDayMonthText(d) : this.$localized.shortWeekdayYearText(d);
      // return thisYear ? this.$localized.monthText(d) : this.$localized.monthYearText(d);
    },
    tabSelected(tab: Tab) {
      this.setTab(tab);
    },
    dateRangeSelected(dateRange: DateRange) {
      this.setDateRange(dateRange);
    },
    showBySelected(showBy: string) {
      this.setCampaignsByDtCreate(showBy === 'by_booking');
    },
    async setTab(tab: Tab) {
      await performActionEx(
        async () => this.$tstore.dispatch('setReportsTab', tab),
        { errorPrefix: this.$i18n.tc('error.load_data'), hideActivityIndicator: true },
      );
    },
    async setDateRange(dateRange: DateRange) {
      await performActionEx(
        async () => this.$tstore.dispatch('setReportsDateRange', dateRange),
        { errorPrefix: this.$i18n.tc('error.load_data'), hideActivityIndicator: true },
      );
    },
    async setCampaignsByDtCreate(value: boolean) {
      await performActionEx(
        async () => this.$tstore.dispatch('setReportsByDtCreate', value),
        { errorPrefix: this.$i18n.tc('error.load_data'), hideActivityIndicator: true },
      );
    },
  },
});

