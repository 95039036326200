/* eslint-disable vue/max-len */
/* eslint-disable max-len */

import { ReportsParams, MsgReports, TabReports } from '@/grpc-pb/reports_service_pb';
import { dateByAddingMinutes } from './time-utils';

const KEEP_TIME = 15 * 60 * 1000; // keep records in cache for 15 mins

interface TabReportEntry {
  timestamp: Date,
  params: ReportsParams.AsObject,
  reports: Map<number, TabReports.AsObject>,
}

interface MsgReportEntry {
  timestamp: Date,
  params: ReportsParams.AsObject,
  reports: Map<string, MsgReports.AsObject>,
}

class Cache {
  tabReportsEntries: TabReportEntry[];

  MsgReportsEntries: MsgReportEntry[];

  resetTimeout: NodeJS.Timeout | null;

  constructor() {
    this.reset();
    this.tabReportsEntries = [];
    this.MsgReportsEntries = [];
    this.resetTimeout = null;
  }

  reset() {
    this.tabReportsEntries = [];
    this.MsgReportsEntries = [];

    if (this.resetTimeout) clearTimeout(this.resetTimeout);
    this.resetTimeout = null;
  }

  scheduleReset() {
    if (this.resetTimeout) clearTimeout(this.resetTimeout);
    this.resetTimeout = setTimeout(() => { this.reset(); }, KEEP_TIME);
  }

  removeOld() {
    const mints = dateByAddingMinutes(new Date(), -KEEP_TIME);
    this.tabReportsEntries = this.tabReportsEntries.filter((re) => re.timestamp > mints);
    this.MsgReportsEntries = this.MsgReportsEntries.filter((re) => re.timestamp > mints);
  }

  removeModified(dateIndices: number[]) {
    this.tabReportsEntries = this.tabReportsEntries.filter((re) => {
      const isaffected = dateIndices.some((di) => re.params.beginDate <= di && re.params.endDate >= di);
      return !isaffected;
    });
  }

  addTabReports(reports: Map<number, TabReports.AsObject>, params: ReportsParams.AsObject) {
    const entry: TabReportEntry = {
      timestamp: new Date(),
      params,
      reports,
    };
    this.tabReportsEntries.push(entry);

    this.removeOld();
    this.scheduleReset();
  }

  addMsgReports(reports: Map<string, MsgReports.AsObject>, params: ReportsParams.AsObject) {
    const entry: MsgReportEntry = {
      timestamp: new Date(),
      params,
      reports,
    };
    this.MsgReportsEntries.push(entry);
  }

  findTabReports(begin: number, end: number, binType: ReportsParams.ReportsBinType, forceExact = false) {
    const exact = forceExact || [ReportsParams.ReportsBinType.OVER_DAY, ReportsParams.ReportsBinType.OVER_YEAR].includes(binType);
    const entry = exact
      ? this.tabReportsEntries.find((r) => r.params.binType === binType && r.params.beginDate === begin && r.params.endDate === end)
      : this.tabReportsEntries.find((r) => r.params.binType === binType && r.params.beginDate >= begin && r.params.endDate <= end);

    return entry;
  }

  findMsgReports(begin: number, end: number, binType: ReportsParams.ReportsBinType, forceExact = false) {
    const exact = forceExact || [ReportsParams.ReportsBinType.OVER_DAY, ReportsParams.ReportsBinType.OVER_YEAR].includes(binType);
    const entry = exact
      ? this.MsgReportsEntries.find((r) => r.params.binType === binType && r.params.beginDate === begin && r.params.endDate === end)
      : this.MsgReportsEntries.find((r) => r.params.binType === binType && r.params.beginDate >= begin && r.params.endDate <= end);

    return entry;
  }
}

const ReportsCache = new Cache();

export default ReportsCache;
