const firebaseAppCfg = {
  apiKey: 'AIzaSyBp2ANRnwSw1CbMeDfRPAO55KU6GV5_VWI',
  authDomain: 'guestplan-ba361.firebaseapp.com',
  projectId: 'guestplan-ba361',
  storageBucket: 'guestplan-ba361.appspot.com',
  messagingSenderId: '625683205123',
  appId: '1:625683205123:web:60ddfc80d7c6a838ee9236',
};

const fcmVapidKey = 'BPOhXN63pZIduWifWu44N67G4RtFSmqOfnAMjBzSis8hz-3qS1cSG3QXYZZ_m-5l9LrdxAott3fUINt2AV_6yUw';
export { firebaseAppCfg, fcmVapidKey };
