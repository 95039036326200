import { IService } from '@/api/api-update';
import {
  dateFromDateString, dateFromDateString8601, dateStringFromDate, dateStringFromDate8601,
} from '@/services/time-utils';

export interface IDTOService extends IService { }

export default class Service {
  id: number;

  title: string;

  order: number;

  color: string;

  constructor(o?: IService) {
    this.id = o?.serviceId ?? 0;
    this.title = o?.title ?? '';
    this.order = o?.serviceOrder ?? 0;
    this.color = o?.color?.toUpperCase() ?? '#FF3D94';
  }

  toDto(): IDTOService {
    const dto: IDTOService = {
      serviceId: this.id,
      title: this.title,
      serviceOrder: this.order,
      color: this.color,
    };
    return dto;
  }

  get value() { return this.id; }

  get text() { return this.title; }

  set text(text: string | null) { this.title = text ?? ''; }
}
