
import Vue from 'vue';
import TablesDialog from '@/components/views/settings/TablesDialog.vue';
import FloorplanEditDialog from '@/components/views/settings/FloorplanEditDialog.vue';
import Tab from '@/model/Tab';
import { performSaveAction } from '@/services/vue-utils';
import { cloneModel, isModelEqualToModel } from '@/model/model-utils';

export default Vue.extend({
  name: 'WelcomeStepTables',
  components: { TablesDialog, FloorplanEditDialog },
  props: { },
  data() {
    return {
      tabItemsDialog: false,
      floorplanDialog: false,
      tab: null as Tab | null,
      tabs: [] as Tab[],
      oldTabs: [] as Tab[],
      unwatch: Function as (() => void),

    };
  },
  computed: {
    isChanged(): boolean {
      return !isModelEqualToModel(this.tabs, this.oldTabs);
    },
  },
  watch: { },
  mounted() {
    this.reset();
  },
  created() {
    this.unwatch = this.$tstore.watch((state, getters) => getters.isLoaded, (newValue, oldValue) => { this.reset(); });
  },
  beforeDestroy() {
    this.unwatch();
  },

  methods: {
    setupTables(tab: Tab) {
      this.tab = tab;
      this.tabItemsDialog = true;
    },
    setupFloorplan(tab: Tab) {
      this.tab = tab;
      this.floorplanDialog = true;
    },
    closeDialogs() {
      this.tabItemsDialog = false;
      this.floorplanDialog = false;
    },
    // eslint-disable-next-line vue/no-unused-properties
    async submit() {
      if (!this.isChanged) {
        this.$emit('advance');
        return;
      }

      const entities = this.tabs;
      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendTabs', { entities }),
      );
      if (!ok) return;

      this.reset();
      this.$emit('advance');
    },
    reset() {
      const { tabs } = this.$tstore.state.settings;
      this.tabs = tabs.map((o) => cloneModel(o));
      this.oldTabs = tabs;
    },
  },
});
