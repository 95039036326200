import i18n from '@/plugins/i18n';
import { ReservationStatus } from '@/model/Reservation';

export const AllStatuses = [
  ReservationStatus.Planned,
  ReservationStatus.Seated,
  ReservationStatus.CheckedIn,
  ReservationStatus.CheckedOut,
  ReservationStatus.Confirmed,
  ReservationStatus.NonConfirmed,
  ReservationStatus.WaitingList,
  ReservationStatus.Cancelled,
  ReservationStatus.NoShow,
  ReservationStatus.Spam,
  ReservationStatus.Deleted,
];

export const InvalidStatuses = [
  ReservationStatus.WaitingList,
  ReservationStatus.Cancelled,
  ReservationStatus.NoShow,
  ReservationStatus.Spam,
  ReservationStatus.Deleted,
];

export const InvalidStatusesForToday = [...InvalidStatuses, ReservationStatus.CheckedOut];

export const EmployeeNotNeededStatuses = [
  ReservationStatus.CheckedIn, ReservationStatus.Seated, ReservationStatus.CheckedOut];

export const WaitingListReservationStatuses = [ReservationStatus.WaitingList];

function statusToListItem(status: ReservationStatus) {
  if (status === ReservationStatus.Separator) return { divider: true };
  return { text: i18n.t(`code.status.${status}`), value: status };
}

export function reservationStatusAllItems() {
  return [
    ReservationStatus.Valid, ReservationStatus.All, ReservationStatus.Separator,
    ReservationStatus.Planned, ReservationStatus.Seated, ReservationStatus.Separator,
    ReservationStatus.CheckedIn, ReservationStatus.CheckedOut, ReservationStatus.Separator,
    ReservationStatus.Confirmed, ReservationStatus.NonConfirmed, ReservationStatus.Separator,
    ReservationStatus.WaitingList, ReservationStatus.Separator,
    ReservationStatus.Cancelled, ReservationStatus.NoShow, ReservationStatus.Spam, ReservationStatus.Deleted,
  ].map((o) => statusToListItem(o));
}

export function reservationStatusItems() {
  return [
    ReservationStatus.Planned, ReservationStatus.Seated, ReservationStatus.Separator,
    ReservationStatus.CheckedIn, ReservationStatus.CheckedOut, ReservationStatus.Separator,
    ReservationStatus.Confirmed, ReservationStatus.NonConfirmed, ReservationStatus.Separator,
    ReservationStatus.WaitingList, ReservationStatus.Separator,
    ReservationStatus.Cancelled, ReservationStatus.NoShow, ReservationStatus.Spam, ReservationStatus.Deleted,
  ].map((o) => statusToListItem(o));
}

export function nextStatusesForStatus(status: ReservationStatus): ReservationStatus[] {
  switch (status) {
    case ReservationStatus.Planned:
      return [ReservationStatus.Seated, ReservationStatus.CheckedIn, ReservationStatus.CheckedOut];
    case ReservationStatus.Seated:
      return [ReservationStatus.CheckedOut];

    case ReservationStatus.CheckedIn:
      return [ReservationStatus.Seated, ReservationStatus.CheckedOut];
    case ReservationStatus.CheckedOut:
      return [];

    case ReservationStatus.Confirmed:
      return [ReservationStatus.Seated, ReservationStatus.CheckedIn, ReservationStatus.CheckedOut];
    case ReservationStatus.NonConfirmed:
      return [ReservationStatus.Confirmed];

    case ReservationStatus.WaitingList:
      return [ReservationStatus.Planned, ReservationStatus.Seated, ReservationStatus.CheckedIn];

      // case ReservationStatus.Cancelled: return [];
      // case ReservationStatus.Deleted: return [];
      // case ReservationStatus.NoShow: return [];
      // case ReservationStatus.Spam: return [];

    default: return [];
  }
}

export function nextStatusItems(status: ReservationStatus) {
  return nextStatusesForStatus(status).map((o) => statusToListItem(o));
}

export function nextStatusItemsBlock(status: ReservationStatus) {
  return [statusToListItem(ReservationStatus.Cancelled)];
}
