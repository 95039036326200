/* eslint-disable class-methods-use-this */
export interface IActivityIndicatorPlugin {

  show(): void;
  hide(): void;

}

class ActivityIndicatorPlugin {
  show: () => void = () => {};

  hide: () => void = () => {};
}

export default new ActivityIndicatorPlugin();
