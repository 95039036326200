
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ColorPickerField',
  props: {
    value: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    showSwatches: { type: Boolean, required: false, default: false },
    swatches: { type: Array, required: false, default: () => [] as Array<String> },
  },
  data() {
    return {
      color: this.value,
      menu: false,
    };
  },
  watch: {
    value() {
      this.color = this.value;
    },
  },
  methods: {
    updateValue() {
      if (this.showSwatches) {
        this.menu = false;
      }
      this.$emit('input', this.color);
    },
  },
});
