
import Vue, { PropType } from 'vue';
import HourSlotsProfileDialog from '@/components/views/settings/HourSlotsProfileDialog.vue';
import HourSlot from '@/model/HourSlot';
import HourSlotProfile, { colorsForProfiles } from '@/model/HourSlotProfile';
import { niceTimeTextFromSlot } from '@/services/time-utils';
import { contrastForColor } from '@/services/color-utils';

export const HourSlotMenuItemType = {
  CLOSED: 0, OPEN: 1, CUSTOM: 2, CUSTOM_NEW: 3, RESET: 4,
};

export default Vue.extend({
  components: { HourSlotsProfileDialog },
  props: {
    hourSlots: { type: Array as PropType<(HourSlot | null)[]>, required: true },
    profiles: { type: Array as PropType<HourSlotProfile[]>, required: true },
    enableReset: { type: Boolean as PropType<boolean>, required: false, default: false },
  },
  data() {
    return {
      gridWidth: 0,
      activeSlotIndex: 0,
      newProfileDialog: false,
      hourSlot: new HourSlot(),
      colors: [
        '#9e9e9e',
        '#3355ff',
        '#E42DD0',
        '#FF3D94',
        '#FF8262',
        '#FFC24E',
        '#F9F871',
        '#88883E',
        '#00A26F',
        '#E90021',
        '#61FBD5',
        '#9D5B00',
        '#312D4A',
        '#1A5A5F',
        '#1F9199',
        '#A92DC8',
        '#6A000F',
        '#D1C7FF',
        '#0011A3',
        '#F37385',
        '#1C1A27',
        '#938BB7',
      ],
    };
  },
  computed: {
    itemsPerRow(): number {
      return this.gridWidth > 750 ? 8 : 4;
    },
    colorsByProfileId(): Map<number, string> {
      return colorsForProfiles(this.profiles, this.colors.slice(2));
    },
  },
  watch: { },
  methods: {
    titleForClosedSlot(slotNr: number): string {
      return niceTimeTextFromSlot(slotNr + 24);
    },
    colorForProfileId(profileId: number): string | undefined {
      return this.colorsByProfileId.get(profileId);
    },
    colorForSlot(slot: HourSlot | null) {
      if (!slot || !slot.isOpen) return 'grey';
      if (slot.profileId) return this.colorForProfileId(slot.profileId);
      return 'blue';
    },
    slotButtonMouseDown(slotNr: number) {
      const btn = (this.$refs[`slot_btn_${slotNr}`] as any)[0] as Vue;
      (this.$refs.menu as any).activator = btn.$el;
    },
    onResize() {
      this.gridWidth = (this.$refs.gridRow as HTMLElement)?.clientWidth || 0;
    },
    openMenu(slotIndex: number) {
      this.activeSlotIndex = slotIndex;
      this.$emit('timeslot-selected', slotIndex);
    },
    openMenuItemSelected() {
      this.$emit('profile-selected', this.activeSlotIndex, HourSlotMenuItemType.OPEN);
    },
    closedMenuItemSelected() {
      this.$emit('profile-selected', this.activeSlotIndex, HourSlotMenuItemType.CLOSED);
    },
    customMenuItemSelected() {
      this.newProfileDialog = true;
    },
    resetMenuItemSelected(type: number) {
      this.$emit('profile-selected', this.activeSlotIndex, HourSlotMenuItemType.RESET);
    },
    customProfileSelected(hourSlot: HourSlot) {
      this.$emit('profile-selected', this.activeSlotIndex, HourSlotMenuItemType.CUSTOM_NEW, hourSlot);
      this.hourSlot.clearParams();
    },
    menuProfileSelected(profileId: number) {
      this.$emit('profile-selected', this.activeSlotIndex, HourSlotMenuItemType.CUSTOM, profileId);
    },
    textColorForSlot(color: string): string {
      return contrastForColor(color);
    },
  },
});
