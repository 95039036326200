
import Vue, { PropType } from 'vue';
import Tab from '@/model/Tab';
import Reservation from '@/model/Reservation';
import TabItem from '@/model/TabItem';
import { mapActions } from 'vuex';
import DragDrop from '@/components/views/tables/mixins/drag-drop';
import DragNewReservation from '@/components/views/tables/mixins/drag-new-reservation';
import DayPartMixin from '@/components/views/tables/mixins/day-part';
import { tryCloseEditReservation, tryEditReservation } from '@/services/reservation-edit-utils';
import TimeSlot from '@/model/TimeSlot';
import { isOngoingReservation } from '@/services/reservation-utils';
import StatusContextMenu from '@/components/reservation/StatusContextMenu.vue';
import TimeTableEvent from '@/components/views/tables/TimeTableEvent.vue';
import TimeTableClosedSlot from '@/components/views/tables/TimeTableClosedSlot.vue';
import TimeTableIndicator from '@/components/views/tables/TimeTableIndicator.vue';
import TimeTableTooltip from '@/components/views/tables/TimeTableTooltip.vue';

const baseSlotWidth = 60;

export default Vue.extend({
  name: 'TimeTableGrid',
  components: {
    TimeTableEvent, TimeTableClosedSlot, TimeTableIndicator, StatusContextMenu, TimeTableTooltip,
  },
  mixins: [DayPartMixin, DragDrop, DragNewReservation],
  props: {
    zoom: { type: Number, required: false, default: 1 },
    tabs: { type: Array as PropType<Array<Tab>>, required: true },
    reservations: { type: Array as PropType<Array<Reservation>>, required: true },
    timeSlots: { type: Array as PropType<Array<TimeSlot>>, required: true },
    timelineStart: { type: Date, required: true },
    timelineEnd: { type: Date, required: true },
    closedSlots: { type: Array as PropType<Array<{id: number, begin: Date, end: Date}>>, required: true },
  },
  data() {
    return {
      headerDragX: 0,
      headerDragScrollOrigin: undefined as number | undefined,
      contextMenu: {
        reservation: null as null | Reservation,
        visible: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    rowHeight(): number {
      return this.zoom < 1 ? 28 : 36;
    },
    slotWidth(): number {
      const width = Math.floor(this.zoom * baseSlotWidth);
      return width % 2 === 0 ? width : width + 1;
    },
    asideWidth(): number {
      return this.zoom < 1 ? 60 : 70;
    },
    reservationsInt(): Reservation[] {
      return this.$data.temporaryReservation !== null
        ? [...this.reservations, this.$data.temporaryReservation] : [...this.reservations];
    },
    reservationsByTabItem(): Map<number, Reservation[]> {
      const map = new Map<number, Reservation[]>();

      this.reservationsInt.forEach((r) => {
        for (let i = 0; i < r.tabItems.length; i += 1) {
          const ti = r.tabItems[i];
          if (i > 0 && r.tab !== null) {
            const tidx = r.tab.tabItems.indexOf(ti);
            if (tidx < 0 || r.tabItems[i - 1] !== r.tab.tabItems[tidx - 1]) {
              map.set(ti.id, (map.get(ti.id) ?? []).concat(r));
            }
          } else {
            map.set(ti.id, (map.get(ti.id) ?? []).concat(r));
          }
        }
      });

      return map;
    },
    overbooked(): Map<number, boolean> {
      const dayOverbookedFlagsMap = this.$tstore.getters.dayOverbookedFlagsForReservationId as Map<number, boolean>;
      return dayOverbookedFlagsMap;
    },
  },
  watch: {
    zoom(current: number, prev: number) {
      // console.log(`zoomOut: ${current < prev}, slotWidth: ${this.slotWidth}, c: ${current}, p: ${prev}`);
      const scrollValue = document.getElementById('tt-scrollable-container')!.scrollLeft;
      const prevSlot = scrollValue / (prev * baseSlotWidth);
      document.getElementById('tt-scrollable-container')!.scrollLeft = prevSlot * this.slotWidth;
    },
  },
  methods: {
    ...mapActions(['editReservation', 'sendReservation', 'setReservationFromContextStatusChange']),
    selected(rid: number): boolean {
      return rid === 0 || this.$tstore.getters.editReservation?.id === rid;
    },
    async eventClicked(r: Reservation, evt: any) {
      if (r.id !== 0 && this.$tstore.getters.editReservation?.id === r.id) {
        await tryCloseEditReservation();
        return;
      }

      const isOngoing = isOngoingReservation(r);
      if (isOngoing) {
        this.showContextMenu(r, evt);
      } else {
        await tryEditReservation(this.$router, r);
      }
    },
    showContextMenu(r: Reservation, evt: MouseEvent) {
      this.contextMenu.x = evt.clientX;
      this.contextMenu.y = evt.clientY;
      this.contextMenu.reservation = r;
      this.contextMenu.visible = true;
    },
    rowSpanForEvent(r: Reservation, tabItem: TabItem): number {
      if (!r.tab) return 1;

      const startIndex = r.tabItems.findIndex((ti) => ti.id === tabItem.id);
      const tStartIndex = r.tab.tabItems.findIndex((ti) => ti.id === tabItem.id);

      let rowSpan = 1;
      for (let i = startIndex + 1; i < r.tabItems.length; i += 1) {
        if (r.tabItems[i] !== r.tab.tabItems[tStartIndex + i - startIndex]) break;
        rowSpan += 1;
      }

      return rowSpan;
    },
    headerMouseup(evt: MouseEvent) {
      this.headerDragScrollOrigin = undefined;
    },
    headerMousedown(evt: MouseEvent) {
      const scrollElm = (this.$refs.scrollableContainer as HTMLElement);
      this.headerDragX = evt.screenX;
      this.headerDragScrollOrigin = scrollElm.scrollLeft;
    },
    headerMouseover(evt: MouseEvent) {
      if (evt.buttons === 0 || this.headerDragScrollOrigin === undefined) { return; }
      const scrollElm = (this.$refs.scrollableContainer as HTMLElement);
      scrollElm.scrollLeft = this.headerDragScrollOrigin + (this.headerDragX - evt.screenX);
    },
  },
});
