import TabItem, { TAB_ITEM_DUMMY_ID } from '@/model/TabItem';
import Tab from '@/model/Tab';

export function x() {}

export function suggestedDropTabItems(partySize: number, tab: Tab, tabItemId: number): TabItem[] {
  const tabItems = [] as TabItem[];

  let seats = 0;
  let ti = tab.tabItems.findIndex((o) => o.id === tabItemId);

  if (ti === -1 || tab.tabItems[ti].id <= TAB_ITEM_DUMMY_ID) return [];

  while (seats < partySize) {
    const tabItem = tab.tabItems[ti];
    if (!tabItem) break;
    tabItems.push(tabItem);
    seats += tabItem.seats;
    ti += 1;
  }

  if (seats < partySize) {
    return [];
  }

  return tabItems;
}
