/* eslint-disable no-param-reassign */
/* eslint-disable vue/max-len */
/* eslint-disable max-len */

import store from '@/store/store';
import { FeedbackReports } from '@/grpc-pb/reports_service_pb';
import localized from '@/plugins/vue-localized-formatter/src/localized';
import i18n from '@/plugins/i18n';
import { CategoryType } from './reports-utils';

export interface RatingChartData {
  category?: string[],
  series?: {name: string, data: number[]}[],
  avgSeries?: {name: string, data: (number|null)[]}[],
}

function categoryData(categoryType: CategoryType, category: Date[]): string[] {
  switch (categoryType) {
    case 'hour': return category.map((d) => localized.hourMinText(d)!);
    case 'month': return category.map((d) => localized.monthText(d)!);
    default: return category.map((d) => localized.shortDayMonthText(d)!);
  }
}

export function ratingChartData(type: 'overall'|'food'|'service'|'ambience'): RatingChartData {
  const feedbacks = store.getters.reportsTabReports?.feedbacks as FeedbackReports.AsObject;
  const pfeedbacks = store.getters.reportsPreviousTabReports?.feedbacks as FeedbackReports.AsObject;

  if (!feedbacks) return {};

  const { categoryType, category: categoryDates } = store.getters.tabReportsCategory as { categoryType: CategoryType, category: Date[] };
  const category = categoryData(categoryType, categoryDates);

  // compare averages
  if (pfeedbacks) {
    const data = feedbacks.binsList.map((bin) => ((bin[type]?.starsList ?? []).reduce((wsum, cnt, rti) => wsum + (rti + 1) * cnt, 0) / (bin[type]?.starsList ?? []).reduce((sum, cnt) => sum + cnt, 0)) || null);
    const pdata = pfeedbacks.binsList.map((bin) => ((bin[type]?.starsList ?? []).reduce((wsum, cnt, rti) => wsum + (rti + 1) * cnt, 0) / (bin[type]?.starsList ?? []).reduce((sum, cnt) => sum + cnt, 0)) || null);
    const avgSeries = [
      { name: i18n.tc('label.report_this_period'), data },
      { name: i18n.tc('label.report_previous_period'), data: pdata },
    ];

    return { category, series: [], avgSeries };
  }

  // bar star ratings + average rating
  const dataset = [5, 4, 3, 2, 1].map((i) => feedbacks.binsList.map((bin) => bin[type]?.starsList[i - 1] ?? 0));
  // const totals = feedbacks.binsList.reduce((sums, bin) => sums.map((s, i) => s + (bin[type]?.starsList[i] ?? 0)), [0, 0, 0, 0, 0]);
  const avgDataset = feedbacks.binsList.map((bin) => ((bin[type]?.starsList ?? []).reduce((wsum, cnt, rti) => wsum + (rti + 1) * cnt, 0) / (bin[type]?.starsList ?? []).reduce((sum, cnt) => sum + cnt, 0)) || null);

  const series = dataset.map((ds, i) => ({ name: '★'.repeat(5 - i), data: ds }));
  const avgSeries = [{ name: i18n.tc('label.report_average_rating'), data: avgDataset }];

  return { category, series, avgSeries };
}
