
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'TimePickerField',
  props: {
    value: { type: String, required: true },
    allowedMinutes: { type: Array, required: false, default: () => [0, 15, 30, 45] as Array<Number> },
    disabled: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    dense: { type: Boolean, required: false, default: false },
    hideDetails: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: false },
    appendOuterIcon: { type: String, required: false, default: null },
    errorMessages: { type: Array, required: false, default: null },
  },
  data() {
    return {
      time: this.value,
      menu: false,
      typedTime: '',
    };
  },
  computed: {
    displayTime(): string | null {
      if (!this.value) return null;
      return this.$localized.hourMinTextFrom24hr(this.value);
    },
    enteringHours(): boolean {
      return this.typedTime.length <= 2 && this.typedTime.indexOf(':') < 0;
    },
  },
  watch: {
    value() {
      this.time = this.value;
    },
    menu() {
      this.typedTime = '';
    },
    typedTime() {
      if (this.typedTime === '') { return; }

      if (this.enteringHours) {
        const minutes = this.time.split(':')[1];
        this.time = `${this.typedTime}:${minutes}`;
      } else {
        const hours = this.typedTime.indexOf(':') < 0 ? this.typedTime.substring(0, 2) : this.typedTime.split(':')[0];
        const typedMin = this.typedTime.indexOf(':') < 0 ? this.typedTime.substring(2) : this.typedTime.split(':')[1];
        const minutes = typedMin.length > 0 ? typedMin : this.time.split(':')[1];
        console.log(`${hours} - ${minutes}`);
        this.time = `${hours}:${minutes}`;
      }

      if ((this.typedTime.length === 4 && this.typedTime.indexOf(':') < 0) || this.typedTime.length === 5) {
        this.typedTime = '';
      }
    },
  },
  methods: {
    normalizeMinutesToAllowed() {
      if (this.allowedMinutes.length === 4) {
        const [hr, min] = this.time.split(':');
        this.time = `${hr}:${String(this.round15(parseInt(min, 10))).padStart(2, '0')}`;
      }
    },
    round15(x: number) {
      return (Math.round(x / 15) * 15) % 60;
    },
    clear() {
      this.time = '';
      this.typedTime = '';
      this.updateValue();
    },
    updateValue() {
      if (this.$refs.picker) {
        (this.$refs.picker as Vue & {selectingHour: boolean}).selectingHour = true;
      }
      this.normalizeMinutesToAllowed();
      this.$emit('input', this.time);
    },
    keydown(evt: KeyboardEvent) {
      if (evt.key === 'Enter') {
        this.normalizeMinutesToAllowed();
        (this.$refs.menu as any).save(this.time);
      } else if (evt.key === 'p' && (this.$refs.picker as any).period === 'am') {
        (this.$refs.picker as any).setPeriod('pm');
      } else if (evt.key === 'a' && (this.$refs.picker as any).period === 'pm') {
        (this.$refs.picker as any).setPeriod('am');
      } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ':'].includes(evt.key)) {
        if (evt.key === ':' && this.typedTime.length === 0) {
          return;
        }
        this.typedTime += evt.key;
      }
    },
  },
});
