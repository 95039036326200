<template>
  <svg
    class="icon-guestplan"
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M112 98H117.431C124.183 87.9793 128 75.9253 128 63C128 50.0747 124.037 38.0207 117.431 28H112V98Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 16H98V10.5688C88.2656 3.81651 76.556 0 64 0C51.444 0 39.7344 3.9633 30 10.5688V16Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M98 112H30V117.431C39.7344 124.183 51.444 128 64 128C76.556 128 88.2656 124.037 98 117.431V112Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 98V28H10.5688C3.81651 38.0207 0 50.0747 0 63C0 75.9253 3.9633 87.9793 10.5688 98H16Z"
    />
    <rect
      x="30"
      y="28"
      width="68"
      height="70"
    />
  </svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'IconGuestplan',
});
</script>

<style lang="scss">
.icon-guestplan {
  fill: currentColor
}
</style>
