
import Vue from 'vue';
import GuestResultsList from '@/components/views/guests/GuestResultsList.vue';
import GuestListPlaceholder from '@/components/views/guests/GuestListPlaceholder.vue';
import Reservation from '@/model/Reservation';

export default Vue.extend({
  name: 'UnreadReservations',
  components: {
    GuestResultsList, GuestListPlaceholder,
  },
  data() {
    return {};
  },
  computed: {
    unreadReservations(): Map<Date, Reservation[]> {
      return this.$tstore.getters.unreadReservationsByDate;
    },
    unreadReservationsCount(): number {
      return this.$tstore.getters.unreadReservationsCount;
    },
  },
});
