
import Vue, { PropType } from 'vue';
import Reservation, { ReservationStatus, ReservationType } from '@/model/Reservation';
import { reservationStatusItems } from '@/util/status';
import { endDateForCheckedOut } from '@/services/reservation-utils';
import { colorForStatus } from '@/components/helpers/colors';

export default Vue.extend({
  name: 'StatusSelectionMenu',
  components: { },
  props: {
    value: { type: String, required: true },
    reservation: { type: Reservation, required: true },
  },
  data() {
    return {
      selected: this.value,
      statuses: reservationStatusItems(),
    };
  },
  computed: {
    endDateTextForCheckedOut(): string | null {
      const endDate = endDateForCheckedOut(this.reservation);
      const endDateText = this.$localized.hourMinText(endDate);
      if (!endDateText) return null;
      const text = this.$i18n.t('label.ending_time_will_be_x', [endDateText]).toString();
      return text;
    },
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
  methods: {
    itemClicked(value: string) {
      this.$emit('item-clicked', value);
    },
    isCheckedOutStatus(s: ReservationStatus): boolean {
      return s === ReservationStatus.CheckedOut;
    },
    colorForStatus(s: ReservationStatus): string {
      return colorForStatus(s);
    },
  },
});
