
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import { ValidationObserver } from 'vee-validate';

export default Vue.extend({
  mixins: [DialogClosable],
  props: {
    title: { type: String as PropType<string>, required: true },
    message: { type: String as PropType<string>, required: true },
    labelTitle: { type: String as PropType<string>, required: true },
    confirmTitle: { type: String as PropType<string>, required: true },
    cancelTitle: { type: String as PropType<string>, required: true },
    rules: { type: String as PropType<string>, required: true },
    value: { type: String as PropType<string>, required: true },
    maxWidth: { type: Number, required: false, default: 290 },
    textfieldPrefix: { type: String, required: false, default: undefined },
    textfieldSuffix: { type: String, required: false, default: undefined },
    noAutoClose: { type: Boolean as PropType<boolean>, required: false, default: false },
    debounceValidation: { type: Number, required: false, default: undefined },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
    // hack to ensure input field is focused & keyboard visible even on iOS
    visible(val) {
      if (!val) {
        return;
      }
      setTimeout(() => {
        const tfElm = ((this.$refs.inputField as Vue).$refs.input as HTMLInputElement);
        tfElm.focus();
      }, 250);
    },
  },
  created() {
    if (this.visible) {
      setTimeout(() => {
        const tfElm = ((this.$refs.inputField as Vue).$refs.input as HTMLInputElement);
        tfElm.focus();
      }, 250);
    }
  },
  methods: {
    updateValue(value: string) {
      this.$emit('input', value);
    },
    reset() {
      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
      if (this.value === undefined) (this.$refs.form as Vue & { reset: () => void }).reset();
    },
    close() {
      this.reset();
      this.$emit('close', true);
    },
    async onClickOk() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) return;
      this.$emit('confirm', this.inputValue);
      if (this.noAutoClose !== true) this.close();
    },
    async onClickCancel() {
      this.$emit('cancel');
      if (this.noAutoClose !== true) this.close();
    },
  },
});
