/* eslint-disable no-param-reassign */
import Vue from 'vue';
import DateSlot from './DateSlot';

interface IWithDateTimeIndex { id: number; dateIndex: number; dateTimeIndex: number; isDeleted: boolean }

function compare<T extends IWithDateTimeIndex>(o1: T, o2: T) {
  const diff = o1.dateTimeIndex - o2.dateTimeIndex;
  if (diff !== 0) return diff;
  return o1.id - o2.id;
}

function toSortedEntitiesByDateIndex<T extends IWithDateTimeIndex>(items: T[]): { [dateIndex: number]: T[] } {
  const map: { [dateIndex: number]: T[] } = {};
  items.forEach((o) => { map[o.dateIndex] = (map[o.dateIndex] ?? []).concat(o); });
  Object.values(map).forEach((os) => os.sort((o1, o2) => compare(o1, o2)));
  return map;
}

function mergeToMapById<T extends IWithDateTimeIndex>(entities: T[], map: { [id: number]: T }) {
  const newEntities: T[] = [];
  const deletedEntities: T[] = [];
  const modifiedEntities: T[] = [];

  entities.forEach((o) => {
    const old = map[o.id];
    if (!old) { // no old exist
      if (!o.isDeleted) { // new not marked as deleted -> add to map and new entities
        Vue.set(map, o.id, o);
        newEntities.push(o);
      } else { // new marked as deleted -> remove from map (but no old hence useless else branch)
        // Vue.delete(map, o.id);
      }
    } else if (old.dateTimeIndex !== o.dateTimeIndex) { // old exists but different times -> add old to deleted entities
      deletedEntities.push(old);
      if (!o.isDeleted) { // new not marked as deleted -> add new in map (replaces old) and in new entities
        Vue.set(map, o.id, o);
        newEntities.push(o);
      } else { // new marked as deleted -> remove old from map (new has different time and marked as deleted)
        Vue.delete(map, old.id);
      }
    } else if (old.dateTimeIndex === o.dateTimeIndex) { // old exists and has same time
      if (!o.isDeleted) { // not marked as deleted -> keep in map and add to modified entities
        Object.assign(old, o);
        modifiedEntities.push(o);
      } else { // old exists and has same time, marked as deleted -> remove from map and add to deleted entities
        Vue.delete(map, old.id);
        deletedEntities.push(old);
      }
    } else; // never happens
  });

  return { newEntities, deletedEntities, modifiedEntities };
}

function sortedIndex<T extends IWithDateTimeIndex>(entities: T[], entitity: T, startIndex: number): number {
  let low = startIndex;
  let high = entities.length;
  if (high === 0) return 0;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);
    if (entities[mid].dateTimeIndex > entitity.dateTimeIndex) high = mid;
    else if (entities[mid].dateTimeIndex === entitity.dateTimeIndex && entities[mid].id >= entitity.id) high = mid;
    else low = mid + 1;
  }

  return high;
}

function mergeSortedEntities<T extends IWithDateTimeIndex>(entities: T[], newEntities: T[]) {
  let index = -1;
  newEntities.forEach((o) => {
    index = sortedIndex(entities, o, index + 1);
    entities.splice(index, 0, o);
  });
}

function deleteSortedEntities<T extends IWithDateTimeIndex>(entities: T[], deletedEntities: T[]) {
  let index = 0;
  deletedEntities.forEach((o) => {
    index = sortedIndex(entities, o, index);
    entities.splice(index, 1);
  });
}

function addDaySlotEntities<T extends IWithDateTimeIndex>(
  entities: T[],
  dateSlotField: keyof DateSlot,
  mapById: { [id: number]: T },
  dateSlotsByDateIndex: { [dateIndex: number]: any },
  p?: {},
) {
  entities.forEach((o) => { Vue.set(mapById, o.id, o); });

  const indices = new Set<number>();

  const mapNewByDateIndex = toSortedEntitiesByDateIndex(entities);
  Object.entries(mapNewByDateIndex).forEach(([di, nos]) => {
    indices.add(Number(di));

    let ds = dateSlotsByDateIndex[Number(di)];
    if (!ds) {
      ds = new DateSlot(Number(di));
      Vue.set(dateSlotsByDateIndex, Number(di), ds);
    }
    Vue.set(ds, dateSlotField, nos);
  });

  return indices;
}

function mergeDaySlotEntities<T extends IWithDateTimeIndex>(
  entities: T[],
  dateSlotField: keyof DateSlot,
  mapById: { [id: number]: T },
  dateSlotsByDateIndex: { [dateIndex: number]: any },
  p? : { noDeleted? : boolean },
) {
  const { newEntities, deletedEntities, modifiedEntities } = mergeToMapById(entities, mapById);
  if (p?.noDeleted) console.assert(deletedEntities.length === 0, 'mergeEntities: entities.length !== 0');

  const indices = new Set<number>();

  modifiedEntities.forEach((o) => indices.add(o.dateIndex));

  const mapDeletedByDateIndex = toSortedEntitiesByDateIndex(deletedEntities);
  Object.entries(mapDeletedByDateIndex).forEach(([di, dos]) => {
    indices.add(Number(di));

    const ds = dateSlotsByDateIndex[Number(di)];
    const os = ds[dateSlotField];
    console.assert(os.length !== 0, 'mapDeletedByDateIndex: entities.length === 0');
    if (os) deleteSortedEntities(os, dos);
  });

  const mapNewByDateIndex = toSortedEntitiesByDateIndex(newEntities);
  Object.entries(mapNewByDateIndex).forEach(([di, nos]) => {
    indices.add(Number(di));

    let ds = dateSlotsByDateIndex[Number(di)];
    if (!ds) {
      ds = new DateSlot(Number(di));
      Vue.set(dateSlotsByDateIndex, Number(di), ds);
    }
    const os = ds[dateSlotField];
    if (os.length !== 0) { mergeSortedEntities(os, nos); } else { ds[dateSlotField] = nos; }
  });

  return indices;
}

// eslint-disable-next-line import/prefer-default-export
export function updateDaySlotEntities<T extends IWithDateTimeIndex>(
  entities: T[],
  dateSlotField: keyof DateSlot,
  mapById: { [id: number]: T },
  dateSlotsByDateIndex: { [dateIndex: number]: any },
  p: { isFullUpdate?: boolean, noDeleted?: boolean},
) {
  if (p.isFullUpdate) return addDaySlotEntities(entities, dateSlotField, mapById, dateSlotsByDateIndex, p);
  return mergeDaySlotEntities(entities, dateSlotField, mapById, dateSlotsByDateIndex, p);
}
