import { render, staticRenderFns } from "./SettingsGroup.vue?vue&type=template&id=4e5c0080&scoped=true&"
import script from "./SettingsGroup.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SettingsGroup.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SettingsGroup.vue?vue&type=style&index=0&id=4e5c0080&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5c0080",
  null
  
)

export default component.exports