import { GetterTree, MutationTree, ActionTree } from 'vuex';
import Reservation, { ReservationMapping } from '@/model/Reservation';
import httpClient from '@/api/http-client';
import Contact from '@/model/Contact';
import { searchForReservations, SearchType } from '@/services/search';
import { toSortedMapByDateIndex, mapByDateIndexToMapByDate } from '@/services/reservations-utils';
import { toMapById } from '@/model/model-utils';
import IRootState, { ISearchState } from './store-state';

export class SearchState implements ISearchState {
  type: SearchType = SearchType.None;

  query: string = '';

  requestCount: number = 0;

  localReservations: Reservation[] = [];

  apiReservations: Reservation[] = [];
}

const mutations = <MutationTree<ISearchState>>{
  RESET(state: ISearchState) {
    Object.assign(state, new SearchState());
  },
  RESET_SEARCH(state: ISearchState) {
    Object.assign(state, new SearchState());
  },
  SET_SEARCH_QUERY(state: ISearchState, p: { query: string, type: SearchType }) {
    state.query = p.query;
    state.type = p.type;
    if (p.query.length < 3) { state.localReservations = []; state.apiReservations = []; }
  },
  CHANGE_SEARCH_REQUEST_COUNT(state: ISearchState, increment: boolean) {
    state.requestCount = increment ? state.requestCount + 1 : state.requestCount - 1;
  },
  SET_SEARCH_RESULTS(
    state: ISearchState,
    p: { query: string, localReservations?: Reservation[], apiReservations?: Reservation[]},
  ) {
    if (p.localReservations) state.localReservations = p.localReservations;
    if (p.apiReservations) state.apiReservations = p.apiReservations;
  },
};

const actions = <ActionTree<ISearchState, IRootState>>{
  async search({
    state, commit, rootGetters, rootState, dispatch,
  }, p: { query: string, type: SearchType }) {
    if (state.query === p.query && state.type === p.type) return;

    console.log('SEARCH query:', p.query);
    commit('SET_SEARCH_QUERY', p);

    commit('CHANGE_SEARCH_REQUEST_COUNT', true);

    setTimeout(async () => {
      try {
        if (state.query !== p.query || p.query.length < 3 || state.type !== p.type) return;

        // get matching local reservations
        const { reservationsById } = rootState.reservations;
        const localReservations = searchForReservations(state.query, state.type, reservationsById);
        commit('SET_SEARCH_RESULTS', { localReservations });
        console.log('SEARCH LOCAL results:', localReservations);

        // get matching backend reservations
        console.log('SEARCH API start:', p.query);
        const update = await httpClient.search(p.query);
        const apiReservations = (update.reservations ?? []).map(
          (o) => new Reservation(o, rootGetters as ReservationMapping),
        );
        console.log('SEARCH API end:', p.query);

        if (state.query !== p.query || state.type !== p.type) return;

        commit('SET_SEARCH_RESULTS', { apiReservations });
        console.log('SEARCH API results:', apiReservations);
      } catch (e) {
        console.log('SEARCH API error:', e);
      } finally {
        commit('CHANGE_SEARCH_REQUEST_COUNT', false);
      }
    }, 500);
  },
  resetSearch({ state, commit, dispatch }) {
    console.log('reset search');
    commit('RESET_SEARCH');
  },
};

const getters = <GetterTree<ISearchState, IRootState>>{
  searchReservations(state, localGetters, rootState, rootGetters): Reservation[] {
    const apiById = toMapById(state.apiReservations);
    const reservations = [...state.apiReservations, ...state.localReservations.filter((r) => !apiById.get(r.id))];
    return reservations;
  },
  searchReservationsByDateIndex(state, localGetters, rootState, rootGetters) {
    const reservations = localGetters.searchReservations as Reservation[];
    return toSortedMapByDateIndex(reservations);
  },
  searchAllReservationsByDate(state, localGetters, rootState, rootGetters) {
    const mapByDateIndex = localGetters.searchReservationsByDateIndex as Map<number, Reservation[]>;
    return mapByDateIndexToMapByDate(mapByDateIndex);
  },
  searchDayReservations(state, localGetters, rootState, rootGetters) {
    const map = localGetters.searchReservationsByDateIndex as Map<number, Reservation[]>;
    const dateIndex = rootState.update.dateIndex as number;
    return map.get(dateIndex) ?? [];
  },
  searchDayReservationsByDate(state, localGetters, rootState, rootGetters) {
    const reservations = localGetters.searchDayReservations as Reservation[];
    const date = rootState.update.date as Date;

    return new Map<Date, Reservation[]>(reservations.length > 0 ? [[date, reservations]] : []);
  },
  searchContacts(state, localGetters, rootState, rootGetters): Contact[] {
    const searchReservations = localGetters.searchReservations as Reservation[];
    const importances = new Map<number, number>();
    const contacts: Contact[] = [];
    searchReservations.forEach((r) => {
      if (!r.contact) return;
      let count = importances.get(r.contact.id);
      if (!count) {
        contacts.push(r.contact);
        count = r.contact.reservationCount ?? 1;
        if (count < 1) count = 1;
        importances.set(r.contact.id, count);
      }
    });

    const sortedContacts = contacts.sort((o1, o2) => {
      const res = importances.get(o2.id)! - importances.get(o1.id)!;
      if (res !== 0) return res;
      return o1.id - o2.id;
    });

    console.log('search contacts: ', sortedContacts);
    return sortedContacts;
  },
  isSearching(state, localGetters, rootState, rootGetters): boolean {
    return state.requestCount !== 0 && state.query.length >= 3;
  },
};

export const SearchStore = {
  namespaced: true,
  state: new SearchState(),
  mutations,
  actions,
  getters,
};

export const NameStore = {
  namespaced: true,
  state: new SearchState(),
  mutations,
  actions,
  getters,
};

export const PhoneStore = {
  namespaced: true,
  state: new SearchState(),
  mutations,
  actions,
  getters,
};

export const EmailStore = {
  namespaced: true,
  state: new SearchState(),
  mutations,
  actions,
  getters,
};
