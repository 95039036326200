
import Vue, { PropType } from 'vue';
import { ValidationObserver } from 'vee-validate';
import { performAction } from '@/services/vue-utils';
import PasswordTextField from '@/components/forms/PasswordTextField.vue';
import { startAutoUpdates } from '@/services/autoupdater';
import { ApiError, ApiErrorCode } from '@/api/api-error';
import toast from '@/plugins/toast-plugin/toast';

export default Vue.extend({
  name: 'RegisterAccountStep',
  components: { PasswordTextField },
  props: { },
  data() {
    return {
      state: 'enter-details',
      fullName: '',
      businessName: '',
      email: '',
      password: '',
      phone: '',
      promoCode: this.$route.params.code,
      showPromoAlert: false,
      promoAlertMessage: '',
    };
  },
  computed: {
    showPromo(): boolean {
      return this.$route.name === 'promo';
    },
  },
  watch: {
    dialog(value, oldValue) {

    },
  },
  methods: {
    reset() {
      this.fullName = '';
      this.businessName = '';
      this.email = '';
      this.password = '';
      this.phone = '';
      this.state = 'enter-details';

      (this.$refs.form as HTMLFormElement).reset();
      (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
    },
    async close() {
      this.reset();
      this.$emit('close');
    },
    async submit() {
      const isValid = await (this.$refs.observer as InstanceType<typeof ValidationObserver>).validate();
      if (!isValid) {
        toast.error(this.$i18n.tc('error.validation_failed'));
        return;
      }

      const { locale } = this.$i18n;

      let promoOk = true;
      const success = this.$i18n.tc('message.registration_code_sent_to_x_please_enter_code', undefined, [this.email]);
      const ok = await performAction(
        '',
        this.$i18n.tc('error.registration_failed'),
        async () => {
          try {
            await this.$tstore.dispatch('openAccount', {
              username: this.email,
              password: this.password,
              firstname: this.fullName,
              companyName: this.businessName,
              phone: this.phone,
              locale,
              promoCode: this.promoCode || undefined,
            });
            await startAutoUpdates();
            toast.success(success);
          } catch (e) {
            const ae = e as ApiError;
            if ([ApiErrorCode.promo_code_not_valid, ApiErrorCode.promo_code_already_exists].includes(ae.code)) {
              promoOk = false;
              this.promoAlertMessage = this.$i18n.tc('message.promo_code_not_valid');
              if (ae.code === ApiErrorCode.promo_code_already_exists) {
                this.promoAlertMessage = this.$i18n.tc('message.promo_code_already_exists');
              }
              this.showPromoAlert = true;
            } else {
              throw e;
            }
          }
        },
      );
      if (ok && promoOk) {
        this.$emit('account-created');
      }
    },
  },
});
