import { render, staticRenderFns } from "./ReservationHistoryDialog.vue?vue&type=template&id=108b6c08&scoped=true&"
import script from "./ReservationHistoryDialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ReservationHistoryDialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ReservationHistoryDialog.vue?vue&type=style&index=0&id=108b6c08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108b6c08",
  null
  
)

export default component.exports