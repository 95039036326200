export function parseColor(color: string): number[] {
  const R = parseInt(color.substring(1, 3), 16);
  const G = parseInt(color.substring(3, 5), 16);
  const B = parseInt(color.substring(5, 7), 16);
  return [R, G, B];
}

export function darkenColor(color: string, val:number): string {
  const rgb = parseColor(color);

  const R = Math.min(Math.max(rgb[0] - val, 0), 255);
  const G = Math.min(Math.max(rgb[1] - val, 0), 255);
  const B = Math.min(Math.max(rgb[2] - val, 0), 255);

  const RR = R.toString(16).padStart(2, '0');
  const GG = G.toString(16).padStart(2, '0');
  const BB = B.toString(16).padStart(2, '0');
  return `#${RR}${GG}${BB}`;
}

export function contrastForColor(color: string): string {
  const rgb = parseColor(color);
  const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);
  return (brightness > 130) ? '#000000' : '#FFFFFF';
}
