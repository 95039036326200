<!-- eslint-disable vue/max-len -->
<!-- eslint-disable max-len -->
<template>
  <svg
    class="icon-walk-outline"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
  >
    <g>
      <path
        d="M77.256,20.274c-3.105,7.176-11.423,10.463-18.601,7.377C51.5,24.56,48.212,16.242,51.307,9.086
    c3.094-7.167,11.399-10.462,18.557-7.368C77.052,4.804,80.338,13.118,77.256,20.274L77.256,20.274z"
      />
      <path
        d="M59.569,32.603c-1.322,0-2.605,0.428-3.653,1.212L39.742,45.827c-1.233,0.917-2.078,2.271-2.373,3.79
    l-5.232,19.685c-1.548,8.03,10.513,10.343,12.053,2.3l4.783-17.333l4.255-3.165v19.614c0,1.606,0.561,3.165,1.548,4.426L73.21,98.68
    l3.604,21.885c1.149,9.974,16.254,7.502,14.169-2.331l-3.867-23.718c-0.204-1.19-0.691-2.321-1.435-3.268L71.664,73.403l0.08-18.288
    l4.815,6.886c0.491,0.685,1.11,1.265,1.834,1.702l13.185,8.062c6.98,4.275,13.383-6.198,6.4-10.462l-12.094-7.391L72.823,35.222
    c-1.149-1.638-3.023-2.615-5.026-2.615L59.569,32.603z"
      />
      <path
        d="M54.22,81.269l-3.045,11.39l-15.318,22.719c-6.055,8.062,6.708,16.661,11.91,8.021l16.013-23.696
    c0.903-1.343,1.539-2.394,1.539-4.257L54.22,81.269z"
      />
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'IconWalkOutline',
});
</script>

<style lang="scss">
.icon-walk-outline {
  fill: currentColor
}
</style>
