

import Vue, { PropType } from 'vue';
import { ReservationStatus } from '@/model/Reservation';

export default Vue.extend({
  name: 'GuestFilterCompactBar',
  components: { },
  props: { },
  data() {
    return { };
  },
  computed: {
    dark(): boolean {
      return this.$vuetify.theme.dark;
    },
    filterText(): Record<string, String>[] {
      const ret = [];

      if (this.$tstore.state.filter.from !== '06:00') {
        ret.push({
          title: `${this.$i18n.tc('label.filter_time_from')}:`,
          val: this.$tstore.state.filter.from,
        });
      }

      if (this.$tstore.state.filter.to !== '24:00') {
        ret.push({
          title: `${this.$i18n.tc('label.filter_time_until')}:`,
          val: this.$tstore.state.filter.to,
        });
      }

      if (this.$tstore.state.filter.tab !== null) {
        ret.push({
          title: `${this.$i18n.tc('label.section')}:`,
          val: this.$tstore.state.filter.tab.name.replace(/ /g, '\u00a0'),
        });
      }

      if (this.$tstore.state.filter.status !== ReservationStatus.Valid) {
        ret.push({
          title: `${this.$i18n.tc('label.status')}:`,
          val: this.$i18n.tc(`code.status.${this.$tstore.state.filter.status}`),
        });
      }

      return ret;
    },
  },
  methods: {
    resetFilter() {
      this.$tstore.dispatch('resetFilter');
    },
  },
});

