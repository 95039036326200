
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'MiniDataChart',
  components: {
    VChart,
  },
  props: {
    title: { type: String, required: true },
    categoryType: { type: String as PropType<string>, default: 'day' },
    category: { type: Array as PropType<Array<Date>>, default: () => [] },
    series: { type: Array as PropType<Array<string>>, default: () => [] },
    dataset: { type: Array as PropType<Array<[number, number, number?]>>, default: () => [] },
    totals: { type: Array as PropType<Array<number>>, default: () => [] },
    inverseMetric: { type: Boolean, required: false, default: false },
    unit: { type: String, required: false, default: '%' },
  },
  data() {
    return {
      option: (this as any).createDataset(),
      themeDark,
      themeLight,
    };
  },
  computed: {
    compare(): boolean {
      return this.totals.length === 2;
    },
    isImproved(): boolean {
      return this.inverseMetric ? this.totals[0] < this.totals[1] : this.totals[0] > this.totals[1];
    },
    isSame(): boolean {
      return this.totals[0] === this.totals[1];
    },
    values(): string[] {
      const values = [this.totals[0] ?? 0, this.totals[1] ?? 0];
      return values.map((v) => {
        const isdecimal = v % 1 !== 0;
        const fdigits = isdecimal ? 1 : 0;
        return v.toFixed(fdigits);
      });
    },
  },
  watch: {
    categoryType() { this.reset(); },
    category() { this.reset(); },
    series() { this.reset(); },
    dataset() { this.reset(); },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const series = [] as any[];
      const colors = ['#3355ff', '#FF8262'];

      this.series.forEach((name, i) => {
        series.push(
          {
            type: 'line',
            smooth: 0.5,
            name,
            symbol: 'none',
            itemStyle: {
              color: colors[i],
            },
            areaStyle: i === 0 ? {
              color: colors[i],
              opacity: 0.5,
            } : undefined,
            encode: {
              x: 'date',
              y: [`series${i}`],
            },
          },
        );
      });

      return {
        grid: {
          left: '34px',
          top: '16px',
          right: '0px',
          bottom: '16px',
        },
        dataset: {
          source: this.dataset,
          dimensions: ['date', 'series0', 'series1'],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
          valueFormatter: (value: number) => (value !== undefined ? `${value.toFixed(1)}${this.unit}` : '-'),
          extraCssText: 'min-width: 200px; max-width: 600px',
        },
        legend: {
          show: false,
        },
        xAxis: {
          type: 'category',
          data: this.category.map((d) => this.$localized.veryShortDayMonthText(d)!),
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          minorSplitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            interval: () => false,
            showMinLabel: false,
            showMaxLabel: true,
            margin: 2,
            formatter: `{value}${this.unit}`,
          },
        },
        series,
      };
    },
  },
});

