/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

import {
  EqualDateFieldOperation, EqualDateNoYearFieldOperation, FieldDateOperation, FilterDateOperand,
} from './filter-rule-date-operation';
import {
  FilterOperandType, NoOperation,
  ContainsFieldOperation, EqualFieldOperation, GreaterThenFieldOperation, LessThenFieldOperation,
  IsSetFieldOperation, IsEmptyFieldOperation,
} from './filter-rule-operation';

type FilterCheckObject = { [field: string]: any }
type FilterCheckFnc = (object: FilterCheckObject) => boolean;

const NoFilterCheckFnc = (object: FilterCheckObject) => false;

export enum FilterRuleType {
  None = 'nn',

  True = 'tr',
  False = 'fls',
  And = 'and',
  Or = 'or',
  Nand = 'nand',
  Nor = 'nor',

  Equal = 'eq',
  NotEqual = 'neq',
  LessThen = 'lt',
  GreaterThen = 'gt',
  Contains = 'in',
  IsSet = 'is',
  IsNotSet = 'ins',
  IsEmpty = 'ie',
  IsNotEmpty = 'ine',
}
const FilterBoolRuleTypes = [
  FilterRuleType.True, FilterRuleType.False,
  FilterRuleType.And, FilterRuleType.Nand, FilterRuleType.Or, FilterRuleType.Nor,
];
const FilterFieldRuleTypes = [
  FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.Contains,
  FilterRuleType.LessThen, FilterRuleType.GreaterThen,
  FilterRuleType.IsSet, FilterRuleType.IsNotSet,
  FilterRuleType.IsEmpty, FilterRuleType.IsNotEmpty,
];

const FilterFieldNoOperandTypes = [
  FilterRuleType.IsSet, FilterRuleType.IsNotSet,
  FilterRuleType.IsEmpty, FilterRuleType.IsNotEmpty,
];

export class FilterRuleContext {
  now: Date = new Date();

  firstDayOfWeek: number = 1;
}

export abstract class FilterRule {
  id = 0;

  type = FilterRuleType.None;

  prepare(context: FilterRuleContext) {}

  preparedCheck: FilterCheckFnc = NoFilterCheckFnc;

  check(object: FilterCheckObject) {
    const check = this.preparedCheck(object);
    // console.log(`FilterRule (${this.type}) check:${check}`);
    return check;
  }

  static isFilterBoolRuleType(type: FilterRuleType) {
    return FilterBoolRuleTypes.includes(type);
  }

  static isFilterFieldRuleType(type: FilterRuleType) {
    return FilterFieldRuleTypes.includes(type);
  }

  static isFilterFieldNoOperandType(type: FilterRuleType) {
    return FilterFieldNoOperandTypes.includes(type);
  }

  static newFilterBoolRule(type: FilterRuleType, rules?: FilterRule[]) {
    if (!this.isFilterBoolRuleType(type)) return null;

    const fbr = new FilterBoolRule(type, rules);
    fbr.type = type;
    return fbr;
  }

  static newFilterFieldRule(type: FilterRuleType, field: string, fieldType: string, operand: FilterOperandType = null) {
    if (!this.isFilterFieldRuleType(type)) return null;
    if (field === '') return null;

    const ffr = new FilterFieldRule();
    ffr.type = type;
    ffr.field = field;
    ffr.fieldType = fieldType as FilterFieldType;
    ffr.operand = operand;
    return ffr;
  }
}

// no
export class FilterNoRule extends FilterRule {
  type = FilterRuleType.None;
}

// bool
export class FilterBoolRule extends FilterRule {
  rules: FilterRule[] = [];

  prepare(context: FilterRuleContext) {
    this.rules.forEach((r) => r.prepare(context));

    switch (this.type) {
      case FilterRuleType.False:
        this.preparedCheck = (object: FilterCheckObject) => false; break;
      case FilterRuleType.True:
        this.preparedCheck = (object: FilterCheckObject) => true; break;
      case FilterRuleType.And:
        this.preparedCheck = (object: FilterCheckObject) => this.rules.every((r) => r.check(object)); break;
      case FilterRuleType.Nand:
        this.preparedCheck = (object: FilterCheckObject) => this.rules.some((r) => !r.check(object)); break;
      case FilterRuleType.Or:
        this.preparedCheck = (object: FilterCheckObject) => this.rules.some((r) => r.check(object)); break;
      case FilterRuleType.Nor:
        this.preparedCheck = (object: FilterCheckObject) => this.rules.every((r) => !r.check(object)); break;
      default:
    }
  }

  constructor(type?: FilterRuleType, rules?: FilterRule[]) {
    super();
    this.type = type ?? FilterRuleType.False;
    this.rules = rules ?? [];
  }
}

// field
export enum FilterFieldType {
  None = 'none',
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  DateNoYear = 'datenoyear',
}

export class FilterFieldRule extends FilterRule {
  field: string = '';

  fieldType: FilterFieldType = FilterFieldType.None;

  operand: FilterOperandType = null;

  prepare(context: FilterRuleContext) {
    const operation = this.operationFromType(context);
    this.preparedCheck = (object: FilterCheckObject) => operation.check(object[this.field]);
  }

  check(object: FilterCheckObject) {
    const check = super.check(object);
    // console.log(`${this.field}: [${object[this.field]}] ${this.type} [${this.operand}] = ${check}`);
    return check;
  }

  operationFromType(context: FilterRuleContext) {
    const { operand } = this;
    const { type } = this;
    const { fieldType } = this;

    if ([FilterRuleType.IsSet, FilterRuleType.IsNotSet].includes(type)) {
      const op = new IsSetFieldOperation();
      op.neg = type === FilterRuleType.IsNotSet;
      return op;
    }

    if ([FilterRuleType.IsEmpty, FilterRuleType.IsNotEmpty].includes(type)) {
      const op = new IsEmptyFieldOperation();
      op.neg = type === FilterRuleType.IsNotEmpty;
      return op;
    }

    if (fieldType === FilterFieldType.Date && operand && FieldDateOperation.isDateOperand(operand)) {
      const op = new EqualDateFieldOperation(operand as FilterDateOperand, context.now, context.firstDayOfWeek);
      switch (type) {
        case FilterRuleType.Equal: return op;
        case FilterRuleType.NotEqual: op.neg = true; return op;
        default: return new NoOperation();
      }
    }

    if (fieldType === FilterFieldType.DateNoYear && operand && FieldDateOperation.isDateOperand(operand)) {
      const op = new EqualDateNoYearFieldOperation(operand as FilterDateOperand, context.now, context.firstDayOfWeek);
      switch (type) {
        case FilterRuleType.Equal: return op;
        case FilterRuleType.NotEqual: op.neg = true; return op;
        default: return new NoOperation();
      }
    }

    switch (type) {
      case FilterRuleType.Equal: return new EqualFieldOperation(operand);
      case FilterRuleType.NotEqual: { const op = new EqualFieldOperation(operand); op.neg = true; return op; }
      case FilterRuleType.Contains: return new ContainsFieldOperation(operand);
      case FilterRuleType.LessThen: return new LessThenFieldOperation(operand);
      case FilterRuleType.GreaterThen: return new GreaterThenFieldOperation(operand);
      default: return new NoOperation();
    }
  }
}
