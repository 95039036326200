
import Vue, { PropType } from 'vue';
import TabItem from '@/model/TabItem';

export default Vue.extend({
  name: 'TabItemAllocationRow',
  components: { },
  props: {
    tabItems: { type: Array as PropType<Array<TabItem>>, required: true },
    value: { type: Array as PropType<Array<TabItem>>, required: true },
    // partySize: { type: Number, required: true },
    // isError: { type: Boolean, required: false },
    label: { type: String, required: false, default: '' },
    errorMessages: { type: Array as PropType<Array<String>>, required: false, default: () => [] },
  },
  data() {
    return {
      selection: this.value as TabItem[],
      groupedTabItems: {} as any,
    };
  },
  computed: {
    displayValue(): string | null {
      if (this.selection.length === 0) { return null; }
      return `${this.$i18n.t('label.table', [''])}${this.selection.map(((e) => e.no)).join('+')}`;
    },
    counterVal(): string {
      return this.$i18n.t('label.x_seats', [this.selectedSeats]) as string;
    },
    selectedSeats(): number {
      return this.selection.map(((o) => o.seats)).reduce((a, b) => a + b, 0);
    },
    // enoughSeatsSelected(): boolean {
    //   return this.selectedSeats >= this.partySize;
    // },
  },
  watch: {
    value() {
      this.selection = this.value;
    },
  },
  mounted() {
    this.groupTabItems();
  },
  methods: {
    groupTabItems() {
      this.groupedTabItems = {};

      this.tabItems.forEach((tabItem) => {
        if (tabItem.seats in this.groupedTabItems) {
          this.groupedTabItems[tabItem.seats].push(tabItem);
        } else {
          Vue.set(this.groupedTabItems, tabItem.seats, [tabItem]);
        }
      });
    },
    checkboxComparator(lhs: TabItem, rhs: TabItem):boolean {
      return lhs.id === rhs.id;
    },
    toggleCheckbox(tabItem: TabItem) {
      if (this.selection.find((o) => o.id === tabItem.id)) {
        this.selection.splice(this.selection.findIndex((v) => v.id === tabItem.id), 1);
      } else {
        this.selection.push(tabItem);
      }
      this.selection.sort((o1, o2) => o1.order - o2.order);
      this.$emit('input', this.selection);
    },
  },
});
