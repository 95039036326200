import DateAndTime from '@/model/DateAndTime';
import OpeningHour from '@/model/OpeningHour';
import TimeSlot from '@/model/TimeSlot';

import {
  dateIndexFromDate, dateTimeIndexFromDateAndTimeIndex,
  DAY_TIME_SLOTS_COUNT,
  MORNING_TIME_SLOTS_COUNT,
  timeIndexFromSlot, TIME_SLOTS_COUNT, weekdayNumber,
} from './time-utils';

export function timeSlotsFromHours(
  date: Date,
  openingHoursInWeekdaysByTimeIndex: Map<number, OpeningHour>[],
  datesAndTimesByDateTimeIndex: Map<number, DateAndTime>,
): TimeSlot[] {
  const dateIndex = dateIndexFromDate(date);
  const weekday = weekdayNumber(date);

  return Array.from({ length: TIME_SLOTS_COUNT }, (x, si) => {
    const timeIndex = timeIndexFromSlot(si);
    const dateTimeIndex = dateTimeIndexFromDateAndTimeIndex(dateIndex, timeIndex);
    const dt = datesAndTimesByDateTimeIndex.get(dateTimeIndex);
    const oh = openingHoursInWeekdaysByTimeIndex[weekday].get(timeIndex);
    return new TimeSlot(si, dateTimeIndex, dt, oh);
  });
}

export function isOpenFromHours(
  date: Date,
  openingHoursInWeekdaysByTimeIndex: Map<number, OpeningHour>[],
  datesAndTimesByDateTimeIndex: Map<number, DateAndTime>,
): boolean {
  const dateIndex = dateIndexFromDate(date);
  const weekday = weekdayNumber(date);

  const hours = Array.from(openingHoursInWeekdaysByTimeIndex[weekday].values());
  for (let i = 0; i < hours.length; i += 1) {
    const oh = hours[i];
    const dateTimeIndex = dateTimeIndexFromDateAndTimeIndex(dateIndex, oh.timeIndex);
    const sh = datesAndTimesByDateTimeIndex.get(dateTimeIndex);
    if (sh?.iIsOpen !== false) return true;
  }

  for (let si = MORNING_TIME_SLOTS_COUNT; si < DAY_TIME_SLOTS_COUNT; si += 1) {
    const timeIndex = timeIndexFromSlot(si);
    const dateTimeIndex = dateTimeIndexFromDateAndTimeIndex(dateIndex, timeIndex);
    const sh = datesAndTimesByDateTimeIndex.get(dateTimeIndex);
    if (sh?.iIsOpen === true) return true;
  }

  return false;
}
