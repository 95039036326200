import Tab from '@/model/Tab';

export interface IDTOFloorplan {
  account: {
    token: string,
    accountName: string,
  },
  tabs: {
    tabId: number,
    tabName: string,
    usingWeekdaysAndTimes?: boolean,
    tabDuration?: number,
    tabDurationMinimum?: number,
    tabItems: {
      itemId: number,
      itemNo: string,
      seats: number,
      shape?: string,
      posX?: number,
      posY?: number,
      rotation?: number,
    }[],
  }[],
}

export function toFloorplanDTO(token: string, accountName: string, tabs: Tab[]): IDTOFloorplan {
  return {
    account: { token, accountName },
    tabs: tabs.map((t: Tab) => ({
      tabId: t.id,
      tabName: t.name,
      usingWeekdaysAndTimes: t.usingWeekdaysAndTimes,
      tabDuration: t.duration,
      tabDurationMinimum: t.minDuration,
      tabItems: t.tabItems.map((ti) => ({
        itemId: ti.id,
        itemNo: ti.no,
        seats: ti.seats,
        shape: ti.shape,
        posX: ti.posX,
        posY: ti.posY,
        rotation: ti.rotation,
      })),
    })),
  };
}
