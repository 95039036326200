/* eslint-disable vue/max-len */
/* eslint-disable max-len */

import { GetterTree, MutationTree, ActionTree } from 'vuex';
import httpClient from '@/api/http-client';
import { IDTOMessengerUpdate } from '@/api/api-messenger';
import MessageEventType, { BuiltInEventTypes } from '@/model/MessageEventType';
import MessageType from '@/model/MessageType';
import MessageHandlebar from '@/model/MessageHandlebar';
import MessageRule, { MessageRuleMapping } from '@/model/MessageRule';
import Campaign from '@/model/Campaign';
import {
  toModelEntities, mergeModelEntities, toMapById, toMapByField,
} from '@/model/model-utils';
import IRootState, { IMessageCenterState } from './store-state';

export class MessageCenterState implements IMessageCenterState {
  eventTypes: MessageEventType[] = [];

  campaigns: Campaign[] = [];

  messageTypes: MessageType[] = [];

  handlebars: MessageHandlebar[] = [];

  rules: MessageRule[] = [];

  isLoaded: boolean = false;
}

const mutations = <MutationTree<IMessageCenterState>>{
  RESET(state: MessageCenterState) {
    Object.assign(state, new MessageCenterState());
  },
  RESET_MESSAGE_CENTER(state: MessageCenterState) {
    Object.assign(state, new MessageCenterState());
  },
  UPDATE_MESSAGE_RULES(state: MessageCenterState, p: { rules: MessageRule[] }) {
    state.rules = p.rules;
    state.isLoaded = true;
    console.log('MC rules:', state.rules);
  },
  UPDATE_MESSAGE_CENTER(
    state: MessageCenterState,
    p: { eventTypes: MessageEventType[], campaigns: Campaign[], messageTypes: MessageType[],
      handlebars: MessageHandlebar[]},
  ) {
    state.eventTypes = p.eventTypes;
    state.campaigns = p.campaigns;
    state.messageTypes = p.messageTypes;
    state.handlebars = p.handlebars;

    console.log('MC eventTypes:', state.eventTypes);
    console.log('MC campaigns:', state.campaigns);
    console.log('MC messageTypes:', state.messageTypes);
    console.log('MC handlebars:', state.handlebars);
  },
};

const actions = <ActionTree<IMessageCenterState, IRootState>>{
  updateMessageRules({ commit, state, rootGetters }, update: IDTOMessengerUpdate) {
    console.log('updateMessageRules: ', update);

    let rules = (update.messageRules ?? []).map((o) => new MessageRule(o, rootGetters as MessageRuleMapping));
    rules = mergeModelEntities(state.rules, rules).filter(
      (o) => o.isDeleted !== true || o.isDefault === true,
    ); // keep default rules (inactive));

    commit('UPDATE_MESSAGE_RULES', { rules });
  },
  updateMessageCenter({ commit, rootGetters }, update: IDTOMessengerUpdate) {
    console.log('updateMessageCenter: ', update);

    const eventTypes = toModelEntities(MessageEventType, update.eventTypes);
    const campaigns = toModelEntities(Campaign, update.campaigns);
    const messageTypes = toModelEntities(MessageType, update.messageTypes);
    const handlebars = toModelEntities(MessageHandlebar, update.handlebars);

    commit('UPDATE_MESSAGE_CENTER', {
      eventTypes, campaigns, messageTypes, handlebars,
    });

    let rules = (update.messageRules ?? []).map((o) => new MessageRule(o, rootGetters as MessageRuleMapping));
    rules = rules.filter((o) => o.isDeleted !== true || o.isDefault === true); // keep default rules (inactive)

    commit('UPDATE_MESSAGE_RULES', { rules });
  },
  async resetMessageCenter({
    state, commit, dispatch, rootGetters,
  }) {
    commit('RESET_MESSAGE_CENTER');
  },
  async loadMessageCenter({
    state, commit, dispatch, rootGetters,
  }) {
    if (!rootGetters.isLoaded) return;
    const update = await httpClient.loadMessageCenter();
    dispatch('updateMessageCenter', update);
  },
  async sendMessageRule({ state, commit, dispatch }, p: { entity: MessageRule, delete: boolean }) {
    const dto = p.entity.toDto();
    if (p.delete) dto.isDeleted = true;
    else dto.isDeleted = false;
    const update = await httpClient.sendMessageRule(dto);
    dispatch('updateMessageRules', update);
  },
};

const getters = <GetterTree<IMessageCenterState, IRootState>>{
  eventTypesById(state: MessageCenterState) { return toMapById(state.eventTypes); },
  campaignsById(state: MessageCenterState) { return toMapById(state.campaigns); },
  handlebarsById(state: MessageCenterState) { return toMapById(state.handlebars); },
  messageTypesById(state: MessageCenterState) { return toMapById(state.messageTypes); },
  eventTypesByType(state: MessageCenterState) { return toMapByField(state.eventTypes, 'type'); },
  messageEventTypes(state: MessageCenterState): MessageEventType[] { return state.eventTypes; },
  builtinRules(state: MessageCenterState, localGetters: any, rootState: any, rootGetters: any): MessageRule[] {
    const types = BuiltInEventTypes;

    const builtinRuleMessageType = state.messageTypes.find((mt) => mt.isBuiltinRuleDefault);

    const rules = types.flatMap((type, index) => {
      let rule = state.rules.find((o) => o.isDefault && o.eventType?.type === type);
      if (rule) return rule;

      const eventType = localGetters.eventTypesByType.get(type);
      if (!eventType) return [];

      rule = new MessageRule();
      rule.id = -(index + 1000);
      rule.eventType = eventType;
      rule.isDefault = true;
      rule.isInactive = true;
      rule.messageType = builtinRuleMessageType;
      return rule;
    });

    // rules marked default only from backend
    const missingDefaultRules = state.rules.filter((o) => o.isDefault && !rules.includes(o));

    // filter out waitlist-related rules if waitlist is not enabled
    const wlOn = (rootState.settings.accountSettings.enableWaitlist && rootGetters.isWaitlistFeatureAllowed) as Boolean;
    const wlFilteredRules = [...rules, ...missingDefaultRules].filter((r) => r.eventType?.isWaitlist !== true || wlOn);

    return wlFilteredRules;
  },
  customRules(state: MessageCenterState, localGetters: any, rootState: any, rootGetters: any): MessageRule[] {
    return [
      ...state.rules.filter((o) => !o.isDefault && !o.isInactive),
      ...state.rules.filter((o) => !o.isDefault && o.isInactive),
    ];
  },
  isMessageCenterLoaded(state: MessageCenterState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded && rootGetters.isLoaded;
  },
};

const MessageCenterStore = {
  namespaced: false,
  state: new MessageCenterState(),
  mutations,
  actions,
  getters,
};

export default MessageCenterStore;
