// source: feedback.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var reservation_pb = require('./reservation_pb.js');
goog.object.extend(proto, reservation_pb);
goog.exportSymbol('proto.grpc.api.v1.Feedback', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.Feedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.Feedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.Feedback.displayName = 'proto.grpc.api.v1.Feedback';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.Feedback.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.Feedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.Feedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.Feedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reservationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    foodRating: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serviceRating: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ambienceRating: jspb.Message.getFieldWithDefault(msg, 5, 0),
    overallRating: jspb.Message.getFieldWithDefault(msg, 6, 0),
    comments: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dtCreate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    reservationDtBegin: jspb.Message.getFieldWithDefault(msg, 10, 0),
    contactName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contactReservationCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    dtRepliedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    reservation: (f = msg.getReservation()) && reservation_pb.Reservation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.Feedback}
 */
proto.grpc.api.v1.Feedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.Feedback;
  return proto.grpc.api.v1.Feedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.Feedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.Feedback}
 */
proto.grpc.api.v1.Feedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReservationId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFoodRating(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceRating(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmbienceRating(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOverallRating(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCreate(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReservationDtBegin(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactReservationCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtRepliedAt(value);
      break;
    case 14:
      var value = new reservation_pb.Reservation;
      reader.readMessage(value,reservation_pb.Reservation.deserializeBinaryFromReader);
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.Feedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.Feedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.Feedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.Feedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReservationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFoodRating();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getServiceRating();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAmbienceRating();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOverallRating();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDtCreate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getReservationDtBegin();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getContactName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContactReservationCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getDtRepliedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getReservation();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      reservation_pb.Reservation.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 reservation_id = 2;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getReservationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 food_rating = 3;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getFoodRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setFoodRating = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 service_rating = 4;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getServiceRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setServiceRating = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ambience_rating = 5;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getAmbienceRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setAmbienceRating = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 overall_rating = 6;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getOverallRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setOverallRating = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string comments = 7;
 * @return {string}
 */
proto.grpc.api.v1.Feedback.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 dt_create = 8;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getDtCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setDtCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool is_public = 9;
 * @return {boolean}
 */
proto.grpc.api.v1.Feedback.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 reservation_dt_begin = 10;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getReservationDtBegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setReservationDtBegin = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string contact_name = 11;
 * @return {string}
 */
proto.grpc.api.v1.Feedback.prototype.getContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 contact_reservation_count = 12;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getContactReservationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setContactReservationCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 dt_replied_at = 13;
 * @return {number}
 */
proto.grpc.api.v1.Feedback.prototype.getDtRepliedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.setDtRepliedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional Reservation reservation = 14;
 * @return {?proto.grpc.api.v1.Reservation}
 */
proto.grpc.api.v1.Feedback.prototype.getReservation = function() {
  return /** @type{?proto.grpc.api.v1.Reservation} */ (
    jspb.Message.getWrapperField(this, reservation_pb.Reservation, 14));
};


/**
 * @param {?proto.grpc.api.v1.Reservation|undefined} value
 * @return {!proto.grpc.api.v1.Feedback} returns this
*/
proto.grpc.api.v1.Feedback.prototype.setReservation = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.Feedback} returns this
 */
proto.grpc.api.v1.Feedback.prototype.clearReservation = function() {
  return this.setReservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.Feedback.prototype.hasReservation = function() {
  return jspb.Message.getField(this, 14) != null;
};


goog.object.extend(exports, proto.grpc.api.v1);
