
import Vue, { PropType } from 'vue';
import themeDark from '@/../static/chart-dark.json';
import themeLight from '@/../static/chart-light.json';

import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default Vue.extend({
  name: 'RatingsBarChart',
  components: {
    VChart,
  },
  props: {
    title: { type: String, required: true },
    // categoryType: { type: String as PropType<string>, default: 'day' },
    category: { type: Array as PropType<Array<Date>>, default: () => [] },
    series: { type: Array as PropType<Array<{name: string, data: number[]}>>, default: () => [] },
    avgSeries: { type: Array as PropType<Array<{name: string, data: number[]}>>, default: () => [] },
  },
  data() {
    return {
      option: undefined as any,
      themeDark,
      themeLight,
    };
  },
  computed: {
    // categoryData(): string[] {
    //   switch (this.categoryType) {
    //     case 'hour': return this.category.map((d) => this.$localized.hourMinText(d)!);
    //     case 'month': return this.category.map((d) => this.$localized.monthText(d)!);
    //     default: return this.category.map((d) => this.$localized.shortDayMonthText(d)!);
    //   }
    // },
  },
  watch: {
    // categoryType() { this.reset(); },
    category() { this.reset(); },
    series() { this.reset(); },
    avgSeries() { this.reset(); },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.option = this.createDataset();
    },
    createDataset(): any {
      const colors = ['#00A26F', '#4dbe9a', '#FF8262', '#EF5350', '#FF002B'];
      const avgColors = ['#3355FF', '#99aaff'];

      // series
      const series = [] as any[];
      this.series?.forEach((s, i) => {
        series.push({
          name: s.name,
          type: 'bar',
          stack: 'total',
          barWidth: '60%',
          smooth: 0.15,
          yAxisIndex: 1,
          symbol: 'roundRect',
          emphasis: {
            focus: 'series',
          },
          data: s.data,
          itemStyle: {
            color: colors[i],
          },
        });
      });
      this.avgSeries?.forEach((s, i) => {
        series.push({
          name: s.name,
          type: 'line',
          connectNulls: true,
          yAxisIndex: 0,
          smooth: 0.5,
          symbol: 'roundRect',
          emphasis: {
            focus: 'series',
          },
          data: s.data,
          itemStyle: {
            color: avgColors[i],
          },
        });
      });

      // yAxis
      const yAxis = [] as any[];
      yAxis.push({
        type: 'value',
        name: this.$i18n.tc('label.report_average'),
        position: 'left',
        // alignTicks: true,
        offset: 0,
        min: 0.0,
        max: 5.0,
        axisLine: {
          show: true,
          lineStyle: {
            color: avgColors[0],
          },
        },
      });
      yAxis.push({
        type: 'value',
        name: this.series.length > 0 ? this.$i18n.tc('label.report_count') : '',
        position: 'right',
        alignTicks: true,
        axisLine: {
          show: this.series.length > 0,
          lineStyle: {
            color: colors[0],
          },
        },
      });

      // chart data
      return {
        grid: {
          left: '2%',
          top: '36px',
          right: '2%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x',
          },
          valueFormatter: (v: number) => (v !== undefined && v % 1 !== 0 ? `${v.toFixed(1)}` : v),
          extraCssText: 'min-width: 200px; max-width: 600px',
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
        },
        xAxis: {
          // splitNumber: 16,
          type: 'category',
          data: this.category,
          axisLabel: {
            interval: 0,
            rotate: 45,
          },
          splitLine: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
        },
        yAxis,
        series,
      };
    },
  },
});

