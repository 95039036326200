
import Vue from 'vue';
import { MenuItem, settingsMenuItems } from '@/util/menu';

export default Vue.extend({
  name: 'Settings',
  components: { },
  data() {
    return {};
  },
  computed: {
    settingItems(): MenuItem[] {
      // show billing page in settings
      const billingPageAllowed = this.$store.getters.isBillingPageAllowed;
      return settingsMenuItems({ billingPageAllowed });
    },
  },
});
