
import Vue from 'vue';
import UnsavedGuard from '@/mixins/unsaved-guard';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import HourSlotsGrid, { HourSlotMenuItemType } from '@/components/views/settings/HourSlotsGrid.vue';
import { performSaveAction } from '@/services/vue-utils';
import { isModelEqualToModel, cloneModel } from '@/model/model-utils';
import HourSlot from '@/model/HourSlot';
import HourSlotProfile, { profilesFromHours } from '@/model/HourSlotProfile';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import {
  DAY_TIME_SLOTS_COUNT, MORNING_TIME_SLOTS_COUNT, timeIndexFromSlot, weekdayNumberFromDateDay,
} from '@/services/time-utils';
import OpeningHour from '@/model/OpeningHour';
import SettingsButtonBlock from '@/components/forms/SettingsButtonBlock.vue';

export default Vue.extend({
  components: {
    SettingsForm, HourSlotsGrid, SettingsButtonBlock,
  },
  mixins: [UnsavedGuard, SettingsBreadcrumbs],
  data() {
    return {
      breadcrumbs: [],
      hours: [] as OpeningHour[],
      oldHours: [] as OpeningHour[],
      dayIndex: 0,
      unwatch: Function as (() => void),
    };
  },
  computed: {
    weekdayNumber(): number {
      return weekdayNumberFromDateDay(this.days[this.dayIndex].value);
    },
    isChanged(): boolean {
      return !isModelEqualToModel(this.hours, this.oldHours);
    },
    daySlots(): (OpeningHour | null)[] {
      const ohmap = new Map<number, OpeningHour>();
      this.hours.forEach((o) => { if (o.weekdayNumber === this.weekdayNumber) ohmap.set(o.timeIndex, o); });

      const slots: (OpeningHour|null)[] = [];
      for (let si = MORNING_TIME_SLOTS_COUNT; si < DAY_TIME_SLOTS_COUNT; si += 1) {
        const ti = timeIndexFromSlot(si);
        const oh = ohmap.get(ti) ?? null;
        slots.push(oh);
      }

      return slots;
    },
    days(): {value: number, text:string}[] {
      return this.$localized.longWeekdays();
    },
    profilesById(): Map<number, HourSlotProfile> {
      return profilesFromHours(this.hours);
    },
    profiles(): HourSlotProfile[] {
      return Array.from(this.profilesById.values());
    },
  },
  watch: {

  },
  created() {
    this.unwatch = this.$tstore.watch((state, getters) => getters.isLoaded, (newValue, oldValue) => {
      this.reset();
    });
  },
  beforeDestroy() {
    this.unwatch();
  },
  destroyed() {},
  mounted() {
    this.reset();
  },
  methods: {
    profileSelected(hourIndex: number, type: number, IDOrHourSlot: number | HourSlot) {
      const slotIndex = hourIndex + MORNING_TIME_SLOTS_COUNT;
      const timeIndex = timeIndexFromSlot(slotIndex);
      const index = this.hours.findIndex((o) => o.weekdayNumber === this.weekdayNumber && o.timeIndex === timeIndex);
      const hour = OpeningHour.fromWeekdayAndSlot(this.weekdayNumber, slotIndex);
      let profile: HourSlotProfile | undefined;

      switch (type) {
        case HourSlotMenuItemType.CLOSED:
        // closed - remove opening hour and return!
          if (index >= 0) this.hours.splice(index, 1);
          return;
        case HourSlotMenuItemType.OPEN:
        // just open - use empty hour
          break;
        case HourSlotMenuItemType.CUSTOM:
        // existing custom - fill and use hour from existing profile
          profile = this.profilesById.get(IDOrHourSlot as number);
          hour.setParams(profile ?? {});
          break;
        case HourSlotMenuItemType.CUSTOM_NEW:
        // new custom - fill and use hour from values
          hour.setParams(IDOrHourSlot as HourSlot);
          break;
        default:
          break;
      }

      // use hour
      if (index >= 0) this.hours[index] = hour;
      else this.hours.push(hour);
      this.hours = this.hours.sort((o1, o2) => o1.order - o2.order);
    },
    copyFrom(dayIndex: number) {
      const weekdayNumber = weekdayNumberFromDateDay(this.days[dayIndex].value);
      const newHours = this.hours.filter((o) => o.weekdayNumber !== this.weekdayNumber);
      const dayHours = this.hours.filter((o) => o.weekdayNumber === weekdayNumber);

      const newDayHours = dayHours.map((o) => {
        const no = cloneModel(o);
        no.weekdayNumber = this.weekdayNumber;
        return no;
      });
      newHours.push(...newDayHours);

      this.hours = newHours.sort((o1, o2) => o1.order - o2.order);
    },
    reset() {
      this.oldHours = this.$tstore.state.settings.openingHours;
      this.hours = this.oldHours.map((o) => cloneModel(o));
    },
    async save() {
      const entities = this.hours;

      const ok = await performSaveAction(
        undefined,
        async () => this.$tstore.dispatch('sendOpeningHours', { entities }),
      );

      if (ok) {
        this.reset();
      }
    },
  },
});
