import * as pbs from '@/grpc-pb/settings_service_pb';
import * as pbm from '@/grpc-pb/currency_pb';

export default class Currency {
  id: number = 0;

  code: string = 'N/A';

  hasDecimals: boolean = false;

  constructor(o?: { id?: number, code?: string, hasDecimals?: boolean }) {
    this.id = o?.id ?? 0;
    this.code = o?.code ?? 'N/A';
    this.hasDecimals = o?.hasDecimals ?? false;
  }

  static fromGrpcResponse(r: pbs.CurrencyResponse): Currency {
    const e = r.getCurrency();
    return Currency.fromGrpcModel(e);
  }

  static fromGrpcModel(o?: pbm.Currency): Currency {
    const e = new Currency();

    e.id = o?.getId() ?? 0;
    e.code = o?.getCode() ?? 'N/A';
    e.hasDecimals = o?.getHasdecimals() ?? false;

    return e;
  }

  toGrpcModel(): pbm.Currency {
    const o = new pbm.Currency();

    o.setId(this.id);
    o.setCode(this.code);
    o.setHasdecimals(this.hasDecimals);

    return o;
  }

  get value() { return this.id; }

  get text() { return this.code; }
}
