import Account from '@/model/Account';
import AccountSetting from '@/model/AccountSettings';
import Employee from '@/model/Employee';
import { cloneModel } from '@/model/model-utils';

export interface IStorageData {
  token?: string;
  username?: string;
  account?: Account;
  accountSetting?: AccountSetting;
  floorplanZoom?: number;
  timetableZoom?: number;
  timetablePosition?: number;
  lastEmployee?: Employee;
  language?: string;
  cameraOnboarded?: boolean;
  cameraActive?: boolean;
  dashboardShowDetails?: boolean;
  languagePromptDisplayed?: boolean;
  fcmToken?: string;
  pwaBarDismissCount?: number;
}

class Storage {
  data: IStorageData = {};

  getFcmToken() { return this.data.fcmToken ?? null; }

  getToken() { return this.data.token ?? null; }

  getUsername() { return this.data.username ?? null; }

  getAccount() { return this.data.account ?? null; }

  getAccountSetting() { return this.data.accountSetting ?? null; }

  getFloorplanZoom() { return this.data.floorplanZoom ?? null; }

  getTimetableZoom() { return this.data.timetableZoom ?? null; }

  getTimetablePosition() { return this.data.timetablePosition ?? null; }

  getLastEmployee() { return this.data.lastEmployee ?? null; }

  getLanguage() { return this.data.language ?? null; }

  getCameraOnboarded() { return this.data.cameraOnboarded ?? null; }

  getCameraActive() { return this.data.cameraActive ?? false; }

  dashboardShowDetails() { return this.data.dashboardShowDetails ?? false; }

  getLanguagePromptDisplayed() { return this.data.languagePromptDisplayed ?? false; }

  getPwaBarDismissCount() { return this.data.pwaBarDismissCount ?? 0; }

  setToken(value: string | null) {
    this.data.token = value || undefined;
    this.saveData();
  }

  setFcmToken(token: string | null) {
    this.data.fcmToken = token || undefined;
    this.saveData();
  }

  setUsername(value: string | null) {
    this.data.username = value || undefined;
    this.saveData();
  }

  setAccount(value: Account | null) {
    this.data.account = value || undefined;
    this.saveData();
  }

  setAccountSetting(value: AccountSetting | null) {
    this.data.accountSetting = value || undefined;
    this.saveData();
  }

  setFloorplanZoom(value: number | null) {
    this.data.floorplanZoom = value || undefined;
    this.saveData();
  }

  setTimetableZoom(value: number | null) {
    this.data.timetableZoom = value || undefined;
    this.saveData();
  }

  setTimetablePosition(value: number | null) {
    this.data.timetablePosition = value || undefined;
    this.saveData();
  }

  setLastEmployee(value: Employee | null) {
    this.data.lastEmployee = value || undefined;
    this.saveData();
  }

  setLanguage(value: string | null) {
    this.data.language = value || undefined;
    this.saveData();
  }

  setCameraOnboarded(value: boolean | null) {
    this.data.cameraOnboarded = value || undefined;
    this.saveData();
  }

  setCameraActive(value: boolean | null) {
    this.data.cameraActive = value || undefined;
    this.saveData();
  }

  setDashboardShowDetails(value: boolean | null) {
    this.data.dashboardShowDetails = value || undefined;
    this.saveData();
  }

  setLanguagePromptDisplayed(value: boolean) {
    this.data.languagePromptDisplayed = value;
    this.saveData();
  }

  setPwaBarDismissCount(value: number) {
    this.data.pwaBarDismissCount = value;
    this.saveData();
  }

  saveData() {
    localStorage.setItem('data', JSON.stringify(this.data));
  }

  clear(all = false) {
    if (all) {
      this.data = {};
    } else {
      this.data.token = undefined;
      this.data.account = undefined;
      this.data.accountSetting = undefined;
      this.data.fcmToken = undefined;
    }
    this.saveData();
  }

  constructor() {
    const text = localStorage.getItem('data');
    if (text) this.data = JSON.parse(text) ?? {};

    // recreate objects
    if (this.data.account) this.data.account = Account.fromData(this.data.account);
    if (this.data.accountSetting) AccountSetting.fromData(this.data.accountSetting);
  }

  setData(data: IStorageData) {
    this.data = { ...data };

    // recreate objects
    if (this.data.account) this.data.account = cloneModel(this.data.account);
    if (this.data.accountSetting) this.data.accountSetting = cloneModel(this.data.accountSetting);

    this.saveData();
  }

  getData(): IStorageData {
    return this.data;
  }
}

const storage = new Storage();
export default storage;
