
import Vue, { PropType } from 'vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import User from '@/model/User';

export default Vue.extend({
  name: 'TableAllocationCTA',
  components: { ConfirmationDialog },
  props: { },
  data() {
    return { dialogVisible: false };
  },
  computed: {
    tableAllocationCTAVisible(): boolean {
      const { tableAllocation } = this.$tstore.state.settings.accountSettings;
      return tableAllocation === true || tableAllocation === undefined;
    },
    userSettingsAllowed(): boolean {
      const activeUser = this.$tstore.getters.activeUser as User | undefined;
      return activeUser?.allowSettings || false;
    },
  },
  methods: { },
});
