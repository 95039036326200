import { boolFromValue } from '@/api/api-utils';
import { IContact } from '@/api/api-update';
import { dateFromDateString } from '@/services/time-utils';
import Country from './Country';

export enum ContactTitle {
  Mr = 'MR',
  Ms = 'MS',
  Fam = 'FAM',
}

export interface ContactMapping {
  countryByCode: Map<string, Country>;
}

export default class Contact {
  id: number;

  name: string | null;

  phone: string | null;

  email?: string;

  address?: string;

  postalCode?: string;

  city?: string;

  state?: string;

  country?: Country;

  companyName?: string;

  reservationCount?: number;

  isVIP?: boolean;

  preferredLanguage?: string;

  dateOfBirth?: Date;

  newsletterSubscription?: boolean;

  notes?: string;

  title?: string;

  constructor(o?: IContact, m?: ContactMapping) {
    this.id = o?.contactId ?? 0;
    this.name = o?.name ?? '';
    this.phone = o?.phone ?? '';
    this.email = o?.email;
    this.address = o?.address;
    this.postalCode = o?.postalCode ?? '';
    this.city = o?.city;
    this.state = o?.state;
    this.companyName = o?.companyName;
    this.reservationCount = o?.reservationCount;
    this.isVIP = boolFromValue(o?.isVIP);
    this.preferredLanguage = o?.preferredLanguage;
    this.dateOfBirth = dateFromDateString(o?.dateOfBirth) ?? undefined;
    this.newsletterSubscription = boolFromValue(o?.newsletterSubscription);
    this.notes = o?.contactNotes;
    this.title = o?.title as ContactTitle;

    this.country = m?.countryByCode.get(o?.country ?? '');
  }

  refresh(m?: ContactMapping) {
    this.country = m?.countryByCode.get(this.country?.code ?? '');
  }
}
