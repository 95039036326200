import Sortable from 'sortablejs';
import { DirectiveFunction } from 'vue';

interface ReorderTable extends DirectiveFunction {}

const ReorderTable = {
  bind(el: any, _: any, vnode: any) {
    const options = {
      handle: '.reorder-handle',
      animation: 150,
      onUpdate(event: any) {
        vnode.child.$emit('sorted', event);
      },
    };
    Sortable.create(el.getElementsByTagName('tbody')[0], options);
  },
};

export default ReorderTable;
