
import Vue from 'vue';
import VCalendar from 'v-calendar';
import { performAction } from '@/services/vue-utils';
import {
  dateByAddingDays, dateByAddingMonths, dateIndexFromDate, isDateBeforeDate,
} from '@/services/time-utils';
import DateSlot from '@/model/DateSlot';
import { isOpenFromHours } from '@/services/timestlot-utils';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

export default Vue.extend({
  name: 'Calendar',
  props: { },
  data() {
    return {
      date: this.$tstore.state.update.date,
      theme: {
        container: {
          light: 'light-theme',
          dark: 'vc-text-white vc-rounded',
        },
        weekdays: {
          light: 'vc-text-xs text--secondary',
          dark: 'vc-text-xs text--secondary',
        },
      },
      fromPage: { month: 0, year: 0 } as { month: number, year: number },
    };
  },
  computed: {
    currentDate(): Date {
      return this.$tstore.state.update.date;
    },
    dates(): Date[] {
      const dates: Date[] = [];

      let startDate = new Date(this.fromPage.year, this.fromPage.month - 1, 1);
      const endDate = dateByAddingMonths(startDate, 3);

      const nowDate = this.$tstore.getters.nowDate as Date;
      if (isDateBeforeDate(startDate, nowDate)) startDate = nowDate;

      for (let date = startDate; isDateBeforeDate(date, endDate); date = dateByAddingDays(date, 1)) {
        dates.push(date);
      }
      return dates;
    },
    dateSlots(): DateSlot[] {
      const { dateSlotsByDateIndex } = this.$tstore.state.reservations;
      return this.dates.flatMap((d) => dateSlotsByDateIndex[dateIndexFromDate(d)] ?? []);
    },
    attributes(): any[] {
      const now = this.$tstore.getters.nowDate as Date;
      const confirmedDates = this.dateSlots.flatMap((ds) => (ds.confirmedCount > 0 ? ds.date : []));
      const nonConfirmedDates = this.dateSlots.flatMap((ds) => (ds.nonConfirmedCount > 0 ? ds.date : []));
      const closedDates = this.dates.flatMap((d) => (!isOpenFromHours(
        d,
        this.$tstore.getters.openingHoursInWeekdaysByTimeIndex,

        this.$tstore.getters.datesAndTimesById,
      ) ? d : []));

      return [
        { // confirmed + planned
          dot: {
            color: 'blue',
          },
          dates: confirmedDates,
        },
        { // unconfirmed
          dot: {
            color: 'purple',
          },
          dates: nonConfirmedDates,
        },
        { // closed
          highlight: {
            contentClass: 'closed-day',
          },
          dates: closedDates,
          order: 1,
        },
        { // today
          highlight: {
            color: 'primary',
            fillMode: 'none',
            class: 'highlight--today',
            contentClass: 'today',
          },
          dates: [
            now,
          ],
        },
      ];
    },
  },
  watch: {
    currentDate() {
      this.date = this.$tstore.state.update.date;
      const calendar = (this.$refs.picker as Vue).$refs.calendar as any;
      calendar.move(this.$tstore.state.update.date);
    },
  },
  methods: {
    async goToDate(date: Date) {
      // ensure calendar-friendly view is pushed when selecting a date from
      // other places like settings, etc.
      if (this.$router.currentRoute.meta?.hasDatePicker !== true) {
        this.$router.push('/guests');
      }

      this.$emit('change', date);
      await performAction(
        null,
        this.$i18n.tc('error.load_data'),
        async () => this.$tstore.dispatch('changeToDate', date),
      );
    },
    async dayclick(event: { date: Date }) {
      (document.activeElement as HTMLElement | null)?.blur();
      this.goToDate(event.date);
    },
    goToToday() {
      this.goToDate(new Date());
      const calendar = (this.$refs.picker as Vue).$refs.calendar as any;
      calendar.move(new Date(), { position: 1 });
    },
    previousMonths() {
      const calendar = (this.$refs.picker as Vue).$refs.calendar as any;
      calendar.move(-1);
    },
    nextMonths() {
      const calendar = (this.$refs.picker as Vue).$refs.calendar as any;
      calendar.move(1);
    },
    updateFromPage(page: { month: number, year: number }) {
      this.fromPage = page;
    },
  },
});
