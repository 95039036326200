/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  if (!window.matchMedia('(display-mode: standalone)').matches || process.env.VUE_APP_BRAND !== 'guestplan') {
    console.log('[sw] Not running in PWA or not Guestplan brand context, exiting');
  } else {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          '[sw] App is being served from cache by a service worker.',
        );
      },
      registered(r: ServiceWorkerRegistration) {
        console.log('[sw] Service worker has been registered.');
      },
      cached() {
        console.log('[sw] Content has been cached for offline use.');
      },
      updatefound() {
        console.log('[sw] New content is downloading.');
      },
      updated(r: ServiceWorkerRegistration) {
        console.log('[sw] New content is available; please refresh.');
        document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: r }),
        );
      },
      offline() {
        console.log('[sw] No internet connection found. App is running in offline mode.');
      },
      error(error) {
        console.error('[sw] Error during service worker registration:', error);
      },
    });
  }
}
