
import Vue from 'vue';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import MessageCenterRulesTable from '@/components/views/settings/MessageCenterRulesTable.vue';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import { performAction } from '@/services/vue-utils';
import MessageRule from '@/model/MessageRule';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import MessageCenterStore from '@/store/message-center-store';
import { MessageTypeChannel } from '@/model/MessageType';

export default Vue.extend({
  name: 'MessageCenter',
  components: { SettingsForm, MessageCenterRulesTable },
  mixins: [SettingsBreadcrumbs],
  data() {
    return {
      breadcrumbs: [],
    };
  },
  computed: {
    customRules(): MessageRule[] {
      return this.$tstore.getters.customRules;
    },
    builtinRules(): MessageRule[] {
      return this.$tstore.getters.builtinRules;
    },
  },
  created() {
    loadStoreModule(this, 'messageCenter', MessageCenterStore, async () => { this.load(); });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'messageCenter', async () => { this.$tstore.dispatch('resetMessageCenter'); });
  },
  methods: {
    async load() {
      if (this.$tstore.getters.isMessageCenterLoaded) return;
      const ok = await performAction(
        null,
        this.$i18n.tc('error.load_message_center'),
        async () => this.$tstore.dispatch('loadMessageCenter'),
      );

      if (!ok) return;

      const messageChannel = this.$route.query['message-channel'] as string;
      const evenType = this.$route.query['event-type'] as string;

      if (messageChannel && evenType) {
        const rule = this.builtinRules.find(
          (r) => r.eventType?.type === evenType && r.messageType?.channel === MessageTypeChannel.EMAIL,
        );
        if (rule) this.edit({ id: rule?.id }, undefined);
      }
    },
    edit(o: any, prop: any) {
      this.$router.push(`/settings/message-center/${o.id}`);
    },
    add() {
      this.$router.push('/settings/message-center/new');
    },
  },
});
