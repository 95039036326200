// source: giftcards_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var empty_pb = require('./empty_pb.js');
goog.object.extend(proto, empty_pb);
var giftcard_pb = require('./giftcard_pb.js');
goog.object.extend(proto, giftcard_pb);
var giftcard_product_pb = require('./giftcard_product_pb.js');
goog.object.extend(proto, giftcard_product_pb);
var giftcard_transaction_pb = require('./giftcard_transaction_pb.js');
goog.object.extend(proto, giftcard_transaction_pb);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsGetGiftcardRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsGiftcardResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsListGiftcardsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsListGiftcardsResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsListTransactionsParams', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsListTransactionsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsListTransactionsResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsProductRequets', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsProductResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsProductRequets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsProductRequets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsProductRequets.displayName = 'proto.grpc.api.v1.GiftcardsProductRequets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsProductResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsProductResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsProductResponse.displayName = 'proto.grpc.api.v1.GiftcardsProductResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.GiftcardsListTransactionsParams.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsListTransactionsParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsListTransactionsParams.displayName = 'proto.grpc.api.v1.GiftcardsListTransactionsParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsListTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsListTransactionsRequest.displayName = 'proto.grpc.api.v1.GiftcardsListTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsListTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsListTransactionsResponse.displayName = 'proto.grpc.api.v1.GiftcardsListTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.GiftcardsListGiftcardsRequest.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsListGiftcardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsListGiftcardsRequest.displayName = 'proto.grpc.api.v1.GiftcardsListGiftcardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsListGiftcardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsListGiftcardsResponse.displayName = 'proto.grpc.api.v1.GiftcardsListGiftcardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.displayName = 'proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsGetGiftcardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsGetGiftcardRequest.displayName = 'proto.grpc.api.v1.GiftcardsGetGiftcardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.GiftcardsGiftcardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.GiftcardsGiftcardResponse.displayName = 'proto.grpc.api.v1.GiftcardsGiftcardResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsProductRequets.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsProductRequets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsProductRequets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsProductRequets.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && giftcard_product_pb.GiftcardProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsProductRequets}
 */
proto.grpc.api.v1.GiftcardsProductRequets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsProductRequets;
  return proto.grpc.api.v1.GiftcardsProductRequets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsProductRequets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsProductRequets}
 */
proto.grpc.api.v1.GiftcardsProductRequets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new giftcard_product_pb.GiftcardProduct;
      reader.readMessage(value,giftcard_product_pb.GiftcardProduct.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsProductRequets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsProductRequets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsProductRequets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsProductRequets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      giftcard_product_pb.GiftcardProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional GiftcardProduct product = 1;
 * @return {?proto.grpc.api.v1.GiftcardProduct}
 */
proto.grpc.api.v1.GiftcardsProductRequets.prototype.getProduct = function() {
  return /** @type{?proto.grpc.api.v1.GiftcardProduct} */ (
    jspb.Message.getWrapperField(this, giftcard_product_pb.GiftcardProduct, 1));
};


/**
 * @param {?proto.grpc.api.v1.GiftcardProduct|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsProductRequets} returns this
*/
proto.grpc.api.v1.GiftcardsProductRequets.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsProductRequets} returns this
 */
proto.grpc.api.v1.GiftcardsProductRequets.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsProductRequets.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsProductResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsProductResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsProductResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsProductResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && giftcard_product_pb.GiftcardProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsProductResponse}
 */
proto.grpc.api.v1.GiftcardsProductResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsProductResponse;
  return proto.grpc.api.v1.GiftcardsProductResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsProductResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsProductResponse}
 */
proto.grpc.api.v1.GiftcardsProductResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new giftcard_product_pb.GiftcardProduct;
      reader.readMessage(value,giftcard_product_pb.GiftcardProduct.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsProductResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsProductResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsProductResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsProductResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      giftcard_product_pb.GiftcardProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional GiftcardProduct product = 1;
 * @return {?proto.grpc.api.v1.GiftcardProduct}
 */
proto.grpc.api.v1.GiftcardsProductResponse.prototype.getProduct = function() {
  return /** @type{?proto.grpc.api.v1.GiftcardProduct} */ (
    jspb.Message.getWrapperField(this, giftcard_product_pb.GiftcardProduct, 1));
};


/**
 * @param {?proto.grpc.api.v1.GiftcardProduct|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsProductResponse} returns this
*/
proto.grpc.api.v1.GiftcardsProductResponse.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsProductResponse} returns this
 */
proto.grpc.api.v1.GiftcardsProductResponse.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsProductResponse.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsListTransactionsParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    beginDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    typesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    giftcardCodesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    giftcardCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsListTransactionsParams;
  return proto.grpc.api.v1.GiftcardsListTransactionsParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeginDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTypes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addGiftcardCodes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftcardCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsListTransactionsParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeginDate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGiftcardCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getGiftcardCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 begin_date = 1;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.getBeginDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.setBeginDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end_date = 2;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string types = 3;
 * @return {!Array<string>}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.getTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * repeated string giftcard_codes = 4;
 * @return {!Array<string>}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.getGiftcardCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.setGiftcardCodesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.addGiftcardCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.clearGiftcardCodesList = function() {
  return this.setGiftcardCodesList([]);
};


/**
 * optional string giftcard_code = 5;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.getGiftcardCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsParams} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsParams.prototype.setGiftcardCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsListTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.GiftcardsListTransactionsParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsRequest}
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsListTransactionsRequest;
  return proto.grpc.api.v1.GiftcardsListTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsRequest}
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.GiftcardsListTransactionsParams;
      reader.readMessage(value,proto.grpc.api.v1.GiftcardsListTransactionsParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsListTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.GiftcardsListTransactionsParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional GiftcardsListTransactionsParams params = 1;
 * @return {?proto.grpc.api.v1.GiftcardsListTransactionsParams}
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.GiftcardsListTransactionsParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.GiftcardsListTransactionsParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.GiftcardsListTransactionsParams|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} returns this
*/
proto.grpc.api.v1.GiftcardsListTransactionsRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsRequest} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsListTransactionsRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsListTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.GiftcardsListTransactionsParams.toObject(includeInstance, f),
    transaction: (f = msg.getTransaction()) && giftcard_transaction_pb.GiftcardTransaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsResponse}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsListTransactionsResponse;
  return proto.grpc.api.v1.GiftcardsListTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsResponse}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.GiftcardsListTransactionsParams;
      reader.readMessage(value,proto.grpc.api.v1.GiftcardsListTransactionsParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    case 2:
      var value = new giftcard_transaction_pb.GiftcardTransaction;
      reader.readMessage(value,giftcard_transaction_pb.GiftcardTransaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsListTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.GiftcardsListTransactionsParams.serializeBinaryToWriter
    );
  }
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      giftcard_transaction_pb.GiftcardTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional GiftcardsListTransactionsParams params = 1;
 * @return {?proto.grpc.api.v1.GiftcardsListTransactionsParams}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.GiftcardsListTransactionsParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.GiftcardsListTransactionsParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.GiftcardsListTransactionsParams|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} returns this
*/
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GiftcardTransaction transaction = 2;
 * @return {?proto.grpc.api.v1.GiftcardTransaction}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.getTransaction = function() {
  return /** @type{?proto.grpc.api.v1.GiftcardTransaction} */ (
    jspb.Message.getWrapperField(this, giftcard_transaction_pb.GiftcardTransaction, 2));
};


/**
 * @param {?proto.grpc.api.v1.GiftcardTransaction|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} returns this
*/
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsListTransactionsResponse} returns this
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsListTransactionsResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsListGiftcardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsListGiftcardsRequest;
  return proto.grpc.api.v1.GiftcardsListGiftcardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatuses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsListGiftcardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string statuses = 1;
 * @return {!Array<string>}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} returns this
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} returns this
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsRequest} returns this
 */
proto.grpc.api.v1.GiftcardsListGiftcardsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsListGiftcardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftcard: (f = msg.getGiftcard()) && giftcard_pb.Giftcard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsListGiftcardsResponse;
  return proto.grpc.api.v1.GiftcardsListGiftcardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new giftcard_pb.Giftcard;
      reader.readMessage(value,giftcard_pb.Giftcard.deserializeBinaryFromReader);
      msg.setGiftcard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsListGiftcardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftcard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      giftcard_pb.Giftcard.serializeBinaryToWriter
    );
  }
};


/**
 * optional Giftcard giftcard = 1;
 * @return {?proto.grpc.api.v1.Giftcard}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.prototype.getGiftcard = function() {
  return /** @type{?proto.grpc.api.v1.Giftcard} */ (
    jspb.Message.getWrapperField(this, giftcard_pb.Giftcard, 1));
};


/**
 * @param {?proto.grpc.api.v1.Giftcard|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse} returns this
*/
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.prototype.setGiftcard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsListGiftcardsResponse} returns this
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.prototype.clearGiftcard = function() {
  return this.setGiftcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsListGiftcardsResponse.prototype.hasGiftcard = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftcardId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    giftcardCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idempotencyKey: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest;
  return proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGiftcardId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftcardCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdempotencyKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftcardId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGiftcardCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getIdempotencyKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 giftcard_id = 1;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.getGiftcardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.setGiftcardId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string giftcard_code = 2;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.getGiftcardCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.setGiftcardCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 balance = 4;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string idempotency_key = 5;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.getIdempotencyKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsRedeemGiftcardRequest.prototype.setIdempotencyKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsGetGiftcardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftcardId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    giftcardCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest}
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsGetGiftcardRequest;
  return proto.grpc.api.v1.GiftcardsGetGiftcardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest}
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGiftcardId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftcardCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsGetGiftcardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftcardId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getGiftcardCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 giftcard_id = 1;
 * @return {number}
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.prototype.getGiftcardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.prototype.setGiftcardId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string giftcard_code = 2;
 * @return {string}
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.prototype.getGiftcardCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.GiftcardsGetGiftcardRequest} returns this
 */
proto.grpc.api.v1.GiftcardsGetGiftcardRequest.prototype.setGiftcardCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.GiftcardsGiftcardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.GiftcardsGiftcardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    giftcard: (f = msg.getGiftcard()) && giftcard_pb.Giftcard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.GiftcardsGiftcardResponse}
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.GiftcardsGiftcardResponse;
  return proto.grpc.api.v1.GiftcardsGiftcardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.GiftcardsGiftcardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.GiftcardsGiftcardResponse}
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new giftcard_pb.Giftcard;
      reader.readMessage(value,giftcard_pb.Giftcard.deserializeBinaryFromReader);
      msg.setGiftcard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.GiftcardsGiftcardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.GiftcardsGiftcardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGiftcard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      giftcard_pb.Giftcard.serializeBinaryToWriter
    );
  }
};


/**
 * optional Giftcard giftcard = 1;
 * @return {?proto.grpc.api.v1.Giftcard}
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.prototype.getGiftcard = function() {
  return /** @type{?proto.grpc.api.v1.Giftcard} */ (
    jspb.Message.getWrapperField(this, giftcard_pb.Giftcard, 1));
};


/**
 * @param {?proto.grpc.api.v1.Giftcard|undefined} value
 * @return {!proto.grpc.api.v1.GiftcardsGiftcardResponse} returns this
*/
proto.grpc.api.v1.GiftcardsGiftcardResponse.prototype.setGiftcard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.GiftcardsGiftcardResponse} returns this
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.prototype.clearGiftcard = function() {
  return this.setGiftcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.GiftcardsGiftcardResponse.prototype.hasGiftcard = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.grpc.api.v1);
