import i18n from '@/plugins/i18n';
import { onBeforeMount, onBeforeUnmount, Ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router/composables';

export default function useUnsavedGuard(isChanged: Ref<boolean>) {
  const preventNav = (event: Event) => {
    if (isChanged.value) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = false;
    }
  };

  onBeforeRouteLeave((to, from, next) => {
    if (isChanged.value) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(i18n.tc('message.confirm_leave_without_saving'))) {
        return;
      }
    }
    next();
  });

  onBeforeMount(() => {
    window.addEventListener('beforeunload', preventNav, false);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', preventNav, false);
  });
}
