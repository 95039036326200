
import Vue from 'vue';
import UnsavedGuard from '@/mixins/unsaved-guard';
import SettingsBreadcrumbs from '@/mixins/settings-breadcrumbs';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import ChangePasswordDialog from '@/components/views/settings/ChangePasswordDialog.vue';
import User from '@/model/User';
import { performAction, performSaveAction } from '@/services/vue-utils';
import { isModelEqualToModel, cloneModel } from '@/model/model-utils';
import SettingsForm from '@/components/layouts/SettingsForm.vue';
import SettingsGroup from '@/components/layouts/SettingsGroup.vue';

export default Vue.extend({
  name: 'User',
  components: {
    ConfirmationDialog, ChangePasswordDialog, SettingsForm, SettingsGroup,
  },
  mixins: [UnsavedGuard, SettingsBreadcrumbs],
  data() {
    return {
      deleteConfirmation: false,
      changePasswordDialog: false,
      u: new User(),
      oldu: new User(),
      unwatch: Function as (() => void),
      privacyItems: [
        { text: this.$t('label.privacy_screen_duration_never'), value: 0 },
        ...[1, 5, 10, 30, 60].map((n) => ({ text: this.$tc('label.x_seconds', n), value: n })),
      ],
      userOptions: [
        {
          title: this.$t('label.allow_signin'),
          subtitle: this.$t('settings.user.allow_signin_help'),
          value: 'allowSignIn',
        },
        {
          title: this.$t('label.allow_reservations'),
          value: 'allowReservations',
          subtitle: this.$t('settings.user.allow_reservations_help'),
        },
        {
          title: this.$i18n.t('label.allow_day_notes'),
          value: 'allowDayNotes',
          subtitle: this.$t('settings.user.allow_day_notes_help'),
        },
        {
          title: this.$i18n.t('label.allow_settings'),
          value: 'allowSettings',
          subtitle: this.$t('settings.user.allow_settings_help'),
        },
        {
          title: this.$i18n.t('label.allow_billing'),
          value: 'allowBilling',
          subtitle: this.$t('settings.user.allow_billing_help'),
        },
        {
          title: this.$i18n.t('label.shared_user'),
          value: 'sharedUser',
          subtitle: this.$t('settings.user.shared_user_help'),
        },
        {
          title: this.$i18n.t('label.user_newsletter_subscription'),
          value: 'newsletterSubscription',
          subtitle: this.$t('settings.user.newsletter_subscription_help'),
        },
      ],
      breadcrumbs: [] as any[],
    };
  },
  computed: {
    isNew(): boolean {
      return !this.u.id;
    },
    isChanged(): boolean {
      return !isModelEqualToModel(this.u, this.oldu);
    },
    isActive(): boolean {
      const activeUser = this.$tstore.getters.activeUser as User | undefined;
      return this.u.id === activeUser?.id;
    },
  },
  created() {
    this.unwatch = this.$tstore.watch((state, getters) => getters.isLoaded, (newValue, oldValue) => { this.reset(); });
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    this.reset();
  },
  methods: {
    async save() {
      const ok = await performSaveAction(
        this.$refs.observer,
        async () => this.$tstore.dispatch('sendUser', { entity: this.u }),
      );

      if (ok) {
        this.reset();
        this.$router.back();
      }
    },
    reset() {
      this.changePasswordDialog = false;

      const id = Number(this.$route.params.id) || 0;
      const u = this.$tstore.state.settings.users.find((o: User) => o.id === id) ?? new User();
      if (!u.id) {
        this.userOptions.forEach((o) => { (u as any)[o.value] = true; });
      }
      this.u = cloneModel(u);
      this.oldu = u;

      this.breadcrumbs[2].text = this.u.name ?? this.$i18n.t('button.add_new_user');
    },
    async remove() {
      const ok = await performAction(
        this.$tc('message.success_user_deleted'),
        this.$tc('error.deleting_user'),
        async () => this.$tstore.dispatch('sendUser', { entity: this.u, delete: true }),
      );
      if (ok) {
        this.reset();
        this.$router.back();
      }
    },
  },
});
