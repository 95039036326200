/* eslint-disable vue/max-len */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import store from '@/store/store';
import { ExponentialBackoff, Websocket, WebsocketBuilder } from 'websocket-ts/lib';
import Timer from '@/services/timer';
import debounce from 'lodash.debounce';
import { dateByAddingSeconds, dateDiff, dateFromUTCDateTimeSecondsString } from './time-utils';

const wsURL = process.env.VUE_APP_ENDPOINT_WS!;

const HeartBeatInterval = 4 * 60; // 4 min heartbeat
const WatchDogInterval = 9 * 60; // 9 min watchdog
const MinHeartBeatInterval = 0; // no minimum heartbeat interval

let websocket: Websocket | null = null;

// watchdog and heartbeat
const heartBeatTimer = new Timer(() => { sendHeardBeat(); });
let lastHeartBeat: Date | null = null;

const watchDogTimer = new Timer(() => { reconnect(); });
let lastWatchDog: Date | null = null;

if (MinHeartBeatInterval && MinHeartBeatInterval < HeartBeatInterval) {
  store.watch<string | null>((state) => state.session.unixTimestamp, (value, oldValue) => { if (store.getters.isLoggedIn) sendHeardBeat(); });
}

// pushupdate request
store.watch<number | null>((state, rootGetters) => rootGetters.pushUpdateRequestTimestamp, (value, oldValue) => {
  if (value && value !== oldValue) debouncedUpdate();
});

// debounced update
const debouncedUpdate = debounce(async () => {
  try {
    await store.dispatch('performPushUpdate');
  } catch (e) {
    console.log('ws update error: ', e);
  }
}, 500, { leading: true, trailing: true, maxWait: 5000 });

// delayd start, immediate end
const debouncedConnect = debounce(() => { connect(); }, 2000);
store.watch<string | null>((state, getters) => getters.token, (value, oldValue) => {
  if (!value || (value !== oldValue && oldValue)) disconnect(); // no token or changed token
  if (value && value !== oldValue) debouncedConnect();
}, { immediate: true });

async function connect(): Promise<boolean> {
  // check logged in (token !== NULL => logged in)
  const { token } = store.state.session;
  if (!token) return Promise.resolve(false);

  console.log('ws connect');
  disconnect();

  const ws = new WebsocketBuilder(wsURL)
    .withProtocols(token)
    .withBackoff(new ExponentialBackoff(1000, 4))
    .onOpen((i, ev) => { console.log('ws opened'); wsOpen(); })
    .onClose((i, ev) => { console.log('ws closed'); wsClose(); })
    .onError((i, ev) => { console.log('ws error: ', ev); })
    .onMessage(async (i, ev) => { console.log('ws message: ', ev.data); await wsMessage(ev.data as string); })
    .onRetry((i, ev) => { console.log('ws retry'); if (!store.state.session.token) disconnect(); })
    .build();

  websocket = ws;

  return Promise.resolve(true);
}

async function reconnect() {
  if (!websocket || !store.state.session.token) { disconnect(); return; }

  console.log('ws reconnect');
  (websocket as any).reconnect();
}

function disconnect() {
  console.log('ws disconnect');

  if (websocket) websocket.close();
  websocket = null;

  watchDogTimer.schedule(null);
  heartBeatTimer.schedule(null);
}

function isWebsocketOpened(): boolean {
  if (!websocket || !websocket.underlyingWebsocket) return false;
  return websocket.underlyingWebsocket.readyState === websocket.underlyingWebsocket.OPEN;
}

function wsOpen() {
  // heart beat
  heartBeatTimer.schedule(dateByAddingSeconds(new Date(), HeartBeatInterval));

  // watchdog
  watchDogTimer.schedule(dateByAddingSeconds(new Date(), WatchDogInterval));
}

function wsClose() {
  watchDogTimer.schedule(null);
  heartBeatTimer.schedule(null);
}

async function wsMessage(data: string) {
  // reset watch dog
  lastWatchDog = new Date();
  watchDogTimer.schedule(dateByAddingSeconds(lastWatchDog, WatchDogInterval));

  // parse json data
  const notification = JSON.parse(data) as { a: number, t: string, u: number };
  const timestamp = notification.u;

  // set timestamp
  store.dispatch('setPushUpdateTimestamp', { timestamp });
}

function sendHeardBeat() {
  if (!isWebsocketOpened()) return;

  const now = new Date();

  // check last heartbeat, wait 60s at least
  if (lastHeartBeat) {
    const diff = dateDiff(now, lastHeartBeat) / 1000;
    if (diff < MinHeartBeatInterval) {
      const next = dateByAddingSeconds(now, MinHeartBeatInterval - Math.ceil(diff));
      heartBeatTimer.schedule(next);
      return;
    }
  }

  // send last update timestamp data
  const u = Number(store.state.session.unixTimestamp ?? 0);
  const json = JSON.stringify({ u });

  console.log('ws send: ', json);
  websocket!.send(json);

  // schedule next heartbeat
  lastHeartBeat = new Date();
  const next = dateByAddingSeconds(lastHeartBeat, HeartBeatInterval);
  heartBeatTimer.schedule(next);
}
