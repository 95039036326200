
import Vue, { PropType } from 'vue';
import Reservation from '@/model/Reservation';
import GuestListRow from '@/components/views/guests/GuestListRow.vue';
import GuestListHeaderRow from '@/components/views/guests/GuestListHeaderRow.vue';

export default Vue.extend({
  name: 'FloorplanViewSidebar',
  components: {
    GuestListRow, GuestListHeaderRow,
  },
  props: {
    reservationsByTime: { type: Object as PropType<Map<string, Reservation[]>>, required: true },
    selectedResId: { type: Number, required: false, default: undefined },
    isLoaded: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      selected: this.selectedResId,
    };
  },
  computed: {
    overbookedTabItemsForReservation(): Map<number, Set<number>|undefined> {
      return this.$tstore.getters.dayOverbookedTabItemsForReservationId;
    },
  },
  watch: {
    selectedResId() {
      this.selected = this.selectedResId;
    },
  },
  methods: {
    selectedChange(reservationId: number) {
      this.$emit('selected', reservationId);
    },
  },
});

