import store from '@/store/store';
import Timer from '@/services/timer';

let updateResolve: ((result: boolean) => void) | null = null;
let updateReject: ((reason?: any) => void) | null = null;

function finishUpdatePromise(resultOrReason: any) {
  if (updateResolve === null || updateReject === null) return;
  if (resultOrReason === true) updateResolve(true);
  else if (resultOrReason === false) updateResolve(false);
  else updateReject(resultOrReason);
  updateResolve = null;
  updateReject = null;
}

const timer = new Timer(async () => {
  try {
    await store.dispatch('performAutoUpdate');
    finishUpdatePromise(true);
  } catch (e) {
    finishUpdatePromise(e);
  }
});

function scheduleAutoUpdate(nextUpdateDate: Date | null) {
  if (!timer.schedule(nextUpdateDate)) {
    finishUpdatePromise(false);
    return;
  }

  store.dispatch('autoUpdateSetScheduled', nextUpdateDate);
}

export async function stopAutoUpdates() {
  await store.dispatch('stopAutoUpdate');
  finishUpdatePromise(false);
  timer.schedule(null);
}

store.watch<Date | null>((state, rootGetters) => rootGetters.autoUpdateNextDate, (value, oldValue) => {
  if (value === oldValue) return;
  scheduleAutoUpdate(value);
});

export async function startAutoUpdates(): Promise<boolean> {
  const nextUpdateDate = await store.dispatch('startAutoUpdate');
  if (!nextUpdateDate) return false;

  scheduleAutoUpdate(nextUpdateDate);

  return new Promise<boolean>((resolve, reject) => { updateResolve = resolve; updateReject = reject; });
}
