
import Vue from 'vue';
import HelpHeader from '@/components/views/settings/HelpHeader.vue';

export default Vue.extend({
  name: 'SettingsForm',
  components: { HelpHeader },
  props: {
    breadcrumbs: { type: Object, required: false, default: null },
  },
  data: () => ({}),
  computed: {
    dark(): boolean { return this.$vuetify.theme.dark; },
  },
});

