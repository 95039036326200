
import Vue from 'vue';
import { performAction } from '@/services/vue-utils';
import { supportedLanguages } from '@/plugins/i18n';
import ResetPasswordDialog from '@/components/views/login/ResetPasswordDialog.vue';
import CreateAccountDialog from '@/components/views/login/CreateAccountDialog.vue';
import storage from '@/services/local-storage';
import { ApiErrorCode } from '@/api/api-error';
import { setDarkAllowed } from '@/services/autodarkmode';
import setLanguage from '@/plugins/i18n-locale';

export default Vue.extend({
  name: 'Login',
  components: {
    ResetPasswordDialog,
    CreateAccountDialog,
    LoginGuestplan: () => import('@/components/views/login/LoginGuestplan.vue'),
    LoginBooq: () => import('@/components/views/login/LoginBooq.vue'),
  },
  props: {},
  data() {
    return {
      languages: supportedLanguages,
      language: this.$i18n.locale.split('-')[0],
      username: process.env.NODE_ENV === 'development' ? 'user1' : null,
      password: process.env.NODE_ENV === 'development' ? 'pass1' : null,
      failedAttempts: 0,
      resetPassword: false,
      createAccount: false,
      resetPasswordHint: false,
    };
  },
  computed: {
    brand(): string | undefined {
      return process.env.VUE_APP_BRAND;
    },
    isRegistered(): boolean {
      return this.$tstore.getters.isRegistered;
    },
    isLoggedIn(): boolean {
      return this.$tstore.getters.isLoggedIn;
    },
    isSignup(): boolean {
      return this.$route.name === 'signup' || this.$route.name === 'promo';
    },
    createAccountVisible(): boolean {
      return this.createAccount || this.isLoggedIn;
    },
  },
  watch: {
    language() {
      setLanguage(this.language, '');
    },
    username() {
      this.failedAttempts = 0;
    },
    isRegistered(newValue: boolean) {
      // registration changed while on login screen -> go to home route
      if (!newValue) return;
      if (this.$route.meta?.public !== true) return;

      this.$router.push('/');
      storage.setLanguage(this.$i18n.locale);
      setDarkAllowed(true);
    },

  },
  async mounted() {
    setDarkAllowed(false);
    if (this.isSignup) {
      this.createAccount = true;
    }
    if (typeof this.$route.query.username === 'string' && this.$route.query.username !== '') {
      this.username = this.$route.query.username;
    }
  },
  methods: {
    async login() {
      let ok = true;
      try {
        await performAction(
          this.$i18n.tc('message.success_sign_in'),
          this.$i18n.tc('error.login'),
          async () => this.$tstore.dispatch('login', { username: this.username, password: this.password }),
          false,
          true,
        );
      } catch (e: any) {
        ok = false;
        if (e.code === ApiErrorCode.user_not_found) {
          this.failedAttempts += 1;
          this.resetPasswordHint = (this.failedAttempts % 3) === 0;
        }
      }

      if (ok) {
        this.failedAttempts = 0;

        this.$router.push('/');
        storage.setLanguage(this.$i18n.locale);
        setDarkAllowed(true);
      }
    },
    resetPasswordHintConfirmed() {
      this.resetPassword = true;
    },
  },
});
