export class BoundingBox {
  x1: number;

  y1: number;

  x2: number;

  y2: number;

  constructor(x: number, y: number, width: number, height: number) {
    this.x1 = x - (width / 2);
    this.y1 = y - (height / 2);
    this.x2 = x + (width / 2);
    this.y2 = y + (height / 2);
  }

  overlaps(bbox: BoundingBox): boolean {
    if (this.x1 >= bbox.x2 || bbox.x1 >= this.x2) return false;
    if (this.y1 >= bbox.y2 || bbox.y1 >= this.y2) return false;
    return true;
  }
}

export interface ITableSize {
  h: number,
  w: number,
}

export interface ITableSeatInfo {
  origin?: number
  rotation?: number,
  isOverflow?: boolean,
  occupied: boolean,
}

export interface ITableSeats {
  n?: ITableSeatInfo[],
  s?: ITableSeatInfo[],
  e?: ITableSeatInfo[],
  w?: ITableSeatInfo[],
}

export enum TableSeatType {
  HEADSEAT = 'HEADSEAT',
  SQUARED = 'SQUARED',
  ROUNDED = 'ROUNDED'
}
