interface IDTOCampaign {
  campaignId: number;
  description?: string;
}

export default class Campaign {
  id: number;

  description: string;

  constructor(o: IDTOCampaign) {
    this.id = o.campaignId;
    this.description = o.description ?? '';
  }

  get value() { return this.id; }

  get text() { return this.description; }
}
