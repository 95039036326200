
import Vue, { PropType } from 'vue';
import DialogClosable from '@/mixins/dialog-closable';
import GuestsAllocationsDialog from '@/components/views/settings/GuestsAllocationsDialog.vue';
import TableCombinationDialog from '@/components/views/settings/TableCombinationDialog.vue';
import Tab from '@/model/Tab';
import Allocation from '@/model/Allocation';

type Item = { partySize: number, allocationsCount: number, isError: boolean };

export enum AllocationsDialogMode {
  AllowedAllocations,
  DisallowedAllocations,
}

export default Vue.extend({
  name: 'TabAllocationsDialog',
  components: { GuestsAllocationsDialog, TableCombinationDialog },
  mixins: [DialogClosable],
  props: {
    mode: { type: Number as PropType<AllocationsDialogMode>, required: true },
  },
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t('label.party_size'),
          value: 'partySize',
          align: 'start',
          sortable: false,
        },
        {
          text: this.$i18n.t('label.allocations'),
          value: 'allocationsCount',
          align: 'end',
          sortable: false,
        },
      ],
      addTableCombinationDialog: false,
      guestsDialog: false,
      // maxPartySize: 0,
      guestsPartySize: 0,
    };
  },
  computed: {
    title(): string {
      switch (this.mode) {
        case AllocationsDialogMode.AllowedAllocations: return this.$i18n.tc('button.preferred_allocations');
        case AllocationsDialogMode.DisallowedAllocations: return this.$i18n.tc('button.disallowed_allocations');
        default: return 'N/A';
      }
    },
    allocationsByPartySize(): Map<number, Allocation[]> {
      switch (this.mode) {
        case AllocationsDialogMode.AllowedAllocations:
          return this.$tstore.getters.allowedAllocationsByPartySize;
        case AllocationsDialogMode.DisallowedAllocations:
          return this.$tstore.getters.disallowedAllocationsByPartySize;
        default: return new Map<number, Allocation[]>();
      }
    },
    items(): Item[] {
      const items: Item[] = [];
      for (let partySize = 1; partySize <= this.maxPartySize; partySize += 1) {
        const allocations = this.allocationsByPartySize.get(partySize) ?? [];
        const isError = this.isErrorWithinAllocations(allocations);
        items.push({ partySize, isError, allocationsCount: allocations.length });
      }
      return items;
    },
    maxPartySize(): number {
      return Math.max(...this.allocationsByPartySize.keys(), 0);
    },
  },
  watch: {
  },
  methods: {
    add() {
      this.addTableCombinationDialog = true;
    },
    close() {
      this.$emit('close');
    },
    closeTableCombinationDialog() {
      this.addTableCombinationDialog = false;
    },
    editAllocations(item: Item) {
      if (item.allocationsCount === 0) { return; }
      this.guestsDialog = true;
      this.guestsPartySize = item.partySize;
    },
    isErrorWithinAllocations(allocations: Allocation[]): boolean {
      const map = new Set<string>();
      return !allocations.every((o) => (
        map.has(o.tableIdsString) || o.isError ? false : map.add(o.tableIdsString)), true);
    },
    dtClassForItem(item: Item) {
      if (item.allocationsCount === 0) {
        return 'disabled-table-row grey--text';
      }
      return 'clickable-table';
    },
  },
});
