import { defineComponent } from 'vue';

export default defineComponent({
  beforeRouteLeave(to, from, next) {
    if (this.isChanged) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(this.$i18n.tc('message.confirm_leave_without_saving'))) {
        return;
      }
    }
    next();
  },
  computed: {
    isChanged() {
      return false;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav, false);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav, false);
    });
  },
  mounted() {},
  methods: {
    preventNav(event: Event) {
      if (this.isChanged) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = false;
      }
    },
  },
});
