
import { billingCurrencyText, preferedCountryCurrency } from '@/services/billing-utils';
import {
  Currency, Customer, Price, Country as BillingCountry,
} from '@/api/api-billing';

import Vue, { PropType } from 'vue';
import Account from '@/model/Account';

export default Vue.extend({
  name: 'CurrencyPicker',
  props: {
    value: { type: String as PropType<string>, required: true },
  },
  data() {
    return {
      currency: Currency.EUR as string,
    };
  },
  computed: {
    currencies(): string[] {
      return this.$tstore.getters.billingCurrencies as string[];
    },
    currencyText(): string {
      return billingCurrencyText(this.currency);
    },
    currenciesItems(): {key: string, value: string}[] {
      return this.currencies.map((curr) => ({ key: curr, value: billingCurrencyText(curr) }));
    },
  },
  watch: {
    value() {
      this.currency = this.value;
    },
  },
  async mounted() {
    this.currency = this.value;
  },
  methods: {
    setCurrency(curr: string) {
      this.currency = curr;
      this.$emit('input', this.currency);
    },
  },
});
