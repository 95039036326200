import { Ref, computed, ref } from 'vue';

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

type PwaInstallInstance = {
  isInstallable: Ref<boolean>;
  installPrompt: () => void;
};

export default (() => {
  const deferredEvent = ref(undefined as undefined | BeforeInstallPromptEvent);

  window.addEventListener('beforeinstallprompt', (e: Event) => {
    // console.log('Captured beforeinstall event:');
    // console.log(e);
    e.preventDefault();
    if (!navigator.userAgent.toLowerCase().includes('android')) { return; }

    deferredEvent.value = e as BeforeInstallPromptEvent;
  });

  const installPrompt = async () => {
    if (!deferredEvent.value) { return; }

    deferredEvent.value.prompt();
    const { outcome } = await deferredEvent.value.userChoice;

    // The deferredPrompt can only be used once.
    deferredEvent.value = undefined;

    // Act on the user's choice
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt.');
    } else if (outcome === 'dismissed') {
      console.log('User dismissed the install prompt');
    }
  };

  const isInstallable = computed(() => deferredEvent.value !== undefined);

  const instance: PwaInstallInstance = {
    isInstallable, installPrompt,
  };

  return () => instance;
})();
