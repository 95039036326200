import { PluginObject, VueConstructor } from 'vue';
import { VueLocalizedFormatterOptions } from './options';
import VueLocalizedFormatter from './localized';

declare global {
  interface Window {
    Vue: VueConstructor;
  }
}

const version = '1.0.0';

const plugin: PluginObject<VueLocalizedFormatterOptions> = {
  install: (Vue, options: VueLocalizedFormatterOptions | undefined): void => {
    if (plugin.installed) {
      return;
    }
    plugin.installed = true;

    const localized = VueLocalizedFormatter;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$localized = localized;

    Object.defineProperties(Vue.prototype, {
      $localized: {
        get() {
          return localized;
        },
      },
    });
  },
  version,
};

export default plugin;
