import storage from '@/services/local-storage';
import moment from 'moment';
import i18n, { engLocales, supportedLocales } from './i18n';
import localized from './vue-localized-formatter/src/localized';

const countryLocaleMap = {
  AD: 'ca',
  AE: 'ar-AE',
  AF: 'fa-AF',
  AG: 'en-AG',
  AI: 'en-AI',
  AL: 'sq',
  AM: 'hy',
  AO: 'pt-AO',
  AR: 'es-AR',
  AS: 'en-AS',
  AT: 'de-AT',
  AU: 'en-AU',
  AW: 'nl-AW',
  AX: 'sv-AX',
  AZ: 'az',
  BA: 'bs',
  BB: 'en-BB',
  BD: 'bn-BD',
  BE: 'nl-BE',
  BF: 'fr-BF',
  BG: 'bg',
  BH: 'ar-BH',
  BI: 'fr-BI',
  BJ: 'fr-BJ',
  BL: 'fr',
  BM: 'en-BM',
  BN: 'ms-BN',
  BO: 'es-BO',
  BQ: 'nl',
  BR: 'pt-BR',
  BS: 'en-BS',
  BT: 'dz',
  BW: 'en-BW',
  BY: 'be',
  BZ: 'en-BZ',
  CA: 'en-CA',
  CC: 'ms-CC',
  CD: 'fr-CD',
  CF: 'fr-CF',
  CG: 'fr-CG',
  CH: 'de-CH',
  CI: 'fr-CI',
  CK: 'en-CK',
  CL: 'es-CL',
  CM: 'en-CM',
  CN: 'zh-CN',
  CO: 'es-CO',
  CR: 'es-CR',
  CU: 'es-CU',
  CV: 'pt-CV',
  CW: 'nl',
  CX: 'en',
  CY: 'el-CY',
  CZ: 'cs',
  DE: 'de',
  DJ: 'fr-DJ',
  DK: 'da-DK',
  DM: 'en-DM',
  DO: 'es-DO',
  DZ: 'ar-DZ',
  EC: 'es-EC',
  EE: 'et',
  EG: 'ar-EG',
  EH: 'ar',
  ER: 'aa-ER',
  ES: 'es-ES',
  ET: 'am',
  FI: 'fi-FI',
  FJ: 'en-FJ',
  FK: 'en-FK',
  FM: 'en-FM',
  FO: 'fo',
  FR: 'fr-FR',
  GA: 'fr-GA',
  GB: 'en-GB',
  GD: 'en-GD',
  GE: 'ka',
  GF: 'fr-GF',
  GG: 'en',
  GH: 'en-GH',
  GI: 'en-GI',
  GL: 'kl',
  GM: 'en-GM',
  GN: 'fr-GN',
  GP: 'fr-GP',
  GQ: 'es-GQ',
  GR: 'el-GR',
  GS: 'en',
  GT: 'es-GT',
  GU: 'en-GU',
  GW: 'pt-GW',
  GY: 'en-GY',
  HK: 'zh-HK',
  HN: 'es-HN',
  HR: 'hr-HR',
  HT: 'ht',
  HU: 'hu-HU',
  ID: 'id',
  IE: 'en-IE',
  IL: 'he',
  IM: 'en',
  IN: 'en-IN',
  IO: 'en-IO',
  IQ: 'ar-IQ',
  IR: 'fa-IR',
  IS: 'is',
  IT: 'it-IT',
  JE: 'en',
  JM: 'en-JM',
  JO: 'ar-JO',
  JP: 'ja',
  KE: 'en-KE',
  KG: 'ky',
  KH: 'km',
  KI: 'en-KI',
  KM: 'ar',
  KN: 'en-KN',
  KP: 'ko-KP',
  KR: 'ko-KR',
  XK: 'sq',
  KW: 'ar-KW',
  KY: 'en-KY',
  KZ: 'kk',
  LA: 'lo',
  LB: 'ar-LB',
  LC: 'en-LC',
  LI: 'de-LI',
  LK: 'si',
  LR: 'en-LR',
  LS: 'en-LS',
  LT: 'lt',
  LU: 'lb',
  LV: 'lv',
  LY: 'ar-LY',
  MA: 'ar-MA',
  MC: 'fr-MC',
  MD: 'ro',
  ME: 'sr',
  MF: 'fr',
  MG: 'fr-MG',
  MH: 'mh',
  MK: 'mk',
  ML: 'fr-ML',
  MM: 'my',
  MN: 'mn',
  MO: 'zh',
  MP: 'fil',
  MQ: 'fr-MQ',
  MR: 'ar-MR',
  MS: 'en-MS',
  MT: 'mt',
  MU: 'en-MU',
  MV: 'dv',
  MW: 'ny',
  MX: 'es-MX',
  MY: 'ms-MY',
  MZ: 'pt-MZ',
  NA: 'en-NA',
  NC: 'fr-NC',
  NE: 'fr-NE',
  NF: 'en-NF',
  NG: 'en-NG',
  NI: 'es-NI',
  NL: 'nl-NL',
  NO: 'no',
  NP: 'ne',
  NR: 'na',
  NU: 'niu',
  NZ: 'en-NZ',
  OM: 'ar-OM',
  PA: 'es-PA',
  PE: 'es-PE',
  PF: 'fr-PF',
  PG: 'en-PG',
  PH: 'tl',
  PK: 'ur-PK',
  PL: 'pl',
  PM: 'fr-PM',
  PN: 'en-PN',
  PR: 'en-PR',
  PS: 'ar-PS',
  PT: 'pt-PT',
  PW: 'pau',
  PY: 'es-PY',
  QA: 'ar-QA',
  RE: 'fr-RE',
  RO: 'ro',
  RS: 'sr',
  RU: 'ru',
  RW: 'rw',
  SA: 'ar-SA',
  SB: 'en-SB',
  SC: 'en-SC',
  SD: 'ar-SD',
  SS: 'en',
  SE: 'sv-SE',
  SG: 'cmn',
  SH: 'en-SH',
  SI: 'sl',
  SJ: 'no',
  SK: 'sk',
  SL: 'en-SL',
  SM: 'it-SM',
  SN: 'fr-SN',
  SO: 'so-SO',
  SR: 'nl-SR',
  ST: 'pt-ST',
  SV: 'es-SV',
  SX: 'nl',
  SY: 'ar-SY',
  SZ: 'en-SZ',
  TC: 'en-TC',
  TD: 'fr-TD',
  TF: 'fr',
  TG: 'fr-TG',
  TH: 'th',
  TJ: 'tg',
  TK: 'tkl',
  TL: 'tet',
  TM: 'tk',
  TN: 'ar-TN',
  TO: 'to',
  TR: 'tr-TR',
  TT: 'en-TT',
  TV: 'tvl',
  TW: 'zh-TW',
  TZ: 'sw-TZ',
  UA: 'uk',
  UG: 'en-UG',
  UM: 'en-UM',
  US: 'en-US',
  UY: 'es-UY',
  UZ: 'uz',
  VA: 'la',
  VC: 'en-VC',
  VE: 'es-VE',
  VG: 'en-VG',
  VI: 'en-VI',
  VN: 'vi',
  VU: 'bi',
  WF: 'wls',
  WS: 'sm',
  YE: 'ar-YE',
  YT: 'fr-YT',
  ZA: 'zu',
  ZM: 'en-ZM',
  ZW: 'en-ZW',
  CS: 'cu',
  AN: 'nl-AN',
} as Record<string, string>;

function getLocaleTraits(localeName: string) {
  console.log(`[LOCALE] =========== ${localeName}=========`);

  const loc = moment(new Date('2022-12-30T16:00:00'));
  loc.locale(localeName);

  const fdow = loc.localeData().firstDayOfWeek();

  const formattedTime = loc.format('LT');
  console.log(`[LOCALE] LT ${formattedTime}`);
  const hc = formattedTime.indexOf('16') > -1 ? 'h24' : 'h12';

  const formattedDate = loc.format('L');
  console.log(`[LOCALE] L ${formattedDate}`);

  let df = '';
  if (formattedDate.startsWith('30')) {
    df = 'dmy';
  } else if (formattedDate.startsWith('12')) {
    df = 'mdy';
  } else {
    df = 'ymd';
  }

  console.log(`[LOCALE] fdow: ${fdow}`);
  console.log(`[LOCALE] hc: ${hc}`);
  console.log(`[LOCALE] df: ${df}`);
  console.log('[LOCALE] =====================');

  return {
    fdow,
    hc,
    df,
  };
}

function updateLangauge(lang: string) {
  console.log(`[LOCALE] resolved to ${lang}`);

  i18n.locale = lang;
  localized.setLocale(lang);
  storage.setLanguage(lang);
}

export default function setLanguage(lang: string, country: string) {
  console.log(`[LOCALE] set lang: ${lang} cc: ${country}`);

  const constructedLocale = `${lang}-${country}`;

  if (country === '') {
    // Only language, unknown country => set both right away
    updateLangauge(lang);
  } else if (supportedLocales.includes(constructedLocale)) {
    // supported combination
    updateLangauge(constructedLocale);
  } else if (lang === 'en') {
    // unsupported combination, english in foreign country

    const localeForCountry = countryLocaleMap[country] ?? 'en-US';
    const traits = getLocaleTraits(localeForCountry);
    const closestEngLocale = engLocales.find(
      (l) => JSON.stringify(getLocaleTraits(l)) === JSON.stringify(traits),
    ) || 'en-GB';
    console.log(`[LOCALE] closest locale to ${country} is deemed ${closestEngLocale}`);
    updateLangauge(closestEngLocale);
  } else {
    // fallback
    updateLangauge(lang);
  }
}
