
import Vue from 'vue';

import SimpleLineChart from '@/components/views/reports/SimpleLineChart.vue';
import ReportsToolbar from '@/components/views/reports/ReportsToolbar.vue';
import { loadStoreModule, unloadStoreModule } from '@/services/module-loader';
import ReportsStore from '@/store/reports-store';
import { ReportsType } from '@/services/reports';
import { ChartData } from '@/services/reports-utils';

export default Vue.extend({
  name: 'ReportMessages',
  components: {
    SimpleLineChart,
    ReportsToolbar,
  },
  data() {
    return {
      sms_series: [this.$i18n.t('label.report_sent_sms')],
      email_series: [this.$i18n.t('label.report_sent_emails')],
    };
  },
  computed: {
    sms(): ChartData {
      return this.$tstore.getters.reportsSms;
    },
    emails(): ChartData {
      return this.$tstore.getters.reportsEmails;
    },
  },
  created() {
    loadStoreModule(this, 'reports', ReportsStore, async () => {
      this.$tstore.dispatch('updateReports', { reportsType: ReportsType.Messages });
    });
  },
  beforeDestroy() {
    unloadStoreModule(this, 'reports', async () => { this.$tstore.dispatch('resetReports'); });
  },
  methods: {},
});

