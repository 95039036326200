import { strcmp } from '@/services/common';
import { isFunction } from 'util';

interface IWithId { id: number }

export interface IApi {
  isDeleted?: string | boolean;
  [field: string]: any,
}

export interface IModel {
  new?(): IModel,
  id: number,
  [field: string]: any,
}

export interface ISingleModel<A extends IApi> {
  id: number,
  toDto(): A,
}

export interface IListModel<A extends IApi> {
  id: number,
  order: number,
  toDto(): A,
}

export function toMapById<M extends IWithId>(items: M[]): Map<number, M> {
  const map = new Map<number, M>();
  items.forEach((o) => { map.set(o.id, o); });
  return map;
}

export function toMapByField<M, KT>(items: M[], field: string): Map<KT, M> {
  const map = new Map<KT, M>();
  items.forEach((o: any) => { map.set(o[field], o); });
  return map;
}

export function toMapByFieldFirst<M, KT>(items: M[], field: string): Map<KT, M> {
  const map = new Map<KT, M>();
  items.forEach((o: any) => { if (!map.has(o[field])) map.set(o[field], o); });
  return map;
}

export function groupByField<M, KT>(items: M[], field: string): Map<KT, M[]> {
  return items.reduce(
    (map, o: any) => map.set(o[field], [...(map.get(o[field]) ?? []), o]),
    new Map<KT, M[]>(),
  );
}

export function uniqueMinId<M extends IWithId>(items: M[]): number {
  return items.reduce((min, o) => (min >= o.id ? o.id - 1 : min), -1);
}

export function mergeModelEntities<T extends IWithId>(oldEntities: T[], newEntities: T[]): T[] {
  const newEntitiesById = toMapById(newEntities);
  const mergedEntities = oldEntities.filter((o) => !newEntitiesById.has(o.id));
  mergedEntities.push(...newEntities);
  return mergedEntities;
}

export function toModelEntities<T extends IApi, G extends IModel, MAP>(
  ModelType: (new (iapi: T, mapping?: MAP) => G),
  apiEntities?: T[],
  p?: { idField?: string, stringOrderField?: string, compareFn?: (o1: G, o2: G) => number, mapping?: MAP},
): G[] {
  // console.log('apiEntities:', apiEntities);
  const undeletedApiEntities = (apiEntities ?? []).filter((o) => o.isDeleted !== 'True' && o.isDeleted !== true);
  // console.log('undeletedApiEntities:', undeletedApiEntities);
  const modelEntities = undeletedApiEntities.map((o) => new ModelType(o, p?.mapping));
  // console.log('modelEntities:', modelEntities);
  const validEntities = modelEntities.filter((o) => o[p?.idField ?? 'id']);
  // console.log('validEntities:', validEntities);

  // console.log('p?.stringOrderField:', p?.stringOrderField);
  let compareFn = p?.compareFn;
  if (!compareFn && p?.stringOrderField) {
    compareFn = (o1: G, o2: G) => strcmp(o1[p?.stringOrderField!], o2[p?.stringOrderField!]);
  }
  if (!compareFn) {
    compareFn = (o1: G, o2: G) => o1.order - o2.order;
  }
  const orderedEntities = validEntities.sort(compareFn);
  // console.log('orderedEntities:', orderedEntities);
  return orderedEntities;
}

export function mergeToModelEntities<A extends IApi, M extends IModel, MAP>(
  ModelType: (new (iapi: A, mapping?: MAP) => M),
  oldEntities?: M[],
  apiEntities?: A[],
  p?: { idField?: string, stringOrderField?: string, compareFn?: (o1: M, o2: M) => number, mapping?: MAP },
): M[] {
  if (!apiEntities) return oldEntities ?? [];
  // console.log('oldEntities:', oldEntities);
  // console.log('apiEntities:', apiEntities);

  const newValidEntities: M[] = [];
  const newEntitiesById = new Map<number, M>();
  (apiEntities ?? []).forEach((o) => {
    const m = new ModelType(o, p?.mapping);
    if (!m.id) return;
    newEntitiesById.set(m.id, m);
    if (o.isDeleted !== 'True' && o.isDeleted !== true) newValidEntities.push(m);
  });
  // console.log('newEntitiesById:', newValidEntities);
  // console.log('newValidEntities:', newValidEntities);

  const mergedEntities = (oldEntities ?? []).filter((o) => !newEntitiesById.has(o.id));
  mergedEntities.push(...newValidEntities);
  // console.log('mergedEntities:', mergedEntities);

  let compareFn = p?.compareFn;
  if (!compareFn && p?.stringOrderField) {
    compareFn = (o1: M, o2: M) => strcmp(o1[p?.stringOrderField!], o2[p?.stringOrderField!]);
  }
  if (!compareFn) {
    compareFn = (o1: M, o2: M) => o1.order - o2.order;
  }
  const orderedEntities = mergedEntities.sort(compareFn);
  // console.log('orderedEntities:', orderedEntities);
  return orderedEntities;
}

export function cloneModel<M extends IModel>(instance: M): M {
  if (typeof instance.clone === 'function') return instance.clone();
  const copy = new (instance.constructor as { new(): M })();
  Object.assign(copy, instance);
  return copy;
}

export function isModelEqualToModel<M extends IModel | IModel[]>(o1: M, o2: M): boolean {
  // console.log('isModelEqualToModel');
  // console.log('1: ', JSON.stringify(o1));
  // console.log('2: ', JSON.stringify(o2));
  const result = JSON.stringify(o1) === JSON.stringify(o2);
  // console.log('r: ', result);
  return result;
}

export function toSaveModelItems<A extends IApi, M extends IListModel<A>>(newItems: M[], oldItems: M[]): A[] {
  // console.log('newItems:', newItems);
  // console.log('oldItems:', oldItems);
  // set order for items
  for (let i = 1; i < newItems.length; i += 1) {
    // eslint-disable-next-line no-param-reassign
    if (newItems[i].order < newItems[i - 1].order) newItems[i].order = newItems[i - 1].order;
  }

  // identity items to delete
  const newById = toMapById(newItems);
  // console.log('newById:', newById);
  const deleteItems = oldItems.filter((o) => !newById.has(o.id));
  // console.log('deleteItems:', deleteItems);

  // identity modyfied items
  const oldById = toMapById(oldItems);
  // console.log('oldById:', oldById);
  const modifyItems = newItems.filter((o) => !oldById.has(o.id) || !isModelEqualToModel(o, oldById.get(o.id)!));
  // console.log('modifyItems:', modifyItems);

  // create dtos
  const deletedDtos = deleteItems.map((o) => { const dto = o.toDto(); dto.isDeleted = true; return dto; });
  const modifiedDtos = modifyItems.map((o) => o.toDto());

  return [...deletedDtos, ...modifiedDtos];
}
