/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */

import localized from '@/plugins/vue-localized-formatter/src/localized';
import {
  FilterRule, FilterBoolRule, FilterRuleType, FilterRuleContext,
} from './filter-rule';

const FILTER_VERSION = '0.9';

export class Filter {
  id: number = 0;

  version: string = FILTER_VERSION;

  rule: FilterRule = new FilterBoolRule();

  static newFilter(version: string, rule: FilterRule) {
    const f = new Filter();
    f.version = version;
    f.rule = rule;
    return f;
  }

  static newFilterFrom3Rules(
    rules: {defineRules: FilterRule[], narrowRules: FilterRule[], excludeRules: FilterRule[]},
  ) {
    const f = new Filter();
    f.version = FILTER_VERSION;
    f.set3Rules(rules);
    return f;
  }

  static newFilterFromRule(rule: FilterRule) {
    const f = new Filter();
    f.version = FILTER_VERSION;
    f.rule = rule;
    return f;
  }

  set3Rules(rules: {defineRules: FilterRule[], narrowRules: FilterRule[], excludeRules: FilterRule[]}) {
    let { defineRules } = rules;
    const { narrowRules } = rules;
    const { excludeRules } = rules;

    defineRules = defineRules.length > 0 ? defineRules : [FilterRule.newFilterBoolRule(FilterRuleType.True)!];

    this.rule = new FilterBoolRule(FilterRuleType.And, [
      new FilterBoolRule(FilterRuleType.Or, defineRules), // false if none
      new FilterBoolRule(FilterRuleType.And, narrowRules), // true if none
      new FilterBoolRule(FilterRuleType.Nor, excludeRules), // true if none
    ]);
  }

  get3Rules(): ({ defineRules: FilterRule[], narrowRules: FilterRule[], excludeRules: FilterRule[] }) {
    const rs = (this.rule as FilterBoolRule)?.rules;

    let defineRules = (rs[0] as FilterBoolRule)?.rules ?? [];
    const narrowRules = (rs[1] as FilterBoolRule)?.rules ?? [];
    const excludeRules = (rs[2] as FilterBoolRule)?.rules ?? [];

    if (defineRules.length === 1 && defineRules[0].type === FilterRuleType.True) defineRules = [];

    return { defineRules, narrowRules, excludeRules };
  }

  prepare(context?: FilterRuleContext) {
    let ctx = context;
    if (!ctx) {
      ctx = new FilterRuleContext();
      ctx.firstDayOfWeek = localized.firstDayOfWeek();
    }

    this.rule.prepare(ctx);
  }

  check(object: { [field: string]: any }, context?: FilterRuleContext) {
    this.prepare(context);
    const res = this.rule.check(object);
    return res;
  }

  count<T extends { [field: string]: any }>(objects: T[], context?: FilterRuleContext): number {
    this.prepare(context);
    let count = 0;
    objects.forEach((o) => { if (this.rule.check(o)) count += 1; });
    return count;
  }

  filter<T extends { [field: string]: any }>(objects: T[], context?: FilterRuleContext): T[] {
    this.prepare(context);
    return objects.filter((o) => this.rule.check(o));
  }
}
